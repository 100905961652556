
import { RouteConfig } from 'vue-router'
import * as NaviTS from '@/type/system/NaviTS'

import DD3DModel from '@/views/dev/DD3DModel.vue'
import DDFault from '@/views/dev/DDFault.vue'
import DDHistoryChart from '@/views/dev/DDHistoryChart.vue'
import DDRealTimeChart from '@/views/dev/DDRealTimeChart.vue'
import DDMap from '@/views/dev/DDMap.vue'
import DDWarn from '@/views/dev/DDWarn.vue'
import DDWatch from '@/views/dev/DDWatch.vue'
import DDWatchHistory from '@/views/dev/DDWatchHistory.vue'
import DevDetail from '@/views/dev/DevDetail.vue'

const routes: Array<RouteConfig> = [
  {
    path: '/dev-detail',
    name: 'DevDetail',
    component: DevDetail,
    meta: {
      title: '设备参数',
      superTitle: NaviTS.ROUTE_SUPER_TITLE.HOME,
      superName: NaviTS.ROUTE_NAME.HOME,
      groupName: NaviTS.ROUTE_NAME.DEV_DETAIL, // 分组标识 在设备详情导航中使用
      noShowSide: true // 不在左侧菜单栏显示
    }
  },
  {
    path: '/3d-model',
    name: 'DD3DModel',
    component: DD3DModel,
    meta: {
      title: '3D建模',
      superTitle: NaviTS.ROUTE_SUPER_TITLE.HOME,
      superName: NaviTS.ROUTE_NAME.HOME,
      groupName: NaviTS.ROUTE_NAME.DEV_DETAIL, // 分组标识 在设备详情导航中使用
      noShowSide: true // 不在左侧菜单栏显示
    }
  },
  {
    path: '/dd-map',
    name: 'DDMap',
    component: DDMap,
    meta: {
      title: '位置',
      superTitle: NaviTS.ROUTE_SUPER_TITLE.HOME,
      superName: NaviTS.ROUTE_NAME.HOME,
      groupName: NaviTS.ROUTE_NAME.DEV_DETAIL, // 分组标识 在设备详情导航中使用
      noShowSide: true // 不在左侧菜单栏显示
    }
  },
  {
    path: '/dd-history-chart',
    name: 'DDHistoryChart',
    component: DDHistoryChart,
    meta: {
      title: '历史趋势查询',
      superTitle: NaviTS.ROUTE_SUPER_TITLE.HOME,
      superName: NaviTS.ROUTE_NAME.HOME,
      groupName: NaviTS.ROUTE_NAME.DEV_DETAIL, // 分组标识 在设备详情导航中使用
      noShowSide: true // 不在左侧菜单栏显示
    }
  },
  {
    path: '/dd-realtime-chart',
    name: 'DDRealTimeChart',
    component: DDRealTimeChart,
    meta: {
      title: '实时趋势查询',
      superTitle: NaviTS.ROUTE_SUPER_TITLE.HOME,
      superName: NaviTS.ROUTE_NAME.HOME,
      groupName: NaviTS.ROUTE_NAME.DEV_DETAIL, // 分组标识 在设备详情导航中使用
      noShowSide: true // 不在左侧菜单栏显示
    }
  },
  {
    path: '/dd-watch',
    name: 'DDWatch',
    component: DDWatch,
    meta: {
      title: '监控信息',
      superTitle: NaviTS.ROUTE_SUPER_TITLE.HOME,
      superName: NaviTS.ROUTE_NAME.HOME,
      groupName: NaviTS.ROUTE_NAME.DEV_DETAIL, // 分组标识 在设备详情导航中使用
      noShowSide: true // 不在左侧菜单栏显示
    },
  },
  {
    path: '/dd-warn',
    name: 'DDWarn',
    component: DDWarn,
    meta: {
      title: '报警信息',
      superTitle: NaviTS.ROUTE_SUPER_TITLE.HOME,
      superName: NaviTS.ROUTE_NAME.HOME,
      groupName: NaviTS.ROUTE_NAME.DEV_DETAIL, // 分组标识 在设备详情导航中使用
      noShowSide: true // 不在左侧菜单栏显示
    }
  },

  {
    path: '/dd-fault',
    name: 'DDFault',
    component: DDFault,
    meta: {
      title: '故障诊断',
      superTitle: NaviTS.ROUTE_SUPER_TITLE.HOME,
      superName: NaviTS.ROUTE_NAME.HOME,
      groupName: NaviTS.ROUTE_NAME.DEV_DETAIL, // 分组标识 在设备详情导航中使用
      noShowSide: true // 不在左侧菜单栏显示
    }
  },
]
export default routes;
