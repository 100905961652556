import axios, { AxiosResponse } from 'axios'
import { message, Modal } from 'ant-design-vue'
import * as HttpTS from '@/type/system/HttpTS'
import * as GlobalRuntime from '@/util/GlobalRuntimeUtil'
import * as NaviTS from '@/type/system/NaviTS'
import * as SessionUtil from '@/util/SessionUtil'
import * as MainLocal from '@/util/MainLocal'
import qs from 'qs'

const BASE_URL = 'sia-server/api/'
const CancelToken = axios.CancelToken;
const source = CancelToken.source();
const CANCEL_FLAG = "NO_aUTH_CANCEL";

export const get = (url: string, data?: any): Promise<any> => {

  const _req = devRequest.request({
    url: url,
    params: data,
    method: 'GET'
  })
  if (SessionUtil.getHttpReqState()) {
    source.cancel(CANCEL_FLAG)
  }
  return _req
}

export const post = (url: string, data?: any): Promise<any> => {
  const _req = devRequest.request({
    url: url,
    data: qs.stringify(data),
    method: 'POST'
  })
  
  if (SessionUtil.getHttpReqState()) {
    source.cancel(CANCEL_FLAG)
  }
  return _req
}

// Http请求对象
const devRequest = axios.create({
  baseURL: BASE_URL,
  timeout: 50000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  },
  withCredentials: true
});


devRequest.interceptors.request.use((config): any => {

  // 和谐掉空字串
  if (config.params) {
    for (const key in config.params) {
      if (config.params[key] === '') {
        config.params[key] = undefined
      }
    }
  }

  // 如果状态为禁用 阻断后续请求
  if (SessionUtil.getHttpReqState()) {
    config.cancelToken = source.token
  }

  return config
})

// 响应拦截
devRequest.interceptors.response.use((res): any => {
  // console.warn("RES+++", res);
  const data = res.data as HttpTS.HttpRes

  if (!data) {
    message.error('未知异常');
    return {};
  }

  // 一般性成功
  if (data.resCode == HttpTS.RESULT_CODE.SUCC) {
    data.isSucc = true;
    return data;
  }

  // 成功弹框
  if (data.resCode == HttpTS.RESULT_CODE.SUCC_ALERT) {
    data.isSucc = true;
    message.info({
      content: data.resMsg || '请求成功!',
      duration: data.resMsg && data.resMsg.length * 0.2 || 3
    });
    return data;
  }

  // 成功 并需要用户确认
  if (data.resCode == HttpTS.RESULT_CODE.SUCC_CONFIRM) {
    data.isSucc = true;
    Modal.info({
      title: '提示',
      content: data.resMsg || '操作成功!',
      okText: '关闭'
    })
    return data;
  }

  // 失败弹框
  if (data.resCode == HttpTS.RESULT_CODE.ERROR_ALERT || data.resCode == HttpTS.RESULT_CODE.ERROR_PERM) {
    message.error({
      content: data.resMsg || '请求失败!',
      duration: data.resMsg && data.resMsg.length * 0.2 || 3
    });
    return data;
  }

  // 失败 并需要用户确认
  if (data.resCode == HttpTS.RESULT_CODE.ERROR_CONFIRM) {
    Modal.error({
      title: '提示',
      content: data.resMsg || '操作失败!',
      okText: '关闭'
    })
    return data;
  }

  if (data.resCode == HttpTS.RESULT_CODE.ERROR_LOGIN) {

    // 屏蔽后续请求 TODO 有空研究一下
    // SessionUtil.banHttpReq()

    // 如果没在登录页 那么显示登录框
    const vm = GlobalRuntime.getVue();
    if (vm && vm.$route.name != NaviTS.ROUTE_NAME.LOGIN) {
      vm.$router.replace({name: NaviTS.ROUTE_NAME.LOGIN});
      if (MainLocal.isLogined()) {
        message.error(data.resMsg || '请重新登录!', data.resMsg && data.resMsg.length * 0.2 || 3, () => {
          // vm && vm.$store.dispatch("MainStore/openLoginDialog");
        });
      }
      
    } 
    // else {
      // message.error({
      //   content: data.resMsg || '请重新登录!',
      //   duration: data.resMsg && data.resMsg.length * 0.2 || 3
      // });
    // }
    return data;
  }

  console.error("Other Error", data);
  // TODO 存全局对象
  return data;
}, (error: any): any => {

  console.error("-------net--error", error);

  if (String(error).indexOf("500") != -1) {
    const vm = GlobalRuntime.getVue();
    if (vm && vm.$route.name != NaviTS.ROUTE_NAME.LOGIN) {
      vm.$router.replace({name: NaviTS.ROUTE_NAME.LOGIN});
      if (MainLocal.isLogined()) {
        message.error('请重新登录!', 3, () => {
          // vm && vm.$store.dispatch("MainStore/openLoginDialog");
        });
      }
      
    } 
  }

  // 主动取消
  if (error instanceof axios.Cancel) {
    if (error.message == CANCEL_FLAG) {
      console.warn("no auth cancel +++++++++++++++++++++++", error)
    }

  } else if (String(error).indexOf("timeout") != -1) {
    message.error("请求超时!");
  } else {
    message.error("网络异常!");
  }
  return {};
});

export const checkSucc = (res: any): boolean => {
  const _res = res as HttpTS.HttpRes;
  if (_res) {
    if (_res.resCode == HttpTS.RESULT_CODE.SUCC
      || _res.resCode == HttpTS.RESULT_CODE.SUCC_ALERT
      || _res.resCode == HttpTS.RESULT_CODE.SUCC_CONFIRM) {
      return true;
    }
  }
  return false;
}