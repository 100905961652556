import * as HttpTS from "@/type/system/HttpTS";

export class SysDictListReq extends HttpTS.ListReq {
  type: string | null = null // 类型
  isDel: number | null = null // 是否禁用 0 - 正常
}

export class SysDictListRes extends HttpTS.ListRes<SysDictTS> {}

export class SysDictTS {
  /** 自增主键, [关联] 数据表枚举值 */
  key?: number | string;
  /** 显示值 */
  value?: string;
  /** 描述信息 */
  title?: string;
  /** 过滤分类  表名.字段名.耦合值(可选) */
  type?: TYPE;
  /** 是否删除 1-是 0-否 */
  isDel?: number;
  // 是否活跃
  isActive?: boolean = false;
}


export enum TYPE {
  DEVICE_STATUS = 'DeviceTS.status', // 设备运行状态
  DEVICE_DEV_TYPE = 'DeviceTS.devType', // 设备分类

  WARN_STATE = 'WarnMsg.state', // 报警状态
  WARN_NOTIFIT = 'WarnMsg.notifiType', // 报警通知
}


export const TYPE_KV_OBJ = {
  [TYPE.DEVICE_STATUS]: '设备表-设备运行状态',
  [TYPE.DEVICE_DEV_TYPE]: '设备表-设备分类',

  [TYPE.WARN_STATE]: '报警状态',
  [TYPE.WARN_NOTIFIT]: '报警通知',
}
