import {Vue, Component, Prop, Model, Watch} from 'vue-property-decorator'
import * as RuleTS from '@/type/system/RuleTS'

/**
 * 规则编辑器
 */
@Component({
  components: {
  }
})
export default class RuleEdit extends Vue {
  // 是否禁用
  @Prop({default: () => false}) disabled!: boolean;
  @Model('mainChange') mainData: string;
  OPERATOR_SEL = RuleTS.OPERATOR_SEL
  CONNECTOR_SEL = RuleTS.CONNECTOR_SEL

  ruleArr: RuleTS.CommonRule[] = []
  // 运算符
  
  @Watch("mainData")
  onWatchMainData() {
    if (this.mainData) {
      this.ruleArr = JSON.parse(this.mainData)
    } else {
      this.ruleArr = []
    }
  }

  @Watch("ruleArr")
  onWatchRuleArr() {
    this.$emit('mainChange', JSON.stringify(this.ruleArr))
  }

  // 添加规则
  onClickAddRule() {
    this.$set(this.ruleArr, this.ruleArr.length, new RuleTS.CommonRule)
  }

  onClickDeleteRule(inx: number) {
    this.ruleArr.splice(inx, 1)
  }
}