import * as HttpUtil from '@/util/HttpUtil'
import * as HttpTS from '@/type/system/HttpTS'
import * as WarnTS from '@/type/project/WarnMsgTS'


export const getList = (params: WarnTS.WarnListReq): Promise<WarnTS.WarnListRes> => {
    params = JSON.parse(JSON.stringify(params))
    params.stateListJson = JSON.stringify(params.stateList)
    params.warnLevelListJson = JSON.stringify(params.warnLevelList)
    // if (params.deviceSensorId) {
    //     const strarr = params.deviceSensorId.split(HttpTS.ARRAY_FLAG)
    //     params.deviceId = Number(strarr[0])
    //     params.sensorCode = Number(strarr[1])
    // } else {
    //     params.deviceId = null;
    //     params.sensorCode = null;
    // }
    return HttpUtil.post("warn/list", params)
}

export const addOne = (mainData: WarnTS.WarnMsgTS): Promise<HttpTS.HttpRes> => {
    return HttpUtil.post("warn/add", {mainJson: JSON.stringify(mainData)})
}

export const update = (template: WarnTS.WarnMsgTS, id: number): Promise<HttpTS.HttpRes> => {
    return HttpUtil.post("warn/update", {mainJson: JSON.stringify(template), id})
}

export const deleteGroup = (ids: number[]): Promise<HttpTS.HttpRes> => {
    return HttpUtil.post("warn/delete", {idList: ids.join(HttpTS.ARRAY_FLAG)})
}


