import {Vue, Component, Prop} from 'vue-property-decorator'
import * as HttpTS from "@/type/system/HttpTS";
import * as HttpUtil from "@/util/HttpUtil";
import * as UITS from "@/type/system/UITS";
import * as CDictTS from "@/type/system/CDictTS";
import * as ObjUtil from "@/util/ObjUtil";

import * as DictTS from "@/type/system/SysDictTS";
import * as DictAPI from "@/api/system/DictAPI";
import DictEdit from "./components/DictEdit.vue";
@Component({
  components: {
    DictEdit
  }
})
export default class DictListView extends Vue {
  TYPE_KV_OBJ = DictTS.TYPE_KV_OBJ;
  // 禁用备选
  BAN_SEL = CDictTS.BAN_SEL;
  // 编辑页初始化类型
  INIT_TYPE = UITS.INIT_TYPE;
  // 查询条件
  reqObj: DictTS.SysDictListReq = new DictTS.SysDictListReq();
  // 响应结果
  mainData: DictTS.SysDictListRes = new DictTS.SysDictListRes;
  // 查询等待
  loading: UITS.Loading = new UITS.Loading();

  created() {
    this.onSearch();
  }

  // 搜索列表
  onSearch(page?: HttpTS.Page) {
    if (page && page instanceof HttpTS.Page) {
      Object.assign(this.reqObj, page);
    } else {
      Object.assign(this.reqObj, new HttpTS.Page());
    }
    this.loading.spinning = true;
    DictAPI.getList(this.reqObj).then((res) => {
      this.loading.spinning = false;
      if (HttpUtil.checkSucc(res)) {
        this.mainData = res;
      }
    });
  }

  // 打开编辑页
  onShowEdit(initType: UITS.INIT_TYPE, data?: DictTS.SysDictTS) {
    const _edit = this.$refs.DictEdit as any;
    _edit.init(initType, data);
  }
}