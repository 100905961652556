
import { Vue, Component } from "vue-property-decorator";
// import zhCN from 'ant-design-vue/es/locale/zh_CN';
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import moment from "moment";
import "moment/locale/zh-cn";

import NaviContainer from "@/components/NaviContainer.vue";
import LoginDialog from "@/components/global/LoginDialog.vue";

import * as LoginAPI from "@/api/system/LoginAPI";
import * as NaviTS from "@/type/system/NaviTS";
import * as LoginCtrl from "@/util/LoginCtrl";
import * as SessionUtil from "@/util/SessionUtil";

moment.locale("zh-CN");

@Component({
  components: {
    NaviContainer,
    LoginDialog,
  },
})
export default class App extends Vue {
  zhCN = zhCN;

  mounted() {
    this.onSysInit();
  }

  /**
   * 验证登录状态 如果后端有数据, 直接就存储, 如果没有 由登录窗口异步处理, 这里不管
   */
  onSysInit() {
    SessionUtil.removeAll();

    if (this.$route.name == NaviTS.ROUTE_NAME.LOGIN) {
      return;
    }

    LoginAPI.getUserInfo().then((res) => {
      if (res.isSucc) {
        LoginCtrl.setLoginInfo(res);
      }
    });
  }
}
