import {Vue, Component, Prop} from 'vue-property-decorator'
import * as LoginAPI from '@/api/system/LoginAPI'

import * as NaviTS from '@/type/system/NaviTS'
@Component({
  components: {

  }
})
export default class Logout extends Vue {

  async onLogout() {
    const sure = await new Promise(resolve => {
      this.$confirm({
        content: '是否退出登录?',
        okText: '退出登录',
        cancelText: '取消',
        onOk: () => {
          resolve(true)
        },
        onCancel: () => {
          resolve(false)
        }
      })
    })
    if (!sure) {
      return;
    }

    LoginAPI.logout().then(res => {
      // 刷新页面, 自动跳转到登录页面
      this.$router.replace({name: NaviTS.ROUTE_NAME.LOGIN})
      // 刷新一下, 清除数据
      window.location.reload()
    })

  } 
}