import { Vue, Component, Prop } from "vue-property-decorator";
import * as HttpTS from "@/type/system/HttpTS";
import * as HttpUtil from "@/util/HttpUtil";
import * as UITS from "@/type/system/UITS";

import DataSrcConfigEdit from "./components/DataSrcConfigEdit.vue";
import * as DataSrcConfigTS from "@/type/project/DataSrcConfigTS";
import * as DataSrcConfigAPI from "@/api/project/DataSrcConfigAPI";
import * as WarnCodeTS from '@/type/project/WarnCodeTS'
import * as SensorTS from "@/type/project/SensorTS";

@Component({
  components: {
    DataSrcConfigEdit,
  },
})
export default class DataSrcConfigListViews extends Vue {
  HANDLE_TYPE_SEL = WarnCodeTS.HANDLE_TYPE_SEL
  HANDLE_KV_OBJ = WarnCodeTS.HANDLE_KV_OBJ
  // 查询等待
  loading: UITS.Loading = new UITS.Loading();
  // 编辑页初始化类型
  INIT_TYPE = UITS.INIT_TYPE;
  // 查询条件
  reqObj: DataSrcConfigTS.DataSrcConfigListReq = new DataSrcConfigTS.DataSrcConfigListReq();
  // 响应结果
  mainData: DataSrcConfigTS.DataSrcConfigListRes = new DataSrcConfigTS.DataSrcConfigListRes;
  // 与数据源绑定的传感器列表 TODO
  sensorListObj: {[key: string]: SensorTS.SensorTS[]} = {}
  
  created() {
    this.onSearch();
  }

  // 搜索列表
  onSearch(page?: HttpTS.Page) {
    if (page && page instanceof HttpTS.Page) {
      Object.assign(this.reqObj, page);
    } else {
      Object.assign(this.reqObj, new HttpTS.Page());
    }
    this.loading.spinning = true;
    DataSrcConfigAPI.getList(this.reqObj).then((res) => {
      this.loading.spinning = false;
      if (HttpUtil.checkSucc(res)) {
        this.mainData = res;
      }
    });
  }

  // 换页
  onChangeTable(page: UITS.TablePage) {
    const _page = new HttpTS.Page();
    _page.currentPage = page.current;
    _page.pageSize = page.pageSize;
    this.onSearch(_page);
  }
  // 打开编辑页
  onShowEdit(initType: UITS.INIT_TYPE, data?: DataSrcConfigTS.DataSrcConfigTS) {
    const _edit = this.$refs.DataSrcConfigEdit as any;
    _edit.init(initType, data);
  }

  onReset() {
    this.reqObj = new DataSrcConfigTS.DataSrcConfigListReq();
    this.onSearch()
  }
}
