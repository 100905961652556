import {Vue, Component, Prop} from 'vue-property-decorator'

import { namespace } from "vuex-class";
import * as HttpUtil from "@/util/HttpUtil";
import * as CDictTS from '@/type/system/CDictTS'
import * as NumUtil from '@/util/NumUtil'
import * as HttpTS from "@/type/system/HttpTS";
import * as UITS from "@/type/system/UITS";
import * as ReSure from "@/components/global/ReSure";

import ShowLen from "@/components/normal/ShowLen.vue";

import * as DeviceAPI from '@/api/project/DevAPI'
import * as SensorAPI from "@/api/project/SensorAPI";
import * as DevSensorAPI from '@/api/project/DevSensorAPI'
import * as DeviceTS from '@/type/project/DeviceTS'
import * as FactoryTS from '@/type/project/FactoryTS'
import * as SysDictTS from '@/type/system/SysDictTS'
import * as DataSrcConfigAPI from '@/api/project/DataSrcConfigAPI'
import * as SubstanceTS from '@/type/project/SubstanceTS'
import * as SensorTS from '@/type/project/SensorTS'
import * as FactoryAPI from '@/api/project/FactoryAPI'
import * as SubstanceAPI from '@/api/project/SubstanceAPI'
import * as DeviceSensorTS from '@/type/project/DeviceSensorTS'
import * as DataSrcConfigTS from '@/type/project/DataSrcConfigTS'

const LoginStore = namespace("LoginStore")

@Component({
  components: {
    ShowLen
  }
})
export default class DevEdit extends Vue {

  @LoginStore.Getter("dict") dict: SysDictTS.SysDictTS[];

  get DEV_TYPE_SEL() {
    return this.dict.filter(item => item.type == SysDictTS.TYPE.DEVICE_DEV_TYPE)
  }
  // 保存回调
  @Prop() onChange?: Function;

  lengthFormat = NumUtil.lengthFormat
  lengthParse = NumUtil.lengthParse
  lengthStep = NumUtil.lengthStep
  massFormat = NumUtil.massFormat
  massParse = NumUtil.massParse
  massStep = NumUtil.massStep
  volumeFormat = NumUtil.volumeFormat
  volumeParse = NumUtil.volumeParse
  volumeStep = NumUtil.volumeStep
  pressureFormat = NumUtil.pressureFormat
  pressureParse = NumUtil.pressureParse
  pressureStep = NumUtil.pressureStep


  /** 已知的中车设备id */
  LNG_DEV_LIST = SensorTS.LNG_DEV_LIST;
  /** 初始化标识 */
  INIT_TYPE = UITS.INIT_TYPE;
  /** 表单校验规则 */
  FORM_RULE = UITS.FORM_RULE;
  /** 时间格式 */
  TIME = UITS.TIME;
  /** 是否显示编辑框 */
  show: boolean = false;
  /** 提交等待 */
  loading: UITS.Loading = new UITS.Loading("数据保存中...");
  /** 初始化参数 */
  initType: UITS.INIT_TYPE | null = null;
  // 设备移动类型
  MOVE_TYPE_SEL = DeviceTS.MOVE_TYPE_SEL;
  
  DATA_SRC_ENUM = DataSrcConfigTS.DATA_SRC_ENUM

  // 工厂备选
  factorySel: FactoryTS.FactoryTS[] = []
  // 介质备选
  substanceSel: SubstanceTS.SubstanceTS[] = []
  // 传感器备选
  sensorSel: SensorTS.SensorTS[] = []

  // 数据源备选列表
  dataSrcConfigSel: DataSrcConfigTS.DataSrcConfigTS[] = []
  /** 主要数据 */
  mainData: DeviceTS.DeviceTS = new DeviceTS.DeviceTS
  // 后台验证表单
  ruleObj: { [key: string]: string } = {};

  mounted() {
    this.getFactorySel()
    this.getSubstanceSel()
    this.getSensorSel()
    this.getDataSrcConfigSel()
  }

  getFactorySel() {
    const _req = new FactoryTS.FactoryListReq
    _req.type = FactoryTS.TYPE.DEVVICE_RECEIVE_ID
    _req.pageSize = 99999
    FactoryAPI.getList(_req).then(res => {
      this.factorySel = res.list || []
    })
  }

  getSensorSel() {
    DevSensorAPI.getLeisureList().then((res) => {
      if (res.isSucc) {
        this.sensorSel = res.list.filter(itrm => itrm.sensorType == SensorTS.SENSOR_DATA_TABLE_NAME.GEO)
      }
    });

  }

  getSubstanceSel() {
    const _req = new SubstanceTS.SubstanceListReq
    _req.pageSize = 99999
    SubstanceAPI.getList(_req).then(res => {
      this.substanceSel = res.list || []
    })
  }

    // 获取数据源列表
    getDataSrcConfigSel() {
      const _req = new DataSrcConfigTS.DataSrcConfigListReq()
      _req.pageSize = 99999
      _req.isDel = CDictTS.IS_BAN.N
      DataSrcConfigAPI.getList(_req).then(res => {
        this.dataSrcConfigSel = res.list || []
      })
    }

  public init(_initType: UITS.INIT_TYPE, _mainData?: DeviceTS.DeviceTS) {
    // 清空表单
    const _mainForm = this.$refs.mainForm as any;
    _mainForm && _mainForm.resetFields();

    // 显示组件
    this.show = true;
    // 调整loading
    this.loading.spinning = false;

    // 注入数据
    if (!_mainData) {
      _mainData = new DeviceTS.DeviceTS();
    } else {
      _mainData = {
        ..._mainData,
      };
    }
    this.mainData = _mainData;
    this.initType = _initType;
    this.getSubstanceSel()
  }

  async onSave() {
    const _mainForm = this.$refs.mainForm as any;

    // 浅拷贝, 避免影响调试
    const _mainData = { ...this.mainData }

    // 表单验证
    const _validSucc = await new Promise((resolve) => {
      _mainForm.validate((valid: boolean) => {
        resolve(valid);
      });
    });
    if (!_validSucc) {
      return;
    }

    // 二次确认
    const _sure = await ReSure.saveSure();
    if (!_sure) {
      return;
    }

    this.loading.spinning = true;

    // 中车设备写入
    if (this.mainData.uiMainDevType == this.DATA_SRC_ENUM.ZHONGCHE_LNG && this.initType == this.INIT_TYPE.ADD) {
      DeviceAPI.addListLNGSensor(_mainData).then((res) => {
        this.onSaveBack(res);
      })
      return;
    }

    // 气相色谱  TODO 补一个默认位置
    if (this.mainData.uiMainDevType == this.DATA_SRC_ENUM.CHROMATOGRAPH_DATA && this.initType == this.INIT_TYPE.ADD) {
      DeviceAPI.addChromaDev(_mainData, null).then((res) => {
        this.onSaveBack(res);
      })
      return;
    }

    // 真空设备
    if (this.mainData.uiMainDevType == this.DATA_SRC_ENUM.SIA_INNER && this.initType == this.INIT_TYPE.ADD) {
      DeviceAPI.addSiaDev(_mainData).then((res) => {
        this.onSaveBack(res);
      })
      return;
    }

    // 默认设备
    const devSen = new DeviceSensorTS.DeviceSensorTS
    switch (this.initType) {
      // 修改
      case this.INIT_TYPE.UPDATE:
        DeviceAPI.update(_mainData, _mainData.id).then((res) => {
          this.onSaveBack(res);
        });
        break;
      // 新增
      case this.INIT_TYPE.ADD:
        devSen.sensorCode = _mainData.tempGeoSensorCode
        devSen.itemName = '位置'
        devSen.sensorType = SensorTS.SENSOR_DATA_TABLE_NAME.GEO

        DeviceAPI.addOne(_mainData, devSen).then((res) => {
          this.onSaveBack(res);
        });
        break;
    }
  }

  onSaveBack(res: HttpTS.HttpRes) {
    const _mainForm = this.$refs.mainForm as any;
    // 唯一字段校验
    if (res.resCode == HttpTS.RESULT_CODE.ERROR_FORM) {
      const _data = res.data as HttpTS.FORM_ERROR;
      this.ruleObj[_data.field] = _data.msg;
      _mainForm.validate();
      return;
    }

    if (res.isSucc) {
      this.onChange && this.onChange();
      this.show = false;
    } else {
      this.loading.spinning = false;
    }
  }

  onClose() {
    ReSure.saveSure("是否放弃保存 ?", "放弃保存").then((res) => {
      if (res) {
        this.show = false;
      }
    });
  }

}