import * as HttpTS from '@/type/system/HttpTS'

export class PermListReq extends HttpTS.ListReq {
}

export class PermListRes extends HttpTS.ListRes<Perm> {
}

export class Perm {
    /** 权限码 */
	 code?: string;
	/** 权限描述 */
	 name?: string;
	/** 父权限数组 */
	 parent?: string;
}


