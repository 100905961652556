import {Vue, Component, Prop} from 'vue-property-decorator'

@Component({
  components: {

  }
})
export default class ShowLen extends Vue {
  @Prop({default: () => 32}) max!: number; 
  @Prop({default: () => 0}) len!: number; 
}