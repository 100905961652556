import { getVue, getRouteFn } from "./GlobalRuntimeUtil";
import * as HttpTS from "@/type/system/HttpTS";
import * as SessionUtil from "@/util/SessionUtil";
import MainWebSocket from '@/util/WebSocketUtil'

// 设置登录信息
export const setLoginInfo = (res: HttpTS.LoginRes) => {
  // 解除其他请求限制
  SessionUtil.resoluteHttpReq();

  const LoginStore = getVue()?.$store;
  LoginStore?.dispatch("LoginStore/setPerm", res.permList);
  LoginStore?.dispatch("LoginStore/setUserInfo", res.userInfo);
  LoginStore?.dispatch("LoginStore/setDict", res.dictList);

  // 调整前端路由 (目前只能追加 不能减少)
  const routeFn = getRouteFn();
  if (routeFn) {
    routeFn(res.permList || []);
  }

  // 开启websocket
  MainWebSocket.init()

  // 驱动刷新
  LoginStore?.dispatch("LoginStore/setRefreshLoginData", String(Math.random()));
};
