import {Vue, Component, Prop} from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import * as NaviType from '@/type/system/NaviTS'
import * as UserTS from '@/type/system/UserTS'

const LoginStore = namespace("LoginStore")
@Component({
  components: {

  }
})
export default class ErrorView extends Vue {
  
  @LoginStore.Getter("userInfo") userInfo!: UserTS.User

  code: string = '404';
  infoMap: {[key: string]: any} = {
    '404': {
      title: '没有这个页面!',
      btnText: '回到首页',
      url: NaviType.ROUTE_NAME.HOME
    },
    '403': {
      title: '暂无相关权限!',
      btnText: '去登录',
      url: NaviType.ROUTE_NAME.LOGIN
    }
  }

  mounted() {
    if (!this.userInfo) {
      this.code = '403'
    } else {
      this.code = '404'
    }
  }

  onGoPage(url: string) {
    this.$router.replace({name: url})
  }

}