import * as HttpTS from "@/type/system/HttpTS";
import * as SysDictTS from '@/type/system/SysDictTS';
import * as ObjUtil from '@/util/ObjUtil';
export class DataSrcConfigListReq extends HttpTS.ListReq {
  isDel: number | null = null;
  srcActionType: string | null = null;
}

export class DataSrcConfigListRes extends HttpTS.ListRes<DataSrcConfigTS> {}

export class DataSrcConfigTS { 

  /**  一个数据源可能关联多个传感器   前后端编码耦合 */
  id?: string;
  /** 数据源名称  */
  name?: string;
  /** 描述 */
  detail?: string;
  /** 是否删除 1 -是 0 -否  */
  isDel?: number;
  /** 创建人  [关联 = 用户]  */
  crUser?: string;
  /** 创建时间  */
  crTime?: number;
  /** 修改时间 1 */
  upTime?: number;
}


  // 耦合数据源数据
  export enum DATA_SRC_ENUM {
    /** 色谱仪数据 */
    CHROMATOGRAPH_DATA = "CHROMATOGRAPH_DATA",
    /** 中车储罐数据 */
    ZHONGCHE_LNG = "ZHONGCHE_LNG",
    /** 内部默认数据 */
    SIA_INNER = "SIA_INNER"
  }