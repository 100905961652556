import * as HttpUtil from '@/util/HttpUtil'
import * as HttpTS from '@/type/system/HttpTS'
import * as FactoryTS from '@/type/project/FactoryTS'

export const getList = (params: FactoryTS.FactoryListReq): Promise<FactoryTS.FactoryListRes> => {
    params = JSON.parse(JSON.stringify(params))
    return HttpUtil.get("factory/list", params)
}

export const addOne = (mainData: FactoryTS.FactoryTS): Promise<HttpTS.HttpRes> => {
    return HttpUtil.post("factory/add", {mainJson: JSON.stringify(mainData)})
}

export const update = (template: FactoryTS.FactoryTS, id?: number): Promise<HttpTS.HttpRes> => {
    return HttpUtil.post("factory/update", {mainJson: JSON.stringify(template), id})
}

export const deleteGroup = (ids: number[]): Promise<HttpTS.HttpRes> => {
    return HttpUtil.post("factory/delete", {idList: ids.join(HttpTS.ARRAY_FLAG)})
}


