import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import * as NaviTS from '@/type/system/NaviTS'
import { setRouteFn } from '@/util/GlobalRuntimeUtil'

import HomeView from '@/views/system/HomeView.vue'
import ErrorView from '@/views/system/ErrorView.vue'
import LoginView from '@/views/system/LoginView.vue'

import systemRoute from './systemRoute'
import devRoute from './devRoute'
import settingRoute from './settingRoute'
import sensorRoute from './sensorRoute'

Vue.use(VueRouter)

// 业务路由
const allRoutes: Array<RouteConfig> = [
  ...devRoute,
  ...sensorRoute,
  ...settingRoute,
  ...systemRoute,
];

// 基础路由
const baseRoutes: Array<RouteConfig> = [

  {
    path: '/',
    name: NaviTS.ROUTE_NAME.HOME,
    component: HomeView,
    meta: {
      title: NaviTS.ROUTE_SUPER_TITLE.HOME,
      icon: 'home',
      superTitle: NaviTS.ROUTE_SUPER_TITLE.HOME
    }
  },

  {
    path: '/login',
    name: NaviTS.ROUTE_NAME.LOGIN,
    component: LoginView,
    meta: {
      withOutNavi: true // 不需要navi
    }
  },

  {
    path: '*',
    name: 'error',
    component: ErrorView,
    meta: {
      withOutNavi: true // 不需要navi
    }
  },
];

/** 根据权限过滤路由 TODO [耦合] needPerm */
export const addPermRoute = (_perm: string[]) => {

  if (!_perm) {
    console.error("NO Permission!");
    return [];
  }

  const removeRouteByPerm = (curRoutes: RouteConfig[]) => {
    for (let i = 0; i < curRoutes.length; i++) {
      const _meta = curRoutes[i].meta

      if (_meta && _meta.needPerm && !_perm.includes(_meta.needPerm)) {
        // 同时也把其子页面删掉了!!! 
        curRoutes.splice(i, 1);
        i--;
        continue;
      }

      const _child = curRoutes[i].children;
      if (_child) {
        removeRouteByPerm(_child);
      }
    }
  }

  // 先执行过滤
  removeRouteByPerm(allRoutes);

  const hasRouteNameArr = router.getRoutes().map(item => item.name)

  // 过滤后动态加载路由
  for (let i = 0; i < allRoutes.length; i++) {
    if (hasRouteNameArr?.includes(allRoutes[i].name)) {
      continue;
    }
    router.addRoute(allRoutes[i])
  }
  return hasRouteNameArr
}

// 存起来
setRouteFn(addPermRoute)

// 生成路由对象
const router = new VueRouter({
  routes: baseRoutes
});

// 没有权限时跳转登录页
// router.beforeEach(async (to, from, next) => {

//   next();
// });

export default router