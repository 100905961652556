import SockJS from 'sockjs-client'
import Stomp from 'stompjs'

/** ws端点路径 */
const BASE_URL = "/sia-server/wsmain"

/** 订阅临时队列 */
export class SubscribeItem {
    /** 最终订阅的url */
    url: string
    /** 订阅主题 */
    topic: SUB_TOPIC
    /** 额外的负载数据 */
    data: string
    /** 监听回调函数 */
    listenceFunc: (message: Stomp.Message) => any
    
    constructor(topic: SUB_TOPIC, data: string, fn: (message: Stomp.Message) => any) {
        this.topic = topic
        this.data = data
        this.url = topic + data
        this.listenceFunc = fn
    }
}

/**
 * WebSocket 枚举类
 */
 export enum SUB_TOPIC {
    /** 订阅 人工识别报警消息 */
    NOTIFY_SENSOR_ALL = "/topic/notify/sensor/all",
    SENSOR_DATA_CHROMAT = "/topic/sensor/data/chromat"
}

/**
 * 主动发送消息
 */
export class SendItem {
    url?: string
    data: any
}

/**
 * 基于SockJS和STOMP
 */
 export default class MainWebSocket {

    /** 客户端对象 */
    private static stompClient: Stomp.Client | null = null;

    // 临时订阅队列
    private static subscribeTempQueue: SubscribeItem[] = []

    // 临时消息发送队列
    private static sendTempQueue: SendItem[] = []

    /** 已订阅的集合 key: SSUB_TOPIC中的一个值 */
    private static hasSubMap: {[key: string]: Stomp.Subscription} = {}

    constructor() {
        console.error("MainWebSocket +++++++++++++++error: place run init")
    }

    /**
     * WebSocket初始化 全局调用一次
     * @param token 
     */
    static async init(): Promise<boolean> {

        console.log("===============init", this.stompClient)
        if (this.stompClient) {
            return true;
        }

        const _stompClient = Stomp.over( new SockJS(BASE_URL, null, {
            //  timeout: 15000
        }))

        _stompClient.connect({}, (frame: any) => {
            console.info("MainWebSocket +++++++++++++++ succ: websocket client is connected", frame)
            // 设置对象
            this.stompClient = _stompClient

            // 处理订阅消息
            while(this.subscribeTempQueue.length) {
                const first = this.subscribeTempQueue.shift()
                if (first) {
                    if (!first.url) {
                        return
                    }
                    const subRes = this.stompClient.subscribe(first.url, first.listenceFunc)
                    // 如果以前有别的订阅 先给取消
                    if (this.hasSubMap[first.url]) {
                        this.hasSubMap[first.url].unsubscribe()
                    }
                    // 存订阅id 直接覆盖之前的
                    this.hasSubMap[first.url] = subRes
                }
            }

            // 处理主动发送
            while(this.sendTempQueue.length) {
                const first = this.sendTempQueue.shift()
                if (first) {
                    this.stompClient.send(first.url || '', {}, JSON.stringify(first.data))
                }
            }

        }, (error) => {
            // 失败回调
            console.error("MainWebSocket +++++++++++++++ error: websocket client init error", error)
            this.stompClient = null
        })
        return true;
    }

    // 添加订阅消息
    static subscribe(item: SubscribeItem) {
        if (this.stompClient && this.stompClient.connected) {
            const subRes = this.stompClient.subscribe(item.url, item.listenceFunc)
            // 如果以前有别的订阅 先给取消
            if (this.hasSubMap[item.url]) {
                this.hasSubMap[item.url].unsubscribe()
            }
            // 存订阅id 直接覆盖之前的
            this.hasSubMap[item.url] = subRes
        } else {
            this.subscribeTempQueue.push(item)
        }
    }

    /** 消息主动发送 */
    static send(item: SendItem) {
        if (this.stompClient && this.stompClient.connected) {
            this.stompClient.send(item.url, {}, JSON.stringify(item.data))
        } else {
            this.sendTempQueue.push(item)
        }
    }

    // 主动断开连接
    static disconnect() {

        // 过一遍取消订阅
        for (const key in this.hasSubMap) {
            if (this.hasSubMap[key]) {
                this.hasSubMap[key].unsubscribe()
            }
        }

        if (!this.stompClient) {
            console.error("MainWebSocket +++++++++++++++ error: websocket is null +++++++++++++++")
            return;
        }
        this.stompClient.disconnect(() => {
            console.info("MainWebSocket +++++++++++++++succ: websocket is disconnect succ +++++++++++++++")
            this.stompClient = null
        })
    }
}

