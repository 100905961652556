import { Vue, Component, Prop, Model } from "vue-property-decorator";

import * as UITS from "@/type/system/UITS";
import * as CDictTS from '@/type/system/CDictTS'
import * as ReSure from "@/components/global/ReSure";
import ShowLen from "@/components/normal/ShowLen.vue";

import * as SensorTS from "@/type/project/SensorTS";
import * as SensorDataGeoTS from '@/type/project/SensorDataGeoTS'
import * as SensorAPI from "@/api/project/SensorAPI";
import * as DataSrcConfigTS from '@/type/project/DataSrcConfigTS'
import * as DataSrcConfigAPI from '@/api/project/DataSrcConfigAPI'
import {MapRes} from '@/components/baidu/MapEditBaidu'
import MapEditBaidu from '@/components/baidu/MapEditBaidu.vue'


/**
 * 对应气相色谱数据源
 *  TODO  可以过 记得整理笔记
 */
@Component({
  components: {
    ShowLen,
    MapEditBaidu
  },
})
export default class SensorEditChromatography extends Vue {

  @Model("onchange") mainData: SensorTS.SensorTS;
  // 初始化标识
  @Prop() initType: UITS.INIT_TYPE

  /** 初始化标识 */
  INIT_TYPE = UITS.INIT_TYPE;
  /** 表单验证规则 */
  FORM_RULE = UITS.FORM_RULE;
  /** 综合下拉选 选定检测指标后别的值都带过来 */
  CHROMA_TYPE_SEL = SensorTS.CHROMA_TYPE_SEL;
  /** 是否显示编辑框 */
  show: boolean = false;
  /** 提交等待 */
  loading: UITS.Loading = new UITS.Loading("数据保存中...");

  mounted() {
  }    

  // 检测指标变更事件
  onChangeTypeSel(value: SensorTS.CHROMA_DATA_TYPE) {
    if (value) {
      Object.assign(this.mainData, this.CHROMA_TYPE_SEL[value])
    }
  }

  /** 表单验证 */
  public async formValidate() {
    const _mainForm = this.$refs.mainForm as any;
    return new Promise((resolve) => {
      _mainForm.validate((valid: boolean) => {
        resolve(valid);
      });
    });
  }

  /** 取消 */
  public onClean() {
    const _mainForm = this.$refs.mainForm as any;
    _mainForm.resetFields()
  }

}
