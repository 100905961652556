import * as HttpTS from "@/type/system/HttpTS";
import * as PrincipleTS from '@/type/project/PrincipleTS'
import * as DeviceSensorTS from '@/type/project/DeviceSensorTS'
export enum WARN_LEVEL {
  WARN = "WARN",
  DANGER = "DANGER"
}
export const warnLevelObj = {
  [WARN_LEVEL.WARN]: "警告",
  [WARN_LEVEL.DANGER]: "危险",
}
export class WarnListReq extends HttpTS.ListReq {
    sensorCode: number | null = null
    deviceId: number | null = null
    crTimeStart?: number =  new Date().getTime() - 24 * 3600 * 1000;
    crTimeEnd?: number = new Date().getTime()
    // 报警等级序列化
    warnLevelListJson: string
    // 报警状态序列化
    stateListJson: string

    /** 前端缓存 */
    // deviceSensorId: string
    warnLevelList: string[]
    stateList: number[]
}

export class WarnListRes extends HttpTS.ListRes<WarnMsgTS> {}

export class WarnMsgTS {
  /** 主键 [关联键] */
  id?: number;
  /** 本系统传感器ID */
  sensorCode?: string;
  /** 本系统设备ID */
  deviceId?: number;
  
  /** 记录数值 */
  value?: number;
  maxValue?: number;
  /** 错误码 */
  code?: string;

  /** 附加消息 */
  unit?: string;
  /** 报警状态 [关联字典表] 新增/已处理 不需处理/已通知责任人/处理中 */
  state?: number;
  /** warn/danger */
  warnLevel?: WARN_LEVEL;
  /** 创建时间 */
  crTime?: number;
  /** 责任人列表 */
  principleList?: PrincipleTS.PrincipleTS[];
  /** 关联的监控项 */
  deviceSensorObj?: DeviceSensorTS.DeviceSensorTS;
}
