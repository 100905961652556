import { Vue, Component, Prop, Model } from "vue-property-decorator";

import * as UITS from "@/type/system/UITS";
import * as HttpTS from '@/type/system/HttpTS'

import * as SensorTS from "@/type/project/SensorTS";
import ShowLen from "@/components/normal/ShowLen.vue";
import MapEditBaidu from '@/components/baidu/MapEditBaidu.vue'
@Component({
  components: {
    ShowLen,
    MapEditBaidu
  },
})
export default class SensorEditLNG extends Vue {
  
  @Model("onchange") mainData: SensorTS.SensorTS;
  // 初始化标识
  @Prop() initType: UITS.INIT_TYPE
  /** 初始化标识 */
  INIT_TYPE = UITS.INIT_TYPE;
  /** 已知的中车设备id */
  LNG_DEV_LIST = SensorTS.LNG_DEV_LIST;
  
  /** 表单验证规则 */
  FORM_RULE = UITS.FORM_RULE;
  /** 中车数据综合下拉选 */
  LNG_TYPE_SEL = SensorTS.LNG_TYPE_SEL;

  /** 是否显示编辑框 */
  show: boolean = false;
  /** 提交等待 */
  loading: UITS.Loading = new UITS.Loading("数据保存中...");

  mounted() {
  }    

  onChangeTypeSel(value: SensorTS.LNG_DATA_TYPE) {
    if (value) {
      Object.assign(this.mainData, this.LNG_TYPE_SEL[value])
      // 处理code
      this.onMakeCode()
    }
  }
  
  onMakeCode() {
    if (this.mainData.LNGDeviceId && this.mainData.LNGType) {
      this.mainData.sensorCode = this.mainData.LNGDeviceId + HttpTS.MID_LINE + this.mainData.LNGType
    }
  }

  /** 表单验证 */
  public async formValidate() {
    const _mainForm = this.$refs.mainForm as any;
    return new Promise((resolve) => {
      _mainForm.validate((valid: boolean) => {
        resolve(valid);
      });
    });
  }

  /** 取消 */
  public onClean() {
    const _mainForm = this.$refs.mainForm as any;
    _mainForm.resetFields()
  }

}
