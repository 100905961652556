import * as HttpTS from "@/type/system/HttpTS";
import * as ObjUtil from '@/util/ObjUtil';
import * as SysDictTS from '@/type/system/SysDictTS'

export class WarnCodeListReq extends HttpTS.ListReq {
  dataSrcId: string | null = null
}

export class WarnCodeListRes extends HttpTS.ListRes<WarnCodeTS> {}

export class WarnCodeTS {
  /** 错误代码 非重 考虑多源互斥问题 主键 [关联键] */
  code?: string;
  /** 获取方式 处理逻辑 耦合编码     geo  vacuum  等 */
  dataSrcId?: string;
  /** 错误名称 */
  name?: string;
  /** 错误描述 */
  title?: string;
  /** 创建人  [关联 = 用户] */
  crUser?: string;
  /** 创建时间 */
  crTime?: number;
  /** 修改时间 */
  upTime?: number;
}

// TODO 整体处理
export enum HANDLE_TYPE {
  GEO = 'geo', // 坐标传感器
  T = 'T', // 温度
  PRESSURE = 'pressure',  // 压力
  VACUUM = 'vacuum', // 真空度
  ELECTRIC = 'electric', // 电池电量
  CONTENT_GAUGE = 'contentGauge' // 液位
}


export const HANDLE_TYPE_SEL: SysDictTS.SysDictTS[] = [
  {key: HANDLE_TYPE.GEO, value: '定位仪'},
  {key: HANDLE_TYPE.T, value: '温度计'},
  {key: HANDLE_TYPE.PRESSURE, value: '压力表'},
  {key: HANDLE_TYPE.VACUUM, value: '真空表'},
  {key: HANDLE_TYPE.ELECTRIC, value: '电池电量'},
  {key: HANDLE_TYPE.CONTENT_GAUGE, value: '液位计'},
]

export const HANDLE_KV_OBJ = ObjUtil.getKVObj(HANDLE_TYPE_SEL)