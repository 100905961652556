
import { RouteConfig } from 'vue-router'
import * as NaviTS from '@/type/system/NaviTS'
import PermListView from '@/views/system/PermListView.vue'
import RoleListView from '@/views/system/RoleListView.vue'
import UserListView from '@/views/system/UserListView.vue'
import DictListView from '@/views/system/DictListView.vue'

const routes: Array<RouteConfig> = [
  // {
  //   path: '/perm-list',
  //   name: 'permList',
  //   component: PermListView,
  //   meta: {
  //     superTitle: NaviTS.ROUTE_SUPER_TITLE.SYSTEM,
  //     title: '网站权限',
  //     icon: 'safety-certificate'
  //   }
  // },
  {
    path: '/role-list',
    name: 'roleList',
    component: RoleListView,
    meta: {
      superTitle: NaviTS.ROUTE_SUPER_TITLE.SYSTEM,
      title: '网站角色',
      icon: 'user-add'
    }
  },
  {
    path: '/user-list',
    name: 'userList',
    component: UserListView,
    meta: {
      superTitle: NaviTS.ROUTE_SUPER_TITLE.SYSTEM,
      title: '网站用户',
      icon: 'team'
    }
  },  
  {
    path: '/dict-list',
    name: 'dictList',
    component: DictListView,
    meta: {
      superTitle: NaviTS.ROUTE_SUPER_TITLE.SYSTEM,
      title: '字典表管理',
      icon: 'snippets'
    }
  },
]
export default routes;
