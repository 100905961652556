import * as HttpUtil from '@/util/HttpUtil'
import * as HttpTS from '@/type/system/HttpTS'
import * as DeviceUIConfigTS from '@/type/project/DeviceUIConfigTS'


export const addOne = (mainData: DeviceUIConfigTS.DeviceUIConfigTS): Promise<HttpTS.HttpRes> => {

    const submitData = JSON.parse(JSON.stringify(mainData)) as DeviceUIConfigTS.DeviceUIConfigTS

    if (submitData.chartOption) {
        submitData.chartOption = JSON.stringify(submitData.chartOption) as any
    }

    return HttpUtil.post("deviceui/add", {mainJson: JSON.stringify(submitData)})
}

export const update = (template: DeviceUIConfigTS.DeviceUIConfigTS, id: number): Promise<HttpTS.HttpRes> => {

    const submitData = JSON.parse(JSON.stringify(template)) as DeviceUIConfigTS.DeviceUIConfigTS

    if (submitData.chartOption) {
        submitData.chartOption = JSON.stringify(submitData.chartOption) as any
    }

    return HttpUtil.post("deviceui/update", {mainJson: JSON.stringify(submitData), id})
}

export const deleteGroup = (ids: number[]): Promise<HttpTS.HttpRes> => {
    return HttpUtil.post("deviceui/delete", {idList: ids.join(HttpTS.ARRAY_FLAG)})
}


