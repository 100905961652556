import {Modal} from 'ant-design-vue'

export const saveSure = async (msg?: string, okText?: string): Promise<boolean> => {
    if (!msg) {
        msg = "是否保存内容 ?"
    }

    if (!okText) {
        okText = "确定保存"
    }

    return new Promise(resolve => {
        Modal.confirm({
            content: msg,
            okText: okText,
            class: `def_modal_confirm ${okText?.indexOf('删除') != -1? 'del' : ''}`,
            title: "提示",
            cancelText: "取消",
            onCancel: () => {
                resolve(false)
            },
            onOk: () => {
                resolve(true)
            }
        })
    })
}