import { Vue, Component, Prop } from "vue-property-decorator";
import moment from "moment";

import * as UITS from "@/type/system/UITS";
import * as HttpTS from "@/type/system/HttpTS";
import * as ReSure from "@/components/global/ReSure";
import * as HttpUtil from "@/util/HttpUtil";
import ShowLen from "@/components/normal/ShowLen.vue";

import * as UserTS from "@/type/system/UserTS";
import * as RoleTS from "@/type/system/RoleTS";
import * as UserAPI from "@/api/system/UserAPI";
import * as RoleAPI from "@/api/system/RoleAPI";

@Component({
  components: {
    ShowLen,
  },
})
export default class UserEdit extends Vue {
  @Prop() onChange?: Function;
  moment = moment;
  /** 初始化标识 */
  INIT_TYPE = UITS.INIT_TYPE;
  /** 表单校验规则 */
  FORM_RULE = UITS.FORM_RULE;
  /** 时间格式 */
  TIME = UITS.TIME;
  /** 是否显示编辑框 */
  show: boolean = false;
  /** 提交等待 */
  loading: UITS.Loading = new UITS.Loading("数据保存中...");
  /** 初始化参数 */
  initType: UITS.INIT_TYPE | '--' = '--';
  /** 主要数据 */
  mainData: UserTS.User = new UserTS.User();
  /** 角色备选列表 由当前用户获取经过校验的角色列表 TODO 如果编辑用户的角色没在列表里 则不允许编辑该项目 */
  roleSel: RoleTS.Role[] = [];

  ruleObj: {
    [key: string]: any;
  } = {
    username: "",
  };
  created() {
    this.getRoleSel();
  }

  init(_initType: UITS.INIT_TYPE, _mainData?: UserTS.User) {
    // 清空表单
    const _mainForm = this.$refs.mainForm as any;
    _mainForm && _mainForm.resetFields();

    // 显示组件
    this.show = true;
    // 调整loading
    this.loading.spinning = false;

    // 注入数据
    if (!_mainData) {
      _mainData = new UserTS.User();
    } else {
      _mainData = {
        ..._mainData,
      };
    }
    this.mainData = _mainData;
    this.initType = _initType;
  }

  getRoleSel() {
    const roleReq = new RoleTS.RoleListReq();
    roleReq.pageSize = 9999;
    RoleAPI.getList(roleReq).then((res) => {
      if (HttpUtil.checkSucc(res)) {
        this.roleSel = res.list || [];
      }
    });
  }

  async onSave() {
    const _mainForm = this.$refs.mainForm as any;

    // 浅拷贝, 避免影响调试
    const _mainData = { ...this.mainData };
    // 表单验证
    const _validSucc = await new Promise((resolve) => {
      _mainForm.validate((valid: boolean) => {
        resolve(valid);
      });
    });
    if (!_validSucc) {
      return;
    }

    // 二次确认
    const _sure = await ReSure.saveSure();
    if (!_sure) {
      return;
    }

    this.loading.spinning = true;

    switch (this.initType) {
      // 修改
      case this.INIT_TYPE.UPDATE:
        UserAPI.updateGroup(_mainData, [_mainData.id!]).then((res) => {
          this.onChange && this.onChange();
          this.show = false;
        });
        break;
      // 新增
      case this.INIT_TYPE.ADD:
        UserAPI.addOne(_mainData).then((res) => {
          if (res.resCode == HttpTS.RESULT_CODE.ERROR_FORM) {
            const _data = res.data as HttpTS.FORM_ERROR;
            this.ruleObj[_data.field] = _data.msg;
            _mainForm.validate();
            return;
          }
          this.onChange && this.onChange();
          this.show = false;
        });
        break;
    }
  }

  onClose() {
    ReSure.saveSure("是否放弃保存 ?", "放弃保存").then((res) => {
      if (res) {
        this.show = false;
      }
    });
  }
}
