
import { RouteConfig } from 'vue-router'
import * as NaviTS from '@/type/system/NaviTS'
import SensorListView from '@/views/sensor/SensorListView.vue'

import DataSrcConfigListView from '@/views/sensor/DataSrcConfigListView.vue'
const routes: Array<RouteConfig> = [
  {
    path: '/sensor-list',
    name: 'sensorList',
    component: SensorListView,
    meta: {
      superTitle: NaviTS.ROUTE_SUPER_TITLE.SETTING,
      title: '传感器管理',
      icon: 'safety-certificate'
    }
  },
  {
    path: '/datasrc-config-list',
    name: 'datasrcConfigList',
    component: DataSrcConfigListView,
    meta: {
      superTitle: NaviTS.ROUTE_SUPER_TITLE.SETTING,
      title: '数据源配置管理',
      icon: 'safety-certificate'
    }
  },
]
export default routes;
