import { Vue, Component, Prop } from "vue-property-decorator";
import moment from 'moment'
import * as UITS from "@/type/system/UITS";
import * as HttpUtil from '@/util/HttpUtil'
import * as DevTS from "@/type/project/DeviceTS";
import * as SensorDataGeoTS from "@/type/project/SensorDataGeoTS";
import * as DevAPI from "@/api/project/DevAPI";
import * as SensorHistoryAPI from '@/api/project/SensorHistoryAPI'
import * as SensorTS from '@/type/project/SensorTS'
import * as DeviceSensorTS from '@/type/project/DeviceSensorTS'
import NaviDetail from "./component/NaviDetail.vue";
import DevEdit from "./component/DevEdit.vue";
import DevSensor from "./component/DevSensor.vue";

const LIST_ROUTE = "home";

@Component({
  components: {
    NaviDetail,
    DevEdit,
    DevSensor,
  },
})
export default class DDMap extends Vue {

  // 传入 路由参数
  detailId = this.$route.query.id as string;

  /** 地图实例对象 */
  myBMap: any = null;
  // 地图参数
  mapOption: UITS.MapOption = new UITS.MapOption(12);
  
  // 地图加载动画
  mapLoading = new UITS.Loading("地图加载中...");
  
  // 查询等待
  loading: UITS.Loading = new UITS.Loading("数据加载中...");
  // 主要数据
  mainData: DevTS.DeviceTS = new DevTS.DeviceTS();
  // 轨迹查询条件
  sensorHistoryReq = null;
  // 设备地图坐标点的数组, 如果坐标为0 则不加到数组中
  devPointArr: SensorDataGeoTS.SensorDataGeoTS[] = [];

  get mapLine() {
    if (this.devPointArr.length >= 2) {
      return this.devPointArr.map(item => ({
        lng: item.lng,
        lat: item.lat
      }))
    }
    return []
  }

  created() {
    // TODO 获取数据 初始化页面
    this.getMainData();
  }

  getMainData() {
    this.loading.spinning = true;
    DevAPI.detail(this.detailId, [DevTS.DEV_DETAIL_ADDINFO.SENSOR_DATA_GEO]).then(
      (res) => {
        this.loading.spinning = false;
        this.mainData = res.data;

        this.getSensorGeoList(res.list)
      }
    );
  }

  /**
   * 获取传感器数据
   */
  getSensorGeoList(sensorList: DeviceSensorTS.DeviceSensorTS[]) {
    // 如果有关联的传感器 进一步获取传感器历史数据 这里只获取位置数据 别的不要
    if (sensorList && sensorList.length) {

      const geoSensor = sensorList.find(item => item.sensorType == SensorTS.SENSOR_DATA_TABLE_NAME.GEO)
      if (!geoSensor) {
        console.warn("no geo sensor")
        return ;
      }

      this.loading.spinning = true;
      const _historyReq = new SensorHistoryAPI.SensorHistoryListReq
      _historyReq.sensorType = geoSensor.sensorType
      _historyReq.sensorCode = geoSensor.sensorCode
      _historyReq.currentPage = 1
      _historyReq.pageSize = 100
      _historyReq.timeStart = 0
      SensorHistoryAPI.getList(_historyReq).then(res => {
        this.loading.spinning = false;
        if (HttpUtil.checkSucc(res)) {
          this.devPointArr = res.list as any || []
          this.onMapRefresh()
        }
      })
      this.sensorHistoryReq = _historyReq
    }
  }

  // 后退键
  onBackList() {
    if (this.$route.name != LIST_ROUTE) {
      this.$router.replace({
        name: LIST_ROUTE,
      });
    }
  }

  // 打开编辑页
  onShowEdit() {
    const _edit = this.$refs.DevEdit as any;
    _edit.init(UITS.INIT_TYPE.UPDATE, this.mainData);
  }

  // 传感器配置 TODO  无权限的 给 detail
  onShowDevSensor() {
    const _edit = this.$refs.DevSensor as any;
    _edit.init(UITS.INIT_TYPE.UPDATE, this.mainData);
  }

  // 地图初始化
  onMapReady(event: any) {
    this.mapLoading.spinning = false;
    // 地图函数库对象
    this.myBMap = event.BMap as any;
  }

  // 地图缩放
  onMapChangeZoom(event: any) {
    const _zoom = event.target.getZoom();
    if (_zoom) {
      this.mapOption.zoom = _zoom;
    }
  }

  // 刷新地图中心点和缩放比例
  onMapRefresh() {
    if (this.devPointArr.length) {
      const _lat = this.devPointArr[0].lat
      const _lng = this.devPointArr[0].lng
      if (_lat && _lng) {

        Object.assign(this.mapOption, {position: {
          lat: _lat,
          lng: _lng
        }});
      }
    }
  }
}
