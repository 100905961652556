import { Vue, Component, Prop } from "vue-property-decorator";

import * as DeviceUIConfigAPI from "@/api/project/DeviceUIConfigAPI";

import * as HttpTS from "@/type/system/HttpTS";
import * as UITS from "@/type/system/UITS";
import * as DevSensorTS from "@/type/project/DeviceSensorTS";
import * as DeviceUIConfigTS from "@/type/project/DeviceUIConfigTS";
import * as DeviceTS from "@/type/project/DeviceTS";

import * as ReSure from "@/components/global/ReSure";

import ShowLen from "@/components/normal/ShowLen.vue";
/**
 * 趋势页面配置框
 */
@Component({
  components: {ShowLen},
})
export default class ChartConfig extends Vue {
  @Prop() deviceSensorList: DevSensorTS.DeviceSensorTS[];
  @Prop() onChange: Function;
  @Prop() devInfo: DeviceTS.DeviceTS;
  // 初始化类型 新增/编辑
  INIT_TYPE = UITS.INIT_TYPE;
  FORM_RULE = UITS.FORM_RULE
  // 显示配置框
  show: boolean = false;
  initType: UITS.INIT_TYPE = null;
  mainData: DeviceUIConfigTS.DeviceUIConfigTS =
    new DeviceUIConfigTS.DeviceUIConfigTS();




  onOpenConfigModal(
    initType: UITS.INIT_TYPE,
    _data?: DeviceUIConfigTS.DeviceUIConfigTS
  ) {
    // 设置初始化标识
    this.initType = initType;
    if (!_data) {
      _data = new DeviceUIConfigTS.DeviceUIConfigTS();
    } else {
      // 反序列化传感器数据
      this.$set(_data, "sensorIdArr", _data.sensorCode.split(HttpTS.ARRAY_FLAG))
    }
    this.mainData = _data;
    // 初始化表单
    const mainForm = this.$refs.mainForm as any;
    if (mainForm) {
      mainForm.clearValidate();
    }
    // 显示modal
    this.show = true;
  }

  async onSubmit() {
    const mainForm = this.$refs.mainForm as any;
    // 表单验证
    const _validSucc = await new Promise((resolve) => {
      mainForm.validate((valid: boolean) => {
        resolve(valid);
      });
    });
    if (!_validSucc) {
      return;
    }
    // 整理数据
    this.mainData.deviceId = this.devInfo.id;
    this.mainData.sensorCode = this.mainData.sensorIdArr.join(HttpTS.ARRAY_FLAG);
    if (this.initType == this.INIT_TYPE.UPDATE) {
      await DeviceUIConfigAPI.update(this.mainData, this.mainData.id);
    } else {
      await DeviceUIConfigAPI.addOne(this.mainData);
    }
    this.show = false;
    if (this.onChange) {
      this.onChange();
    }
  }
  async onDelete() {
    // 二次确认
    const _sure = await ReSure.saveSure("是否确定删除?", "删除");
    if (!_sure) {
      return;
    }

    await DeviceUIConfigAPI.deleteGroup([this.mainData.id])

    this.show = false;
    if (this.onChange) {
      this.onChange();
    }
  }
}
