import { message } from "ant-design-vue";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { Frame } from "stompjs";
import WebSocketUtil, { SubscribeItem, SUB_TOPIC } from "@/util/WebSocketUtil";
import * as StrUtil from '@/util/StrUtil'
import Moment from "moment"
import * as WarnMsgTS from '@/type/project/WarnMsgTS'
import * as DeviceSensorTS from "@/type/project/DeviceSensorTS";
import * as UIType from '@/type/system/UITS'

enum NOTIFY_TYPE {
  SENSOR_WARN = "SENSOR_WARN"
}

class NotifyMsg extends WarnMsgTS.WarnMsgTS {
  isRead: boolean;
  title: string = '消息提醒';
}

/**
 * 全局报警通知组件
 */
@Component({
  components: {
  },
})
export default class NotifyIndex extends Vue {

  TIME = UIType.TIME
  Moment = Moment
  warnLevelObj = WarnMsgTS.warnLevelObj
  // 是否显示消息抽屉
  show: boolean = false;
  // 消息列表
  msgList: NotifyMsg[] = []
  // 获取未读消息数
  get unReadCount() {
    return this.msgList.filter(item => !item.isRead).length;
  }

  mounted() {
    //  开始订阅消息
    this.subscribeMessage()
  }
  subscribeMessage(){
    WebSocketUtil.subscribe(
      new SubscribeItem(
        SUB_TOPIC.NOTIFY_SENSOR_ALL,
        "",
        (frame: Frame) => {
          console.log("==============message back ", frame)
          // 制作消息
          const msg = JSON.parse(frame.body) as NotifyMsg

          if (!msg) {
            console.error("no message body !", frame)
            return;
          }

          msg.isRead = false
          if (!msg.deviceSensorObj) {
            msg.deviceSensorObj = new DeviceSensorTS.DeviceSensorTS
          }
          msg.title = "传感器报警 -- " + msg.deviceSensorObj.itemName

          // 加入消息队列
          this.msgList.unshift(msg)
          this.$nextTick(() => {
            // 显示通知
            const notifyConfig = {
              duration: null,
              message: msg.title,
              description: `
                发生时间: ${Moment(msg.crTime).format(UIType.TIME.SEC)}; 
                显示值: ${msg.value}${msg.unit};
                级别: ${WarnMsgTS.warnLevelObj[msg.warnLevel]}
              `
            }
            if (msg.warnLevel == WarnMsgTS.WARN_LEVEL.DANGER) {
              this.$notification.error(notifyConfig)
            } else {
              this.$notification.warn(notifyConfig)
            }

          })
        }
      )
    )
  }

  onClearRead() {
    this.msgList.forEach(item => {
      item.isRead = true;
    })
  }

}
