import {Vue, Component, Prop} from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import * as UserTS from '@/type/system/UserTS'

const LoginStore = namespace("LoginStore")

@Component({
  components: {}
})
export default class UserCenter extends Vue {

  @LoginStore.Getter("userInfo") userInfo!: UserTS.User

}