import {
  Vue,
  Component,
  Prop
} from 'vue-property-decorator'

import * as HttpTS from '@/type/system/HttpTS'
import * as RoleTS from '@/type/system/RoleTS'
import * as UITS from '@/type/system/UITS'

import * as RoleAPI from '@/api/system/RoleAPI'
import * as HttpUtil from '@/util/HttpUtil'
import * as ReSure from '@/components/global/ReSure'

import RoleEdit from './components/RoleEdit.vue'
@Component({
  components: {
    RoleEdit
  }
})
export default class RoleListView extends Vue {
  // 列表查询条件
  reqObj: RoleTS.RoleListReq = new RoleTS.RoleListReq()
  // 主要数据结果
  mainData: RoleTS.RoleListRes = {
    page: new HttpTS.Page()
  }
  // 表格已选
  tableChoosed: (string|number)[] = []
  // 等待动画
  loading: UITS.Loading = new UITS.Loading()
  // 初始化参数
  INIT_TYPE =  UITS.INIT_TYPE
  
  created() {
    this.onSearch()
  }

  // 搜索列表
  onSearch(page ? : HttpTS.Page) {
    if (page && page instanceof HttpTS.Page) {
      Object.assign(this.reqObj, page)
    } else {
      Object.assign(this.reqObj, new HttpTS.Page())
    }
    this.loading.spinning = true
    RoleAPI.getList(this.reqObj).then(res => {
      this.loading.spinning = false
      if (HttpUtil.checkSucc(res)) {
        this.mainData = res
      }
    })
  }

  // 重置
  onReset() {
    this.reqObj = new RoleTS.RoleListReq()
    this.onSearch()
  }

  // 换页
  onChangeTable(page: UITS.TablePage) {
    const _page = new HttpTS.Page()
    _page.currentPage = page.current
    _page.pageSize = page.pageSize
    this.onSearch(_page)
  }

  // 打开编辑页
  onShowEdit(initType: UITS.INIT_TYPE, data ? : RoleTS.Role) {
    const _edit = this.$refs.RoleEdit as any
    _edit.init(initType, data)
  }

  async onDelete(ids: (string | number)[]) {
    if (!ids) {
      return;
    }
    const _sure = await ReSure.saveSure('是否确定删除?', '确定删除')
    if (!_sure) {
      console.warn("sure do false")
      return;
    }
    RoleAPI.deleteGroup(ids).then(res => {
      const succ = HttpUtil.checkSucc(res)
      if (succ) {
        this.onSearch()
      }
    })
  }

  // onResizeQueryArea() {
  //   const erd = Resize();
  //   erd.listenTo(this.$refs.queryArea as HTMLElement, (elm) => {
  //     const inArea = this.$refs.queryAreaIn as HTMLElement
  //     const inHeight = inArea.offsetHeight;
  //     if (inHeight > DEF_HEIGHT) {
  //       this.queryArea.showBtn = true;
  //     } else {
  //       this.queryArea.showBtn = false;
  //     }
  //   })
  // }



}