import * as SysDictTS from './SysDictTS'

export enum IS_BAN {
  Y = 1,
  N = 0
}

export const BAN_SEL: SysDictTS.SysDictTS[] = [
  {key: IS_BAN.Y, value: '已禁用'},
  {key: IS_BAN.N, value: '正常'},
]

export enum SEX {
  MAN = 1,
  WOMAN = 2
}

export const SEX_KV_OBJ = {
  [SEX.MAN]: '男',
  [SEX.WOMAN]: '女'
}

