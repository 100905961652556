var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"DDWatchHistory"}},[_c('a-page-header',{attrs:{"title":`监控历史 / ${
      (_vm.curDevSensorInfo && _vm.curDevSensorInfo.itemName) || '--'
    }`},on:{"back":_vm.onBackList}}),_c('a-form-model',{staticClass:"gl_list_search",attrs:{"layout":"inline"}},[(_vm.historyReq)?_c('a-form-model-item',[_c('a-range-picker',{staticClass:"def_picker form_item_main",attrs:{"placeholder":['请选择开始时间', '请选择结束时间']},model:{value:(_vm.historyReq.timeRangeUI),callback:function ($$v) {_vm.$set(_vm.historyReq, "timeRangeUI", $$v)},expression:"historyReq.timeRangeUI"}})],1):_vm._e(),_c('a-form-model-item',[_c('a-button',{staticClass:"u_mr_16",attrs:{"type":"primary","icon":"reload"},on:{"click":_vm.onSearch}},[_vm._v(" 搜索 ")])],1)],1),_c('div',{staticClass:"gl_table_out"},[_c('a-table',{attrs:{"row-key":"id","size":"small","pagination":{
        current: _vm.mainData.page.currentPage,
        pageSize: _vm.mainData.page.pageSize,
        total: _vm.mainData.page.count,
      },"data-source":_vm.mainData.list,"loading":_vm.loading.spinning},on:{"change":_vm.onChangeTable}},[_c('a-table-column',{attrs:{"title":"监控值","ellipsis":true},scopedSlots:_vm._u([{key:"default",fn:function(record){return [_c('a-tooltip',{attrs:{"placement":"bottomLeft"}},[_c('template',{slot:"title"},[_vm._v(" "+_vm._s(_vm.onGetValue(record))+" ")]),_vm._v(" "+_vm._s(_vm.onGetValue(record))+" ")],2)]}}])}),(_vm.curDevSensorInfo.sensorType != _vm.SENSOR_DATA_TABLE_NAME.GEO && _vm.curDevSensorInfo.sensorType != _vm.SENSOR_DATA_TABLE_NAME.CODE)?_c('a-table-column',{attrs:{"data-index":"unit","title":"单位","ellipsis":true},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('a-tooltip',{attrs:{"placement":"bottomLeft"}},[_c('template',{slot:"title"},[_vm._v(" "+_vm._s(text || "--")+" ")]),_vm._v(" "+_vm._s(text || "--")+" ")],2)]}}],null,false,3356353841)}):_vm._e(),_c('a-table-column',{attrs:{"data-index":"crTime","title":"数据上传时间","ellipsis":true},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('a-tooltip',{attrs:{"placement":"bottomLeft"}},[_c('template',{slot:"title"},[_vm._v(" "+_vm._s(_vm.moment(text).format(_vm.TIME.NO_SEC))+" ")]),_vm._v(" "+_vm._s(_vm.moment(text).format(_vm.TIME.NO_SEC))+" ")],2)]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }