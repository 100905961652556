import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import Moment from "moment";
import Numeral from "numeral";
import * as Echarts from "echarts";

import * as DevSensorTS from "@/type/project/DeviceSensorTS";
import * as UITS from "@/type/system/UITS";
import * as SensorTS from "@/type/project/SensorTS";
import * as SensorHistoryAPI from "@/api/project/SensorHistoryAPI";
import * as DataSrcConfigTS from '@/type/project/DataSrcConfigTS'

import {SensorData} from './ChartSmall'

/**
 * 趋势统计图 大图 
 */
@Component({
  components: {},
})
export default class ChartBig extends Vue {

  // 监控数据
  @Prop() mainData!: any;
  // 是否显示
  @Prop() show: boolean;

  @Prop() title: string;

  @Prop() onCloseBigBFn: Function;
  @Prop() chartOption: any;

  chartObj: Echarts.ECharts = null;

  initChart() {
    const _dom = this.$refs.chartOut as HTMLElement;
    this.chartObj = Echarts.init(_dom);
    // 设置基础数据
    this.chartObj.setOption(this.getInitOpt());
    this.chartObj.showLoading();
  }

  @Watch("show")
  onDataChange() {
    console.log("===========in", this.mainData, this.chartObj)
    if (!this.show) {
      return;
    }
    this.$nextTick(() => {
      this.initChart()
      this.chartObj.setOption(this.mainData)
      this.chartObj.hideLoading()
    })
  }

  getInitOpt() {
    return {
      legend: {
        orient: 'vertical',
        right: -8,
        top: 32,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'cross',
            label: {
                backgroundColor: '#6a7985'
            }
        }
      },
      grid: {
        show: true,
        top: 20,
        bottom: 32,
        left: 60,
        right: 120,

      },
      // 时间轴 接受一个二维数组 里层第一位是时间 字符串格式
      xAxis: {
        type: "category",
        name: "时间",
      },
      yAxis: {
        type: this.chartOption && this.chartOption.yAxisType && this.chartOption.yAxisType || "value",
        min: "dataMin"
      },
      series: [
        {
          data: [],
          type: "line",
        },
      ],
    };
  }
  onClose() {
    if (this.onCloseBigBFn) {
      this.onCloseBigBFn()
    } else {
      console.error("need set  onCloseBigBFn")
    }
  }
}
