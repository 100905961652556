import Vue from "vue";
import "ant-design-vue/dist/antd.css";
import Antd from "ant-design-vue";

// 百度地图
import BaiduMap from "vue-baidu-map";

// 项目组件
import App from "./App.vue";
import router from "./router";
import store from "./store";

// 自定义工具类
import * as GlobalRuntime from "@/util/GlobalRuntimeUtil";

// 自定义样式
import "@/style/default-ant.scss";
import "@/style/util.scss";
import "@/style/global-layout.scss";

Vue.config.productionTip = false;

Vue.use(Antd);
Vue.use(BaiduMap, {
  ak: "SsFLWGIvrUWAiUaGGiB8FqEEES2HUsfX",
});

const vm = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

GlobalRuntime.setVue(vm);
