import * as SensorTS from "@/type/project/SensorTS";
import * as HttpTS from "@/type/system/HttpTS";
import * as SysDictTS from "@/type/system/SysDictTS";

export class DevSensorListReq extends HttpTS.ListReq {
  deviceId?: number | null | string;
  constructor(deviceId?: string | null | number, pageSize?: number) {
    super();
    if (deviceId) {
      this.deviceId = deviceId;
    }
    if (pageSize) {
      this.pageSize = pageSize;
    }
  }
}

export enum SHOW_IN_3D {
  YES = 1,
  NO = 0,
}

export const SHOW_IN_3D_SEL: SysDictTS.SysDictTS[] = [
  { key: SHOW_IN_3D.NO, value: "不显示" },
  { key: SHOW_IN_3D.YES, value: "显示" },
];

export class DevSensorListRes extends HttpTS.ListRes<DeviceSensorTS> {}

export interface DevSensorDetail extends HttpTS.ListRes<DeviceSensorTS> {
  // 最新值映射
  lastValMap?: SensorTS.SensorHistoryMap;
}

export class DeviceSensorTS extends SensorTS.SensorTS {
  /** 本系统传感器主键 [关联键] */
  sensorCode?: string;
  /** 设备id   联合唯一 一个设备只配置一个传感器 */
  deviceId?: number;
  /** 指标名称 */
  itemName?: string;
  // 是否在3d页面显示
  showIn3D?: SHOW_IN_3D = SHOW_IN_3D.NO;
  /** 指标显示顺序 */
  showOrder: number = 0;
  /** 报警规则表达式 */
  warnExpression?: string;
  /** 危险规则表达式 */
  dangerExpression?: string;
  /** 创建人  [关联 = 用户] */
  crUser?: string;
  /** 创建时间 */
  crTime?: number;
  /** 修改时间 */
  upTime?: number;
  /** 报警责任人ID 如果有 则新建关联关系 */
  warnPrincipleArr?: string[];

  /** 逻辑删除 解除绑定 0 - 正常 1-解绑 */
  isDel?: number;
  /** 绑定时间 */
  connTime?: number;
  /** 解绑时间 */
  disconnTime?: number;
}
