import * as HttpUtil from '@/util/HttpUtil'
import * as HttpTS from '@/type/system/HttpTS'
import * as PermTS from '@/type/system/PermTS'

/**
 * 获取列表
 * @param param 
 * @returns 
 */
export const getList = (params: PermTS.PermListReq): Promise<PermTS.PermListRes> =>  {
    return HttpUtil.get("perm/list", params);
}

/**
 * 修改名称
 * @param permName 
 * 名称
 * @param code 
 * 权限码
 * @returns 
 */
export const updateOne = (permName?: string, code?: string): Promise<HttpTS.HttpRes> => {
    return HttpUtil.post("perm/update", {permName, code});
}
