import * as HttpUtil from '@/util/HttpUtil'
import * as HttpTS from '@/type/system/HttpTS'
import * as SensorTS from '@/type/project/SensorTS'
import * as SensorDataGeoTS from '@/type/project/SensorDataGeoTS'

export const getList = (params: SensorTS.SensorListReq): Promise<SensorTS.SensorListRes> => {
    params = JSON.parse(JSON.stringify(params))
    return HttpUtil.get("sensor/list", params)
}

export const addOne = (mainData: SensorTS.SensorTS, initData: SensorDataGeoTS.SensorDataGeoTS): Promise<HttpTS.HttpRes> => {
    return HttpUtil.post("sensor/add", {mainJson: JSON.stringify(mainData), geoInitData: JSON.stringify(initData)})
}

export const update = (template: SensorTS.SensorTS, sensorCode: string): Promise<HttpTS.HttpRes> => {
    return HttpUtil.post("sensor/update", {mainJson: JSON.stringify(template), sensorCode})
}

export const deleteGroup = (sensorCodeList: string[]): Promise<HttpTS.HttpRes> => {
    return HttpUtil.post("sensor/delete", {sensorCodeList: sensorCodeList.join(HttpTS.ARRAY_FLAG)})
}


