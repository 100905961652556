export {
    setLoginStatus, // 设置登录状态 true - 已登录, false - 未登录
    isLogined, // 已登录
    cleanAll, // 清空
}

const LOGIN_STATUS = "LOGIN_STATUS"

const setLoginStatus = (newVal: boolean): void => {
  window.localStorage.setItem(LOGIN_STATUS, JSON.stringify(newVal));
}

const isLogined = (): boolean => {
    return JSON.parse( window.localStorage.getItem(LOGIN_STATUS) || '');
}

const cleanAll = (): void => {
    window.localStorage.removeItem(LOGIN_STATUS);
}
