import {Vue, Component, Prop} from 'vue-property-decorator'
import * as HttpTS from "@/type/system/HttpTS";
import * as HttpUtil from "@/util/HttpUtil";
import * as UITS from "@/type/system/UITS";
import * as CDictTS from "@/type/system/CDictTS";
import * as SubstanceAPI from '@/api/project/SubstanceAPI'
import * as SubstanceTS from '@/type/project/SubstanceTS'
import SubstanceEdit from './components/SubstanceEdit.vue';

@Component({
  components: {
    SubstanceEdit
  }
})
export default class SubstanceListView extends Vue {
  
  // 禁用备选
  BAN_SEL = CDictTS.BAN_SEL;
  // 编辑页初始化类型
  INIT_TYPE = UITS.INIT_TYPE;
  // 查询等待
  loading: UITS.Loading = new UITS.Loading();
  // 查询条件
  reqObj: SubstanceTS.SubstanceListReq = new SubstanceTS.SubstanceListReq();
  // 响应结果
  mainData: SubstanceTS.SubstanceListRes = {
    page: new HttpTS.Page(),
  };

  created() {
    this.onSearch();
  }

    // 搜索列表
    onSearch(page?: HttpTS.Page) {
      if (page && page instanceof HttpTS.Page) {
        Object.assign(this.reqObj, page);
      } else {
        Object.assign(this.reqObj, new HttpTS.Page());
      }
      this.loading.spinning = true;
      SubstanceAPI.getList(this.reqObj).then((res) => {
        this.loading.spinning = false;
        if (HttpUtil.checkSucc(res)) {
          this.mainData = res;
        }
      });
    }

    
  // 换页
  onChangeTable(page: UITS.TablePage) {
    const _page = new HttpTS.Page();
    _page.currentPage = page.current;
    _page.pageSize = page.pageSize;
    this.onSearch(_page);
  }
  // 打开编辑页
  onShowEdit(initType: UITS.INIT_TYPE, data?: SubstanceTS.SubstanceTS) {
    const _edit = this.$refs.SubstanceEdit as any;
    _edit.init(initType, data);
  }

  onReset() {
    this.reqObj = new SubstanceTS.SubstanceListReq();
    this.onSearch()
  }
}