import * as HttpUtil from '@/util/HttpUtil'
import * as HttpTS from '@/type/system/HttpTS'

import * as CountHomeTS from '@/type/project/CountHomeTS'

export const homeCount = (): Promise<CountHomeRes> => {
    return HttpUtil.get("home/count");
}

export const userCount = (): Promise<CountHomeRes> => {
    return HttpUtil.get("user/count");
}
export class CountHomeRes extends HttpTS.HttpRes {
    data?: CountHomeTS.CountHomeTS
}