import { render, staticRenderFns } from "./SubstanceEdit.vue?vue&type=template&id=54c3c489&"
import script from "./SubstanceEdit.ts?vue&type=script&lang=ts&"
export * from "./SubstanceEdit.ts?vue&type=script&lang=ts&"
import style0 from "./SubstanceEdit.vue?vue&type=style&index=0&id=54c3c489&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/.store/vue-loader@15.10.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports