import * as UserTS from "./UserTS";
import * as SysDictTS from './SysDictTS'

// 数组序列化分割标识
export const ARRAY_FLAG = ",";
/** 用于传感器code拼接Type传感器数据记录表的SensorCode 对应传感器表 code + type */
export const MID_LINE = "--";

export enum RESULT_KEY {
  KEY_LIST = "list",
  KEY_PAGE = "page",
  KEY_DATA = "data",
  KEY_RES_CODE = "resCode",
  KEY_RES_MSG = "resMsg",

  USER_INFO_KEY = "userInfo",
  PERM_LIST_KEY = "permList",
  DICT_LIST_KEY = "dictList",
}

/** 返回结果成功/失败 */
export enum RESULT_CODE {
  // 一般性成功 不提示
  SUCC = 1,
  // 消息轻提示
  SUCC_ALERT = 101,
  // 需要确认的提示
  SUCC_CONFIRM = 102,

  // 一般错误提示(含服务器异常等)
  ERROR_ALERT = 999,
  // 弹框提示
  ERROR_CONFIRM = 998,
  // 未登录
  ERROR_LOGIN = 997,
  // 无权限
  ERROR_PERM = 996,
  // 表单提示处理
  ERROR_FORM = 995,
}

/** 列表请求 */
export class Page {
  /** 当前页 */
  currentPage: number = 1;

  /** 每次搜索个数 */
  pageSize: number = 10;

  /** 总记录数 */
  count: number = 0;

  /** 总页数 */
  countPage?: number;
}

export class HttpRes {
  // 响应码
  [RESULT_KEY.KEY_RES_CODE]?: number;
  // 提示信息
  [RESULT_KEY.KEY_RES_MSG]?: string;
  // 数据负载
  [RESULT_KEY.KEY_DATA]?: any;

  isSucc?: boolean;
}

export class LoginRes extends HttpRes {
  // 登录用 - 用户信息
  [RESULT_KEY.USER_INFO_KEY]?: UserTS.User;

  // 登录用 - 权限列表
  [RESULT_KEY.PERM_LIST_KEY]?: string[];

  // 字典表对应关系
  [RESULT_KEY.DICT_LIST_KEY]?: SysDictTS.SysDictTS[];
}

/** 列表页base */
export class ListRes<T> extends HttpRes {
  [RESULT_KEY.KEY_LIST]?: T[];
  [RESULT_KEY.KEY_PAGE]: Page = new Page;
}

export class ListReq {
  /** 当前页 */
  currentPage: number = 1;

  /** 每次搜索个数 */
  pageSize: number = 10;

  /** 关键字搜索 */
  kw?: string | null;
}

/** 表单验证项 data 下 */
export interface FORM_ERROR {
  field: string;
  msg: string;
}
