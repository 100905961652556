import Moment from 'moment'

import * as HttpUtil from '@/util/HttpUtil'
import * as HttpTS from '@/type/system/HttpTS'
import * as SensorDataGeoTS from '@/type/project/SensorDataGeoTS'
import * as SensorDataMulTS from '@/type/project/SensorDataMulTS'
import * as SensorDataDefTS from '@/type/project/SensorDataDefTS'
import * as SensorTS from '@/type/project/SensorTS'
import * as DeviceSensorTS from '@/type/project/DeviceSensorTS'

export const getList = (params: SensorHistoryListReq): Promise<SensorHistoryListRes> => {
    console.log("=====================", params)
    if (params.timeRangeUI && params.timeRangeUI.length) {
        if (params.timeRangeUI[0]) {
            params.timeStart = Number(params.timeRangeUI[0].startOf('date').format('x'))
        }
        
        if (params.timeRangeUI[1]) {
            params.timeEnd = Number(params.timeRangeUI[1].endOf('date').format('x'))
        }
    }
    params = JSON.parse(JSON.stringify(params))
    delete params.timeRangeUI

    return HttpUtil.get("sensor/history/list", params)
}

// 请求多个传感器的数据
export const getMulList = (params: SensorHistoryMulListReq): Promise<HttpTS.HttpRes> => {
    const _params = JSON.parse(JSON.stringify(params))
    _params.sensorListJson = JSON.stringify(_params.sensorList)
    delete _params.sensorList
    return HttpUtil.post("sensor/history/mullist", _params)

}
export class SensorHistoryMulListReq {
    timeStart: number = new Date().getTime() - 1 * 24 * 3600 * 1000
    timeEnd: number = new Date().getTime()
    // 序列化
    sensorListJson: string = null 
    // 反序列化
    sensorList: DeviceSensorTS.DeviceSensorTS[] = []
}

export class SensorHistoryListReq extends HttpTS.ListReq {
    
    sensorType: SensorTS.SENSOR_DATA_TABLE_NAME = null
    sensorCode: string
    timeStart: number | null = null
    timeEnd: number | null = null

    // 交互值
    timeRangeUI: Moment.Moment[]
    constructor(timeStart?: number, timeEnd?: number) {
        super()
        if (!timeStart) {
            timeStart = Moment(new Date().getTime() - 7 * 24 * 3600 * 1000).startOf('date').valueOf();
        }
        if (!timeEnd) {
            timeEnd = Moment(new Date().getTime()).endOf('date').valueOf()
        }

        this.timeStart = timeStart
        this.timeEnd = timeEnd
        this.timeRangeUI = [Moment(timeStart), Moment(timeEnd)]
    }
}

export interface SensorHistoryListRes extends HttpTS.ListRes<
| SensorDataGeoTS.SensorDataGeoTS
| SensorDataMulTS.SensorDataMulTS
| SensorDataDefTS.SensorDataDefTS> {

}