import * as CDictTS from './CDictTS'
import * as HttpTS from '@/type/system/HttpTS'

export class UserListReq extends HttpTS.ListReq {
  isBan: CDictTS.IS_BAN | null = null // 对应到后端是null
}

export class UserListRes extends HttpTS.ListRes<User> {
}


export class User {
  /** UUID */
  id?: string;
  /** 登录名 */
  username?: string;
  /** 密码 */
  password?: string;
  /** 显示名 */
  name?: string;
  /** 样式文件 */
  config?: string;
  /** 登录次数 */
  loginTimes?: number;
  /** 是否禁用 */
  isBan?: CDictTS.IS_BAN;
  /** 登录时间 */
  lastTime?: number;
  /** 注册时间 */
  crTime?: number;

}
