// 禁止发无用的请求
const KEY_HTTP_REQ_STATE = "HTTP_REQ_STATE";
const VALUE_BAN = "BAN";

// 获取禁用状态
export const getHttpReqState = () => {
    const banVal = window.sessionStorage.getItem(KEY_HTTP_REQ_STATE);
    if (banVal == VALUE_BAN) {
        return true;
    }
    return false;
}

// 设置禁用
export const banHttpReq = () => {
    return window.sessionStorage.setItem(KEY_HTTP_REQ_STATE, VALUE_BAN);
}

// 解除禁用
export const resoluteHttpReq = () => {
    return window.sessionStorage.removeItem(KEY_HTTP_REQ_STATE);
}

export const removeAll = () => {
    return window.sessionStorage.clear()
}