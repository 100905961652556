import { Vue, Component, Prop } from "vue-property-decorator";

import * as UITS from "@/type/system/UITS";
import * as DevTS from "@/type/project/DeviceTS";
import * as DevAPI from '@/api/project/DevAPI'

import NaviDetail from "./component/NaviDetail.vue";
import DevEdit from "./component/DevEdit.vue";
import DevSensor from './component/DevSensor.vue'

const LIST_ROUTE = 'home'

@Component({
  components: {
    NaviDetail,
    DevEdit,
    DevSensor
  },
})
export default class DDFault extends Vue {
  detailId = this.$route.query.id as string;

  // 查询等待
  loading: UITS.Loading = new UITS.Loading("数据加载中...");
  // 主要数据
  mainData: DevTS.DeviceTS = new DevTS.DeviceTS();
  created() {
    // TODO 获取数据 初始化页面
    this.getMainData();
  }

  getMainData() {
    this.loading.spinning = true;
    DevAPI.detail(this.detailId, [DevTS.DEV_DETAIL_ADDINFO.SENSOR_DATA_GEO]).then(
      (res) => {
        this.loading.spinning = false;
        this.mainData = res.data;
      }
    );
  }

  onResetData() {
    this.getMainData()
  }

  onBackList() {
    if (this.$route.name != LIST_ROUTE) {
      this.$router.replace({
        name: LIST_ROUTE,
      });
    }
  }

  // 打开编辑页
  onShowEdit() {
    const _edit = this.$refs.DevEdit as any;
    _edit.init(UITS.INIT_TYPE.UPDATE, this.mainData);
  }
  // 传感器配置 TODO  无权限的 给 detail
  onShowDevSensor() {
    
    const _edit = this.$refs.DevSensor as any;
    _edit.init(UITS.INIT_TYPE.UPDATE, this.mainData);
  }
}
