import * as HttpUtil from '@/util/HttpUtil'
import * as HttpTS from '@/type/system/HttpTS'
import * as PrincipleTS from '@/type/project/PrincipleTS'

export const getList = (params: PrincipleTS.PrincipleListReq): Promise<PrincipleTS.PrincipleListRes> => {
    params = JSON.parse(JSON.stringify(params))
    return HttpUtil.get("principle/list", params)
}

export const addOne = (mainData: PrincipleTS.PrincipleTS): Promise<HttpTS.HttpRes> => {
    return HttpUtil.post("principle/add", {mainJson: JSON.stringify(mainData)})
}

export const update = (template: PrincipleTS.PrincipleTS, id?: number): Promise<HttpTS.HttpRes> => {
    return HttpUtil.post("principle/update", {mainJson: JSON.stringify(template), id})
}

export const deleteGroup = (ids: number[]): Promise<HttpTS.HttpRes> => {
    return HttpUtil.post("principle/delete", {idList: ids.join(HttpTS.ARRAY_FLAG)})
}


