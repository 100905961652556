
interface StateTS {
  loginDialogShow: boolean
}

const state = (): StateTS => ({
  loginDialogShow : false
})

const getters = {
  loginDialogShow: (state: StateTS) => state.loginDialogShow,
}

const actions = {
  openLoginDialog(context: any) {
    context.commit('SET_LOGIN_dIALOG_SHOW', true);
  },
  
  closeLoginDialog(context: any) {
    context.commit('SET_LOGIN_dIALOG_SHOW', false);
  }
}

const mutations = {
  SET_LOGIN_dIALOG_SHOW(state: StateTS, val: boolean) {
    state.loginDialogShow = val;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}