import { Vue, Component, Prop } from "vue-property-decorator";

import * as LoginAPI from "@/api/system/LoginAPI";
import * as UITS from "@/type/system/UITS";
import * as HttpTS from "@/type/system/HttpTS";
import * as LoginCtrl from '@/util/LoginCtrl'


@Component({
  components: {},
})
export default class LoginView extends Vue {

  loginObj = {
    username: "",
    password: "",
  };

  // 提交等待
  loading: UITS.Loading = new UITS.Loading();
  // 表单验证规则
  FORM_RULE = UITS.FORM_RULE;
  // 表单校验
  ruleObj: {
    [key: string]: any;
  } = {
    username: "",
  };

  async onLogin() {
    const _form = this.$refs.mainForm as any;
    this.ruleObj = {};
    // 表单验证
    const formSure = await new Promise((resolve) => {
      _form.validate((validRes: boolean) => {
        resolve(validRes);
      });
    });
    if (!formSure) {
      return;
    }

    this.loading.spinning = true;
    LoginAPI.login(this.loginObj.username, this.loginObj.password).then(
      (res) => {
        this.loading.spinning = false;
        // 后端验证异常
        if (res.resCode == HttpTS.RESULT_CODE.ERROR_FORM) {
          const _data = res.data as HttpTS.FORM_ERROR;
          this.ruleObj[_data.field] = _data.msg;
          _form.validate();
        }

        // 没有异常要清空 方式显示错误
        if (res.isSucc) {

          LoginCtrl.setLoginInfo(res)

          this.$router.replace({
            path: "/",
          });
          
        }
      }
    );
  }

  onReset() {
    const _form = this.$refs.mainForm as any;
    _form.resetFields();
  }
}
