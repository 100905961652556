export class DeviceUIConfigTS {
  /** 主键 [关联键] 设备id  */
  id?: number;
  /** 本系统设备主键 [关联键]  */
  deviceId?: number;
  /** 前端图表 */
  chartId?: number;
  /** 本系统传感器主键 [关联键]  */
  sensorCode?: string;
  /** 注册时间 */
  crTime?: number;
  /** 是否禁用 0-正常 1-禁用 */
  isBan?: number;
  /** 创建人 */
  createUserId?: string;
  /** 图表名称 */
  chartName?: string;
  
  /** 9-25新增, 画图参数 JSON格式 Y轴类型 9-25新增 value / log 默认: value */
  chartOption?: ChartOption = new ChartOption;

  /** 前端冗余 select组件用 */
  sensorIdArr?: string[] = [];
}

export class ChartOption {
  // value / log / undefined
  yAxisType: string;
}
