/**
 * 规则
 */
import * as DictTS from '@/type/system/SysDictTS'
/**
 * 常用规则  等于 大于 小于 
 * 多个对象联用 and/or运算符必须唯一 前端限制
 */
export class CommonRule {
    // 运算符
    operator: OPERATOR = OPERATOR.GT
    // 判断值
    value: number = 0
    // 连接符
    connector: CONNECTOR = CONNECTOR.AND
}

export enum CONNECTOR {
    AND = '&&',
    OR = '||'
}

export enum OPERATOR {
    GT = '>',
    GTE = ">=",
    LT = '<',
    LTE = '<=',
    EQ = '=',
    NEQ = '!='
}

export const CONNECTOR_SEL: DictTS.SysDictTS[] = [
    {key: CONNECTOR.AND, value: '且'},
    {key: CONNECTOR.OR, value: '或'},
]

export const OPERATOR_SEL: DictTS.SysDictTS[] = [
    {key: OPERATOR.GT, value: '大于(>)'},
    {key: OPERATOR.GTE, value: '大于等于(≥)'},
    {key: OPERATOR.LT, value: '小于(<)'},
    {key: OPERATOR.LTE, value: '小于等于(≤)'},
    {key: OPERATOR.EQ, value: '等于(=)'},
    {key: OPERATOR.NEQ, value: '不等于(≠)'},
]

