import { Vue, Component, Prop } from "vue-property-decorator";
import moment from "moment";
import * as UITS from "@/type/system/UITS";
import * as DevTS from "@/type/project/DeviceTS";
import * as DevAPI from "@/api/project/DevAPI";
import * as DevSensorAPI from "@/api/project/DevSensorAPI";
import * as DevSensorTS from "@/type/project/DeviceSensorTS";
import * as SensorTS from "@/type/project/SensorTS";

import * as SensorDataGeoTS from "@/type/project/SensorDataGeoTS";
import * as SensorDataMulTS from "@/type/project/SensorDataMulTS";
import * as SensorDataCodeTS from '@/type/project/SensorDataCodeTS'

import NaviDetail from "./component/NaviDetail.vue";
import DevEdit from "./component/DevEdit.vue";
import DevSensor from "./component/DevSensor.vue";
import DDWatchHistory from "./DDWatchHistory.vue";
import * as SensorDataDefTS from "@/type/project/SensorDataDefTS";

const LIST_ROUTE = "home";

@Component({
  components: {
    NaviDetail,
    DevEdit,
    DevSensor,
    DDWatchHistory,
  },
})
export default class DDWatch extends Vue {
  detailId = this.$route.query.id as string;

  /** 时间格式 */
  TIME = UITS.TIME;

  moment = moment;
  // 查询等待
  loading: UITS.Loading = new UITS.Loading("数据加载中...");
  loadingData: UITS.Loading = new UITS.Loading("数据加载中...");
  // 显示历史
  showHistory: boolean = false;
  // 主要数据
  mainData: DevTS.DeviceTS = new DevTS.DeviceTS();
  // 传感器对象
  sensorObj: SensorTS.SensorHistoryMap = {};
  // 设备-传感器映射
  devSensorArr: DevSensorTS.DeviceSensorTS[] = [];

  created() {
    if (!this.detailId) {
      this.$router.replace({ name: "home" });
      return;
    }
    // TODO 获取数据 初始化页面
    this.getMainData();
    this.getDevSensorList();
  }

  getMainData() {
    this.loading.spinning = true;
    DevAPI.detail(this.detailId, [DevTS.DEV_DETAIL_ADDINFO.SENSOR_DATA_GEO]).then(
      (res) => {
        this.loading.spinning = false;
        this.mainData = res.data;
      }
    );
  }

  getDevSensorList() {
    this.loadingData.spinning = true;
    DevSensorAPI.getDetail(Number(this.detailId)).then((res) => {

      this.loadingData.spinning = false;
      if (!res.isSucc) {
        return;
      }
      if (res.lastValMap) {
        this.sensorObj = res.lastValMap;
      }
      if (res.list) {
        this.devSensorArr = res.list;
      }
    });
  }

  onResetData() {
    this.getMainData();
  }

  onBackList() {
    if (this.$route.name != LIST_ROUTE) {
      this.$router.replace({
        name: LIST_ROUTE,
      });
    }
  }

  // 打开编辑页
  onShowEdit() {
    const _edit = this.$refs.DevEdit as any;
    _edit.init(UITS.INIT_TYPE.UPDATE, this.mainData);
  }
  // 传感器配置 TODO  无权限的 给 detail
  onShowDevSensor() {
    const _edit = this.$refs.DevSensor as any;
    _edit.init(UITS.INIT_TYPE.UPDATE, this.mainData);
  }

  // 获取值
  onGetValue(item: DevSensorTS.DeviceSensorTS) {
    if (!item.sensorType) {
      return "--";
    }
    if (
      SensorTS.SENSOR_DATA_TABLE_NAME.BOTH == item.sensorType
    ) {
      const curSensor = this.sensorObj[item.sensorCode!] as
        | SensorDataMulTS.SensorDataMulTS;
      if (curSensor) {
        return curSensor.value / curSensor.maxValue;
      }
    }

    if (item.sensorType == SensorTS.SENSOR_DATA_TABLE_NAME.GEO) {
      const curSensor = this.sensorObj[
        item.sensorCode!
      ] as SensorDataGeoTS.SensorDataGeoTS;
      if (curSensor) {
        return curSensor.lng + ", " + curSensor.lat;
      }
    }

    if (item.sensorType == SensorTS.SENSOR_DATA_TABLE_NAME.CODE) {
      const curSensor = this.sensorObj[
        item.sensorCode!
      ] as SensorDataCodeTS.SensorDataCodeTS;
      if (curSensor) {
        return curSensor.code;
      }
    }

    const curSensor = this.sensorObj[
      item.sensorCode!
    ] as SensorDataDefTS.SensorDataDefTS

    if (curSensor) {
      return curSensor.value;
    }
    return "--";
  }

  // 查看历史
  onShowSensorHistory(item: DevSensorTS.DeviceSensorTS) {
    const DDWatchHistory = this.$refs.DDWatchHistory as any;
    this.showHistory = true;
    DDWatchHistory.init(item);
  }

  onCloseHistory() {
    this.showHistory = false;
  }
}
