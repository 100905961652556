import Vue from 'vue'

let globalVue: Vue | null = null;
let globalSetRoutes: Function | null = null;


export const setVue = (_vm: Vue) => {
  globalVue = _vm;
}

export const getVue = (): Vue | null =>  {
  return globalVue;
}

export const setRouteFn = (_setRouteFn: Function) => {
  globalSetRoutes = _setRouteFn
}

export const getRouteFn = () => {
  return globalSetRoutes;
}


