import { Vue, Component, Prop } from "vue-property-decorator";
import * as HttpTS from "@/type/system/HttpTS";
import * as HttpUtil from "@/util/HttpUtil";
import * as UITS from "@/type/system/UITS";
import * as CDictTS from "@/type/system/CDictTS";
import * as ObjUtil from "@/util/ObjUtil";

import * as FactoryAPI from "@/api/project/FactoryAPI";
import * as FactoryTS from "@/type/project/FactoryTS";
import FactoryEdit from "./components/FactoryEdit.vue";

@Component({
  components: {
    FactoryEdit,
  },
})
export default class FactoryListView extends Vue {
  // 禁用备选
  BAN_SEL = CDictTS.BAN_SEL;
  // 禁用键值对映射
  BAN_KV_OBJ = ObjUtil.getKVObj(CDictTS.BAN_SEL)
  // 编辑页初始化类型
  INIT_TYPE = UITS.INIT_TYPE;
  // 查询等待
  loading: UITS.Loading = new UITS.Loading();

  // 查询条件
  reqObj: FactoryTS.FactoryListReq = new FactoryTS.FactoryListReq();
  // 响应结果
  mainData: FactoryTS.FactoryListRes = {
    page: new HttpTS.Page(),
  };

  // 分类列表 给下拉选用
  TYPE_SEL = FactoryTS.TYPE_SEL
  // 分类键值映射 给表格用
  TYPE_KV_OBJ = ObjUtil.getKVObj(FactoryTS.TYPE_SEL)
  created() {
    this.onSearch();
  }

  // 搜索列表
  onSearch(page?: HttpTS.Page) {
    if (page && page instanceof HttpTS.Page) {
      Object.assign(this.reqObj, page);
    } else {
      Object.assign(this.reqObj, new HttpTS.Page());
    }
    this.loading.spinning = true;
    FactoryAPI.getList(this.reqObj).then((res) => {
      this.loading.spinning = false;
      if (HttpUtil.checkSucc(res)) {
        this.mainData = res;
      }
    });
  }

  // 换页
  onChangeTable(page: UITS.TablePage) {
    const _page = new HttpTS.Page();
    _page.currentPage = page.current;
    _page.pageSize = page.pageSize;
    this.onSearch(_page);
  }
  // 打开编辑页
  onShowEdit(initType: UITS.INIT_TYPE, data?: FactoryTS.FactoryTS) {
    const _edit = this.$refs.FactoryEdit as any;
    _edit.init(initType, data);
  }

  onReset() {
    this.reqObj = new FactoryTS.FactoryListReq();
    this.onSearch()
  }
}
