import { Vue, Prop, Component, Watch } from 'vue-property-decorator'
import * as UITS from '@/type/system/UITS'
const ZOOM_DEF = 13
class MapRes {
  lng: number = 0
  lat: number = 0
  address: string = ""
}
/**
 * 可编辑的地图组件
 * 初始化: 如果没有坐标 (新增 或坐标设置错误) 按默认初始化 
 * 
 */
@Component({
  components: {
  }
})
export default class MapDetailBaidu extends Vue {

  // 地图数据
  @Prop({default: () => new MapRes()}) initData!: MapRes;

  // 是否显示拉起按钮 默认不显示
  @Prop({default: () => false}) showBtn!: boolean;
  // 按钮模式下: 控制地图展开/隐藏
  showMap = false

  // 地图加载
  divOption: UITS.Loading = {
    spinning: true,
    tip: '地图加载中...'
  }

  /** 地图实例对象 */
  myBMap: any = null;
  
  // 精度
  zoom = ZOOM_DEF;

  // 地图加载回调
  onMapReady(event: any) {
    this.divOption.spinning = false;
    // 地图函数库对象
    this.myBMap = event.BMap as any;
  }

  // 精度变化回调
  onChangeZoom(event: any) {
    this.zoom = event.target.getZoom() || ZOOM_DEF;
  }

}