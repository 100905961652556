import * as HttpTS from "@/type/system/HttpTS";
import * as SysDictTS from '@/type/system/SysDictTS'

export class FactoryListReq extends HttpTS.ListReq {
  type: string | null = null // 类型
  isDel: number | null = null// 是否禁用 0 - 正常
}

export class FactoryListRes extends HttpTS.ListRes<FactoryTS> {}

export class FactoryTS {
  /** 主键 [关联键] */
  id?: number;
  /** 工厂名称 */
  name?: string;
  /**  工厂地址 */
  address?: string;
  /** 工厂电话 */
  telephone?: string;
  /** 工厂描述 */
  description?: string;
  /** 类别 表名.字段名.限制备选 */
  type?: TYPE;
  /** 是否删除 1 -是 0 -否 */
  isDel?: number;
  /** 创建人  [关联 = 用户] */
  crUser?: string;
  /** 创建时间 */
  crTime?: number;
  /** 修改时间 */
  upTime?: number;
}

export enum TYPE {
  DEVVICE_RECEIVE_ID = 'device.receiveId' // 设备接收单位
}

export const TYPE_SEL: SysDictTS.SysDictTS[] = [
  {
    key: TYPE.DEVVICE_RECEIVE_ID,
    value: '设备接收单位'
  }
]