import { Vue, Component, Watch } from 'vue-property-decorator'
import { namespace } from "vuex-class";
import * as NaviTS from '@/type/system/NaviTS'
import Logout from '@/components/header/Logout.vue'
import UserCenter from '@/components/header/UserCenter.vue'
import OnlineNum from '@/components/header/OnlineNum.vue'
import NotifyIndex from '@/components/header/NotifyIndex.vue'

const LoginStore = namespace("LoginStore");

const MIN_ROUTE = 5; // 路由数小于5个直接展开
const ONE = 1;
@Component({
  components: {
    Logout,
    UserCenter,
    OnlineNum,
    NotifyIndex
  }
})
export default class NaviContainer extends Vue {

  @LoginStore.Getter("refreshLoginData") refreshLoginData!: string
  // 当前活跃的路由
  get activeNavi(): string[] {
    const result: string[] = []

    if (this.$route.name) {
      result.push(this.$route.name)
    }

    if (this.$route.meta && this.$route.meta.superName) {
      result.push(this.$route.meta.superName)
    }
    return result
  }

  // 有权限之前先隐藏主页内容
  showPage = false;
  // 路由数组
  naviArr: NaviTS.Navi[] = [];
  // 缩小菜单栏
  minSide = false;
  // 上级路由 展开的路由
  superSide: string[] = [];

  mounted(): void {

    this.makeBtnArr();

    this.getSuperSideMenu();
  }

  // 根据配置制作按钮
  
  @Watch("refreshLoginData")
  makeBtnArr(): void {

    // 根据过滤后的路由制作左侧导航栏
    const _navi = this.$router.getRoutes().filter(item => item.meta && item.meta.superTitle && !item.meta.noShowSide);
    let showNavi: NaviTS.Navi[] = [];

    if (_navi.length < MIN_ROUTE) {
      showNavi = _navi.map(item => {
        return {
          title: item.meta && item.meta.title || 'ERROR',
          icon: item.meta && item.meta.icon || 'ERROR',
          routeName: item.name || 'error',
          path: item.path
        }
      });
    }

    // 路由合并
    let beforeSuperTitle = null;
    for (let i = 0; i < _navi.length; i++) {
      const _meta = _navi[i].meta as NaviTS.MyRouteMeta
      const curSuperTitle = _meta.superTitle as NaviTS.ROUTE_SUPER_TITLE;
      if (curSuperTitle != beforeSuperTitle) {
        showNavi.push({
          title: curSuperTitle,
          icon: NaviTS.SUPER_ICON[curSuperTitle] || 'ERROR',
          routeName: _navi[i].name || 'error', // 先放着 确定为一级以后不会用
          path: _navi[i].path,
          children: [
            {
              title: _meta.title || 'ERROR',
              icon: _meta.icon || 'ERROR',
              routeName: _navi[i].name || 'error', // 先放着 确定为一级以后不会用
              path: _navi[i].path,
            }
          ]
        });
      } else {
        const _index = showNavi.findIndex(item => item.title == curSuperTitle);
        const _child = showNavi[_index].children
        if (!_child) {
          console.warn("NAVI ERROR", showNavi, _index, curSuperTitle);
          continue;
        }
        _child.push({
          title: _meta.title || 'ERROR',
          icon: _meta.icon || 'ERROR',
          routeName: _navi[i].name || 'error', // 先放着 确定为一级以后不会用
          path: _navi[i].path,
        })
      }
      beforeSuperTitle = curSuperTitle;
    }

    // 渲染规则: 某分类下只有一个页面的 平铺展开
    for (let i = 0; i < showNavi.length; i++) {
      const _child = showNavi[i].children
      if (!_child || !_child.length) {
        console.warn("NAVI_ERROR", showNavi, i);
        continue;
      }
      if (_child && _child.length == ONE) {
        showNavi.splice(i, 1, _child[0]);
      }
    }

    // 赋值路由
    this.naviArr = showNavi;

    // 显示页面
    this.showPage = true;
  }

  onGoPage(route: NaviTS.Navi) {

    if (this.$route.name != route.routeName) {
      this.$router.replace({
        name: route.routeName
      })
    }

  }

  // 刷新后 计算上级标签
  @Watch("refreshLoginData")
  getSuperSideMenu() {
    const _superSide = []
    for (let i = 0;i < this.naviArr.length; i++) {
      const _child = this.naviArr[i].children
      if (_child) {
        // 只展开当前页面相关的上级标签
        // for (let k = 0; k < _child.length; k++) {
        //   if (this.$route.name == _child[k].routeName) {
        //     this.superSide.push(this.naviArr[i].routeName);
        //     return;
        //   }
        // }

        // 展开全部

        for (let k = 0; k < _child.length; k++) {
          _superSide.push(this.naviArr[i].routeName);
        }
      }
    }
    this.superSide = _superSide;
  }

  onToggleMenu(routeName: string) {
    if (this.superSide.includes(routeName)) {
      this.superSide.splice(this.superSide.indexOf(routeName), 1)
    } else {
      this.superSide.push(routeName)
    }
  }
}