import * as HttpUtil from '@/util/HttpUtil'
import * as HttpTS from '@/type/system/HttpTS'
import * as UserTS from '@/type/system/UserTS'
import sha1 from 'sha1'

export const getList = (params: UserTS.UserListReq): Promise<UserTS.UserListRes> => {
    return HttpUtil.get("user/list", params)
}

export const addOne = (user: UserTS.User): Promise<HttpTS.HttpRes> => {
    const _user = {...user};
    _user.password = sha1(_user.password || '')
    return HttpUtil.post("user/add", {userJson: JSON.stringify(_user)})
}

export const updateGroup = (user: UserTS.User, ids: string[]): Promise<HttpTS.HttpRes> => {
    return HttpUtil.post("user/update", {userJson: JSON.stringify(user), ids: JSON.stringify(ids)})
}

