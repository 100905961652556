import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {Random} from 'random-js'

import * as UITS from "@/type/system/UITS";
import * as ReSure from "@/components/global/ReSure";

import * as DevSensorAPI from "@/api/project/DevSensorAPI";
import * as DevSensorTS from "@/type/project/DeviceSensorTS";
import * as SensorTS from '@/type/project/SensorTS'

import ShowLen from "@/components/normal/ShowLen.vue";
import SensorEdit from "@/views/sensor/components/SensorEdit.vue";
import RuleEdit from '@/components/normal/RuleEdit.vue'
import * as PrincipleTS from "@/type/project/PrincipleTS";


/**
 * 一个传感器卡片
 */
@Component({
  components: {
    ShowLen,
    SensorEdit,
    RuleEdit
  },
})
export default class DevSensorItem extends Vue {
  // 新增或修改或删除后出发数据刷新
  @Prop() onChange: Function;
  // 取消新增
  @Prop() onCancelAdd: Function;
  // add /detail 其中 detail可以转换成edit
  @Prop() initType?: UITS.INIT_TYPE;
  // 新增用 设备主键
  @Prop() deviceId?: number;
  // 当前传感器关联信息
  @Prop() superData!: DevSensorTS.DeviceSensorTS;
  // 责任人备选
  @Prop() principleSel!: PrincipleTS.PrincipleTS[]
  
  /** 表单校验规则 */
  FORM_RULE = UITS.FORM_RULE;
  // 是否在3d页面展示
  SHOW_IN_3D_SEL = DevSensorTS.SHOW_IN_3D_SEL;
  /** 初始化标识 只负责按钮是否显示  */
  INIT_TYPE = UITS.INIT_TYPE;
  // 查询等待
  loading: UITS.Loading = new UITS.Loading('数据提交中...');

  // 编辑中 管理表单编辑能力
  editing = false
  // 折叠组件容错
  collapseKey = new Random().string(4)
  // 表单数据 默认是新的
  mainData: DevSensorTS.DeviceSensorTS = new DevSensorTS.DeviceSensorTS;
  // 新增时的传感器备选列表
  sensorSel: SensorTS.SensorTS[] = []
  mounted() {
    this.dataInit()
    this.dataInitAdd()
    this.getOtherSensorList()
  }

  @Watch("superData")
  dataInit() {
    if (this.initType == this.INIT_TYPE.DETAIL) {
      this.mainData = JSON.parse(JSON.stringify(this.superData))
    } 
  }

  dataInitAdd() {
    if (this.initType == this.INIT_TYPE.ADD) {
      this.mainData.deviceId = this.deviceId
    }
  }

  // 获取当前设备没有绑定的传感器列表
  getOtherSensorList() {
    if (this.initType != this.INIT_TYPE.ADD) {
      return;
    }
    DevSensorAPI.getOtherList(this.mainData.deviceId).then(res => {
      if (res.isSucc) {
        this.sensorSel = res.list
      }
    })
  }

  // 保存
  async onSave() {

    // 表单验证
    const mainForm = this.$refs.mainForm as any;
    const _validSucc = await new Promise((resolve) => {
      mainForm.validate((valid: boolean) => {
        resolve(valid);
      });
    });
    if (!_validSucc) {
      return;
    }

    const _sure = await ReSure.saveSure("是否确定保存?", "确定");
    if (!_sure) {
      return;
    }

    if (this.initType == this.INIT_TYPE.ADD) {
      await DevSensorAPI.addOne(this.mainData)
    } else {
      await DevSensorAPI.update(this.mainData)
    }
    this.onCancel()
    if (this.onChange) {
      this.onChange()
    }

  }

  // 取消
  onCancel() {
    // 如果是新增 通知父层取消
    if (this.initType == this.INIT_TYPE.ADD) {
      if (this.onCancelAdd) {
        this.onCancelAdd()
      } else {
        console.error("Must instance onCancelAdd!")
      }
      return;
    }

    // 初始化数据
    if (this.superData) {
      this.mainData = JSON.parse(JSON.stringify(this.superData))
    }
    this.editing = false
  }

  // 开始编辑
  onStartEdit() {
    this.editing = true
  }

  // 删除
  async onDelete() {
    const _sure = await ReSure.saveSure("是否确定删除?", "删除");
    if (!_sure) {
      return;
    }

    await DevSensorAPI.deleteOne(this.mainData.deviceId, this.mainData.sensorCode)
    this.onCancel()
    if (this.onChange) {
      this.onChange()
    }
  }

  onAddOneSensor() {
    const _edit = this.$refs.SensorEdit as any;
    _edit.init(this.INIT_TYPE.ADD, null);
  }

  // 新增一个传感器回调
  onAddSensorBack() {
    this.getOtherSensorList()
  }

  // 规则校验 TODO
  onValidatorWarn(r: any, v: any, backFn: Function) {
    // 如果值为空
  }

  // 规则校验 TODO
  onValidatorDanger(r: any, v: any, backFn: Function) {
    // 如果值为空
  }
}
