import {Vue, Component, Prop} from 'vue-property-decorator'
import * as HttpTS from "@/type/system/HttpTS";
import * as HttpUtil from "@/util/HttpUtil";
import * as UITS from "@/type/system/UITS";
import * as CDict from '@/type/system/CDictTS'
import * as ReSure from "@/components/global/ReSure";
import ShowLen from "@/components/normal/ShowLen.vue";

import * as SubstanceTS from '@/type/project/SubstanceTS'
import * as SubstanceAPI from '@/api/project/SubstanceAPI'

@Component({
  components: {
    ShowLen
  }
})
export default class SubstanceEdit extends Vue {
  
  // 保存回调
  @Prop() onChange?: Function;
  /** 初始化标识 */
  INIT_TYPE = UITS.INIT_TYPE;
  /** 表单校验规则 */
  FORM_RULE = UITS.FORM_RULE;
  /** 时间格式 */
  TIME = UITS.TIME;
  /** 是否显示编辑框 */
  show: boolean = false;
  /** 提交等待 */
  loading: UITS.Loading = new UITS.Loading("数据保存中...");
  /** 初始化参数 */
  initType: UITS.INIT_TYPE | '--' = '--';
  /** 主要数据 */
  mainData: SubstanceTS.SubstanceTS = new SubstanceTS.SubstanceTS;
  // 后台验证
  ruleObj: { [key: string]: string } = {};

  public init(_initType: UITS.INIT_TYPE, _mainData?: SubstanceTS.SubstanceTS) {
    // 清空表单
    const _mainForm = this.$refs.mainForm as any;
    _mainForm && _mainForm.resetFields();

    // 显示组件
    this.show = true;
    // 调整loading
    this.loading.spinning = false;

    // 注入数据
    if (!_mainData) {
      _mainData = new SubstanceTS.SubstanceTS();
    } else {
      _mainData = {
        ..._mainData,
      };
    }
    this.mainData = _mainData;
    this.initType = _initType;
  }

  // 开始提交
  async onSave() {
    const _mainForm = this.$refs.mainForm as any;

    // 浅拷贝, 避免影响调试
    const _mainData = { ...this.mainData }

    // 表单验证
    const _validSucc = await new Promise((resolve) => {
      _mainForm.validate((valid: boolean) => {
        resolve(valid);
      });
    });
    if (!_validSucc) {
      return;
    }

    // 二次确认
    const _sure = await ReSure.saveSure();
    if (!_sure) {
      return;
    }

    this.loading.spinning = true;

    switch (this.initType) {
      // 修改
      case this.INIT_TYPE.UPDATE:
        SubstanceAPI.update(_mainData, _mainData.id).then((res) => {
          this.onSaveBack(res);
        });
        break;
      // 新增
      case this.INIT_TYPE.ADD:
        SubstanceAPI.addOne(_mainData).then((res) => {
          this.onSaveBack(res);
        });
        break;
    }
  }

  // 保存回调
  onSaveBack(res: HttpTS.HttpRes) {
    const _mainForm = this.$refs.mainForm as any;
    // 唯一字段校验
    if (res.resCode == HttpTS.RESULT_CODE.ERROR_FORM) {
      const _data = res.data as HttpTS.FORM_ERROR;
      this.ruleObj[_data.field] = _data.msg;
      _mainForm.validate();
      return;
    }
    if (HttpUtil.checkSucc(res)) {
      this.onChange && this.onChange();
      this.show = false;
    } else {
      this.loading.spinning = false;
    }
  }

  // 关闭
  onClose() {
    ReSure.saveSure("是否放弃保存 ?", "放弃保存").then((res) => {
      if (res) {
        this.show = false;
      }
    });
  }
}