
import { RouteConfig } from 'vue-router'
import * as NaviTS from '@/type/system/NaviTS'
import FactoryListView from '@/views/setting/FactoryListView.vue'
import PrincipleListView from '@/views/setting/PrincipleListView.vue'
import SubstanceListView from '@/views/setting/SubstanceListView.vue'

const routes: Array<RouteConfig> = [
  {
    path: '/factory-list',
    name: 'factoryList',
    component: FactoryListView,
    meta: {
      superTitle: NaviTS.ROUTE_SUPER_TITLE.SETTING,
      title: '单位/工厂管理',
      icon: 'safety-certificate'
    }
  },
  {
    path: '/principle-list',
    name: 'principleList',
    component: PrincipleListView,
    meta: {
      superTitle: NaviTS.ROUTE_SUPER_TITLE.SETTING,
      title: '责任人管理',
      icon: 'user-add'
    }
  },
  {
    path: '/substance-list',
    name: 'substanceList',
    component: SubstanceListView,
    meta: {
      superTitle: NaviTS.ROUTE_SUPER_TITLE.SETTING,
      title: '介质管理',
      icon: 'team'
    }
  },
]
export default routes;
