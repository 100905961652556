import * as HttpTS from "@/type/system/HttpTS";

export class PrincipleListReq extends HttpTS.ListReq {
  isDel: number | null = null; // 是否禁用 0 - 正常
}

export class PrincipleListRes extends HttpTS.ListRes<PrincipleTS> {}

export class PrincipleTS { 

/** 责任人ID 主键 [关联键] */
id?: number;
/** 负责人名称 */
name?: string;
/** 性别 [关联字典表] */
sex?: number;
/** 电话 */
telephone?: string;
/** 电子邮件 */
email?: string;
/** 职务 */
duty?: string;
/** 部门 */
department?: string;
/** 是否删除 1 -是 0 -否 */
isDel?: number;
/** 创建人  [关联 = 用户] */
crUser?: string;
/** 创建时间 */
crTime?: number;
/** 修改时间 */
upTime?: number;
}