import { Vue, Prop, Component, Watch } from 'vue-property-decorator'
import * as UITS from '@/type/system/UITS'
const ZOOM_DEF = 13

export class MapRes {
  lng: number = 116.404
  lat: number = 39.915 // 北京市
  address: string = ""
  province: string = ""
  city: string = ""
}

/**
 * 可编辑的地图组件
 * 初始化: 如果没有坐标 (新增 或坐标设置错误) 按默认初始化 
 * 
 */
@Component({
  components: {
  }
})
export default class MapEditBaidu extends Vue {

  @Prop({default: () => new MapRes()}) initData!: MapRes;

  // 回调函数
  @Prop() onChange?: Function;

  get changeAddress() {
    // 如果为空可以联动变化
    if (!this.result.address) {
      this.hasEdit = false;
      return true;
    }

    if (this.hasEdit) {
      return false;
    }

    return true;
  }
  
  // 最大长度
  LONG_TEXT_MAX = UITS.FORM_RULE.MAX_LENG_128;

  // 返回值
  result: MapRes = new MapRes();
  
  // 返回结果: 水滴点
  markerPoint = {
    lng: 116.404, lat: 39.915, // 北京市
  }
  // 地图加载
  divOption: UITS.Loading = {
    spinning: true,
    tip: '地图加载中...'
  }

  // 中心点坐标
  centerPoint = {
    lng: 116.404, lat: 39.915,
  }

  /** 地图实例对象 */
  myBMap: any = null;
  
  // 精度
  zoom = ZOOM_DEF;

  // 是否编辑过
  hasEdit = false;

  // 初始化时有值 只处理一次 
  mounted() {
    this.initSet();
  }

  // 设置初始化参数
  @Watch("initData")
  initSet() {

    // 清空表单
    // const _form = this.$refs.addrForm as any;
    // _form && _form.resetFields();
    
    // 设置传递数据
    this.result = this.initData;
    if (this.result.address) {
      this.hasEdit = true;
    }

  }

  // 表单验证
  // validFormSync(): Promise<boolean> {
  //   const _form = this.$refs.addrForm as any;
  //   return new Promise(resolve => {
  //     _form.validate((valid: boolean) => {
  //       resolve(valid)
  //     })
  //   })
  // }

  // 地图加载回调
  onMapReady(event: any) {
    this.divOption.spinning = false;
    // 地图函数库对象
    this.myBMap = event.BMap as any;
    
    // 设置地图属性
    this.markerPoint = {lng: this.result.lng, lat: this.result.lat};
    this.centerPoint = {lng: this.result.lng, lat: this.result.lat};
  }

  // 精度变化回调
  onChangeZoom(event: any) {
    this.zoom = event.target.getZoom() || ZOOM_DEF;
  }

  // 点击地图
  onSetMarker(event: any) {
    // 设置水滴位置
    this.markerPoint = event.point;
    // 地址解析
    if (!this.myBMap) {
      console.error("ERORR ++++++++++ BMap is NULL");
      return;
    }


    // 设置详细地址
    if (this.changeAddress) {
      const _geo = new this.myBMap.Geocoder();
      _geo.getLocation(event.point, (res: any) => {
          this.result = {
            province: res.addressComponents.province,
            city: res.addressComponents.city,
            address: res.address,
            lng: this.markerPoint.lng,
            lat: this.markerPoint.lat,
          };
          if (this.onChange) {
            this.onChange(this.result);
          }
      })
    }
  }

  // 输入框变化
  onChangeText() {
    this.hasEdit = true;
    if (this.onChange) {
      this.onChange(this.result);
    }
  }

}