
import RouteConfig from 'vue-router'
// 左侧导航 一级标题
export enum ROUTE_SUPER_TITLE {
  // 主页 管理动态数据
  HOME = '首页',
  SYSTEM = '系统设置',
  SETTING = '基本信息管理',
  WARN = '报警管理',

  // ANALYSIS = '数据分析',
  // // 管理设备基础数据
  // DEV_INFO = '设备管理',
  // REMOTE = '远程协助',
  // STATUS = '状态总表',
}

// 为了消除魔法值
export enum ROUTE_NAME  {
  HOME = 'home',
  LOGIN = "login",
  DEV_DETAIL = 'devDetail'
}

// 一级图标  二级图标自己写
export const SUPER_ICON = {

  [ROUTE_SUPER_TITLE.HOME]: 'home',
  [ROUTE_SUPER_TITLE.SYSTEM]: 'apartment',

  // [ROUTE_SUPER_TITLE.ANALYSIS]: 'bar-chart',
  // [ROUTE_SUPER_TITLE.DEV_INFO]: 'home',
  // [ROUTE_SUPER_TITLE.REMOTE]: 'home',
  // [ROUTE_SUPER_TITLE.STATUS]: 'home',
  [ROUTE_SUPER_TITLE.SETTING]: 'setting',
  [ROUTE_SUPER_TITLE.WARN]: 'ring',
}

// 左侧导航
export interface Navi {
  title: string
  routeName: string
  path: string
  icon: string
  children?: Navi[]
}



export interface MyRouteMeta  {

    withOutNavi?: boolean // true - 该页面不需要左侧菜单栏,
    noShowSide?: boolean // true - 不显示在左侧导航栏
    
    title?: string // 导航按钮标题
    icon?: string // 导航按钮图标 详见  https://www.antdv.com/components/icon-cn/
    needPerm?: string // 页面所需权限 同后端权限code  单个权限码 暂时不考虑多权限情况
    
    superTitle?: string // 多级按钮时 一级标题
    superName?: string // 上层的路由名 控制左侧菜单按钮是否被激活
    groupName?: string // 分组标识
}