import * as HttpUtil from '@/util/HttpUtil'
import * as HttpTS from '@/type/system/HttpTS'
import * as SysDictTS from '@/type/system/SysDictTS'

export const getList = (params: SysDictTS.SysDictListReq): Promise<SysDictTS.SysDictListRes> => {
    params = JSON.parse(JSON.stringify(params))
    return HttpUtil.get("dict/list", params)
}

export const addOne = (mainData: SysDictTS.SysDictTS): Promise<HttpTS.HttpRes> => {
    return HttpUtil.post("dict/add", {mainJson: JSON.stringify(mainData)})
}

export const update = (template: SysDictTS.SysDictTS, key?: number | string): Promise<HttpTS.HttpRes> => {
    return HttpUtil.post("dict/update", {mainJson: JSON.stringify(template), key})
}

export const deleteGroup = (ids: number[]): Promise<HttpTS.HttpRes> => {
    return HttpUtil.post("dict/delete", {keyList: ids.join(HttpTS.ARRAY_FLAG)})
}


