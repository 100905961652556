import { Vue, Component } from "vue-property-decorator";
import { namespace } from "vuex-class";

import * as LoginAPI from "@/api/system/LoginAPI";
import * as UITS from "@/type/system/UITS";
import * as UserTS from "@/type/system/UserTS";

const MainStore = namespace("MainStore");
const LoginStore = namespace("LoginStore");

@Component({})
export default class LoginDialog extends Vue {
  @MainStore.Getter("loginDialogShow") loginDialogShow!: boolean;
  @MainStore.Action("closeLoginDialog") closeLoginDialog!: Function;
  @LoginStore.Getter("userInfo") userInfo!: UserTS.User;

  get show() {
    return this.loginDialogShow;
  }

  set show(newVal) {}

  loginObj = {
    username: "",
    password: "",
  };
  // 提交等待
  loading: UITS.Loading = new UITS.Loading();
  // 表单验证规则
  FORM_RULE = UITS.FORM_RULE;
  // 表单校验
  ruleObj: {
    [key: string]: any;
  } = {
    password: "",
  };

  mounted() {
    this.initData();
  }

  initData() {
    if (this.userInfo) {
      this.loginObj.username = this.userInfo.username || "";
    }
  }

  onClose() {
    this.closeLoginDialog && this.closeLoginDialog();
  }

  async onLogin() {
    const _form = this.$refs.mainForm as any;
    this.ruleObj = {};
    // 表单验证
    const formSure = await new Promise((resolve) => {
      _form.validate((validRes: boolean) => {
        resolve(validRes);
      });
    });
    if (!formSure) {
      return;
    }

    this.loading.spinning = true;
    LoginAPI.login(this.loginObj.username, this.loginObj.password).then(
      (res) => {
        this.loading.spinning = false;

        if (res.isSucc) {
          window.location.reload()
        }
      }
    );
  }
}
