import * as HttpUtil from '@/util/HttpUtil'
import * as HttpTS from '@/type/system/HttpTS'
import * as RoleTS from '@/type/system/RoleTS'

/**
 * 获取列表
 * @param param 
 * @returns 
 */
 export const getList = (params: RoleTS.RoleListReq): Promise<RoleTS.RoleListRes> =>  {
    return HttpUtil.get("role/list", params);
}

/**
 * 新增角色
 * @param role 
 * @param permList 
 * @returns 
 */
export const addOne = (role: RoleTS.Role, permList: string[]): Promise<HttpTS.HttpRes> => {
    return HttpUtil.post("role/add", {roleJson: JSON.stringify(role), permListJson: JSON.stringify(permList)});
}

/**
 * 批量修改
 * @param role 
 * @param ids 
 * @param permList 
 * @returns 
 */
export const updateGroup = (role: RoleTS.Role, ids: (string | number)[], permList: string[]): Promise<HttpTS.HttpRes> => {
    return HttpUtil.post("role/update", {roleJson: JSON.stringify(role), ids: JSON.stringify(ids), permListJson: JSON.stringify(permList)})
}

/**
 * 批量删除
 * @param ids 
 * @returns 
 */
export const deleteGroup = (ids: (string | number)[]): Promise<HttpTS.HttpRes> => {
    return HttpUtil.post("role/delete", {ids: JSON.stringify(ids)})
}