import { Vue, Component, Prop } from "vue-property-decorator";
import * as Echarts from "echarts";
import Numeral from 'numeral'

import * as UITS from "@/type/system/UITS";
import * as DevTS from "@/type/project/DeviceTS";
import * as DevAPI from "@/api/project/DevAPI";
import * as DevSensorAPI from "@/api/project/DevSensorAPI";
import * as DevSensorTS from "@/type/project/DeviceSensorTS";
import * as SensorTS from "@/type/project/SensorTS";
import * as DataSrcConfigTS from '@/type/project/DataSrcConfigTS'

import NaviDetail from "./component/NaviDetail.vue";
import DevEdit from "./component/DevEdit.vue";
import DevSensor from "./component/DevSensor.vue";

const LIST_ROUTE = "home";

@Component({
  components: {
    NaviDetail,
    DevEdit,
    DevSensor,
  },
})
export default class DD3DModel extends Vue {
  // 外来数据
  detailId = this.$route.query.id as string;

  // 固定类型
  DEV_MOVE_TYPE = DevTS.DEV_MOVE_TYPE;
  DATA_SRC_ENUM = DataSrcConfigTS.DATA_SRC_ENUM

  // 查询等待
  loading: UITS.Loading = new UITS.Loading("数据加载中...");
  // 主要数据
  mainData: DevTS.DeviceTS = new DevTS.DeviceTS();

  // 传感器数据对象
  sensorObj: SensorTS.SensorHistoryMap = {};
  // 设备-传感器映射
  devSensorArr: DevSensorTS.DeviceSensorTS[] = [];
  // echarts
  chartMap: { [key: string]: Echarts.ECharts } = {};

  created() {
    if (!this.detailId) {
      this.$router.replace({ name: "home" });
      return;
    }
    this.getMainData();
    this.getDevSensorList();
  }

  getMainData() {
    this.loading.spinning = true;
    DevAPI.detail(this.detailId, [DevTS.DEV_DETAIL_ADDINFO.SENSOR_DATA_GEO]).then(
      (res) => {
        this.loading.spinning = false;
        this.mainData = res.data;
      }
    );
  }

  getDevSensorList() {
    DevSensorAPI.getDetail(Number(this.detailId)).then((res) => {
      if (!res.isSucc) {
        return;
      }
      if (res.lastValMap) {
        this.sensorObj = res.lastValMap;
      }
      if (res.list) {
        this.devSensorArr = res.list.filter((item) => item.showIn3D);
      }
      // 节点渲染出来以后 驱动 echarts画图
      this.$nextTick(() => {
        this.initEchartAndSetData();
      });
    });
  }

  initEchartAndSetData() {
    const _chartMap: { [key: string]: Echarts.ECharts } = {};
    // 以 设备-传感器关联数组为核心 所有数据都基于这个出
    for (let i = 0; i < this.devSensorArr.length; i++) {
      const _key = this.getChartKey(this.devSensorArr[i].sensorCode || 0);
      const _dom = this.$refs[_key] as HTMLElement[]; // ??? 为啥是数组? 稳定吗 待查 TODO
      _chartMap[_key] = Echarts.init(_dom[0]);
      this.refreshChartData(
        _chartMap[_key],
        this.devSensorArr[i],
        this.sensorObj[this.devSensorArr[i].sensorCode || "unknow"]
      );
    }
    this.chartMap = _chartMap;
  }

  // TODO 以后优化 any
  refreshChartData(
    _chart: Echarts.ECharts,
    sensorObj: DevSensorTS.DeviceSensorTS,
    data?: any
  ) {

    // 先给基础的
    _chart.setOption(this.getChartOptionBasic())
    
    let _option = {};
    if (!data) {
      _option = {
        series: [
          {
            axisLine: {
              lineStyle: {
                color: [
                  [0.3, "#83ff64"],
                  [0.7, "#fff364"],
                  [1.0, "#ff6464"],
                ],
              }
            },
            detail: {
              formatter: "{value} " + '--',
            },
            data: [{
              value: 0
            }]
          }
        ]
      }
    } else {

      switch(sensorObj.sensorType) {
        // 电池电量
        case SensorTS.SENSOR_DATA_TABLE_NAME.BOTH: 
          _option = {
            series: [
              {
                axisLine: {
                  lineStyle: {
                    color: [
                      [0.3, "#ff6464"],
                      [0.7, "#fff364"],
                      [1.0, "#83ff64"],
                    ],
                  }
                },
                detail: {
                  formatter: "{value} %",
                },
                data: [{
                  value: Numeral(data.value / data.maxValue * 100).format('0,0.[00]')
                }]
              }
            ]
          }
        break;
  
        default: 
        _option = {
          series: [
            {
              axisLine: {
                lineStyle: {
                  color: [
                    [0.3, "#83ff64"],
                    [0.7, "#fff364"],
                    [1.0, "#ff6464"],
                  ],
                }
              },
              detail: {
                formatter: "{value} " + data.unit,
              },
              data: [{
                value: Numeral(data.value).format('0,0.[00]')
              }]
            }
          ]
        }
  
      }
    }
    _chart.setOption(_option);
  }

  getChartKey(sensorCode: string | number): string {
    return "CHART_" + sensorCode;
  }

  onResetData() {
    this.getMainData();
  }

  onBackList() {
    if (this.$route.name != LIST_ROUTE) {
      this.$router.replace({
        name: LIST_ROUTE,
      });
    }
  }

  // 打开编辑页
  onShowEdit() {
    const _edit = this.$refs.DevEdit as any;
    _edit.init(UITS.INIT_TYPE.UPDATE, this.mainData);
  }

  // 传感器配置 TODO  无权限的 给 detail
  onShowDevSensor() {
    const _edit = this.$refs.DevSensor as any;
    _edit.init(UITS.INIT_TYPE.UPDATE, this.mainData);
  }

  getChartOptionBasic() {
    return {
      series: [
        {
          type: "gauge",
          startAngle: 200,
          endAngle: -20,
          radius: '114%',
          center: ['50%', '64%'],
          // 坐标线
          axisLine: {
            lineStyle: {
              width: 6,
            },
          },
          // 中央指针
          pointer: {
            itemStyle: {
                color: 'auto'
            }
        },
          // 短分割线
          axisTick: {
            distance: 4,
            length: 4,
            lineStyle: {
              color: "auto",
              width: 2,
            },
          },

          // 带数字的长分割线
          splitLine: {
            distance: 4,
            length: 10,
            lineStyle: {
              color: "auto",
              width: 4,
            },
          },

          axisLabel: {
            color: "auto",
            distance: 10,
            fontSize: 14,
          },

          // 中央文本
          detail: {
            valueAnimation: true,
            color: "auto",
            fontSize: 16
          },
        },
      ],
    }
  }


}
