import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";

import * as HttpUtil from "@/util/HttpUtil";
import * as NumUtil from "@/util/NumUtil";
import * as HttpTS from "@/type/system/HttpTS";
import * as UITS from "@/type/system/UITS";
import * as ReSure from "@/components/global/ReSure";
import * as CDictTS from "@/type/system/CDictTS";
import * as DevSensorAPI from "@/api/project/DevSensorAPI";
import * as DevSensorTS from "@/type/project/DeviceSensorTS";
import * as DevTS from "@/type/project/DeviceTS";

import ShowLen from "@/components/normal/ShowLen.vue";
import DevSensorItem from "./DevSensorItem.vue";
import * as PrincipleTS from "@/type/project/PrincipleTS";

import * as PrincipleAPI from "@/api/project/PrincipleAPI";

/**
 * 总的传感器配置列表
 * 里面包含若干个传感器配置表单卡片
 * 只有只读和可编辑两种状态 没有新增状态
 */
@Component({
  components: {
    ShowLen,
    DevSensorItem,
  },
})
export default class DevSensor extends Vue {
  /** 初始化标识 */
  INIT_TYPE = UITS.INIT_TYPE;

  /** 是否显示编辑框 */
  show: boolean = false;
  // 添加中  一次添加一个
  adding: boolean = false;
  // 
  devObj: DevTS.DeviceTS = new DevTS.DeviceTS();
  /** 提交等待 */
  loading: UITS.Loading = new UITS.Loading("数据加载中...");
  // 设备-传感器关联
  mainData: DevSensorTS.DeviceSensorTS[] = [];

  /** 责任人列表 */
  principleSel: PrincipleTS.PrincipleTS[] = [];

  mounted() {
    this.getPrincipleSel();
  }

  /**
   * 获取当前设备已经绑定的传感器列表
   */
  init(_initType: UITS.INIT_TYPE, devObj: DevTS.DeviceTS) {
    if (!devObj || !devObj.id) {
      console.error("DeviceId is null!");
      return;
    }
    this.devObj = devObj;
    // 显示组件
    this.show = true;

    this.getDevSensorList();
  }

  getPrincipleSel() {
    const _req: PrincipleTS.PrincipleListReq =
      new PrincipleTS.PrincipleListReq();
    _req.isDel = CDictTS.IS_BAN.N;
    _req.pageSize = 9999;
    PrincipleAPI.getList(_req).then((res) => {
      this.loading.spinning = false;
      if (HttpUtil.checkSucc(res)) {
        this.principleSel = res.list || [];
      }
    });
  }

  // 获取传感器列表
  getDevSensorList() {
    this.adding = false;
    const _req = new DevSensorTS.DevSensorListReq();
    _req.pageSize = 99999;
    _req.deviceId = this.devObj.id;
    // 调整loading
    this.loading.spinning = true;

    DevSensorAPI.getList(_req).then((res) => {
      this.mainData = res.list || [];
      this.loading.spinning = false;
    });
  }

  onClose() {
    this.show = false;
    this.mainData = [];
  }

  // 新增一个
  onAddOne() {
    this.adding = true;
  }

  // 取消新增 TODO 考虑表单缓存问题
  onCancelAdd() {
    this.adding = false;
  }
  // 配置项变更后刷新关联传感器列表
  onChangeItem() {
    this.getDevSensorList()
  }
}
