import { Vue, Component, Prop } from "vue-property-decorator";

import * as UITS from "@/type/system/UITS";
import * as CDictTS from '@/type/system/CDictTS'
import * as HttpTS from '@/type/system/HttpTS'

import * as SensorTS from "@/type/project/SensorTS";
import * as SensorDataGeoTS from '@/type/project/SensorDataGeoTS'
import * as SensorAPI from "@/api/project/SensorAPI";
import * as DataSrcConfigTS from '@/type/project/DataSrcConfigTS'
import * as DataSrcConfigAPI from '@/api/project/DataSrcConfigAPI'
import {MapRes} from '@/components/baidu/MapEditBaidu'


import * as ReSure from "@/components/global/ReSure";
import SensorEditChromatography from './SensorEditChromatography.vue'
import SensorEditLNG from './SensorEditLNG.vue'
import SensorEditSia from './SensorEditSia.vue'

/**
 * 传感器编辑总表
 */
@Component({
  components: {
    SensorEditChromatography,
    SensorEditSia,
    SensorEditLNG
  },
})
export default class SensorEdit extends Vue {
  @Prop() onChange?: Function;
  /** 初始化标识 */
  INIT_TYPE = UITS.INIT_TYPE;
  FORM_RULE = UITS.FORM_RULE;
  /** 数据源下拉选 */
  DATA_SRC_ENUM = DataSrcConfigTS.DATA_SRC_ENUM
  // 数据源备选列表
  dataSrcConfigSel: DataSrcConfigTS.DataSrcConfigTS[] = []
  
  /** 是否显示编辑框 */
  show: boolean = false;
  /** 提交等待 */
  loading: UITS.Loading = new UITS.Loading("数据保存中...");
  /** 初始化参数 */
  initType: UITS.INIT_TYPE | '--' = '--';

  /** 主要数据 */
  mainData: SensorTS.SensorTS = new SensorTS.SensorTS();

  /** 初始位置信息 */
  geoInit: SensorDataGeoTS.SensorDataGeoTS = null;

  mounted() {
    this.getDataSrcConfigSel()
  }    

  // 获取数据源列表
  getDataSrcConfigSel() {
    const _req = new DataSrcConfigTS.DataSrcConfigListReq()
    _req.pageSize = 99999
    _req.isDel = CDictTS.IS_BAN.N
    DataSrcConfigAPI.getList(_req).then(res => {
      this.dataSrcConfigSel = res.list || []
    })
  }

  init(_initType: UITS.INIT_TYPE, _mainData: SensorTS.SensorTS) {
    console.log("_mainData===================", _mainData)
    // 显示组件
    this.show = true;
    // 调整loading
    this.loading.spinning = false;

    // 注入数据
    if (!_mainData) {
      _mainData = new SensorTS.SensorTS();
    } else {
      _mainData = {
        ..._mainData,
      };
    }
    
    // 特殊处理 lng数据
    if (_mainData.dataSrcId == DataSrcConfigTS.DATA_SRC_ENUM.ZHONGCHE_LNG) {
      const _arr =  _mainData.sensorCode && _mainData.sensorCode.split(HttpTS.MID_LINE) || []
      _mainData.LNGDeviceId = _arr[0]
      _mainData.LNGType = _arr[1]
    }

    this.mainData = _mainData;
    this.initType = _initType;
  }

  async onSave() {
    const _mainData = { ...this.mainData }

    const _mainForm = this.$refs.mainForm as any;

    const _validSucc = await new Promise((resolve) => {
      _mainForm.validate((valid: boolean) => {
        resolve(valid);
      });
    });
    if (!_validSucc) {
      return;
    }

    // 处理子表单验证
    const childForm = this.$refs.childForm as any;
    const childFormSucc = await childForm.formValidate()
    if (!childFormSucc) {
      return;
    }

    const _sure = await ReSure.saveSure();
    if (!_sure) {
      return;
    }

    this.loading.spinning = true;

    if (this.initType == this.INIT_TYPE.UPDATE) {
      SensorAPI.update(_mainData, _mainData.sensorCode).then((res) => {
        this.onChange && this.onChange();
        this.show = false;
      });
    } else if (this.initType == this.INIT_TYPE.ADD) {
      // TODO 新增 用sensorCode去关联位置数据
      SensorAPI.addOne(_mainData, this.geoInit).then((res) => {
        this.onChange && this.onChange();
        this.show = false;
      });
    }
  }

  /** 地图选点 */
  onChangeGeoData(data: MapRes) {

    this.geoInit = data
  }

  async onClose(cancelSave: boolean) {

    // 直接关闭 
    if (cancelSave) {
      const sure = await ReSure.saveSure("是否放弃保存 ?", "放弃保存")
      if (!sure) {
        return;
      }
    }

    // 清空数据
    const _mainForm = this.$refs.mainForm as any;
    _mainForm.resetFields();

    // 清空子表单数据
    const childForm = this.$refs.childForm as any;
    childForm && childForm.onClean()

    // 清空初始坐标点
    this.geoInit = null
    // 关闭对话框
    this.show = false;
  }
}
