import { Vue, Component, Prop } from "vue-property-decorator";
import Moment from 'moment'

import * as UITS from "@/type/system/UITS";
import * as HttpTS from '@/type/system/HttpTS'
import * as DevTS from "@/type/project/DeviceTS";
import * as DevSensorTS from "@/type/project/DeviceSensorTS";
import * as SensorTS from "@/type/project/SensorTS";
import * as DeviceUIConfigTS from '@/type/project/DeviceUIConfigTS'

import * as DevAPI from "@/api/project/DevAPI";
import * as DevSensorAPI from "@/api/project/DevSensorAPI";
import * as SensorHistoryAPI from "@/api/project/SensorHistoryAPI";
import * as ObjUtil from '@/util/ObjUtil'
import * as NumUtil from '@/util/NumUtil'

import NaviDetail from "./component/NaviDetail.vue";
import DevEdit from "./component/DevEdit.vue";
import DevSensor from "./component/DevSensor.vue";
import ChartBig from "./component/ChartBig.vue";
import ChartConfig from "./component/ChartConfig.vue";
import ChartSmall from "./component/ChartSmall.vue";

import {SensorData} from "./component/ChartSmall"

const LIST_ROUTE = "home";

/**
 * 趋势页面
 * 处理逻辑:
 *  获取设备信息和ui布局信息
 *  获取设备相关的传感器信息
 *  根据传感器 结合查询条件 获取相关的历史数据
 *    两种模式: 实施更新模式: 获取最近时间点的数据并开启websocket监听; 历史查看模式: 根据指定的时间点查看数据
 *  根据ui布局配置 显示传感器
 *  点击大图后 将数据导入
 *
 */
@Component({
  components: {
    NaviDetail,
    DevEdit,
    DevSensor,
    ChartBig,
    ChartConfig,
    ChartSmall,
  },
})
export default class DDHistoryChart extends Vue {
  // 当前设备id
  detailId = this.$route.query.id as string;

  // 每个传感器给个图
  get chartDevSensor(): {data: SensorData[], id: string}[] {
    return this.devSensor.map(item => ({
      data: [{
        info: item,
        data: this.sensorHistory[item.sensorCode]
      }],
      id: String(item.sensorCode) + 'simgle',
      size: NumUtil.getRandom(0, 9999)
    }))
  }

  // 根据聚合配置给图
  get chartGroupByConfig(): {data: SensorData[], id: string, size: number, config: DeviceUIConfigTS.DeviceUIConfigTS}[] {
    return this.devUIConfig.map(item => {
      let result: SensorData[]= [];
      if (item.sensorCode) {
        // 多个code 逗号分隔
        result = item.sensorCode.split(HttpTS.ARRAY_FLAG).map(itemTwo => ({
          // 获取关联对象
          info: this.devSensorMap[itemTwo],
          // 获取历史数据
          data: this.sensorHistory[itemTwo]
        }))
      }
      return {
        data: result,
        id: String(item.id) + 'group',
        size: NumUtil.getRandom(0, 9999),
        config: item
      };
    })
  }

  get rangePickerData() {
    return [Moment(this.sensorHistoryDataReq.timeStart), Moment(this.sensorHistoryDataReq.timeEnd)]
  }
 
  set rangePickerData(newVal) {
    if (newVal[0]) {
      this.sensorHistoryDataReq.timeStart = newVal[0].unix() * 1000
    }
    if (newVal[1]) {
      this.sensorHistoryDataReq.timeEnd = newVal[1].unix() * 1000
    }
  }

  // 查询等待
  loading: UITS.Loading = new UITS.Loading("数据加载中...");

  // 设备-传感器关联表
  devSensor: DevSensorTS.DeviceSensorTS[] = [];
  devSensorMap: {[key: string]: DevSensorTS.DeviceSensorTS} = {}

  // 设备基础信息表 + 页面布局配置
  devInfo: DevTS.DeviceTS = new DevTS.DeviceTS();
  // 设备ui配置 一条记录一个图
  devUIConfig: DeviceUIConfigTS.DeviceUIConfigTS[] = []
  // 传感器历史数据请求参数
  sensorHistoryDataReq = new SensorHistoryAPI.SensorHistoryMulListReq();
  // 统一查询传感器的历史数据, 保持时间一致 sensorCode - 数据
  sensorHistory: any = {};
  // 显示大图
  showBig: boolean = false;
  // 当前大图展示的数据
  bigDataChartOption: any = {}
  // 自定义的附加参数
  bigChartOption: any = {}
  // 大图标题
  bigTitle = "综合指标"

  // TODO 用Promise语法改
  async created() {
    this.loading.spinning = true;
    // 获取设备基础信息
    this.getDevInfo();
    // 获取设备-传感器绑定关系
    await this.getDevSensorList();
    // 获取传感器历史数据
    await this.getSensorHistory();

    this.loading.spinning = false;

  }

  async getDevSensorList() {
    const res = await DevSensorAPI.getList({
      deviceId: this.detailId,
      pageSize: 9999,
      currentPage: 1,
    });
    if (res.isSucc) {
      this.devSensor =
        res.list?.filter(
          (item) => item.sensorType != SensorTS.SENSOR_DATA_TABLE_NAME.GEO
        ) || [];
      this.devSensorMap = ObjUtil.arrToObj(this.devSensor, "sensorCode")
    }
  }

  async getSensorHistory() {

    this.loading.spinning = true;
    this.sensorHistoryDataReq.sensorList = this.devSensor;
    const res = await SensorHistoryAPI.getMulList(this.sensorHistoryDataReq)
    this.loading.spinning = false;
    if (res.isSucc && res.data) {
      this.sensorHistory = res.data;
    }
  }

  getDevInfo() {
    DevAPI.detail(this.detailId).then((res) => {
      if (res.isSucc) {
        this.devInfo = res.data;
        if (res.data && res.data.uiConfigList) {
          this.devUIConfig = res.data.uiConfigList
        }
      }
    });
  }

  //  变更查询条件
  onChangeTimeRange() {
    // 获取传感器历史数据
    this.getSensorHistory();
  }

  // 刷新数据
  async onResetData() {

    this.loading.spinning = true;
    // 获取设备基础信息
    this.getDevInfo();
    // 获取设备-传感器绑定关系
    await this.getDevSensorList();
    // 获取传感器历史数据
    this.getSensorHistory();

    this.loading.spinning = false;
  }

  onBackList() {
    if (this.$route.name != LIST_ROUTE) {
      this.$router.replace({
        name: LIST_ROUTE,
      });
    }
  }

  // 修改配置
  onChangeConfig() {
    // 刷新设备信息
    this.getDevInfo()
  }

  // 以编辑模式打开配置框
  onOpenConfigUpdate(_data: DeviceUIConfigTS.DeviceUIConfigTS) {
    const ChartConfig = this.$refs.ChartConfig as any
    ChartConfig.onOpenConfigModal(UITS.INIT_TYPE.UPDATE, _data)
  }
  // 打开编辑页
  onShowEdit() {
    const _edit = this.$refs.DevEdit as any;
    _edit.init(UITS.INIT_TYPE.UPDATE, this.devInfo);
  }
  // 传感器配置 TODO  无权限的 给 detail
  onShowDevSensor() {
    const _edit = this.$refs.DevSensor as any;
    _edit.init(UITS.INIT_TYPE.UPDATE, this.devInfo);
  }

  // 打开大图
  onShowBig(_data: any, title: string, chartOption: any){
    this.bigDataChartOption = _data
    this.bigTitle = title;
    this.bigChartOption = chartOption
    this.showBig = true
  }

  onCloseBig() {
    this.bigDataChartOption = {}
    this.bigChartOption = {}
    this.bigTitle = "综合指标"
    this.showBig = false
  }
}
