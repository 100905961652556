import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import * as UITS from "@/type/system/UITS";
import * as CDictTS from '@/type/system/CDictTS'
import * as ObjUtil from "@/util/ObjUtil";
import * as HttpUtil from "@/util/HttpUtil";

import * as DeviceTS from "@/type/project/DeviceTS";
import * as SensorDataGeoTS from "@/type/project/SensorDataGeoTS";
import * as DevAPI from "@/api/project/DevAPI";
import * as CountAPI from '@/api/project/CountAPI'
import * as SysDictTS from '@/type/system/SysDictTS'
import * as DataSrcConfigTS from '@/type/project/DataSrcConfigTS'
import * as DataSrcConfigAPI from '@/api/project/DataSrcConfigAPI'

import DevEdit from "@/views/dev/component/DevEdit.vue";

const LoginStore = namespace("LoginStore")

// 列表类型
enum LIST_MAP {
  MAP = "map",
  LIST = "list",
}

const DETAIL_ROUTE = "DevDetail";

@Component({
  components: {
    DevEdit,
  },
})
export default class HomeView extends Vue {

  @LoginStore.Getter("dict") dict: SysDictTS.SysDictTS[];
  // 设备状态
  get STATUS_SEL() {
    return this.dict.filter(item => item.type == SysDictTS.TYPE.DEVICE_STATUS)
  }

  // 真空设备子分类
  get DEV_TYPE_SEL() {
    return this.dict.filter(item => item.type == SysDictTS.TYPE.DEVICE_DEV_TYPE)
  }

  // 编辑页初始化类型
  INIT_TYPE = UITS.INIT_TYPE;
  // 列表/地图切换
  LIST_MAP = LIST_MAP;

  // 设备移动类型
  DEV_MOVE_TYPE = DeviceTS.DEV_MOVE_TYPE;
  // 移动类型
  MOVE_TYPE_SEL = DeviceTS.MOVE_TYPE_SEL;
  // 移动类型下拉选
  MOVE_TYPE_KV_OBJ = DeviceTS.MOVE_TYPE_KV_OBJ;

  // 数据源备选列表
  dataSrcConfigSel: DataSrcConfigTS.DataSrcConfigTS[] = []

  DATA_SRC_ENUM = DataSrcConfigTS.DATA_SRC_ENUM

  /** 地图实例对象 */
  myBMap: any = null;
  // 地图参数
  mapOption: UITS.MapOption = new UITS.MapOption();
  // 地图加载动画
  mapLoading = new UITS.Loading("地图加载中...");
  // 设备地图坐标点的数组, 如果坐标为0 则不加到数组中
  devPointArr: DeviceTS.DevWithGeo[] = [];
  // 全部设备
  devList: DeviceTS.DevWithGeo[] = []
  // 信息窗
  infoWindow: DeviceTS.DevWithGeo | null = null;
  // 显示信息框
  infoWindowShow: boolean = false;

  // 搜索框展开状态
  reqAreaShow = true;
  // 请求条件
  reqParam: DeviceTS.DevListReq = new DeviceTS.DevListReq();
  // 切换按钮
  listMapTurn: LIST_MAP = LIST_MAP.MAP;
  // 城市备选列表
  cityList: UITS.TreeData[] = [];

  // 顶部统计
  totalArr: SysDictTS.SysDictTS[] = []

  created() {
    this.onSearch();
    this.getDataSrcConfigSel()
  }

  onSearch() {
    
    // 获取统计数据
    this.getTotal();
    
    this.reqParam.pageSize = 99999;
    this.mapLoading.spinning = true;
    DevAPI.getList(this.reqParam).then((res) => {
      this.mapLoading.spinning = false;
      const result: DeviceTS.DevWithGeo[] = [];
      if (HttpUtil.checkSucc(res) && res.list) {
        // 暂存坐标数据
        const _geoObj = ObjUtil.arrToObj(res.geoList, "deviceId");

        for (let i = 0; i < res.list.length; i++) {
          const _geo = _geoObj[res.list[i].id!] as SensorDataGeoTS.SensorDataGeoTS;
          if (!_geo) {
            continue;
          }
          delete _geo.id;
          if (_geo && _geo.lng && _geo.lat) {
            result.push(Object.assign(res.list[i], _geo));
          }
        }
      }

      if (!res.list) {
        this.$message.error("暂无数据!");
      }
      this.devPointArr = result;
      this.devList = res.list
    });
  }
  // 获取数据源列表
  getDataSrcConfigSel() {
    const _req = new DataSrcConfigTS.DataSrcConfigListReq()
    _req.pageSize = 99999
    _req.isDel = CDictTS.IS_BAN.N
    DataSrcConfigAPI.getList(_req).then(res => {
      this.dataSrcConfigSel = res.list || []
    })
  }
  // 渲染在内容容器
  randerMainBody() {
    return window.document.getElementById("MainBody");
  }

  // 渲染容器
  randerHomeSearchPop() {
    return window.document.getElementById("HomeSearchPop");
  }

  onChangeDevBasicType(newVal: number) {
    this.reqParam = Object.assign( new DeviceTS.DevListReq(), {dataSrcId: newVal} )
  }
  // 选择城市
  onChangeCity(a: any) {
    console.log("=---------------", a);
  }

  // 选择设备类型
  onChangeDevType() {
    console.log("");
  }

  // 选择设备状态
  onChangeDevStatus() {
    console.log();
  }

  // 地图初始化
  onMapReady(event: any) {
    this.mapLoading.spinning = false;
    // 地图函数库对象
    this.myBMap = event.BMap as any;
  }

  // 地图缩放
  onMapChangeZoom(event: any) {
    const _zoom = event.target.getZoom();
    if (_zoom) {
      this.mapOption.zoom = _zoom;
    }
  }

  // 点击设备点
  onPointClick(dev: DeviceTS.DevWithGeo) {
    this.infoWindow = dev;
    this.infoWindowShow = true;
  }

  // 跳转登录页
  onGoDetail(curDev: any) {
    if (this.$route.name != DETAIL_ROUTE) {
      this.$router.push({
        name: DETAIL_ROUTE,
        query: { id: curDev.id },
      });
    }
  }

  // 打开编辑页
  onShowEdit(initType: UITS.INIT_TYPE) {
    const _edit = this.$refs.DevEdit as any;
    _edit.init(initType, null);
  }

  getTotal() {
    const _arr: SysDictTS.SysDictTS[] = []
    CountAPI.homeCount().then(res => {
      if (res.isSucc) {
        const _obj = res.data
        if (_obj) {
          _arr.push({key: _obj.devCount, value: '总数'});
          _arr.push({key: _obj.devHasMap, value: '已定位'});
          _arr.push({key: _obj.devNoMap, value: '未定位'});
          _arr.push({key: _obj.devOnline, value: '在线'});
          _arr.push({key: _obj.devOffline, value: '离线'});
          _arr.push({key: _obj.devNoCollection, value: '未链接'});
          _arr.push({key: _obj.devWarn, value: '活跃报警', isActive: true});
        }
        this.totalArr = _arr;
      }
    })
  }
}
