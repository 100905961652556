var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"ChartConfig"},[_c('div',[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.onOpenConfigModal(_vm.INIT_TYPE.ADD)}}},[_vm._v(" 新增趋势图配置 ")])],1),_c('a-modal',{attrs:{"footer":null,"width":"40%","dialog-style":{ top: '64px' },"maskClosable":false},on:{"cancel":function($event){_vm.show = false}},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('div',[_c('a-form-model',{ref:"mainForm",attrs:{"model":_vm.mainData,"labelCol":{ span: 6 },"wrapperCol":{ span: 16 }}},[_c('a-form-model-item',{attrs:{"label":"趋势图名称","prop":"chartName","rules":[
            { required: true, message: '请填写趋势图名称, 例: 温度趋势对照!' },
          ]}},[_c('a-input',{staticClass:"def_inp_only",attrs:{"placeholder":"请填写趋势图名称, 例: 温度趋势对照!","maxLength":_vm.FORM_RULE.MAX_LENG_32},model:{value:(_vm.mainData.chartName),callback:function ($$v) {_vm.$set(_vm.mainData, "chartName", $$v)},expression:"mainData.chartName"}},[_c('ShowLen',{attrs:{"slot":"suffix","max":_vm.FORM_RULE.MAX_LENG_32,"len":(_vm.mainData.chartName && _vm.mainData.chartName.length) || 0},slot:"suffix"})],1)],1),_c('a-form-model-item',{attrs:{"label":"监控项目","prop":"sensorIdArr","rules":[
            {
              required: true,
              type: 'array',
              message: '至少选择一项监控项目!',
            },
          ]}},[(_vm.deviceSensorList)?_c('a-select',{staticClass:"def_select",attrs:{"mode":"multiple","placeholder":"请选择监控项目"},model:{value:(_vm.mainData.sensorIdArr),callback:function ($$v) {_vm.$set(_vm.mainData, "sensorIdArr", $$v)},expression:"mainData.sensorIdArr"}},_vm._l((_vm.deviceSensorList),function(item){return _c('a-select-option',{key:item.sensorCode,attrs:{"value":item.sensorCode}},[_vm._v(_vm._s(item.itemName)+" 单位: "+_vm._s(item.sensorUnit)+" ")])}),1):_vm._e()],1),_c('a-form-model-item',{attrs:{"label":"Y轴数值显示形式"}},[_c('a-select',{staticClass:"def_select",attrs:{"placeholder":"请选择Y轴数值显示形式"},model:{value:(_vm.mainData.chartOption.yAxisType),callback:function ($$v) {_vm.$set(_vm.mainData.chartOption, "yAxisType", $$v)},expression:"mainData.chartOption.yAxisType"}},[_c('a-select-option',{attrs:{"value":"value"}},[_vm._v("数值 ")]),_c('a-select-option',{attrs:{"value":"log"}},[_vm._v("对数 ")])],1)],1)],1)],1),_c('div',{staticClass:"ChartConfig_foot"},[(_vm.initType == _vm.INIT_TYPE.UPDATE)?_c('a-button',{staticClass:"mg_r_16",attrs:{"type":"danger"},on:{"click":_vm.onDelete}},[_vm._v(" 删除此趋势图 ")]):_vm._e(),_c('a-button',{staticClass:"mg_r_16",attrs:{"type":"primary"},on:{"click":_vm.onSubmit}},[_vm._v(" 保存 ")]),_c('a-button',{staticClass:"mg_r_16",attrs:{"type":"default"},on:{"click":function($event){_vm.show = false}}},[_vm._v(" 取消 ")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }