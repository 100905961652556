import { Vue, Component, Prop } from "vue-property-decorator";
import Moment from 'moment'
import * as UITS from "@/type/system/UITS";
import * as HttpTS from '@/type/system/HttpTS'
import * as DevTS from "@/type/project/DeviceTS";
import * as WarnMsg from '@/type/project/WarnMsgTS'
import * as DeviceSensorType from '@/type/project/DeviceSensorTS'
import * as DevAPI from '@/api/project/DevAPI'
import * as WarnAPI from '@/api/project/WarnAPI'
import * as DeviceSensorAPI from '@/api/project/DevSensorAPI'

import NaviDetail from "./component/NaviDetail.vue";
import DevEdit from "./component/DevEdit.vue";
import DevSensor from './component/DevSensor.vue'

const LIST_ROUTE = 'home'

@Component({
  components: {
    NaviDetail,
    DevEdit,
    DevSensor
  },
})
export default class DDWarn extends Vue {

  detailId = this.$route.query.id as string;

  get rangePickerData() {
    const result = [Moment(this.warnReq.crTimeStart), Moment(this.warnReq.crTimeEnd)]
    return result
  }
 
  set rangePickerData(newVal) {
    if (newVal[0]) {
      this.warnReq.crTimeStart = newVal[0].unix() * 1000
    }
    if (newVal[1]) {
      this.warnReq.crTimeEnd = newVal[1].unix() * 1000
    }
  }

  Moment = Moment
  ARRAY_FLAG = HttpTS.ARRAY_FLAG
  TIME = UITS.TIME
  // 查询等待
  loading: UITS.Loading = new UITS.Loading("数据加载中...");
  // 报警等级备选
  warnLevelObj = WarnMsg.warnLevelObj
  // 设备数据
  devInfo: DevTS.DeviceTS = new DevTS.DeviceTS();
  // 列表数据
  mainData: WarnMsg.WarnListRes = new WarnMsg.WarnListRes
  // 主数据查询请求
  warnReq: WarnMsg.WarnListReq = new WarnMsg.WarnListReq
  // 监控项备选
  deviceSensorSel: DeviceSensorType.DeviceSensorTS[] = []

  created() {
    // TODO 获取数据 初始化页面
    this.getMainData();
    // 获取监控项备选
    this.getDeviceSensorSel();

    this.onSearch();
    console.log("============create", this.warnReq)
  }

  // 设置 warnReq  deviceId

  getMainData() {
    this.loading.spinning = true;
    DevAPI.detail(this.detailId, [DevTS.DEV_DETAIL_ADDINFO.SENSOR_DATA_GEO]).then(
      (res) => {
        this.loading.spinning = false;
        this.devInfo = res.data;
      }
    );
  }
  getDeviceSensorSel() {
    DeviceSensorAPI.getList(new DeviceSensorType.DevSensorListReq(this.detailId, 99999)).then(res => {
      if (res.isSucc) {
        this.deviceSensorSel = res.list
      }
    })
  }

   // 搜索列表
   onSearch(page?: HttpTS.Page) {
     this.warnReq.deviceId = Number(this.detailId)
    if (page && page instanceof HttpTS.Page) {
      Object.assign(this.warnReq, page);
    } else {
      Object.assign(this.warnReq, new HttpTS.Page());
    }
    this.loading.spinning = true;
    WarnAPI.getList(this.warnReq).then((res) => {
      this.loading.spinning = false;
      if (res.isSucc) {
        this.mainData = res
      }
    });
  }

  // 换页
  onChangeTable(page: UITS.TablePage) {
    const _page = new HttpTS.Page();
    _page.currentPage = page.current;
    _page.pageSize = page.pageSize;
    this.onSearch(_page);
  }

  onReset() {
    this.warnReq = new WarnMsg.WarnListReq
    this.onSearch()
  }

  onResetData() {
    this.getMainData()
  }

  onBackList() {
    if (this.$route.name != LIST_ROUTE) {
      this.$router.replace({
        name: LIST_ROUTE,
      });
    }
  }

  // 打开编辑页
  onShowEdit() {
    const _edit = this.$refs.DevEdit as any;
    _edit.init(UITS.INIT_TYPE.UPDATE, this.devInfo);
  }
  // 传感器配置 TODO  无权限的 给 detail
  onShowDevSensor() {
    
    const _edit = this.$refs.DevSensor as any;
    _edit.init(UITS.INIT_TYPE.UPDATE, this.devInfo);
  }
}
