var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"LoginView"}},[_c('div',{staticClass:"background"},[_c('div',{staticClass:"login_dialog"},[_vm._m(0),_c('a-form-model',{ref:"mainForm",attrs:{"model":_vm.loginObj}},[_c('a-form-model-item',{attrs:{"prop":"username","rules":[
            { required: true, message: '用户名不能为空' },
            {
              pattern: _vm.FORM_RULE.REP_NO_CN,
              message: '仅支持数字, 字母以及 _, +, =, @, .',
            },
            { validator: (r, v, cb) => (_vm.ruleObj.username && cb(_vm.ruleObj.username)) || cb() },
          ]}},[_c('a-input',{attrs:{"placeholder":"用户名"},model:{value:(_vm.loginObj.username),callback:function ($$v) {_vm.$set(_vm.loginObj, "username", $$v)},expression:"loginObj.username"}},[_c('a-icon',{staticClass:"u_icon_color_in",attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-model-item',{attrs:{"prop":"password","rules":[
            { required: true, message: '密码不能为空' },
            {
              pattern: _vm.FORM_RULE.REP_NO_CN,
              message: '仅支持数字, 字母以及 _, +, =, @, .',
            },
          ]}},[_c('a-input',{attrs:{"placeholder":"密码","type":"password"},model:{value:(_vm.loginObj.password),callback:function ($$v) {_vm.$set(_vm.loginObj, "password", $$v)},expression:"loginObj.password"}},[_c('a-icon',{staticClass:"u_icon_color_in",attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1)],1),_c('div',{staticClass:"btn_row"},[_c('a-button',{staticClass:"login_btn",attrs:{"type":"primary","loading":_vm.loading.spinning},on:{"click":_vm.onLogin}},[_vm._v(" 登 "),_c('i',{staticClass:"u_space"}),_vm._v(" 录 ")]),_c('a-button',{staticClass:"reset_btn",on:{"click":_vm.onReset}},[_vm._v(" 重 "),_c('i',{staticClass:"u_space"}),_vm._v(" 置 ")])],1)],1)]),_vm._m(1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"title_row"},[_c('span',{staticClass:"login_text"},[_vm._v("账号登录")]),_c('span',{staticClass:"login_text_en"},[_vm._v("To login")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"foot_row"},[_c('div',{staticStyle:{"display":"flex","height":"100%","align-items":"center","padding":"0 32px"}},[_c('a',{staticStyle:{"color":"#666666"},attrs:{"target":"_blank","href":"http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=21010602000837"}},[_c('img',{staticStyle:{"height":"24px"},attrs:{"src":require("@/assets/logo_gaba.png")}}),_c('span',[_vm._v(" 辽公网安备 21010602000837号 ")])])]),_c('a',{staticStyle:{"color":"#000000"},attrs:{"href":"https://beian.miit.gov.cn/","target":"_blank"}},[_vm._v("备案号: 辽ICP备2023002445号-1")])])
}]

export { render, staticRenderFns }