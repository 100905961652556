import * as HttpTS from "@/type/system/HttpTS";

export class SubstanceListReq extends HttpTS.ListReq {
}

export class SubstanceListRes extends HttpTS.ListRes<SubstanceTS> {}


export class SubstanceTS { 

/** 介质ID 主键 [关联键] */
id?: number;
/** 介质名称 */
name?: string;
/** 介质描述 */
description?: string;
/** 是否删除 1 -是 0 -否 */
isDel?: number;
/** 创建人  [关联 = 用户] */
crUser?: string;
/** 创建时间 */
crTime?: number;
/** 修改时间 */
upTime?: number;
}