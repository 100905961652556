import { Vue, Component, Prop } from "vue-property-decorator";

import * as HttpTS from "@/type/system/HttpTS";
import * as HttpUtil from "@/util/HttpUtil";
import * as UITS from "@/type/system/UITS";
import * as CDictTS from "@/type/system/CDictTS";
import * as ObjUtil from "@/util/ObjUtil";

import * as UserTS from "@/type/system/UserTS";
import * as UserAPI from "@/api/system/UserAPI";
import UserEdit from "./components/UserEdit.vue";
@Component({
  components: {
    UserEdit,
  },
})
export default class UserListView extends Vue {
  // 禁用备选
  BAN_SEL = CDictTS.BAN_SEL;
  // 编辑页初始化类型
  INIT_TYPE = UITS.INIT_TYPE;
  // 根据值获取显示值
  getValueByKey = ObjUtil.getValueByKey;
  // 查询条件
  reqObj: UserTS.UserListReq = new UserTS.UserListReq();
  // 响应结果
  mainData: UserTS.UserListRes = {
    page: new HttpTS.Page(),
  };
  // 查询等待
  loading: UITS.Loading = new UITS.Loading();
  
  created() {
    this.onSearch();
  }

  // 搜索列表
  onSearch(page?: HttpTS.Page) {
    if (page && page instanceof HttpTS.Page) {
      Object.assign(this.reqObj, page);
    } else {
      Object.assign(this.reqObj, new HttpTS.Page());
    }
    this.loading.spinning = true;
    UserAPI.getList(this.reqObj).then((res) => {
      this.loading.spinning = false;
      if (HttpUtil.checkSucc(res)) {
        this.mainData = res;
      }
    });
  }

  // 换页
  onChangeTable(page: UITS.TablePage) {
    const _page = new HttpTS.Page();
    _page.currentPage = page.current;
    _page.pageSize = page.pageSize;
    this.onSearch(_page);
  }

  // 打开编辑页
  onShowEdit(initType: UITS.INIT_TYPE, data?: UserTS.User) {
    const _edit = this.$refs.UserEdit as any;
    _edit.init(initType, data);
  }
  
  onReset() {
    this.reqObj = new UserTS.UserListReq();
    this.onSearch()
  }
}
