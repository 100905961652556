import * as HttpUtil from '@/util/HttpUtil'
import * as HttpTS from '@/type/system/HttpTS'
import * as MainLocal from '@/util/MainLocal'
import sha1 from 'sha1'
import WebSocketUtil from '@/util/WebSocketUtil'

export const login = async (username: string, password: string): Promise<HttpTS.LoginRes> => {
  password = sha1(password)
  const res = await HttpUtil.get('login', { username, password })
  if (res.isSucc ) {
    MainLocal.setLoginStatus(true)
  }
  return res
}

export const logout = (): Promise<HttpTS.HttpRes> => {
  
  MainLocal.setLoginStatus(false)
  WebSocketUtil.disconnect()
  return HttpUtil.get('logout', {})
}

export const getUserInfo = (): Promise<HttpTS.LoginRes> => {
  return HttpUtil.get("curuser/userinfo", {})
}