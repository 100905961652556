import {Vue, Component, Prop} from 'vue-property-decorator'
import moment from 'moment';

import * as NumUtil from '@/util/NumUtil'
import * as HttpTS from "@/type/system/HttpTS";
import * as HttpUtil from "@/util/HttpUtil";
import * as UITS from "@/type/system/UITS";
import * as CDictTS from "@/type/system/CDictTS";
import * as ObjUtil from "@/util/ObjUtil";

import * as DevAPI from '@/api/project/DevAPI'
import * as DevTS from '@/type/project/DeviceTS'
import * as SensorDataGeoTS from '@/type/project/SensorDataGeoTS'
import { DeviceSensorTS } from '@/type/project/DeviceSensorTS';
import * as DataSrcConfigTS from '@/type/project/DataSrcConfigTS'
import * as SensorTS from '@/type/project/SensorTS'

import DevEdit from './component/DevEdit.vue'
import NaviDetail from './component/NaviDetail.vue'
import DevSensor from './component/DevSensor.vue'

const LIST_ROUTE = 'home'

@Component({
  components: {
    NaviDetail,
    DevEdit,
    DevSensor
  },
})
export default class DevDetail extends Vue {
  // 路由值
  detailId = this.$route.query.id as string;

  /** 时间格式 */
  TIME = UITS.TIME;

  // 工具方法
  moment = moment;
  lengthFormat = NumUtil.lengthFormat;
  massFormat = NumUtil.massFormat;
  volumeFormat = NumUtil.volumeFormat;
  pressureFormat = NumUtil.pressureFormat;

  // 查询等待
  loading: UITS.Loading = new UITS.Loading("数据加载中...");
  // 主要数据
  mainData: DevTS.DeviceTS = new DevTS.DeviceTS();
  // 位置信息
  sensorGeoData: SensorDataGeoTS.SensorDataGeoTS = new SensorDataGeoTS.SensorDataGeoTS();

  devSensorList: DeviceSensorTS[] = []

  get vacuumSensorCode() {
    const one = this.devSensorList.find(item => 
      item.dataSrcId == DataSrcConfigTS.DATA_SRC_ENUM.SIA_INNER 
      && item.sensorCode.indexOf(SensorTS.SIA_INNER_SENSOR.VACUUM) != -1);

      if (!one) {
          return "未绑定"
      }

      return one.sensorCode.replace(HttpTS.MID_LINE, "").replace(SensorTS.SIA_INNER_SENSOR.VACUUM, "")

  }

  created() {
    // TODO 获取数据 初始化页面
    this.getMainData();
  }

  getMainData() {
    this.loading.spinning = true;
    DevAPI.detail(this.detailId, [DevTS.DEV_DETAIL_ADDINFO.SENSOR_DATA_GEO]).then(
      (res) => {
        this.loading.spinning = false;
        this.mainData = res.data;
        this.devSensorList = res.list
        if (res.sensorGeo) {
          this.sensorGeoData = res.sensorGeo;
        }
      }
    );
  }

  onResetData() {
    this.getMainData()
  }

  onBackList() {
    if (this.$route.name != LIST_ROUTE) {
      this.$router.replace({
        name: LIST_ROUTE,
      });
    }
  }

  // 打开编辑页
  onShowEdit() {
    const _edit = this.$refs.DevEdit as any;
    _edit.init(UITS.INIT_TYPE.UPDATE, this.mainData);
  }

  // 传感器配置 TODO  无权限的 给 detail
  onShowDevSensor() {
    
    const _edit = this.$refs.DevSensor as any;
    _edit.init(UITS.INIT_TYPE.UPDATE, this.mainData);
  }
}