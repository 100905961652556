import * as HttpUtil from '@/util/HttpUtil'
import * as HttpTS from '@/type/system/HttpTS'
import * as DataSrcConfigTS from '@/type/project/DataSrcConfigTS'

export const getList = (params: DataSrcConfigTS.DataSrcConfigListReq): Promise<DataSrcConfigTS.DataSrcConfigListRes> => {
    params = JSON.parse(JSON.stringify(params))
    return HttpUtil.get("datasrc/list", params)
}

export const addOne = (mainData: DataSrcConfigTS.DataSrcConfigTS): Promise<HttpTS.HttpRes> => {
    return HttpUtil.post("datasrc/add", {mainJson: JSON.stringify(mainData)})
}

export const update = (template: DataSrcConfigTS.DataSrcConfigTS, id: string): Promise<HttpTS.HttpRes> => {
    return HttpUtil.post("datasrc/update", {mainJson: JSON.stringify(template), id})
}

export const deleteGroup = (ids: string[]): Promise<HttpTS.HttpRes> => {
    return HttpUtil.post("datasrc/delete", {idList: ids.join(HttpTS.ARRAY_FLAG)})
}


