
// 基础树形结构
export interface TreeData {
  value: string | number
  label: string
  children: TreeData[]
}

/** 加载动画 可以作为 loading 属性值放到 ant组件里 也可以 单独 为 <a-spin></> */
export class Loading {
  delay?: number = 300
  spinning: boolean = false
  tip: string = '数据加载中...'
  // 样式
  wrapperClassName?: string

  constructor(tip?: string) {
    if (tip) {
      this.tip = tip;
    }
  }
}

// 表单规则
export enum FORM_RULE {
  MAX_LENG_16 = 16,
  MAX_LENG_32 = 32,
  MAX_LENG_64 = 64,
  MAX_LENG_128 = 128,
  MAX_LENG_200 = 200,
  MAX_LENG_256 = 256,
  MAX_LENG_512 = 512,
  MAX_LENG_2000 = 2000,
  REP_NO_CN = '^[\\da-zA-Z\\_\\=\\+\\.\\@\\%\\-\\#]+$',
  REP_TEL = '^[\\d\\-]+$'
}

// 编辑页初始化标识
export enum INIT_TYPE {
  UPDATE = "update",
  ADD = "add",
  DETAIL = "detail"
}

// 时间格式
export enum TIME {
  NO_SEC = "YYYY-MM-DD HH:mm",
  SEC = "YYYY-MM-DD HH:mm:ss"
}

// 列表页码组件
export interface TablePage {
  current: number,
  pageSize: number
}

// 百度地图 点
export class MapPoint {
  lng: number = 116.294625
  lat: number = 39.961627
  constructor(lng?: number, lat?: number) {
    if (lng && lat) {
      this.lng = lng
      this.lat = lat
    }
  }
}

// 百度地图 基础
export class MapOption {
  zoom: number = 7
  position: MapPoint = new MapPoint
  constructor(zoom?: number) {
    this.zoom = zoom && zoom || 7
  }
}

