import * as HttpTS from "@/type/system/HttpTS";
import * as SysDictTS from "@/type/system/SysDictTS";
import * as ObjUtil from "@/util/ObjUtil";
import * as SensorDataGeoTS from "@/type/project/SensorDataGeoTS";
import * as SensorDataMulTS from "@/type/project/SensorDataMulTS";
import * as SensorDataDefTS from "@/type/project/SensorDataDefTS";
import * as DataSrcConfigTS from "@/type/project/DataSrcConfigTS";

export class SensorListReq extends HttpTS.ListReq {
  isDel: number | null = null; // 是否禁用 0 - 正常
  sensorType: string | null = null; // 传感器类型
  dataSrcId: DataSrcConfigTS.DATA_SRC_ENUM | null = null; // 数据源id
}

export class SensorListRes extends HttpTS.ListRes<SensorTS> {}

/** 传感器表名 */
export enum SENSOR_DATA_TABLE_NAME {
  /** 位置 */
  GEO = "sensor_data_geo",
  /** 单值 */
  SIMPLE = "sensor_data_def",
  /** 多值 */
  BOTH = "sensor_data_mul",
  /** 状态码 */
  CODE = "sensor_data_code"
}

export class SensorTS {
  /** 传感器名称测量指标 */
  name?: string;
  /** 传感器编号 与数据源耦合 与数据记录表 字段耦合 一一对应  */
  sensorCode?: string;
  /**  刷新频率  单位 秒 -1 不刷新  0 - 等待数据推送 [弃用]  */
  refreshRate?: number;
  /** 数据源ID */
  dataSrcId?: DataSrcConfigTS.DATA_SRC_ENUM;
  /** 传感器类型 描述数据存储的位置 */
  sensorType?: SENSOR_DATA_TABLE_NAME;
  /** 默认单位 */
  sensorUnit?: string;

  /** 是否删除 1 -是 0 -否 */
  isDel?: number;
  /** 创建人  [关联 = 用户] */
  crUser?: string;
  /** 创建时间 */
  crTime?: number;
  /** 修改时间 */
  upTime?: number;

  /** 前端 lng的设备id 拼接规则: 设备id_类型  sia   也通用这个 */
  LNGDeviceId?: string;
  /** 前端 自定义的 lng设备类型 LNG_DATA_TYPE */
  LNGType?: string;


  constructor(name?: string, sensorCode?: string, sensorType?: SENSOR_DATA_TABLE_NAME, sensorUnit?: string, dataSrcId?: DataSrcConfigTS.DATA_SRC_ENUM) {
    this.name = name;
    this.sensorCode = sensorCode;
    this.sensorType = sensorType;
    this.sensorUnit = sensorUnit;

    if (dataSrcId) {
      // 中车LNG特殊处理
      if (dataSrcId == DataSrcConfigTS.DATA_SRC_ENUM.ZHONGCHE_LNG || dataSrcId == DataSrcConfigTS.DATA_SRC_ENUM.SIA_INNER) {
        this.sensorCode = null;
        this.LNGType = sensorCode;
      }
    }
  }

}

/** 色谱数据 枚举类 */
export enum CHROMA_DATA_TYPE {
  /** 环境温度 */
  ENV_TEMP = 0,
  /** 柱箱温度1 */
  OVEN_ONE_TEMP = 1,
  /** 柱箱温度2 */
  OVEN_TWO_TEMP = 2,
  /** 温度检测器1 */
  DETECTOR_ONE_TEMP = 3,
  /** 温度检测器2 */
  DETECTOR_TWO_TEMP = 4,
  /** 柱前压2 */
  COLUMN_BEFORE_TWO_PRESSURE = 5,
  /** 柱前压3 */
  COLUMN_BEFORE_THREE_PRESSURE = 6,
  /** 柱箱正压 */
  COLUMN_PRESSURE = 7,
  /** 工作站正压 */
  WORKSTATION_PRESSURE = 8,
  /** 驱动气源总压 */
  DRIVE_TOTAL_PRESSURE= 9
}

export const CHROMA_TYPE_SEL = {
  [CHROMA_DATA_TYPE.ENV_TEMP]: new SensorTS("环境温度", String(CHROMA_DATA_TYPE.ENV_TEMP), SENSOR_DATA_TABLE_NAME.SIMPLE, 'c'),
  [CHROMA_DATA_TYPE.OVEN_ONE_TEMP]: new SensorTS("柱箱温度1", String(CHROMA_DATA_TYPE.OVEN_ONE_TEMP), SENSOR_DATA_TABLE_NAME.SIMPLE, 'c'),
  [CHROMA_DATA_TYPE.OVEN_TWO_TEMP]: new SensorTS("柱箱温度2", String(CHROMA_DATA_TYPE.OVEN_TWO_TEMP), SENSOR_DATA_TABLE_NAME.SIMPLE, 'c'),
  [CHROMA_DATA_TYPE.DETECTOR_ONE_TEMP]: new SensorTS("温度检测器1", String(CHROMA_DATA_TYPE.DETECTOR_ONE_TEMP), SENSOR_DATA_TABLE_NAME.SIMPLE, 'c'),
  [CHROMA_DATA_TYPE.DETECTOR_TWO_TEMP]: new SensorTS("温度检测器2", String(CHROMA_DATA_TYPE.DETECTOR_TWO_TEMP), SENSOR_DATA_TABLE_NAME.SIMPLE, 'c'),

  [CHROMA_DATA_TYPE.COLUMN_BEFORE_TWO_PRESSURE]: new SensorTS("柱前压2", String(CHROMA_DATA_TYPE.COLUMN_BEFORE_TWO_PRESSURE), SENSOR_DATA_TABLE_NAME.SIMPLE, 'kpa'),
  [CHROMA_DATA_TYPE.COLUMN_BEFORE_THREE_PRESSURE]: new SensorTS("柱前压3", String(CHROMA_DATA_TYPE.COLUMN_BEFORE_THREE_PRESSURE), SENSOR_DATA_TABLE_NAME.SIMPLE, 'kpa'),
  [CHROMA_DATA_TYPE.COLUMN_PRESSURE]: new SensorTS("柱箱正压", String(CHROMA_DATA_TYPE.COLUMN_PRESSURE), SENSOR_DATA_TABLE_NAME.SIMPLE, 'pa'),
  [CHROMA_DATA_TYPE.WORKSTATION_PRESSURE]: new SensorTS("工作站正压", String(CHROMA_DATA_TYPE.WORKSTATION_PRESSURE), SENSOR_DATA_TABLE_NAME.SIMPLE, 'pa'),
  [CHROMA_DATA_TYPE.DRIVE_TOTAL_PRESSURE]: new SensorTS("驱动气源总压", String(CHROMA_DATA_TYPE.DRIVE_TOTAL_PRESSURE), SENSOR_DATA_TABLE_NAME.SIMPLE, 'kpa'),
}


/** 中车现有的设备列表 */
export const LNG_DEV_LIST = ["CAQ110210058", "CAQ110190029"];

/** 中车数据子类 */
export enum LNG_DATA_TYPE {
  // sensor_data_def
  GAS_PRESSURE = "GAS_PRESSURE",
  DIFF_PRESSURE = "DIFF_PRESSURE",
  GUAN_TEMP = "GUAN_TEMP",
  CPU_TEMP = "CPU_TEMP",
  BAT_PRE = "BAT_PRE",
  ALTITUDE = "ALTITUDE",

  LIQUID_VOLUME = "LIQUID_VOLUME",
  LIQUID_WEIGHT = "LIQUID_WEIGHT",
  LIQUID_PRECENTAGE = "LIQUID_PRECENTAGE",
  LIQUID_LEVEL = "LIQUID_LEVEL",

  SPEED = "SPEED",
  ASPEED_X = "ASPEED_X",
  ASPEED_Y = "ASPEED_Y",
  ASPEED_Z = "ASPEED_Z",
  // sensor_data_geo
  GEO = "GEO",
}

/** 中车数据综合下拉选 */
export const LNG_TYPE_SEL = {
  [LNG_DATA_TYPE.GAS_PRESSURE]: new SensorTS("罐内压力", LNG_DATA_TYPE.GAS_PRESSURE, SENSOR_DATA_TABLE_NAME.SIMPLE, 'mpa', DataSrcConfigTS.DATA_SRC_ENUM.ZHONGCHE_LNG),
  [LNG_DATA_TYPE.DIFF_PRESSURE]: new SensorTS("压差", LNG_DATA_TYPE.DIFF_PRESSURE, SENSOR_DATA_TABLE_NAME.SIMPLE, 'kpa', DataSrcConfigTS.DATA_SRC_ENUM.ZHONGCHE_LNG),
  [LNG_DATA_TYPE.GUAN_TEMP]: new SensorTS("液相温度", LNG_DATA_TYPE.GUAN_TEMP, SENSOR_DATA_TABLE_NAME.SIMPLE, 'c', DataSrcConfigTS.DATA_SRC_ENUM.ZHONGCHE_LNG),
  [LNG_DATA_TYPE.CPU_TEMP]: new SensorTS("设备环境温度", LNG_DATA_TYPE.CPU_TEMP, SENSOR_DATA_TABLE_NAME.SIMPLE, 'c', DataSrcConfigTS.DATA_SRC_ENUM.ZHONGCHE_LNG),
  [LNG_DATA_TYPE.BAT_PRE]: new SensorTS("电池电量", LNG_DATA_TYPE.BAT_PRE, SENSOR_DATA_TABLE_NAME.SIMPLE, '%', DataSrcConfigTS.DATA_SRC_ENUM.ZHONGCHE_LNG),
  [LNG_DATA_TYPE.ALTITUDE]: new SensorTS("海拔高度", LNG_DATA_TYPE.ALTITUDE, SENSOR_DATA_TABLE_NAME.SIMPLE, 'm', DataSrcConfigTS.DATA_SRC_ENUM.ZHONGCHE_LNG),

  
  [LNG_DATA_TYPE.LIQUID_LEVEL]: new SensorTS("液位", LNG_DATA_TYPE.LIQUID_LEVEL, SENSOR_DATA_TABLE_NAME.SIMPLE, 'm', DataSrcConfigTS.DATA_SRC_ENUM.ZHONGCHE_LNG),
  [LNG_DATA_TYPE.LIQUID_VOLUME]: new SensorTS("液体体积", LNG_DATA_TYPE.LIQUID_VOLUME, SENSOR_DATA_TABLE_NAME.SIMPLE, 'l', DataSrcConfigTS.DATA_SRC_ENUM.ZHONGCHE_LNG),
  [LNG_DATA_TYPE.LIQUID_WEIGHT]: new SensorTS("液体质量", LNG_DATA_TYPE.LIQUID_WEIGHT, SENSOR_DATA_TABLE_NAME.SIMPLE, 'kg', DataSrcConfigTS.DATA_SRC_ENUM.ZHONGCHE_LNG),
  [LNG_DATA_TYPE.LIQUID_PRECENTAGE]: new SensorTS("充装率", LNG_DATA_TYPE.LIQUID_PRECENTAGE, SENSOR_DATA_TABLE_NAME.SIMPLE, '%', DataSrcConfigTS.DATA_SRC_ENUM.ZHONGCHE_LNG),
  
  [LNG_DATA_TYPE.SPEED]: new SensorTS("速度", LNG_DATA_TYPE.SPEED, SENSOR_DATA_TABLE_NAME.SIMPLE, 'km/h', DataSrcConfigTS.DATA_SRC_ENUM.ZHONGCHE_LNG),
  [LNG_DATA_TYPE.ASPEED_X]: new SensorTS("三轴-X", LNG_DATA_TYPE.ASPEED_X, SENSOR_DATA_TABLE_NAME.SIMPLE, 'mg', DataSrcConfigTS.DATA_SRC_ENUM.ZHONGCHE_LNG),
  [LNG_DATA_TYPE.ASPEED_Y]: new SensorTS("三轴-Y", LNG_DATA_TYPE.ASPEED_Y, SENSOR_DATA_TABLE_NAME.SIMPLE, 'mg', DataSrcConfigTS.DATA_SRC_ENUM.ZHONGCHE_LNG),
  [LNG_DATA_TYPE.ASPEED_Z]: new SensorTS("三轴-Z", LNG_DATA_TYPE.ASPEED_Z, SENSOR_DATA_TABLE_NAME.SIMPLE, 'mg', DataSrcConfigTS.DATA_SRC_ENUM.ZHONGCHE_LNG),

  [LNG_DATA_TYPE.GEO]: new SensorTS("设备位置", LNG_DATA_TYPE.GEO, SENSOR_DATA_TABLE_NAME.GEO, 'n', DataSrcConfigTS.DATA_SRC_ENUM.ZHONGCHE_LNG),
}


// 研究所内部数据
export enum SIA_INNER_SENSOR {
  VACUUM = "VACUUM",
  STATUS = "STATUS",
  GEO = "GEO"
}

export const SIA_INNER_SEL = {
  [SIA_INNER_SENSOR.VACUUM]: new SensorTS("真空度", SIA_INNER_SENSOR.VACUUM, SENSOR_DATA_TABLE_NAME.SIMPLE, "mpa", DataSrcConfigTS.DATA_SRC_ENUM.SIA_INNER),
  [SIA_INNER_SENSOR.STATUS]: new SensorTS("设备状态", SIA_INNER_SENSOR.STATUS, SENSOR_DATA_TABLE_NAME.CODE, "n", DataSrcConfigTS.DATA_SRC_ENUM.SIA_INNER),
  [SIA_INNER_SENSOR.GEO]: new SensorTS("设备位置", SIA_INNER_SENSOR.GEO, SENSOR_DATA_TABLE_NAME.GEO, "n", DataSrcConfigTS.DATA_SRC_ENUM.SIA_INNER),
}


export interface SensorHistoryMap {
  [key: string]:
    | SensorDataGeoTS.SensorDataGeoTS
    | SensorDataMulTS.SensorDataMulTS
    | SensorDataDefTS.SensorDataDefTS;
}


