 const dataList = [[35.86, 60.26, 80, 110, 110, 255, 264, 842, 768, 451],
[36.38, 60.69, 79.99, 110, 110, 255, 263.99, 838, 763, 452],
[36.99, 61.72, 80, 109.99, 110, 255, 263.99, 837, 761, 451],
[37.26, 61.7, 79.99, 109.99, 110, 254.99, 263.99, 836, 760, 451],
[37.55, 62.33, 79.99, 110, 110, 255, 263.99, 832, 759, 450],
[37.87, 61.92, 79.99, 110, 110, 255, 263.99, 842, 763, 450],
[38.02, 62.34, 79.99, 110, 110, 255, 263.99, 841, 753, 450],
[39.52, 63.46, 79.99, 109.99, 109.99, 254.99, 264, 842, 755, 449],
[39.49, 63.55, 80, 109.99, 110, 255, 264, 839, 753, 449],
[39.61, 61.44, 80, 109.99, 110, 254.99, 264, 837, 753, 449],
[39.88, 60.95, 80, 110, 109.99, 255, 263.87, 842, 756, 448],
[39.91, 61.12, 79.99, 109.99, 110, 254.99, 264, 837, 750, 448],
[39.85, 61.58, 79.99, 110, 110, 254.99, 264, 827, 743, 446],
[39.85, 60.56, 80, 110, 110, 254.99, 264, 832, 748, 448],
[39.91, 60.27, 80, 110, 110, 255, 264, 843, 755, 449],
[40.09, 62.97, 79.99, 110, 110, 255, 263.3, 845, 751, 449],
[40.09, 63.18, 80, 110, 110, 255, 263.99, 845, 752, 449],
[40.12, 64.94, 79.99, 109.99, 110, 254.99, 263.99, 839, 751, 448],
[40.28, 65.59, 79.99, 110, 110, 254.99, 263.99, 845, 756, 449],
[40.22, 64.98, 80, 110, 110, 255, 263.99, 849, 756, 449],
[40.31, 63.81, 80, 109.99, 110, 255, 262.6, 872, 750, 449],
[40.31, 62.38, 79.99, 109.99, 110, 255, 264, 848, 750, 449],
[40.37, 62.78, 79.99, 109.99, 110, 254.99, 264, 844, 749, 448],
[40.31, 62.17, 79.99, 110, 110, 254.99, 263.99, 841, 745, 448],
[40.37, 61.81, 80, 110, 110, 255, 264, 847, 747, 449],
[40.43, 61.77, 80, 110, 110, 255, 264, 845, 747, 449],
[40.43, 61.07, 79.99, 110, 110, 255, 263.99, 846, 750, 449],
[40.68, 62.79, 79.99, 110, 110, 254.99, 264, 845, 749, 449],
[40.28, 63.41, 80, 109.99, 110, 254.99, 264, 848, 735, 448],
[40.52, 63.34, 80, 110, 110, 255, 264, 846, 736, 449],
[40.34, 64.99, 80, 110, 109.99, 255, 264, 849, 738, 449],
[40.25, 63.91, 79.99, 110, 110, 255, 263.99, 859, 743, 448],
[40.22, 65.95, 80, 110, 110, 255, 263.99, 851, 739, 450],
[40.15, 66.06, 80, 110, 110, 254.99, 263.99, 849, 740, 449],
[40.15, 66.84, 80, 110, 110, 254.99, 264, 851, 740, 449],
[40.15, 66.06, 79.99, 110, 110, 255, 264, 845, 736, 448],
[40.06, 66.77, 80, 110, 110, 255, 264, 850, 744, 449],
[40.03, 66.36, 80, 110, 110, 255, 264, 845, 738, 449],
[40, 64.52, 80, 110, 110, 254.99, 263.99, 844, 731, 450],
[40.09, 64.36, 80, 110, 110, 255, 263.99, 846, 738, 449],
[39.91, 64.89, 79.99, 110, 109.99, 255, 263.99, 849, 737, 448],
[40.03, 64.45, 79.99, 110, 110, 254.99, 263.99, 848, 740, 449],
[39.88, 65.09, 79.99, 110, 110, 254.99, 264, 850, 738, 449],
[39.94, 64.87, 79.99, 110, 110, 254.99, 263.99, 851, 743, 450],
[39.97, 65.58, 79.99, 110, 110, 255, 264, 850, 739, 449],
[39.82, 67.19, 80, 110, 110, 255, 263.99, 847, 739, 450],
[39.85, 65.9, 80, 109.99, 110, 255, 264, 846, 738, 449],
[39.79, 65.07, 79.99, 110, 110, 255, 264, 861, 754, 446],
[39.7, 65.82, 79.99, 110, 110, 254.99, 263.99, 852, 743, 450],
[39.79, 64.88, 79.99, 110, 110, 255, 263.99, 849, 738, 449],
[39.85, 63.96, 80, 110, 110, 255, 264, 849, 740, 450],
[39.67, 63.44, 79.99, 109.99, 110, 255, 263.99, 844, 733, 450],
[39.55, 64.07, 80, 110, 110, 254.99, 264, 849, 739, 449],
[39.58, 62.81, 80, 109.99, 110, 254.99, 263.99, 853, 739, 450],
[39.55, 63.38, 80, 110, 110, 255, 264, 848, 732, 450],
[39.58, 63.06, 80, 110, 110, 255, 264, 851, 737, 449],
[39.55, 62.46, 79.99, 110, 110, 254.99, 263.99, 848, 738, 450],
[39.55, 62.48, 79.99, 110, 109.99, 254.99, 264, 845, 732, 449],
[39.64, 62.19, 80, 110, 110, 254.99, 263.99, 845, 731, 449],
[39.52, 62.26, 80, 110, 110, 254.99, 264, 844, 736, 450],
[39.49, 64.22, 80, 110, 109.99, 255, 264, 846, 735, 450],
[39.7, 64.52, 80, 110, 110, 254.99, 264, 846, 733, 449],
[39.49, 63.58, 79.99, 110, 109.99, 254.99, 264, 846, 737, 450],
[39.39, 63.55, 80, 110, 110, 255.01, 263.99, 886, 736, 450],
[39.43, 64.24, 80, 110, 110, 255, 264, 844, 738, 450],
[39.36, 65.48, 80, 110, 110, 255, 264, 847, 733, 450],
[39.33, 65.87, 80, 110, 109.99, 255, 264, 850, 739, 449],
[39.36, 65.74, 79.99, 110, 110, 254.99, 264, 851, 750, 450],
[39.36, 66.62, 79.99, 110, 110, 254.99, 263.99, 849, 740, 450],
[39.46, 65.54, 80, 110, 110, 254.99, 264, 849, 734, 450],
[39.21, 66.47, 79.99, 110, 109.99, 255, 263.99, 848, 736, 449],
[39.21, 67.15, 80, 110, 110, 255, 264, 847, 738, 450],
[39.24, 65.46, 79.99, 110, 109.99, 254.99, 263.99, 858, 750, 449],
[39.18, 66.66, 79.99, 110, 109.99, 254.99, 263.93, 856, 746, 450],
[39.24, 65.97, 80, 109.99, 110, 255, 264, 849, 741, 450],
[39.33, 65.36, 79.99, 110, 110, 254.99, 263.99, 852, 741, 449],
[39.27, 64.49, 80, 109.99, 110, 254.99, 263.99, 862, 752, 449],
[39.39, 64.21, 79.99, 109.99, 110, 254.99, 264, 853, 740, 450],
[39.36, 63.33, 80, 110, 110, 255, 263.94, 850, 740, 450],
[39.43, 62.72, 79.99, 110, 110, 254.99, 264, 853, 741, 450],
[39.46, 61.82, 80, 110, 110, 254.99, 264, 849, 742, 450],
[39.55, 63.19, 79.99, 110, 110, 255, 264, 852, 741, 450],
[39.46, 64.53, 79.99, 110, 109.99, 255, 264, 853, 743, 450],
[39.58, 65.15, 80, 110, 109.99, 255, 264.02, 857, 744, 449],
[39.76, 65.27, 79.99, 110, 110, 255, 264, 855, 749, 450],
[39.61, 65.27, 80, 110, 110, 255, 264, 851, 742, 450],
[39.55, 67.94, 80, 110, 110, 255, 264, 850, 742, 450],
[39.79, 67.31, 80, 109.99, 110, 255, 264, 850, 738, 450],
[39.61, 66.64, 80, 110, 110, 255.35, 261.86, 854, 742, 450],
[39.58, 66.32, 79.99, 110, 110, 255, 264, 848, 735, 450],
[39.49, 64.91, 80, 110, 109.99, 255, 263.99, 854, 739, 450],
[39.79, 63.6, 80, 109.99, 110, 254.99, 264, 848, 738, 450],
[39.7, 62.45, 80, 109.99, 110, 255, 263.99, 847, 735, 449],
[39.61, 64.05, 79.99, 110, 110, 248.61, 276.35, 848, 740, 450],
[39.52, 65.2, 80, 109.99, 110, 255, 264, 848, 740, 449],
[39.33, 66.66, 79.99, 110, 110, 255, 264, 846, 735, 450],
[39.46, 65.7, 80, 110, 110, 255, 263.99, 846, 735, 450],
[39.49, 65.59, 79.99, 110, 109.99, 254.99, 263.99, 849, 735, 449],
[39.46, 65.58, 79.99, 110, 109.99, 255, 264, 852, 739, 449],
[39.7, 65.13, 80, 110, 110, 254.99, 264, 848, 741, 450],
[39.55, 64.34, 79.99, 109.99, 109.99, 255, 264, 853, 739, 450],
[39.55, 65.99, 80, 109.99, 110, 254.99, 263.99, 850, 739, 450],
[39.64, 64.77, 79.99, 110, 109.99, 255, 264, 850, 740, 449],
[39.82, 64.78, 80, 110, 110, 255, 264, 850, 740, 449],
[40.03, 65.51, 79.99, 109.99, 110, 254.99, 263.99, 856, 745, 450],
[39.88, 65.03, 80, 110, 110, 255, 263.99, 854, 742, 450],
[39.94, 63.71, 80, 110, 110, 254.99, 264, 852, 742, 450],
[39.88, 63.84, 80, 110, 109.99, 255, 263.99, 853, 742, 449],
[39.82, 63.95, 80, 109.99, 109.99, 255, 264, 858, 745, 450],
[39.82, 64.24, 79.99, 109.99, 110, 255, 264, 852, 744, 450],
[39.79, 64.6, 79.99, 110, 110, 254.99, 264, 854, 741, 449],
[39.7, 65.54, 80, 109.99, 110, 254.99, 264, 851, 740, 450],
[39.7, 66.47, 80, 110, 110, 255, 264, 849, 738, 449],
[39.64, 68.6, 80, 110, 110, 255, 264, 853, 742, 450],
[39.67, 68.72, 79.99, 110, 110, 255, 263.99, 846, 739, 449],
[39.58, 68.52, 79.99, 110, 110, 254.99, 263.99, 851, 744, 450],
[39.36, 68.12, 79.99, 109.99, 110, 254.99, 263.98, 851, 738, 450],
[39.55, 66.46, 80, 109.99, 110, 254.99, 263.99, 847, 731, 450],
[39.76, 66.09, 80, 110, 110, 254.99, 263.99, 845, 734, 449],
[39.52, 65.9, 79.99, 110, 110, 254.99, 263.99, 842, 733, 449],
[39.55, 64.79, 80, 110, 110, 255, 264, 839, 733, 449],
[39.36, 65.93, 80, 110, 109.99, 254.99, 263.99, 842, 730, 449],
[39.39, 64.52, 79.99, 110, 109.99, 254.99, 264, 842, 731, 449],
[39.36, 64.28, 80, 110, 110, 255, 264, 842, 734, 450],
[39.33, 63.9, 79.99, 109.99, 110, 254.99, 263.99, 846, 736, 449],
[39.39, 64.12, 80, 110, 109.99, 254.99, 264, 847, 738, 450],
[39.27, 64.01, 79.99, 110, 110, 254.99, 263.99, 844, 733, 449],
[39.24, 62.74, 80, 110, 110, 254.99, 264, 853, 742, 450],
[39.24, 64.17, 80, 110, 110, 254.99, 264, 846, 740, 450],
[39.24, 64.6, 80, 110, 109.99, 255, 264, 853, 741, 450],
[39.18, 64.79, 80, 110, 110, 254.99, 264, 836, 729, 449],
[39.18, 65.65, 80, 110, 110, 254.97, 264, 846, 746, 449],
[39.43, 67.21, 79.99, 109.99, 110, 255, 264, 852, 739, 450],
[39.24, 67.18, 80, 109.99, 110, 254.99, 264, 847, 740, 450],
[39.21, 66.14, 79.99, 110, 110, 255, 263.99, 841, 733, 449],
[39.18, 65.54, 80, 110, 109.99, 254.99, 264, 842, 731, 449],
[39.21, 66, 80, 110, 109.99, 254.97, 264, 849, 740, 450],
[39.09, 67.53, 79.99, 110, 110, 255, 264, 854, 746, 450],
[39.12, 65.82, 79.99, 109.99, 110, 254.99, 264, 854, 741, 450],
[39.12, 66.96, 79.99, 110, 110, 254.99, 263.99, 852, 741, 451],
[39.15, 65.63, 80, 110, 110, 255, 264, 849, 739, 450],
[39.12, 66.76, 79.99, 110, 109.99, 254.98, 263.99, 846, 740, 450],
[39.09, 65.92, 80, 110, 110, 254.99, 263.99, 847, 741, 450],
[39.33, 66.06, 80, 109.99, 110, 255, 264, 854, 743, 450],
[39.12, 65.03, 80, 110, 110, 255, 264, 857, 740, 450],
[39.3, 65.45, 79.99, 110, 110, 255, 263.99, 854, 741, 450],
[39.03, 66.02, 79.99, 110, 110, 255.15, 264, 854, 742, 451],
[39.3, 65.83, 79.99, 110, 110, 255, 264, 848, 739, 450],
[39.12, 64.5, 80, 110, 110, 254.99, 263.99, 857, 746, 450],
[39.21, 64.47, 79.99, 110, 109.99, 255, 263.99, 852, 744, 450],
[39.12, 64.5, 79.99, 110, 110, 254.99, 263.98, 851, 740, 450],
[39.09, 64.13, 80, 109.99, 110, 255.37, 264, 868, 747, 451],
[39.24, 66.35, 79.99, 110, 110, 255, 263.99, 850, 735, 451],
[39.09, 65.97, 79.99, 109.99, 110, 255, 263.99, 848, 737, 451],
[38.5, 65.25, 79.99, 110, 110, 255, 263.99, 845, 731, 450],
[38.88, 64.8, 80, 109.99, 110, 254.99, 264, 846, 732, 450],
[38.76, 65.62, 79.99, 109.99, 110, 254.99, 263.98, 845, 735, 451],
[38.7, 65.9, 80, 110, 110, 255, 263.99, 849, 739, 451],
[38.94, 65.72, 80, 110, 110, 255, 264, 857, 747, 452],
[38.82, 66.39, 79.99, 110, 110, 255, 263.99, 857, 741, 452],
[38.52, 66.06, 79.99, 110, 110, 254.99, 264, 842, 732, 451],
[38.11, 65.2, 80, 109.99, 110, 255, 264, 832, 717, 449],
[38.5, 64.85, 79.99, 110, 110, 255, 264, 843, 731, 452],
[38.17, 63.33, 80, 110, 109.99, 254.99, 263.99, 842, 731, 451],
[38.11, 63.78, 80, 110, 110, 254.99, 263.99, 849, 740, 452],
[38.05, 65.25, 79.99, 110, 110, 254.99, 264, 845, 733, 451],
[37.96, 65.88, 79.99, 110, 109.99, 254.99, 263.99, 842, 732, 452],
[37.93, 65.56, 79.99, 109.99, 110, 255, 263.99, 841, 730, 452],
[37.99, 65.58, 79.99, 110, 110, 255, 264, 845, 733, 452],
[37.93, 64.18, 80, 110, 109.99, 255, 264, 849, 738, 452],
[37.9, 63.13, 80, 110, 110, 254.99, 263.99, 848, 734, 453],
[37.93, 62.56, 80, 110, 110, 255, 264, 853, 733, 453],
[37.96, 61.75, 80, 110, 110, 254.99, 264, 851, 738, 452],
[38.14, 62.05, 80, 110, 110, 254.99, 264, 852, 740, 452],
[37.99, 63.45, 79.99, 109.99, 110, 255, 263.99, 843, 734, 452],
[38.08, 64.29, 79.99, 109.99, 110, 254.99, 263.99, 850, 738, 453],
[38.08, 64.06, 80, 110, 110, 254.99, 264, 850, 737, 452],
[38.02, 63.15, 80, 109.99, 110, 255, 264, 846, 740, 452],
[38.08, 64.22, 79.99, 110, 109.99, 254.99, 264, 844, 738, 452],
[38.11, 63.68, 80, 109.99, 110, 255, 263.99, 852, 744, 452],
[38.11, 66.27, 79.99, 110, 110, 255, 264, 848, 740, 453],
[38.2, 65.73, 80, 110, 110, 254.99, 263.99, 851, 744, 452],
[38.14, 65.29, 79.99, 110, 110, 255, 263.99, 849, 741, 452],
[38.17, 65.23, 79.99, 110, 110, 254.99, 264, 848, 741, 452],
[38.14, 64.52, 79.99, 109.99, 110, 254.99, 264, 848, 738, 452],
[38.17, 64.91, 80, 109.99, 110, 254.99, 263.99, 852, 743, 452],
[38.2, 64.07, 80, 110, 110, 255, 263.99, 853, 742, 452],
[38.14, 64.69, 79.99, 110, 110, 254.99, 264, 854, 743, 452],
[38.14, 63.94, 80, 110, 110, 254.99, 264, 858, 749, 450],
[38.26, 63.98, 80, 109.99, 109.99, 254.99, 264, 853, 742, 452],
[38.26, 64.92, 80, 109.99, 109.99, 255, 263.99, 854, 737, 451],
[38.29, 63.65, 80, 109.99, 110, 254.99, 263.99, 850, 745, 453],
[38.44, 65.21, 80, 109.99, 110, 255, 264, 850, 745, 452],
[38.32, 64.9, 79.99, 109.99, 110, 255, 264, 855, 745, 452],
[38.7, 64.06, 80, 110, 110, 254.99, 264, 857, 742, 450],
[38.55, 64.05, 80, 109.99, 110, 255, 264, 860, 744, 451],
[38.58, 64.03, 79.99, 110, 110, 254.99, 264, 854, 741, 452],
[38.55, 63.97, 80, 109.99, 109.99, 255, 264, 853, 739, 451],
[38.52, 63.49, 80, 109.99, 110, 254.99, 264, 849, 736, 451],
[38.52, 65.03, 79.99, 110, 109.99, 255, 263.99, 855, 739, 452],
[38.5, 64.56, 80, 110, 110, 254.99, 264, 847, 730, 451],
[38.47, 63.31, 80, 110, 110, 255, 263.99, 837, 724, 450],
[38.47, 62.42, 79.99, 110, 110, 255, 264, 836, 725, 449],
[38.41, 63.08, 80, 110, 110, 254.99, 263.99, 831, 721, 448],
[38.52, 65.68, 79.99, 110, 110, 255, 264, 831, 719, 449],
[38.5, 65.38, 79.99, 109.99, 110, 255, 263.99, 827, 721, 449],
[38.23, 64.7, 79.99, 110, 109.99, 254.99, 264, 829, 718, 448],
[38.35, 64.89, 79.99, 110, 110, 255, 264, 830, 717, 448],
[38.41, 63.75, 80, 110, 109.99, 255, 264, 840, 725, 450],
[38.11, 63.16, 79.99, 110, 110, 255, 263.99, 837, 726, 450],
[38.26, 62.83, 80, 110, 110, 254.99, 264, 838, 723, 450],
[38.26, 63.32, 80, 109.99, 110, 254.99, 263.99, 832, 720, 449],
[38.26, 63.86, 79.99, 110, 109.99, 255, 263.99, 834, 725, 450],
[38.23, 64.45, 80, 110, 109.99, 254.99, 263.99, 840, 727, 451],
[38.23, 63.79, 79.99, 110, 110, 254.99, 263.99, 842, 731, 451],
[38.26, 63.37, 80, 110, 110, 254.99, 264, 845, 733, 451],
[38.17, 63.62, 80, 109.99, 110, 254.99, 264, 838, 724, 450],
[38.2, 63.18, 79.99, 110, 110, 255, 263.99, 831, 721, 449],
[38.29, 63.72, 79.99, 109.99, 109.99, 255, 264, 842, 731, 451],
[38.2, 64.71, 80, 110, 109.99, 254.99, 263.99, 849, 739, 452],
[38.23, 64.62, 80, 109.99, 110, 255, 264, 859, 749, 451],
[38.23, 64.64, 80, 110, 110, 255, 263.99, 857, 738, 452],
[38.2, 65.56, 79.99, 109.99, 110, 255, 264, 855, 741, 452],
[38.2, 64.56, 79.99, 110, 109.99, 255, 264, 852, 736, 452],
[38.2, 65.78, 80, 109.99, 109.99, 254.99, 264, 853, 736, 451],
[38.2, 65.6, 80, 110, 110, 255, 264, 854, 737, 452],
[38.26, 64.19, 79.99, 109.99, 110, 254.99, 263.99, 851, 735, 452],
[38.26, 64.48, 79.99, 109.99, 109.99, 254.99, 263.99, 854, 741, 451],
[38.29, 64.7, 80, 110, 110, 254.99, 264, 848, 742, 451],
[38.35, 63.12, 79.99, 110, 110, 255, 263.99, 845, 742, 452],
[38.29, 66.11, 79.99, 109.99, 109.99, 255, 263.71, 861, 742, 452],
[38.73, 66.29, 79.99, 110, 109.99, 255, 264, 853, 745, 452],
[38.38, 67.17, 79.99, 109.99, 110, 254.99, 264, 849, 738, 451],
[38.35, 65.95, 79.99, 110, 110, 254.99, 263.99, 850, 744, 451],
[38.52, 66.02, 79.99, 109.99, 110, 255, 263.99, 847, 740, 451],
[38.41, 66.24, 79.99, 110, 110, 254.99, 263.94, 856, 735, 452],
[38.47, 65.33, 79.99, 109.99, 110, 255, 263.99, 848, 736, 451],
[38.38, 64.12, 80, 109.99, 110, 254.99, 264, 850, 740, 451],
[38.41, 64.49, 79.99, 109.99, 109.99, 254.99, 264, 852, 740, 451],
[38.52, 63.8, 80, 109.99, 109.99, 255, 264, 851, 741, 451],
[38.52, 63.67, 79.99, 110, 109.99, 254.99, 264.32, 867, 738, 451],
[38.52, 64.06, 79.99, 110, 110, 254.99, 263.99, 848, 738, 451],
[38.58, 65.14, 80, 109.99, 110, 254.99, 264, 850, 740, 451],
[38.58, 64.32, 80, 110, 110, 255, 263.99, 851, 740, 451],
[38.61, 64.35, 79.99, 110, 109.99, 255, 263.99, 854, 744, 452],
[38.85, 64.18, 79.99, 110, 110, 254.97, 264.69, 847, 740, 451],
[38.79, 64.55, 79.99, 110, 110, 255, 264, 850, 739, 451],
[38.67, 63.25, 79.99, 110, 110, 255, 264, 853, 738, 451],
[38.91, 66.1, 80, 110, 110, 254.99, 264, 851, 737, 451],
[38.76, 66.75, 80, 110, 110, 254.99, 264, 846, 739, 450],
[38.7, 66.79, 79.99, 110, 110, 254.99, 263.99, 845, 733, 450],
[38.82, 65.21, 80, 110, 110, 254.99, 263.99, 851, 741, 450],
[38.91, 68.83, 79.99, 110, 109.99, 254.99, 263.99, 852, 738, 451],
[38.85, 67.31, 80, 110, 110, 255, 263.99, 848, 737, 450],
[38.85, 66.28, 80, 110, 110, 254.99, 263.99, 843, 735, 450],
[38.91, 64.38, 80, 110, 110, 255, 264, 841, 731, 449],
[39.12, 63.91, 79.99, 110, 110, 255, 263.99, 850, 741, 450],
[38.85, 63.88, 79.99, 110, 110, 255, 264, 851, 741, 450],
[39.03, 63.65, 79.99, 110, 110, 255, 264, 849, 736, 450],
[38.91, 63.23, 80, 110, 110, 255, 263.99, 848, 735, 450],
[39, 64.51, 79.99, 109.99, 109.99, 254.99, 263.99, 842, 733, 449],
[38.61, 65.82, 80, 110, 110, 255, 264, 845, 734, 450],
[38.97, 64.33, 80, 110, 110, 254.99, 263.99, 848, 737, 450],
[39, 64.89, 80, 110, 110, 255, 263.99, 859, 747, 449],
[38.94, 64.14, 79.99, 109.99, 110, 255, 264, 849, 738, 450],
[39.09, 62.46, 80, 109.99, 110, 254.99, 263.99, 841, 729, 449],
[39.21, 62.31, 79.99, 110, 110, 255, 263.98, 850, 736, 450],
[39.12, 62.62, 80, 110, 110, 255, 264, 840, 731, 450],
[39.03, 63.67, 79.99, 110, 109.99, 254.99, 263.99, 850, 736, 450],
[39.03, 63.02, 80, 110, 110, 255, 264, 840, 733, 449],
[39.06, 64.22, 79.99, 110, 110, 255, 263.99, 850, 739, 450],
[39.06, 64.46, 79.99, 109.99, 110, 254.99, 263.99, 844, 736, 450],
[39.06, 63.82, 79.99, 110, 110, 254.99, 263.99, 842, 734, 450],
[39.21, 63.96, 80, 110, 110, 254.99, 264, 843, 732, 449],
[39.03, 65.06, 79.99, 110, 110, 255, 264, 841, 732, 449],
[39.09, 64.05, 79.99, 110, 109.99, 255, 264, 830, 721, 447],
[39.3, 63.83, 80, 110, 110, 255, 263.99, 842, 734, 450],
[39.06, 63.55, 80, 109.99, 110, 254.99, 263.98, 852, 739, 450],
[39.24, 63.77, 79.99, 110, 110, 254.99, 264, 845, 738, 449],
[39.12, 64.66, 79.99, 110, 110, 255, 264, 848, 737, 450],
[39.12, 64.24, 80, 110, 110, 255, 263.99, 851, 738, 450],
[39.15, 65.48, 79.99, 109.99, 109.99, 255, 264, 849, 737, 450],
[39.3, 65.98, 79.99, 110, 110, 254.99, 263.99, 863, 745, 450],
[39.15, 63.02, 80, 110, 110, 255, 264, 853, 741, 450],
[39.15, 62.25, 79.99, 110, 110, 255, 263.99, 853, 738, 450],
[39.15, 63, 80, 109.99, 110, 255, 263.99, 889, 732, 450],
[39.12, 64.61, 80, 109.99, 110, 254.99, 263.99, 847, 735, 449],
[39.15, 65.09, 80, 110, 110, 254.99, 264, 850, 739, 450],
[39.12, 63.65, 80, 110, 110, 255, 264, 850, 739, 450],
[39.27, 65.11, 79.99, 109.99, 109.99, 255, 263.99, 850, 734, 450],
[39.12, 64.54, 80, 110, 110, 255, 264, 900, 737, 450],
[39.09, 64.42, 79.99, 110, 110, 255, 264, 849, 739, 450],
[39.06, 63.33, 80, 110, 110, 254.99, 264, 855, 743, 450],
[39.09, 64.61, 80, 110, 109.99, 255, 264, 853, 739, 450],
[39.09, 64.55, 80, 110, 110, 255, 264, 850, 736, 450],
[39.06, 66.13, 79.99, 110, 110, 254.99, 263.97, 851, 744, 450],
[39.03, 65.35, 80, 110, 110, 254.99, 263.99, 852, 735, 450],
[39.03, 64.79, 80, 109.99, 110, 254.99, 263.99, 853, 741, 450],
[38.97, 63.43, 80, 110, 110, 254.99, 264, 848, 739, 450],
[39.12, 63.65, 79.99, 110, 109.99, 255, 263.99, 852, 738, 451],
[39.15, 63.36, 79.99, 110, 110, 255, 263.97, 849, 736, 451],
[38.94, 63.43, 80, 110, 110, 255, 264, 846, 735, 450],
[39.03, 63.62, 79.99, 110, 110, 255, 263.99, 843, 732, 450],
[38.7, 62.66, 80, 110, 110, 255, 264, 843, 736, 450],
[38.7, 64.99, 79.99, 110, 110, 255, 263.99, 848, 736, 450],
[38.94, 65.29, 79.99, 110, 110, 255, 264.01, 848, 739, 450],
[38.76, 66.01, 79.99, 110, 110, 254.99, 263.99, 853, 739, 451],
[38.97, 64.48, 80, 110, 110, 255, 264, 845, 735, 450],
[38.64, 65.03, 80, 109.99, 109.99, 255, 264, 850, 736, 450],
[38.73, 67.09, 79.99, 110, 110, 254.99, 264, 853, 744, 451],
[38.73, 64.75, 79.99, 109.99, 110, 255.03, 263.58, 849, 740, 450],
[38.73, 67.53, 80, 110, 110, 255, 263.99, 852, 743, 451],
[38.64, 67.43, 79.99, 110, 110, 254.99, 263.99, 854, 744, 451],
[38.64, 67, 80, 110, 110, 255, 263.99, 861, 750, 449],
[38.67, 65.54, 80, 110, 110, 254.99, 264, 856, 741, 451],
[38.79, 64.43, 79.99, 110, 110, 255.3, 261.55, 848, 741, 451],
[38.64, 64.75, 79.99, 110, 110, 254.99, 264, 851, 739, 451],
[38.67, 63.09, 80, 109.99, 110, 254.99, 264, 850, 739, 451],
[38.79, 62.53, 79.99, 109.99, 110, 254.99, 264, 850, 740, 451],
[38.58, 62.92, 80, 110, 110, 254.99, 263.99, 853, 743, 451],
[38.58, 62.53, 79.99, 110, 110, 255.37, 264.34, 851, 737, 451],
[38.82, 63.24, 79.99, 110, 110, 255, 264, 852, 735, 451],
[38.58, 63.27, 80, 109.99, 110, 255, 264, 856, 743, 451],
[38.61, 63.7, 80, 110, 110, 255, 263.99, 853, 742, 451],
[38.7, 64.95, 80, 109.99, 110, 255, 263.99, 851, 739, 451],
[38.58, 67.01, 79.99, 110, 110, 254.99, 264, 854, 737, 451],
[38.55, 66.44, 80, 110, 110, 254.99, 264, 851, 741, 451],
[38.52, 65.7, 79.99, 110, 110, 255, 263.99, 846, 733, 451],
[38.47, 64.66, 80, 110, 109.99, 255, 263.99, 851, 741, 451],
[38.5, 64.19, 80, 109.99, 110, 254.99, 263.99, 851, 736, 451],
[38.5, 63.8, 80, 110, 110, 245.13, 263.68, 857, 744, 451],
[38.58, 66.32, 79.99, 110, 110, 255, 263.99, 855, 742, 451],
[38.55, 65.03, 80, 109.99, 110, 255, 264, 844, 737, 450],
[38.44, 67.19, 79.99, 110, 110, 255, 263.99, 855, 745, 451],
[38.47, 66.37, 79.99, 110, 110, 255, 264, 851, 740, 451],
[38.47, 67.52, 80, 110, 110, 255, 263.99, 847, 738, 451],
[38.58, 66.67, 79.99, 110, 110, 255, 264, 855, 742, 451],
[38.47, 65.15, 80, 110, 110, 254.99, 264, 850, 735, 452],
[38.47, 63.84, 80, 110, 110, 255, 263.99, 854, 740, 450],
[38.44, 64.83, 80, 110, 110, 255, 264, 849, 741, 450],
[38.52, 64.8, 79.99, 110, 110, 254.99, 263.99, 851, 742, 451],
[38.7, 65.14, 79.99, 110, 109.99, 254.99, 264, 865, 748, 450],
[38.58, 65.59, 80, 110, 110, 254.99, 263.99, 855, 746, 452],
[38.44, 67.6, 79.99, 110, 109.99, 254.99, 263.99, 852, 739, 451],
[38.5, 67.21, 80, 110, 110, 255, 263.99, 856, 747, 451],
[38.58, 66.49, 80, 109.99, 110, 255, 264, 855, 746, 451],
[38.67, 64.97, 80, 110, 110, 255, 264, 858, 743, 451],
[38.61, 65.04, 80, 110, 109.99, 254.99, 263.99, 858, 739, 450],
[38.67, 64.82, 80, 109.99, 110, 255, 263.99, 857, 740, 451],
[38.79, 64.21, 80, 110, 110, 254.99, 263.99, 852, 733, 450],
[39.06, 65.12, 80, 110, 110, 254.99, 264, 855, 740, 451],
[38.88, 66.28, 79.99, 109.99, 110, 254.99, 264, 853, 737, 450],
[39.18, 66.26, 80, 110, 110, 255, 263.99, 856, 745, 451],
[39.03, 65.9, 79.99, 109.99, 109.99, 254.99, 264, 856, 741, 450],
[39.18, 67.21, 80, 110, 110, 255, 264.01, 851, 740, 450],
[39.03, 66.49, 79.99, 109.99, 110, 254.99, 263.99, 852, 739, 450],
[39.18, 66.16, 79.99, 110, 110, 254.99, 264, 856, 747, 450],
[39, 64.85, 79.99, 110, 110, 254.99, 263.99, 855, 743, 450],
[39.24, 63.69, 80, 110, 110, 254.99, 264, 853, 739, 450],
[39.06, 63.98, 79.99, 110, 110, 255, 263.99, 851, 740, 450],
[39.18, 64.05, 80, 110, 110, 254.99, 263.99, 854, 745, 450],
[39.15, 65.89, 80, 110, 110, 254.98, 264, 850, 739, 449],
[39.18, 66.21, 79.99, 110, 110, 254.99, 263.99, 848, 733, 449],
[39.27, 64.15, 80, 110, 110, 254.99, 263.99, 856, 743, 450],
[39.24, 64.33, 80, 109.99, 110, 255, 264, 855, 737, 450],
[39.39, 63.42, 80, 110, 110, 254.99, 263.99, 859, 744, 450],
[39.39, 64.33, 79.99, 110, 110, 254.98, 263.98, 855, 737, 450],
[39.43, 63.87, 80, 110, 109.99, 254.99, 264, 857, 740, 450],
[39.55, 65.03, 80, 109.99, 110, 254.99, 263.99, 854, 740, 450],
[39.64, 64.78, 79.99, 110, 110, 254.99, 263.99, 860, 738, 450],
[39.52, 64.27, 79.99, 110, 110, 254.99, 264, 861, 743, 450],
[39.43, 64.39, 79.99, 110, 110, 254.97, 263.99, 858, 746, 450],
[39.43, 64.44, 80, 110, 110, 254.99, 264, 861, 744, 449],
[39.39, 65.84, 79.99, 110, 110, 255, 264, 855, 743, 450],
[39.64, 66.08, 80, 110, 110, 254.99, 264, 864, 744, 449],
[39.36, 65.78, 79.99, 110, 109.99, 255, 264, 863, 746, 450],
[39.55, 66.58, 79.99, 110, 110, 254.97, 263.99, 860, 743, 450],
[39.55, 66.33, 79.99, 110, 110, 255, 263.99, 861, 746, 450],
[39.46, 65.13, 80, 110, 110, 255, 263.99, 860, 747, 451],
[39.55, 64.42, 80, 109.99, 109.99, 254.99, 264, 861, 743, 450],
[39.55, 63.71, 80, 110, 110, 254.99, 264, 859, 742, 450],
[39.61, 64.2, 79.99, 110, 110, 255.02, 263.99, 863, 740, 450],
[39.67, 63.6, 79.99, 110, 110, 254.99, 263.99, 855, 742, 450],
[39.76, 63.85, 79.99, 109.99, 110, 255, 263.99, 854, 739, 450],
[39.76, 65.32, 79.99, 110, 110, 254.99, 263.99, 851, 740, 449],
[39.94, 65.42, 79.99, 110, 110, 255, 264, 854, 739, 449],
[39.88, 66.93, 79.99, 110, 109.99, 255.11, 264, 857, 736, 449],
[40.15, 66.11, 79.99, 110, 110, 255, 264, 855, 743, 449],
[40.03, 65.55, 79.99, 110, 110, 254.99, 263.98, 852, 733, 448],
[40.06, 64.53, 79.99, 110, 110, 254.99, 264, 846, 734, 447],
[40.18, 63.22, 80, 110, 110, 255.35, 264, 866, 733, 448],
[40.37, 63.55, 80, 110, 110, 255, 263.99, 857, 735, 449],
[40.28, 62.76, 79.99, 110, 110, 254.99, 264, 856, 736, 448],
[40.37, 64.06, 80, 109.99, 110, 254.99, 264, 847, 734, 447],
[40.58, 65.85, 79.99, 109.99, 109.99, 254.99, 263.99, 838, 721, 445],
[40.58, 67.55, 79.99, 110, 110, 255, 264, 845, 729, 447],
[40.74, 66.61, 79.99, 110, 110, 254.99, 264, 855, 734, 448],
[40.52, 65.16, 80, 109.99, 110, 255, 264, 855, 739, 448],
[40.49, 64.15, 79.99, 110, 110, 255, 263.99, 859, 736, 448],
[40.55, 64.1, 79.99, 109.99, 110, 255, 263.99, 849, 733, 447],
[40.49, 64.69, 79.99, 110, 110, 254.99, 263.99, 860, 740, 449],
[40.55, 64.98, 80, 110, 110, 254.99, 263.99, 860, 744, 449],
[40.71, 64.36, 79.99, 110, 110, 255, 264, 862, 745, 449],
[40.77, 65.19, 79.99, 110, 109.99, 254.99, 264, 863, 747, 449],
[40.49, 65.59, 80, 110, 110, 255, 264, 851, 733, 448],
[40.49, 66.77, 80, 110, 110, 255, 264, 841, 729, 445],
[40.49, 67.49, 79.99, 110, 110, 255, 263.99, 844, 731, 446],
[40.46, 65.08, 79.99, 110, 110, 255, 264, 862, 747, 449],
[40.43, 66.75, 80, 110, 110, 254.99, 263.99, 858, 741, 448],
[40.4, 65.97, 80, 110, 109.99, 255, 263.99, 860, 743, 449],
[40.52, 64.92, 80, 110, 110, 254.99, 263.99, 856, 739, 448],
[40.4, 65.08, 80, 109.99, 110, 254.99, 263.99, 860, 745, 449],
[40.4, 63.9, 80, 109.99, 110, 254.99, 264, 857, 741, 449],
[40.46, 66.03, 79.99, 109.99, 109.99, 255, 264, 861, 741, 449],
[40.71, 67.85, 79.99, 110, 110, 255, 264, 855, 739, 449],
[40.49, 66.78, 79.99, 110, 110, 255, 263.99, 855, 737, 448],
[40.55, 66.35, 80, 110, 110, 255, 264, 856, 736, 448],
[40.52, 65.88, 80, 110, 110, 255, 263.99, 859, 737, 448],
[40.58, 67.04, 79.99, 109.99, 110, 254.99, 263.99, 858, 743, 448],
[40.64, 66.66, 79.99, 109.99, 110, 255, 264, 859, 738, 449],
[40.52, 65.8, 80, 110, 110, 254.99, 263.99, 859, 739, 449],
[40.49, 65.42, 79.99, 110, 110, 254.99, 263.99, 859, 738, 448],
[40.49, 64.05, 80, 110, 110, 254.99, 264, 853, 735, 448],
[40.46, 64.47, 79.99, 109.99, 110, 255, 263.99, 849, 731, 447],
[40.58, 65, 80, 109.99, 110, 254.99, 263.99, 846, 729, 447],
[40.52, 64.83, 79.99, 109.99, 110, 255, 263.99, 862, 748, 450],
[40.55, 65.49, 80, 110, 110, 255, 264, 862, 748, 449],
[40.55, 66.45, 79.99, 110, 110, 255, 264, 859, 739, 449],
[40.55, 65.43, 79.99, 110, 110, 255, 264, 852, 734, 448],
[40.55, 65.67, 80, 109.99, 110, 255, 264, 854, 734, 448],
[40.55, 65.53, 80, 110, 110, 255, 264, 856, 741, 448],
[40.49, 65.32, 79.99, 110, 110, 255, 263.99, 851, 734, 448],
[40.55, 66.77, 79.99, 109.99, 110, 255, 264, 849, 731, 448],
[40.46, 66.4, 80, 110, 110, 255, 263.99, 856, 739, 448],
[40.43, 67.21, 80, 109.99, 110, 255, 263.99, 856, 736, 448],
[40.68, 66.73, 80, 110, 110, 254.99, 263.98, 852, 734, 448],
[40.43, 68.08, 79.99, 110, 110, 254.99, 264, 854, 733, 448],
[40.43, 67.64, 80, 110, 110, 255, 263.99, 842, 725, 446],
[40.43, 66.37, 79.99, 109.99, 110, 254.99, 263.99, 832, 721, 445],
[40.46, 65.2, 80, 110, 110, 254.99, 263.99, 849, 733, 447],
[40.49, 66.2, 79.99, 109.99, 109.99, 255, 263.99, 858, 739, 449],
[40.46, 66.86, 80, 110, 109.99, 255, 263.99, 856, 737, 448],
[40.34, 66.51, 79.99, 110, 109.99, 254.99, 263.99, 844, 730, 447],
[40.34, 66.06, 80, 110, 110, 255, 263.99, 845, 732, 447],
[40.43, 65.57, 79.99, 109.99, 110, 255, 263.99, 848, 732, 447],
[40.22, 65.11, 80, 110, 109.99, 254.99, 263.99, 850, 737, 448],
[40.4, 65.99, 79.99, 110, 110, 254.99, 264, 848, 732, 448],
[40.15, 67.55, 79.99, 109.99, 110, 255, 264, 850, 736, 448],
[40.09, 67.3, 79.99, 110, 110, 255, 263.99, 852, 735, 449],
[40.06, 67.34, 80, 109.99, 110, 255, 264, 860, 744, 449],
[40.03, 66.02, 79.99, 110, 110, 254.99, 264, 862, 744, 449],
[40.03, 65.69, 79.99, 110, 109.99, 254.99, 264, 855, 741, 448],
[40.18, 65.05, 79.99, 109.99, 109.99, 254.99, 263.99, 850, 738, 449],
[40.15, 65.48, 80, 110, 110, 254.99, 264, 848, 731, 448],
[39.91, 65.5, 80, 110, 110, 255, 263.99, 855, 742, 449],
[39.85, 65.73, 80, 110, 109.99, 255, 263.99, 862, 746, 449],
[39.97, 66.56, 79.99, 110, 110, 255, 264, 862, 744, 449],
[39.91, 66.25, 80, 110, 110, 255, 263.99, 857, 741, 450],
[39.79, 67.74, 79.99, 109.99, 109.99, 254.99, 264, 847, 729, 447],
[39.79, 67.05, 79.99, 110, 110, 254.99, 264, 849, 732, 449],
[39.73, 67.93, 79.99, 110, 110, 254.99, 263.98, 859, 739, 449],
[39.58, 67.94, 80, 110, 110, 254.99, 263.99, 848, 735, 449],
[39.58, 67.51, 80, 110, 110, 254.99, 264, 847, 739, 449],
[39.52, 69.02, 79.99, 110, 110, 254.99, 264, 854, 741, 450],
[39.46, 68.36, 79.99, 110, 110, 255, 264, 854, 744, 449],
[39.46, 66.25, 80, 110, 110, 255, 263.79, 866, 736, 450],
[39.39, 65.96, 80, 110, 110, 254.99, 263.99, 853, 739, 450],
[39.43, 65.7, 79.99, 110, 110, 254.99, 264, 856, 737, 450],
[39.55, 65.25, 80, 110, 109.99, 254.99, 264, 851, 734, 449],
[39.33, 65.76, 79.99, 110, 110, 255, 264, 839, 727, 447],
[39.49, 65.07, 80, 110, 110, 254.99, 264.03, 844, 732, 449],
[39.24, 64.85, 79.99, 110, 109.99, 255, 263.99, 850, 735, 449],
[39.18, 65.42, 79.99, 110, 110, 255, 264.01, 857, 743, 450],
[39.12, 66.05, 80, 110, 109.99, 254.99, 264, 856, 739, 450],
[39.06, 66.94, 79.99, 110, 109.99, 255, 264, 852, 743, 450],
[39.12, 67.41, 79.99, 110, 109.99, 254.99, 263.98, 870, 743, 451],
[39.03, 67.02, 80, 109.99, 110, 255, 264, 856, 740, 449],
[39.43, 65.75, 80, 110, 110, 254.99, 264, 854, 739, 450],
[39.24, 66.67, 79.99, 110, 109.99, 254.99, 263.99, 844, 726, 448],
[39.43, 66.68, 80, 110, 110, 255, 263.98, 840, 724, 448],
[39.36, 66.46, 80, 110, 109.99, 254.99, 263.99, 844, 731, 449],
[39.39, 65.7, 79.99, 110, 110, 255, 263.99, 844, 734, 448],
[39.39, 66.64, 80, 110, 110, 254.99, 264, 849, 738, 449],
[39.3, 65.76, 80, 109.99, 110, 254.99, 263.99, 847, 732, 448],
[39.58, 64.36, 80, 110, 110, 254.99, 264, 840, 724, 447],
[39.55, 64.34, 79.99, 109.99, 110, 255, 264, 835, 721, 446],
[39.79, 63.5, 79.99, 110, 110, 255, 264, 836, 720, 446],
[39.64, 63.89, 80, 109.99, 110, 255, 264, 841, 723, 447],
[39.97, 64.35, 80, 110, 110, 255, 264, 850, 736, 448],
[39.82, 63.8, 79.99, 110, 110, 255, 264, 844, 731, 447],
[39.85, 63.66, 79.99, 110, 109.99, 255, 264, 854, 734, 449],
[40, 64.81, 80, 110, 110, 255, 263.99, 858, 738, 449],
[39.82, 65.4, 80, 110, 110, 254.99, 263.99, 856, 737, 449],
[39.94, 66.67, 80, 109.99, 110, 255, 264, 855, 736, 449],
[39.91, 66.09, 79.99, 110, 110, 255, 264, 850, 733, 448],
[39.91, 66.3, 79.99, 110, 110, 255, 263.99, 843, 729, 447],
[39.97, 65.41, 80, 110, 110, 255, 263.99, 849, 733, 448],
[39.94, 64.61, 80, 110, 110, 254.99, 263.99, 862, 747, 449],
[40.03, 66.05, 80, 110, 110, 255, 264, 855, 736, 449],
[40.22, 66.28, 79.99, 110, 110, 254.99, 263.99, 858, 737, 449],
[40.22, 65.31, 79.99, 109.99, 110, 255, 263.99, 856, 733, 449],
[40.15, 63.92, 80, 109.99, 109.99, 254.99, 263.99, 859, 739, 449],
[40.12, 63.59, 80, 110, 110, 254.99, 263.99, 852, 731, 449],
[40.31, 63.62, 80, 110, 110, 255, 264, 853, 736, 449],
[40.09, 65.2, 80, 110, 110, 255, 263.99, 853, 734, 448],
[40.03, 65.56, 80, 110, 110, 255, 263.99, 857, 739, 449],
[39.7, 65.18, 80, 110, 109.99, 255, 263.99, 861, 737, 450],
[40, 64.55, 80, 110, 110, 255, 263.99, 867, 747, 449],
[39.85, 66.62, 79.99, 110, 110, 254.99, 264, 859, 745, 450],
[39.82, 66, 79.99, 109.99, 110, 255, 264, 848, 729, 449],
[39.88, 66.93, 79.99, 109.99, 110, 255, 263.99, 849, 734, 448],
[39.88, 65.33, 80, 110, 110, 255, 264, 858, 741, 449],
[39.85, 64.69, 80, 110, 110, 254.99, 263.99, 856, 740, 449],
[39.79, 66.96, 80, 110, 109.99, 254.99, 263.99, 855, 739, 450],
[39.79, 67.09, 80, 109.99, 110, 255, 264, 852, 739, 448],
[39.85, 65.67, 79.99, 110, 110, 255, 263.99, 851, 737, 449],
[39.97, 67.46, 80, 110, 110, 254.99, 263.99, 855, 737, 449],
[39.76, 66.86, 79.99, 110, 110, 255, 263.99, 843, 731, 448],
[39.67, 65.88, 80, 110, 110, 255, 264, 850, 733, 449],
[39.73, 65.85, 79.99, 110, 110, 255, 264, 834, 721, 445],
[39.97, 65.66, 80, 110, 110, 255, 264, 844, 732, 447],
[39.94, 66.46, 79.99, 109.99, 110, 254.99, 263.99, 851, 735, 448],
[39.79, 65.53, 80, 109.99, 110, 255, 263.99, 843, 730, 447],
[39.76, 64.39, 80, 110, 110, 254.99, 264, 836, 719, 445],
[40.03, 64.3, 79.99, 110, 110, 255, 263.99, 834, 724, 445],
[39.88, 63.59, 80, 110, 110, 254.99, 264, 831, 720, 445],
[39.85, 65.95, 79.99, 110, 109.99, 254.99, 263.99, 840, 727, 446],
[39.76, 67.27, 79.99, 110, 110, 254.99, 263.99, 835, 727, 446],
[39.7, 67.33, 80, 110, 109.99, 255, 264, 832, 718, 445],
[39.7, 66.19, 80, 110, 110, 255, 264, 836, 720, 446],
[39.91, 67.43, 80, 110, 110, 254.99, 264, 846, 734, 448],
[39.7, 66.15, 80, 109.99, 110, 254.99, 263.99, 855, 737, 449],
[39.67, 66.41, 80, 110, 110, 255, 264, 853, 740, 449],
[39.79, 66.87, 79.99, 110, 110, 254.99, 264, 858, 739, 449],
[39.7, 65.46, 79.99, 109.99, 110, 255, 264, 856, 741, 449],
[39.76, 64.93, 79.99, 110, 110, 254.99, 263.99, 865, 742, 448],
[39.94, 64.54, 79.99, 109.99, 109.99, 255, 263.99, 862, 736, 450],
[39.76, 64.58, 80, 110, 110, 255, 264, 855, 737, 449],
[39.64, 64.37, 80, 110, 110, 255, 264, 850, 731, 448],
[39.67, 64.55, 79.99, 109.99, 110, 255, 263.99, 847, 731, 448],
[39.67, 65.06, 79.99, 110, 110, 255, 264, 851, 735, 449],
[39.7, 65.36, 79.99, 109.99, 110, 255, 264, 865, 736, 449],
[39.88, 66.42, 79.99, 110, 110, 254.99, 263.99, 853, 733, 449],
[39.76, 66.61, 79.99, 110, 110, 255, 264, 853, 734, 449],
[39.94, 65.5, 80, 110, 110, 255, 263.99, 849, 736, 448],
[39.76, 67.06, 79.99, 110, 110, 255, 264, 839, 724, 447],
[39.76, 66.4, 80, 109.99, 110, 254.99, 263.99, 831, 718, 444],
[39.79, 64.68, 80, 109.99, 110, 255, 264, 836, 720, 445],
[39.73, 64.27, 79.99, 110, 110, 254.99, 263.99, 835, 719, 445],
[40.03, 65.21, 80, 110, 110, 255, 264, 841, 723, 445],
[40.03, 65.05, 80, 110, 110, 255, 263.99, 851, 730, 448],
[39.94, 65.74, 79.99, 110, 109.99, 254.99, 263.99, 848, 734, 448],
[39.91, 64.42, 79.99, 109.99, 110, 254.99, 263.99, 844, 727, 447],
[40.03, 66.32, 80, 110, 110, 254.99, 264, 842, 727, 447],
[39.97, 66.69, 79.99, 110, 110, 255, 264, 843, 726, 446],
[40.06, 65.34, 79.99, 110, 110, 254.99, 263.99, 844, 728, 447],
[40.09, 65.56, 79.99, 110, 110, 254.99, 263.9, 842, 725, 446],
[40.09, 64.24, 79.99, 110, 110, 255, 263.99, 834, 714, 444],
[40.06, 64.3, 79.99, 110, 110, 255, 263.99, 839, 723, 445],
[40.15, 62.7, 80, 110, 110, 254.99, 264, 841, 724, 446],
[40.49, 62.31, 80, 109.99, 110, 255, 263.99, 850, 733, 447],
[40.34, 62.91, 79.99, 110, 110, 255.02, 263.6, 857, 735, 445],
[40.34, 64.24, 79.99, 110, 110, 255, 263.99, 852, 734, 447],
[40.43, 66.48, 79.99, 110, 110, 255, 263.99, 854, 738, 448],
[40.46, 66.44, 80, 110, 110, 255, 264, 859, 736, 448],
[40.49, 65.5, 79.99, 110, 110, 255.06, 263.68, 865, 741, 448],
[40.55, 66.69, 80, 109.99, 109.99, 255, 263.99, 862, 745, 448],
[40.61, 66.37, 79.99, 110, 110, 254.99, 264, 865, 741, 449],
[40.68, 66.02, 79.99, 109.99, 110, 255, 264, 860, 738, 448],
[40.68, 64.19, 80, 110, 110, 255, 263.99, 863, 741, 448],
[40.83, 64.23, 80, 110, 110, 255.29, 263.76, 861, 739, 449],
[40.92, 64.07, 80, 110, 110, 255, 263.99, 857, 739, 448],
[40.77, 65.88, 79.99, 110, 110, 254.99, 264, 855, 736, 448],
[41.02, 66.43, 79.99, 110, 109.99, 254.99, 264, 854, 734, 448],
[40.83, 65.75, 79.99, 109.99, 110, 254.99, 263.99, 860, 739, 448],
[40.71, 65.93, 79.99, 110, 110, 254.99, 263.99, 861, 746, 448],
[40.77, 66.02, 79.99, 110, 110, 254.99, 264, 861, 742, 449],
[40.52, 66.73, 79.99, 110, 110, 254.99, 263.99, 854, 737, 448],
[40.46, 67.65, 80, 110, 110, 255, 264, 854, 739, 448],
[40.37, 67.8, 80, 110, 110, 255, 264, 854, 737, 448],
[40.43, 67.14, 79.99, 110, 110, 255, 264, 857, 741, 449],
[40.22, 66.73, 80, 109.99, 110, 255, 264, 860, 739, 449],
[40.37, 66.1, 79.99, 110, 110, 255, 263.99, 856, 736, 449],
[40.43, 65.06, 80, 110, 110, 254.99, 264, 854, 732, 448],
[40.31, 64.31, 80, 110, 110, 254.99, 263.99, 852, 735, 448],
[40.43, 65.86, 79.99, 109.99, 109.99, 255, 264, 858, 739, 449],
[40.28, 66.93, 80, 109.99, 109.99, 255, 263.99, 856, 741, 449],
[40.43, 67, 79.99, 109.99, 110, 254.99, 264, 851, 736, 448],
[40.37, 68.2, 80, 110, 110, 254.99, 263.99, 857, 740, 448],
[40.06, 66.72, 80, 109.99, 110, 254.99, 263.99, 850, 734, 449],
[40.15, 67.35, 79.99, 110, 110, 254.99, 264, 855, 738, 448],
[39.91, 66.08, 79.99, 110, 110, 255, 264, 860, 742, 448],
[39.73, 66.96, 79.99, 110, 110, 254.99, 264, 865, 745, 449],
[39.64, 67.85, 80, 110, 110, 255, 263.99, 860, 747, 450],
[39.52, 68.29, 79.99, 109.99, 110, 255, 264, 856, 738, 450],
[39.39, 68.46, 80, 110, 110, 254.99, 264, 856, 739, 450],
[39.24, 66.62, 80, 110, 110, 255, 264, 857, 739, 450],
[39.21, 66.78, 79.99, 110, 110, 255, 264, 859, 742, 450],
[39.03, 66.64, 79.99, 110, 110, 254.99, 264, 855, 736, 451],
[38.91, 66.13, 79.99, 110, 110, 255, 264, 849, 734, 450],
[38.67, 66.52, 80, 110, 110, 254.99, 263.99, 850, 734, 450],
[38.79, 65.75, 79.99, 110, 110, 255, 264, 856, 741, 451],
[38.64, 64.99, 79.99, 110, 110, 255, 264, 847, 736, 450],
[38.5, 65.11, 80, 109.99, 110, 254.99, 263.99, 852, 737, 451],
[38.52, 66.45, 79.99, 110, 110, 255, 264, 852, 737, 451],
[38.52, 66.78, 80, 110, 110, 255, 263.99, 853, 739, 451],
[38.44, 66.15, 79.99, 110, 110, 255, 263.99, 846, 740, 450],
[38.64, 66.19, 80, 110, 110, 254.99, 264, 851, 739, 451],
[38.2, 68.51, 80, 110, 110, 255, 264, 849, 738, 451],
[38.41, 68.77, 79.99, 110, 109.99, 255, 264, 853, 739, 451],
[38.35, 67.55, 79.99, 110, 109.99, 255, 264, 850, 736, 451],
[38.23, 66.52, 79.99, 110, 110, 255, 263.99, 858, 742, 451],
[38.14, 66.34, 80, 110, 109.99, 254.99, 263.99, 854, 735, 451],
[38.26, 65.26, 80, 110, 110, 255, 264, 854, 735, 452],
[38.2, 65.83, 80, 110, 110, 255, 263.99, 845, 729, 450],
[38.38, 65.6, 80, 110, 110, 255, 264, 848, 736, 451],
[38.11, 66.92, 79.99, 110, 109.99, 255, 263.99, 851, 739, 451],
[38.23, 66.68, 80, 110, 110, 254.98, 264, 855, 742, 451],
[38.23, 66.99, 80, 110, 110, 255, 263.99, 845, 735, 451],
[37.99, 67.42, 79.99, 109.99, 110, 254.99, 264, 844, 730, 450],
[38.02, 65.72, 79.99, 109.99, 110, 255, 263.99, 841, 732, 451],
[37.96, 65.1, 80, 110, 110, 254.98, 264, 843, 730, 451],
[37.96, 64.52, 80, 110, 109.99, 255, 264, 853, 739, 452],
[37.81, 63.34, 80, 110, 110, 255, 264, 849, 734, 451],
[38.05, 63.57, 79.99, 109.99, 110, 254.99, 264, 851, 743, 451],
[37.84, 63.42, 80, 110, 110, 254.99, 264, 847, 734, 451],
[37.87, 63.1, 80, 110, 110, 254.97, 264, 854, 738, 452],
[37.81, 66.06, 79.99, 109.99, 110, 254.99, 263.99, 854, 742, 452],
[37.81, 65.82, 80, 110, 110, 254.99, 264, 857, 744, 452],
[37.96, 64.65, 80, 109.99, 110, 255, 263.99, 852, 740, 452],
[38.02, 65.3, 80, 110, 110, 255, 263.99, 852, 737, 451],
[38.29, 65.46, 79.99, 110, 110, 254.97, 263.99, 843, 731, 450],
[38.08, 66.47, 79.99, 110, 109.99, 255, 264, 845, 735, 451],
[37.93, 67.16, 80, 109.99, 109.99, 255, 264, 850, 735, 452],
[37.93, 65.44, 79.99, 109.99, 110, 255, 263.99, 847, 739, 452],
[37.99, 65.47, 80, 110, 110, 254.99, 263.99, 857, 740, 452],
[37.72, 64.85, 79.99, 109.99, 110, 255.17, 264, 856, 738, 452],
[37.7, 64.73, 79.99, 109.99, 110, 255, 263.98, 848, 735, 452],
[37.81, 63.96, 80, 110, 109.99, 255, 263.99, 852, 734, 452],
[37.81, 64.73, 80, 110, 110, 255, 264, 855, 738, 452],
[37.78, 65.79, 79.99, 110, 110, 255, 263.99, 853, 738, 452],
[38.08, 66.36, 79.99, 110, 110, 255.22, 264, 860, 741, 452],
[37.78, 66.41, 80, 109.99, 110, 255, 263.99, 853, 742, 453],
[38.02, 65.47, 79.99, 109.99, 110, 255, 263.99, 845, 731, 451],
[38.08, 64.08, 80, 110, 109.99, 254.99, 264, 849, 736, 452],
[38.11, 66.76, 79.99, 110, 109.99, 255, 263.99, 850, 734, 452],
[38.11, 67.55, 79.99, 110, 109.99, 255.42, 264, 872, 738, 452],
[38.11, 67.24, 79.99, 110, 110, 255, 263.99, 848, 732, 452],
[37.96, 65.9, 80, 110, 110, 255, 264, 844, 734, 451],
[37.96, 66.73, 80, 110, 110, 255, 263.99, 842, 729, 451],
[37.81, 66.55, 79.99, 109.99, 110, 254.99, 264, 853, 737, 451],
[37.78, 65.27, 80, 110, 110, 257.82, 263.99, 856, 741, 453],
[37.7, 64, 80, 110, 110, 255, 264, 853, 738, 452],
[37.61, 63.09, 79.99, 109.99, 110, 254.99, 264, 857, 742, 453],
[37.67, 63.06, 80, 109.99, 110, 255, 264, 851, 739, 452],
[37.81, 64.29, 80, 109.99, 110, 254.99, 263.99, 854, 743, 453],
[37.75, 66.35, 79.99, 109.99, 110, 254.99, 263.99, 865, 744, 453],
[37.64, 66.21, 80, 110, 110, 254.99, 264, 854, 739, 453],
[37.72, 66.09, 80, 109.99, 110, 254.99, 263.99, 856, 742, 452],
[37.43, 67.01, 80, 109.99, 110, 255, 263.99, 855, 745, 454],
[37.87, 68.64, 79.99, 110, 110, 254.99, 263.99, 853, 743, 454],
[37.58, 68.14, 79.99, 109.99, 110, 255, 264, 857, 739, 453],
[37.7, 66.64, 79.99, 109.99, 110, 255, 264, 851, 736, 452],
[37.87, 66.15, 80, 109.99, 110, 255, 264, 855, 741, 453],
[37.75, 65.69, 80, 109.99, 110, 255, 264, 855, 737, 453],
[37.81, 65.13, 80, 109.99, 110, 254.99, 263.99, 853, 739, 453],
[37.93, 65.75, 80, 109.99, 110, 254.99, 264, 850, 740, 452],
[37.81, 65.37, 80, 110, 110, 254.99, 264, 852, 738, 451],
[37.96, 66.73, 80, 110, 110, 254.99, 264, 860, 748, 453],
[37.99, 68.13, 79.99, 110, 110, 255, 264, 859, 744, 452],
[38.32, 67.08, 80, 110, 110, 254.99, 264, 857, 744, 452],
[38.17, 66.98, 79.99, 110, 110, 255, 264, 852, 737, 452],
[38.23, 68.37, 79.99, 110, 110, 254.99, 264, 844, 731, 451],
[38.35, 67.22, 79.99, 110, 110, 254.99, 264, 839, 723, 447],
[38.38, 65.43, 80, 110, 110, 254.99, 263.99, 843, 731, 449],
[38.47, 65.09, 80, 110, 109.99, 255, 264, 850, 734, 450],
[38.76, 65.13, 80, 109.99, 110, 254.99, 263.99, 858, 743, 451],
[38.61, 65.12, 80, 110, 110, 254.99, 264, 861, 744, 451],
[38.7, 64.65, 80, 109.99, 109.99, 254.99, 264, 854, 739, 451],
[38.79, 65.39, 80, 110, 109.99, 254.99, 264, 851, 732, 450],
[38.88, 66.22, 79.99, 109.99, 109.99, 255, 264, 841, 726, 448],
[39.06, 65.65, 79.99, 110, 110, 254.99, 263.99, 848, 731, 450],
[39, 65.45, 79.99, 110, 110, 254.99, 263.99, 851, 732, 450],
[39.03, 66.04, 79.99, 109.99, 110, 254.99, 263.99, 859, 743, 450],
[39.09, 66.49, 79.99, 109.99, 110, 255, 264, 863, 746, 451],
[39.18, 66.69, 79.99, 110, 110, 254.99, 264, 864, 750, 451],
[39.03, 65.68, 80, 109.99, 110, 254.99, 263.99, 864, 745, 450],
[39.12, 67.1, 80, 110, 110, 254.99, 264, 859, 743, 450],
[39.33, 66.26, 80, 109.99, 110, 255, 263.99, 861, 738, 451],
[39.33, 66.71, 80, 109.99, 110, 255, 264, 863, 745, 450],
[39.52, 66.51, 80, 109.99, 110, 254.99, 264, 864, 743, 451],
[39.46, 66.18, 79.99, 109.99, 110, 255, 263.98, 863, 742, 450],
[39.46, 65.78, 80, 110, 110, 255, 264, 857, 741, 451],
[39.43, 65.15, 79.99, 110, 109.99, 254.99, 264, 860, 738, 450],
[39.49, 64.17, 79.99, 110, 110, 254.99, 263.99, 856, 741, 450],
[39.46, 64.07, 80, 109.99, 110, 254.99, 264, 857, 736, 451],
[39.58, 65.32, 80, 110, 110, 255, 263.99, 860, 737, 450],
[39.39, 65.27, 80, 110, 110, 255, 263.99, 859, 735, 451],
[39.55, 65.71, 80, 110, 109.99, 255, 264, 854, 733, 450],
[39.58, 65.91, 80, 110, 110, 255, 264, 849, 726, 450],
[39.52, 66.47, 80, 110, 110, 254.99, 263.99, 857, 738, 451],
[39.39, 66.53, 79.99, 110, 110, 255, 264, 858, 739, 451],
[39.52, 67.39, 80, 110, 110, 255, 264, 858, 742, 451],
[39.64, 68.29, 79.99, 110, 110, 255, 263.99, 862, 742, 451],
[39.52, 68.53, 80, 110, 110, 255, 264, 861, 739, 451],
[39.55, 67.63, 80, 110, 110, 255, 263.99, 861, 742, 451],
[39.43, 67.23, 80, 110, 110, 255, 264, 853, 734, 450],
[39.49, 66.67, 80, 110, 110, 255, 264, 848, 731, 450],
[39.58, 66.58, 79.99, 110, 110, 254.99, 264.01, 865, 744, 450],
[39.58, 66.56, 79.99, 110, 110, 255, 263.99, 856, 737, 450],
[39.73, 65.78, 79.99, 110, 109.99, 255, 264, 862, 741, 450],
[39.52, 66.43, 80, 110, 109.99, 255, 264, 856, 738, 450],
[39.61, 67.95, 79.99, 109.99, 110, 255, 263.99, 858, 739, 450],
[39.61, 68, 79.99, 110, 110, 254.99, 264, 859, 740, 451],
[39.67, 65.54, 80, 110, 110, 255, 264, 855, 738, 450],
[39.67, 66.9, 80, 110, 109.99, 255, 263.99, 849, 732, 449],
[39.79, 66.61, 80, 110, 110, 254.99, 264, 847, 726, 449],
[39.73, 66.36, 80, 110, 110, 254.99, 263.99, 857, 741, 450],
[39.97, 65.59, 79.99, 109.99, 110, 255, 264.01, 863, 741, 450],
[40.03, 64.39, 80, 109.99, 109.99, 255, 263.99, 862, 743, 450],
[40.03, 63.93, 80, 110, 109.99, 254.99, 263.99, 862, 741, 450],
[39.91, 64.66, 80, 110, 110, 254.99, 263.99, 861, 734, 449],
[39.58, 65.69, 79.99, 110, 110, 254.99, 263.99, 854, 744, 450],
[39.97, 64.66, 79.99, 109.99, 110, 255, 264, 863, 740, 450],
[40.06, 66.16, 80, 109.99, 110, 255, 264, 857, 739, 450],
[40.03, 65.4, 80, 109.99, 109.99, 255, 264, 862, 740, 450],
[40.15, 66.42, 80, 110, 110, 255, 263.99, 859, 741, 450],
[40.28, 67.48, 79.99, 110, 110, 255, 263.96, 864, 738, 450],
[40.18, 66.49, 80, 110, 110, 254.99, 263.99, 855, 738, 449],
[40.25, 66.14, 80, 110, 110, 254.99, 263.99, 857, 738, 449],
[40.46, 65.87, 80, 110, 110, 255, 264, 856, 736, 449],
[40.4, 64.66, 80, 110, 110, 255, 264, 860, 737, 449],
[40.4, 64.13, 80, 109.99, 110, 254.98, 263.43, 867, 743, 448],
[40.49, 63.66, 80, 109.99, 110, 255, 263.99, 866, 744, 449],
[40.71, 62.7, 80, 110, 110, 255, 263.99, 860, 741, 448],
[40.58, 63.39, 80, 110, 109.99, 255, 264, 857, 736, 449],
[40.83, 64.83, 79.99, 110, 109.99, 255, 264, 853, 731, 447],
[40.92, 64.69, 79.99, 109.99, 109.99, 255, 263.26, 871, 737, 448],
[40.98, 64.88, 80, 109.99, 110, 255, 263.99, 856, 738, 448],
[41.02, 65.32, 79.99, 110, 110, 254.99, 263.99, 846, 725, 447],
[40.86, 64.27, 80, 110, 110, 254.99, 263.99, 846, 732, 446],
[41.26, 65.75, 80, 110, 109.99, 255, 264, 845, 732, 446],
[41.33, 64.97, 80, 110, 109.99, 255, 264.15, 872, 736, 447],
[41.39, 66.41, 80, 110, 109.99, 255, 264, 857, 742, 447],
[41.51, 67.99, 79.99, 110, 110, 255, 263.99, 856, 736, 447],
[41.64, 66.99, 80, 110, 110, 254.99, 263.99, 855, 738, 447],
[41.7, 65.66, 80, 110, 110, 255, 264, 845, 728, 445],
[41.67, 65.91, 79.99, 110, 110, 254.99, 263.99, 836, 723, 444],
[41.58, 65.12, 79.99, 109.99, 110, 255, 263.99, 841, 725, 445],
[41.58, 65.37, 79.99, 110, 110, 255, 263.99, 853, 736, 447],
[41.58, 65.8, 80, 110, 110, 254.99, 263.99, 857, 737, 447],
[41.42, 66.37, 79.99, 110, 110, 255, 264, 859, 743, 447],
[41.39, 66.45, 79.99, 110, 110, 255, 264, 856, 735, 447],
[41.51, 64.84, 79.99, 110, 110, 254.99, 263.99, 857, 740, 447],
[41.79, 64.33, 79.99, 110, 110, 254.99, 264, 851, 732, 446],
[41.45, 63.88, 80, 110, 110, 255, 263.99, 849, 731, 446],
[41.42, 62.99, 80, 110, 110, 255, 264, 844, 728, 446],
[41.33, 63.77, 79.99, 110, 109.99, 255, 264, 843, 727, 446],
[41.29, 64.28, 80, 109.99, 110, 254.99, 263.99, 846, 729, 446],
[41.36, 64.59, 80, 110, 109.99, 255, 264, 848, 730, 446],
[41.33, 66.01, 80, 110, 110, 254.99, 263.99, 849, 732, 446],
[41.17, 66.98, 79.99, 110, 110, 254.99, 264, 853, 739, 448],
[41.14, 66.21, 80, 110, 110, 255, 264, 856, 738, 448],
[41.33, 66.2, 80, 110, 110, 255, 263.99, 843, 732, 446],
[41.05, 64.74, 79.99, 109.99, 110, 255, 263.99, 848, 731, 447],
[40.95, 65.29, 80, 109.99, 109.99, 255, 264, 848, 734, 446],
[41.05, 65.7, 79.99, 109.99, 110, 254.99, 264, 845, 727, 446],
[41.33, 65.63, 80, 110, 110, 255, 264, 835, 721, 444],
[41.17, 66.66, 79.99, 110, 110, 254.99, 264, 842, 731, 445],
[41.2, 65.48, 80, 110, 110, 254.99, 263.99, 842, 728, 445],
[41.29, 64.49, 80, 110, 110, 254.99, 263.99, 848, 735, 446],
[41.29, 66.39, 79.99, 110, 109.99, 254.99, 263.99, 850, 731, 446],
[40.98, 65.44, 79.99, 110, 109.99, 255, 264, 851, 735, 447],
[41.02, 65.88, 80, 110, 110, 254.99, 263.98, 860, 737, 447],
[41.11, 64.01, 79.99, 110, 109.99, 254.99, 264, 850, 730, 446],
[40.98, 64.23, 80, 110, 110, 255, 264, 842, 727, 445],
[41.17, 65.84, 80, 110, 110, 254.99, 264, 841, 729, 445],
[41.26, 65.53, 79.99, 110, 110, 255, 263.99, 839, 732, 445],
[41.33, 66.67, 80, 109.99, 110, 255, 263.98, 897, 728, 445],
[41.45, 67.22, 79.99, 110, 110, 254.99, 264, 841, 723, 444],
[41.48, 66.96, 79.99, 110, 110, 255, 264, 840, 726, 444],
[41.54, 66.29, 80, 110, 110, 255, 263.99, 852, 730, 446],
[41.67, 65.2, 79.99, 110, 110, 255, 264, 847, 726, 445],
[41.95, 65.31, 80, 110, 110, 254.99, 263.98, 853, 735, 446],
[41.89, 64.99, 79.99, 110, 109.99, 254.99, 264, 850, 731, 445],
[41.92, 64.54, 79.99, 110, 109.99, 255, 263.99, 852, 730, 446],
[42.05, 63.12, 80, 110, 109.99, 255, 263.99, 842, 729, 443],
[42.14, 65.26, 79.99, 109.99, 109.99, 255, 264, 836, 722, 442],
[42.39, 65.27, 79.99, 110, 110, 254.99, 264, 846, 726, 444],
[42.62, 63.99, 80, 109.99, 110, 254.99, 264, 857, 735, 445],
[42.65, 64.26, 80, 110, 109.99, 255, 263.99, 850, 731, 444],
[42.65, 63.05, 79.99, 110, 110, 255, 263.89, 848, 724, 443],
[42.81, 62.93, 80, 110, 110, 254.99, 263.99, 838, 722, 442],
[43.03, 62.31, 80, 110, 109.99, 255, 263.99, 841, 719, 441],
[43.35, 63.95, 80, 109.99, 110, 255, 264, 844, 725, 442],
[43.06, 66.18, 79.99, 110, 110, 255, 263.99, 841, 721, 442],
[43, 66.45, 80, 109.99, 110, 254.99, 263.9, 850, 731, 444],
[43.16, 65.84, 80, 109.99, 110, 255, 264, 856, 732, 444],
[43.13, 65.92, 80, 110, 109.99, 255, 264, 865, 741, 445],
[43.22, 65.64, 79.99, 110, 110, 255, 264, 864, 746, 445],
[43.22, 66.43, 79.99, 110, 110, 254.99, 264, 864, 741, 445],
[43.38, 67.27, 79.99, 110, 110, 255.04, 263.86, 859, 735, 445],
[43.45, 65.8, 79.99, 110, 109.99, 255, 263.99, 858, 732, 445],
[43.29, 66.1, 80, 110, 110, 254.99, 263.99, 855, 730, 445],
[43.48, 65.89, 79.99, 110, 110, 255.15, 264.02, 852, 731, 445],
[43.32, 64.61, 80, 109.99, 110, 255, 264, 857, 731, 444],
[43.38, 64.65, 79.99, 110, 110, 255, 264.01, 854, 733, 444],
[43.29, 64.51, 79.99, 110, 110, 254.99, 264, 855, 732, 445],
[43.32, 64.57, 79.99, 110, 110, 255, 264, 846, 724, 443],
[43.45, 64.74, 80, 109.99, 110, 255, 264, 845, 724, 443],
[43.32, 65.11, 80, 110, 110, 254.99, 263.99, 843, 721, 442],
[43.42, 63.94, 80, 110, 110, 255, 264, 836, 719, 440],
[43.48, 65.75, 80, 110, 109.99, 255, 263.99, 840, 717, 441],
[43.61, 65.41, 80, 110, 109.99, 255, 263.99, 842, 723, 442],
[43.38, 66.08, 80, 110, 109.99, 254.99, 264, 845, 730, 442],
[43.42, 67.45, 79.99, 109.99, 109.99, 255, 264, 851, 731, 443],
[43.42, 66.79, 79.99, 109.99, 109.99, 254.99, 263.99, 838, 719, 441],
[43.51, 66.43, 80, 110, 110, 255, 263.99, 839, 720, 440],
[43.38, 65.42, 79.99, 109.99, 110, 255, 264, 838, 720, 441],
[43.42, 65.94, 80, 110, 110, 255, 264, 842, 722, 441],
[43.42, 66.3, 80, 110, 110, 255, 264, 837, 721, 441],
[43.48, 66.1, 80, 110, 110, 254.99, 263.99, 837, 724, 441],
[43.32, 65.94, 79.99, 110, 110, 254.99, 263.99, 839, 720, 441],
[43.51, 64.85, 80, 109.99, 109.99, 255, 264, 851, 732, 443],
[43.32, 64.28, 80, 110, 110, 255, 264, 842, 724, 442],
[43.25, 65.31, 79.99, 110, 110, 254.99, 264, 840, 720, 441],
[43.22, 65.27, 80, 110, 110, 254.99, 264, 834, 720, 440],
[43.22, 65.55, 80, 109.99, 110, 255, 264, 841, 722, 441],
[43.19, 65.74, 80, 110, 110, 254.99, 264, 847, 728, 443],
[43.13, 67.62, 79.99, 109.99, 110, 255, 263.99, 853, 730, 443],
[43.06, 67.4, 79.99, 109.99, 110, 255, 264, 842, 724, 443],
[42.97, 67.99, 80, 110, 109.99, 254.99, 264, 843, 731, 443],
[42.58, 67.28, 79.99, 110, 109.99, 255, 264, 850, 736, 444],
[42.42, 67.54, 79.99, 110, 109.99, 255, 263.99, 845, 731, 444],
[42.05, 67.88, 80, 110, 110, 254.99, 264, 848, 730, 444],
[41.86, 67.43, 79.99, 109.99, 109.99, 255, 264, 837, 724, 442],
[41.64, 67.72, 79.99, 110, 110, 254.98, 264, 835, 720, 441],
[41.39, 66.93, 79.99, 110, 110, 255, 263.99, 842, 723, 444],
[41.42, 67.77, 79.99, 110, 110, 255, 264, 853, 736, 446],
[40.89, 66.65, 79.99, 110, 110, 254.99, 264, 855, 734, 446],
[41.17, 65.45, 80, 110, 110, 255, 263.99, 859, 742, 447],
[40.8, 67.85, 79.99, 110, 110, 254.99, 264, 853, 736, 447],
[40.92, 68.47, 79.99, 109.99, 110, 255, 264, 850, 732, 446],
[40.71, 68.38, 79.99, 110, 110, 255, 264, 857, 737, 448],
[40.52, 67.11, 80, 110, 110, 255, 263.99, 859, 745, 447],
[40.22, 68.14, 80, 110, 110, 254.99, 263.99, 856, 740, 447],
[40.06, 68.01, 79.99, 109.99, 110, 254.98, 264, 854, 736, 448],
[40.03, 68.83, 80, 110, 110, 255, 264, 855, 740, 447],
[39.88, 67.64, 79.99, 110, 110, 255, 263.99, 851, 738, 448],
[39.64, 65.95, 80, 110, 110, 255, 263.99, 855, 740, 448],
[39.67, 67.19, 79.99, 110, 110, 254.99, 263.99, 851, 735, 449],
[39.3, 68.54, 79.99, 110, 110, 254.98, 264, 846, 733, 448],
[39.24, 68.58, 79.99, 110, 110, 254.99, 264, 832, 717, 446],
[39.09, 67.31, 80, 110, 110, 255, 264, 841, 731, 448],
[38.91, 68.5, 79.99, 110, 110, 255, 264, 839, 727, 447],
[38.85, 67.27, 79.99, 110, 109.99, 255, 263.99, 842, 725, 448],
[38.64, 65.58, 80, 110, 110, 254.98, 263.99, 855, 738, 450],
[38.79, 65.24, 79.99, 110, 110, 255, 264, 851, 739, 450],
[38.5, 66.33, 79.99, 109.99, 110, 255, 264, 855, 736, 450],
[38.44, 65.99, 79.99, 110, 110, 255, 264, 858, 739, 451],
[38.2, 66.38, 79.99, 110, 110, 255, 263.99, 857, 739, 451],
[38.11, 63.99, 80, 109.99, 110, 255.1, 263.99, 858, 738, 452],
[38.26, 65.49, 80, 110, 110, 255, 263.99, 855, 739, 452],
[37.99, 67.98, 79.99, 110, 110, 255, 263.99, 845, 735, 451],
[37.84, 67.01, 80, 110, 110, 255, 263.99, 849, 736, 452],
[37.84, 65.88, 80, 110, 110, 255, 263.99, 848, 733, 451],
[37.7, 66.71, 80, 110, 110, 255.02, 264, 854, 740, 453],
[37.7, 67.95, 79.99, 110, 110, 254.99, 264, 853, 739, 452],
[37.87, 66.98, 79.99, 110, 110, 255, 264, 855, 740, 452],
[37.58, 66.6, 79.99, 109.99, 110, 255, 264, 849, 739, 452],
[37.43, 67.07, 80, 110, 110, 255.39, 264, 851, 725, 450],
[37.4, 66.42, 79.99, 110, 110, 254.99, 264, 832, 723, 449],
[37.31, 65.87, 79.99, 110, 110, 254.99, 264, 842, 731, 452],
[37.28, 67.04, 80, 110, 110, 255, 264, 849, 735, 452],
[37.31, 66.54, 80, 110, 110, 255, 264, 852, 733, 453],
[37.26, 66.12, 80, 110, 110, 254.99, 264, 840, 732, 451],
[37.26, 66.98, 80, 110, 110, 255, 263.99, 841, 727, 452],
[37.02, 68.97, 79.99, 110, 110, 255, 264, 853, 745, 452],
[37.28, 67.19, 79.99, 110, 110, 254.99, 263.99, 852, 739, 454],
[37.02, 66.25, 79.99, 110, 110, 255, 264, 853, 740, 453],
[37.02, 63.36, 79.99, 109.99, 110, 255, 264, 853, 738, 453],
[36.73, 63.71, 80, 109.99, 110, 255, 264, 855, 744, 454],
[36.91, 65.17, 79.99, 110, 109.99, 255, 263.99, 854, 737, 454],
[36.59, 64.31, 80, 110, 110, 255, 264, 850, 740, 454],
[36.88, 66.12, 79.99, 110, 110, 255, 264, 843, 730, 453],
[36.88, 66.12, 79.99, 110, 110, 254.99, 263.99, 837, 731, 452],
[36.96, 65.77, 79.99, 109.99, 110, 254.99, 264, 839, 729, 453],
[36.88, 65.96, 79.99, 110, 110, 255, 263.99, 850, 740, 454],
[36.79, 66.68, 80, 110, 109.99, 255, 263.99, 852, 734, 454],
[36.82, 65.79, 79.99, 110, 109.99, 255, 264, 850, 739, 454],
[37.02, 66.33, 80, 110, 110, 255, 263.99, 853, 741, 454],
[37.02, 66.05, 79.99, 110, 110, 254.99, 264, 856, 740, 454],
[36.96, 67.99, 80, 109.99, 110, 255, 263.99, 852, 734, 454],
[36.99, 66.13, 80, 110, 110, 254.99, 264, 848, 733, 453],
[37.08, 66.33, 80, 110, 109.99, 255, 264, 853, 742, 455],
[37.08, 66.15, 80, 110, 110, 254.99, 264, 851, 739, 455],
[37.08, 65.16, 80, 109.99, 110, 255, 264, 852, 740, 453],
[37.28, 65.44, 80, 110, 110, 254.99, 263.99, 852, 735, 454],
[36.91, 65.02, 80, 110, 110, 254.99, 264.01, 849, 735, 453],
[36.91, 66, 79.99, 110, 109.99, 255, 263.99, 851, 742, 455],
[36.88, 66.85, 80, 110, 110, 255, 263.99, 842, 736, 453],
[36.96, 64.9, 79.99, 109.99, 110, 254.99, 264, 854, 741, 453],
[37.05, 67.65, 79.99, 109.99, 109.99, 255, 263.99, 857, 740, 454],
[37.08, 66.5, 80, 110, 110, 254.99, 264, 857, 739, 453],
[37.34, 66.39, 79.99, 110, 110, 254.99, 264, 859, 741, 454],
[37.31, 66.28, 80, 109.99, 110, 255, 263.99, 856, 737, 454],
[37.43, 66.55, 79.99, 110, 110, 255, 264, 861, 742, 454],
[37.61, 66.23, 79.99, 110, 110, 254.99, 264, 856, 736, 454],
[37.67, 65.45, 80, 110, 110, 255, 263.99, 849, 735, 452],
[37.72, 65.88, 80, 109.99, 110, 255, 264, 848, 734, 452],
[37.81, 65.2, 80, 110, 110, 255, 264, 855, 738, 453],
[38.08, 64.82, 79.99, 109.99, 110, 254.99, 263.99, 860, 742, 453],
[37.96, 64.46, 79.99, 109.99, 110, 255, 263.99, 858, 741, 453],
[38.11, 64.7, 80, 110, 110, 254.99, 264, 858, 740, 452],
[38.2, 64.63, 79.99, 109.99, 110, 254.99, 264, 858, 738, 452],
[38.38, 66.29, 79.99, 110, 110, 255, 263.99, 860, 744, 452],
[38.35, 66.76, 79.99, 110, 110, 255, 264, 856, 739, 452],
[38.38, 66.33, 80, 110, 110, 255, 264, 862, 741, 451],
[38.55, 65.53, 79.99, 110, 110, 255, 264, 867, 745, 451],
[38.7, 65.68, 80, 109.99, 110, 255, 263.99, 868, 748, 451],
[38.55, 65.22, 79.99, 110, 110, 254.99, 264, 864, 744, 452],
[38.82, 65.08, 79.99, 110, 110, 254.99, 264, 862, 742, 452],
[38.82, 64.79, 80, 109.99, 110, 255, 264, 870, 749, 452],
[38.73, 65.11, 80, 110, 110, 255, 264, 866, 743, 452],
[38.76, 65.47, 80, 110, 110, 255, 264.01, 865, 743, 452],
[38.76, 67.1, 80, 110, 110, 255, 264, 863, 740, 452],
[38.7, 66.89, 80, 109.99, 110, 255, 264, 862, 746, 452],
[38.61, 65.01, 80, 110, 110, 255, 263.99, 862, 740, 452],
[38.52, 66.08, 79.99, 110, 110, 254.99, 263.99, 857, 738, 452],
[38.05, 65.42, 79.99, 110, 110, 254.99, 264, 862, 745, 453],
[38.29, 66.33, 80, 110, 110, 255, 264, 861, 742, 453],
[38.32, 66.92, 79.99, 109.99, 110, 255, 263.99, 858, 738, 453],
[38.35, 66.77, 80, 110, 110, 254.99, 264, 854, 742, 453],
[38.23, 68.76, 79.99, 110, 109.99, 254.99, 264, 856, 740, 453],
[38.2, 68.11, 79.99, 110, 110, 254.99, 263.99, 862, 741, 453],
[38.05, 67.15, 80, 110, 110, 255, 263.99, 857, 736, 454],
[38.11, 66.42, 79.99, 109.99, 110, 255, 263.99, 860, 743, 453],
[38.05, 65.91, 79.99, 110, 110, 254.99, 263.99, 860, 742, 453],
[38.11, 65.6, 79.99, 110, 110, 255, 264, 862, 744, 453],
[38.29, 64.6, 80, 110, 110, 255, 264, 860, 738, 453],
[38.17, 64.93, 80, 109.99, 109.99, 254.99, 264, 860, 737, 453],
[38.32, 65.17, 80, 109.99, 109.99, 254.99, 264, 860, 741, 453],
[38.32, 65.95, 80, 110, 109.99, 254.99, 263.99, 859, 738, 453],
[38.17, 65.99, 79.99, 110, 110, 255, 264, 860, 741, 453],
[38.26, 66.47, 80, 109.99, 110, 254.99, 264, 865, 741, 453],
[38.17, 66.31, 80, 109.99, 110, 254.99, 264, 863, 747, 453],
[38.11, 67.37, 80, 109.99, 110, 255, 263.99, 860, 742, 454],
[38.08, 67.1, 79.99, 110, 110, 255, 263.99, 859, 741, 454],
[38.23, 68.3, 80, 109.99, 110, 254.99, 264, 864, 746, 453],
[37.99, 68.1, 79.99, 110, 110, 255, 264, 871, 745, 453],
[37.96, 68.31, 79.99, 110, 110, 255, 263.99, 858, 738, 454],
[37.9, 66.63, 79.99, 109.99, 110, 255, 264, 865, 746, 454],
[37.93, 65.27, 80, 110, 110, 255, 264, 861, 744, 454],
[37.99, 67.25, 79.99, 110, 110, 254.99, 263.99, 864, 744, 453],
[38.05, 66.34, 80, 109.99, 109.99, 255.01, 262.36, 867, 743, 453],
[38.32, 68.64, 80, 109.99, 110, 255, 264, 863, 749, 454],
[38.11, 67.96, 80, 110, 110, 255, 264, 858, 747, 453],
[38.17, 69, 79.99, 110, 110, 255, 264, 857, 742, 452],
[38.17, 68.5, 79.99, 110, 110, 255, 264, 864, 741, 452],
[38.32, 66.67, 79.99, 109.99, 110, 255, 261.45, 882, 742, 453],
[38.35, 67.85, 80, 110, 110, 255, 263.99, 864, 744, 453],
[38.41, 66.97, 79.99, 110, 110, 254.99, 263.99, 863, 747, 453],
[38.29, 66.34, 80, 110, 110, 254.99, 263.99, 858, 736, 452],
[38.32, 65.89, 79.99, 109.99, 110, 255, 263.99, 850, 729, 452],
[38.08, 66.6, 79.99, 109.99, 110, 255, 264, 861, 740, 453],
[38.2, 67.32, 80, 110, 110, 254.99, 263.99, 858, 739, 453],
[38.08, 66.69, 80, 110, 110, 254.99, 263.99, 856, 738, 453],
[38.05, 66.59, 80, 110, 110, 254.99, 264, 858, 739, 453],
[37.99, 66.43, 80, 110, 110, 255, 263.99, 856, 740, 454],
[38.02, 67.17, 79.99, 110, 110, 255, 264, 856, 734, 453],
[37.93, 67.36, 80, 110, 109.99, 255, 264, 857, 738, 453],
[38.11, 67.39, 80, 110, 110, 254.99, 263.99, 854, 741, 453],
[37.99, 66.62, 79.99, 110, 110, 254.99, 263.99, 855, 736, 453],
[37.99, 66.44, 80, 110, 110, 254.99, 264, 860, 745, 453],
[38.05, 66.82, 80, 110, 109.99, 255, 263.99, 862, 743, 453],
[38.02, 66.88, 80, 110, 110, 255, 263.99, 864, 744, 454],
[38.14, 66.43, 80, 109.99, 110, 254.99, 264, 864, 748, 453],
[38.05, 66.2, 80, 110, 110, 255, 264, 858, 736, 453],
[38.11, 65.6, 79.99, 110, 109.99, 254.99, 263.99, 866, 747, 452],
[38.11, 65.29, 79.99, 109.99, 110, 255, 264, 860, 737, 454],
[38.11, 65.26, 80, 110, 110, 255, 264, 863, 745, 453],
[38.08, 65.41, 79.99, 109.99, 109.99, 254.99, 264, 867, 750, 453],
[38.17, 65.02, 79.99, 110, 110, 254.99, 263.99, 863, 744, 453],
[38.29, 64.69, 80, 109.99, 110, 254.99, 263.99, 862, 745, 454],
[38.29, 66.88, 79.99, 110, 110, 254.99, 264, 855, 742, 453],
[38.44, 67.84, 79.99, 110, 110, 254.99, 264, 860, 740, 453],
[38.7, 67.15, 80, 110, 110, 254.99, 263.99, 852, 739, 452],
[38.5, 66.07, 80, 110, 109.99, 255, 263.99, 848, 732, 451],
[38.58, 66.34, 79.99, 110, 110, 255, 264, 846, 731, 450],
[38.55, 65.17, 79.99, 110, 110, 255, 264, 852, 732, 451],
[38.76, 64.41, 80, 109.99, 110, 254.99, 263.99, 853, 736, 452],
[38.64, 64.16, 80, 109.99, 110, 254.99, 264, 858, 734, 452],
[38.76, 62.96, 80, 110, 110, 254.99, 264, 847, 730, 451],
[38.79, 64.62, 79.99, 110, 110, 255, 263.99, 839, 719, 448],
[38.79, 65.24, 79.99, 109.99, 110, 255, 263.99, 848, 728, 449],
[38.94, 65.36, 80, 110, 110, 255, 263.99, 849, 729, 450],
[39.12, 65.54, 80, 110, 110, 255, 263.98, 856, 732, 451],
[39.21, 65.49, 79.99, 110, 109.99, 254.99, 264, 861, 740, 451],
[39.27, 65.08, 80, 109.99, 110, 254.99, 263.99, 852, 731, 450],
[39.46, 64.87, 80, 109.99, 110, 254.99, 263.99, 857, 737, 449],
[39.73, 65.24, 80, 110, 110, 254.99, 264, 851, 734, 450],
[39.64, 66.45, 80, 109.99, 110, 254.99, 263.99, 858, 736, 450],
[39.79, 65.86, 79.99, 110, 110, 255, 264, 849, 732, 450],
[39.94, 65.52, 80, 110, 110, 255, 264, 841, 726, 448],
[40.4, 65.32, 80, 109.99, 110, 255, 263.99, 839, 730, 447],
[40.77, 65.38, 79.99, 110, 110, 255, 263.99, 849, 737, 449],
[41.36, 65.56, 80.01, 110, 110, 254.99, 263.99, 887, 746, 448],
[41.73, 68.38, 79.99, 110, 110, 255, 264, 859, 746, 448],
[42.01, 68.98, 80, 110, 110, 255, 263.99, 845, 739, 445],
[42.01, 70.89, 79.99, 110, 110, 254.99, 264, 837, 731, 444],
[42.14, 70.61, 80, 110, 110, 254.99, 263.99, 831, 722, 442],
[41.79, 71.42, 80, 110, 110, 254.99, 263.98, 835, 720, 444],
[41.67, 69.99, 80, 110, 110, 254.99, 264, 836, 726, 444],
[41.54, 72.05, 80, 110, 109.99, 255, 264, 846, 735, 447],
[41.79, 70.81, 79.99, 110, 110, 255, 264, 849, 735, 447],
[41.76, 70.05, 80, 109.99, 110, 255, 263.99, 849, 737, 446],
[41.83, 72.39, 79.99, 110, 110, 254.99, 263.95, 845, 731, 445],
[41.86, 72.13, 80, 110, 110, 254.99, 264, 849, 733, 445],
[41.89, 70.72, 79.99, 110, 110, 255, 263.99, 848, 730, 446],
[42.2, 70.62, 79.99, 110, 110, 255, 264, 846, 732, 446],
[42.01, 70.43, 79.99, 109.99, 110, 254.99, 264, 851, 735, 446],
[42.17, 68.4, 79.99, 110, 110, 255, 263.96, 845, 727, 444],
[42.3, 69.52, 80, 110, 110, 255, 263.99, 830, 719, 441],
[42.14, 70.21, 79.99, 110, 109.99, 254.99, 264, 842, 728, 444],
[42.11, 69.25, 80, 110, 110, 255, 264, 843, 727, 444],
[42.23, 69.25, 80, 110, 110, 255, 264, 852, 734, 445],
[42.05, 69.48, 80, 110, 110, 255.01, 264.03, 855, 738, 446],
[42.2, 70.13, 80, 110, 110, 255, 264, 851, 729, 446],
[42.3, 69.82, 80, 110, 110, 254.99, 264, 847, 726, 445],
[42.14, 70.96, 79.99, 109.99, 109.99, 255.01, 264, 846, 724, 444],
[42.08, 69.73, 79.99, 110, 110, 255, 263.99, 846, 725, 444],
[42.17, 69.16, 79.99, 110, 109.99, 255.12, 262.82, 854, 735, 445],
[42.11, 67.06, 79.99, 110, 110, 255, 264, 862, 739, 446],
[42.05, 69.1, 79.99, 110, 109.99, 254.99, 264, 851, 730, 445],
[42.27, 69, 80, 109.99, 110, 255, 264, 849, 733, 444],
[41.98, 70.14, 79.99, 109.99, 110, 255, 264, 855, 739, 446],
[42.08, 69.67, 79.99, 110, 110, 255.43, 260.6, 859, 738, 445],
[42.08, 69.16, 80, 110, 110, 255, 264, 866, 746, 446],
[42.11, 68.36, 79.99, 110, 110, 254.99, 264, 861, 738, 446],
[42.2, 69.58, 79.99, 109.99, 110, 254.99, 264, 855, 738, 446],
[42.08, 69.76, 80, 109.99, 110, 255, 264, 858, 740, 446],
[42.2, 69.6, 79.99, 109.99, 110, 254.99, 264, 861, 744, 446],
[42.33, 69.43, 79.99, 110, 110, 255, 264, 862, 741, 446],
[42.3, 67.84, 80, 110, 110, 254.99, 263.99, 862, 741, 446],
[42.17, 67.84, 80, 110, 109.99, 254.99, 263.99, 858, 738, 446],
[42.46, 69.56, 80, 109.99, 109.99, 255, 263.99, 859, 739, 446],
[42.36, 68.46, 79.99, 110, 109.99, 254.99, 264, 858, 739, 445],
[42.49, 67.32, 80, 110, 109.99, 254.99, 264, 859, 739, 445],
[42.71, 68.16, 80, 110, 109.99, 254.99, 264, 855, 737, 445],
[42.62, 67.1, 80, 110, 110, 255, 263.99, 857, 741, 446],
[42.97, 66.25, 80, 110, 109.99, 255, 263.99, 849, 730, 444],
[42.77, 65.12, 80, 110, 110, 255, 263.99, 851, 732, 444],
[43.03, 64.4, 80, 110, 109.99, 255, 264, 847, 727, 444],
[43, 63.54, 79.99, 109.99, 109.99, 254.99, 263.99, 833, 717, 440],
[42.9, 63.21, 80, 109.99, 110, 255, 263.99, 859, 738, 444],
[43.03, 63.3, 80, 110, 110, 255, 264, 865, 743, 445],
[43.03, 63.88, 79.99, 109.99, 110, 255, 264, 863, 740, 445],
[43.03, 64.39, 80, 109.99, 109.99, 254.99, 264, 849, 730, 443],
[43.13, 64.3, 79.99, 110, 110, 255, 264, 847, 724, 443],
[43.16, 63.43, 80, 110, 110, 254.99, 264, 845, 726, 443],
[43.25, 62.75, 79.99, 110, 109.99, 254.99, 263.99, 852, 731, 444],
[43.35, 64.42, 80, 110, 110, 255, 264, 829, 707, 438],
[43.45, 63.53, 80, 110, 110, 254.99, 264, 830, 717, 439],
[43.54, 64.91, 79.99, 109.99, 110, 255, 263.99, 833, 718, 440],
[43.71, 66.62, 79.99, 109.99, 110, 255, 264, 829, 712, 439],
[43.58, 66.4, 80, 110, 110, 254.99, 263.99, 835, 717, 440],
[43.61, 66.29, 79.99, 110, 110, 254.99, 264, 846, 724, 442],
[43.67, 66.34, 79.99, 110, 110, 254.98, 264, 845, 725, 442],
[43.8, 67.39, 79.99, 110, 110, 255, 264, 843, 722, 442],
[43.84, 67.03, 80, 110, 110, 255, 264, 845, 723, 441],
[43.87, 65.73, 80, 110, 110, 255, 263.99, 844, 729, 442],
[44.06, 65.02, 79.99, 110, 110, 254.98, 264, 846, 726, 442],
[44, 65.65, 79.99, 110, 110, 254.99, 263.99, 846, 723, 442],
[44, 65.42, 80, 109.99, 110, 255, 263.99, 842, 722, 441],
[44.03, 64.98, 79.99, 109.99, 110, 254.99, 264, 843, 719, 441],
[44.03, 65.35, 80, 110, 110, 254.99, 264, 842, 718, 441],
[44.03, 65.42, 79.99, 110, 109.99, 254.97, 264, 844, 723, 442],
[44.03, 65.94, 80, 110, 109.99, 254.99, 263.99, 849, 727, 442],
[44.1, 65.53, 80, 109.99, 110, 254.99, 264, 844, 721, 441],
[44.06, 66.38, 79.99, 110, 109.99, 254.99, 264, 837, 720, 439],
[44.29, 67.37, 80, 110, 110, 254.99, 263.99, 829, 713, 437],
[44.13, 66.85, 79.99, 110, 110, 254.98, 264, 832, 717, 438],
[44.06, 67.34, 80, 110, 110, 255, 263.99, 848, 730, 442],
[44.1, 66.16, 80, 110, 110, 254.99, 263.99, 847, 726, 442],
[44.06, 66.52, 80, 110, 110, 255, 263.99, 832, 716, 438],
[44.26, 66.49, 79.99, 109.99, 110, 254.99, 264, 833, 715, 438],
[44.06, 66.53, 80, 110, 110, 255.01, 263.99, 836, 715, 438],
[44.13, 67.23, 80, 110, 110, 255, 264, 843, 725, 441],
[44.06, 66.49, 79.99, 110, 110, 255, 263.99, 841, 722, 440],
[44.1, 65.77, 79.99, 110, 109.99, 254.99, 264, 845, 721, 441],
[44.23, 65.27, 79.99, 109.99, 110, 255, 263.99, 835, 716, 438],
[44.06, 65.85, 80, 110, 110, 255.36, 264, 847, 714, 439],
[44.39, 67.22, 79.99, 110, 110, 255, 263.99, 840, 716, 441],
[44.1, 66.65, 79.99, 110, 110, 254.99, 264, 853, 732, 442],
[44.06, 68.23, 79.99, 109.99, 109.99, 255, 264, 857, 730, 443],
[44.13, 68.1, 80, 110, 110, 255, 264, 853, 731, 443],
[44.29, 67.84, 79.99, 109.99, 110, 255, 263.99, 841, 721, 440],
[44.1, 67.89, 80, 109.99, 110, 254.99, 264, 833, 714, 438],
[44.03, 67.39, 79.99, 110, 110, 255, 264, 837, 717, 439],
[44, 66.97, 80, 110, 109.99, 254.99, 264, 842, 721, 440],
[44, 66.13, 80, 109.99, 110, 254.99, 264, 853, 730, 442],
[43.93, 66.75, 80, 109.99, 110, 254.99, 263.99, 849, 728, 442],
[44.03, 66.45, 79.99, 110, 109.99, 254.99, 263.99, 853, 733, 442],
[44, 66.35, 80, 110, 110, 255, 264, 845, 722, 441],
[43.93, 66.96, 80, 109.99, 110, 254.99, 264, 843, 724, 440],
[44.06, 68.15, 80, 109.99, 110, 255, 264, 853, 725, 442],
[43.93, 65.94, 79.99, 109.99, 110, 254.99, 263.99, 853, 734, 443],
[43.87, 65.23, 79.99, 109.99, 109.99, 255, 263.99, 860, 739, 444],
[43.9, 64.06, 79.99, 109.99, 109.99, 254.99, 263.99, 864, 740, 444],
[43.93, 63.73, 80, 110, 110, 254.99, 263.99, 861, 737, 444],
[44.06, 62.37, 80, 109.99, 110, 255, 263.99, 860, 737, 444],
[43.8, 62.66, 80, 110, 110, 254.99, 264, 857, 737, 444],
[43.74, 64.31, 79.99, 110, 110, 254.99, 263.99, 860, 731, 444],
[43.74, 64.45, 79.99, 109.99, 110, 254.99, 263.99, 857, 733, 444],
[43.87, 66.26, 80, 110, 109.99, 255, 263.99, 865, 739, 444],
[43.54, 66.91, 79.99, 109.99, 110, 255, 264, 865, 740, 444],
[43.51, 66.22, 80, 110, 110, 255, 264, 862, 740, 445],
[43.42, 65.56, 80, 109.99, 110, 254.99, 264, 864, 735, 445],
[43.74, 64.59, 80, 110, 110, 254.99, 264, 859, 736, 444],
[43.67, 66.72, 80, 110, 110, 254.99, 264, 862, 737, 444],
[43.38, 65.95, 79.99, 110, 110, 255, 264, 864, 739, 444],
[43.42, 65.35, 80, 109.99, 110, 254.99, 263.99, 854, 730, 443],
[43.45, 65.08, 79.99, 110, 110, 254.99, 264, 853, 730, 444],
[43.64, 65.1, 80, 109.99, 110, 254.99, 263.99, 858, 734, 443],
[43.54, 64.75, 80, 109.99, 110, 254.99, 263.99, 859, 737, 444],
[43.54, 65.79, 80, 109.99, 110, 255, 263.99, 861, 735, 444],
[43.74, 65.4, 80, 110, 110, 254.99, 263.99, 858, 735, 444],
[43.67, 66.74, 79.99, 110, 110, 255, 264, 868, 741, 445],
[43.74, 66.72, 80, 110, 110, 254.99, 264, 865, 744, 444],
[43.77, 67.06, 79.99, 109.99, 110, 254.99, 264, 867, 747, 445],
[43.97, 66.49, 80, 110, 110, 254.99, 264, 858, 737, 444],
[43.84, 69.09, 79.99, 110, 109.99, 254.99, 263.99, 858, 731, 443],
[43.8, 67.34, 80, 110, 110, 254.99, 264, 860, 736, 444],
[43.84, 65.45, 79.99, 110, 110, 255, 264, 859, 734, 444],
[43.87, 65.13, 79.99, 110, 110, 255, 263.99, 853, 731, 443],
[43.77, 64.53, 79.99, 110, 110, 255, 263.99, 857, 731, 443],
[43.9, 65.25, 79.99, 110, 109.99, 255, 264, 855, 725, 443],
[43.71, 66.22, 79.99, 110, 110, 254.99, 264, 850, 721, 442],
[43.67, 65.76, 80, 110, 110, 255, 264.01, 846, 718, 441],
[43.58, 66.45, 80, 109.99, 110, 255, 263.99, 855, 724, 443],
[43.35, 66.82, 80, 109.99, 110, 255, 264, 847, 724, 443],
[43.03, 66.33, 80, 109.99, 110, 255, 264, 852, 723, 443],
[43.03, 68.16, 80, 110, 110, 255, 264, 845, 719, 442],
[43, 68.96, 79.99, 110, 109.99, 255, 263.99, 852, 724, 443],
[42.9, 68.33, 80, 109.99, 110, 255, 263.99, 861, 732, 444],
[42.77, 67.44, 79.99, 110, 109.99, 255, 263.99, 860, 736, 444],
[42.74, 66.45, 80, 110, 110, 254.99, 264, 861, 741, 445],
[42.3, 67.68, 79.99, 110, 110, 254.99, 264, 859, 734, 445],
[42.62, 69.72, 79.99, 110, 109.99, 255, 264, 861, 738, 445],
[42.65, 69.79, 80, 109.99, 110, 254.99, 264, 858, 737, 445],
[42.62, 67.82, 80, 109.99, 110, 255, 264.01, 869, 737, 445],
[42.62, 66.85, 79.99, 110, 109.99, 254.99, 263.99, 863, 735, 445],
[42.62, 66.16, 79.99, 110, 110, 254.99, 263.99, 859, 735, 445],
[42.68, 65.69, 79.99, 110, 109.99, 254.99, 264, 863, 733, 444],
[42.81, 66.05, 80, 110, 110, 255, 263.98, 859, 733, 445],
[42.58, 65.12, 79.99, 110, 110, 255, 263.93, 874, 734, 445],
[42.74, 65.12, 79.99, 110, 110, 254.99, 264, 866, 735, 445],
[42.74, 66, 80, 110, 110, 254.99, 263.99, 861, 733, 444],
[42.87, 66.49, 79.99, 110, 110, 255, 263.99, 858, 732, 444],
[42.87, 66.3, 79.99, 110, 110, 255, 263.99, 860, 731, 445],
[42.74, 64.75, 80, 110, 110, 255, 263.68, 880, 740, 445],
[42.74, 64.92, 79.99, 109.99, 110, 254.99, 263.99, 857, 729, 444],
[42.84, 64.66, 80, 109.99, 110, 254.99, 263.99, 853, 729, 444],
[42.77, 65.26, 80, 109.99, 110, 255, 264, 861, 736, 445],
[42.71, 65.46, 79.99, 110, 110, 254.99, 263.99, 858, 725, 445],
[42.77, 63.75, 79.99, 110, 110, 255, 263.68, 861, 732, 445],
[42.71, 65.15, 79.99, 109.99, 110, 255, 264, 854, 728, 444],
[42.77, 65.13, 79.99, 110, 110, 254.99, 264, 852, 724, 444],
[42.58, 66.2, 80, 110, 110, 255, 264, 852, 726, 443],
[42.55, 65.96, 80, 110, 109.99, 255, 264, 859, 730, 444],
[42.49, 66.66, 80, 110, 110, 255, 263.59, 880, 729, 444],
[42.58, 66.28, 80, 110, 110, 255, 263.99, 860, 731, 445],
[42.55, 66.72, 80, 110, 110, 255, 264, 859, 731, 445],
[42.49, 67, 80, 110, 110, 254.99, 264, 859, 738, 444],
[42.55, 67.2, 80, 110, 110, 254.99, 263.99, 851, 728, 443],
[42.46, 67.93, 80, 110, 110, 254.97, 264.13, 847, 723, 442],
[42.46, 67.47, 79.99, 110, 110, 255, 264, 845, 722, 442],
[42.46, 67.24, 79.99, 110, 110, 254.99, 263.99, 841, 719, 442],
[42.52, 66.25, 80, 110, 110, 254.99, 263.99, 842, 718, 442],
[42.33, 69.19, 79.99, 110, 109.99, 254.99, 264, 838, 717, 441],
[42.17, 67.71, 80.01, 110, 110, 255, 264, 838, 719, 441],
[42.11, 69.92, 80, 110, 110, 255, 264, 834, 714, 440],
[41.95, 69.76, 79.99, 110, 110, 255, 263.99, 838, 718, 442],
[42.08, 68.72, 79.99, 110, 110, 254.99, 264, 845, 718, 443],
[42.2, 67.9, 80, 110, 110, 254.99, 264, 853, 729, 444],
[41.95, 67.41, 80, 110, 110, 254.99, 263.99, 853, 731, 444],
[42.05, 67.5, 79.99, 110, 109.99, 254.99, 264, 841, 724, 443],
[42.01, 67.22, 80, 109.99, 110, 255, 263.99, 841, 719, 442],
[41.73, 65.7, 80, 110, 110, 254.99, 263.99, 845, 722, 443],
[41.76, 66.43, 80, 110, 109.99, 255, 264, 852, 731, 444],
[41.64, 66.19, 80, 110, 109.99, 254.99, 263.99, 849, 730, 444],
[41.61, 68.26, 79.99, 110, 110, 255, 263.99, 852, 727, 444],
[41.61, 68.06, 80, 110, 110, 254.99, 264, 848, 725, 444],
[41.39, 67.13, 79.99, 110, 110, 254.99, 264, 845, 724, 444],
[41.42, 67.78, 79.99, 110, 110, 254.99, 264, 838, 718, 442],
[41.33, 66.35, 79.99, 110, 109.99, 255, 264, 844, 721, 443],
[41.26, 65.87, 80, 110, 110, 255, 263.99, 843, 721, 443],
[41.23, 65.07, 80, 110, 110, 255, 263.99, 845, 720, 443],
[41.26, 64.56, 80, 110, 110, 255, 263.98, 862, 731, 445],
[41.17, 64.65, 79.99, 110, 109.99, 254.99, 264, 846, 719, 444],
[41.14, 64.37, 80, 110, 110, 255, 264, 835, 714, 441],
[41.17, 63.75, 80, 110, 110, 255, 263.99, 839, 712, 442],
[41.08, 63.71, 80, 110, 109.99, 255, 264, 837, 716, 443],
[40.98, 64.56, 79.99, 110, 110, 255, 263.99, 852, 725, 445],
[40.98, 65.1, 79.99, 110, 110, 255, 263.99, 856, 729, 446],
[40.92, 66.35, 80, 110, 110, 255, 264, 842, 721, 443],
[40.86, 67.01, 79.99, 109.99, 110, 254.99, 264, 851, 730, 446],
[40.89, 66.55, 80, 110, 110, 255, 264, 857, 732, 446],
[40.95, 67.55, 79.99, 110, 109.99, 255, 264, 852, 726, 446],
[40.86, 67.7, 80, 110, 110, 254.99, 264, 847, 723, 445],
[41.02, 68.47, 79.99, 110, 110, 254.99, 264, 843, 725, 444],
[40.8, 68.82, 79.99, 110, 110, 255, 264, 851, 724, 445],
[40.83, 66.87, 80, 110, 110, 254.99, 263.99, 854, 732, 446],
[40.77, 65.92, 80, 110, 110, 254.99, 263.99, 862, 735, 447],
[40.8, 66.52, 79.99, 110, 110, 255, 263.99, 852, 729, 445],
[40.71, 66.58, 80, 110, 110, 254.99, 263.99, 847, 723, 444],
[40.8, 65.62, 80, 110, 110, 254.99, 264, 847, 721, 444],
[40.74, 65.66, 80, 109.99, 110, 255, 264, 847, 723, 445],
[40.74, 65.66, 79.99, 110, 109.99, 254.99, 263.99, 860, 729, 446],
[40.92, 64.91, 79.99, 110, 110, 255, 263.99, 854, 736, 446],
[40.8, 64.83, 79.99, 110, 110, 255, 264, 855, 730, 446],
[40.71, 64.87, 79.99, 110, 110, 254.99, 264, 843, 721, 444],
[40.77, 65.15, 79.99, 110, 110, 255, 263.99, 849, 725, 445],
[40.83, 64.96, 80, 109.99, 110, 254.99, 264, 858, 733, 446],
[40.61, 66.83, 80, 110, 110, 255, 263.99, 859, 734, 447],
[40.8, 66.2, 80, 110, 110, 254.99, 263.99, 864, 739, 447],
[40.74, 67.67, 79.99, 109.99, 110, 255, 263.99, 864, 737, 447],
[40.95, 67.88, 80, 110, 110, 254.99, 263.98, 847, 712, 443],
[40.8, 67.78, 79.99, 110, 110, 255, 263.99, 850, 727, 445],
[40.74, 67.42, 79.99, 110, 110, 255, 263.99, 855, 731, 446],
[40.71, 66.69, 80, 110, 110, 255, 264, 851, 729, 446],
[40.71, 67.45, 80, 110, 110, 254.99, 264, 848, 723, 445],
[40.86, 66.72, 79.99, 110, 110, 255, 264, 875, 718, 443],
[40.55, 66.75, 80, 110, 109.99, 254.99, 263.99, 845, 722, 445],
[40.77, 66.84, 79.99, 110, 110, 255, 263.99, 857, 730, 447],
[40.55, 64.48, 79.99, 110, 110, 254.99, 264, 857, 733, 447],
[40.52, 62.48, 80, 110, 109.99, 254.99, 264, 860, 734, 447],
[40.46, 62.86, 80, 109.99, 110, 254.99, 263.98, 851, 725, 445],
[40.52, 63.33, 79.99, 110, 110, 255, 263.99, 843, 720, 444],
[40.4, 63.85, 80, 110, 110, 255, 264, 857, 731, 447],
[40.52, 64.96, 80, 110, 110, 255, 264, 868, 739, 447],
[40.52, 66.24, 79.99, 109.99, 110, 254.99, 263.99, 860, 736, 447],
[40.43, 66.38, 79.99, 110, 109.99, 254.99, 263.99, 860, 734, 447],
[40.43, 66.63, 79.99, 110, 110, 255, 263.99, 852, 733, 446],
[40.58, 66.24, 80, 110, 110, 255, 263.99, 850, 727, 446],
[40.49, 65.1, 79.99, 110, 110, 255, 264, 841, 723, 444],
[40.46, 65.62, 80, 109.99, 110, 255, 264, 853, 727, 446],
[40.49, 65.04, 79.99, 110, 110, 255.02, 263.6, 859, 730, 445],
[40.46, 63.72, 79.99, 110, 110, 255, 264, 852, 730, 446],
[40.46, 63.42, 80, 109.99, 109.99, 255, 264, 845, 723, 445],
[40.22, 63.36, 79.99, 110, 110, 255, 263.99, 847, 720, 445],
[40.09, 64.06, 80, 110, 110, 255.13, 262.76, 855, 732, 447],
[40.28, 63.63, 80, 110, 110, 254.99, 264, 854, 727, 447],
[39.94, 63.81, 80, 110, 110, 254.99, 263.99, 848, 722, 446],
[40.03, 63.63, 79.99, 109.99, 110, 255, 264, 848, 730, 446],
[40.03, 64.37, 79.99, 110, 110, 255, 264, 847, 723, 447],
[39.88, 65.37, 79.99, 110, 110, 255.37, 262.7, 845, 722, 445],
[40.03, 64.86, 79.99, 110, 110, 254.99, 263.99, 842, 726, 445],
[40.09, 65.83, 80, 110, 110, 254.99, 263.99, 844, 719, 445],
[40.4, 68.45, 79.99, 110, 110, 254.99, 263.99, 836, 720, 443],
[40.31, 66.89, 79.99, 110, 110, 255, 264, 845, 725, 445],
[40.52, 66.28, 80, 110, 110, 254.99, 263.99, 854, 731, 447],
[40.64, 64.57, 79.99, 110, 110, 254.99, 263.99, 842, 725, 445],
[40.71, 63.51, 80, 110, 110, 254.99, 263.98, 842, 724, 444],
[41.02, 63.04, 79.99, 110, 110, 254.99, 263.99, 833, 720, 443],
[41.2, 63.51, 79.99, 109.99, 110, 255, 263.99, 837, 725, 444],
[41.79, 64.29, 80, 110, 110, 245.52, 257.97, 852, 736, 445],
[42.36, 64.88, 79.99, 109.99, 110, 255, 263.99, 855, 738, 445],
[42.77, 67.27, 79.99, 110, 110, 255, 263.99, 846, 732, 443],
[43.32, 68, 80, 109.99, 110, 255, 264, 838, 724, 440],
[43.25, 70.09, 79.99, 110, 110, 254.99, 264, 844, 730, 442],
[43.42, 69.19, 79.99, 110, 110, 255, 263.99, 845, 730, 442],
[43.25, 69.35, 80, 109.99, 110, 254.99, 264, 853, 737, 443],
[43.16, 72.04, 79.99, 110, 110, 255, 264, 853, 736, 444],
[43, 71.83, 80, 110, 110, 254.99, 264, 850, 736, 444],
[43.06, 71.02, 80, 110, 110, 254.99, 263.99, 841, 724, 443],
[43.32, 71.22, 79.99, 110, 110, 254.99, 264, 841, 726, 442],
[43.29, 70.38, 79.99, 109.99, 109.99, 255, 263.99, 838, 723, 441],
[43.38, 70.07, 80, 110, 110, 254.99, 263.99, 848, 731, 443],
[43.42, 69.8, 80, 110, 110, 254.99, 264, 849, 730, 443],
[43.48, 70.43, 80, 110, 110, 255, 264, 843, 726, 442],
[43.74, 71.88, 79.99, 110, 110, 255, 264, 840, 724, 442],
[43.54, 71.03, 79.99, 110, 110, 255, 264, 843, 723, 442],
[43.64, 70.29, 79.99, 110, 110, 254.99, 263.99, 849, 726, 443],
[43.58, 69.14, 80, 109.99, 109.99, 255, 264, 849, 727, 442],
[43.64, 69.81, 79.99, 110, 110, 255, 264, 844, 721, 442],
[43.48, 68.4, 79.99, 110, 110, 254.99, 264, 828, 710, 438],
[43.48, 67.72, 79.99, 110, 110, 254.99, 264, 829, 710, 438],
[43.51, 66.69, 79.99, 110, 110, 255, 263.99, 828, 710, 438],
[43.61, 65.51, 80, 110, 110, 255.01, 264, 833, 712, 439],
[43.71, 64.9, 79.99, 110, 109.99, 254.99, 264, 837, 717, 441],
[43.48, 64.05, 79.99, 110, 110, 254.99, 264, 843, 721, 441],
[43.51, 63.27, 80, 110, 110, 254.99, 264, 835, 713, 440],
[43.54, 63.8, 79.99, 110, 110, 254.99, 263.99, 832, 714, 439],
[43.61, 64.27, 79.99, 110, 110, 254.99, 263.99, 836, 716, 441],
[43.51, 64.78, 80, 110, 110, 255, 264, 839, 716, 440],
[43.64, 64.83, 80, 110, 110, 255, 264, 828, 707, 438],
[43.54, 64.04, 79.99, 110, 110, 254.98, 263.99, 845, 723, 442],
[43.67, 63.21, 80, 110, 110, 255, 264, 843, 722, 442],
[43.67, 62.89, 80, 110, 109.99, 254.99, 264, 839, 713, 440],
[43.77, 63.3, 79.99, 110, 110, 254.99, 263.99, 841, 714, 441],
[43.97, 63.1, 80, 110, 110, 255, 263.99, 844, 719, 442],
[43.8, 63.63, 79.99, 110, 109.99, 254.98, 263.99, 855, 722, 443],
[43.77, 63.37, 80, 110, 109.99, 255, 263.99, 856, 731, 444],
[44, 64.4, 80, 109.99, 110, 254.99, 263.99, 860, 732, 444],
[44.32, 64.92, 80, 110, 109.99, 254.99, 264, 856, 732, 444],
[44.26, 67.26, 79.99, 110, 110, 254.98, 264, 844, 723, 441],
[44.23, 66.69, 80, 110, 110, 255, 264, 843, 718, 441],
[44.26, 66.37, 80, 109.99, 110, 255, 263.99, 852, 724, 442],
[44.46, 65.53, 80, 109.99, 109.99, 255, 263.99, 849, 722, 442],
[44.39, 63.86, 79.99, 110, 110, 254.97, 264, 831, 710, 438],
[44.59, 63.15, 80, 110, 110, 254.99, 263.99, 828, 707, 437],
[44.55, 62.97, 80, 110, 110, 254.99, 264, 838, 712, 440],
[44.59, 61.56, 79.99, 109.99, 110, 255, 264, 833, 711, 439],
[44.52, 62.07, 79.99, 110, 110, 255, 263.99, 843, 718, 441],
[44.42, 62.89, 79.99, 109.99, 109.99, 254.97, 264, 853, 726, 443],
[44.59, 63.76, 79.99, 110, 109.99, 255, 264, 841, 716, 440],
[44.85, 64.03, 80, 110, 110, 254.99, 263.99, 844, 719, 440],
[44.98, 63.97, 79.99, 110, 110, 255, 263.99, 839, 715, 440],
[44.98, 63.51, 80, 110, 110, 255, 264, 839, 712, 440],
[44.72, 63.35, 79.99, 110, 110, 255.24, 264, 844, 716, 440],
[44.78, 62.18, 80, 110, 110, 254.99, 264, 847, 719, 441],
[44.92, 61.79, 80, 110, 110, 254.99, 264, 840, 717, 440],
[44.85, 61.56, 79.99, 110, 110, 255, 264, 833, 710, 439],
[44.88, 61.18, 80, 110, 109.99, 254.99, 264, 846, 722, 441],
[44.92, 62.92, 80, 110, 110, 255.45, 263.99, 865, 719, 441],
[45.02, 63.44, 80, 110, 110, 255, 264, 843, 716, 441],
[45.12, 64.01, 79.99, 109.99, 110, 254.99, 263.99, 844, 720, 441],
[45.15, 63.95, 80, 110, 110, 255, 263.99, 835, 708, 438],
[45.22, 64.61, 80, 110, 110, 254.99, 264, 837, 713, 439],
[45.15, 65.26, 80, 110, 109.99, 254.98, 263.97, 837, 714, 440],
[45.15, 66.01, 79.99, 110, 110, 254.99, 263.99, 841, 715, 440],
[45.18, 66.01, 80, 109.99, 109.99, 254.99, 264, 852, 726, 442],
[45.25, 66.21, 79.99, 110, 110, 254.99, 263.99, 846, 724, 441],
[45.28, 66.5, 79.99, 109.99, 110, 255, 263.99, 843, 719, 441],
[45.38, 64.08, 80, 110, 110, 255, 263.99, 846, 725, 441],
[45.31, 65.31, 79.99, 110, 109.99, 254.99, 263.99, 858, 731, 443],
[45.48, 65.48, 80, 110, 110, 255, 264, 854, 723, 442],
[45.58, 64.82, 80, 109.99, 110, 254.99, 263.99, 850, 724, 442],
[45.45, 65.66, 79.99, 109.99, 110, 254.99, 264, 850, 722, 442],
[45.45, 65.4, 80, 110, 110, 254.99, 263.99, 855, 728, 442],
[45.52, 65.41, 79.99, 110, 110, 255, 263.99, 857, 731, 443],
[45.55, 64.96, 80, 109.99, 110, 255, 263.99, 856, 731, 443],
[45.55, 64.81, 80, 110, 110, 254.99, 264.01, 861, 730, 443],
[45.62, 65.98, 79.99, 110, 110, 254.99, 263.99, 856, 727, 443],
[45.58, 66.33, 80, 110, 110, 254.99, 264, 853, 724, 442],
[45.68, 66.09, 79.99, 110, 110, 255, 264, 845, 719, 441],
[45.62, 65.44, 79.99, 110, 110, 255, 263.99, 841, 718, 439],
[45.68, 64.96, 80, 110, 110, 254.99, 263.99, 843, 718, 441],
[45.68, 63.4, 80, 110, 110, 255, 264, 837, 714, 438],
[45.82, 64.51, 80, 110, 110, 255, 264, 840, 714, 440],
[45.65, 66.07, 80, 110, 109.99, 254.99, 264, 840, 713, 439],
[45.92, 66.43, 79.99, 109.99, 110, 254.99, 263.99, 839, 713, 439],
[45.68, 67.3, 79.99, 109.99, 109.99, 254.99, 264, 832, 710, 437],
[45.68, 66.38, 80, 110, 110, 255, 264, 834, 706, 437],
[45.65, 66.8, 80, 109.99, 110, 254.99, 264, 834, 711, 438],
[45.78, 66.75, 79.99, 109.99, 110, 255, 263.99, 837, 710, 438],
[45.62, 66.76, 80, 110, 110, 254.99, 263.99, 844, 717, 440],
[45.58, 66.9, 80, 110, 110, 254.99, 263.99, 840, 717, 439],
[45.52, 65.31, 80, 110, 110, 254.99, 263.99, 843, 719, 440],
[45.58, 63.65, 80, 109.99, 110, 255, 264, 848, 723, 440],
[45.55, 63.94, 79.99, 110, 109.99, 254.99, 263.99, 846, 721, 440],
[45.58, 63.7, 80, 110, 110, 254.99, 264, 850, 720, 441],
[45.52, 62.56, 80, 110, 110, 254.99, 263.99, 853, 724, 442],
[45.48, 63.41, 79.99, 110, 110, 255, 263.98, 857, 728, 442],
[45.38, 63.81, 79.99, 110, 110, 254.99, 264, 857, 725, 442],
[45.55, 63.91, 79.99, 110, 110, 254.99, 263.99, 853, 725, 442],
[45.41, 64.36, 79.99, 110, 110, 255, 263.99, 847, 722, 441],
[45.45, 64.01, 80, 110, 110, 254.99, 264, 841, 716, 439],
[45.41, 63.79, 80, 110, 110, 254.99, 263.99, 843, 717, 440],
[45.35, 63.97, 79.99, 110, 110, 254.99, 263.99, 839, 713, 439],
[45.22, 62.49, 80, 109.99, 110, 254.99, 263.99, 851, 723, 441],
[45.35, 63.36, 79.99, 110, 110, 254.99, 264, 851, 723, 441],
[45.22, 63.6, 79.99, 110, 110, 255, 264, 853, 725, 442],
[45.25, 64.72, 80, 110, 109.99, 255, 264, 839, 713, 438],
[45.28, 64.69, 79.99, 109.99, 110, 254.99, 264, 850, 718, 441],
[45.45, 64.78, 79.99, 110, 110, 255, 264, 857, 726, 442],
[45.22, 66.33, 80, 109.99, 110, 254.99, 263.99, 856, 731, 443],
[45.35, 66.89, 80, 109.99, 110, 255, 263.99, 858, 727, 442],
[45.12, 67.7, 79.99, 110, 110, 254.99, 264, 849, 723, 441],
[45.02, 66.95, 79.99, 110, 110, 255, 264, 840, 714, 438],
[44.92, 65.01, 80, 110, 110, 254.99, 263.99, 843, 720, 440],
[44.85, 65.62, 79.99, 109.99, 109.99, 254.99, 263.99, 855, 728, 442],
[44.85, 65.58, 79.99, 110, 110, 255, 263.99, 856, 730, 443],
[44.65, 65.25, 79.99, 110, 110, 255, 263.99, 853, 723, 441],
[44.55, 64.85, 80, 110, 110, 255, 263.99, 835, 712, 437],
[44.46, 64.76, 80, 110, 110, 254.99, 264, 847, 722, 441],
[44.55, 65.52, 79.99, 110, 110, 254.99, 263.99, 856, 730, 443],
[44.23, 65.83, 80, 110, 110, 255, 264, 854, 721, 441],
[44.1, 66.07, 80, 110, 109.99, 254.99, 263.99, 856, 732, 442],
[44.1, 66.59, 80, 110, 109.99, 254.99, 264, 858, 730, 443],
[43.84, 66.81, 79.99, 110, 110, 255, 263.99, 861, 730, 443],
[44.06, 64.86, 79.99, 110, 110, 255, 264, 858, 733, 443],
[43.87, 66.88, 79.99, 110, 109.99, 254.99, 263.99, 857, 730, 442],
[43.64, 66.51, 79.99, 109.99, 109.99, 255, 264, 854, 731, 443],
[43.58, 66.52, 80, 110, 109.99, 255.01, 264, 857, 731, 443],
[43.64, 66.9, 80, 110, 109.99, 255, 263.99, 857, 731, 443],
[43.51, 67.99, 79.99, 110, 110, 254.99, 263.99, 855, 730, 443],
[43.48, 67.08, 80, 110, 110, 255, 263.93, 857, 731, 443],
[43.42, 67.08, 80, 109.99, 110, 255, 263.99, 853, 730, 442],
[43.29, 67.96, 79.99, 110, 110, 255, 263.99, 853, 729, 442],
[43.25, 66.58, 80, 110, 110, 254.99, 264, 856, 734, 443],
[43.13, 68.13, 79.99, 109.99, 110, 255, 263.99, 855, 728, 443],
[43.03, 67.06, 80, 110, 110, 254.99, 263.99, 851, 724, 442],
[43.03, 66.26, 80, 110, 109.99, 254.99, 264, 859, 731, 444],
[43, 66.55, 80, 110, 110, 255, 263.99, 854, 732, 443],
[42.97, 65.1, 79.99, 110, 110, 255, 264.05, 863, 736, 444],
[42.74, 66.39, 79.99, 110, 109.99, 255, 263.99, 855, 732, 444],
[42.93, 66.08, 79.99, 110, 109.99, 255, 263.99, 854, 730, 443],
[42.87, 65.2, 80, 110, 109.99, 254.99, 264, 859, 732, 444],
[42.81, 66.25, 79.99, 110, 110, 255, 263.99, 854, 731, 444],
[42.62, 66.95, 80, 110, 110, 255, 263.22, 859, 732, 444],
[42.58, 67.24, 79.99, 109.99, 110, 255, 264, 857, 733, 444],
[42.27, 68.3, 80, 109.99, 109.99, 255, 264.68, 877, 738, 445],
[42.36, 66.96, 80, 110, 110, 254.99, 264, 859, 739, 443],
[42.33, 68.08, 79.99, 109.99, 110, 255, 263.99, 861, 736, 444],
[42.27, 67.44, 80, 110, 110, 254.99, 264, 859, 732, 444],
[42.23, 67.03, 80, 109.99, 110, 254.99, 264, 861, 736, 444],
[42.17, 67.98, 79.99, 110, 110, 255, 264, 860, 735, 444],
[42.23, 66.39, 80, 110, 110, 255, 263.99, 859, 734, 444],
[42.08, 66.02, 79.99, 110, 110, 255, 263.99, 858, 734, 444],
[42.05, 65.78, 79.99, 109.99, 110, 254.99, 264, 860, 738, 444],
[42.08, 65.33, 80, 110, 110, 255, 263.99, 861, 738, 444],
[42.01, 64.87, 80, 110, 110, 255, 264, 857, 736, 444],
[42.05, 66.2, 79.99, 109.99, 110, 255, 264, 856, 733, 444],
[41.73, 64.21, 79.99, 109.99, 109.99, 254.99, 264, 863, 734, 445],
[42.01, 63.69, 79.99, 109.99, 109.99, 254.99, 264, 859, 733, 444],
[42.01, 63.95, 80, 110, 110, 255, 264, 855, 730, 444],
[41.83, 64.43, 80, 110, 110, 255, 263.99, 859, 733, 444],
[41.67, 65.03, 79.99, 110, 110, 255, 263.99, 860, 736, 445],
[41.79, 64.9, 80, 110, 110, 254.99, 264, 856, 730, 444],
[41.95, 65.64, 79.99, 110, 110, 255, 263.99, 859, 735, 445],
[41.7, 66.61, 79.99, 109.99, 110, 255, 264, 854, 732, 444],
[41.61, 65.86, 79.99, 110, 109.99, 255, 263.99, 851, 730, 443],
[41.61, 65.53, 80, 109.99, 109.99, 254.99, 264, 855, 732, 444],
[41.45, 65.95, 80, 110, 109.99, 254.99, 263.99, 851, 731, 444],
[41.51, 65.48, 80, 110, 110, 254.99, 264, 861, 734, 445],
[41.67, 66.7, 80, 110, 110, 255, 264, 856, 731, 444],
[41.58, 65.65, 79.99, 109.99, 109.99, 254.99, 264, 854, 729, 444],
[41.48, 65.81, 80, 110, 110, 255, 263.99, 836, 715, 440],
[41.39, 65.69, 80, 110, 110, 255, 263.99, 841, 717, 441],
[41.64, 66.66, 79.99, 110, 110, 255, 263.99, 845, 724, 442],
[41.61, 67.44, 79.99, 110, 110, 255, 264, 854, 729, 444],
[41.61, 65.9, 79.99, 110, 110, 255, 264, 856, 732, 444],
[41.67, 66.35, 80, 110, 110, 255, 264, 846, 726, 442],
[41.54, 66.04, 79.99, 110, 110, 255, 264, 841, 717, 441],
[41.54, 65.38, 80, 109.99, 110, 254.99, 263.99, 841, 722, 441],
[41.45, 65.22, 79.99, 110, 110, 254.99, 263.99, 845, 723, 442],
[41.51, 65.03, 80, 110, 110, 255, 263.99, 861, 733, 444],
[41.45, 66.24, 80, 110, 109.99, 254.99, 264, 860, 738, 444],
[41.54, 63.5, 80, 109.99, 110, 255, 263.99, 861, 736, 445],
[41.79, 63.58, 79.99, 110, 110, 254.99, 263.99, 862, 731, 445],
[41.51, 62.15, 79.99, 110, 110, 254.99, 263.99, 865, 736, 444],
[41.51, 62.72, 80, 110, 110, 254.99, 264, 856, 730, 443],
[41.61, 65.07, 80, 109.99, 110, 255, 264, 844, 724, 441],
[41.83, 66.18, 79.99, 110, 110, 255, 263.99, 859, 733, 445],
[41.64, 66.36, 80, 110, 110, 254.99, 263.99, 859, 734, 444],
[41.73, 65.16, 79.99, 109.99, 110, 255, 264, 856, 731, 444],
[41.73, 65.94, 80, 110, 110, 255, 263.99, 862, 732, 444],
[41.86, 64.53, 80, 110, 110, 254.99, 264, 858, 732, 444],
[42.01, 64.8, 79.99, 110, 109.99, 254.99, 263.99, 855, 731, 443],
[42.14, 65.08, 80, 110, 110, 255, 264, 850, 725, 442],
[42.2, 64.4, 79.99, 110, 109.99, 255, 263.99, 848, 724, 442],
[42.36, 64.43, 80, 110, 110, 254.99, 263.99, 855, 726, 442],
[42.52, 63.49, 79.99, 110, 110, 254.99, 264, 847, 720, 441],
[42.33, 62.84, 80, 109.99, 109.99, 255, 263.99, 850, 722, 442],
[42.49, 62.82, 79.99, 110, 110, 255, 263.99, 857, 728, 444],
[42.27, 63.53, 80, 110, 110, 255, 264, 860, 731, 444],
[42.49, 63.51, 79.99, 110, 109.99, 254.99, 263.99, 902, 731, 444],
[42.23, 63.93, 80, 110, 110, 254.99, 264, 855, 729, 443],
[42.2, 63.62, 80, 110, 110, 254.99, 264, 854, 729, 443],
[42.23, 64.43, 79.99, 109.99, 109.99, 255, 264, 844, 720, 441],
[42.2, 65.59, 80, 109.99, 110, 255, 263.99, 859, 733, 444],
[42.23, 66.07, 80, 110, 110, 255, 263.99, 912, 728, 444],
[42.27, 66.66, 80, 110, 110, 254.99, 264, 864, 737, 444],
[42.39, 66.55, 79.99, 110, 110, 254.99, 263.99, 863, 735, 444],
[42.36, 66.13, 79.99, 110, 110, 254.99, 264, 865, 738, 445],
[42.27, 65.93, 79.99, 110, 110, 255, 263.99, 848, 723, 442],
[42.2, 65.61, 79.99, 110, 109.99, 255, 263.91, 843, 719, 441],
[42.39, 64.37, 80, 110, 110, 255, 263.99, 852, 728, 443],
[42.3, 64.23, 79.99, 109.99, 109.99, 255, 264, 862, 734, 444],
[42.3, 64.86, 79.99, 110, 110, 255, 264, 860, 732, 444],
[42.33, 65.34, 80, 110, 110, 254.99, 264, 855, 729, 444],
[42.42, 64.59, 79.99, 110, 110, 255.07, 263.65, 852, 727, 443],
[42.39, 63.88, 80, 110, 110, 255, 264, 859, 732, 444],
[42.52, 63.48, 80, 110, 110, 255, 264, 851, 729, 443],
[42.49, 62.72, 79.99, 110, 110, 254.99, 263.99, 866, 738, 444],
[42.77, 62.62, 80, 109.99, 109.99, 255, 264, 856, 733, 443],
[42.58, 64.54, 79.99, 109.99, 110, 255, 263.96, 862, 734, 445],
[42.68, 63.57, 79.99, 110, 110, 255, 264, 864, 732, 444],
[42.68, 62.47, 80, 110, 110, 254.99, 264, 861, 732, 444],
[42.9, 62.44, 80, 109.99, 110, 254.99, 264, 858, 732, 444],
[42.93, 61.68, 80, 110, 110, 254.99, 264, 844, 719, 440],
[42.87, 63.49, 80, 110, 110, 255.23, 264.25, 837, 712, 439],
[43.03, 63.93, 80, 109.99, 110, 255, 264, 851, 725, 442],
[43, 66.57, 79.99, 110, 110, 254.99, 263.99, 861, 735, 444],
[43.03, 67.47, 79.99, 109.99, 110, 255, 264, 861, 733, 444],
[43.13, 68.24, 80, 110, 110, 254.99, 264, 855, 729, 443],
[43.16, 68.51, 79.99, 110, 110, 255.21, 262.44, 851, 729, 442],
[43.03, 67.53, 80, 110, 110, 255, 263.99, 849, 723, 441],
[43.22, 67.67, 80, 110, 110, 254.99, 264, 863, 737, 443],
[43.35, 66.97, 80, 110, 110, 255, 264, 864, 739, 444],
[43.22, 67.5, 79.99, 110, 110, 255, 264, 861, 735, 444],
[43.16, 66.58, 80, 109.99, 110, 254.99, 264, 859, 737, 443],
[43.16, 68.01, 79.99, 110, 110, 255, 263.99, 864, 742, 444],
[43.03, 67.3, 79.99, 109.99, 110, 255, 263.99, 867, 740, 445],
[43.09, 66.06, 79.99, 110, 110, 255, 263.99, 865, 739, 444],
[43.09, 65.51, 80, 110, 109.99, 254.99, 264, 858, 730, 444],
[43.03, 64.23, 79.99, 110, 110, 255, 263.99, 861, 732, 443],
[43, 64.07, 80, 110, 110, 254.99, 264, 857, 729, 444],
[43.03, 63.66, 80, 110, 110, 255, 264, 862, 734, 444],
[43.03, 62.2, 79.99, 110, 110, 254.99, 264, 860, 733, 444],
[43.03, 62.83, 80, 109.99, 110, 254.99, 263.99, 854, 729, 443],
[42.9, 63.37, 80, 110, 110, 254.99, 264, 845, 715, 441],
[42.93, 64.86, 79.99, 110, 110, 254.99, 264, 854, 725, 443],
[43, 64.59, 79.99, 110, 110, 254.99, 263.99, 856, 730, 443],
[43.03, 65.11, 80, 110, 110, 254.99, 263.99, 855, 731, 443],
[43.03, 64.15, 80, 110, 109.99, 255, 263.99, 856, 726, 442],
[43.13, 63.84, 80, 109.99, 110, 254.99, 263.99, 851, 724, 441],
[43.19, 64.2, 79.99, 110, 110, 255, 264, 843, 722, 441],
[43.16, 64.52, 79.99, 110, 110, 255, 264, 844, 723, 440],
[43.22, 64.82, 79.99, 110, 110, 254.99, 264, 841, 719, 441],
[43.25, 65.05, 80.01, 110, 110, 254.99, 264, 840, 719, 440],
[43.45, 67.15, 79.99, 110, 110, 255, 264, 845, 720, 441],
[43.29, 67.67, 79.99, 110, 110, 255, 264, 852, 727, 442],
[43.29, 67.41, 79.99, 110, 110, 254.99, 264, 847, 726, 441],
[43.42, 65.82, 79.99, 109.99, 110, 255, 264, 844, 720, 440],
[43.9, 65.12, 79.99, 109.99, 109.99, 255, 263.99, 839, 713, 440],
[44.06, 63.92, 79.99, 110, 110, 255, 264, 837, 717, 440],
[44.52, 63.41, 80, 110, 110, 254.99, 264, 845, 722, 441],
[45.12, 62.96, 80, 110, 110, 254.99, 264, 841, 721, 440],
[45.58, 63.45, 80, 110, 110, 255, 263.99, 834, 715, 438],
[45.75, 62.71, 79.99, 110, 110, 255, 263.99, 834, 716, 437],
[45.88, 62.26, 80, 109.99, 110, 254.99, 263.99, 843, 723, 440],
[45.75, 61.85, 79.99, 110, 109.99, 255, 264, 842, 719, 439],
[45.78, 62.09, 80, 109.99, 110, 255, 264, 837, 711, 437],
[45.75, 62.34, 80, 110, 110, 255, 263.99, 836, 714, 438],
[45.78, 62.03, 79.99, 109.99, 110, 255, 264, 833, 712, 438],
[45.99, 62.24, 80, 110, 110, 254.99, 263.99, 832, 711, 437],
[46.05, 64.16, 79.99, 110, 110, 254.99, 264, 830, 707, 437],
[46.29, 65.51, 79.99, 110, 109.99, 254.99, 263.99, 829, 707, 436],
[46.26, 65.5, 80, 110, 110, 254.99, 264, 830, 708, 437],
[46.19, 66.71, 80, 110, 110, 255, 263.99, 844, 716, 440],
[46.46, 67.57, 79.99, 109.99, 110, 254.99, 264, 844, 720, 440],
[46.36, 67.11, 80, 110, 110, 254.99, 264, 853, 727, 441],
[46.49, 67.66, 80, 110, 110, 255, 263.99, 850, 730, 441],
[46.56, 68.29, 80, 109.99, 109.99, 254.99, 264, 851, 729, 441],
[46.66, 69.56, 79.99, 109.99, 110, 254.99, 264, 842, 715, 439],
[46.53, 68.89, 80, 110, 110, 255, 263.99, 855, 726, 442],
[46.53, 69.54, 79.99, 110, 109.99, 254.98, 263.99, 849, 723, 441],
[46.63, 68.32, 80, 110, 110, 255, 264, 832, 708, 436],
[46.6, 66.57, 79.99, 110, 110, 255, 264, 852, 724, 442],
[46.53, 65.81, 80, 110, 109.99, 255, 263.99, 841, 716, 439],
[46.6, 65.39, 79.99, 110, 109.99, 255, 264, 850, 723, 441],
[46.63, 64.88, 79.99, 110, 110, 254.97, 264, 849, 721, 441],
[46.53, 64.87, 80, 110, 110, 255, 263.99, 840, 713, 439],
[46.7, 63.63, 80, 109.99, 110, 255, 264, 834, 712, 438],
[46.8, 62.59, 79.99, 110, 110, 254.97, 263.99, 842, 712, 439],
[46.9, 62.69, 79.99, 110, 110, 255, 263.99, 838, 709, 438],
[46.97, 61.12, 79.99, 110, 110, 255, 264, 844, 716, 439],
[47.11, 60.62, 80, 109.99, 110, 255, 264, 852, 724, 442],
[47.18, 61.2, 79.99, 110, 110, 255, 263.99, 852, 722, 441],
[46.94, 62.34, 79.99, 110, 110, 255, 264, 862, 733, 443],
[46.97, 62.64, 80, 109.99, 110, 255.05, 264, 867, 732, 444],
[47.18, 62.63, 80, 110, 110, 254.99, 263.99, 860, 731, 443],
[47.14, 62.54, 79.99, 110, 109.99, 255, 264, 857, 727, 442],
[47.25, 63.13, 79.99, 110, 110, 254.99, 263.99, 851, 724, 441],
[47.42, 64.67, 79.99, 110, 110, 255, 264, 849, 717, 440],
[47.14, 65.47, 79.99, 110, 110, 255.07, 263.99, 848, 716, 441],
[47.28, 64.94, 80, 110, 110, 255, 263.99, 852, 720, 442],
[47.14, 65.24, 79.99, 110, 110, 254.99, 264, 858, 726, 443],
[47.14, 63.11, 79.99, 110, 110, 255, 263.99, 861, 730, 443],
[47.52, 63.04, 80, 110, 110, 254.99, 264, 839, 710, 438],
[47.38, 62.89, 79.99, 110, 110, 255.36, 263.99, 847, 714, 438],
[47.35, 63.96, 79.99, 110, 109.99, 254.99, 263.99, 844, 713, 440],
[47.56, 64.28, 79.99, 110, 110, 255, 264, 851, 717, 441],
[47.56, 64.95, 80, 109.99, 110, 255, 264, 849, 720, 440],
[47.66, 64.86, 79.99, 110, 110, 255, 264, 849, 715, 440],
[47.77, 64.88, 79.99, 110, 110, 255, 264, 842, 711, 439],
[47.63, 65.61, 80, 110, 110, 255, 264, 847, 719, 440],
[47.73, 64.99, 79.99, 110, 110, 255, 264, 846, 719, 440],
[47.77, 64.52, 79.99, 110, 109.99, 255, 264, 849, 717, 440],
[47.73, 64.05, 79.99, 110, 110, 255, 264, 844, 716, 439],
[47.84, 63.77, 79.99, 110, 110, 254.99, 264, 833, 709, 437],
[47.73, 63.37, 79.99, 110, 110, 255, 263.99, 834, 707, 437],
[47.63, 62.84, 79.99, 110, 110, 255, 263.99, 832, 710, 437],
[47.52, 63.47, 80, 109.99, 110, 254.99, 264, 841, 714, 439],
[47.35, 64.34, 79.99, 110, 110, 255, 263.99, 841, 713, 440],
[47.18, 64.64, 80, 109.99, 110, 255, 263.99, 849, 723, 441],
[47.42, 63.82, 79.99, 110, 110, 254.99, 263.99, 852, 725, 443],
[47.11, 63.59, 79.99, 110, 110, 254.99, 264, 846, 716, 441],
[47.18, 63.57, 80, 109.99, 109.99, 255, 263.99, 837, 714, 439],
[47.14, 64.95, 79.99, 110, 110, 254.99, 264, 860, 729, 444],
[47.18, 64.42, 80, 110, 109.99, 254.99, 264, 854, 724, 443],
[47.07, 65.32, 79.99, 110, 110, 255, 263.99, 854, 729, 443],
[47.11, 64.75, 80, 110, 110, 254.99, 263.99, 845, 721, 441],
[47.21, 65.46, 79.99, 110, 110, 255, 264, 840, 717, 440],
[47.07, 63.8, 80, 110, 110, 254.99, 264, 862, 731, 444],
[47.01, 63.22, 79.99, 109.99, 110, 255, 263.99, 850, 721, 443],
[46.94, 63.14, 79.99, 109.99, 110, 254.99, 263.99, 849, 722, 442],
[47.25, 62.56, 79.99, 110, 110, 254.99, 263.99, 849, 721, 442],
[47.21, 63.24, 79.99, 110, 110, 254.99, 263.99, 854, 724, 442],
[47.14, 63.03, 80, 110, 110, 254.99, 264, 854, 725, 443],
[47.18, 63.52, 79.99, 110, 110, 255, 263.99, 855, 725, 443],
[47.49, 63.73, 79.99, 110, 110, 254.99, 264, 836, 710, 437],
[47.18, 62.71, 80, 110, 109.99, 255, 264, 830, 703, 436],
[47.42, 63.53, 80, 110, 110, 254.99, 264, 837, 705, 437],
[47.49, 63.9, 80, 110, 110, 254.99, 264, 852, 719, 442],
[47.73, 64.01, 79.99, 109.99, 110, 254.99, 264, 845, 715, 441],
[47.77, 63.74, 79.99, 110, 110, 254.99, 264, 856, 723, 443],
[47.84, 64.43, 80, 109.99, 110, 255, 264, 853, 720, 442],
[47.8, 64.86, 80, 109.99, 110, 254.99, 263.99, 855, 724, 442],
[47.87, 66.7, 80, 110, 109.99, 255, 263.99, 851, 718, 441],
[48.33, 66.89, 79.99, 110, 110, 255, 264, 860, 728, 444],
[48.01, 66.57, 79.99, 110, 110, 254.99, 263.98, 866, 731, 444],
[48.08, 65.93, 79.99, 110, 110, 254.99, 264, 848, 720, 441],
[48.15, 64.86, 80, 110, 110, 254.99, 264, 841, 709, 439],
[48.22, 65.78, 79.99, 110, 110, 255, 263.99, 845, 713, 440],
[48.26, 66.98, 79.99, 110, 110, 254.99, 264, 849, 715, 440],
[48.08, 67.23, 80, 109.99, 110, 255, 264, 856, 724, 443],
[48.12, 66.66, 79.99, 110, 110, 255, 264, 849, 716, 441],
[48.12, 66.65, 80, 110, 110, 255, 263.99, 859, 723, 443],
[48.08, 66.03, 79.99, 110, 110, 254.99, 264, 859, 728, 443],
[48.08, 65.12, 80, 110, 110, 255, 264, 857, 724, 443],
[48.19, 65.32, 79.99, 110, 110, 254.99, 263.99, 856, 724, 443],
[48.12, 64.5, 80, 109.99, 110, 254.99, 263.99, 851, 721, 442],
[48.08, 64.98, 79.99, 110, 110, 254.99, 263.99, 853, 718, 442],
[48.01, 63.81, 80, 110, 110, 255, 264, 862, 724, 444],
[48.08, 63.71, 79.99, 110, 110, 255, 264, 859, 723, 443],
[47.91, 62.38, 79.99, 110, 109.99, 254.99, 264, 848, 719, 441],
[48.01, 62.1, 79.99, 110, 110, 255, 264, 849, 721, 442],
[47.42, 60.06, 80, 110, 110, 254.99, 263.99, 861, 727, 444],
[47.07, 60.35, 80, 110, 110, 255, 264, 858, 724, 443],
[47.32, 60.97, 80, 110, 110, 255, 263.99, 856, 725, 443],
[46.87, 61.97, 80, 110, 109.99, 254.99, 264.01, 861, 723, 443],
[46.97, 60.55, 80, 110, 110, 255, 263.99, 867, 733, 445],
[46.73, 59.97, 80, 110, 109.99, 255, 264, 865, 729, 444],
[46.73, 60.63, 79.99, 110, 109.99, 255, 264, 861, 725, 444],
[46.66, 60.99, 80, 110, 110, 255, 263.99, 857, 721, 443],
[46.46, 61.87, 79.99, 110, 110, 255, 263.88, 875, 724, 443],
[46.46, 63.54, 79.99, 109.99, 109.99, 255, 263.99, 859, 724, 443],
[46.36, 63.36, 79.99, 110, 110, 255.01, 264, 861, 726, 442],
[46.32, 65.76, 80, 109.99, 110, 255, 263.75, 867, 723, 442],
[46.36, 66.05, 80, 110, 110, 255, 264, 850, 723, 441],
[46.39, 64.69, 79.99, 110, 110, 255, 263.71, 861, 723, 441],
[46.19, 63.86, 80, 110, 110, 255, 263.99, 852, 720, 441],
[46.05, 63.85, 80, 109.99, 110, 255, 264, 852, 724, 441],
[46.15, 63.62, 79.99, 110, 109.99, 255, 264, 854, 721, 441],
[45.99, 64.42, 80, 110, 109.99, 255, 264, 856, 727, 442],
[45.92, 66.11, 80, 110, 110, 255, 264.07, 854, 722, 441],
[45.95, 66.27, 79.99, 110, 109.99, 255, 263.99, 855, 721, 442],
[45.82, 66.96, 79.99, 110, 110, 255, 264, 849, 718, 439],
[45.62, 66.2, 80, 110, 110, 255, 264, 849, 723, 441],
[45.75, 66.28, 79.99, 110, 110, 254.99, 264, 858, 728, 442],
[45.72, 63.78, 79.99, 110, 110, 255, 263.99, 862, 726, 443],
[45.78, 62.94, 80, 110, 110, 254.99, 264, 864, 733, 443],
[45.82, 65.31, 79.99, 109.99, 110, 255, 263.99, 877, 740, 444],
[45.82, 65.22, 79.99, 110, 109.99, 254.99, 263.99, 858, 728, 443],
[45.75, 64.62, 79.99, 109.99, 110, 254.99, 264, 853, 725, 441],
[45.72, 64.33, 80, 110, 110, 255, 263.99, 858, 730, 442],
[45.72, 65.39, 80, 110, 110, 255, 264, 859, 729, 443],
[45.68, 66.12, 80, 110, 110, 254.99, 264, 862, 733, 443],
[45.78, 66.47, 80, 110, 110, 254.99, 264, 857, 731, 443],
[45.78, 65.92, 80, 110, 109.99, 255, 263.99, 853, 729, 441],
[45.82, 67.13, 79.99, 109.99, 110, 255, 264, 854, 726, 441],
[45.45, 67.65, 79.99, 110, 109.99, 255, 263.99, 852, 726, 441],
[45.45, 66.31, 80, 110, 110, 255, 264, 854, 728, 441],
[45.75, 67.05, 79.99, 109.99, 110, 255, 264, 849, 724, 440],
[45.62, 64.65, 79.99, 110, 110, 254.99, 263.99, 857, 724, 442],
[45.62, 64.46, 80, 110, 110, 254.99, 263.99, 854, 724, 441],
[45.82, 64.79, 80, 110, 110, 255, 264, 851, 721, 440],
[45.68, 64.88, 80, 110, 110, 254.99, 264, 854, 724, 441],
[45.72, 64.62, 79.99, 110, 109.99, 255, 264, 858, 730, 442],
[45.48, 64.07, 80, 110, 110, 255, 264, 853, 724, 441],
[45.58, 64.03, 80, 110, 110, 255, 263.99, 849, 721, 440],
[45.52, 64.79, 79.99, 110, 110, 255, 263.99, 855, 727, 441],
[45.65, 64.65, 80, 110, 109.99, 255, 263.99, 847, 720, 440],
[45.75, 64.49, 80, 110, 109.99, 254.99, 264, 859, 731, 442],
[45.75, 64.99, 80, 110, 110, 255, 263.99, 858, 725, 442],
[45.99, 63.88, 80, 110, 110, 254.99, 263.99, 856, 726, 442],
[45.82, 63.68, 79.99, 110, 109.99, 255, 264, 853, 724, 441],
[46.02, 63.52, 80, 109.99, 110, 254.99, 263.99, 847, 717, 439],
[46.02, 64.1, 79.99, 110, 110, 254.99, 263.98, 859, 727, 442],
[46.05, 64, 80, 110, 109.99, 254.99, 264, 867, 734, 443],
[46.02, 63.82, 80, 110, 110, 254.99, 263.98, 861, 726, 442],
[46.19, 63.35, 79.99, 110, 110, 254.99, 264, 859, 727, 442],
[45.95, 64.46, 79.99, 110, 109.99, 255, 264, 859, 724, 441],
[46.02, 64.46, 80, 110, 109.99, 254.99, 263.99, 862, 724, 443],
[46.09, 64.25, 80, 110, 110, 255, 263.99, 859, 731, 442],
[46.09, 65.22, 79.99, 110, 110, 255, 264, 869, 730, 443],
[46.12, 65.32, 79.99, 110, 110, 254.99, 264, 869, 736, 443],
[46.15, 64.72, 80, 110, 110, 255, 263.99, 867, 731, 443],
[45.99, 65.33, 80, 110, 110, 254.99, 263.99, 864, 728, 442],
[46.05, 66.03, 79.99, 109.99, 110, 254.99, 263.99, 863, 728, 442],
[46.22, 65.05, 80, 109.99, 110, 255, 264, 866, 732, 443],
[46.19, 64.19, 80, 110, 110, 254.99, 264, 869, 731, 443],
[46.15, 65.74, 80, 110, 109.99, 254.99, 264, 860, 727, 441],
[46.19, 65.19, 79.99, 110, 110, 255, 264, 861, 725, 442],
[46.29, 65.1, 80, 110, 110, 254.99, 264, 865, 731, 443],
[46.29, 66.06, 79.99, 110, 110, 254.99, 263.99, 862, 727, 442],
[46.26, 66.51, 80, 110, 110, 254.99, 263.99, 861, 734, 442],
[46.32, 66.01, 79.99, 110, 110, 255, 264, 857, 728, 442],
[46.19, 66.09, 80, 109.99, 110, 255, 264, 861, 732, 442],
[46.29, 67.1, 79.99, 110, 110, 254.99, 263.99, 863, 731, 442],
[46.26, 66.93, 79.99, 109.99, 110, 254.98, 263.99, 860, 732, 442],
[46.29, 67.32, 80, 110, 110, 254.99, 264, 863, 734, 442],
[46.29, 67.69, 79.99, 110, 110, 255, 263.99, 851, 718, 439],
[46.26, 67.94, 80, 110, 110, 255, 264, 848, 719, 439],
[46.36, 67.05, 79.99, 110, 110, 255, 263.99, 848, 722, 439],
[46.32, 65.65, 79.99, 110, 110, 255, 263.99, 864, 731, 442],
[46.19, 64.22, 80, 110, 110, 255, 264, 858, 723, 441],
[46.39, 62.49, 80, 109.99, 110, 254.99, 263.99, 860, 724, 441],
[46.09, 61.61, 79.99, 110, 110, 254.99, 264, 869, 732, 442],
[46.15, 61.52, 79.99, 110, 110, 254.99, 264, 860, 724, 441],
[46.12, 60.76, 80, 109.99, 109.99, 255, 263.99, 863, 725, 442],
[46.05, 61.17, 80, 110, 109.99, 255, 263.99, 861, 724, 441],
[46.19, 61.11, 80, 110, 110, 255, 264, 868, 731, 442],
[46.09, 61.31, 80, 110, 109.99, 254.99, 263.98, 874, 731, 443],
[46.05, 61.83, 80, 110, 109.99, 254.99, 263.99, 866, 730, 442],
[46.19, 60.62, 79.99, 110, 110, 254.99, 264, 866, 728, 443],
[46.09, 61.14, 79.99, 110, 110, 254.99, 264, 864, 729, 442],
[45.99, 60.44, 80, 110, 110, 254.99, 264, 864, 724, 442],
[45.92, 60.27, 80, 110, 110, 254.99, 263.99, 904, 730, 443],
[45.99, 60.53, 80, 110, 110, 255, 263.99, 865, 727, 442],
[45.92, 60.71, 80, 110, 110, 254.99, 263.99, 865, 730, 443],
[45.92, 60.6, 80, 110, 110, 255, 263.99, 861, 726, 442],
[46.12, 61.86, 79.99, 110, 109.99, 254.99, 264, 865, 726, 442],
[45.82, 61.91, 80, 110, 109.99, 254.99, 263.98, 865, 729, 443],
[45.85, 63.7, 79.99, 110, 110, 254.99, 264, 875, 733, 444],
[45.72, 62.74, 79.99, 109.99, 110, 255, 263.99, 864, 724, 442],
[45.68, 62.39, 80, 110, 110, 255, 264, 861, 727, 441],
[45.68, 62.58, 80, 110, 110, 254.99, 263.99, 866, 729, 442],
[45.62, 62.8, 80, 110, 110, 254.99, 264, 864, 725, 442],
[45.62, 63.68, 80, 110, 110, 255, 263.99, 868, 734, 443],
[45.52, 63.43, 80, 110, 110, 254.99, 264, 867, 731, 443],
[45.62, 62.69, 80, 110, 109.99, 255, 263.99, 860, 724, 441],
[45.52, 70.6, 79.99, 110, 110, 255, 264, 862, 730, 443],
[45.65, 68.94, 79.99, 109.99, 110, 255.29, 261.9, 861, 727, 442],
[45.45, 68.29, 79.99, 110, 110, 255, 263.99, 863, 731, 442],
[45.52, 65.76, 79.99, 110, 110, 255, 264, 865, 731, 443],
[45.58, 64.25, 80, 110, 110, 255, 263.99, 864, 733, 443],
[45.65, 62.75, 79.99, 110, 110, 254.99, 263.99, 864, 728, 442],
[45.48, 62.35, 79.99, 109.99, 109.99, 255.5, 262.4, 864, 728, 443],
[45.58, 62.37, 79.99, 110, 110, 254.99, 263.99, 870, 736, 443],
[45.58, 62.11, 80, 110, 110, 254.99, 264, 861, 732, 443],
[45.45, 62.08, 79.99, 110, 109.99, 255, 264, 866, 733, 443],
[45.48, 61.9, 80, 110, 109.99, 255, 263.99, 867, 732, 443],
[45.58, 63.06, 79.99, 110, 110, 254.99, 264, 861, 726, 441],
[45.48, 63.92, 80, 109.99, 110, 254.99, 263.99, 857, 725, 441],
[45.65, 66.55, 80, 110, 109.99, 255, 264, 858, 728, 442],
[45.68, 65.5, 80, 110, 109.99, 254.99, 263.99, 858, 730, 442],
[45.75, 67.76, 79.99, 110, 109.99, 254.99, 263.99, 860, 729, 442],
[45.92, 68.33, 79.99, 110, 110, 255, 264, 851, 723, 441],
[45.82, 66.7, 79.99, 109.99, 110, 254.99, 264, 843, 714, 438],
[46.02, 65.88, 80, 109.99, 110, 254.99, 264, 834, 708, 435],
[46.05, 64.92, 79.99, 110, 110, 254.99, 264, 841, 717, 438],
[46.12, 64.79, 79.99, 109.99, 109.99, 255, 264, 849, 723, 440],
[46.09, 64.7, 79.99, 110, 110, 255, 264, 842, 715, 439],
[46.43, 64.03, 80, 110, 110, 254.99, 264, 834, 703, 436],
[46.43, 63.04, 79.99, 110, 110, 255, 263.99, 842, 718, 440],
[46.36, 62.31, 79.99, 110, 110, 255, 264, 849, 724, 441],
[47.52, 63.27, 80, 110, 110, 255, 263.99, 838, 720, 439],
[47.91, 63.2, 79.99, 110, 110, 254.99, 264, 837, 712, 438],
[47.7, 62.62, 80, 110, 110, 255, 264, 843, 722, 441],
[47.59, 62.61, 80, 110, 109.99, 254.99, 263.99, 849, 726, 443],
[47.56, 63.04, 80, 110, 109.99, 255, 263.99, 854, 730, 443],
[47.21, 62.36, 80, 109.99, 110, 255, 264, 859, 730, 444],
[47.07, 62.58, 80, 110, 110, 255, 263.99, 853, 728, 443],
[47.11, 62.07, 80, 110, 110, 255, 264, 846, 719, 441],
[47.21, 62.84, 79.99, 110, 110, 254.99, 264, 846, 721, 442],
[47.07, 62.69, 79.99, 110, 109.99, 255, 264, 847, 726, 444],
[47.07, 62.02, 79.99, 110, 110, 254.99, 264, 851, 725, 443],
[47.01, 61.45, 80, 110, 110, 255, 263.99, 848, 723, 443],
[46.9, 61.61, 79.99, 109.99, 110, 255, 264, 845, 721, 443],
[46.94, 61.15, 79.99, 109.99, 109.99, 254.99, 264, 849, 724, 443],
[46.97, 61.38, 80, 110, 110, 255, 263.99, 844, 722, 442],
[46.97, 61.97, 79.99, 110, 110, 255, 264, 850, 724, 443],
[47.01, 62.96, 79.99, 110, 110, 255, 263.99, 838, 714, 440],
[46.97, 63.14, 80, 110, 109.99, 254.99, 264, 841, 716, 441],
[47.04, 64.2, 80, 110, 109.99, 255, 264, 842, 716, 440],
[47.04, 63.38, 79.99, 110, 110, 255, 264, 839, 714, 441],
[47.07, 63.6, 79.99, 110, 110, 254.99, 264, 845, 719, 442],
[47.07, 63.99, 79.99, 110, 110, 254.99, 263.99, 832, 712, 438],
[47.11, 64.66, 80, 110, 110, 254.99, 263.99, 831, 705, 437],
[47.21, 64.26, 79.99, 110, 110, 254.99, 264, 838, 713, 439],
[47.18, 64.04, 79.99, 110, 110, 255, 264, 844, 716, 441],
[47.32, 64.05, 80, 110, 110, 254.99, 264, 842, 715, 440],
[47.25, 65.05, 80, 110, 110, 255, 263.99, 826, 704, 436],
[47.11, 65.06, 79.99, 110, 110, 254.99, 264, 830, 702, 438],
[47.49, 64.84, 79.99, 110, 110, 255, 263.99, 848, 722, 442],
[47.56, 66.11, 79.99, 110, 110, 255, 263.99, 848, 720, 442],
[47.63, 66.12, 80, 110, 110, 255, 263.99, 850, 723, 443],
[47.77, 66.2, 79.99, 109.99, 110, 255, 264, 841, 719, 440],
[48.05, 64.78, 79.99, 109.99, 110, 255, 263.98, 843, 717, 441],
[47.98, 63.87, 79.99, 110, 110, 255, 264, 844, 718, 442],
[48.01, 63.6, 80, 109.99, 109.99, 254.99, 263.99, 848, 720, 442],
[47.98, 64.03, 80, 110, 109.99, 254.98, 263.99, 852, 723, 443],
[47.98, 64.88, 79.99, 110, 110, 254.99, 263.99, 857, 729, 444],
[48.01, 63.77, 80, 110, 110, 255, 263.99, 853, 725, 444],
[48.15, 64.65, 80, 110, 110, 254.99, 264, 857, 726, 444],
[48.26, 64.58, 80, 110, 110, 254.99, 264, 857, 725, 444],
[48.15, 64.74, 79.99, 109.99, 109.99, 254.98, 264, 854, 723, 444],
[48.29, 64.67, 80, 110, 110, 255, 263.99, 859, 727, 445],
[48.47, 65.4, 79.99, 109.99, 110, 254.99, 263.99, 858, 724, 445],
[48.54, 64.94, 79.99, 110, 110, 254.99, 263.99, 855, 723, 444],
[48.64, 65.11, 79.99, 109.99, 110, 254.99, 264, 857, 728, 445],
[48.43, 64.27, 79.99, 110, 110, 254.96, 264, 848, 718, 443],
[48.47, 63.27, 79.99, 109.99, 110, 255, 263.99, 848, 719, 443],
[48.5, 62.98, 80, 110, 110, 255, 264, 850, 722, 444],
[48.5, 62.68, 79.99, 109.99, 110, 255, 264, 848, 718, 442],
[48.47, 63.33, 80, 109.99, 110, 255, 263.99, 848, 719, 443],
[48.47, 62.96, 79.99, 110, 110, 255.01, 264, 845, 719, 442],
[48.64, 62.63, 80, 109.99, 110, 255, 264, 836, 710, 438],
[48.64, 62.09, 79.99, 109.99, 110, 254.99, 264, 846, 719, 441],
[48.61, 62.31, 79.99, 110, 110, 255, 263.99, 855, 725, 444],
[48.26, 62.92, 79.99, 109.21, 108.87, 255, 263.99, 851, 719, 443],
[48.15, 62.25, 80, 104.87, 103.7, 255, 263.99, 847, 717, 443],
[47.94, 62.27, 79.99, 101.18, 99.44, 255, 264, 843, 711, 441],
[47.87, 62.51, 80, 98.03, 95.93, 254.99, 264, 845, 716, 442],
[47.8, 61.65, 80, 95.37, 93.08, 255, 263.99, 847, 716, 443],
[47.77, 62.12, 79.99, 93.07, 90.7, 255, 263.99, 851, 719, 443],
[47.63, 62.56, 79.99, 91.11, 88.74, 255, 263.99, 849, 721, 443],
[47.56, 63.25, 79.99, 89.43, 87.12, 254.99, 264, 839, 712, 440],
[47.59, 63.84, 80, 88.01, 85.79, 255, 264, 829, 704, 438],
[47.42, 63.39, 79.99, 86.77, 84.68, 254.99, 263.99, 842, 717, 442],
[47.45, 63.59, 80, 85.71, 83.77, 254.99, 263.99, 852, 722, 444],
[47.42, 64.76, 79.99, 84.82, 83.01, 255, 264, 847, 722, 444],
[47.35, 64.15, 79.99, 84.04, 82.38, 254.99, 264, 845, 719, 443],
[47.32, 64.53, 79.99, 83.37, 81.86, 255, 263.98, 838, 709, 440],
[47.38, 64.71, 79.99, 82.8, 81.44, 255, 264, 836, 709, 440],
[47.32, 64.54, 80, 82.31, 81.09, 254.99, 264, 848, 716, 443],
[47.28, 63.16, 80, 81.9, 80.79, 255, 264, 852, 720, 444],
[47.21, 63.84, 80, 81.54, 80.56, 254.99, 264, 847, 717, 443],
[47.21, 63.48, 79.99, 80.98, 80.2, 254.99, 263.99, 844, 713, 442],
[47.14, 63.24, 80, 80.76, 80.06, 255, 263.99, 840, 718, 442],
[47.04, 63.27, 79.99, 80.57, 79.95, 254.99, 263.99, 848, 721, 444],
[46.97, 63.43, 80, 80.41, 79.86, 255, 264, 845, 715, 443],
[47.01, 63.45, 79.99, 80.27, 79.79, 254.99, 164.04, 847, 722, 443],
[47.04, 63.73, 79.99, 80.16, 79.72, 255, 264, 851, 721, 444],
[46.87, 63.32, 79.99, 80.05, 79.67, 115.86, 100.39, 845, 721, 443],
[46.7, 62.44, 79.99, 80.67, 79.78, 255, 264, 840, 713, 442],
[47.11, 63.14, 80, 96.81, 93.64, 255, 263.99, 849, 720, 443],
[46.94, 63.94, 79.99, 110.31, 106.25, 255, 263.99, 851, 722, 444],
[47.73, 64.21, 80, 110, 109.88, 254.99, 264, 848, 715, 443],
[47.66, 65.23, 79.99, 110, 110.02, 255, 264, 847, 717, 443],
[48.57, 64.75, 79.99, 110, 109.99, 254.99, 264, 844, 713, 442],
[48.47, 64.4, 79.99, 110, 110, 254.99, 263.99, 846, 714, 442],
[48.57, 64.35, 80, 110, 110, 255, 264, 840, 712, 440],
[48.5, 64.23, 79.99, 110, 109.99, 255, 263.96, 860, 716, 442],
[48.64, 63.51, 79.99, 110, 110, 254.99, 263.99, 844, 715, 441],
[48.57, 63.83, 80, 110, 110, 255, 264, 850, 719, 443],
[48.68, 64.83, 80, 109.99, 110, 254.99, 264, 853, 720, 443],
[48.68, 64.65, 80, 109.99, 109.99, 255, 263.99, 854, 721, 443],
[48.79, 65.33, 79.99, 110, 110, 254.99, 263.98, 870, 725, 444],
[48.86, 64.75, 80, 109.99, 110, 255, 264, 852, 722, 442],
[48.72, 65.09, 80, 110, 109.99, 254.99, 263.95, 857, 722, 443],
[48.72, 64.59, 80, 109.99, 109.99, 255, 264, 844, 717, 441],
[48.47, 64.87, 79.99, 110, 110, 254.99, 263.99, 848, 721, 441],
[48.47, 64.79, 80, 110, 110, 255, 264, 845, 715, 441],
[48.4, 64.88, 79.99, 109.99, 110, 254.99, 265.3, 847, 712, 440],
[48.29, 64.54, 80, 110, 110, 254.99, 264, 833, 709, 437],
[48.12, 66.24, 79.99, 110, 110, 254.99, 264, 845, 714, 440],
[47.94, 66.12, 79.99, 110, 109.99, 254.99, 264, 851, 719, 442],
[47.87, 64.92, 79.99, 110, 109.99, 254.99, 264, 857, 725, 443],
[47.8, 64.26, 80, 109.99, 110, 254.99, 263.99, 852, 720, 442],
[47.52, 63.24, 80, 109.99, 110, 255, 264, 854, 721, 442],
[47.35, 61.78, 79.99, 110, 110, 254.99, 264, 842, 715, 440],
[47.07, 62.18, 80, 109.99, 109.99, 255, 263.99, 860, 729, 443],
[47.07, 62.67, 79.99, 110, 110, 255, 264, 860, 728, 443],
[46.8, 61.76, 80, 109.99, 109.99, 254.99, 263.99, 857, 725, 444],
[46.7, 60.72, 80, 110, 110, 254.99, 263.99, 861, 729, 444],
[46.8, 60.48, 79.99, 110, 109.99, 255, 264, 863, 724, 444],
[46.73, 59.73, 79.99, 110, 110, 255, 263.99, 863, 730, 444],
[46.36, 60.38, 80, 110, 110, 255, 263.99, 860, 722, 443],
[46.22, 61.18, 79.99, 110, 109.99, 255, 264, 861, 724, 442],
[46.22, 61.13, 80, 110, 110, 255, 263.99, 847, 719, 440],
[46.19, 61.62, 79.99, 110, 110, 255, 264, 842, 711, 439],
[45.92, 61.18, 80, 110, 110, 254.99, 263.99, 850, 718, 440],
[45.35, 64.19, 80, 110, 110, 254.99, 264, 861, 731, 443],
[45.52, 64.76, 80, 110, 110, 254.99, 264, 860, 729, 442],
[45.12, 65.47, 79.99, 109.99, 110, 254.99, 264, 856, 728, 442],
[45.08, 66.45, 80, 110, 110, 255, 264, 856, 727, 441],
[45.02, 68.05, 79.99, 110, 110, 255, 263.99, 851, 723, 440],
[45.05, 68.25, 80, 110, 110, 254.99, 264, 859, 724, 442],
[44.82, 66.44, 80, 110, 110, 254.99, 264, 860, 730, 443],
[44.65, 65.5, 80, 110, 110, 254.99, 263.99, 865, 735, 443],
[44.78, 65.15, 79.99, 110, 110, 255, 263.99, 865, 728, 443],
[44.52, 64.75, 79.99, 110, 110, 254.99, 263.99, 858, 727, 442],
[44.36, 64.52, 80, 109.99, 109.99, 254.99, 264, 855, 727, 441],
[44.26, 65.08, 80, 110, 110, 254.99, 264, 853, 721, 441],
[44.23, 64.27, 79.99, 110, 110, 255, 264, 853, 730, 442],
[44.13, 66, 80, 110, 110, 255, 264, 858, 731, 442],
[44.1, 67.05, 80, 110, 110, 254.99, 263.99, 857, 724, 442],
[44.06, 65.92, 80, 110, 110, 254.99, 263.99, 857, 726, 442],
[43.93, 65.25, 80, 110, 110, 255, 263.99, 865, 735, 443],
[44.16, 65.47, 80, 110, 109.99, 255, 264, 864, 733, 443],
[43.97, 64.75, 80, 110, 109.99, 254.99, 263.99, 861, 734, 443],
[43.93, 65.22, 79.99, 110, 110, 254.99, 263.99, 864, 739, 443],
[44, 64.59, 80, 110, 110, 255, 263.99, 862, 733, 442],
[44, 64.04, 79.99, 109.99, 110, 255, 263.99, 867, 735, 443],
[44.1, 64.07, 80, 109.99, 109.99, 255, 264, 865, 731, 443],
[43.8, 62.44, 80, 109.99, 110, 255, 264, 862, 730, 442],
[43.77, 62.28, 80, 110, 110, 254.99, 263.99, 862, 732, 442],
[43.77, 63.31, 80, 110, 110, 255, 264, 858, 731, 442],
[43.74, 64.83, 80, 109.99, 110, 254.99, 264, 856, 730, 442],
[43.64, 66.66, 79.99, 110, 109.99, 254.99, 264, 856, 725, 442],
[43.67, 67.63, 80, 109.99, 109.99, 255, 264, 856, 727, 441],
[43.64, 67, 80, 110, 110, 254.99, 263.99, 867, 737, 443],
[43.58, 66.55, 79.99, 110, 109.99, 254.99, 263.99, 858, 733, 442],
[43.61, 64.9, 80, 110, 110, 255, 264, 860, 732, 442],
[43.64, 63.88, 79.99, 110, 110, 255, 263.99, 856, 730, 442],
[43.48, 64.48, 80, 110, 109.99, 255, 263.99, 857, 728, 442],
[43.32, 65.23, 79.99, 110, 110, 254.99, 263.99, 882, 730, 443],
[43.32, 65.58, 79.99, 110, 110, 255, 263.99, 855, 726, 442],
[43.42, 64.28, 79.99, 110, 110, 255, 263.99, 860, 735, 443],
[43.16, 64.74, 80, 109.99, 110, 255, 263.99, 854, 729, 441],
[43.03, 65.67, 80, 110, 109.99, 254.98, 263.98, 847, 724, 441],
[43.03, 65.41, 79.99, 110, 110, 255, 263.99, 844, 723, 438],
[42.97, 68.09, 79.99, 110, 110, 255, 263.99, 848, 724, 441],
[42.84, 67.64, 79.99, 110, 110, 255, 264, 849, 723, 441],
[42.84, 66.86, 80, 110, 110, 254.99, 264, 859, 735, 443],
[42.81, 66.85, 79.99, 110, 110, 254.99, 263.98, 861, 735, 443],
[42.84, 65.38, 79.99, 110, 110, 255, 263.99, 859, 734, 443],
[42.87, 65.59, 79.99, 110, 110, 255, 264, 858, 732, 442],
[42.87, 65.27, 79.99, 110, 110, 255, 264, 853, 731, 442],
[42.74, 64.41, 80, 110, 110, 254.99, 263.99, 861, 734, 443],
[42.77, 63.76, 79.99, 110, 110, 255, 264, 862, 732, 443],
[42.77, 64.57, 80, 109.99, 110, 255, 264, 862, 735, 442],
[42.9, 66.18, 79.99, 109.99, 110, 254.99, 263.98, 856, 730, 442],
[42.81, 65.4, 79.99, 110, 110, 255, 263.98, 859, 728, 443],
[42.81, 63.59, 80, 110, 110, 255, 263.99, 860, 734, 443],
[42.71, 63.77, 79.99, 109.99, 109.99, 255, 264, 859, 735, 443],
[43.03, 63.06, 79.99, 110, 110, 255, 264, 861, 732, 443],
[42.9, 63.83, 79.99, 109.99, 110, 255, 264, 859, 733, 443],
[42.74, 64.06, 79.99, 110, 110, 255.03, 263.99, 861, 732, 443],
[42.71, 63.66, 79.99, 110, 109.99, 255, 264, 860, 735, 443],
[42.84, 64.48, 80, 110, 110, 254.99, 263.99, 862, 734, 443],
[42.77, 64.25, 79.99, 110, 110, 255, 263.99, 856, 730, 442],
[42.97, 64.99, 80, 109.99, 110, 254.99, 264, 858, 728, 443],
[42.77, 64.82, 79.99, 110, 110, 255.03, 264.02, 859, 734, 443],
[42.74, 66.01, 80, 110, 110, 255, 263.99, 858, 730, 442],
[43.03, 65.11, 80, 110, 110, 254.99, 263.99, 860, 735, 442],
[42.68, 66.42, 79.99, 109.99, 110, 254.99, 263.99, 857, 731, 442],
[42.68, 65.41, 80, 110, 110, 255.17, 264.17, 857, 733, 443],
[42.71, 65.82, 79.99, 109.99, 110, 254.99, 264, 857, 735, 443],
[42.46, 64.72, 79.99, 110, 110, 254.99, 264, 859, 731, 442],
[42.55, 64.54, 79.99, 109.99, 110, 254.99, 264, 863, 740, 443],
[42.52, 65, 80, 109.99, 110, 254.99, 263.99, 857, 731, 442],
[42.71, 64.77, 79.99, 110, 109.99, 255.41, 264.54, 854, 732, 442],
[42.52, 64.72, 79.99, 110, 110, 254.99, 263.99, 859, 732, 442],
[42.49, 66.08, 79.99, 109.99, 110, 255, 264, 859, 737, 443],
[42.58, 65.62, 80, 110, 110, 255, 264, 858, 732, 443],
[42.55, 66.4, 79.99, 110, 110, 255, 264, 860, 739, 443],
[42.52, 66.12, 79.99, 109.99, 110, 255, 264, 860, 734, 442],
[42.55, 67.41, 80, 109.99, 109.99, 255, 264, 861, 732, 443],
[42.55, 67.31, 80, 110, 110, 255, 263.99, 857, 725, 442],
[42.49, 67.59, 80, 110, 110, 254.99, 263.99, 859, 731, 442],
[42.52, 66, 79.99, 110, 110, 254.99, 264, 862, 733, 443],
[42.39, 67.01, 80, 110, 110, 254.99, 264, 861, 734, 443],
[42.3, 66.07, 80, 110, 110, 254.99, 263.99, 862, 739, 443],
[42.33, 67.02, 80, 110, 110, 254.99, 263.99, 857, 731, 442],
[42.27, 66.49, 79.99, 110, 110, 255, 263.99, 856, 731, 443],
[42.27, 65.36, 80, 110, 110, 255, 263.99, 862, 734, 444],
[42.2, 66.28, 79.99, 109.99, 110, 255, 264, 863, 735, 442],
[42.01, 66.53, 79.99, 110, 110, 255, 264, 860, 733, 443],
[41.95, 65.86, 79.99, 110, 110, 254.99, 263.99, 861, 734, 444],
[41.89, 66.15, 79.99, 110, 110, 254.99, 263.99, 857, 732, 443],
[41.76, 65.07, 79.99, 110, 110, 254.99, 264, 862, 734, 443],
[42.01, 64.15, 80, 109.99, 109.99, 254.99, 263.99, 864, 739, 444],
[41.67, 62.04, 80, 109.99, 110, 255, 264, 855, 728, 443],
[41.54, 61.58, 79.99, 110, 110, 254.99, 264, 861, 731, 443],
[41.58, 61.65, 80, 110, 110, 255, 264, 856, 726, 443],
[41.48, 62.7, 80, 110, 110, 254.99, 264, 845, 722, 440],
[41.7, 63.12, 79.99, 110, 110, 254.99, 264, 839, 717, 439],
[41.51, 63.34, 80, 109.99, 110, 255, 264, 837, 712, 438],
[41.51, 62.94, 79.99, 110, 110, 255, 263.99, 835, 714, 438],
[41.64, 64.67, 79.99, 109.99, 109.99, 255, 263.99, 832, 712, 438],
[41.42, 65.86, 80, 109.99, 109.99, 255, 264, 836, 714, 438],
[41.51, 65.98, 80, 110, 109.99, 255, 263.99, 833, 709, 438],
[41.48, 66.49, 79.99, 110, 110, 254.99, 263.99, 835, 712, 439],
[41.7, 65.67, 80, 110, 110, 255, 263.99, 833, 710, 437],
[41.64, 64.66, 80, 110, 110, 255, 263.99, 831, 711, 437],
[41.67, 67.25, 79.99, 110, 109.99, 255, 264, 831, 713, 437],
[41.76, 67.59, 79.99, 110, 110, 254.99, 264, 833, 710, 438],
[41.83, 66.05, 80, 110, 110, 255, 264, 832, 714, 438],
[41.89, 64.38, 80, 110, 110, 254.99, 263.99, 834, 713, 438],
[41.92, 62.99, 80, 110, 110, 255, 264, 833, 711, 437],
[42.11, 63.72, 80, 110, 109.99, 254.99, 264, 834, 716, 438],
[42.27, 63.97, 80, 110, 110, 254.99, 263.99, 827, 705, 437],
[42.33, 63.6, 80, 110, 110, 255, 263.99, 832, 713, 437],
[42.58, 63.83, 79.99, 110, 110, 255, 264, 833, 716, 437],
[42.81, 63.74, 80, 110, 110, 254.99, 263.99, 832, 717, 437],
[42.93, 64.76, 80, 110, 110, 254.99, 264, 839, 720, 439],
[43.03, 65.89, 80, 110, 109.99, 255, 263.99, 830, 712, 437],
[43.25, 66.08, 80, 110, 110, 255, 263.99, 827, 711, 437],
[43.84, 65.74, 80, 110, 110, 254.99, 263.98, 827, 715, 437],
[44.23, 66.03, 79.99, 110, 110, 254.99, 263.99, 828, 709, 436],
[44.88, 65.94, 79.99, 110, 110, 254.99, 264, 832, 717, 437],
[45.41, 65.77, 80, 110, 110, 254.99, 263.99, 824, 715, 435],
[45.99, 64.92, 80, 110, 110, 255, 264, 833, 715, 436],
[46.26, 64.79, 79.99, 110, 110, 255, 264, 837, 721, 437],
[46.26, 64.74, 80, 110, 110, 254.98, 264, 833, 714, 436],
[46.39, 63.77, 80, 110, 110, 254.99, 263.99, 836, 717, 437],
[46.19, 64.52, 80, 110, 110, 255, 263.99, 832, 709, 437],
[46.19, 65.5, 79.99, 109.99, 110, 255, 264, 834, 711, 437],
[46.32, 65.29, 80, 110, 110, 255, 264, 839, 714, 438],
[46.63, 63.96, 79.99, 110, 110, 254.97, 263.99, 840, 721, 437],
[46.53, 66.33, 80, 110, 110, 255, 264, 834, 716, 437],
[46.6, 67.41, 79.99, 110, 109.99, 254.99, 263.99, 840, 721, 439],
[46.7, 67.87, 80, 110, 110, 254.99, 263.99, 854, 733, 442],
[46.7, 68.45, 80, 109.99, 110, 254.99, 264, 851, 730, 441],
[46.6, 70.59, 80, 110, 110, 254.97, 263.99, 860, 737, 443],
[46.73, 72.37, 80, 110, 110, 254.99, 263.99, 854, 736, 443],
[46.77, 70.33, 79.99, 110, 110, 254.99, 263.99, 855, 734, 443],
[46.87, 68.24, 79.99, 109.99, 110, 254.99, 264, 848, 728, 441],
[46.87, 65.65, 79.99, 110, 110, 254.99, 264, 841, 713, 438],
[46.9, 64.41, 80, 110, 110, 254.99, 263.99, 854, 724, 442],
[46.87, 63.76, 79.99, 110, 109.99, 255, 264, 849, 722, 441],
[46.9, 63.6, 79.99, 110, 110, 255, 264, 848, 725, 440],
[46.97, 63.57, 79.99, 110, 110, 255.29, 264, 834, 709, 437],
[46.97, 62.69, 80, 110, 110, 254.99, 264, 838, 714, 438],
[47.01, 62.4, 79.99, 110, 110, 255, 264, 847, 718, 440],
[47.14, 62.33, 79.99, 110, 110, 254.99, 263.99, 843, 716, 440],
[47.11, 63.11, 80, 110, 110, 255, 263.99, 844, 720, 441],
[47.42, 63.64, 80, 110, 110, 255.29, 263.98, 845, 717, 438],
[47.32, 63.82, 79.99, 110, 110, 254.99, 264, 831, 709, 436],
[47.28, 63.97, 80, 110, 110, 254.99, 264, 843, 720, 440],
[47.42, 63.68, 80, 110, 110, 254.99, 263.99, 848, 723, 441],
[47.45, 63.61, 80, 110, 110, 254.99, 264, 851, 727, 442],
[47.52, 64.58, 80, 109.99, 110, 254.99, 263.99, 850, 725, 441],
[47.84, 65.45, 79.99, 110, 109.99, 254.99, 264, 848, 717, 441],
[47.73, 63.99, 80, 110, 110, 255, 263.99, 840, 714, 439],
[47.8, 63.34, 80, 110, 110, 254.99, 263.99, 840, 710, 439],
[47.94, 62.8, 79.99, 110, 110, 255, 264, 845, 716, 440],
[48.05, 62.33, 80, 110, 110, 255, 264, 852, 722, 442],
[47.91, 62.58, 79.99, 110, 109.99, 255, 263.99, 854, 721, 443],
[48.29, 63.61, 80, 109.99, 110, 254.99, 263.99, 851, 724, 442],
[48.22, 63.15, 80, 109.99, 110, 255, 264, 854, 724, 443],
[48.29, 62.98, 80, 110, 110, 254.99, 264, 857, 727, 443],
[48.33, 63.45, 79.99, 109.99, 110, 254.99, 264, 855, 725, 443],
[48.33, 63.55, 80, 109.99, 110, 254.99, 263.99, 857, 729, 444],
[48.54, 63.45, 79.99, 110, 110, 254.99, 264, 852, 725, 442],
[48.93, 62.83, 80, 109.99, 110, 255, 264, 859, 729, 445],
[48.89, 63.02, 79.99, 110, 109.99, 254.99, 264, 862, 726, 444],
[49.11, 62.9, 79.99, 110, 109.99, 254.99, 264, 864, 729, 445],
[49, 62.97, 80, 110, 110, 255, 264, 855, 724, 443],
[48.96, 63.77, 79.99, 110, 110, 255, 263.99, 851, 724, 444],
[49.21, 63.67, 80, 110, 110, 255, 264, 860, 727, 444],
[49.14, 64.37, 80, 110, 110, 255, 264, 863, 735, 445],
[49.32, 64.7, 80, 110, 110, 255, 264, 852, 720, 444],
[49.39, 65.26, 79.99, 109.99, 109.99, 255, 263.99, 853, 723, 443],
[49.43, 64.83, 79.99, 109.99, 110, 254.99, 263.99, 852, 722, 443],
[49.43, 63.61, 80, 110, 110, 254.99, 264, 854, 722, 443],
[49.5, 63.33, 80, 110, 110, 255, 264, 855, 719, 444],
[49.68, 64.3, 79.99, 110, 110, 255, 263.99, 842, 710, 439],
[49.75, 64.64, 80, 110, 110, 254.99, 263.99, 840, 714, 441],
[49.86, 63.82, 79.99, 110, 109.99, 254.99, 264, 842, 716, 441],
[49.86, 62.87, 79.99, 110, 109.99, 254.99, 264, 847, 717, 442],
[49.97, 62.55, 80, 110, 110, 254.99, 264, 848, 718, 442],
[49.94, 62.39, 79.99, 109.99, 110, 255, 264, 843, 714, 440],
[49.97, 63.27, 80, 109.99, 110, 255, 263.99, 847, 716, 441],
[50.12, 63.1, 79.99, 110, 110, 255, 264, 853, 722, 444],
[50.04, 63.52, 80, 110, 109.99, 255, 263.99, 860, 724, 445],
[50.04, 62.98, 80, 110, 110, 255, 263.99, 859, 726, 445],
[50.19, 63.52, 79.99, 109.99, 110, 254.99, 264, 859, 724, 445],
[50.12, 62.84, 79.99, 110, 110, 254.99, 263.99, 863, 726, 445],
[50.08, 63.09, 79.99, 109.99, 109.99, 254.99, 264, 858, 724, 445],
[50.19, 63.13, 79.99, 110, 110, 254.99, 264, 871, 737, 447],
[50.12, 62.2, 79.99, 110, 110, 254.99, 264, 866, 733, 446],
[50.12, 63.13, 80, 110, 110, 255, 264, 863, 728, 446],
[50.08, 63.11, 80, 110, 110, 254.99, 264, 864, 730, 446],
[50.08, 64.02, 80, 110, 110, 254.99, 263.99, 865, 730, 446],
[50.01, 64.58, 80, 110, 110, 254.99, 263.99, 867, 735, 446],
[50.26, 64.39, 79.99, 109.99, 110, 255, 264, 862, 727, 445],
[49.9, 64.09, 79.99, 109.99, 110, 255, 263.99, 854, 722, 444],
[49.83, 64.09, 79.99, 110, 110, 254.99, 263.99, 851, 721, 442],
[49.72, 64.46, 80, 109.99, 110, 255, 264, 854, 722, 442],
[49.68, 64.7, 79.99, 110, 110, 255, 264, 860, 723, 444],
[49.47, 63.18, 79.99, 110, 110, 254.99, 263.99, 863, 726, 444],
[49.54, 62.95, 80, 110, 109.99, 255, 264, 859, 725, 443],
[49.32, 62.81, 80, 110, 109.99, 254.99, 263.99, 862, 730, 444],
[49.32, 63.32, 80, 110, 109.99, 255, 263.99, 868, 732, 445],
[49.04, 62.57, 80, 110, 109.99, 254.99, 263.99, 864, 731, 445],
[48.93, 62.12, 79.99, 110, 110, 254.99, 264, 853, 721, 443],
[48.89, 61.49, 80, 110, 110, 255, 264, 862, 724, 443],
[48.82, 61.39, 80, 110, 109.99, 255, 263.99, 867, 728, 445],
[48.79, 61.36, 80, 110, 109.99, 255, 264, 868, 732, 446],
[48.64, 61.09, 79.99, 109.99, 110, 255, 264, 863, 728, 444],
[48.47, 61.21, 80, 109.99, 110, 254.99, 263.99, 858, 723, 443],
[48.36, 61.9, 80, 110, 110, 255, 264, 853, 720, 442],
[48.19, 62.5, 80, 110, 110, 255, 263.99, 841, 707, 438],
[47.94, 62.03, 79.99, 110, 110, 255, 264.01, 856, 721, 442],
[47.01, 66.62, 80, 110, 110, 255, 263.99, 843, 719, 439],
[46.87, 67.45, 80, 109.99, 110, 255, 263.99, 839, 710, 437],
[47.01, 65.51, 80, 110, 110, 254.99, 264, 839, 709, 438],
[46.73, 64.33, 80, 109.99, 110, 255, 264, 859, 728, 443],
[46.56, 65.41, 80, 109.99, 110, 255, 264, 858, 731, 443],
[46.66, 64.53, 80, 109.99, 110, 255, 264, 856, 727, 443],
[46.63, 66.31, 80, 110, 109.99, 254.99, 264, 858, 729, 442],
[46.83, 65.64, 79.99, 110, 109.99, 255, 264, 861, 731, 443],
[46.56, 63.96, 80, 110, 110, 254.99, 264, 855, 729, 442],
[46.6, 64.53, 80, 110, 110, 255, 264, 853, 728, 441],
[46.49, 64.78, 79.99, 110, 110, 254.99, 263.99, 840, 710, 438],
[46.56, 64.67, 79.99, 110, 110, 255, 264.01, 831, 705, 435],
[46.66, 64.59, 79.99, 110, 110, 255, 264, 855, 722, 441],
[46.39, 63.98, 79.99, 109.99, 109.99, 254.99, 264, 858, 725, 442],
[46.39, 63.45, 79.99, 110, 110, 255, 264, 854, 724, 441],
[46.49, 62.74, 79.99, 109.99, 110, 254.99, 263.99, 848, 721, 440],
[46.46, 62.59, 80, 109.99, 109.99, 255, 263.93, 880, 730, 442],
[46.36, 63.02, 80, 110, 110, 255, 264, 854, 727, 443],
[46.36, 64.66, 79.99, 110, 109.99, 255, 264, 854, 724, 442],
[46.26, 64.48, 80, 109.99, 110, 254.99, 264, 859, 729, 443],
[46.39, 65.26, 80, 110, 110, 255, 264, 861, 732, 443],
[46.32, 64.86, 80, 110, 110, 255, 264.04, 867, 734, 444],
[46.29, 66.87, 79.99, 110, 110, 255, 263.99, 862, 732, 444],
[46.32, 65.71, 80, 110, 110, 255, 263.99, 860, 729, 443],
[46.36, 65.45, 80, 110, 110, 255, 263.99, 854, 732, 442],
[46.26, 66.31, 80, 110, 110, 255, 264.16, 867, 732, 443],
[46.36, 67.64, 80, 109.99, 110, 255, 263.99, 853, 724, 442],
[46.56, 66.3, 79.99, 110, 110, 255, 264, 858, 727, 442],
[46.19, 65.35, 80, 109.99, 110, 254.99, 264, 860, 730, 443],
[46.19, 64.71, 80, 109.99, 110, 254.99, 264, 861, 730, 443],
[46.22, 64.32, 80, 109.99, 110, 255, 263.99, 862, 734, 443],
[46.12, 64.08, 79.99, 110, 110, 255, 264, 857, 730, 442],
[46.19, 65.27, 79.99, 110, 110, 255, 264, 857, 733, 441],
[46.19, 63.87, 80, 110, 110, 254.99, 264, 857, 723, 442],
[46.19, 64.51, 79.99, 110, 110, 255, 263.99, 854, 723, 441],
[46.19, 64.68, 79.99, 110, 109.99, 255, 263.99, 839, 712, 438],
[46.22, 65.71, 80, 110, 110, 255, 264, 845, 718, 439],
[46.39, 66.21, 79.99, 110, 110, 254.99, 263.99, 836, 715, 437],
[46.19, 64.84, 79.99, 110, 110, 255, 264, 846, 715, 439],
[46.29, 64.01, 79.99, 110, 110, 254.99, 263.99, 854, 724, 442],
[46.36, 63.64, 80, 110, 110, 255, 264, 858, 728, 442],
[46.15, 64.2, 80, 110, 110, 254.99, 264, 854, 725, 442],
[46.32, 63.81, 80, 110, 110, 255, 263.99, 852, 725, 441],
[46.15, 65.31, 80, 109.99, 110, 255, 264, 858, 729, 442],
[46.15, 65.03, 79.99, 110, 110, 255, 264, 853, 723, 441],
[46.12, 65.48, 80, 110, 109.99, 255, 264, 857, 730, 442],
[46.09, 64.86, 80, 110, 109.99, 255, 263.99, 856, 728, 442],
[46.09, 66.5, 79.99, 110, 109.99, 254.99, 263.99, 861, 736, 443],
[46.19, 67.3, 80, 110, 110, 254.99, 264, 860, 731, 443],
[46.32, 66.41, 79.99, 110, 110, 255, 263.99, 858, 734, 443],
[46.15, 65.03, 80, 110, 109.99, 254.99, 264, 862, 735, 444],
[45.92, 64.6, 80, 110, 110, 255, 264, 858, 730, 443],
[46.09, 64.26, 80, 110, 110, 255, 263.99, 858, 730, 442],
[45.99, 64.72, 80, 110, 110, 254.99, 263.99, 864, 738, 443],
[46.19, 64.7, 80, 109.99, 110, 255, 264, 863, 732, 443],
[46.19, 64, 80, 110, 110, 254.99, 263.99, 860, 731, 443],
[46.43, 63.93, 80, 110, 110, 255, 263.99, 864, 731, 442],
[46.6, 63.75, 80, 110, 109.99, 254.99, 263.99, 859, 732, 442],
[46.39, 63.66, 80, 110, 110, 254.99, 263.98, 867, 728, 443],
[46.39, 63.31, 80, 110, 110, 255, 264, 855, 724, 441],
[46.36, 62.96, 80, 110, 110, 254.99, 263.98, 855, 727, 442],
[46.46, 63.53, 79.99, 110, 110, 255, 263.99, 855, 726, 441],
[46.46, 66.34, 79.99, 109.99, 110, 254.99, 263.98, 861, 727, 442],
[46.43, 65.92, 79.99, 109.99, 110, 255, 263.99, 851, 725, 441],
[46.43, 65.19, 79.99, 110, 110, 254.99, 263.99, 859, 733, 442],
[46.49, 65.84, 79.99, 110, 110, 254.99, 263.99, 851, 724, 441],
[46.36, 66.42, 79.99, 110, 110, 254.99, 263.99, 885, 732, 443],
[46.32, 65.78, 80, 110, 109.99, 255, 264, 851, 721, 440],
[46.56, 65.96, 79.99, 110, 110, 254.99, 263.99, 845, 713, 439],
[46.43, 64.66, 79.99, 110, 110, 254.99, 264, 851, 726, 441],
[46.46, 64.19, 79.99, 110, 110, 255, 264, 857, 729, 442],
[46.36, 62.64, 80, 110, 110, 254.99, 263.97, 865, 734, 444],
[46.39, 62.4, 80, 110, 109.99, 254.99, 263.99, 863, 733, 444],
[46.43, 62.07, 80, 110, 110, 255, 264, 863, 734, 444],
[46.39, 62.23, 79.99, 110, 109.99, 254.99, 264, 866, 735, 444],
[46.46, 61.94, 80, 109.99, 110, 255, 264, 864, 732, 444],
[46.56, 62.13, 80, 110, 109.99, 254.99, 263.91, 868, 737, 444],
[46.6, 61.78, 79.99, 110, 110, 254.99, 264, 859, 731, 442],
[46.46, 62.88, 79.99, 110, 109.99, 255, 263.99, 864, 730, 443],
[46.49, 62.65, 80, 110, 110, 255, 263.99, 863, 730, 443],
[46.63, 61.99, 80, 110, 110, 255, 263.99, 857, 728, 442],
[46.46, 61.7, 79.99, 109.99, 110, 255.43, 260.46, 842, 717, 438],
[46.43, 62.29, 79.99, 110, 110, 254.99, 263.99, 851, 719, 440],
[46.46, 61.8, 80, 110, 109.99, 254.99, 263.99, 859, 726, 441],
[46.39, 61.71, 79.99, 110, 109.99, 255, 263.99, 857, 728, 442],
[46.29, 61.83, 79.99, 110, 110, 255, 264, 851, 718, 439],
[46.43, 61.77, 79.99, 110, 110, 255.06, 263.3, 837, 711, 437],
[46.32, 62.48, 80, 110, 109.99, 255, 264, 846, 716, 438],
[46.29, 64.28, 79.99, 110, 110, 255, 264, 854, 722, 440],
[46.46, 65.09, 79.99, 110, 110, 254.99, 264, 855, 726, 441],
[46.43, 65.51, 80, 110, 109.99, 254.99, 263.99, 849, 721, 439],
[46.39, 67.54, 79.99, 110, 110, 255.44, 267.31, 855, 723, 440],
[46.39, 67.99, 80, 110, 110, 255, 264, 850, 719, 440],
[46.12, 65.15, 79.99, 110, 110, 255, 263.99, 864, 737, 442],
[46.39, 64.83, 80, 110, 110, 254.99, 264, 860, 734, 442],
[46.19, 65.14, 79.99, 110, 110, 255, 263.99, 863, 732, 443],
[46.19, 64.9, 79.99, 110, 109.99, 254.99, 264, 855, 724, 440],
[46.19, 63.47, 79.99, 110, 110, 255, 264, 858, 729, 441],
[46.02, 63.1, 80, 110, 109.99, 255, 264, 856, 724, 440],
[46.19, 63.07, 79.99, 110, 109.99, 254.99, 264, 845, 714, 438],
[46.09, 63.96, 80, 110, 110, 255, 264, 861, 731, 442],
[46.15, 65.42, 80, 110, 110, 255, 263.99, 861, 731, 442],
[46.46, 65.6, 80, 110, 110, 255, 264, 861, 730, 442],
[46.12, 65.51, 79.99, 110, 110, 255, 263.99, 863, 732, 442],
[46.15, 65.2, 80, 110, 110, 254.99, 264, 856, 728, 441],
[46.05, 64.14, 79.99, 110, 110, 255, 264, 859, 731, 442],
[46.32, 64.76, 79.99, 110, 110, 255, 264, 853, 721, 440],
[46.39, 63.57, 79.99, 110, 110, 255, 264, 856, 727, 440],
[46.02, 63.21, 79.99, 110, 110, 255, 264, 856, 726, 440],
[46.15, 63.14, 80, 110, 110, 254.99, 263.99, 858, 730, 442],
[46.09, 63.93, 80, 110, 110, 255, 264, 843, 716, 438],
[46.09, 64.27, 79.99, 110, 110, 255, 264, 849, 724, 439],
[46.19, 65.3, 80, 110, 110, 255, 264, 851, 725, 440],
[46.19, 67.61, 79.99, 110, 109.99, 255, 263.99, 854, 723, 440],
[46.09, 67.15, 79.99, 110, 110, 254.99, 263.99, 849, 722, 439],
[46.05, 66.16, 79.99, 110, 109.99, 254.99, 264, 843, 712, 437],
[46.19, 65.42, 80, 110, 110, 254.99, 264, 838, 713, 437],
[45.99, 65.69, 79.99, 109.99, 110, 254.99, 263.99, 837, 713, 436],
[45.95, 65.09, 79.99, 110, 109.99, 254.99, 263.99, 843, 720, 438],
[46.19, 64.72, 80, 110, 110, 255, 263.99, 855, 728, 441],
[45.92, 64.84, 79.99, 109.99, 109.99, 254.99, 264, 847, 724, 439],
[45.99, 64.99, 80, 109.99, 109.99, 254.99, 263.99, 844, 717, 439],
[46.02, 66.3, 79.99, 109.99, 110, 254.99, 264, 856, 728, 442],
[45.72, 66, 80, 110, 110, 254.99, 263.99, 855, 733, 441],
[45.85, 66.25, 79.99, 110, 110, 255, 263.99, 856, 723, 441],
[45.92, 65.91, 79.99, 110, 110, 254.98, 263.99, 845, 719, 439],
[45.85, 66.03, 79.99, 110, 110, 255, 264, 845, 720, 438],
[46.19, 65.4, 79.99, 110, 110, 254.99, 264, 860, 734, 442],
[45.92, 64.37, 80, 109.99, 110, 255, 264, 862, 732, 442],
[45.99, 62.89, 79.99, 109.99, 110, 255, 264, 862, 731, 442],
[45.88, 62.43, 79.99, 110, 110, 254.98, 263.99, 855, 723, 440],
[45.99, 62.7, 80, 110, 109.99, 255, 264, 855, 726, 441],
[45.92, 62.3, 79.99, 109.99, 109.99, 254.99, 263.99, 857, 729, 441],
[46.02, 61.28, 80, 110, 110, 255, 263.99, 860, 729, 442],
[45.82, 60.48, 80, 109.99, 110, 255, 263.99, 862, 731, 442],
[45.78, 60.85, 79.99, 110, 109.99, 255, 264, 862, 730, 442],
[45.88, 60.96, 80, 110, 109.99, 255, 263.99, 860, 726, 441],
[45.72, 61.19, 79.99, 109.99, 110, 255, 264, 860, 730, 443],
[45.75, 61.29, 79.99, 110, 109.99, 255.07, 264, 863, 730, 442],
[45.99, 62.48, 79.99, 110, 109.99, 255, 264, 854, 722, 441],
[45.82, 61.96, 79.99, 110, 109.99, 255, 264, 856, 726, 441],
[45.75, 62.12, 79.99, 109.99, 110, 254.99, 264, 854, 726, 441],
[45.78, 61.51, 80, 110, 110, 254.99, 263.99, 858, 730, 442],
[45.85, 61.14, 80, 110, 110, 254.52, 264.01, 867, 733, 442],
[46.15, 60.86, 80, 109.99, 109.99, 255, 264, 855, 725, 441],
[46.15, 63.23, 79.99, 110, 110, 254.99, 264, 851, 723, 439],
[46.09, 63.3, 79.99, 110, 109.99, 255, 264, 854, 727, 440],
[46.15, 62.67, 80, 109.99, 110, 255, 264, 848, 720, 438],
[46.36, 62.36, 80, 110, 110, 255, 264, 854, 722, 440],
[46.39, 63.69, 80, 109.99, 110, 255, 263.99, 855, 723, 441],
[46.39, 63.47, 79.99, 110, 110, 255, 263.99, 856, 724, 440],
[46.36, 63.6, 79.99, 110, 110, 254.99, 264, 854, 724, 440],
[46.36, 63.69, 80, 109.99, 110, 255, 264, 847, 718, 438],
[46.36, 64.85, 80, 110, 110, 254.99, 263.99, 848, 718, 439],
[46.43, 63.82, 80, 109.99, 110, 255, 263.99, 844, 715, 437],
[46.43, 63.66, 79.99, 110, 109.99, 255, 264, 838, 715, 436],
[46.63, 62.81, 80, 109.99, 110, 254.99, 264, 845, 716, 439],
[46.32, 63.22, 80, 110, 110, 254.99, 263.99, 864, 739, 443],
[46.53, 63.87, 80, 110, 110, 255, 263.99, 873, 740, 443],
[46.39, 65.35, 80, 110, 110, 255, 263.99, 855, 730, 441],
[46.32, 64.15, 80, 110, 110, 255, 264, 859, 731, 441],
[46.39, 64.72, 79.99, 110, 110, 255, 263.99, 866, 736, 443],
[46.22, 64.74, 80, 110, 109.99, 255, 263.99, 862, 735, 443],
[46.32, 64.31, 79.99, 110, 110, 255, 263.99, 861, 734, 442],
[46.32, 65.29, 79.99, 109.99, 110, 255, 264, 855, 732, 442],
[46.43, 64.63, 80, 110, 110, 255, 264, 861, 731, 442],
[46.39, 64.2, 80, 110, 109.99, 255, 263.99, 865, 736, 443],
[46.46, 65.44, 80, 110, 110, 255, 263.99, 868, 738, 444],
[46.73, 66.8, 80, 110, 110, 254.99, 263.99, 863, 738, 443],
[46.56, 66.87, 80, 110, 110, 255, 264, 861, 737, 442],
[46.53, 65.08, 79.99, 110, 110, 255, 264, 863, 736, 442],
[46.73, 64.25, 80, 110, 110, 255, 263.99, 862, 737, 442],
[46.8, 64.36, 80, 110, 110, 255, 263.99, 859, 730, 442],
[46.73, 64.05, 79.99, 109.99, 109.99, 255, 264, 860, 730, 442],
[46.66, 65.3, 79.99, 110, 110, 255, 263.98, 856, 724, 441],
[46.7, 64.47, 79.99, 110, 110, 255, 264, 858, 730, 442],
[46.73, 64.73, 79.99, 110, 110, 255, 264, 861, 729, 443],
[46.8, 63.9, 80, 109.99, 110, 255, 263.99, 850, 723, 439],
[46.77, 63.74, 80, 110, 110, 254.99, 263.99, 861, 733, 442],
[46.8, 64.23, 80, 110, 109.99, 254.99, 263.99, 857, 730, 441],
[46.9, 64.33, 79.99, 110, 110, 255, 264.01, 860, 731, 442],
[46.73, 63.59, 79.99, 110, 110, 255, 263.99, 863, 731, 442],
[46.77, 63.35, 80, 109.99, 109.99, 254.99, 263.99, 864, 732, 442],
[46.77, 62.49, 80, 110, 110, 255, 264, 855, 729, 440],
[46.77, 62.7, 80, 110, 109.99, 255, 263.99, 849, 720, 439],
[46.8, 63.15, 80, 110, 109.99, 255, 264, 848, 722, 439],
[46.9, 64.04, 79.99, 109.99, 110, 254.99, 264, 848, 720, 439],
[47.01, 63.48, 80, 110, 110, 254.99, 264, 862, 732, 442],
[47.07, 63.35, 80, 110, 110, 255, 263.99, 860, 728, 442],
[46.77, 63.38, 79.99, 110, 110, 255, 264, 867, 735, 443],
[46.77, 63.39, 79.99, 109.99, 110, 254.99, 264.01, 864, 732, 443],
[46.87, 62.72, 79.99, 109.99, 110, 254.99, 264, 868, 733, 443],
[47.01, 62.59, 80, 109.99, 110, 255, 264, 863, 731, 442],
[46.87, 62.48, 79.99, 109.99, 110, 254.99, 264, 864, 732, 442],
[46.9, 62.17, 80, 110, 110, 255, 263.99, 858, 729, 441],
[47.01, 61.81, 79.99, 110, 110, 254.99, 264.01, 857, 723, 440],
[47.01, 62.38, 79.99, 110, 110, 255, 263.99, 854, 724, 440],
[47.01, 62.42, 79.99, 110, 110, 254.99, 264, 866, 731, 442],
[47.14, 64.4, 79.99, 110, 109.99, 254.99, 264, 856, 730, 441],
[47.28, 65.8, 79.99, 110, 110, 255, 263.81, 852, 720, 439],
[47.32, 65.56, 79.99, 110, 110, 255, 263.99, 862, 732, 442],
[47.56, 65.19, 79.99, 109.99, 110, 254.99, 264, 857, 725, 442],
[47.45, 64.45, 80, 110, 110, 254.99, 264, 858, 728, 441],
[47.73, 63.86, 80, 109.99, 110, 255, 264, 860, 727, 442],
[47.7, 63.02, 79.99, 110, 110, 254.99, 264.17, 856, 720, 439],
[47.59, 63.81, 80, 109.99, 110, 255, 264, 840, 714, 436],
[47.84, 62.54, 80, 110, 110, 254.99, 264.33, 885, 733, 442],
[47.84, 65.81, 79.99, 110, 110, 255, 263.99, 868, 732, 443],
[48.08, 66.09, 79.99, 110, 110, 255, 263.99, 861, 730, 442],
[47.98, 65.3, 80, 110, 110, 254.99, 263.99, 866, 735, 443],
[48.15, 65.63, 79.99, 110, 110, 255, 264, 864, 731, 443],
[48.12, 65.57, 79.99, 110, 110, 254.99, 281.12, 866, 733, 443],
[48.15, 65.2, 79.99, 110, 109.99, 255, 264, 858, 724, 441],
[48.08, 63.52, 80, 110, 110, 254.99, 264, 862, 725, 443],
[48.22, 62.93, 79.99, 109.99, 110, 254.99, 264, 860, 730, 443],
[48.36, 61.96, 80, 110, 110, 254.99, 263.99, 868, 733, 443],
[48.15, 62.12, 80, 110, 110, 255, 263.99, 871, 738, 444],
[48.22, 62.78, 79.99, 109.99, 110, 255, 264, 863, 727, 442],
[48.4, 62.74, 80, 110, 110, 255, 264, 860, 727, 442],
[48.22, 63.37, 79.99, 110, 110, 254.99, 264, 865, 731, 443],
[48.19, 63.37, 79.99, 110, 110, 255, 263.99, 862, 731, 442],
[48.22, 63.19, 79.99, 110, 110, 254.99, 263.99, 864, 728, 442],
[48.19, 63.22, 80, 109.99, 110, 254.99, 264.01, 861, 729, 442],
[48.15, 62.86, 79.99, 110, 109.99, 255, 264, 855, 726, 441],
[48.15, 62.93, 80, 110, 110, 254.99, 264, 843, 713, 437],
[48.12, 62.87, 79.99, 110, 110, 255, 263.99, 843, 711, 437],
[48.12, 62.57, 80, 110, 109.99, 255, 264, 836, 708, 435],
[48.19, 62.53, 79.99, 110, 110, 254.99, 264, 842, 710, 436],
[48.15, 62.6, 79.99, 110, 110, 254.99, 263.99, 847, 718, 438],
[48.05, 62.71, 80, 110, 110, 254.99, 263.99, 846, 715, 437],
[48.26, 63.3, 79.99, 110, 109.99, 254.99, 263.99, 838, 705, 435],
[48.01, 63.71, 79.99, 110, 110, 255, 264, 850, 716, 439],
[48.08, 63.06, 80, 110, 110, 255, 264, 844, 712, 437],
[48.05, 63.74, 79.99, 109.99, 110, 254.99, 264, 847, 714, 437],
[48.05, 62.41, 79.99, 110, 110, 254.99, 263.99, 847, 713, 437],
[48.05, 62.37, 79.99, 110, 110, 255, 264, 842, 711, 437],
[48.08, 62.25, 80, 110, 110, 254.99, 263.99, 846, 714, 437],
[47.98, 62.53, 80, 110, 110, 255, 263.99, 845, 712, 437],
[47.98, 62.53, 79.99, 110, 110, 254.99, 264, 847, 714, 438],
[48.05, 62.39, 79.99, 110, 110, 254.99, 263.99, 859, 725, 440],
[47.98, 63.01, 80, 110, 110, 254.99, 263.99, 855, 721, 439],
[47.94, 63.33, 80, 110, 110, 254.99, 263.99, 854, 724, 440],
[47.98, 65.06, 79.99, 109.99, 109.99, 255, 263.99, 859, 727, 440],
[47.94, 66, 79.99, 109.99, 110, 255, 263.99, 858, 725, 441],
[47.94, 65.42, 79.99, 110, 110, 255, 263.99, 858, 724, 441],
[47.87, 64.04, 79.99, 109.99, 110, 255, 263.99, 853, 722, 438],
[47.84, 64.17, 79.99, 110, 110, 255, 264, 851, 718, 438],
[47.8, 63.59, 79.99, 110, 110, 255, 264, 863, 729, 442],
[47.77, 63.08, 79.99, 110, 110, 254.99, 263.99, 864, 730, 443],
[47.8, 62.85, 80, 110, 110, 254.99, 263.98, 871, 733, 443],
[47.8, 62.49, 80, 110, 110, 255, 264, 864, 731, 442],
[47.8, 61.72, 79.99, 110, 110, 255, 264, 846, 714, 438],
[47.77, 61.59, 80, 110, 110, 255, 264, 856, 723, 440],
[47.77, 61.68, 79.99, 110, 110, 255, 263.99, 865, 732, 442],
[47.77, 61.71, 79.99, 110, 110, 255, 263.99, 920, 732, 443],
[47.84, 61.55, 80, 110, 110, 254.99, 264, 862, 730, 442],
[47.73, 61.34, 79.99, 109.99, 109.99, 254.99, 264, 851, 717, 439],
[47.7, 62.36, 79.99, 110, 109.99, 254.99, 263.99, 840, 714, 436],
[47.7, 62.19, 80, 110, 110, 254.99, 263.99, 845, 714, 437],
[47.66, 62.56, 80, 109.99, 110, 254.99, 263.98, 849, 716, 438],
[47.8, 62.28, 79.99, 110, 110, 255, 263.99, 854, 719, 440],
[47.63, 62.14, 80, 110, 109.99, 254.99, 264, 851, 721, 438],
[47.73, 62.45, 79.99, 110, 109.99, 255, 263.99, 838, 709, 436],
[47.84, 62.66, 80, 109.99, 110, 255, 263.99, 849, 716, 438],
[47.77, 63.56, 79.99, 110, 110, 254.99, 263.93, 859, 724, 441],
[47.84, 64.2, 80, 109.99, 110, 254.99, 264, 860, 727, 441],
[47.84, 65.17, 79.99, 110, 110, 254.99, 263.99, 864, 727, 441],
[47.73, 65.84, 79.99, 110, 109.99, 254.99, 264, 862, 731, 441],
[47.66, 64.98, 79.99, 110, 109.99, 254.99, 264, 856, 726, 441],
[47.8, 65.23, 79.99, 110, 110, 255.02, 263.71, 855, 722, 440],
[47.77, 64.04, 80, 110, 110, 254.99, 263.99, 849, 721, 439],
[47.59, 63.28, 80, 110, 110, 255, 264, 840, 711, 436],
[47.63, 62.73, 80, 109.99, 110, 254.99, 263.99, 851, 720, 439],
[47.7, 62.87, 79.99, 110, 110, 254.99, 264, 854, 724, 439],
[47.73, 63.53, 79.99, 110, 110, 255.04, 263.59, 859, 725, 440],
[47.8, 64.35, 79.99, 109.99, 110, 254.99, 264, 852, 722, 439],
[47.77, 63.85, 80, 110, 110, 254.99, 264, 846, 716, 438],
[47.73, 64.46, 79.99, 110, 110, 255, 263.99, 850, 718, 438],
[47.73, 64.73, 79.99, 110, 109.99, 255, 264, 854, 723, 439],
[47.49, 65.25, 80, 110, 110, 255.21, 263.6, 857, 725, 440],
[47.77, 64.28, 80, 110, 110, 254.99, 264, 873, 738, 444],
[47.73, 64.72, 79.99, 110, 110, 255, 263.99, 875, 737, 444],
[47.73, 64.3, 80, 109.99, 109.99, 255, 264, 872, 734, 444],
[47.73, 64.21, 79.99, 110, 109.99, 255, 264, 876, 738, 444],
[47.7, 63.06, 79.99, 110, 110, 255.4, 263.47, 872, 739, 443],
[47.66, 63.92, 80, 110, 110, 255, 263.99, 873, 734, 443],
[47.66, 64.35, 79.99, 110, 110, 254.99, 263.99, 864, 732, 442],
[47.56, 63.85, 79.99, 110, 110, 255, 264, 866, 732, 442],
[47.73, 63.49, 80, 109.99, 110, 255, 264, 868, 737, 443],
[47.63, 63.08, 80, 110, 109.99, 254.99, 264, 866, 734, 443],
[47.63, 63.33, 79.99, 109.99, 110, 254.99, 264, 864, 732, 442],
[47.56, 62.26, 79.99, 110, 109.99, 254.99, 264, 866, 731, 442],
[47.52, 62.57, 80, 110, 110, 254.99, 263.99, 855, 727, 440],
[47.49, 63.07, 80, 109.99, 110, 255, 264, 862, 728, 441],
[47.42, 63.6, 79.99, 110, 110, 255, 263.99, 859, 725, 441],
[47.77, 62.6, 80, 110, 110, 254.99, 263.99, 856, 722, 439],
[47.73, 62.61, 79.99, 110, 110, 255, 264, 854, 721, 439],
[47.42, 62.69, 80, 110, 110, 254.99, 264, 844, 713, 436],
[47.28, 62.74, 80, 109.99, 110, 255, 264, 843, 714, 436],
[47.11, 62.78, 80, 110, 109.99, 254.99, 264, 840, 715, 435],
[47.07, 62.86, 79.99, 110, 110, 255, 264, 840, 712, 435],
[47.21, 63.78, 79.99, 109.99, 110, 254.99, 264, 841, 711, 435],
[46.97, 64.42, 79.99, 110, 110, 254.99, 264, 841, 711, 436],
[47.14, 64.53, 79.99, 110, 110, 255, 263.99, 848, 718, 437],
[46.83, 64.42, 79.99, 110, 110, 255, 263.99, 848, 717, 438],
[46.73, 64.94, 79.99, 110, 110, 254.99, 264, 853, 723, 439],
[46.63, 64.56, 80, 110, 110, 254.99, 264, 847, 718, 438],
[46.6, 64.1, 80, 110, 110, 254.99, 264, 844, 714, 436],
[46.6, 64.93, 79.99, 110, 110, 254.99, 264, 837, 714, 435],
[46.6, 64.17, 79.99, 110, 110, 254.99, 264, 848, 716, 438],
[46.53, 64.12, 79.99, 109.99, 109.99, 255, 264, 863, 730, 441],
[46.39, 63.36, 80, 110, 110, 254.99, 264, 866, 733, 442],
[46.39, 64.33, 79.99, 110, 109.99, 255, 264, 854, 722, 440],
[46.29, 63.04, 80, 110, 110, 255, 264, 863, 731, 441],
[46.19, 63.62, 79.99, 110, 109.99, 255, 264, 860, 734, 441],
[46.12, 63.88, 79.99, 110, 110, 254.99, 264, 859, 728, 441],
[45.92, 65.3, 79.99, 110, 110, 255, 264, 845, 716, 436],
[45.82, 65.34, 80, 110, 110, 255, 263.99, 854, 723, 439],
[45.78, 67.64, 79.99, 110, 110, 254.99, 263.99, 861, 731, 441],
[45.68, 67.55, 79.99, 110, 110, 254.99, 264, 857, 730, 439],
[45.72, 68.16, 79.99, 110, 110, 255, 264, 860, 731, 440],
[45.68, 66.24, 80, 110, 110, 254.99, 264, 851, 721, 438],
[45.65, 66.5, 79.99, 110, 110, 254.99, 264, 854, 724, 439],
[45.62, 65.27, 80, 110, 110, 254.99, 263.99, 854, 729, 439],
[45.52, 67.06, 79.99, 110, 110, 255, 264, 854, 725, 438],
[45.48, 66.98, 79.99, 109.99, 110, 254.99, 263.99, 855, 728, 439],
[45.68, 66.53, 80, 109.99, 110, 255, 264, 853, 728, 439],
[45.12, 63.66, 79.99, 110, 109.99, 255, 264, 854, 724, 438],
[45.22, 64.36, 80, 110, 110, 254.97, 263.99, 847, 723, 437],
[45.18, 63.13, 80, 110, 110, 254.99, 264, 848, 723, 437],
[45.12, 63.11, 79.99, 110, 109.99, 254.99, 264, 849, 724, 438],
[45.18, 61.63, 80, 110, 109.99, 255, 264, 859, 729, 440],
[45.08, 61.53, 80, 110, 109.99, 255, 264, 850, 721, 438],
[45.05, 60.79, 80, 110, 110, 254.99, 264, 853, 725, 439],
[45.12, 60.56, 79.99, 110, 109.99, 254.99, 264, 863, 731, 441],
[45.05, 60.41, 79.99, 110, 109.99, 254.99, 264, 862, 731, 440],
[44.95, 61.03, 79.99, 109.99, 110, 255, 264, 858, 727, 440],
[44.65, 62.29, 79.99, 110, 110, 255, 263.99, 863, 733, 441],
[44.78, 62.37, 80, 110, 110, 255.33, 264.01, 864, 725, 439],
[44.62, 62.54, 79.99, 109.99, 110, 255, 263.99, 849, 724, 438],
[44.52, 62.62, 80, 110, 110, 254.99, 264, 839, 717, 436],
[44.49, 64.23, 79.99, 110, 109.99, 255, 263.99, 841, 718, 436],
[44.39, 65.11, 79.99, 109.99, 110, 255, 264, 860, 735, 441],
[44.29, 65.43, 80, 110, 110, 254.99, 264, 849, 727, 438],
[44.13, 65.74, 80, 110, 110, 254.99, 264, 844, 720, 437],
[44.06, 66.05, 79.99, 110, 110, 255, 263.99, 840, 715, 436],
[43.87, 66.7, 80, 110, 110, 254.99, 263.99, 837, 711, 435],
[43.8, 65.74, 80, 110, 110, 255, 263.99, 838, 711, 436],
[43.64, 65.84, 80, 110, 110, 255, 264, 836, 719, 436],
[43.54, 65.66, 80, 110, 109.99, 255, 263.99, 857, 735, 440],
[43.48, 64.97, 80, 110, 110, 255, 264, 859, 732, 441],
[43.42, 67.22, 79.99, 109.99, 110, 255, 264, 857, 731, 440],
[43.45, 66.85, 80, 109.99, 110, 255, 264, 850, 723, 439],
[43.51, 65.82, 79.99, 110, 110, 254.99, 264, 847, 721, 438],
[43.61, 65.32, 80, 110, 110, 254.99, 263.99, 848, 725, 438],
[43.61, 65.05, 79.99, 110, 110, 255, 263.99, 852, 733, 439],
[43.58, 65.76, 79.99, 109.99, 110, 255, 263.99, 855, 728, 440],
[43.54, 65.83, 80, 110, 109.99, 255, 264, 850, 724, 439],
[43.8, 65.94, 80, 110, 110, 254.99, 263.99, 851, 728, 439],
[43.93, 65.9, 80, 110, 109.99, 255, 263.99, 861, 733, 441],
[43.97, 64.89, 79.99, 110, 110, 254.99, 263.99, 860, 735, 442],
[43.84, 64.88, 79.99, 110, 110, 255, 264, 859, 729, 441],
[43.8, 63.98, 80, 110, 109.99, 255, 264, 858, 732, 441],
[43.8, 63.11, 80, 110, 110, 255, 264, 852, 729, 439],
[43.8, 63.99, 80, 110, 110, 254.99, 263.99, 849, 724, 438],
[43.84, 64.6, 80, 110, 110, 254.99, 263.99, 846, 723, 438],
[43.87, 65.74, 79.99, 109.99, 110, 255, 263.99, 859, 732, 441],
[43.87, 65.33, 79.99, 109.99, 109.99, 254.99, 263.99, 862, 736, 441],
[43.87, 65.14, 79.99, 110, 110, 254.99, 263.99, 860, 730, 441],
[43.87, 64.54, 80, 110, 110, 255, 264, 852, 724, 439],
[43.87, 65.41, 80, 110, 110, 254.99, 263.99, 854, 729, 439],
[43.9, 64.66, 80, 110, 109.99, 255, 263.99, 855, 733, 440],
[43.97, 65.72, 79.99, 110, 109.99, 254.99, 264, 855, 724, 440],
[44.06, 65.75, 80, 110, 110, 254.99, 264, 850, 725, 439],
[43.9, 66.99, 80, 110, 110, 255, 264, 839, 716, 436],
[43.9, 68.48, 80, 110, 110, 255, 263.99, 847, 723, 439],
[43.84, 68.72, 79.99, 109.99, 109.99, 254.99, 264, 857, 732, 440],
[44.06, 67.13, 80, 110, 110, 255, 264, 857, 729, 440],
[43.9, 65.99, 80, 110, 110, 255, 263.99, 850, 728, 438],
[43.9, 67.26, 79.99, 109.99, 109.99, 255, 264, 852, 723, 439],
[43.93, 68.07, 80, 109.99, 110, 254.99, 264, 853, 724, 439],
[43.9, 67.5, 79.99, 110, 110, 255, 263.99, 855, 728, 440],
[43.84, 67.71, 80, 110, 110, 255, 264, 843, 720, 437],
[43.97, 68.34, 80, 110, 110, 254.99, 263.99, 853, 722, 439],
[44.06, 66.62, 79.99, 110, 110, 254.99, 264, 844, 720, 438],
[43.97, 64.77, 80, 110, 110, 254.99, 263.99, 842, 718, 436],
[43.93, 64.25, 79.99, 110, 110, 254.99, 264, 846, 716, 438],
[44.06, 63.82, 79.99, 110, 110, 255, 263.99, 846, 719, 438],
[43.97, 63.15, 79.99, 110, 110, 254.99, 263.98, 841, 716, 437],
[43.97, 62.87, 80, 110, 110, 255, 264, 836, 717, 436],
[44.03, 63.25, 80, 110, 110, 255, 263.99, 842, 725, 438],
[44.1, 61.51, 79.99, 109.99, 110, 255, 263.99, 857, 731, 441],
[44.06, 62.49, 79.99, 110, 109.99, 254.99, 263.99, 858, 732, 441],
[44.03, 62.62, 79.99, 110, 109.99, 255, 264, 854, 731, 440],
[44.13, 61.92, 80, 109.99, 110, 255, 263.96, 849, 720, 438],
[44, 62.96, 80, 109.99, 109.99, 254.99, 263.99, 845, 717, 438],
[44.13, 64.01, 80, 110, 109.99, 255, 263.99, 856, 732, 441],
[44.32, 64.4, 80, 110, 110, 254.99, 263.99, 860, 739, 442],
[44.23, 64.72, 80, 110, 110, 255, 264, 852, 732, 440],
[44.39, 64.08, 80, 110, 110, 254.99, 263.99, 847, 721, 438],
[44.42, 64.55, 79.99, 109.99, 110, 255, 264.01, 835, 714, 435],
[44.46, 65.05, 79.99, 110, 110, 254.99, 264, 843, 721, 438],
[44.55, 64.37, 79.99, 109.99, 110, 254.99, 264, 853, 733, 441],
[44.72, 63.45, 80, 110, 110, 255, 264, 846, 723, 439],
[45.08, 64.15, 79.99, 110, 110, 255, 263.92, 871, 724, 439],
[45.35, 64.57, 79.99, 109.99, 110, 254.99, 264, 836, 716, 435],
[45.65, 65.95, 80, 110, 109.99, 254.99, 263.99, 840, 721, 437],
[45.92, 65.74, 79.99, 110, 109.99, 254.99, 264, 850, 723, 439],
[46.19, 66.03, 80, 110, 110, 255, 264, 862, 738, 441],
[46.32, 69.43, 79.99, 110, 109.99, 254.99, 263.69, 854, 729, 439],
[46.39, 69.81, 79.99, 109.99, 110, 255, 263.99, 844, 722, 437],
[46.22, 67.97, 80, 110, 110, 255, 263.99, 838, 718, 436],
[46.19, 67.67, 79.99, 110, 110, 254.99, 263.99, 842, 723, 437],
[46.22, 67.66, 80, 110, 110, 255, 264, 838, 713, 437],
[46.19, 65.8, 80, 109.99, 110, 255, 262.64, 869, 730, 439],
[46.15, 64.51, 80, 110, 110, 255, 263.99, 847, 725, 438],
[46.32, 63.88, 80, 110, 109.99, 254.99, 264, 844, 724, 438],
[46.26, 63.01, 80, 110, 110, 254.99, 263.99, 858, 730, 441],
[46.26, 62.76, 80, 110, 110, 254.99, 263.99, 862, 736, 442],
[46.39, 61.63, 80, 110, 110, 255, 263.99, 861, 733, 441],
[46.63, 62.43, 79.99, 110, 110, 255, 263.99, 867, 738, 442],
[46.53, 62.16, 80, 109.99, 110, 255, 263.99, 858, 730, 440],
[46.63, 61.61, 80, 109.99, 110, 254.99, 263.99, 856, 731, 441],
[47.01, 62.31, 79.99, 109.99, 110, 255, 264, 868, 737, 442],
[46.97, 61.74, 80, 110, 110, 254.99, 263.99, 854, 726, 440],
[47.04, 60.44, 80, 109.99, 110, 254.99, 264, 858, 729, 441],
[47.14, 60.81, 79.99, 110, 110, 254.99, 264, 852, 722, 439],
[47.21, 60.71, 79.99, 110, 110, 254.99, 264, 851, 723, 439],
[47.35, 60.59, 80, 110, 110, 254.99, 264, 850, 721, 439],
[47.38, 60.81, 80, 110, 109.99, 255, 264, 846, 720, 439],
[47.66, 60.82, 80, 110, 110, 254.99, 264, 844, 714, 438],
[47.66, 62.11, 79.99, 110, 109.99, 255, 263.99, 855, 723, 441],
[47.56, 62.74, 80, 110, 110, 255, 263.99, 849, 720, 439],
[47.59, 63.51, 80, 109.99, 109.99, 254.99, 264, 852, 723, 440],
[47.84, 64.39, 80, 109.99, 110, 255, 263.98, 850, 718, 440],
[47.73, 65.26, 80, 110, 110, 254.99, 264, 846, 714, 438],
[47.8, 64.1, 79.99, 110, 110, 255, 264, 850, 722, 439],
[47.84, 64.3, 79.99, 110, 110, 254.99, 263.99, 846, 718, 439],
[47.91, 64.06, 79.99, 110, 109.99, 255, 263.99, 847, 720, 438],
[47.98, 63.74, 80, 110, 110, 254.99, 263.99, 834, 710, 435],
[48.12, 63.73, 80, 110, 110, 255, 264, 835, 707, 436],
[48.15, 64.54, 80, 109.99, 110, 255, 264, 852, 723, 440],
[48.29, 63.92, 80, 109.99, 110, 254.99, 263.99, 851, 723, 441],
[48.36, 64.03, 80, 110, 109.99, 255, 263.99, 847, 718, 439],
[48.57, 62.76, 79.99, 110, 110, 254.99, 263.99, 849, 717, 439],
[48.61, 63.77, 79.99, 110, 110, 255, 263.98, 859, 727, 442],
[48.64, 64.77, 80, 109.99, 110, 255, 264, 846, 717, 439],
[48.79, 64.97, 80, 110, 110, 254.99, 263.99, 845, 721, 439],
[48.96, 64.63, 79.99, 110, 110, 254.99, 264, 847, 717, 439],
[48.96, 64.08, 80, 110, 109.99, 254.99, 263.99, 849, 717, 439],
[49.32, 64.06, 79.99, 110, 110, 254.99, 264, 850, 720, 440],
[49.18, 63.77, 79.99, 109.99, 110, 254.99, 264, 848, 723, 440],
[49.32, 62.72, 79.99, 110, 110, 255, 264, 857, 731, 442],
[49.32, 63.03, 79.99, 109.99, 110, 255, 263.99, 851, 723, 441],
[49.54, 63.15, 80, 109.99, 110, 254.99, 263.99, 907, 728, 442],
[49.54, 64.16, 80, 109.99, 110, 255, 264, 847, 719, 441],
[49.61, 64.71, 79.99, 110, 109.99, 255, 264, 845, 714, 439],
[49.61, 63.67, 79.99, 110, 110, 254.99, 263.99, 850, 723, 441],
[49.68, 64.1, 79.99, 110, 110, 255, 264, 860, 734, 444],
[49.86, 64.82, 79.99, 109.99, 110, 254.98, 263.99, 853, 722, 441],
[50.01, 64.78, 80, 110, 109.99, 254.99, 264, 848, 720, 441],
[50.08, 63.48, 80, 109.99, 110, 255, 264, 854, 721, 441],
[50.26, 63.95, 80, 110, 109.99, 255, 264, 845, 715, 439],
[50.26, 64.47, 79.99, 109.99, 110, 254.99, 264, 840, 717, 439],
[50.34, 64.98, 80, 110, 109.99, 254.99, 263.98, 843, 715, 438],
[50.45, 64.35, 79.99, 110, 110, 255, 264.01, 835, 712, 437],
[50.78, 62.78, 80, 110, 110, 255.07, 263.41, 851, 722, 443],
[50.85, 62.36, 80, 109.99, 110, 254.99, 263.99, 852, 723, 442],
[50.96, 63.23, 79.99, 109.99, 110, 254.99, 263.99, 868, 736, 446],
[51.11, 64.81, 80, 110, 110, 254.99, 264, 872, 738, 447],
[51.15, 64.6, 79.99, 110, 109.99, 254.99, 263.99, 872, 736, 447],
[51.3, 64.57, 80, 109.99, 110, 255, 263.93, 871, 736, 445],
[51.26, 63.22, 79.99, 110, 110, 255, 264, 868, 735, 446],
[51.33, 64.05, 79.99, 110, 110, 254.99, 263.99, 862, 730, 444],
[51.3, 62.99, 79.99, 110, 110, 255, 264, 862, 729, 444],
[51.63, 63.62, 80, 110, 110, 254.99, 263.99, 851, 724, 442],
[51.9, 64.35, 79.99, 110, 110, 254.99, 264, 864, 730, 445],
[52.13, 63.44, 80, 109.99, 110, 254.99, 263.99, 856, 723, 444],
[52.24, 62.94, 79.99, 110, 110, 255.46, 263.57, 859, 730, 443],
[52.24, 62.93, 80, 110, 109.99, 255, 263.99, 863, 724, 445],
[52.31, 63.11, 80, 110, 110, 255, 263.99, 848, 713, 442],
[52.16, 62.73, 79.99, 110, 110, 255, 264, 856, 720, 443],
[52.31, 63.34, 79.99, 110, 110, 254.99, 264, 856, 720, 442],
[52.24, 63.05, 79.99, 110, 110, 254.99, 263.99, 858, 723, 444],
[52.16, 63.47, 79.99, 110, 110, 254.99, 263.99, 860, 723, 444],
[52.16, 63.28, 79.99, 110, 110, 254.99, 264, 863, 728, 445],
[52.35, 62.86, 80, 110, 110, 254.99, 263.99, 856, 721, 443],
[52.2, 62.74, 80, 110, 109.99, 254.99, 264, 858, 725, 444],
[52.13, 62.08, 79.99, 110, 110, 254.99, 263.99, 865, 733, 444],
[52.28, 61.68, 80, 110, 109.99, 254.99, 263.99, 869, 733, 447],
[52.24, 61.15, 80, 110, 110, 255, 264, 858, 723, 444],
[52.2, 61.09, 79.99, 109.99, 109.99, 254.99, 263.99, 859, 725, 444],
[52.09, 62.27, 79.99, 110, 110, 255, 264, 858, 725, 444],
[52.13, 62.03, 79.99, 110, 110, 254.99, 264, 856, 717, 442],
[52.13, 61.77, 80, 110, 109.99, 254.99, 264, 853, 715, 442],
[51.97, 61.81, 80, 109.99, 110, 255, 264, 852, 716, 441],
[52.01, 62.38, 80, 110, 110, 254.99, 263.99, 850, 716, 441],
[52.05, 62.28, 79.99, 110, 110, 255, 264, 847, 714, 441],
[52.05, 62.17, 79.99, 110, 110, 255, 264, 845, 712, 439],
[52.01, 62.13, 79.99, 110, 110, 254.99, 264, 849, 717, 441],
[51.97, 63.28, 80, 110, 110, 254.99, 264, 846, 714, 440],
[51.9, 63.87, 80, 110, 110, 254.99, 264, 839, 706, 439],
[51.78, 64.2, 80, 110, 110, 255, 264, 855, 722, 444],
[51.78, 62.69, 79.99, 110, 110, 254.99, 264, 847, 715, 441],
[51.48, 62.6, 80, 110, 110, 254.98, 263.99, 849, 714, 440],
[51.48, 62.29, 80, 110, 110, 255, 263.99, 846, 716, 440],
[51.48, 62.73, 80, 110, 110, 255, 264, 850, 718, 441],
[51.37, 63.37, 79.99, 109.99, 109.99, 254.99, 264, 837, 706, 438],
[51.37, 63.25, 79.99, 110, 110, 255, 263.99, 839, 706, 438],
[51.63, 62.91, 79.99, 110, 110, 254.97, 264, 835, 704, 437],
[51.26, 63.23, 79.99, 110, 110, 255, 263.99, 853, 717, 443],
[51.15, 62.9, 80, 109.99, 110, 254.99, 263.99, 838, 710, 438],
[50.96, 62.2, 80, 110, 110, 254.99, 264, 839, 707, 439],
[51.07, 62.15, 80, 110, 110, 255, 263.99, 835, 705, 439],
[50.85, 61.88, 79.99, 109.99, 110, 255, 264, 843, 711, 440],
[50.81, 61.48, 80, 110, 109.99, 255, 263.99, 853, 718, 442],
[50.7, 61.16, 80, 110, 110, 255, 264, 856, 719, 442],
[50.78, 60.24, 79.99, 110, 110, 255.02, 264, 848, 713, 440],
[50.7, 60.22, 79.99, 110, 110, 254.99, 264, 841, 710, 439],
[50.74, 60.44, 80, 110, 110, 255, 263.99, 845, 715, 440],
[50.81, 61.78, 80, 110, 110, 255, 264, 851, 717, 442],
[50.7, 63.8, 79.99, 109.99, 110, 254.99, 264, 855, 718, 443],
[50.63, 63.54, 79.99, 110, 109.99, 255.3, 264, 857, 719, 441],
[50.74, 63.39, 79.99, 110, 110, 254.99, 264, 848, 719, 440],
[50.45, 62.6, 79.99, 109.99, 110, 254.99, 263.99, 865, 731, 447],
[50.41, 63.24, 79.99, 110, 110, 254.99, 264, 862, 728, 445],
[50.7, 63.91, 80, 110, 110, 255, 264, 864, 727, 445],
[50.37, 63.24, 79.99, 109.99, 110, 254.99, 263.99, 876, 735, 448],
[50.41, 63.46, 80, 110, 110, 254.99, 263.99, 879, 739, 449],
[50.56, 63.36, 80, 110, 110, 254.99, 263.99, 867, 730, 446],
[50.3, 64.58, 79.99, 110, 109.99, 255, 263.99, 865, 731, 446],
[50.26, 65.09, 79.99, 109.99, 110, 254.99, 264, 864, 731, 446],
[50.26, 65.42, 79.99, 110, 110, 254.99, 264, 865, 731, 446],
[50.19, 63.63, 80, 109.99, 110, 255, 264, 859, 726, 444],
[50.04, 63.47, 79.99, 110, 110, 255, 263.99, 860, 727, 445],
[50.04, 63.41, 80, 110, 110, 255, 264, 862, 728, 445],
[50.12, 62.98, 80, 110, 110, 255, 263.99, 864, 731, 445],
[50.23, 62.68, 80, 110, 110, 255, 264, 861, 730, 445],
[50.04, 62.31, 80, 110, 110, 255, 264, 853, 721, 442],
[50.19, 63.58, 80, 109.99, 110, 254.99, 263.99, 855, 721, 442],
[50.01, 63.9, 80, 109.99, 110, 255, 264, 858, 724, 443],
[50.04, 64.42, 79.99, 110, 110, 254.99, 263.99, 862, 730, 446],
[50.04, 64.83, 80, 110, 110, 254.99, 263.99, 851, 719, 442],
[50.12, 63.73, 79.99, 110, 110, 254.99, 263.99, 853, 721, 442],
[50.08, 62.84, 80, 109.99, 110, 255, 264, 850, 717, 442],
[50.12, 62.24, 79.99, 110, 110, 254.99, 263.99, 843, 712, 440],
[50.23, 63, 79.99, 109.99, 110, 254.99, 264, 849, 717, 440],
[50.26, 63.1, 79.99, 109.99, 109.99, 255, 263.99, 839, 708, 438],
[50.23, 63.11, 80, 109.99, 110, 255, 264, 855, 721, 443],
[50.41, 63.74, 80, 110, 110, 254.99, 264, 856, 723, 442],
[50.23, 62.6, 79.99, 110, 110, 255, 263.99, 866, 730, 445],
[50.34, 61.85, 80, 109.99, 110, 255, 264, 857, 720, 443],
[50.19, 62.5, 80, 110, 110, 254.99, 263.99, 855, 718, 442],
[50.01, 63.68, 80, 110, 110, 254.99, 264, 846, 715, 440],
[49.9, 63.95, 79.99, 110, 110, 255, 263.99, 850, 717, 441],
[49.94, 64.03, 80, 110, 110, 255, 264, 849, 719, 441],
[49.9, 64.55, 80, 110, 110, 255, 263.99, 858, 725, 443],
[50.08, 64.04, 80, 110, 109.99, 254.99, 263.99, 855, 721, 442],
[49.97, 63.19, 79.99, 110, 110, 255, 263.99, 852, 716, 441],
[49.9, 62.39, 79.99, 110, 110, 254.99, 264, 835, 708, 437],
[49.86, 63.14, 80, 110, 110, 254.99, 263.99, 853, 719, 441],
[50.01, 62.94, 80, 110, 110, 255, 263.99, 857, 722, 444],
[49.9, 62.5, 80, 110, 110, 254.99, 263.99, 868, 731, 446],
[49.94, 62.24, 79.99, 110, 109.99, 255, 264, 868, 731, 446],
[49.94, 63.04, 79.99, 110, 110, 254.99, 264, 865, 731, 444],
[50.08, 64.38, 79.99, 110, 110, 254.99, 264, 853, 718, 441],
[49.94, 64.57, 79.99, 110, 110, 254.99, 264, 852, 720, 440],
[49.9, 63.55, 79.99, 110, 110, 255, 263.99, 863, 729, 444],
[50.12, 63.6, 79.99, 110, 110, 254.99, 264, 871, 733, 445],
[49.97, 63.61, 80, 110, 110, 255, 264, 867, 730, 444],
[49.94, 63.45, 80, 110, 109.99, 254.99, 264, 855, 721, 441],
[49.83, 64.78, 80, 109.99, 110, 255, 264, 846, 715, 439],
[50.04, 64.4, 80, 110, 110, 255, 264, 849, 715, 440],
[50.01, 64.48, 80, 110, 110, 255, 263.99, 861, 730, 443],
[49.65, 64.46, 80, 110, 110, 255, 263.99, 852, 721, 441],
[49.47, 64.44, 80, 110, 110, 254.99, 264.01, 863, 728, 443],
[49.47, 63.98, 80, 110, 110, 255, 264, 870, 732, 445],
[49.29, 63.75, 80, 110, 110, 254.99, 263.99, 866, 731, 444],
[49.18, 63.78, 80, 110, 109.99, 254.99, 263.99, 865, 731, 443],
[49.18, 63.92, 80, 109.99, 110, 255, 264, 865, 733, 444],
[49.07, 63.67, 79.99, 110, 110, 255, 264, 872, 734, 445],
[49.21, 64.12, 79.99, 110, 110, 254.99, 264, 868, 734, 444],
[49.04, 64.5, 79.99, 110, 110, 254.99, 264, 865, 733, 443],
[49.07, 64.08, 80, 110, 110, 254.99, 263.99, 864, 729, 442],
[49.04, 64.05, 79.99, 110, 110, 255, 264, 868, 729, 443],
[49.21, 66.39, 79.99, 110, 110, 255, 263.99, 863, 729, 442],
[49.04, 66.62, 79.99, 110, 110, 255, 263.99, 865, 731, 443],
[49.11, 65.83, 79.99, 110, 109.99, 254.99, 263.99, 862, 730, 442],
[48.93, 65.45, 79.99, 109.99, 110, 255, 262.8, 867, 723, 441],
[48.96, 64.52, 79.99, 109.99, 110, 255, 264, 855, 721, 441],
[48.82, 63.42, 79.99, 110, 110, 255, 263.99, 856, 724, 441],
[48.79, 62.79, 80, 110, 110, 255, 263.99, 857, 727, 441],
[48.72, 62.41, 80, 110, 110, 254.99, 263.99, 848, 716, 437],
[48.61, 61.55, 79.99, 110, 110, 255, 262.34, 865, 718, 439],
[48.79, 61.05, 80, 109.99, 110, 254.99, 264, 860, 727, 441],
[48.5, 61.45, 80, 110, 110, 255, 264, 861, 725, 442],
[48.43, 60.74, 80, 110, 110, 255, 263.99, 860, 724, 442],
[48.64, 60.35, 80, 110, 110, 254.99, 263.99, 863, 726, 442],
[48.4, 60.12, 79.99, 109.99, 110, 254.99, 263.99, 866, 730, 442],
[48.36, 59.97, 80, 110, 110, 255, 263.99, 861, 724, 442],
[48.36, 59.87, 80, 109.99, 109.99, 254.99, 263.99, 861, 726, 441],
[48.26, 59.97, 79.99, 109.99, 110, 255, 263.99, 868, 734, 443],
[48.12, 60.55, 79.99, 110, 110, 255, 264, 871, 733, 444],
[48.19, 61.45, 80, 109.99, 109.99, 255, 264, 870, 734, 444],
[47.98, 63.11, 80, 109.99, 110, 254.99, 264, 869, 734, 444],
[47.8, 63.38, 80, 110, 110, 255, 263.99, 868, 735, 444],
[47.59, 63.92, 79.99, 109.99, 110, 254.99, 264, 864, 731, 443],
[47.45, 63.55, 79.99, 110, 110, 254.99, 264, 863, 731, 443],
[47.07, 64.92, 79.99, 110, 110, 254.99, 264, 863, 733, 443],
[46.83, 65.1, 80, 110, 110, 254.99, 264, 868, 737, 443],
[46.63, 65, 79.99, 110, 109.99, 255, 263.99, 874, 741, 444],
[46.66, 64.63, 80, 110, 110, 255, 264, 866, 738, 442],
[46.32, 66.22, 79.99, 110, 109.99, 254.99, 264, 863, 734, 442],
[45.65, 65.57, 80, 110, 110, 254.99, 264, 850, 724, 439],
[45.55, 64.83, 80, 110, 110, 254.99, 263.99, 847, 719, 438],
[45.38, 64.31, 79.99, 110, 110, 255, 263.99, 841, 718, 436],
[45.35, 63.82, 79.99, 110, 110, 254.99, 264, 847, 718, 437],
[45.28, 62.89, 80, 110, 110, 255, 264, 851, 724, 438],
[45.22, 61.96, 80, 110, 109.99, 255, 264, 854, 731, 439],
[45.18, 62.53, 80, 110, 110, 254.99, 263.99, 857, 727, 439],
[45.18, 62.95, 79.99, 110, 109.99, 255, 263.99, 850, 724, 437],
[45.28, 64.92, 80, 109.99, 110, 255, 264, 846, 725, 437],
[45.28, 64.2, 79.99, 110, 110, 254.99, 263.99, 847, 723, 438],
[45.25, 64.35, 80, 110, 110, 254.99, 263.99, 869, 726, 439],
[45.31, 64.69, 79.99, 110, 110, 255, 263.99, 863, 738, 441],
[45.38, 67.55, 79.99, 110, 109.99, 255, 263.99, 838, 711, 435],
[45.62, 65.26, 80, 110, 110, 254.99, 263.99, 871, 724, 439],
[45.52, 63.41, 80, 110, 110, 254.99, 264, 860, 734, 441],
[45.58, 63.41, 80, 110, 110, 254.99, 263.99, 860, 734, 441],
[45.55, 64.95, 79.99, 110, 110, 254.99, 264, 861, 738, 442],
[45.58, 65, 80, 110, 109.99, 254.99, 263.99, 855, 733, 440],
[45.72, 63.93, 80, 110, 110, 254.99, 263.98, 856, 731, 441],
[45.72, 63.2, 80, 110, 110, 254.99, 264, 864, 736, 442],
[45.82, 62.88, 80, 109.99, 110, 255, 263.99, 860, 736, 442],
[45.99, 63.3, 80, 110, 110, 255, 264, 862, 736, 442],
[46.46, 63.33, 80, 110, 110, 255, 263.98, 856, 734, 439],
[46.87, 64.63, 80, 110, 110, 254.99, 263.99, 861, 739, 442],
[46.94, 64.46, 79.99, 110, 109.99, 255, 263.99, 852, 731, 441],
[46.94, 64.97, 80, 110, 109.99, 254.99, 264, 846, 726, 439],
[46.94, 63.93, 79.99, 110, 110, 255, 263.99, 863, 741, 442],
[47.07, 63.11, 79.99, 110, 110, 255.02, 263.88, 853, 730, 441],
[46.9, 63.08, 80, 110, 110, 254.99, 263.99, 854, 732, 442],
[46.94, 63.04, 79.99, 110, 110, 254.99, 263.99, 857, 731, 442],
[46.77, 62.4, 80, 110, 109.99, 254.99, 263.99, 858, 732, 442],
[46.6, 61.33, 80, 110, 110, 255, 264, 848, 722, 439],
[46.87, 61.36, 80, 110, 110, 255.18, 263.19, 860, 733, 442],
[47.07, 61.2, 79.99, 110, 110, 254.99, 263.99, 857, 729, 441],
[47.18, 61.04, 79.99, 110, 110, 255, 264, 858, 732, 442],
[47.07, 61.04, 79.99, 109.99, 110, 254.99, 264, 854, 728, 441],
[47.11, 60.89, 80, 110, 110, 255, 263.99, 858, 731, 442],
[47.42, 61.83, 79.99, 109.99, 110, 254.99, 264, 856, 730, 440],
[47.18, 61.28, 80, 110, 110, 254.99, 264, 852, 728, 441],
[47.42, 61.43, 79.99, 110, 109.99, 255, 264, 860, 733, 443],
[47.52, 61.18, 79.99, 110, 110, 255, 263.99, 858, 731, 442],
[47.59, 61.55, 79.99, 110, 110, 254.99, 264, 859, 733, 442],
[47.63, 62.44, 80, 109.99, 109.99, 255, 264, 843, 717, 438],
[47.66, 63.34, 80, 110, 110, 254.99, 263.99, 847, 717, 439],
[47.87, 62.53, 79.99, 110, 110, 254.99, 263.99, 862, 732, 443],
[47.84, 62.19, 80, 110, 110, 254.99, 264, 864, 737, 443],
[47.84, 62.98, 79.99, 109.99, 110, 255, 264, 865, 738, 444],
[47.94, 63.37, 80, 110, 110, 255, 264, 860, 732, 443],
[47.94, 63.8, 79.99, 109.99, 110, 255, 264, 859, 729, 443],
[48.15, 63.33, 80, 110, 110, 254.99, 263.99, 859, 732, 443],
[48.29, 62.62, 79.99, 110, 110, 255, 263.99, 863, 736, 443],
[48.19, 63.11, 79.99, 110, 110, 254.99, 264, 871, 740, 445],
[48.4, 62.5, 80, 110, 110, 254.99, 264, 870, 741, 444],
[48.43, 63.36, 79.99, 110, 110, 254.99, 263.99, 861, 731, 442],
[48.57, 63.44, 79.99, 110, 110, 255, 263.99, 863, 729, 443],
[48.82, 63.38, 80, 110, 109.99, 255, 263.99, 862, 731, 443],
[48.79, 63.4, 79.99, 110, 110, 255, 264, 862, 731, 441],
[48.86, 62.66, 79.99, 110, 110, 254.99, 264, 865, 740, 444],
[48.93, 61.86, 80, 110, 110, 255, 264, 862, 736, 443],
[49.07, 62.78, 80, 110, 110, 255, 264, 870, 741, 445],
[49.18, 63.16, 79.99, 109.99, 110, 254.99, 263.99, 867, 733, 444],
[49.18, 62.7, 80, 110, 110, 255, 264, 862, 734, 444],
[49.43, 63.44, 79.99, 109.99, 109.99, 254.99, 264, 861, 731, 444],
[49.86, 62.93, 80, 110, 109.99, 255, 263.99, 861, 730, 444],
[49.68, 63.27, 79.99, 110, 110, 255, 264, 861, 730, 443],
[49.68, 62.42, 79.99, 109.99, 109.99, 255, 264, 855, 725, 442],
[49.86, 62.19, 80, 109.99, 109.99, 255, 263.99, 857, 726, 442],
[49.94, 63.83, 79.99, 110, 110, 254.99, 264, 854, 724, 441],
[50.04, 63.96, 80, 110, 110, 255, 264, 851, 721, 441],
[50.19, 64.17, 79.99, 110, 110, 254.99, 263.99, 852, 724, 442],
[50.19, 63.48, 79.99, 110, 110, 254.99, 264, 857, 730, 442],
[50.37, 63.38, 80, 109.99, 110, 255, 264, 851, 720, 440],
[50.45, 63.11, 79.99, 110, 110, 254.99, 264, 841, 714, 438],
[50.56, 62.88, 80, 110, 110, 254.99, 263.99, 843, 717, 438],
[50.7, 63.21, 80, 110, 109.99, 254.99, 263.99, 846, 715, 440],
[50.63, 64.27, 80, 110, 110, 254.99, 264, 851, 722, 441],
[50.63, 64.72, 80, 109.99, 109.99, 255, 263.99, 878, 745, 447],
[51.11, 64.68, 79.99, 110, 110, 254.98, 264, 867, 732, 446],
[50.96, 63.93, 80, 110, 109.99, 255, 263.99, 860, 733, 443],
[51, 64.04, 80, 110, 110, 254.99, 264, 848, 722, 440],
[51.04, 64.18, 80, 110, 110, 254.99, 264, 853, 723, 442],
[51.04, 63.3, 80, 110, 110, 255, 263.99, 868, 734, 446],
[51.19, 63.11, 80, 110, 110, 254.97, 264, 868, 735, 445],
[51.15, 63.06, 79.99, 110, 109.99, 254.99, 264, 865, 733, 445],
[51.22, 62.72, 79.99, 109.99, 110, 254.99, 264, 854, 724, 442],
[51.3, 63.22, 79.99, 110, 109.99, 254.99, 263.99, 853, 724, 441],
[51.37, 64.09, 80, 110, 110, 255, 264, 855, 727, 443],
[51.37, 65.21, 79.99, 110, 110, 254.97, 264, 857, 725, 444],
[51.48, 64.38, 79.99, 110, 110, 255, 263.99, 863, 730, 445],
[51.9, 63.56, 79.99, 110, 110, 255, 264, 861, 730, 445],
[51.6, 63.22, 79.99, 109.99, 109.99, 254.99, 263.99, 852, 720, 442],
[51.63, 62.38, 79.99, 110, 110, 254.99, 263.99, 845, 715, 440],
[51.6, 62.76, 80, 110, 109.99, 254.97, 264, 842, 713, 440],
[51.26, 62.33, 79.99, 110, 109.99, 255, 263.99, 855, 721, 442],
[51.67, 63.94, 79.99, 110, 109.99, 254.99, 263.99, 858, 727, 444],
[51.78, 64, 79.99, 110, 110, 255, 264, 866, 732, 445],
[51.82, 65.09, 80, 110, 110, 255, 263.99, 857, 724, 444],
[51.67, 65.21, 80, 110, 110, 255.13, 264, 859, 726, 442],
[51.75, 64.75, 80, 110, 110, 255, 263.99, 857, 724, 444],
[51.67, 63.96, 79.99, 110, 110, 255, 263.99, 862, 731, 444],
[51.71, 65.19, 79.99, 109.99, 110, 254.99, 264, 865, 727, 444],
[51.71, 64.84, 80, 110, 110, 254.99, 263.99, 864, 731, 445],
[51.86, 63.93, 80, 110, 110, 255.16, 264, 873, 739, 446],
[51.67, 64.41, 79.99, 109.99, 110, 255, 264, 868, 733, 448],
[51.63, 63.94, 79.99, 110, 110, 255, 263.99, 848, 716, 441],
[51.9, 63.48, 80, 110, 110, 255, 263.99, 855, 725, 442],
[51.56, 63.4, 80, 109.99, 110, 254.99, 263.99, 856, 723, 442],
[51.52, 62.78, 80, 110, 110, 255, 263.99, 878, 724, 443],
[51.45, 63.2, 80, 110, 109.99, 255, 264, 857, 724, 443],
[51.45, 64.18, 79.99, 110, 110, 255, 263.99, 851, 718, 441],
[51.63, 63.65, 80, 110, 110, 254.99, 264, 842, 713, 439],
[51.3, 63.85, 80, 110, 110, 255, 263.99, 849, 717, 440],
[51.19, 64, 80, 110, 109.99, 255, 264, 859, 732, 445],
[51.19, 63.58, 80, 110, 110, 254.99, 264, 862, 729, 445],
[51.11, 63.04, 80, 110, 110, 254.99, 264, 858, 725, 444],
[50.93, 63.27, 80, 110, 109.99, 254.99, 264, 860, 724, 444],
[50.81, 63.48, 79.99, 109.99, 110, 255, 264, 853, 724, 443],
[50.67, 63.54, 79.99, 110, 110, 255, 264, 858, 723, 444],
[50.37, 62.99, 80, 110, 110, 255, 264, 859, 728, 444],
[50.7, 62.9, 80, 110, 109.99, 255, 264, 861, 731, 445],
[50.34, 63.1, 79.99, 110, 110, 255, 264, 854, 722, 442],
[50.19, 63.22, 80, 110, 110, 255, 263.99, 857, 723, 443],
[50.3, 62.52, 79.99, 110, 110, 255, 264, 859, 726, 443],
[50.04, 62.58, 79.99, 110, 110, 254.99, 264, 862, 730, 445],
[50.01, 62.7, 79.99, 109.99, 109.99, 254.99, 263.99, 860, 728, 444],
[49.94, 62.44, 79.99, 109.99, 110, 254.99, 263.99, 857, 725, 444],
[49.72, 62.18, 80, 110, 110, 254.99, 263.99, 852, 721, 441],
[49.97, 62.83, 80, 110, 110, 255, 264, 847, 716, 440],
[49.54, 63.19, 79.99, 110, 110, 255, 264, 873, 736, 448],
[49.14, 63.44, 79.99, 110, 110, 255, 263.99, 858, 728, 444],
[49.04, 62.9, 79.99, 109.99, 110, 255, 263.99, 860, 729, 445],
[48.86, 62.41, 80, 110, 109.99, 255, 264, 844, 718, 439],
[48.47, 63.06, 80, 109.99, 109.99, 254.99, 263.99, 850, 722, 441],
[48.33, 63.15, 80, 109.99, 110, 254.99, 264, 848, 719, 439],
[48.12, 62.1, 80, 110, 110, 255, 264, 856, 730, 442],
[48.05, 63.03, 80, 110, 110, 255, 263.99, 859, 728, 443],
[48.15, 63.74, 80, 110, 110, 254.99, 264, 855, 725, 441],
[47.73, 63.24, 79.99, 110, 110, 255, 263.99, 857, 728, 442],
[47.7, 62.97, 80, 109.99, 110, 254.99, 264, 864, 730, 442],
[47.59, 62.47, 80, 110, 110, 254.99, 263.99, 874, 740, 445],
[47.35, 61.65, 79.99, 110, 110, 255, 263.99, 870, 740, 445],
[47.18, 62.45, 79.99, 110, 110, 255, 264, 866, 737, 444],
[47.11, 62.77, 80, 110, 110, 255, 264, 871, 737, 444],
[47.01, 62.43, 79.99, 109.99, 110, 255, 264, 871, 739, 444],
[46.7, 62.46, 79.99, 110, 110, 254.99, 264, 870, 737, 444],
[46.19, 61.96, 79.99, 110, 110, 255, 263.99, 861, 732, 442],
[46.05, 62.82, 79.99, 110, 109.99, 255, 264, 864, 735, 441],
[45.82, 63.15, 79.99, 110, 109.99, 254.99, 263.99, 866, 737, 442],
[45.55, 62.5, 79.99, 110, 109.99, 254.99, 263.99, 866, 736, 442],
[45.65, 62.05, 79.99, 109.99, 110, 254.99, 263.99, 859, 734, 441],
[45.31, 61.43, 80, 110, 110, 255, 264, 860, 730, 440],
[45.15, 61.94, 79.99, 109.99, 110, 254.99, 263.99, 860, 731, 441],
[44.92, 61.85, 80, 109.99, 110, 255, 263.99, 857, 733, 440],
[44.59, 63.37, 80, 110, 109.99, 255, 264, 866, 739, 441],
[44.49, 63.95, 80, 110, 110, 254.99, 264, 862, 739, 441],
[44.26, 64.83, 79.99, 110, 110, 254.99, 264, 866, 739, 441],
[43.93, 65.63, 79.99, 110, 110, 254.99, 263.99, 862, 738, 441],
[43.8, 64.79, 79.99, 110, 110, 254.99, 264, 856, 729, 439],
[43.74, 64.54, 80, 110, 110, 254.99, 263.99, 847, 723, 437],
[43.8, 64.73, 79.99, 109.99, 109.99, 255, 264, 854, 731, 439],
[43.74, 64.78, 80, 110, 110, 254.99, 263.99, 858, 738, 440],
[43.64, 64.96, 80, 110, 109.99, 255, 264, 861, 732, 441],
[43.54, 63.19, 80, 110, 109.99, 254.99, 263.99, 852, 730, 438],
[43.54, 63.64, 80, 109.99, 110, 255, 263.99, 852, 726, 438],
[43.38, 65.01, 79.99, 109.99, 109.99, 255, 264, 848, 733, 439],
[43.25, 64.75, 80, 110, 109.99, 255, 264, 859, 730, 440],
[43.32, 65.39, 80, 110, 109.99, 254.98, 264, 865, 741, 441],
[43.38, 64.7, 80, 110, 109.99, 255, 264, 862, 737, 441],
[43.58, 64.73, 79.99, 110, 110, 255, 263.91, 875, 739, 441],
[43.38, 64.29, 80, 110, 109.99, 254.99, 264, 864, 736, 440],
[43.51, 65.07, 80, 110, 109.99, 255, 264, 861, 742, 440],
[43.45, 64.47, 80, 110, 110, 255, 264, 863, 736, 441],
[43.87, 67.28, 79.99, 110, 110, 255, 264, 868, 739, 442],
[43.61, 67.06, 79.99, 109.99, 110, 254.99, 263.35, 867, 740, 441],
[43.74, 65.39, 79.99, 110, 110, 255, 263.99, 870, 742, 442],
[43.67, 64.03, 79.99, 110, 110, 255, 263.99, 864, 740, 442],
[43.64, 63.05, 80, 110, 110, 255, 264, 864, 737, 441],
[43.74, 63.33, 80, 110, 110, 255, 264, 866, 741, 441],
[43.87, 64.97, 79.99, 110, 110, 255, 260.54, 886, 737, 442],
[43.93, 64.61, 80, 109.99, 110, 255, 264, 865, 742, 441],
[43.77, 64.16, 79.99, 110, 110, 255, 263.99, 859, 740, 441],
[43.71, 64.46, 80, 110, 110, 255, 264, 861, 740, 441],
[43.67, 65.62, 79.99, 110, 110, 254.99, 263.99, 863, 742, 441],
[43.87, 66.25, 79.99, 110, 109.99, 254.99, 264, 883, 740, 441],
[43.58, 66.51, 79.99, 110, 110, 255, 263.99, 862, 736, 441],
[43.58, 65.82, 79.99, 110, 109.99, 255, 264, 862, 739, 441],
[43.64, 65.91, 80, 110, 110, 255, 264, 859, 737, 440],
[43.58, 65.65, 79.99, 110, 110, 254.99, 264, 859, 735, 440],
[43.45, 64.35, 80, 109.99, 110, 255, 263.99, 855, 733, 439],
[43.45, 64.15, 80, 110, 110, 255, 264, 853, 732, 439],
[43.35, 63.91, 80, 110, 110, 254.99, 264, 861, 734, 441],
[43.32, 64.15, 80, 110, 109.99, 254.99, 264, 865, 736, 441],
[43.35, 64.93, 80, 110, 110, 254.99, 263.99, 863, 735, 441],
[43.22, 64.67, 79.99, 109.99, 109.99, 255, 264, 864, 742, 441],
[43.19, 64.69, 79.99, 109.99, 109.99, 255, 264, 848, 725, 438],
[43.19, 64.47, 80, 109.99, 110, 254.99, 264, 848, 724, 437],
[43.03, 64.81, 80, 110, 110, 255, 263.99, 854, 733, 439],
[43.03, 63.97, 79.99, 110, 110, 254.99, 263.99, 862, 740, 441],
[43.09, 63.16, 80, 109.99, 110, 255, 264, 858, 736, 439],
[43.03, 63.83, 79.99, 110, 110, 255, 263.99, 843, 721, 436],
[43.03, 63.21, 79.99, 110, 110, 254.99, 264, 850, 729, 438],
[43.25, 63.56, 80, 110, 110, 255, 263.99, 864, 739, 441],
[43.25, 63.42, 80, 110, 109.99, 255, 263.99, 857, 731, 440],
[43.16, 62.78, 79.99, 110, 110, 254.99, 263.99, 855, 738, 439],
[43.22, 62.74, 79.99, 109.99, 110, 254.99, 263.99, 859, 734, 440],
[43.42, 62.01, 80, 110, 110, 255, 263.99, 862, 737, 440],
[43.03, 63, 79.99, 110, 109.99, 254.99, 263.99, 863, 733, 441],
[43.09, 64.61, 79.99, 109.99, 109.99, 255, 263.99, 864, 739, 441],
[43.09, 64.94, 79.99, 109.99, 110, 254.99, 264, 856, 734, 440],
[42.9, 65.07, 79.99, 109.99, 110, 255, 264, 857, 733, 440],
[42.9, 65.5, 79.99, 110, 110, 254.99, 263.99, 859, 739, 441],
[42.84, 64.89, 80, 110, 110, 254.99, 263.99, 859, 735, 440],
[42.81, 65.27, 80, 110, 109.99, 255, 263.99, 851, 725, 438],
[42.77, 66.17, 79.99, 110, 110, 255, 263.99, 847, 731, 438],
[42.77, 65.29, 80, 110, 110, 254.99, 263.99, 855, 732, 440],
[42.97, 64.97, 79.99, 110, 110, 255, 264, 861, 739, 441],
[42.9, 64.81, 80, 110, 110, 254.99, 263.99, 861, 743, 441],
[43.03, 64.83, 80, 110, 110, 255, 263.99, 864, 742, 441],
[43.03, 64.43, 80, 110, 109.99, 254.99, 263.99, 861, 740, 441],
[43, 64.89, 79.99, 110, 110, 255, 264, 860, 740, 441],
[43.13, 64.89, 80, 110, 110, 255, 263.99, 858, 738, 440],
[43.32, 65.35, 80, 110, 110, 254.99, 264, 865, 739, 441],
[43.16, 65.2, 79.99, 110, 110, 254.99, 263.99, 853, 732, 439],
[43.16, 64.36, 79.99, 110, 110, 255, 263.99, 862, 732, 440],
[43.19, 64.91, 80, 110, 110, 255, 264, 855, 733, 440],
[43.03, 66.22, 79.99, 110, 110, 254.99, 263.99, 857, 734, 440],
[43.03, 65.65, 79.99, 110, 110, 254.99, 264, 860, 732, 441],
[42.93, 65.23, 79.99, 110, 110, 255, 264, 852, 730, 439],
[42.74, 67.55, 80, 110, 110, 255, 263.99, 856, 734, 440],
[42.93, 67.08, 79.99, 110, 110, 254.99, 263.99, 860, 733, 440],
[42.77, 65.84, 79.99, 110, 110, 255, 264, 852, 733, 439],
[42.71, 64.06, 79.99, 110, 110, 254.99, 263.99, 861, 738, 441],
[42.68, 64.08, 80, 110, 109.99, 254.99, 264, 851, 727, 438],
[42.68, 64.57, 79.99, 110, 109.99, 255, 264, 853, 737, 440],
[42.71, 63.97, 79.99, 110, 110, 255, 263.99, 912, 734, 440],
[42.68, 63.63, 79.99, 110, 109.99, 254.99, 264, 864, 739, 441],
[42.74, 63.85, 79.99, 109.99, 110, 255, 263.99, 858, 735, 440],
[42.68, 64.52, 79.99, 110, 110, 255, 264, 861, 739, 440],
[42.39, 64.79, 79.99, 110, 109.99, 255, 263.99, 849, 723, 437],
[42.68, 64.32, 79.99, 110, 110, 254.99, 263.96, 840, 720, 438],
[42.62, 63.33, 79.99, 109.99, 109.99, 255, 264, 851, 729, 439],
[42.55, 63.02, 79.99, 110, 110, 254.99, 263.99, 848, 725, 438],
[42.74, 63.47, 80, 109.99, 110, 255, 263.99, 855, 739, 440],
[42.74, 64.67, 80, 110, 110, 255, 263.99, 859, 739, 441],
[42.55, 64.57, 80, 110, 109.99, 255, 263.88, 837, 718, 436],
[42.49, 64.93, 79.99, 110, 110, 254.99, 264, 834, 716, 435],
[42.52, 65.15, 80, 110, 110, 254.99, 264, 842, 722, 437],
[42.71, 65.71, 79.99, 110, 110, 255, 264, 852, 732, 439],
[42.42, 65.13, 79.99, 110, 109.99, 254.99, 263.99, 857, 734, 440],
[42.52, 63.72, 80, 110, 109.99, 255.01, 263.81, 860, 739, 441],
[42.65, 66.45, 79.99, 110, 110, 255, 263.99, 851, 730, 438],
[42.46, 66.33, 79.99, 110, 110, 255, 264, 840, 723, 436],
[42.46, 65.51, 79.99, 110, 110, 254.99, 263.99, 853, 735, 438],
[42.36, 65.26, 80, 110, 109.99, 254.99, 264, 855, 732, 439],
[42.39, 64.72, 80, 109.99, 109.99, 255.25, 263.01, 864, 741, 440],
[42.39, 65.85, 80, 110, 110, 254.99, 263.99, 866, 741, 442],
[42.36, 66.67, 80, 110, 110, 254.99, 263.99, 864, 743, 442],
[42.33, 65.5, 79.99, 110, 110, 255, 264, 863, 740, 441],
[42.33, 65.88, 79.99, 109.99, 110, 255, 264, 860, 738, 441],
[42.3, 66.46, 79.99, 110, 109.99, 255.31, 278.39, 862, 744, 442],
[42.27, 67.14, 79.99, 110, 110, 255, 264, 862, 740, 441],
[41.98, 67.95, 80, 109.99, 110, 254.99, 263.99, 859, 743, 441],
[42.05, 67.56, 80, 110, 110, 255, 263.99, 867, 742, 442],
[42.05, 66.98, 80, 109.99, 110, 254.99, 264, 859, 735, 441],
[41.95, 66.06, 80, 110, 110, 255, 264, 862, 739, 441],
[41.92, 65.58, 79.99, 110, 110, 255, 264, 866, 741, 442],
[41.92, 64.36, 79.99, 109.99, 110, 254.99, 264, 868, 743, 442],
[41.79, 63.42, 79.99, 110, 110, 254.99, 264, 860, 736, 441],
[41.89, 63.14, 79.99, 110, 109.99, 255, 264, 854, 736, 440],
[41.73, 63.34, 79.99, 110, 109.99, 254.99, 263.99, 854, 732, 440],
[41.83, 62.51, 80, 110, 110, 254.99, 263.99, 855, 731, 440],
[41.7, 62.64, 80, 110, 110, 255, 264, 857, 734, 440],
[41.86, 62.92, 79.99, 110, 109.99, 255, 263.99, 857, 732, 440],
[41.58, 62.99, 79.99, 110, 110, 255, 264, 853, 726, 439],
[41.48, 62.39, 80, 110, 110, 255, 264, 855, 732, 439],
[41.42, 63.65, 79.99, 109.99, 110, 254.99, 264, 841, 721, 437],
[41.42, 63.52, 79.99, 109.99, 110, 255, 263.99, 841, 723, 438],
[41.45, 63.55, 80, 109.99, 109.99, 255, 264, 835, 718, 436],
[41.42, 64, 80, 110, 110, 255, 264, 837, 720, 437],
[41.39, 64.54, 79.99, 110, 110, 254.99, 264, 843, 726, 438],
[41.58, 66.52, 79.99, 110, 110, 255, 264, 831, 714, 436],
[41.36, 65.41, 80, 110, 110, 255, 263.99, 835, 716, 436],
[41.36, 64.18, 79.99, 110, 110, 255, 264, 835, 715, 436],
[41.42, 63.64, 79.99, 110, 110, 255, 264, 838, 718, 437],
[41.45, 63.34, 79.99, 110, 110, 254.99, 264, 838, 719, 437],
[41.42, 62.69, 80, 110, 110, 254.99, 263.99, 834, 718, 436],
[41.39, 63.85, 79.99, 110, 110, 254.99, 263.99, 833, 716, 435],
[41.64, 63.53, 80, 110, 110, 254.99, 264, 833, 717, 435],
[41.54, 65.64, 80, 109.99, 110, 255, 264, 831, 709, 435],
[41.64, 65.76, 79.99, 110, 110, 254.99, 263.99, 833, 716, 436],
[41.67, 65.59, 80, 110, 109.99, 255, 263.99, 838, 719, 437],
[41.89, 64.89, 80, 110, 110, 255, 264, 841, 730, 438],
[42.01, 67.32, 79.99, 110, 110, 255, 264, 845, 730, 439],
[42.14, 66.87, 79.99, 109.99, 110, 255, 263.99, 850, 737, 440],
[42.33, 66.71, 80, 110, 110, 254.99, 264, 850, 732, 439],
[42.36, 66.31, 80, 110, 109.99, 254.99, 263.99, 848, 732, 438],
[42.46, 66.49, 80, 110, 109.99, 255, 264, 857, 736, 441],
[42.49, 66.21, 80, 110, 109.99, 254.99, 264, 855, 737, 441],
[42.74, 65.64, 79.99, 110, 109.99, 254.99, 263.99, 857, 743, 441],
[42.58, 65.89, 79.99, 110, 110, 254.99, 264, 853, 739, 439],
[42.84, 65.76, 79.99, 110, 110, 255, 264, 843, 730, 438],
[42.68, 66.58, 79.99, 110, 110, 255, 264, 841, 719, 436],
[42.74, 66.33, 80, 109.99, 110, 254.99, 264, 839, 723, 436],
[43, 65.93, 80, 110, 110, 255, 264, 839, 721, 436],
[43.03, 63.93, 80, 109.99, 110, 254.99, 263.99, 843, 725, 437],
[43.32, 64.64, 79.99, 110, 110, 254.99, 264, 831, 715, 434],
[43.16, 64.2, 80, 110, 110, 254.99, 263.99, 830, 716, 434],
[43.35, 63.33, 79.99, 110, 110, 255, 264, 839, 721, 435],
[43.51, 62.94, 79.99, 110, 109.99, 255, 263.99, 849, 727, 438],
[43.48, 63.94, 80, 110, 109.99, 254.98, 264, 842, 723, 437],
[43.35, 66.41, 79.99, 110, 110, 255, 264, 840, 720, 436],
[43.45, 66.62, 79.99, 110, 110, 254.99, 264, 832, 715, 435],
[43.48, 65.58, 80, 110, 110, 255, 264, 835, 716, 435],
[43.54, 65.07, 80, 110, 109.99, 254.99, 263.99, 847, 725, 438],
[43.84, 67.77, 79.99, 109.99, 110, 254.97, 264, 854, 731, 439],
[43.64, 68.54, 79.99, 110, 110, 255, 264, 844, 726, 437],
[43.84, 66.81, 80, 110, 109.99, 255, 263.99, 840, 716, 435],
[44, 64.4, 80, 110, 110, 255, 264, 836, 718, 435],
[44.03, 64.52, 80, 109.99, 110, 254.99, 264, 841, 722, 436],
[44.16, 63.79, 80, 110, 110, 255.19, 264, 846, 717, 437],
[44.23, 64.03, 79.99, 110, 109.99, 255, 263.99, 858, 738, 440],
[44.49, 63.48, 79.99, 109.99, 110, 255, 264, 859, 732, 440],
[44.39, 63.47, 80, 110, 110, 255, 264, 850, 728, 438],
[44.32, 64.23, 80, 110, 109.99, 255, 264, 837, 717, 434],
[44.46, 65.09, 79.99, 110, 109.99, 255.15, 264, 847, 719, 435],
[44.52, 66.17, 79.99, 110, 110, 255, 264, 849, 722, 438],
[44.78, 64.81, 79.99, 110, 110, 255, 263.99, 864, 740, 441],
[44.69, 64.27, 79.99, 110, 109.99, 255, 263.99, 861, 734, 440],
[44.92, 62.97, 79.99, 110, 110, 255, 263.99, 862, 735, 440],
[44.92, 63.15, 80, 110, 110, 254.99, 264, 855, 729, 439],
[44.98, 63.98, 80, 110, 110, 254.99, 264, 856, 734, 440],
[45.08, 63.88, 79.99, 110, 110, 255, 264, 869, 747, 442],
[45.22, 63.85, 79.99, 110, 110, 255, 264.01, 864, 733, 441],
[45.15, 64.1, 80, 110, 110, 255, 264, 863, 739, 441],
[45.25, 63.63, 79.99, 110, 110, 255, 263.99, 852, 730, 439],
[45.38, 63.34, 80, 110, 110, 255, 264, 853, 727, 439],
[45.58, 63.55, 80, 110, 110, 254.99, 264, 855, 732, 439],
[45.41, 63.39, 79.99, 109.99, 110, 254.99, 264, 863, 738, 441],
[45.58, 63.61, 79.99, 110, 109.99, 254.99, 264, 866, 736, 441],
[45.38, 63.41, 79.99, 110, 110, 255, 264, 856, 730, 440],
[45.45, 64.49, 80, 110, 110, 254.99, 263.99, 856, 733, 440],
[45.58, 65.49, 79.99, 110, 110, 255, 263.99, 858, 734, 441],
[45.62, 66.46, 79.99, 109.99, 109.99, 254.99, 264, 868, 741, 441],
[45.62, 66.71, 80.01, 110, 110, 254.99, 264, 864, 743, 441],
[45.62, 69.15, 79.99, 110, 110, 254.99, 263.99, 861, 735, 440],
[45.82, 68.84, 80, 110, 110, 255, 263.99, 852, 731, 439],
[45.48, 67.35, 79.99, 109.99, 110, 254.99, 263.99, 846, 725, 438],
[45.55, 65.96, 80, 110, 110, 255, 264, 843, 730, 437],
[45.48, 65.08, 80, 110, 110, 254.99, 264, 853, 728, 439],
[45.45, 64.1, 80, 110, 109.99, 255, 264, 853, 729, 439],
[45.48, 63.4, 79.99, 110, 110, 254.99, 264, 853, 729, 439],
[45.45, 62.89, 80, 110, 110, 254.99, 263.99, 835, 716, 434],
[45.48, 62.79, 79.99, 110, 110, 254.99, 264, 846, 723, 437],
[45.48, 63.03, 79.99, 110, 110, 254.99, 264, 853, 726, 439],
[45.38, 64.01, 79.99, 110, 109.99, 255, 263.99, 857, 733, 440],
[45.35, 64.27, 80, 110, 110, 254.99, 263.99, 851, 724, 439],
[45.52, 63.86, 79.99, 109.99, 110, 255, 263.99, 856, 731, 439],
[45.45, 63.55, 80, 109.99, 109.99, 255, 264, 851, 725, 438],
[45.45, 63.69, 80, 110, 110, 255, 263.99, 854, 729, 439],
[45.41, 65.01, 79.99, 110, 110, 255, 263.99, 859, 732, 440],
[45.45, 64.83, 80, 110, 110, 255, 264, 860, 731, 440],
[45.41, 65.07, 79.99, 109.99, 109.99, 255, 264, 852, 726, 439],
[45.48, 64.45, 79.99, 110, 110, 255, 263.99, 865, 742, 441],
[45.41, 64.05, 80, 110, 110, 255, 264, 872, 739, 441],
[45.52, 63.51, 79.99, 110, 110, 255, 264, 859, 731, 440],
[45.38, 62.54, 80, 109.99, 110, 254.99, 263.99, 855, 733, 439],
[45.48, 62.31, 79.99, 110, 109.99, 254.99, 263.99, 846, 722, 438],
[45.35, 61.24, 80, 110, 110, 255, 264, 843, 717, 436],
[45.45, 61.13, 79.99, 109.99, 110, 255, 263.99, 848, 720, 437],
[45.41, 62.76, 80, 109.99, 110, 255, 263.99, 849, 722, 438],
[45.58, 63.95, 80, 109.99, 109.99, 254.99, 263.99, 847, 722, 436],
[45.45, 62.61, 80, 110, 110, 255, 264, 842, 717, 435],
[45.58, 62.22, 79.99, 110, 110, 255, 264, 843, 719, 436],
[45.45, 62.5, 80, 110, 110, 254.99, 264, 846, 722, 437],
[45.78, 62.71, 80, 110, 110, 254.99, 263.98, 851, 724, 437],
[45.41, 63.94, 79.99, 110, 110, 255, 264, 841, 718, 436],
[45.45, 64.52, 80, 110, 110, 254.99, 264, 842, 717, 435],
[45.48, 64.27, 80, 109.99, 110, 255, 264, 846, 716, 436],
[45.48, 64.02, 80, 109.99, 110, 255, 264, 867, 736, 441],
[45.41, 64.08, 80, 110, 110, 254.99, 264, 857, 731, 439],
[45.52, 64.85, 80, 110, 110, 255, 264, 844, 715, 435],
[45.62, 64.08, 79.99, 109.99, 109.99, 255, 264, 855, 723, 438],
[45.52, 63.72, 80, 110, 109.99, 255, 264.01, 859, 732, 439],
[45.52, 63.2, 79.99, 110, 110, 255, 263.99, 859, 733, 439],
[45.62, 62.89, 79.99, 109.99, 110, 255, 264, 854, 724, 437],
[45.62, 63.93, 79.99, 110, 110, 254.99, 264, 835, 711, 434],
[45.55, 64.98, 79.99, 110, 110, 254.99, 263.99, 855, 727, 438],
[45.72, 64.97, 79.99, 110, 110, 255, 264, 874, 739, 441],
[45.68, 65.08, 80, 110, 110, 255, 264, 877, 743, 442],
[45.58, 64.2, 79.99, 110, 110, 254.99, 264, 866, 736, 440],
[45.52, 63.64, 80, 109.99, 110, 254.99, 263.99, 864, 736, 440],
[45.65, 62.85, 80, 110, 110, 255, 264, 856, 730, 439],
[45.45, 63.34, 80, 109.99, 109.99, 255.01, 263.93, 871, 734, 440],
[45.55, 64.49, 79.99, 109.99, 109.99, 255, 263.99, 865, 738, 441],
[44.98, 64.28, 80, 110, 109.99, 254.99, 264, 860, 734, 440],
[44.95, 64.25, 80, 110, 109.99, 254.99, 264, 857, 731, 439],
[44.95, 65.18, 79.99, 110, 110, 255, 263.94, 849, 723, 437],
[44.88, 65.76, 80, 109.99, 109.99, 255, 264, 846, 723, 436],
[44.82, 65.16, 80, 110, 110, 255, 264, 859, 732, 439],
[44.82, 64.37, 79.99, 110, 110, 254.99, 263.99, 866, 735, 440],
[44.72, 63.9, 79.99, 110, 110, 254.99, 263.99, 857, 729, 438],
[44.69, 64.79, 79.99, 109.99, 110, 255.01, 263.69, 861, 727, 438],
[44.69, 64.71, 80, 110, 110, 255, 263.99, 855, 729, 439],
[44.69, 65.23, 80, 110, 110, 254.99, 264, 860, 734, 440],
[44.75, 65.19, 79.99, 110, 110, 255, 264, 874, 742, 441],
[44.55, 65.43, 80, 110, 110, 254.99, 264, 859, 732, 438],
[44.72, 64.99, 80, 109.99, 110, 255, 263.99, 855, 729, 439],
[44.69, 64.54, 80, 109.99, 110, 254.99, 264, 863, 734, 440],
[44.72, 63.95, 80, 110, 110, 255, 264, 866, 733, 440],
[44.69, 62.55, 80, 109.99, 110, 255, 263.99, 862, 733, 440],
[44.65, 62.94, 79.99, 110, 110, 255, 264, 847, 723, 436],
[44.75, 63.48, 79.99, 110, 110, 254.99, 264, 849, 726, 437],
[44.92, 65.29, 80, 110, 110, 255, 263.99, 864, 733, 440],
[44.92, 65.35, 79.99, 110, 110, 255, 264, 853, 730, 438],
[44.72, 65.49, 80, 109.99, 110, 254.99, 263.99, 848, 725, 437],
[44.75, 65.75, 80, 109.99, 110, 255, 264, 862, 732, 439],
[44.55, 66.63, 79.99, 110, 110, 254.99, 263.99, 864, 734, 440],
[44.46, 66.58, 80, 109.99, 110, 254.99, 263.99, 866, 740, 441],
[44.36, 66.34, 79.99, 110, 110, 254.99, 264, 867, 740, 441],
[44.29, 66.21, 80, 110, 110, 255, 264, 864, 737, 441],
[44.16, 65.25, 80, 110, 110, 254.99, 264, 872, 745, 441],
[44.19, 65.06, 79.99, 109.99, 110, 255, 263.99, 868, 739, 442],
[44.19, 64.7, 80, 110, 110, 254.99, 264, 872, 744, 442],
[44.19, 65, 80, 110, 110, 255, 264, 867, 741, 441],
[44.06, 66.67, 79.99, 109.99, 110, 254.99, 263.99, 865, 741, 441],
[44.13, 67.25, 79.99, 110, 110, 255, 263.99, 854, 730, 439],
[44.23, 67.85, 80, 110, 110, 255, 264, 852, 723, 437],
[44.26, 64.51, 79.99, 110, 110, 255, 264, 864, 738, 440],
[44, 65.27, 80, 109.99, 110, 254.99, 263.99, 865, 738, 441],
[44, 67.2, 79.99, 110, 110, 255, 264, 864, 738, 441],
[43.9, 67.95, 79.99, 109.99, 110, 254.99, 263.99, 861, 738, 440],
[43.77, 67.32, 80, 110, 110, 255, 264, 864, 738, 440],
[43.71, 66.76, 80, 110, 110, 255, 264, 848, 728, 438],
[43.45, 68.6, 80, 109.99, 110, 254.99, 263.99, 844, 725, 436],
[43.35, 67.27, 80, 110, 110, 255, 264, 844, 722, 437],
[43.03, 67.55, 80, 110, 110, 255, 264, 846, 723, 438],
[43.03, 66.78, 80, 110, 110, 254.99, 263.99, 861, 736, 440],
[42.97, 66.47, 80, 110, 110, 254.99, 263.99, 861, 740, 440],
[42.84, 67.96, 79.99, 110, 110, 255, 264, 851, 732, 438],
[42.74, 67.21, 80, 110, 110, 254.99, 264, 851, 733, 439],
[42.93, 66.74, 80, 110, 110, 255, 263.99, 852, 730, 439],
[42.55, 66.28, 80, 110, 110, 255, 263.99, 863, 739, 441],
[42.58, 66.03, 80, 109.99, 110, 254.99, 263.99, 864, 738, 442],
[42.46, 67.25, 80, 109.99, 110, 255, 264, 866, 744, 442],
[42.27, 66.58, 80, 109.99, 109.99, 255, 264, 858, 740, 441],
[42.33, 67.23, 79.99, 110, 110, 255.01, 264, 895, 741, 441],
[42.05, 67.74, 79.99, 109.99, 110, 255, 263.99, 862, 740, 441],
[41.89, 66.97, 79.99, 110, 110, 254.99, 263.99, 863, 740, 442],
[41.67, 66.26, 79.99, 109.99, 110, 254.99, 264, 861, 738, 442],
[41.48, 65.11, 79.99, 110, 110, 254.99, 264, 861, 743, 442],
[41.42, 65.2, 80, 110, 110, 255, 264, 862, 744, 442],
[41.23, 66.2, 80, 110, 110, 255, 264, 865, 749, 443],
[41.51, 66.02, 79.99, 110, 110, 254.98, 263.99, 863, 743, 442],
[41.2, 67.1, 79.99, 109.99, 110, 255, 264, 866, 743, 443],
[41.14, 65.6, 80, 110, 110, 255, 264, 862, 739, 442],
[41.42, 64.76, 80, 110, 110, 254.99, 263.99, 864, 746, 442],
[41.2, 66.05, 80, 110, 109.99, 255, 264, 862, 741, 441],
[41.26, 65.88, 79.99, 110, 110, 255.13, 264.17, 856, 739, 441],
[41.33, 65.45, 79.99, 110, 110, 255, 264, 862, 741, 442],
[41.23, 65.24, 80, 110, 109.99, 254.99, 264, 858, 738, 442],
[41.08, 66.11, 79.99, 109.99, 110, 254.99, 264, 856, 739, 441],
[41.05, 66.33, 80, 110, 110, 254.99, 264, 857, 736, 441],
[41.08, 66.34, 79.99, 110, 110, 255.18, 264.12, 858, 739, 442],
[41.14, 65.09, 80, 110, 109.99, 255, 263.99, 861, 742, 442],
[41.14, 64.8, 80, 110, 110, 255, 264, 867, 742, 442],
[41.05, 63.47, 79.99, 110, 110, 255, 264, 863, 742, 442],
[41.08, 62.56, 79.99, 110, 110, 255, 264.01, 866, 740, 443],
[41.08, 62.28, 79.99, 109.99, 110, 245.42, 260.66, 855, 734, 442],
[41.33, 62.61, 80, 110, 110, 254.99, 263.99, 859, 738, 442],
[41.17, 63.59, 79.99, 110, 109.99, 255, 264, 867, 741, 443],
[41.14, 64.25, 79.99, 110, 109.99, 255, 263.99, 871, 744, 443],
[41.05, 64.38, 80, 110, 110, 255, 264, 868, 745, 443],
[41.05, 64.79, 79.99, 110, 110, 254.99, 263.99, 864, 737, 442],
[41.2, 66.02, 80, 109.99, 110, 255, 263.99, 868, 742, 442],
[40.98, 66.25, 80, 109.99, 110, 254.99, 263.99, 868, 745, 443],
[41.14, 65.75, 79.99, 110, 110, 254.99, 263.99, 867, 746, 443],
[41.2, 65.07, 79.99, 110, 109.99, 255, 264, 870, 745, 443],
[41.29, 65.14, 80, 110, 110, 255, 264, 868, 745, 442],
[41.51, 63.65, 80, 110, 110, 255, 263.99, 870, 745, 442],
[41.45, 65.32, 80, 110, 110, 254.99, 264, 873, 745, 443],
[41.64, 64.38, 80, 110, 110, 254.99, 263.99, 870, 750, 442],
[41.7, 66.73, 79.99, 110, 109.99, 254.99, 263.99, 869, 742, 442],
[41.73, 66.26, 79.99, 110, 110, 254.99, 263.99, 863, 738, 442],
[42.01, 65.3, 80, 110, 109.99, 255, 263.99, 865, 742, 442],
[41.83, 65.57, 79.99, 110, 110, 255, 263.99, 871, 745, 442],
[41.95, 65.51, 80, 110, 110, 254.99, 264, 872, 745, 442],
[41.92, 65.25, 79.99, 110, 109.99, 254.99, 264, 869, 744, 442],
[41.92, 65.47, 79.99, 109.99, 109.99, 254.99, 263.99, 866, 743, 442],
[41.79, 66.01, 79.99, 110, 110, 255, 263.99, 867, 747, 442],
[41.79, 66.1, 79.99, 110, 109.99, 255, 264, 871, 745, 443],
[41.95, 65.35, 79.99, 109.99, 110, 255, 264, 871, 744, 443],
[41.95, 65.29, 79.99, 110, 110, 254.99, 263.99, 874, 749, 443],
[41.89, 65.79, 79.99, 110, 110, 255, 264, 868, 743, 442],
[41.89, 66.45, 79.99, 109.99, 110, 255, 264, 867, 741, 442],
[41.89, 67.07, 80, 110, 110, 254.99, 264, 871, 742, 443],
[41.7, 66.92, 80, 110, 110, 254.99, 263.99, 868, 747, 442],
[42.01, 66.33, 79.99, 110, 110, 255, 263.99, 874, 747, 442],
[41.95, 65.53, 80, 110, 110, 255, 264, 872, 745, 443],
[42.05, 65.98, 80, 110, 110, 255, 263.99, 863, 739, 442],
[41.92, 67.42, 79.99, 110, 109.99, 254.98, 264, 861, 734, 441],
[41.92, 66.49, 79.99, 110, 110, 254.99, 263.99, 865, 736, 441],
[41.83, 65.05, 80, 109.99, 110, 255, 263.99, 863, 742, 442],
[41.83, 64.95, 80, 110, 110, 254.99, 263.99, 869, 745, 442],
[41.95, 64.77, 80, 110, 110, 254.99, 264, 867, 745, 442],
[41.92, 65.8, 80, 110, 110, 254.98, 263.99, 865, 740, 441],
[42.01, 65.73, 80, 110, 110, 255, 263.99, 861, 740, 442],
[41.92, 68.66, 79.99, 110, 109.99, 255, 264, 861, 742, 441],
[41.92, 67.89, 79.99, 110, 109.99, 254.99, 263.99, 854, 736, 440],
[42.01, 67.03, 80, 110, 110, 254.99, 263.99, 865, 741, 442],
[42.01, 66.61, 79.99, 110, 110, 254.97, 263.99, 867, 742, 443],
[41.95, 66.39, 79.99, 110, 110, 254.99, 264, 858, 739, 441],
[41.98, 65.99, 80, 110, 110, 254.99, 263.99, 859, 739, 441],
[42.01, 65.22, 80, 110, 110, 255, 263.99, 861, 737, 441],
[41.67, 64.67, 79.99, 110, 110, 254.99, 263.99, 851, 728, 439],
[41.89, 64.15, 80, 110, 110, 254.97, 263.99, 848, 723, 437],
[41.86, 63.76, 80, 110, 110, 254.99, 264, 845, 729, 438],
[41.98, 63.9, 79.99, 110, 110, 255, 264, 859, 739, 442],
[41.86, 64.06, 79.99, 110, 109.99, 255, 264, 859, 733, 441],
[41.86, 65.23, 80, 109.99, 110, 254.99, 264, 852, 727, 439],
[41.92, 65.38, 79.99, 110, 110, 254.97, 264, 862, 734, 440],
[41.89, 66.01, 79.99, 109.99, 110, 255, 263.99, 859, 737, 440],
[41.92, 67.45, 80, 110, 110, 254.99, 264, 859, 736, 441],
[41.86, 68.38, 79.99, 110, 110, 255, 263.99, 867, 737, 442],
[42.01, 66.55, 80, 110, 110, 255, 264, 871, 743, 442],
[41.86, 68.06, 79.99, 110, 110, 255.01, 263.99, 862, 737, 441],
[41.86, 66.66, 79.99, 109.99, 110, 255, 263.99, 850, 728, 438],
[42.01, 64.81, 80, 110, 110, 255, 263.99, 854, 730, 440],
[41.86, 65.11, 80, 110, 110, 255, 264, 864, 742, 442],
[41.92, 65.31, 80, 110, 110, 254.99, 263.98, 868, 743, 443],
[41.92, 65.62, 79.99, 109.99, 110, 255.11, 264, 867, 739, 442],
[41.89, 64.98, 79.99, 110, 109.99, 254.99, 263.99, 862, 740, 441],
[41.86, 64.45, 79.99, 110, 110, 255, 264, 864, 739, 442],
[41.83, 64.5, 80, 110, 110, 255, 263.99, 871, 743, 442],
[41.76, 65.55, 79.99, 110, 110, 255, 263.99, 867, 739, 442],
[41.76, 64.99, 79.99, 109.99, 110, 246.87, 263.98, 871, 742, 442],
[41.61, 65.28, 79.99, 110, 110, 255, 264, 864, 741, 442],
[41.61, 64.74, 80, 109.99, 110, 255, 264, 869, 744, 442],
[41.76, 65.91, 79.99, 110, 110, 255, 263.99, 866, 743, 443],
[41.54, 66.23, 79.99, 110, 110, 255, 264, 861, 738, 441],
[41.64, 65.69, 79.99, 110, 110, 255, 263.99, 864, 741, 442],
[41.54, 65.06, 80, 110, 110, 254.99, 263.99, 863, 740, 442],
[41.67, 65.79, 79.99, 110, 110, 254.99, 263.99, 865, 740, 442],
[41.54, 67.02, 80, 110, 110, 255, 263.99, 863, 742, 441],
[41.54, 66.59, 79.99, 110, 110, 254.99, 264, 863, 744, 442],
[41.51, 66.73, 80, 110, 110, 255, 264, 860, 744, 442],
[41.45, 68.57, 80, 110, 110, 255, 263.99, 868, 742, 443],
[41.33, 68.17, 79.99, 110, 110, 255, 264, 861, 739, 442],
[41.17, 66.74, 80, 110, 110, 254.99, 264, 861, 740, 442],
[41.29, 66.43, 79.99, 110, 110, 254.99, 264, 852, 734, 441],
[41.17, 66.25, 80, 110, 110, 255, 264, 848, 732, 440],
[41.08, 65.71, 80, 110, 110, 255, 263.99, 851, 731, 441],
[41.02, 66.3, 80, 110, 110, 255, 264, 868, 744, 443],
[41.05, 65.76, 80, 110, 110, 255, 263.99, 865, 744, 443],
[41.14, 66.16, 80, 109.99, 109.99, 255, 264, 853, 732, 441],
[41.02, 64.18, 80, 110, 110, 254.99, 264, 844, 721, 438],
[41.02, 64.48, 79.99, 110, 110, 254.99, 264, 836, 717, 437],
[41.14, 63.7, 80, 109.99, 110, 255, 263.99, 847, 730, 439],
[41.02, 65.13, 79.99, 109.99, 109.99, 255, 264, 846, 732, 440],
[41.05, 65.09, 80, 110, 110, 254.99, 263.99, 858, 741, 442],
[40.98, 66.68, 79.99, 110, 110, 255, 264, 859, 740, 442],
[41.02, 66.55, 80, 110, 110, 255, 264, 857, 738, 441],
[41.23, 65.25, 80, 110, 110, 255, 264, 855, 733, 441],
[41.14, 65.64, 79.99, 110, 110, 254.99, 263.99, 861, 740, 442],
[41.26, 65.33, 80, 110, 109.99, 255, 264, 858, 739, 441],
[41.11, 66.34, 79.99, 110, 110, 254.99, 263.99, 857, 739, 441],
[41.45, 66.15, 80, 110, 109.99, 255, 264, 861, 741, 441],
[41.26, 65.74, 80, 110, 109.99, 254.99, 264, 857, 736, 441],
[41.39, 65.7, 79.99, 110, 110, 255, 264, 860, 733, 441],
[41.48, 64.59, 79.99, 110, 110, 255, 264, 865, 742, 443],
[41.67, 64.91, 79.99, 110, 109.99, 254.99, 264, 875, 749, 443],
[41.76, 64.73, 79.99, 110, 110, 254.99, 264, 876, 751, 443],
[42.01, 63.58, 80, 110, 110, 254.99, 263.99, 863, 741, 441],
[41.89, 63.34, 80, 110, 110, 255, 264, 869, 745, 443],
[42.01, 63.69, 79.99, 110, 110, 254.99, 263.99, 875, 748, 442],
[42.05, 64.53, 80, 110, 110, 255, 264, 868, 744, 442],
[42.23, 64.58, 79.99, 109.99, 110, 254.99, 264, 875, 742, 443],
[42.3, 63.51, 80, 110, 110, 255, 263.99, 867, 741, 442],
[42.42, 64.1, 79.99, 110, 110, 254.99, 264, 873, 745, 442],
[42.39, 66.35, 79.99, 110, 110, 254.99, 264, 864, 739, 441],
[42.52, 64.68, 80, 109.99, 110, 254.99, 263.99, 845, 725, 437],
[42.65, 64.83, 79.99, 110, 109.99, 254.99, 263.99, 857, 731, 440],
[42.55, 65.28, 80, 110, 110, 254.99, 264, 868, 742, 442],
[42.55, 65.1, 80, 110, 109.99, 255, 263.99, 862, 738, 441],
[42.58, 65.81, 79.99, 109.99, 110, 254.99, 263.99, 845, 723, 437],
[42.58, 65.38, 79.99, 109.99, 110, 255, 264, 843, 723, 437],
[42.71, 64.62, 79.99, 110, 110, 255, 264, 854, 732, 439],
[42.62, 65.33, 79.99, 110, 110, 255, 263.99, 864, 733, 441],
[42.74, 65.64, 80, 110, 110, 255, 264.01, 867, 740, 441],
[42.77, 65.1, 80, 110, 110, 255, 264, 861, 735, 440],
[42.68, 65.51, 80, 110, 110, 255, 263.99, 847, 725, 437],
[42.71, 65.35, 79.99, 109.99, 110, 254.99, 263.99, 846, 721, 436],
[42.81, 65.25, 79.99, 110, 110, 255, 264, 856, 726, 439],
[43.03, 64.41, 80, 110, 110, 254.99, 264, 869, 740, 441],
[42.97, 63.92, 79.99, 110, 110, 254.99, 264, 868, 745, 441],
[43, 63.62, 79.99, 110, 109.99, 254.99, 264, 861, 733, 440],
[43.03, 63.84, 80, 110, 110, 255, 264, 856, 726, 438],
[43.03, 65.43, 80, 110, 110, 255, 264, 855, 732, 439],
[43.03, 64.97, 79.99, 110, 110, 255, 263.99, 869, 741, 441],
[43.25, 64.65, 80, 110, 109.99, 254.99, 263.99, 869, 745, 441],
[43.48, 64.74, 80, 110, 110, 255, 264, 870, 743, 442],
[43.29, 65.9, 79.99, 110, 110, 255, 264, 871, 741, 441],
[43.29, 65.3, 79.99, 110, 110, 254.99, 264, 872, 740, 442],
[43.45, 63.72, 80, 110, 110, 254.99, 263.99, 869, 741, 442],
[43.25, 63.25, 79.99, 110, 110, 255, 264, 869, 741, 441],
[43.38, 62.5, 80, 110, 110, 254.99, 264, 866, 739, 441],
[43.29, 62.7, 80, 110, 110, 255, 264, 868, 734, 441],
[43.35, 62.44, 80, 109.99, 110, 255, 264, 859, 733, 440],
[43.38, 63.28, 79.99, 110, 110, 254.99, 263.99, 859, 731, 440],
[43.51, 63.24, 80, 110, 110, 255, 263.99, 866, 732, 441],
[43.45, 63.27, 80, 109.99, 110, 254.99, 264.01, 867, 735, 441],
[43.38, 64.78, 80, 110, 109.99, 254.99, 263.99, 867, 733, 440],
[43.45, 64.27, 79.99, 110, 110, 254.99, 264, 858, 728, 439],
[43.42, 64.51, 79.99, 110, 110, 254.99, 263.99, 861, 731, 440],
[43.54, 64.86, 80, 110, 110, 255, 263.99, 867, 732, 441],
[43.51, 63.78, 79.99, 110, 110, 254.99, 264, 868, 733, 441],
[43.35, 64.88, 79.99, 109.99, 109.99, 254.99, 264, 868, 733, 441],
[43.32, 64.19, 79.99, 109.99, 110, 254.99, 264, 853, 728, 439],
[43.45, 63.07, 80, 110, 110, 255, 263.99, 859, 724, 440],
[43.45, 62.83, 80, 110, 110, 255, 264, 864, 732, 440],
[43.45, 64.31, 79.99, 110, 110, 255, 263.99, 869, 732, 441],
[43.51, 64.78, 79.99, 110, 110, 255, 263.99, 869, 738, 441],
[43.64, 64.23, 80, 110, 110, 255, 264, 857, 724, 439],
[43.58, 63.88, 80, 110, 109.99, 254.99, 263.99, 856, 719, 439],
[43.67, 64.44, 79.99, 110, 110, 254.99, 264, 862, 729, 440],
[43.32, 64.1, 80, 109.99, 110, 254.97, 263.73, 871, 736, 441],
[43.16, 63.5, 80, 109.99, 109.99, 255, 263.99, 864, 733, 441],
[43.03, 65.54, 80, 110, 109.99, 254.99, 263.99, 861, 727, 441],
[42.93, 65.11, 80, 110, 110, 255, 264, 858, 730, 440],
[42.84, 65.42, 80, 110, 110, 255, 264, 856, 726, 440],
[42.81, 64.8, 80, 110, 109.99, 255, 264.07, 867, 731, 441],
[42.62, 66.04, 79.99, 110, 110, 254.99, 264, 865, 734, 441],
[42.49, 67.06, 79.99, 110, 110, 255, 264, 865, 733, 441],
[42.46, 66.51, 80, 109.99, 110, 255, 264, 866, 738, 441],
[42.42, 66.22, 79.99, 110, 110, 254.99, 264, 863, 733, 441],
[42.33, 65.94, 79.99, 110, 110, 254.99, 264.37, 878, 731, 441],
[42.3, 65.03, 80, 110, 110, 254.99, 263.99, 869, 742, 442],
[42.27, 65.03, 79.99, 110, 110, 254.99, 263.99, 862, 732, 441],
[42.33, 65.06, 80, 110, 110, 254.99, 264, 862, 731, 441],
[42.52, 65.39, 80, 110, 110, 255, 263.99, 867, 743, 441],
[42.17, 65.55, 80, 110, 110, 254.99, 264.46, 877, 736, 442],
[42.11, 63.82, 80, 109.99, 110, 255, 263.99, 860, 730, 441],
[42.08, 65.85, 79.99, 110, 110, 254.99, 264, 858, 730, 440],
[42.17, 66.04, 79.99, 109.99, 110, 254.99, 263.99, 871, 738, 442],
[41.95, 67.38, 79.99, 110, 110, 255, 264, 864, 735, 441],
[41.79, 67.63, 79.99, 110, 110, 254.99, 263.99, 858, 732, 440],
[41.79, 67.74, 80, 110, 110, 255, 264, 861, 731, 441],
[41.76, 66.36, 80, 110, 110, 255, 263.99, 867, 741, 442],
[41.83, 65.25, 80, 110, 110, 254.99, 264, 861, 733, 441],
[41.67, 64.85, 79.99, 110, 110, 254.99, 264, 869, 739, 442],
[41.45, 64.82, 80, 110, 110, 255, 264, 873, 742, 442],
[41.58, 64.56, 79.99, 109.99, 109.99, 255, 263.99, 869, 738, 442],
[41.61, 64.39, 80, 110, 110, 254.99, 264, 871, 739, 443],
[41.58, 64.11, 80, 110, 110, 254.99, 264, 871, 739, 442],
[41.64, 64.1, 79.99, 110, 110, 254.99, 263.99, 869, 737, 442],
[41.64, 65.53, 79.99, 110, 109.99, 255, 263.99, 869, 738, 442],
[41.67, 65.3, 80, 110, 109.99, 255, 264, 875, 746, 443],
[41.79, 66.5, 79.99, 109.99, 110, 255, 263.99, 872, 739, 443],
[41.95, 65.82, 80, 110, 110, 255, 263.99, 865, 734, 441],
[41.79, 66.46, 79.99, 110, 109.99, 254.99, 263.99, 869, 731, 441],
[41.83, 66.36, 80, 110, 110, 254.99, 264, 871, 742, 442],
[41.86, 64.55, 80, 109.99, 109.99, 254.99, 264, 871, 742, 442],
[42.11, 65.71, 79.99, 109.99, 110, 254.99, 263.98, 871, 742, 442],
[41.83, 65.72, 80, 109.99, 110, 254.99, 263.99, 863, 731, 441],
[42.01, 67.27, 79.99, 110, 110, 254.99, 264, 871, 742, 442],
[42.08, 65.88, 79.99, 110, 110, 254.99, 264, 863, 737, 441],
[41.86, 65.51, 79.99, 110, 110, 254.99, 264, 868, 742, 442],
[41.86, 64.5, 79.99, 110, 110, 255, 263.99, 872, 743, 442],
[41.83, 65.7, 79.99, 110, 109.99, 254.99, 264, 867, 735, 442],
[41.73, 67.31, 80, 110, 110, 255, 263.99, 881, 749, 443],
[41.67, 66.59, 79.99, 110, 110, 255, 264, 877, 741, 443],
[41.61, 65.94, 80, 110, 109.99, 254.99, 263.98, 868, 738, 442],
[41.58, 67.52, 79.99, 109.99, 109.99, 255, 264, 863, 733, 442],
[41.45, 68.11, 80, 110, 110, 254.99, 263.99, 871, 737, 443],
[41.39, 66.71, 79.99, 109.99, 110, 255, 264, 870, 738, 442],
[41.42, 65.04, 80, 110, 110, 255, 264, 872, 737, 442],
[41.39, 65.09, 79.99, 109.99, 110, 255, 263.99, 866, 738, 441],
[41.42, 65, 79.99, 110, 110, 255, 263.99, 866, 735, 441],
[41.39, 64.78, 80, 110, 109.99, 254.99, 264, 874, 747, 443],
[41.45, 65.28, 79.99, 109.99, 110, 254.99, 263.99, 872, 738, 443],
[41.36, 65.32, 79.99, 110, 110, 255, 263.99, 868, 736, 442],
[41.39, 64.84, 80, 110, 110, 254.99, 264, 869, 738, 442],
[41.39, 64.28, 79.99, 110, 110, 255, 264, 867, 734, 442],
[41.42, 63.74, 79.99, 110, 110, 255, 263.99, 867, 739, 442],
[41.23, 63.36, 80, 110, 110, 254.99, 263.99, 866, 734, 442],
[41.45, 64.21, 79.99, 110, 110, 254.99, 264, 866, 733, 442],
[41.64, 63.68, 80, 110, 110, 255, 264, 862, 726, 441],
[41.61, 63.75, 80, 110, 110, 254.99, 264, 858, 731, 441],
[41.73, 64.3, 80, 110, 110, 254.99, 263.99, 878, 740, 443],
[41.64, 66.44, 80, 109.99, 110, 254.99, 264, 868, 739, 442],
[41.67, 65.99, 79.99, 110, 110, 254.99, 263.99, 864, 731, 441],
[41.73, 66.02, 79.99, 110, 110, 255, 264, 860, 728, 441],
[41.67, 65.98, 80, 110, 110, 254.99, 263.99, 860, 731, 440],
[41.7, 65.01, 80, 109.99, 110, 254.98, 263.99, 868, 732, 442],
[41.89, 66.11, 79.99, 110, 110, 254.99, 263.99, 869, 732, 442],
[41.7, 65.37, 79.99, 110, 110, 255, 264, 874, 737, 442],
[41.76, 64.31, 79.99, 109.99, 110, 255, 264, 875, 742, 442],
[41.89, 63.97, 79.99, 110, 110, 255, 263.99, 867, 732, 442],
[41.89, 63.69, 80, 110, 109.99, 255, 263.99, 870, 732, 441],
[41.83, 63.58, 79.99, 109.99, 110, 255, 263.99, 871, 738, 442],
[41.7, 63.02, 79.99, 110, 110, 254.99, 264, 863, 734, 442],
[41.64, 63.12, 80, 110, 110, 254.99, 264, 870, 735, 442],
[41.64, 62.75, 79.99, 109.99, 110, 254.99, 264, 871, 739, 443],
[41.51, 62.8, 80, 110, 110, 254.99, 263.99, 882, 733, 442],
[41.51, 62.58, 79.99, 110, 109.99, 254.99, 264, 856, 727, 440],
[41.54, 62.12, 79.99, 110, 109.99, 255, 264, 859, 728, 440],
[41.11, 64.01, 80, 110, 109.99, 255, 263.99, 875, 735, 443],
[40.95, 63.92, 79.99, 110, 110, 254.99, 263.98, 907, 732, 442],
[41.14, 63.43, 80, 110, 110, 255, 264, 857, 726, 441],
[40.95, 65.43, 80, 109.99, 110, 255, 264, 851, 722, 439],
[40.71, 65.44, 79.99, 110, 110, 254.99, 264, 857, 724, 441],
[40.74, 65.45, 79.99, 110, 109.99, 255, 264, 866, 738, 443],
[40.77, 65.27, 79.99, 110, 110, 254.99, 263.98, 869, 736, 443],
[40.98, 64.51, 80, 110, 110, 254.99, 264, 864, 729, 442],
[40.71, 63.71, 80, 110, 110, 254.99, 263.99, 866, 729, 442],
[40.83, 62.87, 80, 110, 110, 255, 264, 863, 730, 442],
[40.68, 63.09, 79.99, 109.99, 109.99, 255, 264, 869, 734, 443],
[40.74, 62.95, 80, 110, 110, 254.99, 263.97, 868, 735, 443],
[41.17, 62.47, 79.99, 110, 110, 254.99, 263.99, 866, 735, 443],
[40.86, 64.23, 79.99, 110, 109.99, 254.99, 264, 863, 732, 442],
[40.8, 64.71, 79.99, 110, 110, 254.99, 263.99, 870, 734, 442],
[40.8, 63.91, 79.99, 110, 110, 255, 263.99, 874, 737, 444],
[40.77, 65.26, 79.99, 110, 110, 255.07, 263.88, 870, 736, 443],
[40.98, 65.19, 79.99, 110, 110, 255, 264, 871, 737, 443],
[40.89, 64.95, 80, 110, 110, 254.99, 263.99, 870, 736, 443],
[40.86, 66.11, 80, 110, 110, 254.99, 263.99, 867, 730, 442],
[40.68, 64.95, 80, 110, 109.99, 255, 264, 869, 733, 443],
[40.61, 64.94, 80, 110, 109.99, 255.07, 263.85, 870, 732, 443],
[40.64, 65.27, 79.99, 109.99, 110, 254.99, 264, 873, 733, 444],
[40.55, 63.94, 80, 110, 110, 255, 263.99, 872, 737, 444],
[40.58, 64.42, 79.99, 110, 109.99, 255, 263.99, 873, 735, 443],
[40.8, 65, 80, 110, 110, 254.99, 264, 871, 742, 444],
[40.8, 65.06, 80, 110, 110, 254.99, 264, 872, 740, 444],
[40.61, 64.11, 80, 109.99, 110, 254.99, 263.99, 863, 731, 443],
[40.64, 64.17, 79.99, 110, 110, 255, 264, 861, 731, 442],
[40.49, 63.28, 79.99, 110, 110, 254.99, 264, 854, 719, 440],
[40.49, 62.92, 80, 109.99, 110, 254.99, 264, 847, 713, 438],
[40.43, 63.15, 79.99, 110, 110, 255, 264, 855, 725, 440],
[40.43, 64.3, 80, 110, 110, 254.99, 264, 851, 714, 440],
[40.46, 64.35, 80, 109.99, 110, 255, 264, 849, 719, 439],
[40.49, 64.53, 79.99, 110, 109.99, 254.99, 263.99, 852, 717, 440],
[40.71, 63.96, 79.99, 109.99, 110, 254.99, 263.99, 858, 722, 442],
[40.52, 64.18, 79.99, 110, 109.99, 254.99, 263.99, 849, 720, 439],
[40.71, 64.63, 79.99, 110, 110, 255, 264, 850, 718, 439],
[40.49, 63.81, 80, 110, 110, 255, 263.99, 844, 713, 438],
[40.46, 63.34, 80, 110, 110, 254.99, 264, 838, 713, 437],
[40.46, 63.32, 79.99, 110, 110, 255, 263.99, 854, 721, 441],
[40.74, 61.85, 80, 110, 110, 255, 263.99, 871, 734, 444],
[40.64, 63.02, 80, 110, 110, 254.99, 264, 868, 733, 443],
[40.31, 62.07, 80, 110, 110, 254.99, 263.99, 867, 734, 443],
[40.68, 63.13, 79.99, 110, 110, 254.99, 263.99, 871, 735, 443],
[40.68, 62.8, 80, 110, 110, 255, 264, 865, 731, 442],
[40.74, 62.81, 79.99, 109.99, 110, 255, 263.99, 854, 722, 440],
[40.95, 64.43, 79.99, 110, 110, 254.99, 264, 851, 723, 440],
[40.74, 64.42, 79.99, 110, 110, 255, 264, 856, 722, 441],
[41.02, 63.89, 80, 110, 110, 254.99, 263.99, 869, 733, 442],
[40.77, 63.7, 79.99, 110, 110, 254.99, 264, 870, 732, 443],
[40.92, 63.88, 79.99, 110, 110, 255, 264, 868, 736, 441],
[40.86, 62.78, 80, 110, 110, 254.99, 264, 854, 724, 440],
[40.92, 63.64, 79.99, 110, 110, 255, 264, 850, 720, 439],
[41.11, 63.77, 79.99, 110, 109.99, 255, 264, 854, 728, 439],
[41.33, 64.26, 80, 109.99, 110, 255, 264, 862, 733, 442],
[40.98, 63.64, 80, 110, 110, 254.99, 264, 868, 738, 443],
[41.11, 63.28, 80, 110, 109.99, 254.99, 264, 864, 728, 442],
[41.17, 61.61, 79.99, 109.99, 110, 255, 264, 870, 735, 443],
[41.08, 64.58, 80, 110, 110, 255, 264, 872, 736, 443],
[41.2, 64.31, 80, 110, 110, 254.99, 263.99, 870, 737, 443],
[41.17, 65.34, 79.99, 110, 110, 255, 263.99, 871, 739, 443],
[41.02, 65.04, 80, 109.99, 110, 255, 263.99, 865, 732, 443],
[41.05, 65.01, 80, 109.99, 109.99, 254.99, 263.99, 867, 738, 443],
[41.17, 64.26, 80, 110, 109.99, 255, 264, 872, 738, 443],
[41.02, 63.87, 80, 110, 109.99, 254.99, 264, 864, 734, 443],
[41.14, 64.52, 79.99, 110, 110, 255, 264, 866, 733, 442],
[40.86, 64.37, 79.99, 110, 110, 254.99, 263.99, 863, 727, 442],
[40.95, 64.36, 80, 110, 110, 254.99, 264, 862, 732, 442],
[41.02, 63.07, 80, 110, 110, 255, 264, 856, 729, 441],
[40.95, 62.91, 79.99, 110, 110, 254.98, 264, 854, 723, 440],
[41.11, 64.39, 79.99, 110, 110, 254.99, 264, 859, 727, 441],
[41.08, 64.53, 80, 110, 110, 255, 264, 858, 731, 441],
[41.05, 64.43, 80, 110, 110, 254.99, 264, 854, 729, 441],
[41.02, 64.23, 79.99, 110, 109.99, 254.99, 264, 865, 735, 443],
[41.2, 63.24, 80, 110, 110, 254.99, 263.99, 866, 732, 442],
[41.05, 63.51, 80, 110, 110, 255, 264, 866, 734, 443],
[40.98, 63.67, 79.99, 110, 110, 254.99, 263.99, 866, 732, 442],
[41.05, 63.5, 80, 110, 110, 254.99, 263.99, 850, 722, 440],
[41.11, 63.54, 79.99, 109.99, 110, 255, 264, 850, 716, 439],
[41.08, 64.6, 79.99, 110, 109.99, 254.98, 264, 855, 723, 441],
[41.05, 63.88, 80, 110, 109.99, 254.99, 263.99, 862, 731, 442],
[41.17, 63.87, 80, 110, 110, 255, 264, 856, 721, 441],
[41.11, 65.81, 79.99, 110, 110, 254.98, 264, 851, 720, 440],
[41.17, 65.38, 80, 110, 110, 254.99, 264.01, 860, 728, 441],
[41.36, 65.66, 80, 109.99, 110, 254.99, 264, 871, 739, 443],
[41.14, 63.96, 80, 110, 110, 255, 263.99, 869, 741, 443],
[41.11, 64.46, 79.99, 109.99, 110, 255, 264, 866, 735, 443],
[41.08, 64.41, 79.99, 110, 110, 254.99, 264, 878, 741, 444],
[41.14, 64.48, 80, 110, 110, 254.99, 264, 867, 742, 443],
[41.05, 66.67, 79.99, 109.99, 109.99, 255, 264, 871, 737, 442],
[41.11, 66.91, 79.99, 110, 110, 254.99, 264, 868, 739, 443],
[41.11, 65.01, 79.99, 110, 110, 254.99, 264, 860, 734, 442],
[41.17, 64.31, 80, 110, 110, 255, 264, 865, 736, 442],
[41.23, 65.98, 79.99, 110, 110, 254.99, 263.99, 867, 734, 442],
[41.29, 64.51, 80, 110, 110, 255, 264, 866, 739, 443],
[41.17, 65.66, 79.99, 110, 109.99, 254.99, 264, 869, 738, 443],
[41.42, 65.83, 80, 110, 109.99, 255, 264, 871, 738, 443],
[41.17, 65.18, 80, 110, 110, 255.35, 264, 876, 732, 442],
[41.2, 65.61, 80, 110, 110, 255, 263.99, 867, 737, 443],
[41.26, 65.19, 79.99, 110, 110, 254.99, 264, 866, 735, 442],
[41.23, 63.64, 80, 110, 110, 255, 264, 868, 733, 442],
[41.23, 64.82, 79.99, 110, 110, 254.99, 264, 864, 735, 443],
[41.14, 64.71, 79.99, 110, 110, 254.99, 264, 881, 732, 442],
[41.17, 65.36, 80, 110, 110, 254.99, 264, 866, 737, 442],
[41.33, 65.94, 80, 110, 110, 255, 264, 860, 731, 442],
[41.14, 67.67, 80, 109.99, 110, 255, 263.99, 861, 736, 443],
[41.2, 67.05, 79.99, 110, 110, 254.99, 263.99, 861, 731, 442],
[41.05, 67.49, 79.99, 110, 110, 255, 263.99, 861, 732, 442],
[41.02, 66.3, 80, 110, 110, 255, 264, 863, 737, 442],
[41.02, 64, 80, 110, 110, 255, 264, 866, 741, 443],
[41.02, 63.56, 80, 110, 110, 255, 264, 863, 735, 442],
[40.89, 64.17, 79.99, 110, 110, 254.99, 263.99, 856, 731, 442],
[41.02, 64.05, 80, 110, 110, 255, 263.99, 871, 740, 442],
[40.95, 64.08, 79.99, 109.99, 110, 255, 264, 872, 742, 443],
[41.02, 63.62, 79.99, 110, 110, 255, 264, 864, 731, 443],
[41.14, 65, 79.99, 109.99, 110, 255, 263.99, 863, 732, 442],
[41.05, 64.35, 79.99, 110, 110, 254.99, 264, 863, 732, 443],
[41.2, 63.75, 80, 110, 110, 254.99, 264, 870, 737, 443],
[40.98, 66.04, 79.99, 110, 109.99, 254.99, 263.99, 870, 737, 444],
[40.92, 65.64, 80, 110, 110, 254.99, 264, 852, 725, 440],
[41.14, 66.28, 79.99, 110, 110, 254.99, 264, 849, 722, 440],
[40.86, 65.78, 79.99, 110, 110, 254.99, 263.99, 856, 729, 442],
[40.77, 65.44, 79.99, 110, 110, 254.99, 263.99, 866, 736, 442],
[40.92, 64.21, 79.99, 110, 110, 255, 263.99, 864, 731, 442],
[40.86, 63.82, 79.99, 109.99, 110, 255, 264, 852, 722, 440],
[40.8, 64.34, 80, 109.99, 110, 255, 264, 842, 720, 438],
[41.02, 64.56, 79.99, 110, 109.99, 255, 264, 848, 720, 439],
[40.86, 64.41, 79.99, 110, 109.99, 254.99, 264, 851, 721, 440],
[40.83, 64.37, 79.99, 110, 110, 254.99, 264, 854, 722, 441],
[40.61, 63.09, 80, 110, 110, 255, 264, 861, 732, 442],
[40.89, 64.62, 79.99, 109.99, 110, 254.99, 264, 862, 729, 442],
[40.89, 66.73, 79.99, 110, 109.99, 255, 263.99, 854, 724, 441],
[40.95, 65.7, 79.99, 110, 110, 255, 264, 856, 722, 441],
[40.92, 64.98, 79.99, 110, 110, 255, 264, 846, 718, 440],
[40.83, 65.61, 80, 110, 110, 254.99, 264, 844, 719, 438],
[41.2, 65.77, 79.99, 109.99, 110, 255, 264, 851, 720, 439],
[40.98, 65.22, 79.99, 109.99, 110, 255, 264, 855, 722, 440],
[41.11, 64.74, 79.99, 110, 109.99, 255, 264, 857, 723, 441],
[41.08, 64.54, 79.99, 110, 110, 255, 264, 856, 726, 441],
[40.95, 65.63, 79.99, 110, 110, 254.99, 263.99, 860, 736, 442],
[41.11, 65.65, 80, 110, 110, 255, 264, 844, 718, 439],
[41.23, 65.41, 79.99, 110, 110, 254.99, 264, 847, 722, 439],
[41.17, 65.63, 79.99, 110, 110, 255, 264, 854, 724, 440],
[41.17, 65.61, 80, 110, 110, 255, 263.99, 860, 730, 442],
[41.36, 64.41, 79.99, 109.99, 110, 255, 264, 868, 736, 443],
[41.2, 67.14, 79.99, 110, 110, 254.99, 264, 862, 729, 442],
[41.2, 66.51, 80, 110, 110, 254.99, 264, 853, 725, 440],
[41.29, 66.44, 80, 110, 110, 254.99, 264, 849, 725, 439],
[41.26, 66.32, 79.99, 110, 110, 254.99, 264, 860, 732, 441],
[41.33, 65, 79.99, 109.99, 109.99, 255, 264, 869, 739, 442],
[41.42, 64.68, 80, 110, 110, 255, 264, 864, 734, 442],
[41.33, 64.8, 80, 110, 110, 255, 263.99, 870, 739, 442],
[41.42, 64.82, 79.99, 110, 110, 254.99, 264, 862, 731, 442],
[41.48, 63.89, 79.99, 110, 110, 255, 264, 865, 732, 442],
[41.45, 63.74, 79.99, 109.99, 110, 255.01, 263.99, 866, 734, 442],
[41.64, 63.07, 79.99, 110, 110, 254.99, 263.99, 861, 730, 441],
[41.7, 62, 80, 109.99, 110, 254.99, 263.99, 858, 726, 440],
[41.86, 62.51, 79.99, 110, 110, 255, 264, 864, 731, 441],
[41.73, 63.1, 80, 109.99, 110, 254.99, 263.99, 866, 733, 442],
[41.76, 65.63, 80, 110, 109.99, 254.99, 263.99, 866, 735, 442],
[41.83, 65.22, 79.99, 110, 110, 255, 263.99, 868, 738, 442],
[41.83, 66.17, 80.01, 110, 110, 255, 263.99, 855, 731, 440],
[41.89, 67.3, 79.99, 110, 110, 254.99, 263.99, 849, 725, 440],
[41.98, 66.93, 80, 109.99, 110, 255, 263.98, 854, 720, 439],
[41.92, 66.46, 79.99, 109.99, 110, 254.99, 263.99, 858, 729, 440],
[42.23, 65.42, 80, 110, 110, 255, 263.99, 865, 734, 441],
[42.01, 64.49, 80, 110, 110, 255, 264, 865, 734, 441],
[42.49, 62.88, 80, 110, 110, 255, 263.99, 868, 732, 441],
[42.14, 63.02, 80, 110, 110, 255, 263.9, 880, 737, 442],
[42.2, 63.35, 79.99, 110, 109.99, 254.99, 264, 861, 731, 442],
[42.2, 63.15, 80, 110, 110, 254.99, 264, 846, 719, 437],
[42.14, 63.82, 79.99, 110, 110, 254.99, 263.99, 846, 712, 437],
[42.14, 63.7, 80, 110, 110, 255.01, 263.58, 866, 722, 439],
[42.23, 63.74, 80, 109.99, 110, 255, 264, 868, 736, 442],
[42.17, 64.66, 79.99, 110, 110, 255, 264, 860, 729, 441],
[42.2, 65.02, 80, 110, 110, 254.99, 263.99, 860, 728, 440],
[42.33, 64.54, 79.99, 110, 110, 254.99, 263.99, 876, 739, 442],
[42.17, 65.42, 80, 110, 109.99, 255, 259.95, 890, 736, 442],
[42.17, 65.44, 79.99, 110, 109.99, 255, 263.99, 878, 744, 443],
[42.27, 66.44, 79.99, 109.99, 109.99, 254.99, 263.99, 881, 744, 443],
[42.3, 66.06, 80, 109.99, 110, 254.99, 263.99, 874, 739, 442],
[42.46, 65.03, 79.99, 110, 110, 254.99, 263.99, 874, 744, 443],
[42.3, 65.37, 80, 110, 110, 255, 260.49, 894, 739, 442],
[42.55, 64.67, 79.99, 109.99, 110, 254.99, 264, 879, 743, 443],
[42.42, 63.72, 79.99, 110, 110, 255, 263.99, 877, 738, 443],
[42.42, 63.22, 79.99, 110, 110, 254.99, 263.99, 870, 738, 442],
[42.46, 62.7, 80, 110, 110, 255, 263.99, 861, 731, 440],
[42.52, 62.35, 79.99, 110, 110, 255, 264, 871, 719, 438],
[42.55, 62.29, 80, 110, 109.99, 255, 264, 856, 725, 439],
[42.71, 64.11, 79.99, 110, 110, 254.99, 264, 855, 728, 439],
[42.68, 64.91, 80, 110, 110, 254.99, 264, 863, 730, 441],
[42.55, 64.62, 79.99, 110, 110, 255, 263.99, 857, 724, 440],
[42.49, 64.68, 80, 110, 110, 254.99, 264, 855, 724, 439],
[42.58, 64.66, 79.99, 110, 110, 254.99, 264, 851, 715, 438],
[42.46, 63.31, 80, 110, 110, 254.99, 264, 852, 722, 439],
[42.46, 63.41, 79.99, 110, 110, 254.99, 264, 862, 730, 441],
[42.49, 65.03, 80, 110, 110, 254.99, 263.99, 870, 735, 441],
[42.49, 64.72, 80, 110, 109.99, 255, 263.99, 858, 727, 440],
[42.55, 64.54, 79.99, 110, 109.99, 255, 263.99, 861, 731, 440],
[42.62, 65.33, 79.99, 110, 110, 255, 263.99, 862, 730, 441],
[42.58, 65.63, 79.99, 110, 110, 255, 264, 853, 724, 439],
[42.65, 64.51, 79.99, 109.99, 110, 254.99, 264, 854, 723, 439],
[42.52, 64.28, 79.99, 110, 110, 254.99, 263.99, 861, 730, 441],
[42.55, 63.97, 79.99, 109.99, 110, 255, 264, 863, 731, 440],
[42.81, 62.95, 80, 109.99, 110, 255, 264, 850, 722, 439],
[42.55, 62.23, 80, 110, 110, 255, 264, 854, 723, 439],
[42.71, 61.83, 80, 110, 110, 255, 264, 851, 724, 439],
[42.58, 62.08, 79.99, 110, 110, 254.99, 263.99, 859, 728, 440],
[42.58, 61.27, 80, 110, 110, 255, 264, 856, 727, 440],
[42.65, 61.58, 79.99, 109.99, 110, 255, 264.01, 863, 730, 441],
[42.68, 65.68, 79.99, 110, 110, 255, 264, 849, 723, 439],
[42.58, 64.94, 80, 109.99, 110, 255, 264, 858, 729, 440],
[42.77, 66.26, 79.99, 110, 110, 255, 263.99, 863, 727, 441],
[42.49, 66.7, 79.99, 110, 110, 255, 264, 850, 719, 438],
[42.58, 65.2, 80, 110, 110, 254.99, 263.99, 845, 714, 437],
[42.58, 64.76, 79.99, 110, 110, 255, 264, 846, 718, 437],
[42.52, 64.74, 79.99, 110, 110, 255, 264, 864, 733, 440],
[42.46, 63.51, 80, 110, 110, 254.99, 264, 863, 730, 441],
[42.42, 63.59, 79.99, 110, 110, 254.99, 263.99, 867, 730, 441],
[42.58, 62.88, 80, 110, 109.99, 254.99, 263.99, 865, 731, 441],
[42.49, 63.93, 80, 110, 110, 255, 264, 863, 735, 441],
[42.46, 65.04, 80, 110, 110, 255, 264, 865, 732, 441],
[42.52, 64.97, 80, 110, 110, 255, 263.99, 862, 735, 441],
[42.65, 65.53, 79.99, 110, 110, 254.99, 263.99, 864, 730, 441],
[42.46, 65.26, 80, 110, 110, 255, 264, 869, 736, 442],
[42.39, 64.31, 79.99, 109.99, 110, 255, 264, 866, 732, 441],
[42.71, 63.58, 79.99, 110, 110, 255, 263.99, 875, 739, 442],
[42.52, 62.72, 79.99, 109.99, 110, 255, 264, 862, 734, 441],
[42.84, 64.45, 79.99, 109.99, 110, 255, 264, 898, 726, 441],
[42.42, 64.15, 80, 110, 110, 255, 264, 846, 716, 437],
[42.42, 64.81, 79.99, 109.99, 109.99, 255, 264, 863, 728, 440],
[42.46, 64.3, 79.99, 110, 109.99, 255, 264, 868, 738, 442],
[42.36, 64.65, 79.99, 110, 109.99, 255, 264, 869, 735, 442],
[42.46, 65.58, 79.99, 109.99, 110, 255, 263.99, 921, 737, 441],
[42.52, 65.1, 80, 110, 110, 255, 264, 863, 735, 441],
[42.36, 65.56, 79.99, 110, 110, 255, 263.99, 857, 730, 440],
[42.39, 66.27, 79.99, 110, 110, 255, 263.99, 855, 724, 440],
[42.33, 66.71, 80, 110, 110, 255, 263.99, 852, 725, 438],
[42.52, 65.31, 80, 110, 110, 254.99, 263.98, 904, 728, 440],
[42.33, 65.34, 80, 110, 110, 255, 264, 860, 731, 441],
[42.33, 64.7, 80, 110, 110, 254.99, 263.99, 860, 731, 441],
[42.2, 65.01, 79.99, 110, 110, 255, 264, 855, 724, 439],
[42.39, 65.04, 79.99, 109.99, 110, 255, 264, 849, 718, 438],
[42.3, 63.79, 80, 110, 110, 254.99, 263.98, 842, 715, 437],
[42.14, 63.46, 80, 110, 109.99, 255, 264, 849, 720, 438],
[41.95, 63.69, 80, 110, 110, 255, 263.99, 867, 735, 442],
[42.08, 65.07, 80, 110, 109.99, 254.99, 263.99, 871, 739, 442],
[42.08, 66.04, 80, 110, 110, 254.99, 263.99, 872, 739, 442],
[42.01, 64.91, 79.99, 110, 110, 254.99, 263.97, 864, 732, 441],
[41.98, 65.81, 80, 110, 110, 255, 264, 866, 734, 441],
[42.01, 65.44, 80, 110, 110, 255, 264, 863, 731, 441],
[41.92, 65.81, 79.99, 109.99, 109.99, 255, 263.99, 865, 738, 442],
[42.11, 66.39, 79.99, 109.99, 109.99, 255, 263.99, 866, 733, 441],
[41.98, 65.87, 80, 110, 110, 255, 264, 867, 733, 441],
[41.98, 66.9, 79.99, 110, 110, 255, 264, 876, 741, 442],
[42.01, 65.38, 79.99, 110, 110, 255, 264, 877, 742, 443],
[41.83, 66.11, 79.99, 110, 110, 255.04, 263.91, 871, 738, 442],
[41.83, 64.39, 80, 109.99, 109.99, 255, 264, 868, 740, 442],
[42.01, 65.29, 79.99, 110, 110, 255, 263.99, 869, 740, 442],
[41.79, 65.66, 80, 110, 110, 255, 264, 868, 740, 442],
[41.79, 64.98, 80, 110, 110, 255, 263.99, 874, 742, 443],
[41.79, 64.65, 80, 110, 110, 255.05, 263.9, 871, 735, 442],
[41.73, 65.67, 79.99, 110, 109.99, 255, 264, 863, 731, 442],
[41.76, 66.32, 80, 110, 110, 254.99, 264, 865, 736, 442],
[41.73, 67.19, 79.99, 110, 110, 255, 264, 866, 739, 442],
[41.7, 66.53, 80, 110, 110, 254.99, 264, 866, 739, 442],
[41.79, 66.55, 79.99, 110, 110, 255.08, 263.76, 869, 740, 442],
[41.64, 67.03, 79.99, 110, 110, 254.99, 264, 864, 731, 441],
[41.58, 66.36, 80, 110, 110, 255, 263.99, 857, 725, 440],
[41.61, 65.42, 80, 110, 109.99, 254.99, 264, 862, 731, 441],
[41.61, 64.48, 79.99, 110, 110, 255, 263.99, 869, 739, 442],
[41.61, 63.58, 80, 110, 109.99, 255.23, 263.49, 870, 737, 442],
[41.64, 63.42, 80, 109.99, 110, 254.99, 264, 865, 734, 441],
[41.64, 62.99, 79.99, 110, 110, 255, 264, 857, 727, 440],
[41.67, 64.14, 79.99, 110, 110, 255, 264, 860, 728, 441],
[41.61, 65.34, 79.99, 110, 110, 254.99, 263.99, 860, 731, 441],
[41.58, 66.81, 80, 109.99, 110, 248.88, 264.2, 863, 732, 440],
[41.61, 66.22, 80, 109.99, 110, 255, 264, 863, 733, 441],
[41.64, 66.55, 79.99, 110, 110, 255, 263.99, 868, 735, 442],
[41.7, 64.84, 79.99, 109.99, 110, 255, 263.99, 866, 732, 441],
[41.83, 63.37, 80, 109.99, 110, 255, 264, 859, 727, 440],
[41.92, 63.29, 80, 110, 110, 254.99, 264, 867, 732, 441],
[42.01, 63.49, 79.99, 110, 109.99, 255, 264, 863, 728, 441],
[42.08, 63.58, 80, 110, 110, 254.99, 264, 869, 732, 441],
[42.3, 64.81, 79.99, 110, 109.99, 255, 264, 853, 721, 438],
[42.14, 65.13, 80, 110, 110, 254.99, 264, 845, 716, 437],
[42.11, 64.16, 79.99, 110, 110, 254.99, 263.99, 855, 728, 439],
[42.14, 64.22, 80, 110, 110, 254.99, 263.99, 863, 731, 441],
[41.95, 65.78, 80, 110, 109.99, 254.99, 263.99, 858, 728, 440],
[41.95, 66.59, 80, 110, 110, 255, 264, 856, 730, 440],
[42.01, 65.89, 80, 110, 110, 255, 263.99, 858, 732, 441],
[42.14, 65.91, 80, 110, 110, 254.99, 264, 860, 729, 441],
[41.98, 64.25, 80, 110, 109.99, 254.99, 263.99, 863, 730, 441],
[41.79, 65.08, 80, 110, 110, 254.99, 264, 863, 732, 441],
[41.73, 65.71, 80, 110, 110, 255, 264, 853, 724, 439],
[41.76, 65.84, 79.99, 109.99, 110, 255, 264, 858, 727, 440],
[41.64, 64.86, 80, 110, 110, 255, 264, 866, 733, 441],
[41.95, 63.51, 79.99, 110, 109.99, 254.99, 263.99, 866, 730, 441],
[41.73, 63.18, 79.99, 110, 110, 255, 264.01, 868, 737, 442],
[41.83, 63.67, 79.99, 110, 110, 254.99, 264, 873, 739, 442],
[41.89, 64.36, 80, 109.99, 110, 255, 264.01, 868, 739, 442],
[41.73, 64.46, 80, 110, 109.99, 255, 263.99, 869, 738, 441],
[41.79, 64.88, 79.99, 110, 110, 254.99, 263.99, 869, 735, 442],
[41.83, 64.5, 80, 110, 110, 254.99, 263.99, 876, 740, 442],
[41.89, 65.96, 79.99, 110, 109.99, 254.99, 264, 871, 737, 442],
[41.92, 65.63, 79.99, 110, 110, 255, 263.98, 875, 741, 443],
[41.89, 65.16, 79.99, 110, 110, 255, 264, 878, 742, 442],
[42.01, 64.92, 79.99, 110, 110, 255, 263.99, 873, 739, 443],
[41.89, 63.72, 79.99, 110, 110, 254.99, 264, 874, 738, 443],
[41.98, 64.04, 80, 110, 110, 255, 263.99, 880, 741, 443],
[41.95, 64.3, 80, 110, 109.99, 254.99, 263.99, 873, 741, 443],
[41.92, 62.66, 80, 110, 109.99, 254.99, 263.99, 872, 738, 443],
[41.89, 62.92, 80, 110, 110, 254.99, 264, 869, 749, 442],
[41.95, 63.42, 79.99, 110, 110, 254.98, 263.99, 855, 732, 440],
[41.73, 63.57, 80, 109.99, 110, 254.99, 264, 863, 734, 441],
[41.95, 65.32, 79.99, 110, 110, 254.99, 264, 865, 738, 443],
[41.64, 65.03, 80, 110, 110, 255, 263.99, 871, 737, 442],
[41.58, 64.19, 80, 110, 110, 255, 264, 870, 737, 442],
[41.48, 65.1, 80, 110, 110, 255, 263.99, 865, 735, 442],
[41.42, 63.69, 80, 109.99, 109.99, 255, 264, 864, 737, 442],
[41.42, 62.51, 80, 110, 109.99, 255, 264, 864, 733, 442],
[41.36, 62.66, 80, 110, 110, 254.97, 263.99, 863, 732, 442],
[41.33, 62.44, 80, 110, 109.99, 254.99, 263.99, 858, 725, 441],
[41.48, 61.65, 80, 109.99, 109.99, 255, 263.98, 865, 735, 442],
[41.33, 62.08, 79.99, 110, 110, 254.99, 263.99, 868, 739, 443],
[41.33, 62.99, 79.99, 110, 110, 254.99, 263.99, 864, 736, 442],
[41.42, 63.54, 80, 110, 109.99, 254.97, 264, 861, 730, 441],
[41.36, 63.24, 80, 110, 110, 255, 263.99, 861, 730, 441],
[41.51, 64.46, 79.99, 110, 110, 255, 264, 863, 730, 441],
[41.36, 64.88, 80, 110, 110, 255, 263.99, 867, 739, 442],
[41.39, 64.73, 80, 110, 110, 254.99, 264, 866, 735, 442],
[41.33, 63.31, 80, 110, 110, 254.99, 263.99, 871, 736, 443],
[41.45, 63.53, 79.99, 109.99, 110, 254.99, 264, 867, 737, 443],
[41.33, 62.43, 80, 109.99, 110, 255, 263.99, 871, 737, 443],
[41.39, 63.95, 79.99, 110, 110, 255, 264, 865, 734, 442],
[41.51, 64.86, 80, 110, 110, 254.99, 263.98, 870, 742, 443],
[41.39, 67.56, 79.99, 110, 109.99, 255.06, 264, 881, 744, 443],
[41.33, 65.98, 79.99, 110, 110, 254.99, 263.99, 871, 739, 443],
[41.51, 67.28, 80, 109.99, 110, 255, 264, 871, 737, 443],
[41.58, 65.68, 80, 110, 110, 255, 263.99, 880, 743, 443],
[41.7, 65.29, 80, 109.99, 110, 255, 264, 878, 740, 443],
[41.64, 63.72, 80, 110, 110, 255.28, 264.01, 880, 740, 443],
[41.7, 63.08, 80, 110, 110, 255, 263.99, 871, 739, 443],
[41.61, 64.76, 79.99, 110, 110, 255, 264, 874, 739, 443],
[41.58, 65.4, 79.99, 110, 110, 254.99, 263.99, 873, 743, 443],
[41.51, 64.63, 80, 110, 110, 254.99, 263.99, 867, 738, 443],
[41.54, 63.82, 80, 110, 110, 250.99, 263.96, 873, 742, 443],
[41.76, 63.62, 79.99, 110, 110, 255, 263.99, 869, 739, 443],
[41.54, 65.26, 80, 110, 110, 255, 263.99, 861, 732, 441],
[41.61, 65.33, 80, 109.99, 110, 254.99, 264, 853, 730, 439],
[41.58, 65.51, 79.99, 109.99, 110, 255, 263.99, 860, 735, 441],
[41.76, 66.51, 79.99, 109.99, 110, 255, 263.99, 872, 738, 442],
[41.67, 65.86, 79.99, 110, 110, 255, 263.99, 879, 742, 442],
[41.92, 65.09, 79.99, 110, 110, 255, 263.99, 867, 738, 441],
[41.83, 64.24, 80, 110, 110, 254.99, 264, 859, 733, 440],
[41.86, 66.43, 80, 110, 109.99, 255, 264, 860, 728, 440],
[41.95, 65.95, 79.99, 109.99, 110, 255, 264, 863, 729, 440],
[42.01, 64.18, 80, 109.99, 110, 254.99, 263.99, 864, 728, 440],
[42.3, 62.91, 80, 110, 110, 254.99, 264, 857, 727, 440],
[42.36, 63.47, 80, 109.99, 109.99, 254.99, 263.99, 861, 729, 440],
[42.42, 62.66, 80, 110, 109.99, 254.99, 264, 864, 731, 440],
[42.46, 63.73, 80, 110, 110, 254.99, 264, 865, 731, 440],
[42.55, 64.69, 79.99, 109.99, 109.99, 254.99, 263.99, 858, 725, 439],
[42.62, 65.95, 79.99, 110, 109.99, 255, 263.99, 862, 731, 440],
[42.68, 66.93, 79.99, 109.99, 110, 254.99, 264, 871, 738, 441],
[42.81, 67.63, 80, 110, 110, 254.99, 263.99, 874, 740, 442],
[42.87, 66.53, 79.99, 110, 109.99, 254.98, 263.99, 876, 742, 441],
[42.93, 65.86, 80, 110, 110, 254.99, 264, 871, 732, 441],
[43.03, 64.73, 80, 109.99, 110, 255, 263.99, 865, 728, 440],
[43.09, 65.09, 80, 110, 110, 254.99, 264, 869, 733, 441],
[43.19, 63.8, 80, 110, 110, 255, 264, 872, 740, 442],
[43.19, 64.93, 79.99, 110, 110, 255, 264, 876, 742, 441],
[43.19, 65.42, 79.99, 110, 110, 255, 263.99, 866, 732, 441],
[43.22, 64.1, 79.99, 110, 109.99, 255, 264, 865, 730, 440],
[43.29, 63.82, 80, 110, 110, 254.99, 264, 865, 732, 440],
[43.25, 64.86, 80, 110, 109.99, 255, 264, 862, 731, 439],
[43.29, 65.17, 79.99, 109.99, 109.99, 254.99, 264, 863, 731, 439],
[43.22, 65.35, 80, 110, 110, 255, 264, 854, 725, 439],
[43.09, 65.47, 80, 110, 110, 255, 263.99, 841, 716, 437],
[42.93, 65.41, 80, 109.99, 109.99, 254.99, 264, 853, 727, 439],
[42.84, 67.19, 79.99, 110, 110, 254.99, 263.99, 856, 725, 439],
[42.71, 66.78, 80, 110, 110, 255, 264, 854, 726, 439],
[42.58, 66.15, 80, 110, 109.99, 255, 264, 861, 737, 441],
[42.52, 67.04, 79.99, 110, 110, 254.99, 264, 864, 733, 441],
[42.62, 66.8, 79.99, 110, 110, 255, 264, 858, 731, 440],
[42.27, 66.14, 80, 110, 110, 255, 263.99, 856, 722, 440],
[42.23, 65.31, 80, 110, 110, 254.99, 264, 864, 735, 442],
[42.36, 65.46, 79.99, 109.99, 110, 255, 264, 860, 731, 441],
[42.23, 64.49, 80, 109.99, 110, 255, 264, 860, 733, 440],
[42.08, 64.69, 80, 110, 109.99, 254.99, 263.99, 859, 731, 440],
[42.27, 65.71, 80, 110, 109.99, 254.99, 263.99, 867, 734, 441],
[42.17, 65.4, 80, 110, 110, 255, 264, 861, 734, 440],
[42.27, 68.48, 79.99, 110, 110, 254.99, 264, 850, 722, 437],
[42.33, 67.41, 79.99, 110, 110, 255, 263.99, 851, 718, 438],
[42.55, 66.26, 80, 110, 110, 255, 264, 851, 722, 438],
[42.58, 65.28, 80, 110, 110, 255, 264, 857, 724, 439],
[42.81, 64.39, 80, 110, 110, 254.99, 264, 851, 720, 437],
[42.84, 64.27, 79.99, 110, 110, 255, 264, 862, 730, 440],
[42.97, 64.24, 80, 110, 109.99, 255, 264, 866, 734, 440],
[43.13, 64.55, 80, 110, 110, 254.99, 264, 870, 734, 441],
[43.09, 65.73, 80, 110, 110, 255, 263.99, 870, 734, 441],
[43.25, 65.79, 80, 110, 110, 255, 263.99, 851, 721, 437],
[43.25, 67.91, 79.99, 110, 110, 254.99, 263.99, 849, 719, 437],
[43.61, 66.63, 79.99, 110, 110, 255, 263.99, 851, 719, 437],
[43.45, 66.54, 80, 110, 110, 254.99, 263.99, 860, 724, 439],
[43.51, 64.73, 80, 110, 110, 254.99, 264, 865, 732, 440],
[43.77, 64.68, 80, 110, 110, 254.99, 263.99, 871, 738, 440],
[43.77, 64.67, 80, 110, 110, 255, 264, 864, 727, 439],
[43.8, 66.44, 79.99, 110, 110, 255, 263.99, 859, 723, 438],
[43.77, 65.54, 80, 110, 110, 255, 263.99, 859, 725, 439],
[43.84, 64.34, 80, 110, 110, 255, 263.99, 853, 721, 438],
[44.06, 63.76, 79.99, 110, 110, 254.99, 264, 858, 724, 438],
[44.06, 63.15, 79.99, 110, 109.99, 254.99, 264, 861, 722, 438],
[44.06, 63.5, 79.99, 109.99, 109.99, 255, 264, 860, 725, 439],
[44.26, 62.71, 79.99, 109.99, 110, 255, 264, 860, 726, 439],
[44.23, 61.83, 80, 110, 110, 255, 263.99, 862, 729, 439],
[44.29, 61.56, 79.99, 110, 110, 255, 263.99, 866, 729, 439],
[44.55, 61.51, 80, 110, 110, 254.99, 264, 865, 726, 440],
[44.62, 60.94, 79.99, 110, 110, 254.99, 263.99, 852, 718, 436],
[44.55, 62.16, 80, 109.99, 109.99, 255, 263.99, 858, 722, 438],
[44.52, 62.43, 80, 110, 110, 254.99, 263.99, 865, 730, 440],
[44.62, 61.64, 80, 110, 109.99, 254.99, 263.99, 867, 727, 440],
[44.69, 61.66, 79.99, 110, 110, 255, 263.99, 879, 736, 441],
[44.65, 62.24, 79.99, 110, 110, 254.99, 264, 870, 732, 440],
[44.65, 64.05, 80, 110, 109.99, 254.99, 264, 853, 717, 436],
[44.65, 64.61, 80, 110, 110, 254.99, 264, 858, 721, 438],
[44.72, 64.03, 80, 110, 110, 255, 263.99, 860, 722, 438],
[44.75, 64.49, 79.99, 110, 110, 254.99, 263.98, 867, 726, 439],
[44.69, 65.26, 79.99, 109.99, 110, 254.99, 264, 863, 729, 439],
[44.85, 64.5, 80, 110, 110, 255, 263.99, 870, 729, 440],
[44.85, 65.49, 80, 110, 110, 255, 263.99, 866, 729, 440],
[44.85, 64.62, 80, 110, 109.99, 255, 263.99, 879, 741, 441],
[44.95, 64.21, 80, 110, 110, 255, 263.92, 873, 731, 439],
[44.98, 63.61, 79.99, 109.99, 110, 255, 263.99, 869, 729, 440],
[44.98, 63.23, 79.99, 110, 110, 255, 264, 857, 721, 438],
[45.12, 63.42, 79.99, 110, 110, 255, 263.99, 866, 726, 440],
[45.22, 63.91, 80, 110, 110, 254.99, 263.99, 857, 723, 438],
[45.12, 63.93, 79.99, 110, 110, 255, 263.19, 869, 727, 440],
[45.31, 64.51, 79.99, 110, 110, 254.99, 263.99, 871, 734, 440],
[45.55, 64.51, 80, 110, 110, 255, 263.99, 870, 728, 439],
[45.58, 65.47, 80, 110, 110, 254.99, 264, 856, 721, 437],
[45.68, 64.82, 80, 110, 110, 254.99, 263.99, 860, 722, 438],
[45.65, 65.16, 79.99, 110, 109.99, 255, 261.88, 866, 726, 438],
[45.62, 63.46, 80, 110, 110, 255, 263.99, 861, 722, 438],
[45.75, 62.46, 80, 110, 110, 254.99, 264, 872, 737, 441],
[45.78, 63.51, 80, 110, 110, 254.98, 264, 877, 736, 441],
[45.85, 64.4, 80, 110, 109.99, 254.99, 264, 868, 730, 439],
[46.09, 66, 79.99, 110, 110, 255, 263.99, 870, 731, 440],
[46.09, 65.15, 80, 110, 110, 254.99, 264, 862, 729, 438],
[46.02, 65.48, 79.99, 110, 110, 254.99, 263.99, 851, 716, 435],
[46.05, 65.48, 79.99, 110, 110, 254.99, 263.99, 868, 717, 436],
[46.12, 65.22, 80, 110, 109.99, 255, 264, 851, 717, 436],
[46.15, 65.43, 80, 110, 110, 255, 263.99, 856, 718, 437],
[46.02, 65.48, 80, 110, 110, 255, 264, 860, 725, 438],
[46.22, 65.77, 79.99, 110, 110, 255, 264, 860, 723, 438],
[46.32, 64.99, 80, 110, 110, 255, 264, 863, 727, 438],
[46.43, 64.57, 79.99, 110, 109.99, 255, 264, 850, 713, 435],
[46.43, 63.5, 80, 110, 109.99, 255, 264, 862, 720, 438],
[46.66, 62.71, 80, 110, 110, 254.99, 263.99, 872, 730, 440],
[46.49, 62.01, 80, 110, 110, 255, 264, 882, 740, 442],
[46.56, 61.29, 80, 110, 110, 254.99, 264, 880, 739, 441],
[46.56, 61.54, 80, 110, 110, 254.99, 263.99, 861, 723, 438],
[46.73, 61.22, 80, 110, 110, 254.99, 263.99, 869, 728, 439],
[46.66, 61.72, 79.99, 110, 110, 255, 263.99, 882, 739, 441],
[46.7, 62.82, 79.99, 110, 110, 255, 263.99, 880, 739, 441],
[46.87, 62.61, 79.99, 110, 110, 254.99, 263.99, 878, 736, 441],
[46.94, 61.59, 79.99, 109.99, 110, 254.99, 264, 874, 732, 441],
[46.73, 62.71, 80, 110, 110, 255, 264, 874, 731, 441],
[46.77, 62.68, 80, 110, 109.99, 254.99, 263.99, 873, 731, 441],
[46.83, 63.74, 80, 110, 109.99, 254.99, 263.99, 870, 733, 441],
[46.83, 64.63, 79.99, 109.99, 109.99, 255, 263.99, 870, 731, 439],
[46.9, 64.36, 80, 110, 110, 254.99, 263.99, 875, 733, 441],
[46.83, 64.57, 79.99, 110, 110, 254.99, 264, 858, 721, 437],
[46.9, 63.85, 80, 110, 110, 255, 264, 855, 717, 436],
[46.9, 64.48, 79.99, 110, 110, 254.99, 263.99, 867, 724, 439],
[46.87, 64.01, 80, 110, 110, 254.99, 263.99, 867, 725, 439],
[46.94, 63.7, 79.99, 110, 110, 254.99, 264, 867, 724, 439],
[47.14, 63.81, 80, 110, 109.99, 254.99, 264, 858, 722, 437],
[46.9, 65.39, 80, 109.99, 110, 255, 264, 865, 726, 438],
[47.11, 65.53, 80, 110, 110, 254.99, 264, 870, 724, 440],
[46.94, 64.68, 79.99, 110, 110, 254.99, 264, 871, 731, 440],
[47.01, 63.6, 80, 110, 109.99, 254.99, 263.99, 877, 733, 441],
[46.73, 63.52, 79.99, 110, 110, 255, 263.99, 871, 731, 440],
[46.94, 62.85, 79.99, 110, 110, 254.99, 263.99, 875, 732, 440],
[46.94, 63.12, 80, 110, 109.99, 255, 263.99, 868, 727, 439],
[46.94, 62.9, 79.99, 110, 110, 254.99, 264, 861, 723, 437],
[47.11, 63.58, 80, 110, 110, 255, 264, 873, 730, 440],
[46.83, 63.99, 79.99, 109.99, 109.99, 255, 263.99, 866, 726, 439],
[46.83, 64.12, 79.99, 110, 109.99, 254.99, 264, 869, 729, 440],
[46.8, 63.83, 79.99, 110, 110, 254.99, 264, 863, 728, 438],
[46.7, 63.59, 80, 110, 110, 255, 263.99, 854, 718, 436],
[46.7, 63.43, 79.99, 109.99, 110, 254.99, 264, 849, 714, 434],
[46.7, 63.85, 80, 110, 110, 254.99, 263.99, 848, 714, 435],
[46.49, 63.73, 79.99, 110, 110, 254.99, 263.99, 856, 716, 437],
[46.66, 62.83, 80, 110, 110, 254.99, 264, 860, 722, 438],
[46.53, 63.73, 79.99, 110, 110, 254.99, 264, 858, 724, 437],
[46.43, 63.21, 79.99, 110, 110, 254.99, 264, 848, 714, 435],
[46.49, 63.09, 80, 110, 110, 254.99, 264, 860, 721, 438],
[46.49, 63.7, 79.99, 110, 110, 254.99, 263.99, 871, 731, 439],
[46.49, 64.31, 80, 110, 110, 255, 264, 871, 727, 439],
[46.46, 64.42, 80, 110, 109.99, 255, 263.99, 873, 729, 439],
[46.66, 65.01, 80, 110, 109.99, 254.99, 264, 864, 723, 438],
[46.56, 65.59, 79.99, 109.99, 110, 254.99, 263.99, 861, 723, 438],
[46.73, 65.58, 80, 110, 110, 255, 264, 874, 732, 440],
[46.8, 64.49, 80, 110, 110, 255, 263.99, 876, 735, 441],
[46.6, 64.76, 80, 110, 110, 255, 264, 874, 733, 441],
[46.7, 63.13, 80, 110, 110, 255, 263.99, 870, 732, 440],
[46.8, 64.59, 80, 110, 110, 255, 263.99, 872, 732, 440],
[46.83, 64.35, 79.99, 110, 110, 255, 263.99, 909, 733, 440],
[46.73, 64.31, 80, 110, 110, 254.99, 264, 880, 737, 441],
[46.77, 65.27, 80, 110, 110, 255, 264, 882, 737, 441],
[47.01, 66.1, 80, 110, 110, 255, 263.99, 875, 733, 440],
[46.87, 65.84, 79.99, 110, 110, 254.99, 264, 874, 734, 440],
[46.73, 66.1, 80, 110, 110, 254.99, 263.98, 860, 717, 437],
[46.7, 66.19, 79.99, 110, 110, 255, 263.99, 860, 722, 437],
[46.63, 66.43, 80, 109.99, 110, 255, 264, 865, 725, 438],
[46.56, 66.53, 79.99, 110, 109.99, 254.99, 264, 864, 728, 438],
[46.49, 67.1, 79.99, 110, 110, 254.99, 264, 867, 731, 439],
[46.53, 66.68, 80, 109.99, 110, 254.99, 263.99, 867, 729, 439],
[46.36, 67.18, 79.99, 110, 110, 255, 264, 867, 733, 439],
[46.56, 66.16, 79.99, 110, 110, 254.99, 263.99, 871, 731, 439],
[46.39, 65.91, 80, 110, 110, 254.99, 263.99, 871, 732, 440],
[46.26, 65.56, 79.99, 109.99, 110, 255, 263.99, 871, 733, 440],
[46.19, 65.02, 80, 110, 110, 254.99, 263.99, 872, 731, 440],
[46.22, 63.71, 79.99, 109.99, 110, 255, 264, 878, 736, 441],
[46.12, 64.27, 79.99, 110, 109.99, 255, 264, 880, 735, 441],
[46.19, 63.58, 80, 110, 110, 255, 263.99, 872, 732, 440],
[46.12, 62.98, 79.99, 110, 110, 254.99, 263.95, 876, 733, 441],
[46.15, 62.8, 80, 110, 110, 255, 263.99, 871, 729, 440],
[46.19, 62.6, 80, 110, 109.99, 255, 264, 877, 735, 441],
[46.12, 62.83, 80, 110, 109.99, 254.99, 264, 872, 729, 440],
[46.12, 62.74, 79.99, 110, 109.99, 254.99, 263.99, 873, 731, 440],
[46.12, 62.99, 79.99, 110, 109.99, 254.99, 263.94, 876, 732, 441],
[46.19, 63.47, 80, 110, 109.99, 254.99, 264, 873, 730, 440],
[46.29, 63.84, 79.99, 110, 109.99, 255, 263.99, 866, 727, 438],
[46.19, 62.88, 79.99, 110, 110, 255, 264, 862, 721, 438],
[46.19, 63.44, 80, 110, 110, 255, 264, 860, 719, 437],
[46.22, 64.18, 79.99, 109.99, 110, 255, 263.75, 858, 720, 436],
[46.19, 64.16, 80, 110, 109.99, 254.99, 263.99, 848, 716, 434],
[46.46, 65.16, 80, 110, 110, 255, 264, 852, 715, 435],
[46.19, 65.23, 79.99, 109.99, 110, 254.99, 263.99, 871, 731, 440],
[46.32, 64.34, 80, 110, 110, 255, 264, 873, 732, 440],
[46.29, 63.29, 79.99, 110, 110, 255.02, 263.64, 873, 734, 440],
[46.15, 63.89, 80, 110, 110, 255, 264, 869, 728, 440],
[46.09, 63.15, 79.99, 110, 110, 254.99, 263.99, 876, 734, 441],
[46.19, 62.34, 80, 110, 110, 255, 264, 879, 732, 440],
[46.39, 62.94, 80, 109.99, 110, 255, 264, 873, 730, 440],
[46.15, 62.81, 79.99, 110, 110, 255.01, 263.7, 874, 728, 440],
[46.19, 61.7, 80, 110, 110, 255, 264, 876, 731, 440],
[46.09, 62.24, 79.99, 110, 109.99, 255, 263.99, 877, 734, 441],
[46.09, 61.41, 80, 110, 109.99, 255, 263.99, 875, 732, 441],
[46.09, 61.13, 79.99, 109.99, 109.99, 255, 264, 873, 729, 439],
[45.99, 61.56, 80, 110, 109.99, 255.2, 262.88, 871, 729, 439],
[45.99, 61.08, 80, 110, 110, 255, 263.99, 875, 733, 440],
[45.72, 61.15, 79.99, 110, 110, 254.99, 264, 873, 731, 440],
[45.82, 61.3, 79.99, 109.99, 110, 254.99, 264, 879, 736, 440],
[45.95, 62.4, 79.99, 110, 109.99, 255, 264, 879, 737, 441],
[45.72, 63.57, 79.99, 110, 109.99, 255.22, 263.03, 871, 726, 440],
[45.75, 65.39, 80, 110, 110, 255, 264, 879, 737, 440],
[45.58, 66.34, 79.99, 110, 109.99, 255, 264, 882, 738, 441],
[45.82, 67.47, 79.99, 109.99, 110, 254.99, 264, 881, 738, 441],
[45.75, 66.79, 80, 110, 110, 254.99, 264, 881, 735, 441],
[45.55, 66.05, 80, 110, 110, 252.83, 264, 878, 737, 441],
[45.65, 64.61, 80, 110, 110, 255, 264, 890, 744, 441],
[45.78, 63.36, 79.99, 109.99, 110, 255, 263.99, 879, 734, 441],
[45.82, 64.18, 79.99, 110, 110, 254.99, 264, 873, 732, 440],
[45.82, 64.44, 79.99, 110, 109.99, 255, 264, 870, 731, 439],
[45.65, 64.26, 79.99, 110, 110, 255, 263.99, 869, 730, 439],
[45.62, 64.82, 80, 110, 110, 255, 263.99, 873, 731, 439],
[45.65, 64.79, 80, 110, 110, 255, 263.99, 861, 723, 437],
[45.65, 64.68, 80, 110, 110, 254.99, 263.99, 862, 724, 437],
[45.62, 64.71, 80, 110, 110, 254.99, 264, 853, 716, 436],
[45.65, 64.32, 79.99, 110, 110, 255, 263.98, 848, 714, 433],
[45.78, 64.29, 80, 110, 110, 254.99, 263.99, 860, 723, 438],
[45.92, 63.63, 80, 110, 110, 254.99, 263.99, 870, 731, 440],
[45.72, 63.1, 80, 110, 110, 255, 263.99, 851, 720, 435],
[45.95, 63.14, 80, 110, 110, 255, 263.99, 850, 717, 435],
[45.72, 64.08, 80, 110, 110, 254.99, 264, 848, 719, 435],
[45.82, 64.95, 79.99, 110, 110, 254.99, 263.99, 856, 716, 437],
[45.78, 65.72, 80, 110, 110, 254.99, 264, 869, 732, 439],
[45.88, 63.66, 79.99, 110, 110, 254.99, 263.99, 862, 724, 438],
[45.78, 62.92, 79.99, 110, 109.99, 255, 264, 856, 718, 436],
[46.12, 61.9, 80, 110, 110, 255, 263.99, 856, 718, 436],
[45.95, 63.55, 80, 110, 109.99, 254.99, 264, 875, 734, 441],
[46.02, 63.31, 80, 110, 110, 255, 264.01, 871, 732, 440],
[45.85, 63.03, 79.99, 110, 110, 254.99, 264, 872, 731, 440],
[46.09, 62.89, 80, 110, 110, 255, 264, 874, 731, 440],
[46.05, 62.82, 80, 110, 110, 255, 264, 873, 729, 440],
[45.85, 62.68, 80, 110, 110, 254.99, 264, 883, 740, 442],
[46.05, 62.38, 79.99, 110, 110, 254.99, 264, 886, 738, 442],
[45.85, 62.69, 80, 109.99, 110, 255, 263.99, 878, 735, 441],
[45.78, 62.11, 80, 110, 110, 255, 264, 886, 739, 441],
[46.05, 62.16, 80, 110, 110, 254.99, 263.99, 875, 737, 441],
[45.82, 62.52, 80, 110, 109.99, 254.99, 264, 878, 731, 441],
[45.82, 62.03, 79.99, 110, 110, 255, 263.99, 877, 732, 440],
[45.95, 61.19, 80, 110, 109.99, 255, 264, 873, 729, 440],
[45.95, 61.77, 79.99, 109.99, 110, 254.99, 264, 872, 733, 440],
[45.85, 61.6, 79.99, 110, 110, 255, 264, 865, 723, 438],
[45.92, 62.81, 80, 110, 110, 254.99, 264, 859, 718, 437],
[45.85, 64.32, 79.99, 110, 109.99, 254.99, 264, 872, 731, 440],
[45.95, 64.74, 79.99, 109.99, 109.99, 255, 263.99, 877, 733, 440],
[45.92, 64.02, 80, 110, 110, 255, 263.98, 871, 732, 440],
[45.92, 65.29, 80, 109.99, 110, 254.99, 263.99, 871, 730, 439],
[45.92, 65.55, 79.99, 109.99, 110, 254.99, 264, 873, 732, 440],
[45.92, 65.16, 79.99, 110, 109.99, 255, 263.99, 880, 736, 440],
[45.95, 67.02, 80, 110, 109.99, 254.99, 263.99, 872, 732, 440],
[45.88, 67.13, 80, 109.99, 110, 254.99, 264, 878, 740, 440],
[46.12, 66.3, 79.99, 110, 110, 255, 264, 869, 730, 439],
[46.09, 65.7, 80, 110, 110, 254.99, 264, 871, 731, 439],
[45.88, 66.61, 79.99, 110, 109.99, 255, 264, 877, 736, 441],
[45.92, 66.51, 79.99, 110, 110, 254.99, 263.99, 878, 735, 440],
[45.88, 66.47, 79.99, 109.99, 110, 255, 263.99, 865, 729, 438],
[45.99, 66.01, 79.99, 110, 110, 255, 263.99, 857, 719, 436],
[45.92, 65.43, 79.99, 110, 110, 254.98, 263.99, 849, 713, 435],
[45.88, 64.11, 79.99, 110, 110, 254.99, 263.99, 855, 716, 435],
[45.88, 63.79, 79.99, 110, 110, 254.99, 264, 851, 717, 435],
[45.82, 63.25, 80, 110, 110, 255, 264, 851, 711, 435],
[45.88, 62.94, 79.99, 110, 110, 254.99, 263.99, 846, 709, 434],
[45.82, 63.38, 80, 110, 110, 254.99, 264, 851, 713, 434],
[45.82, 63.34, 79.99, 110, 110, 255, 263.99, 854, 718, 435],
[46.02, 64.26, 79.99, 110, 110, 254.99, 264, 850, 712, 434],
[45.78, 64.17, 80, 110, 110, 255, 264, 851, 711, 435],
[45.88, 64.46, 80, 109.99, 110, 254.99, 264, 853, 715, 435],
[46.09, 64.71, 80, 110, 110, 254.97, 263.99, 856, 718, 436],
[45.82, 66.39, 80, 110, 110, 254.99, 264, 867, 730, 438],
[45.92, 66.88, 79.99, 110, 110, 255, 263.99, 874, 730, 440],
[45.82, 66.81, 80, 110, 110, 255, 264, 879, 733, 440],
[45.82, 66.62, 80, 110, 110, 255, 264, 875, 736, 440],
[45.78, 68.2, 79.99, 110, 110, 254.96, 264, 860, 716, 436],
[45.75, 66.74, 79.99, 110, 110, 254.99, 263.99, 864, 722, 437],
[45.75, 66.29, 80, 110, 110, 255, 264, 860, 727, 438],
[45.78, 65.31, 80, 109.99, 109.99, 255, 264.01, 874, 731, 439],
[45.75, 65.23, 79.99, 110, 110, 254.99, 263.99, 873, 732, 440],
[45.75, 64.81, 80, 110, 110, 254.98, 263.99, 862, 723, 437],
[45.85, 65.56, 80, 110, 110, 255, 263.99, 852, 715, 435],
[45.75, 65.77, 80, 109.99, 109.99, 255, 263.99, 854, 716, 435],
[45.92, 67.37, 79.99, 110, 109.99, 255, 264, 865, 731, 438],
[45.92, 67.83, 79.99, 110, 110, 255, 264, 875, 736, 440],
[45.99, 66.86, 80, 110, 110, 254.97, 263.99, 876, 736, 440],
[45.85, 66.31, 79.99, 110, 110, 255, 263.99, 869, 731, 439],
[45.78, 67.21, 79.99, 110, 110, 255, 264, 864, 724, 438],
[45.58, 66.88, 80, 110, 109.99, 255, 264, 858, 722, 437],
[45.75, 66.05, 80, 110, 110, 254.98, 264, 873, 735, 440],
[45.78, 69.15, 80, 110, 109.99, 254.99, 263.99, 873, 733, 439],
[45.75, 69.34, 79.99, 110, 110, 255, 263.99, 872, 734, 440],
[45.78, 67.66, 79.99, 109.99, 110, 255, 264, 865, 731, 438],
[45.82, 68.14, 79.99, 110, 110, 254.99, 263.99, 855, 718, 436],
[45.68, 67.8, 79.99, 110, 110, 255.16, 263.99, 860, 726, 438],
[45.75, 67.26, 79.99, 110, 110, 255, 263.99, 875, 731, 440],
[45.82, 66.08, 80, 110, 109.99, 255, 263.99, 872, 732, 440],
[45.68, 65.42, 80, 109.99, 109.99, 255, 263.99, 872, 731, 439],
[45.68, 64.59, 79.99, 110, 110, 254.99, 263.99, 864, 727, 438],
[45.85, 64.61, 79.99, 109.99, 110, 255.21, 264, 873, 721, 437],
[45.65, 64.39, 79.99, 109.99, 110, 255, 264, 866, 725, 438],
[45.58, 65.71, 80, 110, 110, 255, 263.99, 857, 721, 437],
[45.65, 65.93, 79.99, 110, 109.99, 255, 264, 857, 723, 437],
[45.65, 64.85, 80, 110, 110, 254.99, 263.99, 855, 719, 437],
[45.62, 65.3, 80, 109.99, 110, 255, 263.99, 865, 727, 438],
[45.62, 64.73, 79.99, 110, 110, 255, 263.99, 869, 729, 439],
[45.65, 64.99, 79.99, 110, 110, 255, 264, 874, 728, 439],
[45.58, 64.47, 79.99, 110, 110, 254.99, 263.99, 887, 729, 439],
[45.65, 64.92, 79.99, 110, 110, 255, 264, 864, 729, 438],
[45.52, 65, 79.99, 109.99, 110, 255, 263.99, 862, 725, 438],
[45.55, 64.57, 80, 110, 110, 254.99, 263.99, 873, 733, 440],
[45.65, 63.9, 80, 110, 110, 255, 264, 882, 742, 441],
[45.72, 63.71, 79.99, 110, 109.99, 254.98, 264.01, 882, 730, 440],
[45.55, 63.25, 80, 110, 109.99, 255, 263.99, 876, 731, 440],
[45.52, 63.3, 79.99, 110, 109.99, 254.99, 263.99, 877, 732, 441],
[45.52, 63.13, 80, 110, 110, 255, 263.99, 878, 732, 441],
[45.48, 62.84, 80, 110, 109.99, 254.99, 263.99, 879, 738, 440],
[45.48, 62.72, 80, 110, 109.99, 254.99, 263.97, 875, 733, 441],
[45.65, 65.18, 80, 110, 109.99, 255, 264, 878, 736, 441],
[45.48, 65.87, 80, 109.99, 110, 254.99, 264, 880, 740, 441],
[45.52, 67.28, 80, 110, 109.99, 255, 263.99, 871, 733, 440],
[45.41, 66.87, 80, 110, 110, 255, 263.99, 870, 731, 439],
[45.38, 66.7, 79.99, 110, 109.99, 255, 263.99, 869, 728, 440],
[45.55, 66.31, 79.99, 110, 110, 254.99, 263.99, 875, 731, 440],
[45.48, 65.45, 79.99, 110, 110, 255, 263.99, 871, 732, 440],
[45.38, 65.34, 79.99, 110, 110, 255, 264, 877, 736, 441],
[45.41, 65.83, 79.99, 110, 110, 254.99, 263.99, 874, 732, 441],
[45.38, 64.26, 79.99, 110, 110, 255, 263.99, 878, 732, 441],
[45.31, 63.77, 80, 110, 110, 255, 264, 867, 730, 439],
[45.55, 64.13, 80, 110, 110, 254.99, 263.99, 867, 731, 440],
[45.31, 63.78, 80, 110, 110, 254.99, 263.99, 875, 735, 440],
[45.48, 62.82, 79.99, 110, 110, 254.99, 263.99, 875, 735, 441],
[45.15, 62.91, 80, 110, 109.99, 255, 264, 880, 736, 441],
[45.31, 62.34, 80, 110, 110, 254.99, 264, 875, 732, 440],
[45.22, 64.25, 79.99, 110, 110, 254.99, 263.99, 867, 727, 440],
[45.22, 64.65, 79.99, 110, 110, 255, 264, 866, 727, 439],
[45.22, 64.26, 79.99, 110, 110, 255, 264, 866, 724, 439],
[45.15, 64.61, 79.99, 110, 110, 254.99, 264, 868, 731, 439],
[44.98, 63.9, 80, 110, 110, 255, 263.99, 862, 729, 438],
[45.18, 64.12, 79.99, 110, 110, 254.99, 263.99, 857, 721, 437],
[45.22, 63.9, 80, 109.99, 109.99, 254.99, 263.99, 846, 712, 435],
[45.15, 63.68, 80, 110, 110, 255, 263.99, 857, 720, 437],
[45.22, 63.72, 80, 110, 110, 254.99, 264, 862, 725, 438],
[45.28, 63.27, 80, 110, 110, 254.99, 264, 861, 721, 439],
[45.35, 64.81, 80, 109.99, 110, 255, 264, 849, 713, 436],
[45.52, 65.59, 80, 109.99, 110, 254.99, 264, 851, 720, 436],
[45.52, 66.08, 80, 110, 109.99, 255, 263.99, 853, 723, 437],
[45.68, 65.69, 79.99, 110, 110, 254.99, 263.99, 857, 721, 437],
[45.48, 67.55, 80, 109.99, 110, 255, 263.99, 862, 724, 439],
[45.55, 66.62, 80, 110, 110, 254.99, 263.99, 850, 718, 436],
[45.52, 66.71, 79.99, 109.99, 110, 255, 263.99, 845, 711, 434],
[45.48, 65.24, 79.99, 110, 110, 254.99, 264, 857, 720, 437],
[45.82, 64.43, 79.99, 110, 110, 255, 263.99, 863, 725, 438],
[45.75, 64.62, 80, 110, 110, 254.99, 263.99, 868, 729, 440],
[45.68, 64.24, 79.99, 110, 110, 255, 263.99, 876, 734, 440],
[45.88, 63.94, 80, 110, 110, 254.99, 264, 864, 731, 439],
[45.92, 64.63, 80, 110, 109.99, 254.99, 264, 857, 724, 438],
[45.75, 64.23, 80, 110, 110, 255, 263.99, 861, 724, 438],
[45.92, 64.73, 80, 109.99, 110, 254.99, 264, 874, 733, 440],
[45.85, 65.24, 80, 110, 109.99, 255, 264, 878, 741, 441],
[45.78, 65.82, 80, 109.99, 109.99, 254.99, 264, 871, 734, 440],
[45.88, 65.32, 79.99, 110, 110, 255, 263.99, 876, 731, 441],
[45.75, 65.92, 79.99, 110, 110, 254.99, 264, 875, 734, 440],
[45.95, 65.11, 80, 109.99, 110, 254.99, 264, 878, 735, 441],
[45.75, 65.04, 79.99, 110, 109.99, 255, 263.99, 880, 739, 441],
[45.65, 65.33, 79.99, 109.99, 110, 255, 263.99, 879, 740, 441],
[45.75, 65.2, 79.99, 110, 110, 255, 264, 874, 740, 441],
[45.78, 64.9, 79.99, 110, 109.99, 254.99, 263.99, 873, 735, 440],
[45.78, 64.81, 79.99, 109.99, 109.99, 254.99, 264, 867, 730, 439],
[45.82, 65.18, 79.99, 109.99, 110, 255, 264, 864, 728, 439],
[45.78, 66.29, 80, 109.99, 109.99, 255, 264, 876, 737, 440],
[45.68, 66.06, 80, 110, 110, 254.99, 263.99, 873, 737, 441],
[45.65, 66.05, 80, 110, 110, 255, 264, 867, 727, 439],
[45.82, 65.82, 79.99, 109.99, 110, 255, 263.99, 861, 723, 438],
[45.62, 65.15, 79.99, 110, 109.99, 254.99, 264, 858, 722, 437],
[45.65, 64.31, 80, 110, 109.99, 255, 263.99, 875, 737, 440],
[45.82, 64.69, 80, 110, 110, 255, 264, 872, 735, 440],
[45.55, 65.23, 79.99, 110, 110, 254.99, 264, 871, 732, 440],
[45.65, 65.33, 80, 110, 110, 255, 263.99, 864, 730, 439],
[45.41, 66.12, 80, 109.99, 109.99, 254.99, 263.99, 869, 730, 439],
[45.35, 65.51, 80, 109.99, 110, 255, 264, 864, 725, 439],
[45.25, 64.42, 80, 110, 110, 254.99, 263.99, 871, 731, 440],
[45.18, 64.03, 79.99, 110, 110, 254.99, 263.99, 869, 733, 439],
[45.12, 64.19, 79.99, 110, 110, 254.99, 264, 872, 734, 440],
[45.25, 63.04, 80, 109.99, 110, 254.99, 264, 868, 730, 439],
[45.05, 63.61, 79.99, 110, 110, 255, 264, 860, 726, 438],
[45.05, 64.78, 80, 110, 110, 255, 264, 865, 731, 439],
[45.28, 65.89, 80, 110, 110, 254.99, 264, 868, 729, 439],
[45.02, 66.21, 80, 110, 110, 255, 263.99, 862, 726, 438],
[45.12, 67.36, 80, 110, 110, 255, 263.99, 855, 724, 437],
[45.08, 68.51, 79.99, 110, 110, 255, 264, 870, 732, 439],
[45.08, 68.07, 80, 110, 110, 254.99, 264, 868, 728, 439],
[44.92, 67.23, 79.99, 110, 110, 255, 264, 879, 742, 441],
[44.95, 68.25, 79.99, 110, 109.99, 254.99, 264, 876, 743, 441],
[44.85, 67.5, 80, 110, 109.99, 255, 264, 882, 744, 441],
[44.82, 65.3, 79.99, 109.99, 110, 255, 264, 879, 739, 441],
[44.82, 65.1, 80, 109.99, 110, 255, 263.99, 872, 735, 441],
[44.62, 63.82, 79.99, 110, 110, 255, 263.98, 868, 731, 440],
[44.62, 63.97, 79.99, 110, 110, 255, 264, 875, 736, 441],
[44.62, 63.03, 80, 110, 110, 255, 263.99, 884, 743, 442],
[44.65, 64.02, 80, 109.99, 109.99, 254.99, 263.99, 875, 741, 441],
[44.55, 65.48, 79.99, 110, 110, 255, 264, 876, 739, 441],
[44.52, 65.26, 79.99, 110, 109.99, 255, 264, 863, 724, 439],
[44.39, 65.04, 80, 110, 110, 254.99, 264, 859, 725, 439],
[44.52, 65.74, 80, 110, 110, 254.99, 264, 869, 730, 440],
[44.23, 65.77, 79.99, 110, 110, 255, 263.99, 873, 740, 441],
[44.29, 65.56, 80, 110, 110, 255, 263.99, 868, 733, 440],
[44.13, 64.56, 79.99, 109.99, 110, 255, 264, 863, 725, 438],
[44.06, 65.68, 79.99, 110, 110, 254.99, 264, 850, 720, 436],
[44.06, 65.45, 79.99, 110, 110, 254.99, 263.99, 847, 719, 436],
[44.06, 66.3, 79.99, 110, 110, 255, 263.99, 841, 711, 434],
[43.97, 66.48, 79.99, 109.99, 110, 255, 263.99, 849, 714, 435],
[43.93, 65.49, 79.99, 110, 110, 254.99, 264, 847, 718, 436],
[44.06, 65.72, 79.99, 110, 110, 255, 263.99, 853, 720, 437],
[43.84, 66.65, 79.99, 109.99, 110, 255, 264, 851, 716, 437],
[43.84, 67.78, 80, 109.99, 109.99, 254.99, 264, 844, 714, 434],
[43.77, 65.52, 79.99, 110, 109.99, 255, 264, 842, 713, 435],
[43.64, 64.58, 79.99, 110, 110, 254.99, 264, 846, 718, 435],
[43.48, 64.44, 80, 110, 110, 255, 264, 848, 719, 435],
[43.42, 64.5, 79.99, 109.99, 110, 254.99, 264, 846, 721, 437],
[43.13, 65.42, 80, 110, 110, 255, 264, 855, 725, 438],
[43.16, 65.02, 80, 110, 110, 254.99, 263.99, 854, 723, 438],
[43, 64.72, 80, 110, 110, 254.99, 264, 854, 724, 438],
[42.87, 64.71, 79.99, 109.99, 110, 255, 263.99, 865, 734, 440],
[42.74, 64.5, 80, 109.99, 110, 254.99, 264, 868, 734, 441],
[42.71, 64.05, 79.99, 110, 110, 255, 264, 869, 737, 441],
[42.3, 66.79, 80, 110, 110, 255, 264, 867, 742, 442],
[42.17, 67.94, 79.99, 110, 109.99, 254.99, 263.99, 860, 730, 440],
[42.01, 66.99, 79.99, 110, 110, 255, 264, 862, 734, 440],
[41.7, 68.05, 80, 110, 110, 255, 264, 866, 733, 441],
[41.67, 68.42, 80, 110, 110, 255, 263.99, 870, 739, 442],
[41.48, 68.25, 79.99, 110, 110, 254.99, 263.99, 870, 737, 442],
[41.45, 66.75, 80, 110, 110, 254.99, 263.98, 868, 743, 443],
[41.05, 66.03, 79.99, 110, 110, 255, 264, 860, 732, 441],
[40.95, 66.87, 79.99, 109.99, 110, 254.99, 264.01, 860, 730, 441],
[40.83, 67.69, 79.99, 110, 110, 255, 264, 860, 729, 442],
[40.58, 67.98, 80, 110, 110, 255, 264, 864, 735, 442],
[40.83, 67.22, 79.99, 110, 110, 254.99, 263.93, 865, 732, 443],
[40.58, 66.4, 80, 109.99, 110, 255, 263.99, 864, 732, 442],
[40.77, 65.61, 79.99, 110, 110, 254.99, 264, 869, 733, 443],
[40.52, 65.27, 79.99, 110, 110, 254.98, 263.99, 868, 741, 443],
[40.68, 66.55, 79.99, 110, 109.99, 255, 263.99, 861, 731, 442],
[40.52, 65.83, 80, 110, 110, 255, 263.87, 870, 740, 443],
[40.52, 66.22, 80, 109.99, 110, 255, 263.99, 868, 737, 443],
[40.4, 68.37, 80, 110, 109.99, 255, 264, 856, 729, 441],
[40.22, 67.61, 79.99, 110, 110, 254.99, 263.99, 857, 733, 441],
[40.22, 67.61, 79.99, 109.99, 110, 254.99, 263.98, 858, 730, 441],
[40.31, 66.72, 79.99, 110, 110, 254.99, 263.99, 856, 730, 441],
[40.18, 66.19, 80, 110, 110, 255, 263.98, 868, 737, 442],
[40.03, 66.25, 79.99, 109.99, 110, 255, 263.99, 855, 725, 441],
[40.03, 66.66, 79.99, 110, 110, 254.99, 263.99, 853, 724, 441],
[40.15, 66.6, 79.99, 109.99, 110, 255, 263.77, 849, 719, 440],
[40.12, 65.37, 80, 109.99, 110, 255, 263.99, 852, 723, 440],
[40.25, 65.68, 80, 110, 110, 255, 264, 853, 719, 441],
[40, 66.29, 79.99, 110, 110, 255, 264, 860, 732, 442],
[40.03, 65.58, 80, 109.99, 110, 254.99, 263.99, 867, 738, 443],
[40, 66.62, 79.99, 110, 110, 255, 263.6, 861, 724, 442],
[39.97, 66.13, 80, 110, 110, 254.99, 264, 868, 736, 443],
[39.97, 66.73, 79.99, 110, 109.99, 254.99, 264, 866, 738, 444],
[39.88, 66.02, 80, 110, 110, 254.99, 263.99, 869, 740, 444],
[39.88, 66.16, 79.99, 110, 110, 254.99, 264, 868, 739, 444],
[39.97, 66.16, 79.99, 110, 110, 255, 262.94, 897, 739, 444],
[39.94, 66.01, 80, 110, 109.99, 254.99, 263.99, 858, 733, 442],
[39.91, 65.43, 79.99, 110, 109.99, 254.99, 264, 856, 726, 441],
[39.97, 64.53, 79.99, 110, 110, 254.99, 264, 860, 730, 443],
[39.94, 65.23, 79.99, 110, 110, 255, 264, 871, 735, 444],
[40, 64.49, 80, 110, 110, 255, 263.45, 863, 731, 443],
[40, 65.66, 80, 109.99, 110, 254.99, 263.99, 859, 727, 442],
[40.03, 66.66, 79.99, 109.99, 109.99, 254.99, 264, 853, 729, 441],
[40.03, 68.03, 80, 109.99, 110, 255, 263.99, 857, 731, 442],
[40.03, 67.26, 79.99, 110, 110, 254.99, 263.99, 867, 735, 443],
[40.06, 66.54, 80, 110, 110, 255, 262.7, 871, 735, 443],
[40.15, 65.23, 79.99, 109.99, 110, 254.99, 263.99, 866, 737, 443],
[40.22, 65.08, 80, 110, 110, 255, 263.99, 865, 735, 443],
[40.06, 65.5, 80, 110, 110, 254.99, 264, 866, 737, 443],
[40, 68.16, 79.99, 110, 110, 254.99, 263.99, 876, 742, 444],
[40.25, 68.01, 79.99, 110, 110, 255.01, 275.67, 881, 741, 444],
[40.03, 66.9, 80, 109.99, 110, 254.99, 263.99, 868, 738, 444],
[39.97, 66.11, 79.99, 110, 110, 254.99, 263.99, 870, 742, 444],
[39.79, 65.31, 79.99, 110, 109.99, 254.99, 263.99, 865, 734, 444],
[39.94, 64.21, 80, 109.99, 110, 254.99, 263.99, 860, 733, 443],
[39.85, 63.78, 80, 110, 110, 255, 263.99, 869, 738, 444],
[39.85, 64.5, 80, 110, 110, 255, 263.99, 868, 739, 444],
[39.85, 63.9, 80, 109.99, 110, 254.99, 264, 870, 742, 444],
[39.73, 65.9, 80, 109.99, 109.99, 254.99, 263.99, 871, 741, 444],
[39.61, 65.63, 80.01, 110, 110, 254.99, 264, 867, 739, 444],
[39.7, 69.64, 79.99, 109.99, 110, 255, 264, 870, 742, 445],
[39.55, 68.71, 80, 110, 110, 255, 264, 867, 744, 445],
[39.55, 66.87, 80, 110, 110, 255, 264, 870, 744, 445],
[39.43, 66.38, 80, 110, 110, 254.99, 264, 873, 748, 445],
[39.39, 65.55, 79.99, 110, 110, 255, 263.99, 869, 740, 445],
[39.36, 64.8, 80, 110, 110, 254.99, 264, 868, 738, 445],
[39.33, 63.89, 80, 110, 110, 254.99, 264, 866, 741, 444],
[39.36, 64.87, 79.99, 109.99, 109.99, 254.99, 264, 865, 736, 445],
[39.39, 65.31, 80, 110, 109.99, 254.99, 263.99, 868, 740, 444],
[39.43, 65.22, 80, 110, 110, 255, 264, 866, 739, 444],
[39.36, 66.98, 80, 109.99, 110, 255, 264, 864, 737, 444],
[39.55, 68.14, 79.99, 110, 110, 255, 264, 861, 733, 444],
[39.3, 68.69, 80, 110, 110, 255, 263.99, 858, 732, 444],
[39.46, 68.76, 80, 110, 109.99, 254.99, 264, 860, 735, 444],
[39.3, 67.76, 79.99, 110, 110, 254.99, 263.99, 865, 741, 445],
[39.3, 66.41, 79.99, 110, 109.99, 255, 263.99, 871, 741, 445],
[39.18, 65.97, 79.99, 109.99, 110, 255, 264, 871, 744, 445],
[39.09, 65.47, 79.99, 110, 110, 255, 264, 863, 737, 444],
[39.15, 66.04, 79.99, 110, 110, 255, 263.99, 856, 729, 443],
[39.12, 64.61, 79.99, 110, 110, 255, 263.99, 860, 732, 444],
[39.06, 65.44, 79.99, 110, 110, 255, 263.99, 871, 741, 445],
[39.09, 64.91, 80, 110, 110, 254.99, 264, 868, 741, 445],
[39.12, 65.7, 80, 110, 109.99, 254.99, 263.99, 860, 732, 444],
[39.12, 66.77, 80, 110, 109.99, 254.99, 263.99, 861, 732, 444],
[39.15, 66.6, 79.99, 110, 109.99, 255, 264, 858, 729, 444],
[39.33, 67.04, 80, 110, 110, 255, 263.99, 865, 738, 445],
[39.18, 65.96, 80, 110, 110, 255, 263.99, 871, 739, 445],
[39.43, 65.7, 79.99, 110, 110, 254.99, 263.99, 873, 744, 446],
[39.24, 64.71, 80, 110, 110, 255, 264, 871, 747, 446],
[39.21, 64.17, 79.99, 109.99, 110, 254.99, 264.01, 869, 740, 445],
[39.24, 63.8, 79.99, 110, 110, 255, 264, 870, 742, 445],
[39.46, 63.23, 79.99, 109.99, 110, 254.99, 264, 872, 742, 446],
[39.43, 64.46, 80, 110, 110, 254.99, 263.99, 866, 740, 445],
[39.21, 63.62, 79.99, 110, 109.99, 255, 264, 870, 741, 445],
[39.18, 63.96, 80, 110, 110, 254.99, 264, 875, 750, 446],
[39.15, 65.54, 79.99, 110, 109.99, 255, 263.99, 869, 737, 446],
[39.3, 66.56, 80, 110, 110, 254.99, 264, 879, 747, 446],
[39.43, 66.67, 79.99, 110, 110, 254.99, 264, 873, 744, 446],
[39.24, 66.08, 80, 110, 110, 255, 264, 865, 741, 445],
[39.09, 66.65, 79.99, 110, 110, 255, 263.99, 866, 741, 445],
[39.12, 66.62, 79.99, 109.99, 110, 255, 263.99, 870, 735, 446],
[39.24, 65.29, 80, 109.99, 110, 254.99, 264, 870, 740, 445],
[39.15, 65.13, 80, 110, 110, 254.99, 264, 868, 736, 445],
[39.33, 65.75, 79.99, 110, 109.99, 255, 264, 865, 734, 444],
[39.18, 65.41, 80, 110, 110, 255, 263.99, 859, 730, 444],
[39.21, 66.36, 79.99, 109.99, 110, 255, 264, 859, 731, 444],
[39.24, 65.92, 80, 110, 110, 254.99, 263.99, 869, 745, 446],
[39.21, 66.75, 80, 110, 109.99, 254.99, 263.99, 864, 739, 445],
[39.15, 66.87, 79.99, 110, 110, 255, 264, 859, 732, 444],
[39.12, 66.34, 80, 110, 110, 255, 263.99, 862, 738, 444],
[39.3, 67.04, 80, 110, 110, 254.99, 263.99, 869, 739, 446],
[39.24, 66.89, 79.99, 110, 110, 255, 264, 871, 742, 445],
[39.12, 65.48, 80, 110, 109.99, 254.99, 263.99, 869, 745, 445],
[39.12, 65.16, 79.99, 110, 109.99, 254.99, 263.99, 869, 741, 446],
[39.06, 64.54, 79.99, 110, 109.99, 255, 264, 865, 740, 445],
[39.15, 64.18, 79.99, 110, 110, 254.99, 263.99, 870, 739, 446],
[39.12, 65.79, 79.99, 109.99, 109.99, 255, 263.99, 877, 741, 445],
[39.18, 64.62, 80, 110, 109.99, 255, 264, 868, 740, 445],
[39.24, 64.95, 80, 110, 109.99, 255, 264, 864, 737, 445],
[39.33, 64.61, 80, 110, 110, 255, 264, 864, 736, 445],
[39.36, 65.05, 79.99, 110, 110, 255, 263.99, 868, 741, 445],
[39.27, 64.58, 79.99, 110, 110, 255, 264, 865, 739, 445],
[39.24, 65.04, 79.99, 110, 110, 254.99, 264, 873, 741, 445],
[39.18, 65.97, 79.99, 110, 110, 255, 263.99, 870, 742, 446],
[39.21, 64.52, 80, 109.99, 110, 254.99, 264, 871, 745, 446],
[39.12, 68.56, 79.99, 110, 110, 255, 264, 871, 744, 446],
[39.36, 68.56, 79.99, 109.99, 110, 254.99, 263.99, 867, 738, 445],
[39.06, 67.61, 80, 110, 110, 255, 264, 875, 745, 446],
[39.18, 66.68, 79.99, 110, 110, 255, 264, 868, 736, 446],
[39.09, 66.19, 80, 110, 110, 254.99, 264, 877, 745, 446],
[39.09, 67.03, 79.99, 110, 110, 254.99, 263.98, 870, 738, 446],
[39.24, 67.05, 80, 110, 110, 255, 264, 872, 740, 446],
[39.06, 65.24, 79.99, 110, 109.99, 254.99, 264, 879, 748, 446],
[39.21, 64.79, 79.99, 110, 109.99, 255, 264, 876, 743, 446],
[39.12, 64.41, 80, 110, 110, 255, 263.99, 869, 739, 446],
[39.27, 63.95, 79.99, 110, 110, 255, 264, 873, 740, 446],
[39.15, 64.07, 80, 110, 110, 255, 264, 872, 739, 446],
[39.15, 65.41, 80, 110, 109.99, 254.99, 264, 877, 742, 445],
[39.39, 65.35, 80, 110, 110, 255, 263.99, 875, 742, 446],
[39.3, 64.76, 79.99, 110, 110, 255, 264, 878, 744, 446],
[39.36, 64.75, 80, 110, 110, 254.99, 264, 876, 742, 445],
[39.46, 65.79, 79.99, 110, 109.99, 254.99, 264, 875, 747, 446],
[39.46, 66.51, 80, 110, 110, 254.99, 264, 873, 742, 445],
[39.46, 65.78, 79.99, 110, 110, 254.99, 264, 870, 738, 445],
[39.58, 67.7, 79.99, 110, 110, 254.99, 264, 872, 737, 445],
[39.58, 66.88, 79.99, 110, 110, 254.99, 263.99, 875, 745, 445],
[39.7, 66.94, 80, 109.99, 110, 255, 264, 872, 742, 445],
[39.67, 67.02, 79.99, 110, 110, 254.99, 264, 875, 739, 445],
[39.67, 65.69, 79.99, 110, 110, 255, 263.99, 868, 736, 444],
[39.61, 65.15, 79.99, 110, 109.99, 254.99, 264, 865, 734, 445],
[39.67, 64.84, 80, 110, 110, 254.99, 263.99, 869, 739, 445],
[39.73, 66.34, 80, 109.99, 110, 255, 263.99, 872, 738, 445],
[39.73, 65.38, 80, 110, 110, 254.99, 264, 877, 744, 445],
[39.76, 65.81, 80, 110, 110, 255, 264, 876, 744, 445],
[39.76, 66.97, 79.99, 110, 110, 255, 264, 875, 740, 445],
[39.79, 66.29, 79.99, 110, 110, 255, 263.99, 871, 734, 444],
[39.85, 66.6, 79.99, 110, 109.99, 255, 264, 874, 739, 445],
[39.73, 65.97, 79.99, 110, 110, 254.99, 264, 869, 739, 445],
[39.85, 65.07, 80, 110, 109.99, 255, 264, 861, 735, 444],
[40, 64.65, 80, 109.99, 110, 255, 264, 872, 745, 445],
[39.91, 66.89, 79.99, 110, 110, 255, 264, 878, 742, 446],
[39.79, 66.83, 79.99, 110, 110, 255, 264, 870, 738, 445],
[39.79, 66.99, 80, 110, 110, 254.99, 263.99, 870, 737, 445],
[39.82, 66.87, 79.99, 110, 110, 254.99, 263.99, 872, 739, 445],
[39.91, 66.86, 79.99, 110, 110, 255, 263.99, 875, 738, 445],
[39.7, 65.82, 80, 109.99, 109.99, 255, 263.99, 869, 732, 444],
[39.82, 65.78, 79.99, 110, 109.99, 255, 264, 870, 734, 444],
[40.03, 64.89, 80, 110, 110, 255, 263.98, 876, 744, 445],
[39.94, 66.18, 80, 110, 110, 255, 263.99, 873, 741, 445],
[40.03, 65.76, 80, 110, 110, 255, 264, 863, 735, 444],
[40.15, 65.47, 79.99, 110, 110, 255, 263.99, 865, 731, 443],
[40.43, 65.29, 80, 110, 110, 255, 264, 870, 741, 445],
[40.55, 66.63, 79.99, 110, 110, 255, 263.98, 879, 741, 444],
[40.43, 66.43, 79.99, 110, 110, 254.99, 264, 870, 740, 445],
[40.74, 65.35, 80, 110, 110, 255, 264, 866, 739, 444],
[40.58, 66.24, 79.99, 110, 110, 255, 264, 871, 739, 444],
[40.83, 64.47, 79.99, 110, 110, 255, 264, 867, 739, 444],
[41.36, 65.52, 79.99, 110, 109.99, 255, 263.99, 887, 740, 444],
[41.61, 66.59, 80, 109.99, 110, 255, 264, 876, 748, 444],
[41.92, 66.9, 79.99, 109.99, 110, 255, 264, 879, 753, 444],
[42.39, 66.26, 80, 110, 110, 255, 264, 872, 749, 443],
[42.49, 68.58, 79.99, 110, 109.99, 255, 264, 862, 739, 441],
[42.81, 68.65, 79.99, 110, 110, 255, 264, 922, 744, 442],
[42.62, 69.74, 79.99, 110, 110, 255, 263.99, 867, 740, 442],
[42.81, 69.25, 80, 109.99, 110, 254.99, 263.99, 868, 737, 441],
[42.81, 69.97, 79.99, 110, 109.99, 255, 264, 860, 731, 441],
[42.55, 69.76, 80, 110, 110, 254.99, 264, 844, 730, 438],
[42.42, 71.11, 79.99, 110, 110, 254.99, 263.99, 845, 721, 438],
[42.49, 69.51, 79.99, 110, 110, 255, 263.99, 864, 732, 441],
[42.62, 68.89, 79.99, 110, 109.99, 255, 263.99, 857, 734, 440],
[42.62, 68.45, 79.99, 110, 109.99, 254.99, 263.99, 863, 733, 441],
[42.55, 67.29, 80, 110, 110, 254.99, 263.99, 868, 742, 441],
[42.65, 67.62, 79.99, 110, 110, 255, 263.99, 875, 744, 442],
[42.77, 67.91, 80, 109.99, 110, 255, 264, 871, 743, 442],
[42.65, 70.19, 80, 110, 110, 255, 263.99, 871, 743, 442],
[42.65, 68.8, 79.99, 110, 110, 254.99, 263.98, 867, 733, 441],
[42.71, 66.92, 80.01, 110, 110, 254.99, 263.99, 867, 740, 442],
[42.68, 69.76, 79.99, 110, 110, 254.99, 264, 866, 735, 441],
[42.77, 69.46, 79.99, 110, 110, 255, 263.99, 867, 736, 441],
[42.84, 69.53, 80, 110, 110, 255, 263.99, 873, 741, 443],
[42.97, 67.88, 80, 110, 110, 254.98, 263.98, 864, 731, 441],
[42.9, 68.04, 80, 110, 110, 255, 264, 865, 736, 441],
[43.09, 66.37, 80, 110, 110, 255, 264, 868, 736, 441],
[43.13, 65.08, 80, 110, 110, 254.99, 263.99, 879, 744, 443],
[43.19, 64.81, 80, 110, 110, 254.99, 263.99, 872, 739, 442],
[43.25, 64.14, 80, 110, 110, 254.99, 263.99, 861, 730, 440],
[43.25, 63.92, 80, 110, 110, 254.99, 264.01, 850, 721, 438],
[43.42, 65.09, 79.99, 110, 110, 255, 264, 850, 723, 439],
[43.32, 66.09, 79.99, 110, 110, 255, 263.99, 850, 720, 438],
[43.51, 66.41, 80, 110, 110, 254.99, 264, 839, 712, 436],
[43.32, 66.05, 80, 109.99, 110, 254.99, 263.97, 845, 718, 437],
[43.32, 68.13, 79.99, 110, 109.99, 254.99, 264, 853, 724, 439],
[43.38, 67.44, 79.99, 110, 110, 255, 264, 853, 730, 439],
[43.42, 66.23, 80, 110, 110, 255, 264, 850, 725, 438],
[43.48, 66.97, 79.99, 110, 110, 255, 264, 860, 728, 439],
[43.67, 66.41, 80, 110, 110, 255, 263.98, 854, 725, 439],
[43.67, 66.11, 80, 110, 110, 255, 263.99, 852, 723, 440],
[43.58, 65.55, 80, 110, 110, 254.99, 263.99, 860, 725, 440],
[43.54, 66.01, 80, 110, 110, 255, 264, 866, 731, 441],
[43.87, 64.91, 79.99, 110, 110, 254.99, 263.99, 872, 736, 441],
[43.67, 63.75, 80, 110, 110, 254.99, 263.97, 875, 740, 442],
[43.67, 61.8, 79.99, 110, 110, 254.99, 264, 860, 728, 440],
[43.74, 61.79, 80, 110, 110, 255, 263.99, 854, 726, 440],
[43.54, 61.77, 80, 110, 109.99, 254.99, 263.99, 864, 731, 441],
[43.61, 61.66, 80, 110, 110, 254.99, 263.99, 869, 737, 442],
[43.58, 61.48, 80, 109.99, 110, 255, 263.97, 864, 735, 441],
[43.54, 61.95, 80, 109.99, 110, 254.99, 264, 857, 727, 440],
[43.54, 60.76, 79.99, 110, 110, 255, 264, 864, 727, 441],
[43.58, 61.31, 80, 109.99, 110, 255, 264, 865, 733, 441],
[43.84, 62.26, 80, 109.99, 109.99, 255, 264, 859, 731, 440],
[43.61, 65, 80, 109.99, 109.99, 254.98, 263.97, 859, 725, 440],
[43.67, 65.02, 80, 110, 110, 255, 264, 840, 711, 435],
[43.8, 65.94, 80, 110, 110, 255, 263.99, 855, 724, 439],
[43.67, 65.92, 80, 110, 110, 255, 263.99, 864, 725, 440],
[44.03, 64.35, 79.99, 110, 110, 255, 264, 863, 730, 441],
[43.8, 63.7, 79.99, 110, 110, 255, 263.96, 865, 727, 441],
[43.74, 62.72, 80, 110, 110, 255, 264, 859, 725, 439],
[43.97, 62.64, 79.99, 110, 109.99, 254.99, 264, 861, 728, 440],
[43.74, 63.37, 80, 109.99, 110, 255, 264, 861, 731, 440],
[43.9, 63.91, 79.99, 109.99, 110, 254.99, 263.99, 861, 728, 440],
[43.71, 64.39, 80, 110, 109.99, 254.99, 263.95, 856, 728, 439],
[43.71, 64.76, 80, 110, 109.99, 254.99, 264, 851, 724, 439],
[43.87, 67, 79.99, 110, 110, 255, 264, 854, 721, 439],
[43.8, 65.58, 80, 110, 110, 254.99, 264, 865, 737, 441],
[43.74, 66.57, 79.99, 110, 110, 255, 263.99, 857, 730, 440],
[43.97, 65.95, 80, 109.99, 110, 255, 263.79, 861, 730, 441],
[43.71, 65.64, 79.99, 110, 110, 254.99, 263.99, 853, 724, 439],
[43.77, 65.26, 79.99, 110, 110, 255, 263.99, 841, 716, 436],
[43.77, 65.48, 80, 110, 109.99, 254.99, 264, 835, 710, 435],
[44, 65.6, 80, 110, 109.99, 254.99, 263.99, 851, 723, 438],
[43.9, 65.14, 80, 110, 110, 255.01, 263.74, 857, 729, 438],
[44.06, 64.22, 80, 110, 109.99, 255, 264, 857, 724, 439],
[43.84, 63.91, 79.99, 110, 110, 254.99, 263.99, 863, 732, 441],
[43.97, 63.6, 79.99, 109.99, 109.99, 254.99, 263.99, 862, 731, 441],
[43.84, 61.87, 80, 110, 109.99, 254.99, 263.99, 864, 729, 441],
[43.77, 61.54, 79.99, 110, 110, 255.02, 263.64, 863, 731, 440],
[43.77, 60.74, 79.99, 110, 109.99, 255, 264, 877, 741, 441],
[43.93, 61.91, 80, 110, 109.99, 254.99, 263.99, 869, 735, 441],
[43.84, 61.93, 80, 109.99, 110, 254.99, 263.99, 868, 735, 441],
[44.06, 62.73, 79.99, 110, 110, 254.99, 264, 873, 740, 442],
[43.8, 63.44, 79.99, 110, 110, 255.01, 263.73, 871, 735, 441],
[43.8, 62.54, 79.99, 110, 109.99, 255, 263.99, 864, 733, 440],
[43.8, 63.76, 80, 110, 110, 255, 263.99, 874, 739, 442],
[43.97, 63.82, 80, 110, 110, 255, 264, 872, 741, 442],
[43.84, 64.21, 79.99, 109.99, 110, 254.99, 264, 861, 729, 441],
[43.8, 64.11, 80, 110, 110, 255.01, 263.69, 865, 736, 441],
[43.67, 66.86, 79.99, 110, 109.99, 255, 263.99, 862, 733, 441],
[43.61, 67.09, 79.99, 110, 110, 254.99, 264, 856, 724, 439],
[43.67, 65.7, 80, 110, 110, 254.99, 263.99, 862, 730, 440],
[43.38, 64.8, 79.99, 110, 110, 255, 264, 854, 724, 439],
[43.29, 64.06, 79.99, 110, 110, 255.07, 263.38, 861, 728, 440],
[43.32, 63.04, 80, 110, 110, 255, 263.99, 864, 728, 441],
[43.09, 63.43, 79.99, 109.99, 110, 255, 263.99, 864, 734, 442],
[43.06, 62.48, 79.99, 110, 110, 255, 263.99, 861, 731, 440],
[43.03, 63.42, 80, 110, 109.99, 255, 264, 862, 728, 440],
[42.97, 63.69, 80, 109.99, 109.99, 255.23, 262.69, 862, 733, 441],
[43.03, 65.06, 80, 109.99, 110, 255, 264, 840, 713, 436],
[42.87, 65.99, 79.99, 110, 110, 254.99, 264, 854, 720, 439],
[42.74, 65.2, 80, 110, 110, 254.99, 264, 859, 729, 440],
[42.58, 65.25, 79.99, 110, 110, 255, 264, 860, 733, 441],
[42.58, 65.78, 80, 110, 110, 255.13, 263.25, 855, 726, 440],
[42.52, 65.68, 80, 109.99, 110, 254.99, 263.99, 842, 715, 438],
[42.39, 66.23, 79.99, 110, 109.99, 254.99, 264, 840, 712, 437],
[42.17, 66.59, 79.99, 110, 110, 254.99, 264, 853, 720, 439],
[42.08, 64.67, 80, 110, 110, 254.99, 263.99, 866, 735, 442],
[42.01, 65.11, 79.99, 109.99, 110, 255.15, 263.33, 871, 741, 442],
[41.79, 64.33, 80, 110, 110, 255, 263.99, 866, 736, 442],
[41.7, 64.02, 79.99, 110, 110, 255, 264, 858, 729, 440],
[41.61, 64.9, 80, 110, 110, 255, 263.99, 869, 732, 442],
[41.51, 64.45, 80, 109.99, 109.99, 255, 264, 868, 737, 442],
[41.26, 65.49, 80, 110, 110, 255.07, 263.7, 870, 735, 443],
[41.23, 65.74, 80, 110, 109.99, 254.99, 264, 864, 732, 442],
[41.23, 64.87, 79.99, 110, 110, 255, 263.99, 868, 733, 442],
[41.14, 65.02, 79.99, 110, 110, 254.99, 264, 852, 722, 441],
[41.08, 64.32, 79.99, 110, 110, 255, 263.99, 865, 734, 442],
[41.02, 64.67, 79.99, 110, 110, 255.18, 263.42, 871, 738, 444],
[40.89, 63.05, 79.99, 110, 110, 254.99, 263.99, 868, 737, 443],
[41.02, 62.93, 79.99, 110, 110, 255, 264, 867, 738, 443],
[40.71, 62.54, 80, 110, 110, 255, 264, 867, 732, 443],
[40.52, 62.14, 80, 110, 110, 254.99, 264, 868, 738, 444],
[40.55, 63.03, 80, 110, 110, 255, 263.99, 872, 739, 443],
[40.55, 64.95, 79.99, 110, 109.99, 254.99, 263.99, 864, 739, 443],
[40.43, 65.21, 79.99, 110, 110, 255, 264, 874, 740, 445],
[40.46, 64.33, 79.99, 110, 110, 255, 264, 870, 734, 444],
[40.28, 65.59, 80, 110, 110, 254.99, 264, 866, 736, 443],
[40.4, 66.79, 80, 109.99, 110, 253.75, 262.65, 866, 735, 444],
[40.18, 66.68, 80, 110, 110, 254.99, 263.99, 868, 734, 443],
[40.25, 65.2, 79.99, 109.99, 110, 255, 264, 866, 735, 444],
[40.28, 66.28, 79.99, 110, 110, 254.99, 263.99, 865, 734, 443],
[40.03, 65.69, 79.99, 110, 110, 255, 264, 857, 728, 442],
[40.03, 65.6, 79.99, 110, 110, 254.99, 264, 859, 733, 443],
[39.91, 66.18, 80, 110, 110, 254.99, 263.99, 860, 731, 443],
[39.91, 65.44, 79.99, 110, 110, 254.99, 263.99, 858, 732, 443],
[40, 66.62, 79.99, 110, 110, 254.99, 264, 857, 730, 442],
[39.94, 66.76, 80, 110, 110, 255, 264, 868, 739, 444],
[40.03, 66.23, 79.99, 110, 110, 255, 264, 883, 745, 444],
[40, 66.81, 79.99, 110, 110, 254.99, 263.99, 877, 741, 445],
[40.03, 66.13, 80, 110, 110, 254.99, 264, 877, 741, 445],
[40.09, 64.38, 80, 110, 110, 255, 264, 884, 748, 444],
[40.18, 63.58, 80, 110, 110, 254.99, 263.99, 877, 743, 445],
[40.06, 64.12, 80, 110, 110, 255, 264, 882, 752, 445],
[40.12, 65.06, 80, 110, 110, 254.99, 263.99, 864, 732, 444],
[40.03, 65.76, 80, 110, 109.99, 254.99, 264, 863, 733, 443],
[40.09, 65.38, 79.99, 110, 109.99, 254.99, 264, 866, 739, 444],
[40.03, 65.78, 79.99, 110, 110, 255, 264, 869, 737, 444],
[39.97, 66.51, 79.99, 110, 109.99, 254.99, 263.99, 852, 723, 441],
[40, 65.48, 80, 110, 110, 255, 264, 859, 734, 443],
[39.88, 67.21, 79.99, 110, 110, 254.99, 264, 865, 739, 444],
[39.88, 66.86, 80, 109.99, 110, 255, 264, 866, 738, 444],
[39.88, 67.48, 79.99, 110, 110, 254.99, 264, 853, 730, 443],
[39.88, 67.12, 80, 110, 110, 255, 264, 855, 726, 443],
[39.7, 66.59, 80, 110, 110, 254.99, 264, 859, 733, 443],
[39.64, 65.39, 80, 110, 110, 254.99, 263.99, 867, 742, 444],
[39.61, 66.47, 79.99, 109.99, 110, 255, 264, 860, 731, 444],
[39.76, 67.12, 80, 109.99, 110, 254.99, 263.99, 849, 726, 442],
[39.46, 67.58, 79.99, 110, 109.99, 254.99, 263.99, 860, 733, 443],
[39.43, 66.35, 80, 110, 110, 255, 263.99, 863, 738, 445],
[39.43, 66.32, 79.99, 109.99, 110, 254.99, 264, 868, 740, 445],
[39.33, 67.29, 79.99, 110, 110, 254.99, 263.99, 860, 734, 444],
[39.39, 67.17, 80, 110, 110, 255, 264, 865, 742, 445],
[39.36, 66.5, 79.99, 110, 110, 254.99, 263.98, 871, 740, 445],
[39.36, 65.6, 80, 110, 110, 255, 263.99, 879, 749, 446],
[39.7, 65.71, 79.99, 110, 110, 255, 263.99, 881, 749, 446],
[39.58, 65.06, 80.01, 110, 110, 255, 264, 872, 744, 445],
[39.67, 69.48, 79.98, 110, 110, 255, 263.99, 872, 744, 445],
[39.7, 68.47, 79.99, 110, 110, 255, 264, 874, 742, 444],
[39.85, 68.37, 79.99, 110, 110, 255, 263.99, 875, 744, 444],
[39.94, 67.34, 80, 109.99, 110, 254.99, 263.99, 873, 744, 445],
[39.82, 65.63, 80, 109.99, 110, 254.99, 263.99, 876, 740, 444],
[40.28, 64.81, 79.99, 110, 110, 254.99, 264, 876, 742, 444],
[40.49, 63.18, 80, 110, 110, 254.99, 263.99, 870, 740, 444],
[40.4, 63.41, 80, 110, 110, 254.99, 264, 878, 745, 444],
[40.4, 65.62, 79.99, 110, 110, 255, 263.99, 872, 739, 444],
[40.49, 65.71, 79.99, 110, 110, 254.99, 264, 874, 742, 444],
[40.43, 65.72, 79.99, 109.99, 109.99, 254.99, 263.99, 875, 743, 445],
[40.49, 65.96, 80, 110, 110, 255, 263.99, 875, 747, 444],
[40.52, 69.45, 79.99, 110, 109.99, 255, 264, 876, 740, 444],
[40.46, 68.24, 80, 110, 110, 255, 263.99, 870, 745, 443],
[40.52, 69.89, 80, 109.99, 109.99, 255, 263.99, 859, 730, 442],
[40.58, 69.24, 79.99, 110, 110, 255, 263.99, 863, 733, 443],
[40.61, 68.37, 80, 110, 110, 255, 264.01, 877, 742, 443],
[40.68, 67.97, 79.99, 110, 110, 255, 264, 868, 739, 443],
[40.77, 66.23, 79.99, 110, 110, 255, 263.99, 861, 726, 442],
[40.64, 65.51, 79.99, 109.99, 110, 255, 264, 865, 731, 442],
[40.68, 65.13, 80, 110, 110, 254.99, 264, 866, 738, 443],
[40.52, 64.78, 80, 110, 110, 255, 264, 869, 735, 443],
[40.71, 64.73, 80, 110, 110, 255, 264, 872, 738, 444],
[40.61, 65.14, 79.99, 110, 110, 255, 264, 877, 739, 444],
[40.52, 64.76, 79.99, 110, 110, 255, 263.99, 871, 738, 444],
[40.43, 66.99, 79.99, 109.99, 110, 255, 264, 871, 738, 444],
[40.28, 68.22, 80, 110, 110, 255, 264, 877, 744, 445],
[40.28, 67.28, 80, 110, 109.99, 254.99, 264, 873, 741, 445],
[40.09, 68.25, 79.99, 110, 110, 255, 264, 865, 733, 444],
[40.06, 68.03, 80, 109.99, 110, 255, 263.99, 864, 741, 444],
[39.91, 67.21, 80, 109.99, 110, 255, 264, 869, 739, 443],
[39.82, 67.05, 79.99, 109.99, 109.99, 255, 264, 855, 727, 443],
[39.73, 66.91, 79.99, 110, 109.99, 254.99, 264, 846, 724, 442],
[39.61, 65.38, 80, 109.99, 110, 254.99, 263.99, 864, 737, 444],
[39.21, 67.1, 79.99, 110, 110, 255, 264, 871, 746, 446],
[39.55, 66.61, 79.99, 110, 110, 254.99, 263.99, 874, 746, 446],
[39.33, 66.95, 80, 110, 110, 254.99, 264, 869, 744, 446],
[39.09, 68.53, 79.99, 110, 110, 255, 263.99, 876, 746, 447],
[39.03, 66.73, 80, 110, 110, 254.99, 263.99, 871, 749, 447],
[38.91, 67.88, 79.99, 110, 110, 255, 264, 876, 747, 447],
[38.88, 67.92, 80, 110, 110, 255, 264, 874, 744, 446],
[38.73, 67.66, 79.99, 110, 110, 255, 263.99, 871, 743, 447],
[39, 65.96, 80.01, 110, 110, 254.99, 264, 871, 748, 447],
[38.61, 70.05, 79.99, 110, 110, 254.99, 263.99, 867, 741, 447],
[38.58, 69.73, 79.99, 109.99, 110, 255, 263.99, 873, 741, 447],
[38.5, 68.16, 79.99, 110, 110, 254.99, 264, 870, 743, 447],
[38.44, 67.49, 79.99, 110, 110, 255, 263.99, 867, 742, 447],
[38.61, 67.23, 79.99, 110, 110, 255, 264, 871, 740, 448],
[38.47, 66.16, 80, 110, 109.99, 255, 263.99, 873, 744, 448],
[38.52, 64.5, 79.99, 110, 109.99, 255, 264, 868, 741, 448],
[38.41, 64.37, 80, 110, 110, 255, 264, 874, 744, 447],
[38.41, 63.58, 80, 110, 109.99, 254.99, 263.99, 872, 742, 448],
[38.41, 64.2, 80, 110, 110, 254.99, 264, 875, 745, 448],
[38.23, 64.74, 79.99, 110, 110, 255, 264, 876, 742, 447],
[38.58, 65.61, 79.99, 109.99, 110, 254.99, 263.99, 867, 738, 447],
[38.7, 66.19, 79.99, 110, 110, 254.99, 264, 865, 740, 446],
[38.82, 67.15, 79.99, 110, 109.99, 255, 264, 866, 734, 446],
[38.88, 66.08, 79.99, 110, 110, 254.99, 264, 865, 734, 446],
[39.12, 66.01, 79.99, 110, 110, 255, 263.99, 861, 733, 445],
[39, 64.77, 80, 110, 110, 254.99, 264, 860, 731, 444],
[39.15, 64.61, 79.99, 110, 110, 254.99, 263.99, 850, 722, 443],
[39.09, 64.47, 80, 110, 110, 255, 263.99, 851, 727, 443],
[39, 63.49, 79.99, 110, 109.99, 255, 264, 855, 727, 444],
[39.09, 63.72, 80, 110, 109.99, 254.99, 264, 855, 731, 444],
[39.15, 65.31, 79.99, 109.99, 109.99, 255, 264, 853, 725, 444],
[39.03, 65.02, 80, 110, 110, 255, 263.99, 849, 723, 443],
[39, 65.79, 79.99, 110, 109.99, 254.99, 263.99, 859, 735, 445],
[38.91, 67.38, 80, 110, 110, 255, 263.99, 845, 718, 443],
[38.97, 65.83, 80, 110, 110, 254.99, 264, 843, 723, 442],
[38.94, 65.15, 80, 110, 110, 254.99, 263.99, 858, 732, 445],
[38.97, 65.13, 80, 110, 110, 254.99, 264, 850, 728, 444],
[38.91, 65.51, 79.99, 109.99, 110, 255, 263.99, 861, 734, 446],
[38.91, 64.5, 80, 110, 109.99, 254.99, 264, 865, 737, 446],
[38.88, 63.18, 80, 109.99, 110, 254.99, 264, 871, 743, 447],
[38.82, 67.38, 79.99, 109.99, 109.99, 255, 264, 858, 733, 446],
[38.73, 66.1, 80, 110, 110, 255, 264, 862, 732, 446],
[38.94, 65.14, 79.99, 110, 110, 255, 263.99, 863, 733, 447],
[38.79, 64.26, 80, 110, 110, 254.99, 263.99, 866, 738, 447],
[38.55, 63.79, 79.99, 110, 110, 255, 263.99, 861, 731, 446],
[38.52, 63.95, 80, 109.99, 110, 254.99, 263.99, 851, 727, 445],
[38.44, 65.48, 80, 110, 110, 254.99, 264, 857, 731, 446],
[38.35, 67.43, 79.99, 110, 110, 254.99, 264, 866, 738, 448],
[38.52, 66.37, 80, 110, 110, 254.99, 263.99, 862, 731, 447],
[38.23, 64.77, 80, 110, 110, 255, 264, 860, 734, 448],
[38.47, 64.68, 80, 110, 110, 254.99, 264, 858, 732, 446],
[38.26, 65.17, 80, 110, 110, 255, 264, 860, 732, 447],
[38.38, 64.27, 80, 110, 110, 254.99, 263.99, 863, 740, 447],
[38.32, 64.1, 80, 110, 110, 255, 264, 868, 743, 448],
[38.5, 64.85, 79.99, 110, 109.99, 254.99, 263.99, 865, 734, 448],
[38.38, 64.81, 80, 110, 109.99, 254.99, 263.99, 862, 739, 447],
[38.5, 66.65, 80, 110, 109.99, 254.99, 264, 862, 732, 447],
[38.58, 64.78, 79.99, 110, 110, 254.99, 264, 857, 737, 446],
[38.55, 66.71, 79.99, 110, 110, 255, 263.99, 862, 739, 446],
[38.7, 66.74, 79.99, 110, 110, 255, 263.99, 853, 724, 444],
[38.76, 65.1, 80, 110, 110, 255, 263.99, 859, 731, 446],
[38.7, 64.66, 79.99, 110, 109.99, 255, 263.99, 875, 748, 447],
[38.73, 64.95, 79.99, 109.99, 110, 255, 264, 861, 732, 446],
[38.97, 65.97, 79.99, 110, 110, 254.99, 264, 859, 738, 446],
[38.76, 65.04, 80, 109.99, 110, 254.99, 264, 869, 740, 447],
[38.82, 64.63, 80, 110, 110, 254.99, 264, 860, 736, 446],
[39.21, 64.28, 80, 110, 109.99, 255, 264, 849, 723, 444],
[39.09, 63.51, 79.99, 110, 110, 254.99, 264, 841, 720, 442],
[39.06, 63.38, 80, 110, 110, 255, 264, 839, 720, 442],
[39.15, 61.88, 80, 110, 110, 254.99, 263.99, 855, 733, 445],
[39.24, 61.94, 80, 110, 110, 255, 264, 858, 731, 445],
[39.52, 61.27, 79.99, 110, 109.99, 255, 263.99, 862, 738, 445],
[39.64, 61.77, 79.99, 110, 109.99, 254.98, 263.99, 847, 723, 443],
[39.79, 62.08, 80, 110, 109.99, 254.99, 263.99, 855, 731, 444],
[39.7, 63.45, 80, 110, 110, 255, 263.99, 857, 736, 444],
[40.28, 65.68, 79.99, 110, 110, 255, 263.99, 863, 742, 445],
[40.58, 66.12, 79.99, 110, 109.99, 255, 263.99, 848, 726, 442],
[40.68, 66.8, 79.99, 109.99, 110, 254.99, 264, 857, 740, 443],
[40.71, 67.92, 80, 110, 110, 254.99, 264, 859, 736, 443],
[40.61, 70.23, 79.99, 110, 110, 255, 264, 870, 747, 445],
[40.49, 69.97, 79.99, 110, 110, 254.99, 264, 869, 742, 444],
[40.52, 69, 80, 110, 110, 255, 263.99, 863, 740, 444],
[40.55, 68.9, 79.99, 110, 110, 254.99, 264, 864, 736, 444],
[40.74, 67.45, 79.99, 110, 110, 255, 263.99, 856, 734, 442],
[40.64, 67.25, 80, 110, 110, 255, 264, 853, 731, 442],
[40.92, 65, 80, 110, 110, 255, 264, 876, 747, 444],
[41.14, 65.38, 80, 110, 110, 254.99, 264, 869, 741, 444],
[40.92, 64.14, 80, 110, 110, 254.98, 264, 871, 743, 443],
[40.95, 64.91, 79.99, 110, 110, 255, 263.99, 864, 742, 444],
[41.05, 64.51, 79.99, 110, 109.99, 255, 264, 869, 740, 443],
[41.02, 64.36, 80, 110, 110, 255, 264, 867, 745, 443],
[41.17, 65.25, 80, 109.99, 110, 254.99, 264, 871, 741, 443],
[41.29, 65.32, 79.99, 110, 109.99, 254.97, 264, 872, 739, 443],
[41.17, 64.93, 79.99, 110, 110, 254.99, 263.99, 863, 733, 443],
[41.2, 65.87, 80, 110, 110, 255, 263.99, 867, 741, 443],
[41.26, 67.09, 79.99, 110, 110, 255, 264, 867, 738, 443],
[41.2, 67.64, 79.99, 109.99, 110, 255, 263.99, 871, 739, 443],
[41.45, 67.04, 79.99, 109.99, 110, 254.99, 264, 868, 738, 443],
[41.33, 67.27, 80, 110, 110, 254.99, 264, 866, 736, 443],
[41.64, 67.46, 79.99, 110, 109.99, 255, 264, 862, 732, 442],
[41.67, 67.75, 80, 110, 109.99, 255, 263.99, 854, 731, 442],
[41.54, 67.36, 79.99, 110, 110, 255, 264, 862, 738, 443],
[41.67, 66.24, 79.99, 110, 110, 254.97, 263.99, 865, 731, 442],
[41.58, 65.73, 80, 110, 110, 255, 264, 848, 724, 440],
[41.76, 64.16, 80, 110, 110, 255, 264, 844, 719, 440],
[41.76, 63.42, 80, 110, 110, 255, 264, 857, 732, 441],
[41.89, 63.5, 79.99, 110, 110, 254.97, 263.99, 853, 726, 440],
[41.98, 63.5, 80, 110, 110, 254.99, 264, 852, 725, 440],
[42.27, 63.05, 80, 110, 110, 254.99, 264, 847, 722, 439],
[41.95, 65.24, 79.99, 110, 110, 255, 264, 861, 732, 441],
[42.14, 66.17, 79.99, 109.99, 110, 254.99, 263.98, 859, 729, 441],
[42.14, 65.72, 79.99, 110, 110, 254.98, 263.99, 861, 733, 441],
[42.23, 66.8, 79.99, 110, 109.99, 255, 264, 861, 733, 441],
[42.42, 66.06, 80, 110, 110, 254.99, 264, 842, 718, 438],
[42.36, 66.23, 79.99, 110, 110, 255, 263.99, 841, 713, 438],
[42.68, 65.5, 79.99, 110, 110, 254.98, 264, 866, 732, 442],
[42.49, 63.99, 80, 110, 110, 254.99, 264, 870, 736, 442],
[42.33, 64.32, 80, 110, 110, 254.99, 264, 862, 732, 442],
[42.65, 62.75, 80, 110, 109.99, 255, 264, 863, 734, 441],
[42.74, 62.3, 79.99, 110, 109.99, 254.99, 263.99, 865, 736, 442],
[42.62, 63.49, 79.99, 110, 110, 254.98, 263.99, 856, 730, 440],
[42.9, 63.06, 80, 110, 110, 254.99, 263.99, 856, 727, 440],
[42.74, 62.15, 79.99, 109.99, 110, 255, 264, 863, 735, 441],
[42.71, 62.28, 80, 110, 110, 254.99, 263.99, 873, 737, 442],
[43.03, 62.94, 79.99, 110, 110, 254.99, 263.99, 869, 736, 442],
[43.16, 62.73, 79.99, 110, 109.99, 254.99, 264, 878, 737, 442],
[43.13, 62.42, 80, 109.99, 110, 254.99, 264, 867, 743, 441],
[43.25, 64.75, 79.99, 109.99, 110, 255, 264, 865, 734, 441],
[43.25, 65.18, 80, 110, 110, 255, 264, 864, 735, 441],
[43.32, 66.88, 80, 110, 110, 254.99, 263.99, 864, 736, 441],
[43.54, 65.51, 80, 110, 110, 255.18, 264, 868, 732, 441],
[43.48, 65.8, 79.99, 110, 110, 254.99, 264, 860, 733, 440],
[43.54, 63.85, 80, 110, 110, 254.99, 263.99, 862, 734, 441],
[43.54, 63.6, 79.99, 109.99, 110, 255, 264, 866, 736, 441],
[43.67, 63.39, 79.99, 110, 109.99, 255, 264, 867, 734, 441],
[43.74, 62.85, 79.99, 109.99, 110, 255.13, 264, 870, 733, 442],
[43.77, 62.48, 80, 110, 110, 254.99, 264, 856, 729, 440],
[43.84, 62.71, 79.99, 109.99, 110, 254.99, 263.99, 850, 723, 438],
[43.9, 64.24, 79.99, 109.99, 109.99, 255, 264, 858, 730, 439],
[44, 63.88, 80, 110, 110, 255, 263.99, 858, 728, 439],
[43.93, 64.39, 80, 110, 110, 255.46, 264, 864, 735, 440],
[44.03, 66.08, 79.99, 110, 110, 255, 264, 861, 731, 440],
[43.97, 67.31, 79.99, 109.99, 110, 255, 264, 862, 731, 440],
[44.06, 65.79, 80, 110, 110, 255, 264, 839, 716, 435],
[44.23, 65.19, 79.99, 110, 110, 254.99, 263.99, 840, 711, 436],
[44.32, 64.73, 79.99, 110, 109.99, 255.25, 264.01, 841, 713, 435],
[44.19, 64, 80, 110, 110, 254.99, 264, 838, 712, 434],
[44.42, 65.57, 79.99, 110, 109.99, 254.99, 264, 836, 711, 434],
[44.23, 65.26, 80, 110, 110, 254.99, 264, 836, 710, 435],
[44.39, 64.01, 80, 110, 110, 254.99, 263.99, 841, 718, 436],
[44.36, 64.07, 79.99, 110, 110, 255.07, 264, 848, 722, 437],
[44.29, 64.57, 79.99, 110, 109.99, 255, 263.99, 842, 717, 436],
[44.39, 64.61, 80, 109.99, 109.99, 254.99, 264, 839, 709, 435],
[44.42, 64.93, 79.99, 110, 110, 254.99, 264, 837, 714, 435],
[44.59, 63.6, 80, 110, 110, 255, 264, 837, 714, 435],
[44.49, 63.58, 79.99, 110, 110, 255.15, 263.99, 841, 711, 435],
[44.49, 63.27, 80, 110, 110, 254.99, 264, 847, 718, 437],
[44.46, 65.56, 79.99, 109.99, 110, 255, 264, 850, 722, 437],
[44.52, 64.69, 80, 109.99, 110, 255, 264, 846, 717, 436],
[44.52, 66.05, 79.99, 110, 109.99, 255, 264, 850, 720, 438],
[44.42, 66.08, 79.99, 110, 110, 255.08, 264, 851, 717, 438],
[44.36, 65.13, 80, 110, 109.99, 255, 263.99, 845, 712, 435],
[44.59, 64.52, 80, 109.99, 110, 254.99, 263.99, 840, 712, 435],
[44.42, 63.85, 80, 110, 110, 255, 263.98, 847, 721, 437],
[44.39, 64.12, 79.99, 110, 110, 254.99, 264, 847, 718, 437],
[44.52, 64.22, 80, 110, 110, 255.13, 264, 853, 718, 437],
[44.32, 65.73, 79.99, 110, 109.99, 254.99, 263.99, 845, 716, 436],
[44.29, 65.75, 79.99, 109.99, 110, 255, 264, 841, 714, 435],
[44.26, 65.53, 79.99, 110, 110, 255, 263.99, 840, 710, 435],
[44.32, 63.55, 79.99, 110, 110, 255, 263.99, 842, 715, 435],
[44.26, 64.23, 79.99, 110, 110, 255.16, 264, 862, 726, 439],
[44.29, 64.06, 80, 110, 109.99, 254.99, 264, 866, 733, 440],
[44.32, 65.32, 79.99, 110, 110, 254.99, 263.99, 857, 727, 439],
[44.29, 66.71, 79.99, 110, 110, 255, 264, 857, 726, 438],
[44.19, 66.11, 79.99, 110, 109.99, 255, 263.99, 836, 714, 435],
[44.42, 64.87, 80, 110, 110, 254.95, 263.98, 848, 725, 438],
[44.16, 63.53, 80, 110, 110, 255, 263.99, 857, 730, 439],
[44.19, 65.74, 80, 110, 109.99, 254.99, 264, 862, 732, 440],
[44.13, 66.13, 80, 110, 110, 255, 264, 862, 738, 440],
[43.97, 66.16, 79.99, 110, 110, 254.99, 264, 859, 723, 439],
[43.9, 67.18, 79.99, 110, 110, 254.4, 264, 849, 723, 438],
[43.97, 66.1, 80, 110, 110, 255, 263.99, 862, 732, 440],
[43.84, 64.76, 79.99, 110, 109.99, 254.99, 263.99, 866, 734, 440],
[43.71, 65.48, 79.99, 110, 110, 254.99, 264, 864, 731, 441],
[43.71, 65.51, 79.99, 109.99, 110, 254.99, 264, 855, 720, 438],
[43.64, 66.37, 79.99, 110, 110, 255.03, 264.01, 856, 717, 437],
[43.45, 65.09, 80, 110, 109.99, 254.99, 263.99, 844, 720, 437],
[43.45, 67.26, 79.99, 109.99, 109.99, 254.99, 263.99, 861, 733, 440],
[43.45, 67.54, 80, 110, 109.99, 254.99, 264, 867, 732, 441],
[43.35, 67.05, 80, 110, 110, 255, 264, 877, 747, 442],
[43.19, 68.43, 80, 109.99, 110, 255.32, 264, 887, 733, 441],
[43.22, 68.55, 80, 110, 110, 254.99, 264, 852, 723, 438],
[43.19, 67.8, 79.99, 110, 110, 255, 264, 856, 722, 438],
[43.35, 65.1, 79.99, 110, 109.99, 255, 264, 851, 723, 438],
[42.93, 64.41, 80, 110, 110, 254.99, 264, 864, 731, 441],
[43.25, 64.76, 79.99, 110, 110, 255, 264.01, 873, 739, 441],
[43.09, 64.24, 80, 110, 110, 255, 263.99, 871, 738, 442],
[43.03, 66.06, 79.99, 110, 109.99, 255, 263.99, 864, 738, 441],
[43.03, 65.55, 80, 109.99, 110, 255, 264, 859, 728, 440],
[43.03, 66.14, 80, 110, 110, 254.99, 264, 869, 741, 441],
[42.81, 67.29, 79.99, 110, 110, 255.3, 264, 889, 735, 442],
[42.77, 66.44, 79.99, 110, 110, 254.99, 264, 864, 739, 442],
[42.62, 66.68, 79.99, 109.99, 110, 254.99, 263.99, 866, 739, 442],
[42.58, 67.62, 79.99, 110, 109.99, 255, 264, 868, 737, 441],
[42.62, 68.22, 80, 110, 110, 255, 264, 859, 733, 441],
[42.46, 67.69, 79.99, 110, 110, 254.99, 264, 871, 734, 441],
[42.33, 69.04, 80, 110, 110, 254.99, 264, 864, 734, 441],
[42.49, 66.97, 80, 110, 110, 254.99, 264, 863, 736, 442],
[42.17, 66.97, 80, 110, 110, 255, 263.99, 858, 725, 440],
[42.14, 66.39, 79.99, 110, 110, 254.99, 264, 846, 719, 437],
[42.23, 65.23, 80, 110, 110, 255.07, 264, 871, 731, 441],
[42.14, 64.51, 80, 110, 110, 255, 263.99, 863, 734, 441],
[42.27, 64.63, 80, 109.99, 110, 255, 263.99, 845, 721, 437],
[42.05, 65.13, 80, 110, 110, 254.99, 264, 838, 713, 436],
[42.05, 65.7, 80, 110, 109.99, 255, 263.99, 851, 726, 439],
[41.95, 64.97, 80, 110, 110, 254.99, 263.99, 857, 727, 440],
[41.92, 65.21, 80, 110, 110, 255, 264, 849, 725, 439],
[41.92, 66.33, 79.99, 110, 110, 254.99, 264, 853, 725, 439],
[41.79, 65.54, 80, 110, 110, 254.99, 263.99, 854, 726, 440],
[41.79, 67.67, 80, 109.99, 110, 254.99, 264, 845, 723, 438],
[41.73, 67.27, 79.99, 110, 110, 255, 264, 855, 731, 440],
[41.92, 65.61, 80, 109.99, 109.99, 255, 264, 862, 731, 441],
[41.86, 65.8, 80, 110, 110, 254.99, 263.99, 857, 726, 440],
[41.95, 67.09, 79.99, 110, 110, 254.99, 263.99, 867, 736, 440],
[42.05, 66.89, 80, 110, 110, 255, 264, 867, 736, 441],
[42.01, 66.48, 79.99, 110, 110, 255, 264, 867, 738, 441],
[41.98, 66.27, 79.99, 110, 110, 254.99, 264, 865, 737, 441],
[41.95, 65.86, 80, 110, 110, 255, 264, 863, 732, 441],
[41.89, 65.01, 80, 109.99, 109.99, 254.99, 264, 848, 722, 439],
[41.79, 66.11, 80, 110, 110, 255, 264, 861, 734, 441],
[41.73, 65.74, 79.99, 110, 110, 254.99, 264, 857, 733, 440],
[41.76, 66.15, 79.99, 110, 109.99, 255, 263.98, 855, 732, 441],
[41.7, 66.7, 79.99, 109.99, 110, 254.99, 264, 869, 744, 442],
[41.61, 67.5, 79.99, 109.99, 110, 255, 263.99, 863, 734, 442],
[41.42, 66.51, 80, 110, 110, 254.99, 263.99, 847, 721, 439],
[41.48, 66.28, 80, 110, 109.99, 254.99, 264, 844, 719, 439],
[41.51, 67.37, 80, 109.99, 110, 254.99, 263.99, 853, 731, 440],
[41.51, 67.86, 79.99, 110, 109.99, 254.99, 264, 856, 733, 440],
[41.33, 66.03, 80, 110, 110, 255, 264, 859, 730, 440],
[41.39, 64.54, 79.99, 110, 110, 255, 264, 853, 727, 439],
[41.51, 64.4, 79.99, 110, 110, 254.99, 263.99, 871, 742, 442],
[41.29, 64.54, 80, 109.99, 109.99, 254.99, 263.99, 872, 747, 443],
[41.36, 68.22, 79.99, 110, 110, 255, 264, 870, 741, 444],
[41.26, 67.24, 79.99, 110, 110, 255, 264, 872, 742, 443],
[41.2, 66.78, 80, 110, 110, 255, 264.01, 860, 733, 441],
[41.14, 66.1, 80, 110, 110, 255, 264, 852, 723, 439],
[41.2, 64.5, 79.99, 109.99, 110, 255, 263.99, 858, 731, 441],
[41.26, 65.15, 80, 110, 109.99, 255, 263.99, 866, 735, 442],
[41.26, 66.15, 79.99, 110, 110, 254.99, 264, 865, 736, 442],
[41.29, 66.63, 80, 110, 110, 255, 264, 866, 737, 442],
[41.17, 65.49, 80, 110, 110, 254.99, 263.99, 865, 737, 442],
[41.14, 65.23, 80, 110, 110, 254.99, 264.01, 855, 731, 441],
[41.14, 65.47, 80, 110, 110, 254.99, 264, 863, 735, 442],
[41.05, 66.6, 80, 110, 110, 255, 263.99, 865, 737, 443],
[41.02, 66.23, 79.99, 110, 110, 255, 264, 868, 738, 443],
[41.23, 64.98, 80, 110, 110, 254.99, 264, 876, 747, 443],
[41.02, 64.66, 80, 110, 110, 255, 263.98, 878, 744, 443],
[40.95, 64.57, 80, 109.99, 110, 255, 264, 866, 734, 443],
[41.02, 64.25, 79.99, 110, 110, 255, 263.99, 864, 734, 442],
[41.11, 64.47, 80, 110, 110, 255, 264, 860, 739, 441],
[41.2, 65.12, 80, 110, 110, 255, 264, 862, 736, 442],
[41.14, 67.29, 79.99, 110, 110, 255, 264, 863, 732, 442],
[41.11, 66.46, 79.99, 110, 110, 255, 264, 869, 739, 442],
[41.33, 65.89, 79.99, 109.99, 110, 255, 264, 866, 740, 442],
[41.26, 65.62, 80, 110, 110, 255, 263.99, 869, 742, 443],
[41.14, 65.34, 79.99, 110, 110, 255, 263.99, 874, 739, 443],
[41.17, 65.33, 79.99, 110, 110, 255, 264, 875, 741, 443],
[41.29, 64.68, 80, 110, 110, 255, 263.99, 873, 745, 443],
[41.45, 64.91, 79.99, 110, 110, 255, 263.99, 865, 737, 442],
[41.45, 64.12, 80, 110, 110, 254.99, 264, 847, 723, 439],
[41.51, 63.37, 80, 110, 110, 254.99, 264, 861, 731, 441],
[41.17, 64.41, 80, 110, 110, 254.99, 263.99, 871, 741, 442],
[41.58, 64.56, 80, 109.99, 110, 254.99, 264, 874, 742, 443],
[41.45, 64.75, 79.99, 110, 110, 255, 264, 870, 733, 442],
[41.45, 64.54, 80, 110, 110, 254.99, 263.99, 864, 734, 442],
[41.51, 64.5, 79.99, 110, 109.99, 254.99, 264, 863, 731, 441],
[41.51, 64.64, 80, 109.99, 110, 255, 263.99, 858, 727, 440],
[41.51, 65.41, 79.99, 110, 109.99, 255, 264, 860, 727, 440],
[41.67, 65.03, 80, 109.99, 110, 254.99, 263.99, 858, 730, 441],
[41.39, 65.58, 79.99, 110, 110, 254.99, 264, 870, 739, 443],
[41.64, 65.12, 79.99, 110, 109.99, 255, 264, 867, 730, 442],
[41.54, 65.39, 79.99, 110, 110, 255, 264, 872, 739, 443],
[41.58, 63.99, 80, 109.99, 110, 255, 264, 875, 740, 442],
[41.58, 63.93, 80, 110, 110, 254.99, 263.99, 873, 739, 443],
[41.48, 64.12, 80, 110, 110, 255, 264, 876, 741, 443],
[41.48, 65.04, 79.99, 110, 109.99, 255, 263.98, 870, 737, 443],
[41.45, 65.42, 79.99, 109.99, 110, 254.99, 264, 865, 737, 442],
[41.45, 65.85, 80, 110, 110, 254.99, 264, 881, 749, 443],
[41.67, 65.5, 80, 110, 110, 254.99, 263.99, 876, 745, 443],
[41.51, 64.58, 80, 110, 110, 255, 263.99, 879, 746, 443],
[41.51, 64.75, 80, 110, 110, 255, 263.98, 878, 748, 444],
[41.54, 65.62, 80, 110, 109.99, 255, 263.99, 871, 743, 443],
[41.58, 66.44, 80, 110, 110, 255, 264, 872, 740, 443],
[41.42, 66.09, 80, 110, 110, 254.99, 264, 879, 745, 443],
[41.51, 65.43, 80, 110, 109.99, 255, 263.99, 878, 746, 444],
[41.36, 65.26, 79.99, 110, 110, 254.99, 263.99, 875, 743, 443],
[41.39, 65, 80, 109.99, 110, 255, 264, 870, 740, 442],
[41.51, 66.57, 79.99, 110, 110, 255, 264, 866, 735, 442],
[41.45, 66.04, 80, 110, 110, 255, 263.99, 874, 744, 443],
[41.7, 66.04, 79.99, 110, 110, 254.99, 263.99, 875, 738, 443],
[41.51, 65.29, 79.99, 110, 110, 255, 263.99, 871, 736, 443],
[41.54, 66.05, 80, 109.99, 110, 254.99, 263.99, 868, 737, 442],
[41.61, 66.64, 80, 109.99, 110, 255, 263.99, 864, 733, 441],
[41.54, 66.82, 79.99, 110, 110, 254.99, 264, 856, 729, 440],
[41.54, 66.63, 80, 110, 110, 254.99, 264, 861, 732, 442],
[41.54, 65.3, 79.99, 110, 110, 255, 264, 866, 739, 443],
[41.51, 64.74, 80, 109.99, 110, 255, 263.99, 868, 736, 442],
[41.39, 64.41, 80, 110, 109.99, 254.99, 263.99, 862, 732, 442],
[41.58, 65.27, 80, 110, 109.99, 255, 264, 848, 724, 440],
[41.48, 66.16, 79.99, 110, 110, 254.99, 263.99, 856, 726, 441],
[41.36, 66.33, 80, 110, 110, 255, 264, 861, 730, 441],
[41.48, 65.29, 80, 110, 110, 255, 263.99, 871, 738, 442],
[41.36, 66.09, 80, 110, 110, 254.99, 264, 869, 737, 442],
[41.45, 65.3, 80, 110, 110, 254.99, 264, 867, 733, 443],
[41.48, 65.73, 80, 110, 110, 254.99, 264, 869, 738, 442],
[41.67, 65.33, 80, 110, 110, 254.99, 264, 859, 724, 441],
[41.61, 64.09, 80, 110, 110, 255, 263.99, 858, 725, 441],
[41.61, 63.41, 80, 110, 110, 255, 264, 863, 731, 441],
[41.76, 64.24, 79.99, 109.99, 110, 254.99, 264, 869, 734, 442],
[41.83, 64.44, 79.99, 110, 110, 255, 263.99, 883, 748, 443],
[41.86, 65.15, 79.99, 110, 110, 254.99, 264, 871, 740, 442],
[41.89, 65.45, 80, 110, 110, 255, 264.01, 872, 740, 442],
[41.89, 66.54, 80, 110, 110, 255, 263.99, 876, 741, 443],
[41.89, 67.5, 80, 109.99, 109.99, 254.99, 263.99, 875, 746, 443],
[42.01, 67.15, 79.99, 110, 110, 255, 263.99, 884, 749, 443],
[41.92, 67.63, 79.99, 110, 110, 255, 264, 874, 744, 443],
[41.95, 67.39, 80, 110, 110, 255, 263.99, 862, 729, 441],
[42.11, 66.15, 80, 110, 110, 254.99, 264, 866, 732, 442],
[41.95, 65.92, 79.99, 109.99, 110, 255, 264, 865, 733, 441],
[41.76, 64.75, 79.99, 110, 110, 255, 264, 866, 736, 442],
[41.89, 64.67, 79.99, 110, 110, 254.99, 264, 865, 732, 441],
[41.73, 64.83, 80, 109.99, 110, 255, 264, 851, 721, 440],
[41.73, 63.81, 79.99, 110, 110, 255, 264, 851, 721, 439],
[41.76, 64.03, 80, 109.99, 109.99, 254.99, 264.01, 856, 729, 440],
[41.64, 64.27, 80, 110, 109.99, 255, 263.99, 858, 730, 441],
[41.67, 65.15, 79.99, 110, 110, 255, 263.99, 863, 731, 441],
[41.67, 65.69, 79.99, 110, 110, 255, 263.99, 863, 732, 442],
[41.79, 64.56, 79.99, 109.99, 110, 254.99, 264, 865, 731, 442],
[41.48, 64.11, 80, 109.99, 110, 255, 264, 859, 725, 440],
[41.7, 63.89, 80, 110, 110, 255, 264, 860, 727, 441],
[41.61, 64.41, 80, 110, 109.99, 255, 264, 862, 731, 441],
[41.79, 64.57, 80, 110, 109.99, 254.99, 263.99, 861, 730, 441],
[41.64, 65.01, 79.99, 109.99, 110, 255, 264, 863, 732, 442],
[41.79, 65.62, 80, 110, 110, 255, 263.99, 869, 737, 442],
[31.4, 40.31, 77.31, 84.31, 82.27, 254.99, 263.99, 860, 733, 444],
[33.71, 39.32, 77.01, 83.28, 81.33, 254.99, 264, 860, 733, 443],
[34.81, 38.36, 76.71, 82.34, 80.49, 255, 263.99, 866, 736, 444],
[35.15, 37.57, 76.4, 81.49, 79.74, 254.99, 264, 866, 735, 443],
[36.01, 36.89, 76.09, 80.73, 79.07, 254.99, 264, 866, 736, 444],
[36.24, 36.23, 75.78, 80.02, 78.45, 254.99, 263.99, 872, 738, 445],
[36.5, 35.63, 75.46, 79.37, 77.89, 255, 264, 848, 717, 441],
[36.67, 35.09, 75.14, 78.77, 77.36, 254.99, 263.99, 852, 719, 442],
[36.96, 34.61, 74.82, 78.2, 76.87, 254.99, 264, 858, 723, 442],
[37.2, 34.21, 74.5, 77.67, 76.4, 254.99, 264, 860, 729, 443],
[37.55, 33.81, 74.16, 77.16, 75.96, 254.99, 263.99, 872, 733, 444],
[37.61, 33.4, 73.84, 76.69, 75.54, 255, 264, 870, 732, 444],
[37.72, 33.08, 73.51, 76.23, 75.13, 254.99, 264, 863, 726, 443],
[37.93, 32.75, 73.18, 75.79, 74.73, 255, 263.99, 862, 724, 443],
[38.17, 32.46, 72.85, 75.36, 74.35, 255, 263.99, 864, 723, 443],
[38.41, 32.17, 72.52, 74.95, 73.97, 255, 264, 869, 727, 443],
[38.32, 31.92, 72.19, 74.55, 73.6, 254.99, 264, 869, 727, 444],
[38.23, 31.68, 71.87, 74.16, 73.24, 254.99, 264, 871, 728, 443],
[38.58, 31.44, 71.54, 73.78, 72.89, 254.99, 264, 853, 711, 441],
[38.55, 31.19, 71.2, 73.41, 72.53, 254.99, 263.99, 851, 711, 441],
[38.64, 31.01, 70.88, 73.04, 72.19, 254.99, 264, 856, 714, 441],
[38.79, 30.83, 70.56, 72.68, 71.85, 254.99, 263.99, 858, 716, 442],
[38.67, 30.63, 70.22, 72.32, 71.51, 254.99, 263.99, 857, 715, 441],
[38.88, 30.44, 69.9, 71.98, 71.17, 255, 264, 843, 703, 438],
[38.91, 30.3, 69.58, 71.63, 70.84, 254.99, 264, 845, 704, 439],
[39.12, 30.13, 69.27, 71.29, 70.51, 254.99, 263.99, 849, 707, 440],
[39.18, 30, 68.94, 70.95, 70.18, 254.99, 264, 857, 715, 441],
[39.24, 29.86, 68.62, 70.61, 69.86, 255, 263.99, 865, 718, 442],
[39.3, 29.73, 68.31, 70.29, 69.54, 254.99, 264.01, 855, 711, 441],
[39.43, 29.59, 67.99, 69.96, 69.21, 255, 263.99, 849, 707, 440],
[39.46, 29.49, 67.68, 69.63, 68.9, 255, 264, 851, 709, 440],
[39.61, 29.38, 67.37, 69.31, 68.58, 255, 264, 860, 714, 441],
[39.64, 29.3, 67.05, 68.99, 68.27, 254.99, 263.99, 859, 717, 442],
[39.73, 29.21, 66.75, 68.67, 67.95, 255, 264, 867, 720, 442],
[39.76, 29.12, 66.44, 68.35, 67.64, 255, 264, 852, 708, 440],
[39.85, 29.01, 66.14, 68.04, 67.34, 255, 263.99, 842, 700, 438],
[39.82, 28.96, 65.84, 67.73, 67.03, 255, 263.99, 850, 709, 440],
[39.91, 28.84, 65.53, 67.42, 66.73, 254.99, 264, 852, 710, 441],
[40.12, 28.79, 65.24, 67.11, 66.43, 254.99, 264, 855, 713, 441],
[40.06, 28.71, 64.94, 66.81, 66.12, 255, 264, 853, 710, 441],
[40.25, 28.66, 64.65, 66.5, 65.83, 254.99, 263.99, 851, 710, 440],
[40.25, 28.62, 64.35, 66.2, 65.53, 255, 264, 844, 700, 438],
[40.25, 28.53, 64.06, 65.91, 65.24, 254.99, 264, 845, 702, 438],
[40.28, 28.46, 63.77, 65.61, 64.95, 254.99, 263.98, 854, 708, 440],
[40.37, 28.44, 63.48, 65.32, 64.66, 255, 264, 860, 713, 441],
[40.4, 28.38, 63.2, 65.03, 64.37, 255, 264, 858, 709, 440],
[40.52, 28.38, 62.92, 64.73, 64.09, 254.99, 264, 851, 706, 439],
[40.58, 28.28, 62.64, 64.45, 63.8, 255, 264, 847, 703, 439],
[40.68, 28.27, 62.36, 64.16, 63.52, 255, 264, 851, 704, 439],
[40.8, 28.23, 62.07, 63.88, 63.24, 254.99, 263.99, 854, 709, 440],
[40.98, 28.18, 61.8, 63.6, 62.97, 254.99, 264, 856, 711, 440],
[41.02, 28.18, 61.53, 63.32, 62.69, 254.99, 263.99, 854, 708, 439],
[40.98, 28.12, 61.26, 63.04, 62.42, 254.99, 264, 851, 706, 439],
[40.95, 28.11, 60.99, 62.76, 62.15, 254.99, 264, 855, 707, 439],
[41.2, 28.09, 60.73, 62.49, 61.88, 255, 263.99, 863, 714, 441],
[41.79, 28.06, 60.46, 62.22, 61.61, 255, 264, 869, 716, 441],
[41.23, 28.02, 60.2, 61.95, 61.35, 254.99, 263.99, 864, 714, 441],
[41.26, 27.99, 59.94, 61.68, 61.09, 254.99, 263.99, 860, 712, 440],
[41.45, 27.95, 59.68, 61.42, 60.83, 255, 263.99, 864, 715, 441],
[41.39, 27.91, 59.43, 61.16, 60.57, 254.99, 264, 864, 716, 441],
[41.48, 27.92, 59.17, 60.89, 60.31, 255, 263.99, 864, 714, 441],
[41.54, 27.91, 58.92, 60.64, 60.06, 255, 264, 863, 716, 441],
[41.7, 27.93, 58.67, 60.38, 59.81, 255, 263.99, 857, 711, 440],
[41.51, 27.9, 58.42, 60.13, 59.56, 254.99, 263.99, 852, 706, 439],
[41.83, 27.87, 58.17, 59.87, 59.31, 254.99, 264, 854, 704, 438],
[41.7, 27.86, 57.93, 59.62, 59.06, 254.99, 263.99, 862, 711, 440],
[41.95, 27.84, 57.69, 59.37, 58.82, 255, 264, 867, 717, 441],
[41.83, 27.83, 57.44, 59.12, 58.58, 254.99, 264, 860, 712, 440],
[41.79, 27.79, 57.21, 58.88, 58.34, 254.99, 264, 853, 705, 438],
[42.01, 27.79, 56.97, 58.64, 58.1, 254.99, 263.99, 844, 698, 437],
[41.95, 27.77, 56.74, 58.4, 57.86, 255, 264, 855, 707, 439],
[42.05, 27.74, 56.5, 58.16, 57.63, 255, 263.99, 855, 707, 439],
[41.95, 27.75, 56.27, 57.92, 57.39, 255, 264, 857, 710, 440],
[42.01, 27.74, 56.04, 57.69, 57.16, 255, 264, 851, 702, 438],
[42.08, 27.72, 55.81, 57.45, 56.93, 254.99, 263.99, 836, 695, 435],
[42.05, 27.67, 55.59, 57.22, 56.71, 255, 264, 851, 707, 439],
[42.08, 27.65, 55.36, 56.99, 56.48, 255, 264, 859, 711, 439],
[42.17, 27.62, 55.14, 56.76, 56.25, 254.99, 264, 857, 711, 439],
[41.95, 27.6, 54.92, 56.54, 56.03, 255, 264, 850, 704, 438],
[42.11, 27.56, 54.7, 56.31, 55.81, 254.99, 264, 842, 696, 436],
[42.11, 27.56, 54.48, 56.09, 55.59, 254.99, 263.99, 853, 705, 439],
[42.08, 27.49, 54.26, 55.86, 55.37, 254.99, 264, 849, 702, 437],
[42.08, 27.48, 54.05, 55.64, 55.16, 255, 264, 849, 702, 437],
[42.11, 27.44, 53.84, 55.43, 54.94, 255, 264, 854, 707, 439],
[42.05, 27.35, 53.62, 55.21, 54.73, 254.99, 263.99, 858, 712, 440],
[41.98, 27.35, 53.41, 54.99, 54.52, 254.99, 264, 847, 700, 437],
[42.08, 27.36, 53.2, 54.78, 54.31, 255, 264, 843, 693, 435],
[42.3, 27.26, 53, 54.57, 54.1, 255, 264, 849, 702, 438],
[41.95, 27.23, 52.79, 54.36, 53.89, 254.99, 264, 853, 704, 438],
[42.2, 27.21, 52.58, 54.15, 53.69, 255, 263.99, 858, 708, 439],
[42.14, 27.19, 52.38, 53.94, 53.48, 254.99, 263.99, 854, 704, 439],
[42.2, 27.13, 52.18, 53.73, 53.28, 255, 264, 847, 700, 437],
[42.33, 27.04, 51.98, 53.53, 53.08, 255, 264, 859, 707, 439],
[42.27, 27.02, 51.78, 53.32, 52.88, 254.99, 264, 859, 710, 440],
[42.08, 26.96, 51.58, 53.12, 52.68, 254.99, 264, 865, 715, 441],
[42.08, 26.91, 51.39, 52.92, 52.48, 255, 263.99, 869, 715, 441],
[42.01, 26.89, 51.19, 52.72, 52.28, 254.99, 263.99, 861, 711, 440],
[42.01, 26.8, 50.99, 52.52, 52.09, 255, 264, 864, 711, 440],
[41.95, 26.76, 50.8, 52.33, 51.89, 254.99, 264, 869, 717, 441],
[41.89, 26.69, 50.61, 52.13, 51.7, 255, 264, 865, 715, 441],
[41.76, 26.62, 50.42, 51.93, 51.51, 255, 263.99, 868, 718, 441],
[41.86, 26.58, 50.23, 51.74, 51.32, 255, 264, 863, 714, 441],
[41.61, 26.5, 50.04, 51.55, 51.13, 255, 263.99, 857, 709, 440],
[41.67, 26.41, 49.86, 51.36, 50.94, 255, 264, 855, 707, 439],
[41.42, 26.33, 49.66, 51.17, 50.75, 254.99, 264, 857, 709, 440],
[41.26, 26.26, 49.48, 50.98, 50.56, 255, 263.99, 862, 711, 441],
[41.23, 26.18, 49.29, 50.79, 50.38, 254.99, 264, 860, 714, 441],
[40.98, 26.08, 49.11, 50.6, 50.19, 255, 263.98, 853, 705, 440],
[40.95, 25.95, 48.93, 50.41, 50.01, 255, 264, 845, 697, 437],
[40.95, 25.88, 48.75, 50.23, 49.82, 255, 264, 860, 709, 440],
[40.68, 25.76, 48.56, 50.04, 49.64, 254.99, 263.99, 857, 709, 440],
[40.77, 25.65, 48.38, 49.86, 49.46, 255, 264, 868, 717, 441],
[40.52, 25.54, 48.19, 49.67, 49.28, 255, 263.99, 865, 714, 441],
[40.46, 25.5, 48.02, 49.49, 49.09, 254.99, 263.99, 866, 712, 442],
[40.37, 25.32, 47.84, 49.31, 48.91, 254.99, 264, 861, 712, 441],
[40.28, 25.22, 47.66, 49.13, 48.73, 254.99, 264, 857, 706, 440],
[40.22, 25.1, 47.49, 48.95, 48.56, 254.99, 264, 866, 712, 441],
[40.25, 25.03, 47.31, 48.77, 48.38, 255, 263.99, 862, 710, 441],
[40, 24.92, 47.13, 48.59, 48.2, 255, 264, 857, 707, 441],
[39.94, 24.8, 46.95, 48.41, 48.02, 255, 264, 862, 711, 441],
[39.97, 24.66, 46.78, 48.23, 47.85, 255, 264, 866, 716, 442],
[39.91, 24.57, 46.6, 48.06, 47.67, 254.99, 263.99, 868, 714, 442],
[39.85, 24.47, 46.43, 47.88, 47.5, 254.99, 263.99, 869, 715, 442],
[39.85, 24.37, 46.25, 47.7, 47.32, 255, 263.99, 877, 722, 443],
[39.88, 24.29, 46.08, 47.53, 47.15, 254.99, 263.99, 868, 714, 443],
[39.79, 24.2, 45.91, 47.36, 46.98, 254.99, 263.99, 869, 716, 443],
[39.94, 24.15, 45.74, 47.18, 46.81, 254.99, 264, 868, 716, 443],
[39.67, 24.03, 45.57, 47.01, 46.63, 254.99, 263.99, 872, 717, 443],
[39.7, 23.94, 45.4, 46.84, 46.46, 254.99, 264, 871, 716, 443],
[39.61, 23.85, 45.23, 46.66, 46.29, 255, 264, 869, 715, 443],
[39.61, 23.76, 45.06, 46.49, 46.13, 254.99, 264, 872, 720, 443],
[39.49, 23.69, 44.9, 46.32, 45.96, 255, 264, 871, 716, 443],
[39.36, 23.6, 44.73, 46.15, 45.79, 254.99, 263.99, 874, 718, 443],
[39.33, 23.53, 44.56, 45.99, 45.62, 254.99, 263.99, 872, 720, 443],
[39.43, 23.45, 44.4, 45.82, 45.46, 254.99, 263.99, 865, 712, 442],
[39.3, 23.37, 44.24, 45.65, 45.29, 254.99, 264, 869, 715, 443],
[39.18, 23.26, 44.07, 45.49, 45.13, 254.99, 263.99, 874, 717, 443],
[39.15, 23.19, 43.91, 45.32, 44.97, 255, 264, 877, 720, 444],
[39.21, 23.12, 43.75, 45.16, 44.8, 254.99, 263.99, 877, 720, 444],
[38.97, 23.05, 43.59, 44.99, 44.64, 255, 264, 874, 717, 443],
[38.97, 22.97, 43.42, 44.83, 44.48, 255, 264, 872, 720, 444],
[39.09, 22.88, 43.27, 44.67, 44.32, 254.99, 263.99, 876, 718, 444],
[38.85, 22.81, 43.11, 44.51, 44.16, 255, 263.99, 872, 718, 444],
[39, 22.72, 42.95, 44.34, 44, 255, 263.99, 867, 716, 443],
[38.73, 22.63, 42.79, 44.18, 43.84, 254.99, 263.99, 876, 722, 444],
[38.64, 22.55, 42.63, 44.02, 43.68, 255, 264, 870, 716, 445],
[38.58, 22.51, 42.48, 43.86, 43.52, 255, 264, 868, 717, 444],
[38.64, 22.38, 42.31, 43.71, 43.37, 255, 264, 869, 714, 444],
[38.55, 22.31, 42.16, 43.55, 43.21, 254.99, 263.99, 867, 714, 444],
[39, 22.23, 42.01, 43.39, 43.06, 254.99, 263.99, 857, 703, 442],
[38.41, 22.14, 41.85, 43.23, 42.9, 254.99, 263.99, 861, 710, 443],
[38.47, 22.08, 41.7, 43.08, 42.75, 254.99, 264, 870, 716, 444],
[38.44, 22.04, 41.55, 42.92, 42.59, 255, 263.99, 869, 716, 444],
[38.47, 21.95, 41.4, 42.77, 42.44, 255, 264, 862, 707, 443],
[38.41, 21.86, 41.25, 42.62, 42.29, 255, 263.99, 854, 705, 442],
[38.08, 21.82, 41.1, 42.46, 42.14, 255, 263.99, 860, 710, 443],
[38.26, 21.7, 40.95, 42.31, 41.99, 254.99, 264, 871, 718, 445],
[37.99, 21.64, 40.8, 42.16, 41.84, 255, 264, 874, 719, 445],
[37.93, 21.58, 40.65, 42.01, 41.68, 254.99, 263.99, 873, 717, 445],
[37.9, 21.49, 40.5, 41.86, 41.54, 254.99, 264, 872, 718, 445],
[38.02, 21.42, 40.35, 41.71, 41.39, 254.99, 263.98, 872, 716, 445],
[37.99, 21.38, 40.21, 41.56, 41.24, 255, 263.99, 885, 724, 445],
[38.02, 21.32, 40.06, 41.41, 41.1, 254.99, 264, 877, 718, 445],
[37.99, 21.26, 39.92, 41.26, 40.95, 254.99, 264, 884, 725, 445],
[38.11, 21.24, 39.77, 41.12, 40.81, 254.99, 264, 872, 717, 445],
[38.14, 21.19, 39.63, 40.97, 40.66, 255, 264, 871, 715, 445],
[38.26, 21.14, 39.48, 40.83, 40.52, 255, 263.99, 871, 716, 444],
[38.23, 21.08, 39.34, 40.68, 40.38, 254.99, 263.99, 870, 715, 445],
[38.44, 21.09, 39.2, 40.54, 40.23, 255.01, 264, 872, 717, 445],
[38.32, 21.06, 39.07, 40.4, 40.09, 254.99, 263.99, 869, 715, 444],
[38.35, 21.02, 38.93, 40.25, 39.95, 255, 264, 864, 712, 444],
[38.5, 21, 38.79, 40.11, 39.81, 255, 264, 869, 714, 444],
[38.47, 20.97, 38.65, 39.98, 39.68, 254.99, 264, 867, 711, 444],
[38.35, 20.94, 38.52, 39.83, 39.54, 255, 263.99, 870, 713, 444],
[38.32, 20.91, 38.38, 39.7, 39.4, 255, 263.99, 857, 705, 443],
[38.58, 20.88, 38.25, 39.56, 39.27, 254.99, 264, 857, 706, 443],
[38.35, 20.86, 38.11, 39.42, 39.13, 254.99, 263.99, 854, 701, 442],
[38.38, 20.83, 37.98, 39.28, 39, 254.99, 263.99, 859, 707, 443],
[38.44, 20.76, 37.84, 39.15, 38.87, 254.99, 264, 869, 712, 444],
[38.35, 20.75, 37.71, 39.02, 38.74, 254.99, 264, 873, 715, 445],
[38.29, 20.71, 37.58, 38.88, 38.61, 254.99, 264, 865, 710, 443],
[38.38, 20.67, 37.45, 38.75, 38.47, 255, 264, 857, 703, 442],
[38.47, 20.64, 37.33, 38.62, 38.34, 255, 264, 864, 709, 443],
[38.47, 20.63, 37.19, 38.49, 38.22, 255, 263.99, 874, 715, 444],
[38.44, 20.59, 37.06, 38.36, 38.09, 254.99, 263.99, 876, 717, 445],
[38.47, 20.56, 36.94, 38.23, 37.96, 255, 264, 875, 716, 445],
[38.44, 20.52, 36.81, 38.1, 37.83, 255, 264, 878, 718, 445],
[38.41, 20.49, 36.69, 37.97, 37.71, 255, 264, 881, 720, 445],
[38.38, 20.47, 36.57, 37.84, 37.58, 255, 264, 875, 716, 445],
[38.35, 20.41, 36.44, 37.72, 37.46, 254.99, 264, 881, 721, 446],
[38.32, 20.37, 36.31, 37.59, 37.33, 254.99, 263.99, 881, 719, 446],
[38.17, 20.32, 36.19, 37.46, 37.21, 254.99, 264, 873, 714, 445],
[38.5, 20.28, 36.07, 37.34, 37.09, 255, 264, 878, 718, 445],
[38.26, 20.25, 35.95, 37.22, 36.96, 255, 264, 873, 715, 445],
[38.23, 20.2, 35.83, 37.09, 36.84, 254.99, 264, 872, 714, 445],
[38.26, 20.14, 35.71, 36.97, 36.72, 255, 264, 879, 719, 446],
[38.26, 20.09, 35.58, 36.85, 36.6, 255, 264, 873, 716, 445],
[38.29, 20.05, 35.47, 36.73, 36.48, 254.99, 264, 875, 716, 446],
[38.14, 20.03, 35.35, 36.61, 36.36, 255, 264, 877, 717, 446],
[38.23, 19.97, 35.23, 36.49, 36.24, 255, 264, 877, 716, 446],
[38.05, 19.93, 35.12, 36.37, 36.13, 254.99, 264, 876, 715, 446],
[38.08, 19.9, 35, 36.25, 36.01, 255, 264, 869, 712, 445],
[38.05, 19.84, 34.89, 36.13, 35.89, 255, 264, 873, 712, 446],
[38.05, 19.82, 34.77, 36.01, 35.78, 255, 263.99, 874, 715, 446],
[38.02, 19.77, 34.65, 35.9, 35.66, 254.99, 263.99, 874, 716, 446],
[38.02, 19.72, 34.54, 35.78, 35.55, 255, 264, 872, 714, 445],
[38.02, 19.7, 34.43, 35.66, 35.43, 254.99, 264, 870, 714, 445],
[38.02, 19.66, 34.31, 35.55, 35.32, 255, 264, 875, 716, 446],
[38.11, 19.63, 34.2, 35.43, 35.21, 255, 264, 874, 716, 446],
[37.99, 19.58, 34.09, 35.32, 35.1, 255, 264, 883, 722, 446],
[38.08, 19.56, 33.98, 35.21, 34.99, 255, 264, 879, 715, 446],
[38.08, 19.55, 33.87, 35.09, 34.87, 255, 263.99, 874, 715, 445],
[38.2, 19.48, 33.75, 34.98, 34.76, 254.99, 263.99, 874, 713, 445],
[38.08, 19.47, 33.65, 34.87, 34.65, 254.99, 264, 876, 715, 446],
[38.05, 19.44, 33.54, 34.76, 34.55, 254.99, 263.99, 879, 716, 446],
[38.11, 19.4, 33.43, 34.65, 34.44, 254.99, 264, 874, 714, 446],
[37.96, 19.39, 33.33, 34.54, 34.33, 254.99, 264, 883, 719, 445],
[38.14, 19.36, 33.22, 34.43, 34.22, 255, 264, 872, 711, 445],
[38.08, 19.34, 33.12, 34.32, 34.12, 254.99, 264, 874, 711, 445],
[38.29, 19.31, 33.01, 34.22, 34.01, 255, 264, 880, 717, 446],
[38.26, 19.3, 32.91, 34.11, 33.91, 255, 263.99, 876, 715, 446],
[38.05, 19.24, 32.8, 34, 33.8, 255, 263.99, 876, 717, 446],
[38.11, 19.23, 32.7, 33.9, 33.7, 255, 263.99, 875, 715, 445],
[38.17, 19.21, 32.59, 33.79, 33.59, 255, 264, 875, 715, 446],
[38.08, 19.17, 32.49, 33.69, 33.49, 255, 263.99, 881, 719, 446],
[38.32, 19.16, 32.39, 33.59, 33.39, 254.99, 264, 883, 717, 446],
[38.08, 19.12, 32.29, 33.48, 33.29, 255, 264, 879, 718, 446],
[38.23, 19.07, 32.18, 33.38, 33.19, 255, 264, 877, 715, 446],
[38.08, 19.07, 32.08, 33.28, 33.09, 254.99, 264, 875, 715, 446],
[38.29, 19.01, 31.99, 33.18, 32.99, 255, 264, 871, 712, 446],
[38.17, 19, 31.89, 33.08, 32.89, 255, 264, 870, 710, 445],
[38.05, 18.97, 31.79, 32.97, 32.79, 254.99, 263.99, 872, 713, 446],
[38.11, 18.95, 31.69, 32.87, 32.69, 254.99, 263.99, 873, 713, 446],
[38.05, 18.92, 31.6, 32.78, 32.59, 254.99, 264, 873, 711, 445],
[38.05, 18.91, 31.5, 32.68, 32.5, 254.99, 263.99, 869, 709, 446],
[38.11, 18.86, 31.4, 32.58, 32.4, 255, 264, 874, 712, 446],
[38.26, 18.84, 31.31, 32.48, 32.3, 254.99, 264, 872, 710, 446],
[38.11, 18.8, 31.21, 32.39, 32.21, 255, 264, 876, 713, 446],
[38.08, 18.78, 31.12, 32.29, 32.11, 255, 263.99, 874, 714, 446],
[38.11, 18.76, 31.02, 32.19, 32.02, 254.99, 264, 871, 709, 445],
[38.11, 18.73, 30.93, 32.1, 31.93, 254.99, 263.99, 873, 712, 446],
[38.05, 18.69, 30.84, 32, 31.83, 255, 264, 868, 709, 445],
[38.05, 18.7, 30.74, 31.91, 31.74, 254.99, 264, 868, 707, 446],
[38.08, 18.66, 30.65, 31.81, 31.65, 255, 264, 854, 697, 443],
[38.23, 18.63, 30.56, 31.72, 31.55, 255, 263.99, 864, 704, 445],
[38.23, 18.58, 30.47, 31.63, 31.46, 254.99, 264, 873, 714, 446],
[37.99, 18.58, 30.38, 31.53, 31.37, 255, 263.99, 870, 710, 445],
[38.11, 18.54, 30.29, 31.44, 31.28, 255, 263.99, 865, 706, 445],
[38.02, 18.52, 30.2, 31.35, 31.19, 255, 264, 867, 707, 445],
[38.11, 18.48, 30.12, 31.26, 31.1, 254.99, 263.99, 873, 712, 446],
[37.99, 18.47, 30.02, 31.17, 31.02, 254.99, 263.99, 876, 715, 446],
[38.05, 18.44, 29.93, 31.08, 30.93, 255, 264, 871, 711, 446],
[38.29, 18.41, 29.85, 31, 30.84, 254.99, 264, 868, 709, 446],
[38.02, 18.39, 29.76, 30.91, 30.75, 254.99, 264, 867, 707, 445],
[37.99, 18.35, 29.67, 30.82, 30.67, 255, 263.99, 875, 714, 446],
[37.93, 18.35, 29.59, 30.73, 30.58, 255, 264, 876, 714, 447],
[37.99, 18.31, 29.5, 30.64, 30.49, 255, 264, 874, 715, 446],
[37.99, 18.3, 29.41, 30.55, 30.41, 255, 264, 866, 708, 445],
[37.99, 18.29, 29.33, 30.47, 30.32, 254.99, 263.99, 869, 713, 446],
[38.02, 18.26, 29.25, 30.38, 30.24, 254.99, 263.99, 869, 711, 446],
[38.05, 18.25, 29.17, 30.3, 30.15, 254.99, 264, 874, 713, 446],
[37.9, 18.24, 29.08, 30.21, 30.07, 254.99, 264, 874, 715, 446],
[37.93, 18.24, 29, 30.13, 29.99, 254.99, 264, 871, 715, 447],
[38.11, 18.23, 28.92, 30.04, 29.91, 254.99, 263.99, 869, 710, 446],
[37.84, 18.21, 28.84, 29.96, 29.83, 254.99, 264, 869, 709, 446],
[38.08, 18.18, 28.76, 29.88, 29.74, 254.99, 263.99, 868, 711, 446],
[37.96, 18.2, 28.68, 29.8, 29.66, 254.99, 263.99, 877, 718, 447],
[37.96, 18.16, 28.6, 29.71, 29.58, 254.99, 264, 868, 709, 446],
[37.93, 18.16, 28.52, 29.63, 29.5, 254.99, 264, 853, 698, 443],
[37.9, 18.15, 28.44, 29.55, 29.43, 255, 263.99, 871, 712, 446],
[37.9, 18.13, 28.36, 29.47, 29.35, 255, 264, 867, 708, 446],
[37.9, 18.12, 28.28, 29.39, 29.27, 254.99, 264, 845, 693, 442],
[37.96, 18.12, 28.21, 29.31, 29.19, 254.99, 263.99, 840, 690, 441],
[37.96, 18.11, 28.13, 29.24, 29.12, 255, 263.99, 850, 696, 443],
[37.93, 18.11, 28.05, 29.16, 29.04, 255, 264, 853, 698, 443],
[38.05, 18.09, 27.98, 29.08, 28.96, 254.99, 264, 843, 691, 441],
[37.99, 18.09, 27.9, 29, 28.89, 255, 264, 849, 693, 442],
[37.99, 18.1, 27.83, 28.93, 28.81, 255, 264, 843, 692, 441],
[38.11, 18.08, 27.75, 28.85, 28.74, 255, 263.98, 851, 696, 442],
[38.08, 18.08, 27.68, 28.78, 28.67, 254.99, 263.99, 862, 703, 444],
[38.26, 18.09, 27.61, 28.7, 28.59, 254.99, 264, 859, 703, 444],
[38.08, 18.06, 27.54, 28.63, 28.52, 254.99, 264, 860, 701, 443],
[38.08, 18.07, 27.46, 28.55, 28.45, 255, 263.99, 854, 699, 443],
[38.29, 18.08, 27.39, 28.48, 28.38, 254.99, 264, 862, 705, 444],
[38.44, 18.08, 27.32, 28.41, 28.31, 254.99, 263.99, 863, 705, 444],
[38.38, 18.12, 27.25, 28.34, 28.24, 255, 263.99, 856, 701, 443],
[38.41, 18.14, 27.18, 28.27, 28.17, 255, 263.99, 850, 698, 442],
[38.52, 18.16, 27.11, 28.19, 28.1, 255, 264, 859, 704, 443],
[38.38, 18.18, 27.05, 28.12, 28.03, 255, 263.99, 868, 712, 445],
[38.52, 18.21, 26.98, 28.05, 27.96, 254.99, 263.99, 871, 710, 445],
[38.41, 18.22, 26.91, 27.99, 27.89, 254.99, 263.99, 870, 713, 445],
[38.44, 18.24, 26.84, 27.92, 27.83, 254.99, 263.99, 869, 713, 444],
[38.44, 18.25, 26.78, 27.85, 27.76, 254.99, 263.99, 868, 709, 444],
[38.64, 18.27, 26.72, 27.78, 27.7, 254.99, 263.99, 849, 699, 441],
[38.58, 18.28, 26.65, 27.72, 27.63, 255, 264, 856, 704, 443],
[38.35, 18.28, 26.58, 27.65, 27.57, 254.99, 264, 866, 710, 444],
[38.35, 18.3, 26.52, 27.58, 27.5, 255, 263.99, 859, 705, 443],
[38.14, 18.29, 26.45, 27.52, 27.44, 254.99, 264, 856, 700, 443],
[38.29, 18.29, 26.39, 27.46, 27.38, 254.99, 264, 843, 692, 441],
[37.84, 18.29, 26.33, 27.39, 27.32, 254.99, 263.99, 848, 695, 441],
[38.14, 18.28, 26.27, 27.33, 27.25, 255, 264, 848, 695, 442],
[38.47, 18.29, 26.21, 27.27, 27.19, 255, 264, 847, 696, 442],
[38.17, 18.31, 26.15, 27.2, 27.13, 254.99, 264, 853, 698, 442],
[38.47, 18.32, 26.09, 27.14, 27.07, 255, 263.99, 839, 689, 440],
[38.29, 18.33, 26.03, 27.08, 27.01, 254.99, 263.99, 842, 694, 440],
[38.5, 18.36, 25.97, 27.02, 26.96, 254.99, 264, 851, 699, 442],
[38.58, 18.37, 25.91, 26.96, 26.9, 255, 263.98, 857, 702, 442],
[38.55, 18.4, 25.86, 26.9, 26.84, 255, 263.99, 863, 705, 443],
[38.7, 18.44, 25.8, 26.84, 26.78, 255, 263.99, 854, 701, 442],
[38.88, 18.48, 25.74, 26.78, 26.73, 254.99, 264, 846, 696, 441],
[38.82, 18.52, 25.69, 26.73, 26.67, 255, 264, 856, 705, 443],
[39, 18.56, 25.63, 26.67, 26.62, 254.99, 264, 874, 712, 444],
[39, 18.61, 25.57, 26.61, 26.56, 254.99, 264, 864, 709, 443],
[39.24, 18.65, 25.52, 26.56, 26.51, 254.99, 263.99, 862, 706, 442],
[39.24, 18.71, 25.47, 26.5, 26.45, 255, 264, 856, 704, 443],
[39.18, 18.77, 25.42, 26.45, 26.4, 254.99, 263.99, 847, 697, 440],
[39.24, 18.81, 25.37, 26.39, 26.35, 254.99, 264, 852, 696, 440],
[39.33, 18.85, 25.31, 26.34, 26.3, 255, 263.99, 855, 700, 441],
[39.49, 18.89, 25.26, 26.29, 26.25, 255, 263.99, 866, 709, 443],
[39.52, 18.97, 25.17, 26.19, 26.15, 254.99, 263.99, 867, 709, 443],
[39.55, 19.01, 25.12, 26.14, 26.1, 254.99, 263.99, 868, 708, 443],
[39.64, 19.04, 25.07, 26.09, 26.06, 254.99, 264, 868, 711, 443],
[39.73, 19.09, 25.02, 26.04, 26.01, 255, 263.99, 869, 711, 444],
[39.73, 19.11, 24.98, 25.99, 25.96, 254.99, 264, 858, 703, 441],
[39.79, 19.16, 24.93, 25.94, 25.92, 254.99, 263.99, 847, 695, 439],
[39.79, 19.19, 24.89, 25.89, 25.87, 255, 263.99, 850, 696, 440],
[40.03, 19.22, 24.84, 25.85, 25.83, 255, 263.99, 865, 709, 442],
[39.7, 19.25, 24.8, 25.8, 25.78, 254.99, 264, 861, 706, 441],
[39.94, 19.29, 24.76, 25.76, 25.74, 254.99, 263.98, 861, 708, 442],
[40.15, 19.33, 24.71, 25.71, 25.7, 254.99, 263.99, 857, 702, 441],
[40.09, 19.36, 24.67, 25.67, 25.65, 254.99, 264, 853, 698, 440],
[40.18, 19.41, 24.63, 25.62, 25.61, 255, 264, 851, 697, 440],
[40.28, 19.45, 24.59, 25.58, 25.57, 255, 264, 859, 702, 441],
[40.37, 19.49, 24.55, 25.54, 25.53, 255, 263.99, 866, 706, 441],
[40.43, 19.54, 24.51, 25.5, 25.49, 255, 263.99, 863, 704, 441],
[40.64, 19.59, 24.47, 32.13, 30.55, 254.99, 263.99, 854, 697, 440],
[40.61, 19.65, 24.45, 49.18, 45.45, 255, 264, 862, 703, 441],
[40.77, 19.72, 24.48, 63.69, 57.83, 254.99, 264, 858, 701, 440],
[40.92, 19.78, 24.55, 76.02, 68.15, 255, 264, 843, 691, 437],
[41.05, 19.85, 24.68, 86.49, 76.68, 254.99, 264.01, 846, 693, 437],
[41.23, 19.93, 24.85, 95.37, 83.64, 255, 264, 850, 694, 438],
[41.26, 20, 25.05, 102.77, 89.42, 255, 264, 855, 698, 439],
[41.36, 20.09, 25.29, 109.16, 94.23, 254.99, 264, 853, 697, 439],
[41.67, 20.16, 25.54, 109.98, 98.22, 254.99, 263.99, 852, 695, 438],
[41.45, 20.25, 25.81, 110, 101.51, 255, 264, 853, 696, 437],
[41.67, 20.32, 26.09, 110, 104.24, 255, 264, 847, 692, 436],
[41.7, 20.41, 26.37, 110, 106.52, 255, 263.99, 855, 699, 438],
[41.86, 20.49, 26.66, 110.02, 108.4, 254.99, 264, 855, 700, 438],
[42.14, 20.56, 26.95, 110, 110.03, 255, 263.99, 858, 703, 440],
[42.23, 20.63, 27.23, 110, 109.98, 255, 264, 852, 698, 438],
[42.2, 20.71, 27.52, 110, 109.99, 254.99, 264, 855, 698, 439],
[42.27, 20.78, 27.8, 110, 110, 255, 263.99, 866, 708, 441],
[42.46, 20.84, 28.08, 110, 110, 255, 264, 862, 701, 439],
[42.81, 26.82, 31.17, 110, 110, 255, 264, 863, 713, 439],
[42.87, 44.69, 34.79, 110, 110.02, 254.99, 263.99, 855, 709, 438],
[43.35, 55.27, 37.87, 110.01, 110.02, 254.99, 263.99, 855, 717, 438],
[43.19, 61.24, 40.71, 110.01, 110.02, 254.99, 264, 842, 699, 435],
[43.03, 61.96, 43.39, 110.01, 110.02, 255, 264, 856, 712, 438],
[43.06, 59.93, 45.97, 110.01, 110.02, 255, 264, 876, 731, 441],
[43.38, 59.98, 48.57, 110.01, 110.02, 254.99, 264, 865, 724, 440],
[43.42, 60.07, 51.14, 110.01, 110.02, 255, 263.99, 860, 717, 439],
[43.51, 60.23, 53.7, 110.01, 110.02, 254.99, 264.01, 847, 711, 436],
[43.48, 59.97, 56.25, 110.01, 110.02, 254.99, 264, 847, 712, 436],
[43.64, 59.81, 58.83, 110.01, 110.02, 255, 263.99, 863, 727, 439],
[43.67, 60.05, 61.39, 110.01, 110.02, 254.99, 264, 863, 728, 440],
[43.8, 60.22, 63.93, 110.01, 110.02, 254.99, 264, 874, 740, 441],
[43.77, 60.01, 66.4, 110.01, 110.02, 254.99, 264, 876, 734, 441],
[43.93, 60.1, 68.9, 110.01, 110.02, 255, 263.99, 867, 731, 441],
[43.87, 60.1, 71.35, 110.01, 110.02, 255, 264, 855, 721, 439],
[43.93, 59.88, 73.79, 110.01, 110.02, 255, 264, 864, 726, 440],
[43.93, 60.05, 76.21, 110.01, 110.02, 255, 264, 850, 719, 437],
[43.93, 60.54, 78.6, 110.01, 110.02, 254.99, 263.99, 851, 721, 437],
[43.9, 60.91, 80.03, 110.01, 110.01, 255, 264, 846, 713, 436],
[44.06, 61.42, 80.01, 110, 110, 255, 264, 844, 709, 435],
[44.13, 61.66, 80, 110, 110, 254.99, 264, 842, 710, 435],
[44, 62.65, 80, 110, 110, 254.99, 264, 851, 718, 436],
[43.93, 61.34, 80, 110, 110, 254.99, 264, 852, 724, 437],
[43.97, 60.37, 80, 110, 110, 255, 264, 853, 719, 437],
[44.1, 59.79, 80.03, 110, 110, 254.99, 264, 850, 720, 437],
[44.06, 60.59, 80.05, 110, 110, 254.99, 263.99, 853, 717, 437],
[44.06, 60.43, 80.08, 110, 110, 254.99, 263.99, 841, 712, 435],
[44.06, 60.11, 80.08, 110, 110, 254.99, 263.99, 845, 713, 435],
[43.9, 59.58, 80.09, 109.99, 110, 255, 263.99, 846, 715, 435],
[43.9, 60.04, 80.1, 110, 110, 255, 264, 844, 709, 434],
[43.87, 60.37, 80.1, 110, 110, 254.99, 263.99, 853, 720, 437],
[43.77, 59.94, 80.09, 110, 110, 254.99, 264, 862, 729, 440],
[43.87, 60.71, 80.09, 110, 109.99, 254.99, 264, 869, 738, 441],
[43.71, 61.46, 80.06, 110, 110, 255, 263.99, 873, 735, 440],
[43.8, 60.84, 80.04, 109.99, 109.99, 255, 264, 857, 722, 438],
[43.71, 61.34, 80.02, 110, 109.99, 255, 263.99, 862, 731, 439],
[43.58, 62.06, 80, 110, 109.99, 255, 264, 862, 732, 439],
[43.77, 62.33, 80, 109.99, 110, 255, 264, 861, 729, 439],
[43.61, 61.08, 79.99, 110, 109.99, 254.99, 264, 858, 725, 438],
[43.64, 61.61, 79.99, 110, 110, 255, 263.99, 860, 727, 438],
[43.58, 61.42, 79.99, 110, 110, 255, 263.99, 850, 715, 437],
[43.54, 62.34, 80, 110, 110, 255, 264, 848, 714, 436],
[43.51, 61.77, 80, 110, 109.99, 255, 264, 841, 708, 435],
[43.42, 62.62, 80, 110, 110, 255, 264, 843, 714, 435],
[43.45, 61.82, 80, 109.99, 110, 255, 263.99, 850, 717, 436],
[43.42, 62.09, 80, 110, 110, 255, 263.99, 851, 719, 437],
[43.35, 61.74, 79.99, 109.99, 110, 255, 264, 846, 712, 435],
[43.35, 61.82, 80, 110, 110, 254.99, 263.99, 847, 715, 435],
[43.35, 62.35, 79.99, 110, 110, 254.99, 264, 847, 714, 436],
[43.13, 63.32, 79.99, 110, 110, 255, 263.99, 848, 717, 436],
[43.09, 64.15, 80, 110, 109.99, 255, 264, 844, 716, 436],
[42.87, 65.08, 79.99, 110, 110, 255, 264, 840, 707, 434],
[42.74, 65.32, 80, 110, 110, 254.99, 264, 846, 718, 436],
[42.68, 65.73, 80, 109.99, 110, 255, 264, 849, 722, 436],
[42.55, 66.6, 79.99, 110, 110, 255, 264, 844, 714, 435],
[42.49, 67.24, 80, 110, 110, 254.99, 264, 851, 724, 437],
[42.33, 66.21, 80, 110, 110, 255, 263.99, 850, 719, 436],
[42.49, 66.12, 79.99, 110, 110, 255, 264, 841, 714, 435],
[42.23, 66.18, 79.99, 109.99, 110, 254.99, 264, 833, 710, 434],
[42.08, 66.38, 80, 110, 109.99, 255, 264, 841, 711, 434],
[41.89, 65.73, 80, 110, 109.99, 255, 263.99, 848, 720, 436],
[41.73, 64.91, 79.99, 110, 110, 255, 264, 859, 729, 439],
[41.58, 64.03, 79.99, 110, 110, 254.99, 264, 868, 734, 441],
[41.61, 63.97, 79.99, 110, 109.99, 254.99, 263.99, 865, 733, 440],
[41.36, 62.63, 79.99, 109.99, 110, 254.99, 264, 868, 737, 441],
[41.36, 64.31, 79.99, 110, 110, 254.99, 264, 866, 735, 440],
[41.17, 64.66, 79.99, 110, 110, 255, 263.99, 862, 729, 440],
[41.2, 65.34, 79.99, 109.99, 110, 254.99, 264, 854, 724, 439],
[40.89, 64.51, 79.99, 110, 110, 255, 264, 851, 720, 438],
[40.89, 64.82, 80, 110, 110, 254.99, 263.99, 862, 732, 440],
[40.83, 64.57, 79.99, 110, 110, 255, 263.99, 856, 730, 440],
[40.64, 64.73, 79.99, 109.99, 110, 255, 263.99, 865, 731, 441],
[40.52, 65.4, 80, 109.99, 110, 255, 264, 865, 729, 441],
[40.34, 67.63, 79.99, 110, 109.99, 255, 264, 857, 730, 439],
[40.34, 67.37, 79.99, 110, 109.99, 255, 264, 858, 725, 440],
[40.18, 66.5, 80, 109.99, 110, 254.99, 263.99, 864, 732, 441],
[40.06, 65.85, 80, 110, 110, 255, 264.01, 861, 732, 441],
[40.09, 66.03, 79.99, 109.99, 110, 254.99, 263.99, 865, 734, 442],
[40.09, 68.32, 79.99, 110, 110, 254.99, 263.99, 866, 740, 442],
[39.97, 67.88, 79.99, 110, 109.99, 254.99, 264, 862, 731, 441],
[39.85, 67.76, 80, 110, 110, 255, 263.99, 862, 734, 441],
[39.85, 66.91, 80, 110, 110, 255, 263.99, 870, 741, 442],
[39.85, 66.26, 79.99, 110, 110, 254.99, 263.99, 875, 747, 444],
[39.88, 64.72, 80, 110, 110, 255, 263.99, 876, 744, 444],
[39.94, 64.99, 79.99, 110, 110, 255, 263.99, 874, 742, 442],
[39.82, 66.13, 79.99, 110, 110, 255, 263.99, 869, 740, 443],
[39.82, 65.94, 80, 110, 110, 254.99, 263.99, 873, 742, 443],
[39.85, 67.44, 79.99, 110, 110, 254.99, 264, 870, 736, 442],
[39.88, 67.31, 80, 110, 110, 254.99, 264, 870, 739, 442],
[39.85, 67.9, 79.99, 110, 110, 255, 263.99, 873, 736, 443],
[39.79, 66.4, 80, 110, 110, 255, 264, 873, 741, 442],
[39.67, 65.43, 80, 109.99, 110, 254.99, 264, 878, 748, 443],
[39.76, 64.92, 80, 110, 110, 255, 264, 873, 741, 443],
[39.97, 65.02, 79.99, 109.99, 110, 254.99, 263.99, 868, 738, 443],
[39.67, 65.4, 79.99, 110, 109.99, 255, 263.99, 873, 741, 443],
[39.67, 66.21, 79.99, 110, 110, 255, 264, 878, 747, 444],
[39.64, 65.84, 79.99, 110, 110, 255, 264, 878, 743, 444],
[39.55, 65.54, 80, 110, 110, 254.99, 263.99, 877, 744, 444],
[39.73, 66.13, 79.99, 110, 110, 255, 264, 870, 738, 443],
[39.49, 67.26, 80, 110, 110, 255, 264, 866, 741, 443],
[39.33, 69.05, 79.99, 110, 110, 255, 263.99, 869, 740, 443],
[39.46, 67.21, 80.01, 109.99, 110, 255, 263.99, 875, 748, 444],
[39.33, 69.52, 79.99, 110, 110, 254.99, 264, 872, 741, 443],
[39.3, 69.51, 79.99, 110, 110, 255, 263.99, 869, 740, 443],
[39.24, 67.56, 79.99, 110, 109.99, 254.99, 263.99, 872, 741, 444],
[39.3, 67.42, 79.99, 109.99, 110, 254.99, 264, 876, 739, 444],
[39.27, 66.23, 80, 110, 110, 255, 264, 868, 738, 443],
[39.55, 66.31, 79.99, 109.99, 109.99, 254.99, 263.99, 872, 739, 443],
[39.33, 65.35, 80, 110, 110, 254.99, 263.99, 860, 729, 441],
[39.52, 63.49, 80, 109.99, 110, 255, 263.99, 847, 720, 439],
[39.3, 63.25, 79.99, 110, 110, 254.99, 264, 850, 723, 439],
[39.43, 64.56, 80, 109.99, 110, 255, 263.99, 855, 724, 441],
[39.39, 64.73, 80, 110, 110, 255, 263.98, 868, 738, 442],
[39.39, 65.44, 79.99, 110, 110, 255, 264, 870, 738, 443],
[39.27, 66.75, 80, 110, 110, 254.99, 263.99, 863, 735, 442],
[39.3, 66.35, 79.99, 110, 110, 254.99, 263.99, 861, 734, 442],
[39.27, 65.63, 80, 109.99, 110, 255, 263.99, 874, 745, 443],
[39.21, 64.96, 80, 110, 110, 254.99, 263.99, 874, 743, 443],
[39.18, 65.51, 80, 110, 110, 255, 264, 873, 742, 444],
[39.09, 65.53, 80, 109.99, 110, 255, 264, 863, 738, 443],
[39, 68.03, 80, 110, 109.99, 254.99, 263.99, 867, 743, 443],
[38.82, 68.16, 80, 110, 110, 254.99, 263.99, 883, 746, 444],
[38.79, 67.77, 79.99, 110, 110, 254.99, 264, 882, 753, 445],
[38.64, 67.22, 80, 109.99, 110, 255, 264, 878, 743, 445],
[38.55, 67.15, 79.99, 109.99, 110, 255, 264, 876, 750, 445],
[38.5, 66.97, 79.99, 110, 110, 255, 264, 874, 747, 445],
[38.47, 65.73, 80, 110, 110, 254.99, 264, 869, 741, 444],
[38.47, 63.47, 79.99, 110, 110, 254.99, 264, 867, 742, 445],
[38.44, 63.48, 79.99, 110, 110, 254.99, 264, 867, 743, 445],
[38.35, 63.98, 79.99, 110, 110, 254.99, 263.99, 869, 740, 445],
[38.41, 64.47, 80, 110, 110, 254.99, 263.99, 875, 746, 445],
[38.29, 63.93, 79.99, 110, 110, 255, 264, 878, 747, 446],
[38.35, 64.15, 80, 110, 110, 254.99, 263.99, 874, 747, 445],
[38.35, 66.02, 80, 110, 110, 254.99, 263.99, 876, 741, 445],
[38.44, 66.52, 79.99, 109.99, 109.99, 254.99, 264, 873, 745, 446],
[38.26, 65.9, 79.99, 110, 110, 255, 263.99, 871, 742, 445],
[38.17, 67.79, 80, 110, 110, 254.99, 263.99, 859, 733, 444],
[38.29, 67.03, 80, 110, 110, 255, 263.99, 859, 732, 444],
[37.93, 65.94, 80, 110, 110, 254.99, 263.99, 852, 732, 443],
[38.11, 64.75, 80, 110, 109.99, 255, 263.99, 858, 736, 443],
[38.02, 65.28, 79.99, 110, 110, 254.99, 263.99, 855, 730, 443],
[37.96, 63.4, 80, 110, 110, 255, 263.99, 853, 731, 442],
[38.05, 65.55, 80, 109.99, 109.99, 255, 264, 859, 734, 443],
[38.2, 66.27, 79.99, 109.99, 110, 254.99, 264, 860, 734, 443],
[38.38, 65.64, 79.99, 110, 110, 255, 264, 860, 735, 443],
[38.32, 64.54, 80, 110, 109.99, 255, 263.99, 860, 733, 442],
[38.58, 64.6, 79.99, 110, 110, 254.99, 264, 857, 729, 442],
[38.58, 63.27, 80, 110, 110, 254.99, 263.99, 853, 731, 442],
[38.67, 65.21, 79.99, 110, 110, 255, 264, 857, 731, 442],
[38.61, 65.75, 80, 110, 110, 255, 264, 855, 729, 441],
[38.67, 64.85, 80, 110, 110, 254.99, 263.99, 854, 732, 441],
[38.7, 65.69, 79.99, 109.99, 110, 254.99, 264, 857, 731, 442],
[39.09, 65.4, 79.99, 110, 110, 255, 263.99, 862, 735, 443],
[38.94, 64.46, 80, 110, 110, 254.99, 263.99, 858, 734, 442],
[38.85, 63.68, 79.99, 109.99, 110, 254.99, 263.99, 860, 730, 442],
[39, 63.46, 79.99, 110, 110, 255, 263.99, 854, 729, 441],
[39.06, 65.25, 79.99, 110, 110, 254.99, 264, 857, 730, 442],
[39.09, 64.73, 79.99, 110, 110, 255, 264, 869, 738, 443],
[39.12, 65.34, 80, 110, 110, 254.99, 263.99, 876, 747, 444],
[39.15, 65.13, 80, 109.99, 110, 254.99, 264, 874, 746, 444],
[39.24, 64.58, 79.99, 109.99, 110, 254.99, 264, 874, 740, 444],
[39.49, 64.5, 80, 110, 110, 254.99, 264, 869, 738, 444],
[39.27, 63.54, 80, 110, 110, 255, 263.99, 874, 745, 445],
[39.3, 63.73, 79.99, 109.99, 110, 255, 264, 873, 745, 444],
[39.27, 63.35, 79.99, 109.99, 109.99, 255, 263.99, 873, 746, 445],
[39.27, 62.26, 79.99, 109.99, 109.99, 255, 263.99, 872, 742, 445],
[39.36, 63.41, 79.99, 110, 110, 255, 264, 868, 741, 444],
[39.33, 63.03, 79.99, 110, 109.99, 255, 263.99, 867, 739, 444],
[39.3, 64.7, 79.99, 109.99, 110, 254.99, 264, 866, 742, 444],
[39.52, 65.28, 79.99, 109.99, 110, 254.99, 264, 869, 742, 443],
[39.46, 67.31, 80, 110, 110, 255, 263.99, 871, 741, 444],
[39.49, 68.12, 80, 110, 110, 255, 264, 869, 742, 444],
[39.46, 66.5, 80, 110, 110, 255, 263.99, 864, 738, 443],
[39.52, 65.22, 79.99, 110, 110, 255, 263.99, 857, 729, 442],
[39.58, 63.87, 79.99, 109.99, 110, 255, 264, 865, 735, 443],
[39.79, 63.7, 80, 110, 110, 255, 264, 866, 738, 443],
[39.7, 64.63, 80, 110, 110, 254.99, 263.99, 865, 734, 443],
[39.7, 63.53, 80, 109.99, 110, 255, 264, 857, 727, 441],
[39.67, 63.87, 80, 110, 110, 254.99, 264, 853, 724, 440],
[39.7, 63.07, 79.99, 110, 110, 254.99, 264, 850, 718, 440],
[39.79, 61.77, 80, 110, 110, 254.99, 264, 854, 727, 441],
[39.73, 61.61, 80, 110, 110, 255, 264, 857, 730, 442],
[39.85, 63.03, 79.99, 110, 110, 254.99, 263.99, 868, 737, 443],
[39.7, 63.73, 79.99, 110, 110, 255, 264, 869, 734, 443],
[39.73, 64.03, 80, 110, 110, 255, 264, 862, 729, 442],
[39.73, 64.97, 79.99, 110, 109.99, 254.99, 263.99, 858, 732, 441],
[39.67, 65.82, 79.99, 110, 110, 255, 263.99, 854, 724, 440],
[39.76, 66.28, 79.99, 110, 110, 255, 264, 854, 730, 441],
[39.76, 65.07, 80, 110, 110, 254.99, 264, 856, 728, 441],
[39.76, 66, 79.99, 109.99, 110, 255, 263.99, 866, 733, 442],
[39.82, 66.43, 79.99, 110, 110, 254.99, 264, 865, 739, 443],
[39.94, 67.15, 79.99, 110, 110, 254.99, 263.99, 859, 728, 441],
[39.85, 66.1, 79.99, 110, 110, 255, 264, 850, 721, 440],
[39.82, 65.92, 79.99, 110, 110, 255, 263.99, 855, 727, 441],
[39.79, 64.88, 79.99, 110, 110, 255, 264, 853, 728, 441],
[39.94, 63.56, 80, 110, 110, 254.99, 263.99, 869, 743, 443],
[39.73, 66.94, 79.99, 110, 109.99, 255, 263.99, 861, 736, 442],
[39.7, 67.31, 79.99, 110, 110, 254.99, 264, 866, 738, 443],
[39.7, 66.52, 79.99, 110, 110, 255, 264, 865, 741, 442],
[39.76, 67.06, 79.99, 110, 109.99, 254.99, 264, 870, 741, 443],
[39.79, 67.73, 79.99, 110, 110, 255, 264, 877, 747, 443],
[39.85, 64.55, 80, 110, 110, 254.99, 264, 854, 733, 441],
[39.85, 65.22, 79.99, 109.99, 110, 254.99, 263.99, 866, 741, 443],
[39.79, 65.91, 80, 110, 109.99, 254.99, 264, 865, 741, 442],
[39.97, 68.21, 79.99, 110, 110, 254.99, 264, 867, 744, 442],
[39.67, 67.27, 80, 110, 110, 254.99, 263.99, 872, 745, 444],
[39.88, 67.29, 79.99, 110, 110, 255, 264, 866, 740, 443],
[39.64, 65.79, 79.99, 110, 110, 254.99, 263.99, 869, 738, 443],
[39.55, 64.77, 80, 110, 110, 255, 264, 864, 736, 442],
[39.49, 64.92, 79.99, 110, 110, 254.99, 264, 858, 734, 442],
[39.58, 65.36, 80, 109.99, 110, 255, 264, 854, 732, 442],
[39.55, 65.34, 79.99, 110, 110, 254.99, 263.99, 862, 734, 443],
[39.43, 64.36, 79.99, 110, 110, 255, 263.99, 860, 734, 443],
[39.46, 64.28, 79.99, 110, 110, 254.99, 264, 867, 738, 443],
[39.39, 63.28, 80, 110, 110, 255, 264, 863, 736, 442],
[39.94, 62.21, 79.99, 109.99, 110, 255, 264, 859, 732, 442],
[41.23, 63.7, 79.99, 110, 110, 255, 263.99, 861, 733, 441],
[41.39, 64.23, 80, 109.99, 110, 254.99, 264, 859, 735, 441],
[41.45, 65.17, 79.99, 110, 110, 254.99, 263.99, 852, 727, 439],
[41.54, 65.1, 79.99, 110, 110, 254.99, 264, 845, 720, 438],
[41.64, 64.24, 80, 109.99, 110, 254.99, 264, 840, 722, 438],
[41.64, 63.25, 80, 110, 110, 254.99, 263.99, 851, 730, 439],
[41.76, 63.91, 80, 109.99, 109.99, 254.99, 264, 853, 726, 440],
[41.95, 63.58, 80, 110, 109.99, 254.99, 264, 860, 734, 441],
[41.95, 64.81, 80, 109.99, 110, 254.99, 264, 853, 731, 440],
[42.14, 66.3, 79.99, 110, 110, 254.99, 264, 850, 724, 439],
[42.27, 66.35, 79.99, 110, 110, 254.99, 263.99, 854, 731, 440],
[42.3, 65.79, 80, 110, 110, 255, 264, 867, 740, 441],
[42.52, 67.35, 80, 110, 109.99, 254.99, 264, 866, 734, 441],
[42.81, 65.73, 80, 109.99, 110, 254.99, 264, 862, 739, 440],
[42.97, 64.48, 80, 110, 110, 255, 263.99, 859, 733, 440],
[42.97, 63.34, 79.99, 110, 110, 255, 264, 865, 737, 441],
[43, 63.09, 80, 109.99, 109.99, 254.99, 264, 869, 743, 442],
[43, 63.35, 79.99, 110, 110, 255, 264, 867, 739, 441],
[43.03, 63.79, 80, 110, 110, 254.99, 264, 861, 732, 440],
[43, 63.68, 79.99, 110, 110, 255, 263.99, 851, 729, 439],
[43.03, 62.16, 80, 110, 110, 255, 263.99, 845, 724, 437],
[43.13, 64.05, 79.99, 109.99, 110, 255, 263.99, 854, 731, 439],
[43.13, 64.73, 79.99, 110, 110, 254.99, 263.99, 855, 730, 439],
[43.06, 63.42, 80, 110, 110, 254.99, 263.99, 857, 731, 440],
[43.13, 63.99, 80, 110, 109.99, 255, 264, 863, 736, 441],
[43.19, 64.55, 80, 109.99, 109.99, 254.99, 264, 871, 746, 441],
[43.35, 67.83, 80, 110, 110, 255, 264, 864, 740, 440],
[43.38, 66.4, 80, 110, 110, 255, 263.99, 861, 732, 440],
[43.61, 64.62, 79.99, 109.99, 110, 254.99, 263.99, 867, 735, 440],
[43.58, 64.68, 80, 109.99, 110, 255, 263.99, 866, 741, 441],
[43.64, 64.11, 79.99, 109.99, 109.99, 254.99, 263.99, 869, 738, 441],
[43.67, 64.15, 79.99, 110, 109.99, 255, 263.99, 869, 742, 441],
[43.71, 64.79, 79.99, 110, 110, 254.99, 264, 858, 735, 440],
[43.74, 64.42, 80, 109.99, 110, 254.99, 264, 848, 731, 438],
[43.84, 64.59, 79.99, 110, 110, 254.99, 264, 836, 710, 435],
[43.97, 63.91, 80, 109.99, 109.99, 255, 264, 850, 722, 438],
[44.06, 63.14, 80, 109.99, 109.99, 255, 263.99, 858, 730, 439],
[43.87, 62.79, 80, 110, 110, 255, 264, 852, 724, 438],
[44.06, 63.51, 80, 110, 110, 255, 264, 852, 722, 438],
[44.16, 63.46, 79.99, 109.99, 110, 255, 263.99, 857, 729, 439],
[44.19, 63.94, 80, 110, 110, 255, 263.98, 855, 724, 438],
[44.36, 64.22, 79.99, 109.99, 110, 255, 263.99, 854, 731, 439],
[44.49, 65.03, 79.99, 110, 109.99, 255, 263.91, 865, 730, 439],
[44.55, 64.43, 79.99, 109.99, 110, 254.99, 263.99, 867, 743, 441],
[44.78, 63.54, 80, 110, 110, 255, 263.99, 857, 731, 439],
[44.65, 63.3, 79.99, 110, 110, 255, 264, 849, 722, 437],
[45.02, 62.53, 80, 110, 110, 254.99, 263.99, 851, 724, 437],
[45.12, 63.49, 79.99, 110, 110, 255, 264, 865, 738, 440],
[45.02, 64.7, 79.99, 110, 110, 255, 264, 863, 733, 440],
[45.18, 64.6, 80, 110, 110, 254.99, 264, 850, 724, 437],
[45.41, 64.26, 79.99, 109.99, 110, 255, 264, 854, 728, 439],
[45.35, 64.29, 80, 110, 110, 254.99, 263.99, 859, 733, 439],
[45.35, 65.2, 80, 109.99, 109.99, 254.99, 264, 844, 722, 436],
[45.52, 65.66, 79.99, 110, 110, 254.99, 264, 848, 721, 437],
[45.48, 65.58, 80, 110, 110, 254.99, 263.99, 860, 730, 439],
[45.35, 65.63, 79.99, 110, 110, 255, 263.99, 864, 731, 440],
[45.38, 66.36, 79.99, 110, 109.99, 254.99, 263.99, 866, 734, 440],
[45.35, 65.1, 80, 110, 110, 255, 264, 858, 729, 439],
[45.08, 65.6, 79.99, 110, 110, 255, 263.99, 850, 721, 436],
[45.35, 64.44, 80, 110, 110, 255, 264, 844, 717, 436],
[45.35, 64.49, 80, 110, 110, 254.99, 263.99, 847, 723, 437],
[45.28, 65.61, 79.99, 110, 110, 255, 263.99, 846, 718, 437],
[45.18, 65.04, 79.99, 110, 109.99, 254.99, 264, 848, 720, 436],
[45.28, 64.41, 79.99, 109.99, 110, 255, 263.99, 847, 720, 437],
[45.38, 64.48, 79.99, 110, 109.99, 255, 264, 858, 728, 439],
[45.25, 64.14, 79.99, 110, 110, 255, 264, 859, 727, 438],
[45.25, 62.43, 80, 110, 110, 254.99, 264, 858, 727, 439],
[45.35, 62.89, 79.99, 110, 110, 255, 264, 867, 734, 440],
[45.38, 63.41, 80, 110, 110, 254.99, 264, 856, 725, 437],
[45.22, 62.96, 79.99, 110, 109.99, 255, 263.99, 855, 723, 437],
[45.38, 63.62, 79.99, 110, 110, 254.99, 263.99, 866, 734, 440],
[45.08, 63.05, 80, 110, 109.99, 254.99, 264, 861, 731, 439],
[45.22, 63.38, 79.99, 110, 110, 254.99, 263.99, 852, 724, 437],
[45.18, 62.99, 80, 110, 110, 255, 263.99, 859, 730, 439],
[45.12, 63.78, 80, 110, 110, 255, 264, 866, 736, 440],
[45.12, 64.16, 80, 109.99, 110, 255, 264, 862, 732, 439],
[45.12, 64.61, 80, 110, 110, 254.99, 263.98, 854, 727, 438],
[44.92, 64.48, 80, 110, 110, 255, 263.99, 851, 724, 437],
[44.85, 65.48, 79.99, 110, 110, 255, 264, 859, 729, 438],
[45.05, 65.83, 80, 109.99, 109.99, 254.99, 264, 872, 736, 441],
[44.88, 66.23, 79.99, 110, 110, 255, 264, 870, 739, 440],
[44.75, 66.36, 79.99, 110, 110, 254.99, 263.99, 866, 737, 440],
[44.75, 65.38, 79.99, 110, 110, 254.99, 264, 856, 730, 438],
[44.69, 67.69, 79.99, 110, 110, 255, 263.99, 849, 720, 436],
[44.52, 66.14, 79.99, 110, 110, 255, 264, 844, 718, 435],
[44.46, 66.17, 80, 110, 110, 255, 264, 851, 723, 437],
[44.39, 65.45, 80, 110, 110, 255, 264, 869, 739, 441],
[44.36, 65.76, 80, 110, 109.99, 254.99, 263.99, 868, 736, 440],
[44.26, 65.19, 79.99, 110, 110, 254.99, 263.99, 860, 730, 438],
[44.26, 64.47, 80, 109.99, 110, 255, 263.99, 857, 725, 438],
[44.36, 64.26, 79.99, 110, 109.99, 254.99, 264, 852, 721, 437],
[44.16, 64.84, 80, 110, 110, 254.99, 264, 852, 719, 437],
[44.13, 64.6, 80, 110, 110, 254.99, 263.98, 862, 731, 439],
[44.1, 64.01, 79.99, 109.99, 110, 254.99, 263.99, 848, 723, 436],
[44, 63.58, 79.99, 109.99, 109.99, 254.99, 264, 842, 715, 435],
[43.97, 63.29, 80, 109.99, 110, 254.99, 264, 860, 728, 439],
[43.87, 63.67, 79.99, 110, 109.99, 255, 264, 865, 736, 440],
[43.84, 65.7, 79.99, 110, 110, 255, 263.99, 855, 729, 438],
[43.87, 65.05, 80, 109.99, 110, 254.99, 264, 852, 725, 437],
[43.84, 66.56, 80, 110, 110, 254.99, 264.01, 842, 716, 436],
[43.51, 65.99, 80, 110, 110, 255, 263.99, 859, 729, 438],
[43.67, 65.49, 80, 110, 110, 254.99, 263.99, 853, 732, 438],
[43.38, 65.32, 80, 110, 110, 254.99, 263.99, 849, 721, 437],
[43.35, 64.88, 80, 110, 110, 255, 264, 848, 723, 437],
[43.19, 64.1, 79.99, 110, 109.99, 254.99, 263.99, 842, 719, 435],
[43.16, 64.3, 79.99, 110, 110, 254.99, 263.99, 852, 726, 438],
[43.09, 63.88, 80, 110, 110, 254.99, 263.99, 860, 732, 439],
[43, 64.61, 80, 110, 110, 254.99, 264, 860, 732, 439],
[42.93, 66.37, 80, 110, 110, 255, 264, 868, 738, 441],
[42.93, 67.61, 79.99, 109.99, 110, 254.99, 264, 863, 730, 439],
[42.87, 68.11, 80, 109.99, 110, 254.99, 264, 858, 727, 439],
[42.71, 67.53, 79.99, 110, 110, 254.99, 263.98, 860, 731, 440],
[42.68, 66.72, 80, 110, 110, 255, 264, 860, 726, 439],
[42.49, 66.45, 79.99, 110, 110, 254.99, 264, 853, 729, 439],
[42.65, 66.16, 79.99, 110, 110, 255, 264, 854, 729, 438],
[42.49, 65.13, 80, 110, 110, 255, 264, 860, 734, 440],
[42.36, 64.62, 80, 110, 110, 254.99, 263.99, 876, 745, 443],
[42.3, 65.07, 80, 109.99, 109.99, 254.99, 263.99, 866, 737, 442],
[41.86, 64.45, 80, 110, 110, 254.99, 263.99, 861, 735, 441],
[41.86, 65.85, 79.99, 110, 109.99, 254.99, 264, 862, 735, 440],
[41.45, 67.14, 79.99, 110, 109.99, 254.99, 264, 861, 731, 441],
[41.58, 67.32, 79.99, 109.99, 110, 255, 263.99, 870, 740, 442],
[41.51, 67.09, 79.99, 110, 110, 255, 263.99, 878, 748, 443],
[41.51, 66.51, 79.99, 110, 110, 254.99, 264, 870, 737, 442],
[41.51, 65.98, 80, 110, 110, 255, 263.99, 893, 738, 441],
[41.42, 65.17, 79.99, 110, 110, 255, 263.99, 867, 736, 441],
[41.23, 65.9, 79.99, 110, 110, 255, 263.99, 868, 737, 442],
[41.33, 65.58, 79.99, 110, 110, 254.99, 263.99, 867, 741, 442],
[41.2, 64.7, 80, 110, 110, 255, 264, 874, 741, 442],
[41.14, 66.16, 79.99, 110, 110, 255, 264, 889, 742, 443],
[41.17, 65.49, 79.99, 110, 109.99, 254.99, 264, 864, 736, 443],
[40.98, 65.53, 80, 110, 110, 255, 264, 863, 735, 442],
[40.92, 65.07, 79.99, 109.99, 110, 255, 264, 848, 724, 439],
[40.86, 65.44, 80, 110, 110, 255, 264, 852, 724, 439],
[40.77, 66.02, 79.99, 109.99, 110, 254.97, 263.98, 866, 737, 442],
[40.58, 66.56, 80, 109.99, 109.99, 254.99, 264, 862, 742, 442],
[40.58, 67.15, 79.99, 109.99, 110, 255, 264, 862, 735, 441],
[40.52, 67.96, 80, 110, 110, 255, 263.99, 864, 735, 442],
[40.52, 66.72, 79.99, 110, 110, 254.99, 264, 864, 734, 442],
[40.4, 67.39, 79.99, 110, 110, 254.99, 263.98, 861, 732, 442],
[40.37, 67.59, 80, 110, 110, 254.99, 264, 872, 744, 443],
[40.34, 66.05, 79.99, 110, 110, 255, 264, 865, 733, 441],
[40.25, 65.09, 80, 109.99, 109.99, 255, 264, 862, 735, 442],
[40.22, 65.94, 79.99, 110, 110, 254.99, 264, 872, 745, 444],
[40.22, 67.81, 79.99, 110, 110, 255.01, 264.1, 871, 742, 443],
[40.09, 66.01, 79.99, 110, 110, 255, 264, 871, 739, 443],
[40.06, 66.1, 80, 110, 110, 255, 263.99, 873, 746, 444],
[40.03, 67.36, 79.99, 110, 110, 255, 264, 870, 741, 443],
[39.97, 67.93, 79.99, 110, 110, 255, 263.99, 863, 734, 442],
[39.91, 68.14, 79.99, 110, 110, 255.12, 263.84, 863, 732, 442],
[39.91, 68.3, 80, 110, 110, 255, 263.99, 855, 731, 441],
[40, 67.55, 79.99, 110, 110, 254.99, 263.99, 858, 733, 442],
[39.79, 66.99, 80, 110, 110, 255, 264, 872, 741, 443],
[39.82, 65.64, 80, 110, 110, 255, 264, 871, 742, 444],
[39.67, 67, 80, 109.99, 110, 255.09, 262.76, 870, 741, 444],
[39.7, 67.64, 80, 110, 110, 255, 264, 870, 744, 444],
[39.64, 66.94, 80, 109.99, 110, 255, 264, 873, 741, 444],
[39.64, 66.7, 80, 110, 110, 254.99, 264, 871, 741, 444],
[39.79, 67.98, 79.99, 109.99, 110, 255, 263.99, 871, 742, 444],
[39.52, 67.84, 79.99, 110, 110, 255.72, 262.94, 866, 739, 444],
[39.61, 67.32, 80, 110, 110, 255, 264, 862, 739, 444],
[39.39, 66.27, 80, 110, 110, 255, 264, 869, 742, 445],
[39.46, 67.11, 79.99, 110, 110, 254.99, 264, 872, 750, 445],
[39.43, 64.4, 79.99, 109.99, 110, 255, 263.99, 875, 747, 445],
[39.39, 64.63, 79.99, 109.99, 110, 255, 263.99, 873, 746, 445],
[39.43, 65.18, 80, 110, 109.99, 255, 263.99, 869, 741, 445],
[39.3, 65.02, 80, 109.99, 110, 254.99, 264, 861, 740, 444],
[39.43, 67.86, 80, 109.99, 110, 254.99, 264, 874, 745, 445],
[39.33, 68.05, 79.99, 110, 110, 254.99, 263.99, 873, 746, 445],
[39.21, 68.23, 79.99, 110, 110, 255, 264, 874, 750, 445],
[39.24, 67.43, 80, 110, 110, 254.99, 264, 870, 748, 445],
[39.24, 67.76, 80, 110, 109.99, 254.99, 264, 859, 731, 443],
[39.27, 66.84, 80, 110, 110, 255, 263.99, 863, 741, 444],
[39.24, 65.45, 80, 110, 110, 254.99, 264, 866, 748, 444],
[39.21, 67.12, 79.99, 110, 110, 254.99, 263.99, 867, 737, 444],
[39.3, 66.68, 80, 110, 109.99, 255, 264, 868, 743, 444],
[39.24, 66.11, 80, 110, 109.99, 254.99, 264, 866, 740, 445],
[39.09, 64.98, 80, 110, 110, 254.99, 264, 868, 745, 445],
[39.03, 66.68, 80, 109.99, 110, 255, 263.99, 862, 736, 444],
[39.27, 66.77, 80, 109.99, 110, 254.99, 264, 868, 744, 445],
[39.12, 66.17, 80, 110, 110, 254.99, 264, 864, 741, 444],
[38.94, 66.92, 79.99, 109.99, 110, 255, 263.99, 863, 738, 444],
[39, 67.46, 80, 110, 109.99, 254.99, 264, 872, 745, 446],
[39.09, 65.65, 79.99, 110, 110, 255, 264, 872, 742, 446],
[38.67, 66.62, 79.99, 110, 110, 254.99, 263.99, 866, 739, 446],
[38.91, 66.14, 80, 110, 110, 255, 263.99, 868, 743, 446],
[38.88, 66.26, 79.99, 110, 110, 254.99, 264, 868, 741, 446],
[38.79, 65.54, 79.99, 110, 110, 254.99, 264, 869, 740, 446],
[38.67, 64.89, 79.99, 110, 110, 254.99, 263.98, 871, 746, 446],
[38.79, 64.88, 80, 110, 110, 254.99, 264, 874, 749, 447],
[38.76, 66.29, 80, 110, 110, 254.99, 264, 861, 733, 445],
[38.67, 66.24, 80, 109.99, 110, 254.99, 264, 863, 737, 446],
[38.82, 65.77, 79.99, 109.99, 110, 255, 264, 867, 742, 446],
[38.61, 66.22, 80, 110, 110, 255, 264, 870, 745, 446],
[38.67, 65.94, 80, 109.99, 110, 254.99, 264, 872, 745, 447],
[38.58, 67.13, 79.99, 110, 110, 255, 264, 861, 740, 446],
[38.52, 67.53, 79.99, 110, 110, 255, 263.98, 862, 736, 445],
[38.5, 67, 79.99, 110, 110, 254.99, 264.01, 848, 729, 443],
[38.47, 66.32, 80, 109.99, 110, 255, 264, 859, 734, 446],
[38.41, 66.17, 80, 110, 110, 254.99, 264, 865, 740, 447],
[38.41, 65.63, 80, 110, 109.99, 255, 264, 863, 743, 447],
[38.41, 67.25, 80, 109.99, 110, 254.99, 263.99, 867, 744, 447],
[38.32, 65.52, 80, 110, 109.99, 255, 263.99, 868, 746, 447],
[38.38, 65.74, 79.99, 110, 110, 255, 263.99, 866, 741, 447],
[38.17, 66.53, 80, 109.99, 109.99, 254.99, 263.99, 872, 744, 448],
[38.14, 66.28, 80, 110, 109.99, 254.99, 263.99, 870, 746, 447],
[38.17, 67.21, 79.99, 110, 110, 254.99, 264, 873, 746, 447],
[38.11, 67.96, 79.99, 110, 110, 254.99, 263.99, 866, 745, 447],
[38.05, 66.57, 80, 110, 110, 255, 264, 871, 748, 448],
[38.11, 66.78, 80, 110, 110, 254.99, 264, 868, 747, 448],
[38.26, 66.8, 79.99, 110, 110, 254.99, 264, 873, 750, 448],
[38.02, 65.86, 80, 110, 110, 255, 263.99, 863, 745, 447],
[38.05, 65.01, 80, 109.99, 110, 255, 264.01, 867, 741, 447],
[38.08, 66.33, 80, 110, 110, 255, 264, 867, 743, 447],
[38.05, 65.24, 79.99, 109.99, 110, 255, 264, 872, 750, 448],
[38.14, 65.49, 80, 110, 110, 254.99, 264, 855, 736, 445],
[38.08, 66.21, 79.99, 110, 110, 254.99, 264, 851, 729, 444],
[38.08, 65.12, 80, 109.99, 110, 255, 263.99, 846, 727, 444],
[38.14, 65.88, 79.99, 109.99, 110, 255, 264, 844, 721, 443],
[38.2, 65.99, 80, 109.99, 109.99, 254.99, 264, 850, 731, 444],
[38.23, 66.75, 79.99, 109.99, 110, 254.97, 264.01, 852, 728, 444],
[38.32, 66.13, 80, 109.99, 110, 255, 264, 846, 725, 443],
[38.29, 65.39, 80, 110, 110, 254.99, 264, 842, 724, 442],
[38.35, 66.34, 79.99, 110, 110, 255, 263.99, 841, 724, 442],
[38.52, 65.49, 79.99, 110, 110, 255, 263.99, 845, 723, 443],
[38.32, 65.48, 80, 110, 110, 254.97, 263.99, 840, 723, 443],
[38.38, 64.12, 80, 110, 110, 255, 264, 845, 726, 442],
[38.38, 64.82, 80, 110, 110, 255, 263.99, 837, 724, 442],
[38.41, 66.13, 80, 110, 109.99, 255, 263.99, 843, 723, 442],
[38.5, 65.09, 79.99, 110, 110, 254.99, 263.99, 844, 725, 443],
[38.44, 66.16, 79.99, 110, 110, 254.97, 263.99, 856, 732, 445],
[38.55, 67.29, 79.99, 110, 110, 254.99, 263.99, 859, 733, 445],
[38.52, 66.37, 80, 110, 110, 255, 264, 849, 726, 443],
[38.58, 65.23, 80, 110, 110, 255, 263.99, 840, 722, 442],
[38.73, 65.59, 79.99, 109.99, 109.99, 255, 264, 845, 727, 443],
[38.82, 65.84, 79.99, 109.99, 109.99, 255.08, 264, 844, 723, 442],
[38.79, 65.07, 80, 110, 110, 254.99, 263.99, 846, 730, 443],
[38.76, 68.1, 79.99, 110, 110, 255, 263.99, 847, 730, 443],
[38.79, 67.61, 79.99, 110, 110, 255, 264, 847, 727, 443],
[38.85, 66.81, 79.99, 110, 110, 255, 264, 841, 723, 442],
[39.09, 66.44, 79.99, 110, 110, 255.35, 264, 865, 729, 443],
[39, 65.35, 79.99, 110, 110, 255, 264, 842, 730, 442],
[39.06, 65.3, 79.99, 110, 110, 254.99, 264, 845, 729, 442],
[39.15, 64.77, 80, 110, 110, 255, 263.99, 848, 728, 442],
[39.33, 65.26, 79.99, 110, 110, 255, 264, 865, 746, 444],
[39.55, 64.78, 80, 110, 110, 253.66, 264, 861, 737, 444],
[39.88, 65.15, 80, 109.99, 110, 255, 264, 847, 732, 441],
[40.25, 66.11, 80, 110, 109.99, 254.99, 264, 856, 740, 443],
[40.58, 66.03, 80, 110, 110, 255, 264, 865, 750, 443],
[40.77, 65.88, 80, 110, 110, 255, 264, 858, 740, 443],
[40.83, 66.33, 80, 110, 110, 255, 264, 868, 751, 443],
[40.92, 66.59, 80, 110, 110, 254.99, 264, 858, 739, 442],
[40.89, 66.34, 80, 110, 110, 254.99, 263.98, 857, 736, 442],
[41.02, 67.58, 79.99, 109.99, 110, 255, 263.99, 855, 729, 441],
[41.14, 67.28, 80, 110, 110, 254.99, 263.99, 855, 733, 441],
[41.08, 68.03, 80, 109.99, 110, 254.99, 263.99, 846, 727, 440],
[41.23, 68.22, 80, 110, 110, 255, 263.99, 847, 722, 439],
[41.33, 68.58, 79.99, 110, 110, 254.99, 263.99, 836, 723, 437],
[41.48, 67.09, 80, 110, 110, 255, 264, 839, 721, 437],
[42.81, 66.08, 80, 110, 110, 255, 264, 845, 723, 437],
[42.87, 65.91, 80, 110, 110, 255, 264, 869, 742, 441],
[42.97, 66.36, 79.99, 110, 109.99, 254.99, 264, 859, 736, 440],
[43.03, 65.74, 79.99, 109.99, 109.99, 254.99, 263.99, 862, 737, 441],
[43.16, 65.01, 80, 109.99, 110, 254.99, 263.99, 863, 740, 440],
[43.77, 63.7, 79.99, 110, 109.99, 254.99, 263.99, 853, 731, 439],
[43.93, 63.52, 79.99, 110, 109.99, 254.99, 264, 853, 732, 438],
[43.8, 65.06, 80, 109.99, 109.99, 255, 264, 860, 731, 439],
[43.87, 66.04, 79.99, 110, 110, 255, 263.99, 860, 734, 440],
[43.8, 67.62, 79.99, 109.99, 110, 255, 263.99, 859, 732, 439],
[43.97, 66.42, 79.99, 110, 109.99, 255, 264, 870, 741, 442],
[43.93, 66.33, 79.99, 110, 110, 254.99, 264, 862, 736, 440],
[44.06, 65.22, 80, 110, 110, 255, 263.99, 856, 730, 439],
[44.1, 65.33, 79.99, 110, 110, 255, 264, 850, 722, 437],
[44.06, 65.49, 79.99, 110, 109.99, 255, 263.99, 868, 735, 440],
[44.19, 64.56, 80, 109.99, 109.99, 254.99, 263.99, 857, 728, 439],
[44.23, 64.11, 80, 110, 110, 255, 263.99, 858, 730, 439],
[45.22, 62.62, 80, 110, 110, 255, 263.99, 866, 736, 439],
[45.18, 63.63, 79.99, 110, 109.99, 255, 264, 860, 728, 439],
[45.31, 63.73, 80, 109.99, 110, 254.99, 264, 849, 720, 436],
[46.02, 64.53, 79.99, 110, 110, 254.99, 264, 850, 722, 437],
[45.99, 64.33, 79.99, 109.99, 109.99, 255, 264, 846, 722, 435],
[45.99, 63.77, 79.99, 110, 110, 254.99, 263.99, 845, 721, 436],
[45.99, 65.81, 79.99, 110, 110, 254.99, 263.99, 846, 719, 436],
[46.09, 66.21, 79.99, 110, 110, 255, 264, 857, 726, 438],
[46.02, 64.8, 80, 110, 109.99, 254.99, 263.99, 850, 725, 436],
[46.09, 66.05, 80, 110, 110, 255, 263.99, 839, 712, 434],
[46.05, 64.79, 80, 110, 110, 255, 264, 844, 716, 435],
[46.05, 64.15, 80, 109.99, 110, 255, 264.01, 860, 731, 439],
[46.12, 63.28, 80, 110, 110, 254.99, 264, 861, 733, 439],
[46.05, 63.37, 80, 110, 110, 255, 263.99, 862, 736, 440],
[45.92, 64.19, 80, 110, 110, 255, 264, 862, 737, 439],
[45.88, 64.29, 80, 110, 110, 255, 264, 857, 731, 438],
[45.78, 63.96, 80, 109.99, 110, 254.99, 263.99, 852, 720, 437],
[45.78, 63.69, 79.99, 109.99, 109.99, 254.99, 263.99, 853, 723, 437],
[45.68, 64.6, 80, 109.99, 110, 254.99, 264, 847, 719, 436],
[45.75, 64.1, 79.99, 109.99, 110, 255, 263.99, 850, 722, 437],
[45.75, 65.68, 80, 110, 109.99, 255, 263.97, 862, 731, 439],
[45.72, 65.26, 79.99, 110, 110, 254.99, 263.99, 859, 733, 438],
[45.75, 64.48, 80, 110, 110, 255, 264, 851, 724, 437],
[45.78, 64.8, 80, 110, 110, 254.99, 263.99, 848, 720, 436],
[45.52, 65.27, 80, 110, 110, 254.99, 264, 845, 719, 436],
[45.48, 64.3, 79.99, 110, 109.99, 254.99, 263.94, 851, 716, 436],
[45.65, 64.02, 79.99, 110, 109.99, 255, 263.99, 846, 720, 436],
[45.35, 64.71, 79.99, 110, 109.99, 255, 264, 843, 721, 436],
[45.31, 64.94, 79.99, 110, 110, 255, 264, 846, 718, 436],
[45.25, 64.89, 80, 110, 109.99, 254.99, 263.99, 861, 731, 439],
[45.28, 65.88, 79.99, 110, 110, 255, 263.98, 865, 739, 439],
[45.12, 64.9, 79.99, 110, 110, 255, 264, 845, 723, 436],
[45.02, 64.11, 80, 110, 110, 254.99, 264, 844, 718, 435],
[45.05, 63.51, 79.99, 110, 110, 255, 263.93, 868, 719, 437],
[44.82, 63.17, 79.99, 110, 110, 255, 264, 856, 729, 438],
[44.78, 62.98, 79.99, 110, 110, 255, 263.99, 852, 728, 438],
[44.72, 62.81, 80, 110, 110, 254.99, 263.99, 849, 724, 437],
[44.62, 64.08, 80, 110, 109.99, 254.99, 263.99, 848, 720, 436],
[44.65, 64.27, 80, 109.99, 110, 254.99, 262.52, 867, 719, 436],
[44.55, 64.43, 80, 110, 110, 254.99, 264, 840, 713, 434],
[44.55, 65.28, 80, 109.99, 109.99, 255, 264, 841, 715, 434],
[44.46, 65.05, 80, 110, 110, 254.99, 264, 846, 718, 436],
[44.39, 66.55, 80, 110, 110, 255, 264, 841, 719, 435],
[44.13, 66.62, 80, 110, 110, 255, 264, 869, 737, 440],
[44.06, 66.38, 79.99, 110, 110, 254.99, 264, 870, 741, 441],
[44, 66.47, 80, 109.99, 110, 255, 263.99, 877, 747, 441],
[43.97, 67.52, 79.99, 110, 109.99, 255, 263.99, 884, 750, 442],
[43.84, 67.25, 80, 109.99, 110, 254.99, 263.99, 870, 739, 441],
[43.8, 65.68, 80, 109.99, 110, 255, 264, 870, 741, 441],
[43.67, 64.38, 79.99, 110, 110, 255, 264, 877, 748, 442],
[43.84, 64.41, 79.99, 110, 110, 255, 263.99, 876, 746, 442],
[43.54, 66.14, 80, 110, 110, 255, 263.99, 878, 749, 442],
[43.42, 66.39, 79.99, 109.99, 110, 254.99, 264, 866, 741, 441],
[43.35, 67.86, 79.99, 110, 110, 254.99, 263.99, 870, 739, 441],
[43.16, 67.04, 80, 110, 110, 255, 264, 881, 755, 442],
[43.03, 69.41, 79.99, 110, 110, 255, 264, 868, 740, 441],
[43.03, 68.7, 80, 110, 110, 254.99, 264, 872, 739, 441],
[42.97, 66.7, 80, 110, 110, 255, 263.99, 868, 738, 441],
[42.9, 65.9, 79.99, 110, 110, 254.99, 264, 865, 741, 441],
[42.87, 64.66, 79.99, 110, 110, 254.99, 264, 859, 730, 439],
[42.84, 64.95, 80, 110, 110, 255, 264, 851, 728, 438],
[42.87, 64.83, 79.99, 110, 110, 254.99, 264, 867, 738, 441],
[42.84, 64.1, 79.99, 110, 110, 255, 263.99, 867, 735, 440],
[42.77, 63.76, 80, 110, 110, 255, 263.99, 859, 726, 439],
[42.62, 65.09, 79.99, 109.99, 110, 255, 264, 843, 718, 436],
[42.58, 66.67, 79.99, 110, 109.99, 254.99, 264, 842, 720, 436],
[42.55, 66.94, 79.99, 110, 110, 254.99, 263.99, 849, 729, 438],
[42.49, 66.16, 80, 110, 110, 255, 263.99, 855, 728, 438],
[42.3, 65.83, 80, 110, 110, 255, 263.99, 856, 729, 439],
[42.42, 67.03, 80, 110, 109.99, 255, 264, 852, 730, 438],
[42.36, 67.71, 79.99, 110, 110, 255, 264, 849, 724, 437],
[42.39, 67.63, 79.99, 110, 110, 255, 263.99, 843, 721, 436],
[42.39, 68.68, 80, 110, 110, 254.99, 263.99, 854, 729, 439],
[42.52, 66.78, 79.99, 110, 110, 254.99, 263.99, 873, 746, 442],
[42.36, 66.12, 79.99, 110, 110, 255, 264, 871, 744, 441],
[42.52, 65.44, 80, 110, 110, 254.99, 263.99, 877, 744, 442],
[42.33, 63.88, 80, 110, 110, 254.99, 263.99, 868, 740, 441],
[42.36, 64.85, 79.99, 110, 110, 255, 263.98, 861, 733, 439],
[42.3, 64.77, 79.99, 110, 110, 254.99, 264, 857, 731, 439],
[42.36, 65.19, 80, 110, 110, 255, 264, 860, 732, 439],
[42.42, 65.43, 79.99, 110, 110, 255, 264, 874, 744, 441],
[42.3, 66.36, 80, 110, 110, 254.99, 264, 871, 743, 442],
[42.49, 67.01, 80, 110, 109.99, 254.99, 264, 866, 736, 440],
[42.08, 66.6, 79.99, 110, 110, 254.99, 264, 855, 732, 438],
[42.39, 66.5, 80, 110, 110, 254.99, 263.99, 848, 721, 437],
[42.27, 65.48, 79.99, 110, 110, 255, 264, 856, 732, 438],
[42.2, 65.91, 80, 110, 109.99, 255, 263.99, 867, 739, 441],
[42.17, 65.59, 80, 110, 109.99, 255, 263.99, 860, 731, 440],
[42.08, 64.78, 80, 110, 110, 254.99, 264, 851, 729, 438],
[41.98, 64.48, 79.99, 110, 110, 255, 264, 859, 732, 439],
[41.92, 64.86, 79.99, 109.99, 110, 254.99, 263.99, 869, 740, 441],
[41.83, 66.88, 80, 110, 109.99, 254.99, 264, 868, 735, 441],
[41.89, 67.17, 79.99, 110, 109.99, 254.99, 264, 871, 741, 441],
[41.73, 66.87, 79.99, 110, 109.99, 255, 263.99, 862, 732, 440],
[41.76, 65, 79.99, 110, 110, 255, 264, 860, 732, 440],
[41.83, 64.17, 80, 110, 110, 254.99, 263.99, 848, 723, 437],
[41.89, 65.19, 80, 110, 110, 254.99, 263.99, 869, 732, 439],
[41.86, 66.13, 80, 110, 110, 254.99, 263.99, 854, 732, 438],
[41.86, 67.11, 79.99, 110, 110, 254.99, 263.99, 848, 725, 437],
[41.79, 67.27, 79.99, 109.99, 109.99, 254.99, 264, 872, 743, 442],
[41.79, 66.66, 80, 110, 110, 255, 263.99, 863, 739, 441],
[41.7, 65.69, 79.99, 109.99, 110, 255, 263.98, 871, 725, 440],
[41.79, 65.28, 80, 110, 110, 255, 263.99, 855, 730, 439],
[41.67, 64.03, 79.99, 110, 110, 254.99, 264, 846, 722, 438],
[41.98, 64.01, 79.99, 109.99, 109.99, 254.99, 263.99, 850, 723, 438],
[41.83, 64.5, 80, 109.99, 110, 255, 263.99, 860, 731, 439],
[41.7, 63.67, 79.99, 110, 110, 254.99, 263.99, 889, 732, 440],
[41.45, 63.45, 80, 110, 110, 255, 263.99, 857, 731, 439],
[41.67, 63.73, 79.99, 110, 109.99, 255, 264, 850, 728, 438],
[41.73, 64.28, 79.99, 110, 109.99, 254.99, 264, 853, 729, 439],
[41.54, 64.56, 80, 110, 110, 255, 263.99, 854, 730, 439],
[41.54, 64.13, 80, 110, 110, 254.99, 263.97, 858, 734, 440],
[41.36, 67.34, 79.99, 110, 110, 254.99, 264, 851, 729, 439],
[41.26, 67.17, 79.99, 110, 110, 255, 264.01, 842, 717, 437],
[41.23, 65.41, 80, 109.99, 110, 255, 264, 844, 725, 437],
[41.36, 67.08, 79.99, 110, 110, 254.98, 263.94, 847, 723, 439],
[41.33, 66.04, 80, 110, 110, 255, 263.99, 854, 731, 440],
[41.08, 66.98, 79.99, 110, 110, 254.99, 264, 861, 734, 441],
[40.98, 67.2, 80, 109.99, 110, 254.99, 264, 846, 722, 438],
[41.11, 67.92, 80, 110, 110, 255, 263.99, 849, 725, 439],
[40.98, 66.63, 79.99, 110, 110, 254.99, 263.99, 846, 719, 439],
[40.89, 68.01, 79.99, 110, 110, 254.99, 264, 851, 731, 440],
[40.89, 67.94, 80, 110, 110, 254.99, 263.99, 855, 731, 441],
[40.74, 66.94, 80, 110, 109.99, 254.99, 264, 848, 724, 438],
[40.68, 65.88, 80, 110, 110, 254.99, 264, 844, 724, 438],
[40.55, 65.79, 79.99, 110, 110, 255.06, 264.42, 849, 726, 439],
[40.55, 65.69, 80, 110, 110, 254.99, 263.99, 862, 736, 441],
[40.68, 65.34, 79.99, 110, 110, 255, 264, 877, 742, 443],
[40.43, 65.12, 80, 110, 110, 255, 263.99, 871, 741, 443],
[40.43, 65.35, 80, 109.99, 110, 255, 264, 863, 733, 442],
[40.4, 66.48, 79.99, 110, 110, 255.16, 264.04, 854, 730, 441],
[40.46, 65.2, 79.99, 110, 109.99, 254.99, 263.99, 866, 737, 443],
[40.03, 66.44, 79.99, 110, 110, 255, 264, 864, 735, 442],
[40.06, 66.3, 79.99, 109.99, 110, 255, 263.99, 864, 742, 443],
[39.94, 68.21, 79.99, 110, 110, 255, 263.99, 858, 732, 442],
[39.79, 67.38, 80, 110, 110, 255.14, 275.11, 850, 728, 441],
[39.88, 66.9, 79.99, 110, 110, 255, 263.99, 861, 735, 443],
[39.85, 66.15, 80, 110, 110, 255, 264, 861, 736, 443],
[39.76, 65.97, 79.99, 110, 110, 254.99, 263.99, 866, 740, 444],
[39.67, 64.99, 80, 110, 110, 254.99, 264, 867, 744, 444],
[39.79, 65.35, 79.99, 110, 110, 254.99, 264, 857, 731, 443],
[39.61, 65.01, 80, 110, 110, 255, 264, 852, 739, 442],
[39.49, 67.27, 80, 110, 109.99, 255, 264, 871, 743, 444],
[39.33, 66.32, 79.99, 110, 110, 254.99, 264, 873, 745, 445],
[39.43, 65.09, 79.99, 110, 109.99, 255, 263.99, 868, 737, 444],
[39.27, 65.93, 79.99, 110, 110, 255, 263.99, 863, 738, 444],
[39.49, 65.83, 80, 110, 109.99, 255, 264, 855, 733, 442],
[39.33, 65.05, 80, 110, 110, 255, 263.99, 861, 738, 443],
[39.36, 66.15, 80, 110, 110, 255, 263.99, 862, 739, 443],
[39.36, 65.7, 80, 110, 110, 255, 263.99, 876, 747, 445],
[39.49, 66.67, 80, 110, 109.99, 255, 264, 871, 749, 445],
[39.58, 67.41, 79.99, 110, 110, 255, 264, 864, 740, 444],
[39.52, 67.72, 80, 110, 110, 255, 264, 852, 729, 441],
[39.79, 66.66, 80, 110, 109.99, 254.99, 263.99, 850, 730, 442],
[39.58, 66.68, 79.99, 110, 109.99, 254.99, 263.98, 866, 738, 443],
[39.61, 65.3, 80, 110, 110, 254.99, 264, 868, 743, 444],
[39.7, 65.6, 79.99, 110, 110, 255, 263.99, 872, 742, 444],
[39.73, 66.97, 79.99, 109.99, 110, 254.99, 264, 865, 741, 444],
[39.79, 65.76, 79.99, 110, 110, 255, 263.99, 868, 744, 443],
[39.73, 65.1, 80, 109.99, 110, 254.99, 263.99, 858, 735, 443],
[39.82, 65.18, 79.99, 109.99, 110, 254.99, 264, 860, 735, 443],
[39.67, 64.22, 80, 110, 110, 254.99, 264, 852, 728, 441],
[39.73, 65.11, 79.99, 109.99, 110, 255, 263.99, 855, 732, 442],
[39.73, 66.51, 79.99, 109.99, 109.99, 255, 264, 862, 740, 442],
[39.82, 66.86, 80, 110, 110, 255, 263.99, 872, 739, 445],
[39.85, 66.41, 80, 110, 110, 255, 264, 862, 735, 443],
[40, 65.71, 80, 110, 110, 254.99, 264, 865, 739, 444],
[40.25, 65.45, 80, 110, 109.99, 254.99, 263.99, 868, 741, 444],
[40.12, 66.14, 79.99, 110, 110, 255, 264, 868, 737, 443],
[40.22, 65.89, 79.99, 110, 110, 254.99, 264, 873, 743, 444],
[40.18, 66.03, 80, 110, 110, 255, 264, 885, 752, 444],
[40.25, 65.3, 79.99, 110, 109.99, 254.99, 264, 889, 757, 444],
[40.25, 64.88, 79.99, 110, 110, 255, 264, 879, 752, 444],
[40.31, 65.15, 80, 110, 110, 254.99, 263.99, 880, 748, 444],
[40.28, 65.02, 79.99, 110, 110, 254.99, 264.01, 871, 741, 444],
[40.18, 64.05, 80, 110, 110, 255, 263.99, 869, 741, 444],
[40.18, 64.86, 80, 110, 110, 255, 264, 862, 739, 443],
[40.09, 65.6, 80, 110, 110, 254.99, 263.99, 853, 730, 441],
[40.18, 65.47, 80, 110, 109.99, 255, 264, 859, 736, 443],
[40.03, 66.57, 79.99, 110, 110, 254.99, 263.99, 863, 739, 444],
[39.76, 65.56, 80, 109.99, 110, 254.99, 264, 860, 739, 443],
[39.91, 66.19, 80, 110, 110, 255, 264, 861, 735, 443],
[39.91, 65.68, 80, 110, 110, 255, 264, 845, 722, 440],
[39.49, 64.93, 80, 110, 109.99, 254.99, 264, 857, 732, 443],
[39.46, 65.57, 79.99, 109.99, 110, 254.99, 264, 866, 737, 444],
[39.73, 65.56, 80, 109.99, 109.99, 255, 264, 860, 735, 443],
[39.55, 65.58, 79.99, 110, 110, 254.99, 264, 866, 740, 444],
[39.64, 65.65, 80, 110, 110, 255, 264, 863, 738, 444],
[39.82, 65.36, 80, 110, 110, 255, 264, 864, 739, 443],
[39.82, 65.86, 79.99, 109.99, 110, 254.99, 264.01, 866, 739, 444],
[40, 65.9, 79.99, 110, 110, 255, 264, 865, 736, 443],
[40.31, 66.14, 80, 110, 110, 255, 263.99, 869, 744, 444],
[40.15, 66.5, 80, 110, 110, 254.99, 263.99, 847, 726, 440],
[40.28, 66.09, 79.99, 110, 110, 254.99, 264, 850, 728, 440],
[40.61, 65.46, 80, 110, 110, 255, 264, 860, 740, 442],
[40.61, 67.34, 79.99, 110, 110, 255, 264, 856, 736, 442],
[40.58, 67.44, 79.99, 110, 109.99, 254.99, 264, 860, 737, 442],
[40.64, 66.12, 79.99, 110, 110, 254.99, 263.99, 852, 731, 440],
[40.92, 65.3, 79.99, 110, 110, 255, 263.99, 846, 718, 439],
[40.92, 65.38, 79.99, 110, 110, 255, 263.99, 856, 729, 441],
[41.05, 65.1, 80, 110, 110, 255, 263.99, 857, 731, 441],
[41.17, 64.56, 79.99, 110, 110, 255, 263.98, 855, 731, 441],
[41.36, 63.14, 79.99, 110, 110, 255, 264, 848, 725, 439],
[41.7, 63.64, 80, 110, 110, 254.99, 264, 847, 725, 439],
[42.23, 62.63, 79.99, 110, 110, 255, 263.99, 860, 738, 441],
[42.27, 61.6, 79.99, 110, 110, 254.99, 264, 863, 734, 441],
[43.97, 65.25, 80, 110, 110, 254.96, 264, 860, 724, 440],
[44.26, 64.07, 80, 110, 109.99, 254.99, 264, 844, 718, 436],
[44.55, 64.9, 79.99, 110, 110, 255, 263.99, 846, 716, 437],
[44.55, 64.27, 80, 110, 110, 255, 263.99, 855, 726, 438],
[45.12, 62.45, 80, 109.99, 109.99, 255, 263.99, 859, 722, 439],
[45.45, 64.83, 79.99, 109.99, 109.99, 255.24, 263.99, 871, 728, 440],
[45.55, 66.4, 79.99, 110, 110, 254.99, 264, 867, 732, 440],
[45.65, 66.55, 80, 109.99, 110, 255, 264, 863, 733, 439],
[45.62, 65.88, 79.99, 109.99, 110, 255, 263.99, 866, 727, 440],
[45.55, 65.21, 80, 110, 110, 254.99, 264, 869, 735, 441],
[45.65, 65.92, 79.99, 110, 110, 255.28, 264, 889, 730, 440],
[45.25, 65.95, 80, 110, 110, 254.99, 263.99, 864, 730, 440],
[45.95, 65.57, 79.99, 109.99, 109.99, 254.99, 264, 867, 731, 440],
[45.99, 65.35, 80, 110, 110, 255, 264, 866, 731, 439],
[46.12, 65.73, 80, 109.99, 110, 254.99, 264, 872, 740, 441],
[46.19, 65.61, 79.99, 110, 109.99, 255, 264, 869, 733, 440],
[46.26, 65.26, 79.99, 110, 110, 254.99, 263.99, 875, 742, 442],
[46.09, 64.53, 80, 110, 110, 255, 264, 864, 726, 440],
[46.15, 64.34, 80, 109.99, 110, 254.99, 263.99, 865, 731, 440],
[46.39, 63.85, 80, 110, 110, 254.99, 263.99, 868, 735, 440],
[46.26, 65.9, 80, 110, 109.99, 255, 264, 859, 728, 438],
[46.26, 66.66, 79.99, 110, 110, 255, 263.99, 852, 718, 437],
[46.19, 67.63, 80, 110, 110, 254.99, 264, 854, 719, 438],
[45.99, 65.52, 80, 110, 109.99, 255, 264, 846, 714, 435],
[46.05, 64.55, 80, 110, 110, 254.99, 264, 843, 711, 435],
[46.02, 63.32, 79.99, 110, 110, 254.99, 263.99, 861, 722, 439],
[46.19, 63.39, 80, 110, 109.99, 254.99, 264, 854, 718, 437],
[46.05, 64.76, 79.99, 110, 109.99, 254.99, 263.99, 852, 716, 437],
[46.15, 64.76, 79.99, 110, 110, 254.99, 263.99, 854, 720, 437],
[46.19, 66.46, 79.99, 109.99, 109.99, 255, 264, 859, 721, 438],
[45.88, 67.25, 80, 110, 109.99, 254.99, 263.99, 876, 735, 441],
[45.95, 66.11, 80, 110, 109.99, 255, 263.99, 861, 725, 438],
[45.99, 66.35, 80, 110, 110, 255, 264, 857, 721, 438],
[46.15, 66.77, 79.99, 110, 109.99, 255, 264, 873, 736, 441],
[45.95, 65.33, 80, 110, 110, 255, 264, 867, 735, 440],
[45.99, 66.1, 80, 109.99, 110, 254.99, 264, 872, 734, 441],
[45.92, 67.03, 79.99, 110, 110, 255, 264, 867, 731, 440],
[45.92, 63.63, 80, 110, 110, 254.99, 263.99, 864, 728, 439],
[45.85, 62.91, 80, 110, 109.99, 254.99, 263.99, 868, 732, 440],
[45.78, 63.65, 79.99, 110, 110, 254.99, 264, 867, 730, 439],
[45.78, 62.57, 80, 110, 110, 255, 264, 869, 731, 440],
[45.82, 62.18, 80, 110, 110, 254.99, 263.98, 866, 731, 440],
[45.95, 61.42, 79.99, 110, 110, 255, 264, 865, 725, 440],
[45.65, 61.97, 80, 110, 110, 254.99, 263.99, 866, 729, 440],
[45.62, 62.61, 79.99, 110, 110, 254.99, 264, 865, 728, 439],
[45.68, 63.2, 79.99, 110, 109.99, 254.99, 263.99, 868, 731, 439],
[45.75, 63.73, 80, 110, 110, 254.99, 264, 862, 726, 439],
[45.52, 65.3, 79.99, 110, 109.99, 254.99, 264, 859, 722, 438],
[45.52, 64.91, 80, 110, 110, 254.99, 263.99, 849, 713, 436],
[45.48, 64.83, 79.99, 110, 110, 254.99, 263.99, 848, 712, 436],
[45.31, 67.3, 80, 110, 110, 255, 264.01, 876, 741, 441],
[45.25, 66.4, 80, 109.99, 109.99, 254.99, 263.99, 866, 727, 439],
[45.15, 64.83, 80, 110, 110, 254.99, 263.99, 867, 732, 440],
[44.98, 65.69, 79.99, 110, 110, 255, 264, 871, 734, 440],
[44.98, 65.09, 79.99, 110, 110, 255, 264, 868, 731, 440],
[44.95, 64.04, 79.99, 110, 109.99, 255, 264.01, 870, 733, 441],
[44.98, 64.63, 79.99, 110, 110, 255, 264, 873, 731, 440],
[44.62, 63.96, 80, 109.99, 110, 254.99, 263.99, 873, 732, 441],
[44.59, 65.27, 80, 110, 110, 254.99, 264, 869, 734, 440],
[44.52, 66.85, 79.99, 109.99, 109.99, 254.99, 264, 873, 734, 441],
[44.46, 66.76, 80, 110, 110, 254.99, 264, 872, 736, 440],
[44.46, 67.99, 80, 110, 109.99, 254.99, 264, 868, 732, 441],
[44.46, 68.18, 80, 110, 110, 254.99, 263.98, 878, 730, 440],
[44.39, 67.38, 79.99, 109.99, 110, 254.99, 263.99, 868, 733, 440],
[44.32, 66.49, 80, 109.99, 110, 255, 264, 846, 717, 436],
[44.19, 65.04, 79.99, 110, 110, 254.99, 263.95, 875, 733, 440],
[44.32, 65.9, 80, 110, 110, 255, 263.98, 864, 731, 439],
[44.19, 67.21, 80, 110, 110, 255.01, 263.79, 865, 729, 440],
[44.03, 66.32, 80, 109.99, 110, 254.99, 263.99, 858, 728, 438],
[44.06, 67.83, 80, 110, 110, 255, 263.99, 847, 718, 436],
[44, 66.75, 79.99, 110, 110, 254.99, 264, 841, 713, 434],
[43.97, 65.85, 79.99, 110, 110, 254.99, 264, 842, 715, 435],
[43.9, 65.57, 80, 110, 110, 254.98, 263.22, 861, 723, 439],
[43.9, 66.35, 79.99, 110, 110, 255, 264, 864, 733, 440],
[43.93, 64.47, 80, 110, 110, 254.99, 264, 868, 730, 440],
[43.87, 65.42, 80, 109.99, 109.99, 255, 263.99, 869, 732, 440],
[43.9, 65.62, 80, 110, 110, 255, 263.76, 886, 732, 440],
[43.87, 65.88, 79.99, 110, 110, 255, 263.99, 870, 738, 440],
[43.9, 66.57, 79.99, 110, 110, 255, 264, 871, 734, 441],
[43.87, 65.02, 79.99, 110, 110, 255, 263.99, 867, 731, 439],
[43.77, 64.08, 80, 110, 110, 255, 262.98, 875, 737, 441],
[43.84, 64.11, 79.99, 110, 109.99, 255, 264, 873, 736, 441],
[43.84, 63.67, 79.99, 110, 110, 254.99, 264, 870, 738, 441],
[43.77, 64.16, 80, 110, 110, 254.99, 263.99, 866, 730, 440],
[43.87, 64.33, 80, 110, 110, 255, 263.99, 865, 728, 440],
[43.74, 64.11, 79.99, 110, 109.99, 255, 263.72, 852, 720, 437],
[43.64, 64.33, 80, 110, 110, 254.99, 264, 869, 731, 441],
[43.64, 64.19, 80, 110, 110, 254.99, 263.99, 872, 740, 442],
[43.54, 64.51, 80, 110, 110, 254.99, 263.99, 870, 735, 441],
[43.58, 65.74, 80, 110, 110, 255, 264, 872, 735, 441],
[43.61, 66.47, 80, 110, 110, 255, 263.59, 893, 735, 441],
[43.45, 66.81, 79.99, 109.99, 110, 254.99, 264, 873, 737, 441],
[43.32, 65.44, 80, 110, 109.99, 254.99, 264, 869, 735, 441],
[43.35, 64.85, 79.99, 110, 110, 254.99, 264, 874, 738, 441],
[43.32, 65.52, 79.99, 110, 110, 254.99, 264, 869, 732, 441],
[43.03, 65.29, 80, 109.99, 109.99, 255, 263.99, 877, 741, 442],
[43.32, 65.07, 80, 110, 110, 255, 263.99, 869, 735, 441],
[43.19, 64.92, 79.99, 110, 110, 255, 263.99, 863, 724, 439],
[43.19, 65.15, 80, 110, 110, 254.99, 264, 852, 723, 438],
[43.03, 65.78, 79.99, 110, 110, 255, 263.99, 844, 710, 435],
[43.16, 65.68, 79.99, 109.99, 110, 254.99, 264, 851, 719, 438],
[43.19, 66.29, 79.99, 110, 110, 255, 264, 865, 730, 440],
[43.13, 65.36, 80, 110, 110, 255, 263.99, 867, 730, 441],
[43.13, 63.48, 79.99, 110, 110, 255, 264, 855, 723, 439],
[43.16, 63.66, 79.99, 109.99, 110, 254.99, 263.99, 844, 712, 435],
[43.13, 64.42, 79.99, 110, 109.99, 255, 263.99, 850, 713, 437],
[43.16, 64.88, 79.99, 110, 109.99, 255, 263.99, 851, 720, 437],
[43.19, 63.9, 80, 110, 110, 254.99, 263.99, 856, 725, 438],
[43.29, 64.75, 80, 110, 110, 254.99, 263.99, 875, 736, 441],
[43.13, 66.3, 80, 110, 109.99, 255, 264, 868, 732, 440],
[43.25, 67.22, 79.99, 110, 110, 255, 264, 867, 730, 440],
[43.25, 68.05, 79.99, 110, 110, 254.99, 264, 880, 741, 441],
[43.38, 66.88, 79.99, 110, 110, 255, 264, 883, 740, 442],
[43.32, 66.42, 80, 109.99, 110, 255, 264, 874, 734, 441],
[43.48, 65.71, 80, 110, 110, 255, 264, 870, 731, 441],
[43.35, 64.24, 80, 110, 110, 254.99, 263.99, 866, 730, 440],
[43.45, 63.72, 79.99, 110, 110, 254.99, 264, 870, 736, 441],
[43.51, 64.19, 80, 109.99, 110, 254.99, 263.99, 871, 737, 441],
[43.38, 64.69, 80, 110, 110, 254.99, 263.99, 867, 734, 441],
[43.45, 65.64, 80, 110, 110, 254.99, 264, 871, 732, 440],
[43.45, 65.61, 80, 110, 110, 255, 263.99, 864, 730, 440],
[43.22, 66.35, 79.99, 110, 110, 255, 264, 863, 731, 440],
[43.25, 66.32, 80, 110, 110, 255, 264, 867, 733, 441],
[43.13, 66.44, 80, 110, 109.99, 254.99, 264, 871, 739, 441],
[42.9, 67.57, 80, 110, 109.99, 254.99, 264, 870, 740, 442],
[42.9, 68.55, 79.99, 110, 110, 255, 263.99, 867, 736, 441],
[42.74, 64.55, 80, 110, 110, 254.99, 263.98, 868, 735, 441],
[42.74, 63.73, 80, 110, 110, 254.99, 263.98, 871, 733, 441],
[42.68, 62.97, 80, 110, 110, 255, 264, 870, 736, 441],
[42.81, 66.37, 80, 110, 110, 255, 264, 877, 743, 442],
[42.84, 65.82, 80, 110, 109.99, 254.99, 264, 873, 736, 441],
[42.71, 64.93, 79.99, 110, 110, 254.99, 263.99, 883, 746, 442],
[43.03, 63.53, 79.99, 109.99, 110, 254.99, 263.99, 874, 737, 442],
[43.06, 62.89, 80, 110, 110, 254.99, 264, 871, 734, 441],
[43.06, 62.37, 80, 110, 110, 255, 264, 870, 733, 440],
[43.09, 62.64, 80, 110, 109.99, 255, 263.99, 876, 737, 441],
[43.03, 62.73, 80, 110, 110, 254.99, 264, 870, 734, 441],
[43.13, 62.98, 80, 110, 110, 254.99, 264, 871, 737, 441],
[43.19, 64.03, 80, 109.99, 110, 255, 264, 869, 737, 441],
[43.22, 65.65, 79.99, 110, 109.99, 254.99, 263.99, 868, 732, 441],
[43.13, 64.21, 80, 110, 110, 254.99, 264, 871, 732, 441],
[43.13, 66.54, 80, 110, 110, 255, 263.99, 869, 732, 441],
[43.19, 66.24, 79.99, 110, 110, 255, 264, 869, 735, 441],
[43.25, 65.28, 80, 110, 110, 255, 263.99, 874, 737, 441],
[43.29, 64.51, 80, 110, 110, 254.99, 263.99, 867, 734, 440],
[43.13, 64.87, 80, 110, 110, 254.99, 263.99, 867, 733, 441],
[43.35, 65.28, 80, 110, 110, 255, 264, 870, 733, 441],
[43.32, 66.23, 79.99, 110, 109.99, 254.99, 263.99, 872, 736, 441],
[43.45, 66.1, 80, 110, 110, 254.99, 263.99, 875, 736, 441],
[43.35, 64.65, 80, 110, 110, 254.99, 263.99, 874, 734, 441],
[43.54, 64.74, 80, 110, 110, 255, 264, 867, 731, 440],
[43.45, 64.62, 80, 110, 110, 255, 264, 873, 740, 441],
[43.48, 65.38, 79.99, 110, 110, 255, 263.99, 871, 731, 441],
[43.61, 65.92, 79.99, 110, 110, 254.99, 263.99, 872, 734, 441],
[43.61, 66.71, 79.99, 110, 109.99, 255, 264, 870, 734, 440],
[43.64, 66.78, 80, 110, 110, 255, 264, 873, 739, 441],
[43.9, 66.38, 79.99, 109.99, 109.99, 254.99, 264, 870, 740, 441],
[43.64, 65.98, 79.99, 109.99, 110, 255, 263.99, 868, 732, 440],
[43.64, 65.28, 80, 110, 110, 254.99, 263.98, 875, 736, 441],
[43.58, 67.3, 79.99, 110, 110, 255, 263.99, 860, 728, 439],
[43.54, 66.86, 80, 110, 110, 254.99, 264, 865, 731, 440],
[43.51, 66.22, 80, 110, 110, 255, 264, 874, 743, 441],
[43.71, 65.94, 79.99, 110, 110, 255, 264, 875, 739, 441],
[43.61, 63.44, 80, 110, 110, 254.99, 263.99, 871, 735, 441],
[43.58, 63.69, 80, 110, 110, 255, 264, 871, 736, 441],
[43.71, 63.68, 80, 110, 109.99, 254.99, 263.99, 877, 739, 441],
[43.87, 64.72, 80, 110, 110, 254.99, 263.98, 901, 738, 441],
[43.67, 65.22, 79.99, 110, 109.99, 255, 263.99, 878, 742, 441],
[43.71, 66.54, 79.99, 110, 110, 254.99, 263.99, 874, 736, 441],
[43.87, 65.24, 80, 110, 110, 254.99, 264, 870, 733, 441],
[43.74, 65.07, 80, 110, 110, 254.99, 263.99, 863, 729, 440],
[43.64, 64.84, 80, 109.99, 110, 254.99, 264, 866, 733, 440],
[43.71, 64.72, 79.99, 110, 109.99, 254.99, 263.99, 868, 731, 440],
[43.64, 64.58, 80, 110, 109.99, 255, 263.99, 866, 733, 440],
[43.67, 65.71, 79.99, 110, 110, 254.99, 263.99, 859, 724, 438],
[43.67, 66.06, 80, 109.99, 110, 255, 263.99, 846, 716, 436],
[43.58, 66.22, 79.99, 110, 109.99, 254.99, 263.99, 844, 715, 435],
[43.71, 65.08, 80, 110, 110, 254.99, 263.98, 845, 717, 436],
[43.74, 65.9, 80, 110, 109.99, 255, 263.99, 847, 713, 435],
[43.71, 66.37, 80, 110, 110, 255, 264, 845, 714, 435],
[43.87, 66.53, 80, 110, 110, 254.99, 263.9, 846, 712, 436],
[43.8, 65.9, 80, 110, 109.99, 255, 263.99, 861, 725, 439],
[43.67, 65.45, 79.99, 110, 109.99, 255, 264, 857, 722, 438],
[43.9, 64.92, 80, 110, 110, 255, 263.99, 861, 722, 438],
[43.84, 63.95, 80, 110, 110, 255, 264, 849, 719, 437],
[43.71, 63.16, 79.99, 110, 110, 255, 264.03, 846, 713, 436],
[43.71, 62.74, 79.99, 110, 110, 255, 264, 848, 715, 436],
[43.67, 63.49, 79.99, 110, 110, 255, 264, 849, 719, 437],
[43.71, 64.88, 79.99, 110, 110, 254.99, 264, 851, 720, 437],
[43.8, 63.99, 79.99, 110, 110, 254.99, 263.99, 870, 733, 440],
[43.97, 64.83, 80, 110, 110, 255.07, 263.71, 874, 734, 441],
[43.77, 64.45, 79.99, 110, 110, 254.99, 263.99, 868, 732, 441],
[43.84, 64.41, 79.99, 109.99, 110, 255, 263.99, 870, 733, 440],
[43.84, 64.18, 80, 110, 110, 255, 264, 869, 729, 441],
[43.77, 63.55, 80, 109.99, 110, 255, 263.99, 873, 739, 441],
[43.97, 64.13, 79.99, 109.99, 109.99, 255.14, 262.63, 879, 741, 440],
[43.9, 65.62, 80, 110, 110, 254.99, 264, 872, 731, 441],
[43.97, 65.74, 79.99, 109.99, 109.99, 254.99, 264, 857, 726, 439],
[44.06, 65.26, 80, 109.99, 110, 254.99, 264, 869, 731, 441],
[44, 66.63, 80, 109.99, 110, 255, 263.99, 880, 740, 442],
[44.23, 66.67, 80, 110, 110, 253.16, 266.34, 875, 737, 441],
[44.06, 65, 79.99, 110, 110, 255, 263.99, 877, 739, 441],
[43.93, 63.91, 80, 109.99, 110, 255, 264, 876, 739, 441],
[43.93, 66.23, 79.99, 110, 109.99, 254.99, 264, 868, 736, 441],
[43.97, 66.04, 80, 110, 110, 255, 263.99, 867, 732, 440],
[43.97, 65.72, 80, 109.99, 110, 254.99, 264, 872, 738, 441],
[44.06, 65.63, 80, 110, 110, 255, 264, 865, 731, 440],
[44.06, 65.14, 80, 110, 110, 255, 264, 870, 737, 440],
[43.97, 68.88, 80, 110, 109.99, 254.99, 263.99, 874, 741, 441],
[43.97, 66.87, 80, 110, 110, 254.99, 264, 869, 737, 440],
[44.03, 67.4, 79.99, 110, 110, 255, 263.99, 867, 735, 440],
[43.97, 66.12, 80, 110, 110, 255, 264, 872, 744, 441],
[43.93, 66.55, 79.99, 110, 110, 255, 263.99, 869, 737, 440],
[43.8, 65.85, 79.99, 110, 110, 255, 264, 860, 731, 439],
[43.9, 64.93, 80, 110, 109.99, 254.99, 263.99, 860, 728, 438],
[44.06, 66.66, 79.99, 110, 109.99, 255, 263.99, 869, 735, 440],
[43.87, 67.43, 79.99, 110, 110, 255, 263.99, 872, 736, 441],
[44, 67.56, 80, 110, 109.99, 254.99, 263.99, 871, 739, 441],
[43.87, 67.79, 80, 110, 110, 255, 264, 871, 741, 440],
[43.87, 67.13, 79.99, 109.99, 110, 254.99, 263.99, 872, 733, 441],
[43.87, 67.33, 80, 110, 110, 255, 264, 865, 730, 439],
[44, 65.79, 79.99, 109.99, 110, 254.99, 263.99, 862, 727, 439],
[44, 64.3, 80, 110, 110, 254.99, 264, 866, 735, 440],
[43.97, 65.08, 80, 110, 110, 255, 264, 872, 738, 441],
[43.84, 64.82, 79.99, 109.99, 110, 254.99, 264, 872, 740, 441],
[43.97, 65.67, 79.99, 110, 110, 254.99, 264, 872, 741, 441],
[43.84, 64.15, 79.99, 110, 109.99, 254.99, 263.99, 867, 730, 440],
[43.58, 64.02, 80, 110, 109.99, 255, 264, 866, 731, 440],
[43.77, 64, 79.99, 110, 110, 255, 263.99, 865, 729, 439],
[43.8, 64.65, 79.99, 110, 109.99, 254.99, 263.99, 867, 732, 440],
[43.84, 64.56, 80, 110, 109.99, 254.99, 264, 862, 730, 439],
[43.9, 64.69, 79.99, 110, 110, 254.99, 264, 859, 724, 438],
[43.77, 65.02, 79.99, 109.99, 110, 254.99, 264, 854, 722, 437],
[43.74, 64.66, 80, 109.99, 109.99, 255, 263.99, 857, 722, 438],
[43.67, 64.5, 79.99, 110, 110, 254.99, 264, 859, 723, 438],
[43.8, 65.98, 79.99, 109.99, 110, 254.99, 264, 870, 734, 441],
[43.84, 65.68, 80, 109.99, 110, 254.99, 264, 857, 725, 439],
[43.71, 67.32, 79.99, 110, 110, 255, 263.99, 867, 725, 440],
[43.84, 66.83, 79.99, 109.99, 110, 255, 264, 866, 730, 440],
[43.8, 66.37, 79.99, 110, 110, 254.99, 264, 870, 734, 441],
[43.67, 66.23, 79.99, 109.99, 110, 254.99, 264, 864, 726, 440],
[43.8, 65.27, 79.99, 110, 110, 255, 264, 857, 723, 438],
[43.71, 64.93, 80, 110, 110, 255, 264, 855, 722, 437],
[43.74, 63.51, 79.99, 110, 110, 255, 264, 862, 725, 439],
[43.71, 64.11, 79.99, 110, 110, 254.99, 263.99, 852, 722, 437],
[43.74, 64.79, 79.99, 109.99, 110, 255, 264, 860, 727, 439],
[43.8, 65.75, 79.99, 110, 109.99, 255, 264.01, 868, 731, 440],
[43.77, 65.15, 79.99, 109.99, 109.99, 255, 264, 871, 736, 440],
[43.77, 65.32, 80, 110, 109.99, 255, 263.99, 874, 736, 441],
[43.77, 65.19, 80, 110, 110, 254.98, 263.99, 878, 740, 441],
[43.8, 65.29, 79.99, 109.99, 109.99, 255, 263.99, 875, 740, 441],
[43.8, 65.73, 80, 110, 110, 254.99, 264, 869, 738, 441],
[43.74, 65.53, 80, 110, 110, 254.99, 263.99, 862, 728, 439],
[43.84, 65.8, 79.99, 109.99, 109.99, 254.99, 264, 868, 731, 439],
[43.74, 67.18, 79.99, 109.99, 110, 255, 264, 871, 733, 441],
[43.54, 65.73, 79.99, 110, 110, 254.99, 264, 868, 731, 440],
[43.61, 64.62, 79.99, 110, 110, 255, 263.99, 867, 733, 440],
[43.61, 64.31, 79.99, 110, 110, 255, 264, 855, 720, 437],
[43.64, 63.68, 80, 110, 109.99, 254.98, 264, 851, 724, 436],
[43.71, 64.08, 80, 110, 110, 254.99, 264, 856, 723, 438],
[43.58, 64.13, 79.99, 109.99, 110, 254.99, 263.99, 868, 732, 440],
[43.54, 63.84, 80, 110, 110, 255, 263.99, 860, 733, 438],
[43.74, 65.3, 79.99, 109.99, 110, 254.99, 264, 851, 718, 437],
[43.8, 64.58, 80, 110, 110, 254.97, 264, 859, 729, 438],
[43.61, 65.03, 79.99, 110, 110, 255, 264, 862, 728, 439],
[43.61, 64.79, 80, 110, 110, 255, 264, 862, 729, 439],
[43.61, 64.39, 80, 110, 110, 255, 264, 852, 722, 437],
[43.58, 64.55, 80, 110, 110, 255, 264, 842, 714, 435],
[43.48, 64.46, 80, 110, 110, 254.97, 263.99, 851, 720, 437],
[43.54, 66.59, 80, 109.99, 110, 254.99, 263.99, 862, 730, 439],
[43.61, 66.46, 79.99, 110, 110, 254.99, 263.99, 864, 730, 439],
[43.87, 65.42, 80, 110, 110, 254.99, 263.99, 859, 727, 439],
[43.58, 66.77, 79.99, 110, 110, 254.99, 264, 856, 724, 438],
[43.51, 66.22, 79.99, 110, 110, 255.14, 264, 858, 720, 437],
[43.48, 65.74, 80, 110, 110, 254.99, 263.99, 858, 724, 438],
[43.58, 64.22, 79.99, 110, 110, 254.99, 263.99, 871, 734, 440],
[43.54, 63.78, 80, 110, 110, 254.99, 264, 868, 731, 440],
[43.71, 62.61, 79.99, 110, 110, 255, 263.99, 869, 734, 440],
[43.64, 62.33, 79.99, 110, 110, 255.17, 264, 875, 735, 440],
[43.64, 64.11, 79.99, 109.99, 109.99, 255, 263.99, 872, 734, 441],
[43.71, 63.64, 80, 110, 110, 254.99, 264, 876, 736, 441],
[43.64, 62.17, 80, 109.99, 110, 254.99, 264, 875, 739, 441],
[43.67, 61.77, 79.99, 109.99, 110, 255, 264, 879, 739, 442],
[43.84, 61.28, 79.99, 109.99, 110, 255.02, 264, 894, 739, 442],
[43.67, 60.44, 80, 110, 110, 254.99, 264, 874, 734, 441],
[43.58, 62.37, 79.99, 110, 110, 254.99, 263.99, 874, 736, 441],
[43.61, 62.69, 79.99, 110, 110, 255, 264, 872, 733, 441],
[43.58, 63.25, 79.99, 109.99, 110, 254.99, 263.99, 870, 732, 441],
[43.67, 64.88, 80, 110, 110, 255, 264, 871, 733, 440],
[43.64, 65.08, 80, 110, 110, 254.99, 264, 879, 741, 441],
[43.67, 65.57, 79.99, 110, 109.99, 255, 264, 870, 731, 441],
[43.54, 65.8, 80, 110, 110, 254.99, 263.99, 876, 733, 441],
[43.64, 64.26, 80, 109.99, 110, 255, 263.99, 870, 734, 440],
[43.48, 64.64, 80, 110, 110, 255, 264, 870, 733, 441],
[43.67, 64.23, 80, 109.99, 110, 254.99, 264, 864, 730, 439],
[43.58, 63.3, 80, 110, 110, 254.99, 263.99, 866, 727, 440],
[43.71, 66.38, 79.99, 109.99, 110, 255, 264, 867, 734, 440],
[43.74, 66.43, 80, 110, 110, 254.99, 264, 866, 728, 439],
[43.64, 65.79, 79.99, 110, 109.99, 254.99, 264, 874, 736, 440],
[43.8, 65.13, 80, 110, 110, 254.99, 264, 869, 731, 440],
[43.77, 65.26, 79.99, 110, 110, 255, 264, 874, 735, 441],
[44.03, 63.81, 80, 110, 110, 255, 264, 868, 738, 440],
[43.8, 64.46, 79.99, 110, 110, 255, 263.99, 870, 735, 440],
[43.84, 63.7, 79.99, 110, 110, 254.99, 264, 865, 731, 440],
[43.9, 63.24, 79.99, 110, 110, 254.99, 263.99, 864, 727, 439],
[44, 63.94, 79.99, 110, 110, 254.99, 263.99, 868, 732, 440],
[43.87, 63.27, 80, 110, 109.99, 255, 263.99, 874, 735, 441],
[43.87, 62.8, 79.99, 109.99, 110, 255, 263.99, 869, 732, 440],
[43.93, 62.57, 80, 110, 109.99, 255, 263.99, 874, 739, 441],
[43.87, 62.76, 79.99, 110, 110, 255, 263.99, 865, 733, 440],
[44.23, 63.48, 79.99, 110, 109.99, 255, 263.99, 873, 738, 441],
[44.06, 63.33, 80, 110, 110, 254.99, 263.99, 872, 735, 440],
[43.93, 63.35, 80, 110, 110, 255, 264, 873, 738, 441],
[44.06, 65.81, 80, 110, 110, 255, 264, 882, 744, 442],
[43.93, 67.2, 79.99, 109.99, 109.99, 254.99, 264, 878, 741, 442],
[43.97, 68, 80, 109.99, 110, 255, 263.99, 874, 741, 441],
[43.97, 67.36, 79.99, 109.99, 110, 254.99, 263.99, 874, 739, 441],
[43.97, 66.39, 80, 110, 110, 255, 263.99, 878, 740, 442],
[43.93, 65.76, 79.99, 110, 110, 255, 263.99, 874, 736, 441],
[44, 64.55, 80, 110, 110, 254.99, 264, 871, 734, 441],
[44.06, 63.5, 80, 110, 110, 254.99, 263.99, 871, 732, 440],
[44.06, 62.81, 80, 110, 110, 254.99, 264, 872, 739, 441],
[43.97, 62.99, 80, 110, 110, 255, 264, 877, 739, 441],
[44.06, 62.77, 79.99, 110, 110, 254.99, 263.99, 867, 733, 441],
[44.16, 63.01, 80, 110, 110, 255, 264, 872, 738, 440],
[44.06, 63.71, 80, 110, 110, 254.99, 264, 877, 737, 441],
[44.32, 63.81, 80, 110, 110, 254.99, 263.99, 865, 731, 440],
[44.16, 63.41, 80, 110, 110, 254.99, 264, 860, 726, 439],
[44.16, 63.72, 79.99, 110, 109.99, 255, 264, 865, 726, 439],
[44.32, 63.81, 79.99, 110, 109.99, 254.99, 263.99, 870, 733, 440],
[44.36, 64.81, 79.99, 110, 110, 254.99, 264, 871, 731, 440],
[44.49, 63.97, 79.99, 110, 109.99, 254.99, 264, 873, 734, 440],
[44.39, 63.57, 79.99, 110, 110, 254.99, 263.99, 866, 729, 439],
[44.55, 62.99, 80, 110, 110, 255, 264, 866, 732, 440],
[44.62, 62.2, 79.99, 110, 109.99, 255, 263.99, 870, 731, 439],
[45.08, 62.92, 79.99, 110, 110, 255, 264, 878, 738, 441],
[45.12, 62.87, 80, 110, 110, 255, 263.99, 878, 738, 441],
[45.12, 63.43, 80, 110, 110, 255, 263.99, 871, 737, 440],
[45.05, 64.55, 79.99, 109.99, 110, 255, 264, 875, 739, 440],
[45.15, 63.78, 80, 110, 110, 255, 263.99, 876, 734, 441],
[45.41, 64.97, 79.99, 110, 109.99, 255, 263.99, 865, 727, 439],
[45.38, 66.02, 79.99, 109.99, 110, 254.99, 263.99, 870, 728, 439],
[45.28, 65.59, 80, 110, 110, 255, 263.99, 875, 734, 441],
[45.18, 66.09, 79.99, 110, 110, 255, 263.99, 876, 733, 441],
[45.35, 64.83, 79.99, 110, 110, 255, 263.98, 876, 732, 440],
[45.35, 64.71, 80, 110, 110, 254.99, 263.99, 866, 731, 440],
[45.41, 64.51, 80, 109.99, 109.99, 255, 264, 859, 726, 438],
[45.08, 64.41, 80, 109.99, 109.99, 254.99, 263.99, 862, 726, 438],
[45.31, 63.45, 79.99, 110, 109.99, 255, 264, 860, 724, 438],
[45.28, 64.2, 79.99, 109.99, 110, 254.99, 264, 874, 737, 440],
[45.15, 63.46, 80, 110, 109.99, 254.99, 263.99, 870, 732, 440],
[45.22, 63.63, 80, 110, 109.99, 255, 263.99, 873, 734, 440],
[45.22, 64.69, 79.99, 109.99, 110, 255, 263.97, 868, 728, 439],
[45.22, 65.01, 79.99, 110, 110, 254.99, 263.99, 870, 730, 439],
[45.41, 64.3, 80, 110, 109.99, 255, 264, 861, 725, 438],
[45.41, 65.07, 80, 110, 110, 255, 264, 864, 727, 439],
[45.35, 66.69, 79.99, 110, 110, 255, 264, 865, 733, 439],
[45.28, 65.41, 80, 110, 110, 255, 263.99, 872, 732, 439],
[45.15, 65.2, 79.99, 110, 110, 255, 263.99, 863, 727, 437],
[45.15, 66.78, 79.99, 109.99, 110, 255, 264, 869, 737, 440],
[45.18, 66.36, 79.99, 110, 110, 255, 263.78, 885, 731, 439],
[45.12, 66.97, 79.99, 110, 110, 254.99, 264, 873, 739, 440],
[45.41, 65.11, 79.99, 110, 110, 255, 264, 869, 732, 440],
[44.98, 66.3, 79.99, 109.99, 110, 254.99, 263.99, 863, 724, 438],
[45.31, 66.13, 80, 109.99, 110, 255, 263.93, 870, 721, 436],
[45.22, 65.4, 80, 110, 109.99, 255, 263.99, 857, 722, 437],
[45.12, 63.73, 80, 110, 110, 254.99, 264, 854, 719, 436],
[45.12, 63.14, 80, 110, 110, 254.99, 263.99, 850, 712, 435],
[45.08, 63.02, 79.99, 110, 110, 254.99, 264.22, 863, 720, 436],
[45.15, 62.91, 80, 110, 109.99, 255, 263.99, 846, 713, 434],
[45.31, 63.24, 80, 110, 110, 254.99, 263.99, 857, 723, 437],
[45.35, 67.4, 80, 110, 110, 254.99, 262.35, 858, 719, 436],
[45.58, 68.7, 80, 110, 110, 255, 264, 857, 725, 437],
[45.62, 69.2, 80, 109.99, 110, 255, 263.99, 868, 733, 439],
[45.58, 67.64, 79.99, 110, 110, 254.99, 264, 874, 734, 439],
[45.41, 66.16, 79.99, 110, 110, 255, 264, 868, 732, 439],
[45.62, 65.71, 80, 110, 110, 254.99, 264, 877, 741, 440],
[45.65, 66.4, 79.99, 110, 109.99, 255, 264, 879, 741, 440],
[45.72, 67.07, 80, 110, 109.99, 254.99, 263.99, 880, 742, 441],
[45.72, 67.26, 80, 109.99, 110, 254.99, 263.99, 874, 742, 440],
[45.68, 67.43, 79.99, 110, 110, 255, 263.99, 864, 730, 438],
[45.85, 68.9, 80, 110, 110, 255, 263.99, 874, 735, 440],
[45.68, 69.89, 80, 110, 109.99, 254.99, 263.99, 876, 740, 441],
[45.62, 71.54, 80, 110, 110, 254.99, 263.99, 880, 740, 441],
[45.41, 70.54, 79.99, 110, 110, 255, 264, 862, 732, 438],
[45.55, 69.64, 80, 109.99, 110, 255, 263.99, 851, 718, 435],
[45.41, 69.23, 79.99, 110, 110, 255, 264, 857, 729, 437],
[45.31, 69.12, 80, 109.99, 109.99, 255, 263.99, 865, 729, 438],
[45.38, 68.22, 80, 110, 109.99, 255, 264, 859, 729, 436],
[45.62, 67.09, 80, 110, 110, 254.99, 264, 854, 723, 436],
[45.45, 65.75, 80, 110, 110, 255, 263.99, 847, 718, 434],
[45.31, 63.86, 80, 110, 110, 255, 263.99, 852, 722, 435],
[45.58, 64.6, 80, 110, 110, 254.99, 264, 846, 717, 434],
[45.52, 66.5, 80, 110, 109.99, 255, 263.99, 857, 724, 436],
[45.92, 66.82, 80, 110, 110, 254.99, 264, 852, 718, 435],
[45.72, 67.06, 79.99, 110, 110, 255, 264, 860, 723, 437],
[45.75, 65.87, 79.99, 110, 110, 254.99, 264, 854, 724, 435],
[45.85, 65.81, 79.99, 110, 110, 255, 264, 854, 723, 436],
[45.82, 65.26, 79.99, 109.99, 110, 255, 264, 852, 719, 434],
[45.88, 64.76, 80, 109.99, 109.99, 255, 263.99, 848, 713, 433],
[46.09, 64.66, 79.99, 109.99, 110, 255, 263.99, 849, 714, 434],
[45.99, 64.91, 79.99, 109.99, 109.99, 254.99, 264, 850, 717, 435],
[45.82, 66.42, 80, 110, 110, 254.99, 264, 878, 741, 440],
[45.88, 66.16, 79.99, 110, 110, 254.99, 264, 889, 752, 441],
[45.92, 63.66, 79.99, 110, 110, 255, 264, 886, 745, 441],
[46.12, 63.25, 80, 109.99, 110, 255, 264, 891, 748, 441],
[45.88, 63.47, 79.99, 110, 110, 255, 263.99, 889, 744, 441],
[45.92, 63.69, 80, 110, 109.99, 254.99, 263.99, 888, 743, 441],
[46.15, 64.03, 79.99, 110, 110, 254.99, 263.99, 888, 745, 441],
[46.12, 63.52, 79.99, 110, 109.99, 255, 263.99, 883, 741, 440],
[46.43, 63.34, 80, 110, 110, 254.99, 264, 886, 747, 441],
[46.09, 63.79, 79.99, 109.99, 110, 254.99, 264, 881, 741, 441],
[46.19, 63.99, 80, 110, 109.99, 254.99, 264, 876, 739, 440],
[46.19, 63.45, 79.99, 109.99, 109.99, 254.99, 264, 874, 736, 440],
[46.19, 63.41, 80, 110, 110, 254.99, 263.99, 866, 728, 437],
[46.19, 62.29, 80, 110, 109.99, 255, 263.99, 863, 725, 437],
[46.36, 66.61, 79.99, 109.99, 110, 254.99, 263.99, 872, 732, 439],
[46.26, 66.39, 79.99, 110, 110, 255, 263.99, 871, 734, 439],
[46.36, 65.26, 80, 110, 110, 255, 263.99, 866, 726, 438],
[46.19, 65.14, 80, 110, 109.99, 255, 263.99, 862, 724, 437],
[46.43, 65.37, 79.99, 110, 110, 254.99, 263.99, 865, 721, 437],
[46.19, 64.6, 79.99, 110, 110, 254.99, 264, 863, 721, 436],
[46.22, 63.31, 79.99, 110, 110, 254.99, 264, 868, 727, 438],
[46.22, 65.1, 79.99, 110, 109.99, 255, 264, 851, 715, 434],
[46.19, 65.44, 79.99, 110, 109.99, 255.01, 263.99, 882, 714, 435],
[46.46, 64.65, 79.99, 109.99, 110, 254.99, 264, 864, 728, 438],
[46.32, 63.62, 79.99, 109.99, 110, 254.99, 264, 870, 728, 438],
[46.29, 63.51, 80, 110, 109.99, 255, 263.99, 877, 732, 440],
[46.32, 63, 79.99, 109.99, 109.99, 255, 264, 871, 734, 439],
[46.36, 62.97, 80, 110, 110, 254.99, 263.98, 869, 731, 439],
[46.36, 63.11, 80, 110, 110, 255, 264, 859, 726, 437],
[46.29, 63.49, 79.99, 110, 110, 255, 264, 858, 724, 436],
[46.22, 62.49, 80, 110, 110, 255, 263.99, 865, 728, 437],
[46.26, 62.62, 80, 110, 110, 255, 264, 860, 721, 436],
[46.29, 62.74, 80, 109.99, 109.99, 254.99, 263.97, 876, 736, 440],
[46.32, 63.66, 79.99, 109.99, 109.99, 254.99, 264, 870, 732, 439],
[46.36, 64.27, 80, 110, 109.99, 255, 263.99, 860, 719, 436],
[46.22, 63.06, 80, 110, 110, 255, 264, 864, 724, 438],
[46.39, 62.32, 79.99, 110, 109.99, 255, 263.99, 876, 734, 440],
[46.39, 61.11, 79.99, 109.99, 110, 254.98, 263.99, 874, 731, 440],
[46.53, 61.05, 80, 110, 110, 254.99, 263.99, 868, 730, 438],
[46.7, 63.42, 80, 110, 110, 255, 263.99, 861, 723, 438],
[46.46, 62.46, 79.99, 110, 110, 255, 263.99, 859, 720, 436],
[46.7, 61.52, 80, 110, 109.99, 254.99, 264, 850, 713, 435],
[46.73, 62.94, 80, 110, 110, 254.99, 263.99, 854, 717, 435],
[46.53, 64.14, 79.99, 109.99, 110, 255.04, 263.94, 864, 722, 438],
[46.73, 64.46, 80, 110, 109.99, 254.99, 264, 871, 727, 439],
[46.8, 64.21, 79.99, 110, 110, 254.99, 264, 861, 724, 437],
[46.77, 64.72, 79.99, 110, 110, 254.99, 264, 857, 722, 436],
[47.04, 65.43, 80, 110, 110, 254.99, 263.99, 860, 725, 437],
[47.07, 64.18, 79.99, 110, 110, 254.99, 264, 862, 725, 437],
[46.94, 63.27, 80, 110, 110, 254.99, 264, 865, 724, 437],
[46.94, 63.13, 79.99, 110, 110, 254.99, 264, 860, 723, 437],
[47.07, 64.18, 79.99, 109.99, 110, 255, 263.99, 853, 718, 436],
[47.01, 65.08, 79.99, 110, 110, 254.99, 264, 851, 720, 434],
[47.01, 64.86, 80, 110, 110, 254.99, 264, 862, 728, 437],
[46.94, 65.99, 80, 109.99, 109.99, 254.99, 264, 858, 728, 437],
[47.18, 66.27, 80, 109.99, 109.99, 254.99, 264, 859, 724, 436],
[47.01, 66.65, 79.99, 110, 109.99, 253.49, 286.77, 861, 723, 437],
[47.01, 66.89, 80, 110, 109.99, 255, 264, 867, 732, 438],
[47.14, 66.77, 80, 110, 110, 254.99, 264, 867, 727, 438],
[47.07, 66.01, 79.99, 110, 110, 255, 264, 872, 731, 439],
[47.04, 64.48, 80, 110, 109.99, 255, 264, 876, 734, 439],
[46.9, 64.53, 79.99, 110, 110, 255, 264, 857, 722, 436],
[47.11, 65.28, 80, 110, 110, 254.99, 264, 861, 723, 437],
[47.11, 65.22, 80, 109.99, 110, 254.99, 263.99, 859, 722, 437],
[46.97, 66.03, 79.99, 110, 110, 255, 264, 861, 722, 436],
[47.01, 63.57, 79.99, 110, 109.99, 255, 263.99, 866, 726, 438],
[47.07, 62.78, 80, 110, 110, 255, 263.99, 866, 726, 438],
[47.21, 63.34, 80, 110, 110, 255, 264, 867, 729, 439],
[47.01, 63.14, 79.99, 110, 110, 255, 264, 874, 731, 440],
[47.07, 62.31, 80, 109.99, 110, 254.99, 264, 879, 742, 441],
[47.32, 63, 79.99, 110, 110, 255, 264, 875, 735, 440],
[47.01, 62.78, 79.99, 110, 110, 255, 263.99, 869, 736, 439],
[47.42, 63.76, 79.99, 110, 110, 254.99, 264, 859, 723, 438],
[47.11, 63.23, 79.99, 109.99, 110, 254.99, 264, 862, 726, 437],
[47.11, 62.8, 80, 110, 110, 255, 264, 864, 728, 438],
[47.35, 63.88, 80, 110, 110, 254.99, 263.99, 855, 720, 436],
[48.19, 61.69, 79.99, 110, 110, 255, 264, 888, 742, 442],
[47.94, 62.04, 80, 109.99, 110, 254.98, 264, 886, 741, 442],
[48.19, 62.85, 79.99, 110, 109.99, 254.99, 264, 880, 735, 441],
[47.87, 61.34, 80, 110, 110, 255, 264, 881, 737, 441],
[47.91, 62, 80, 110, 110, 254.99, 263.99, 884, 737, 442],
[47.94, 61.56, 79.99, 110, 110, 254.98, 263.99, 882, 736, 441],
[47.91, 61.82, 80, 110, 110, 254.99, 263.99, 878, 732, 440],
[47.94, 63.03, 79.99, 110, 110, 254.99, 263.99, 864, 721, 438],
[47.91, 64.36, 80, 109.99, 110, 255, 264, 878, 732, 440],
[47.91, 64.31, 79.99, 110, 110, 254.99, 264, 872, 732, 440],
[48.01, 64.57, 80, 110, 110, 254.97, 263.99, 881, 736, 441],
[48.01, 66.39, 79.99, 109.99, 110, 255, 263.99, 878, 736, 440],
[47.91, 67.53, 79.99, 109.99, 110, 255, 263.99, 877, 735, 440],
[47.98, 66.19, 79.99, 109.99, 110, 254.99, 263.99, 868, 733, 439],
[47.84, 67.65, 79.99, 110, 110, 255, 264, 869, 732, 439],
[47.87, 66.7, 79.99, 110, 110, 254.99, 264, 875, 736, 440],
[47.87, 65.75, 80, 110, 110, 254.99, 264, 873, 731, 440],
[47.98, 64.35, 80, 110, 109.99, 254.99, 263.99, 869, 730, 439],
[48.05, 63.69, 80, 110, 109.99, 254.99, 263.99, 878, 736, 440],
[47.77, 64.95, 80, 110, 110, 255, 263.99, 875, 735, 440],
[47.87, 64.98, 80, 110, 110, 254.97, 264, 879, 736, 440],
[47.87, 65.02, 80, 110, 109.99, 254.99, 263.99, 874, 737, 440],
[47.94, 65.68, 79.99, 110, 110, 254.99, 264, 865, 724, 438],
[47.87, 65.16, 79.99, 109.99, 110, 255, 264, 854, 719, 436],
[47.87, 64.57, 80, 109.99, 110, 254.99, 263.99, 873, 730, 439],
[47.91, 65.91, 80, 110, 110, 254.99, 264, 872, 734, 439],
[47.77, 65.56, 80, 110, 110, 255, 263.99, 881, 745, 441],
[47.59, 66.95, 79.99, 110, 110, 254.99, 263.99, 874, 734, 440],
[47.59, 65.23, 80, 109.99, 109.99, 255, 263.99, 887, 745, 441],
[47.77, 64.38, 80, 110, 110, 255, 264, 890, 747, 441],
[47.49, 64.47, 79.99, 110, 109.99, 255, 263.99, 883, 740, 441],
[47.52, 63.33, 79.99, 109.99, 110, 255.22, 264, 902, 745, 441],
[47.38, 63.31, 79.99, 110, 110, 255, 264, 880, 736, 441],
[47.32, 63.62, 79.99, 109.99, 110, 255, 264, 884, 742, 442],
[47.42, 62.21, 80, 109.99, 110, 254.99, 264, 885, 741, 442],
[47.07, 61.94, 80, 109.99, 110, 255, 264, 879, 738, 441],
[46.94, 61.01, 80, 110, 110, 255, 263.99, 882, 738, 441],
[46.83, 63.41, 80, 110, 109.99, 255, 264, 882, 739, 441],
[46.66, 63.98, 79.99, 110, 110, 254.99, 263.99, 880, 740, 441],
[46.6, 63.43, 80, 110, 110, 254.99, 264, 878, 738, 440],
[46.46, 63.98, 80, 110, 110, 254.99, 264, 871, 734, 440],
[46.6, 65.55, 80, 109.99, 110, 255, 263.99, 879, 741, 440],
[46.87, 66.73, 79.99, 109.99, 110, 254.99, 263.99, 878, 735, 440],
[46.8, 66.42, 80, 109.99, 109.99, 255, 264, 871, 733, 439],
[46.73, 67.54, 80, 110, 110, 255, 263.98, 876, 735, 440],
[46.56, 67.34, 79.99, 110, 110, 254.99, 263.99, 883, 746, 441],
[46.66, 64.95, 80, 110, 110, 255, 264.01, 875, 738, 440],
[46.6, 65.68, 79.99, 110, 109.99, 255, 263.99, 861, 722, 437],
[46.6, 65.9, 79.99, 110, 110, 255, 263.99, 877, 738, 440],
[46.6, 64.95, 79.99, 109.99, 110, 255, 264, 880, 738, 442],
[46.49, 63.85, 79.99, 110, 109.99, 254.99, 263.99, 880, 740, 441],
[46.63, 63.76, 80, 109.99, 110, 255, 263.99, 881, 739, 441],
[46.32, 63.43, 80, 110, 110, 255, 264, 880, 738, 441],
[46.39, 63.46, 80, 110, 110, 255, 264, 883, 743, 441],
[46.46, 62.45, 79.99, 109.99, 110, 254.99, 263.99, 886, 742, 442],
[46.32, 61.64, 80, 109.99, 109.99, 255, 263.99, 880, 737, 441],
[46.26, 61.62, 79.99, 110, 109.99, 255, 264, 877, 732, 440],
[46.49, 60.9, 80, 110, 110, 255, 264.01, 876, 734, 441],
[46.22, 61.33, 79.99, 110, 110, 254.99, 264, 883, 740, 441],
[46.26, 61.13, 80, 109.99, 109.99, 254.99, 263.99, 884, 742, 441],
[46.46, 62.2, 80, 110, 110, 255, 263.99, 879, 735, 440],
[46.46, 62.54, 80, 110, 110, 255, 263.99, 883, 738, 441],
[46.22, 62.41, 80, 110, 110, 254.99, 263.99, 881, 739, 441],
[46.36, 62.69, 79.99, 110, 109.99, 254.99, 263.99, 882, 742, 440],
[46.26, 64.86, 80, 110, 109.99, 254.99, 263.99, 889, 750, 442],
[46.36, 64.84, 80, 109.99, 110, 255, 264, 881, 739, 441],
[46.19, 65.95, 80, 109.99, 110, 255, 263.99, 878, 741, 441],
[46.26, 67.16, 79.99, 110, 110, 254.99, 264, 884, 745, 441],
[46.22, 67.39, 80, 110, 110, 255.01, 264, 885, 745, 441],
[46.22, 66.7, 79.99, 109.99, 110, 255, 263.99, 883, 740, 441],
[46.19, 66.6, 79.99, 109.99, 110, 255, 263.99, 884, 742, 441],
[46.22, 66.62, 79.99, 109.99, 110, 255, 264.01, 877, 734, 440],
[46.22, 66.2, 79.99, 110, 110, 254.99, 263.99, 882, 739, 441],
[46.29, 65.08, 80, 110, 110, 254.99, 264, 877, 734, 441],
[46.22, 64.66, 80, 110, 110, 255, 263.99, 876, 734, 439],
[46.22, 64.13, 80, 110, 110, 254.99, 264, 872, 732, 439],
[46.46, 64.22, 80, 110, 110, 255, 263.99, 872, 738, 440],
[46.22, 65.59, 80, 110, 109.99, 254.99, 264, 881, 739, 441],
[46.32, 65.99, 80, 110, 110, 255, 264, 877, 739, 440],
[46.6, 65.73, 79.99, 109.99, 109.99, 254.99, 263.99, 880, 738, 441],
[46.46, 65.65, 79.99, 110, 110, 254.99, 264, 876, 739, 441],
[46.49, 64.38, 79.99, 109.99, 110, 254.99, 264, 872, 734, 440],
[46.32, 64.34, 80, 110, 110, 255, 264, 879, 740, 441],
[46.29, 64.86, 79.99, 110, 110, 254.99, 263.99, 883, 739, 441],
[46.43, 65.03, 80, 110, 109.99, 254.99, 263.99, 877, 737, 440],
[46.39, 65.81, 79.99, 109.99, 110, 255, 264, 879, 738, 440],
[46.46, 65.76, 79.99, 109.99, 110, 255, 264, 880, 739, 440],
[46.46, 65.75, 79.99, 110, 110, 255, 264, 879, 740, 440],
[46.49, 66.94, 79.99, 110, 109.99, 254.99, 264, 882, 739, 440],
[46.53, 67.48, 80, 110, 110, 254.99, 263.99, 881, 740, 440],
[46.39, 66.49, 79.99, 110, 110, 254.99, 264, 885, 745, 441],
[46.53, 65.5, 79.99, 110, 110, 255, 263.99, 877, 732, 440],
[46.49, 65.03, 79.99, 110, 110, 254.99, 264, 860, 721, 436],
[46.46, 64.4, 79.99, 110, 110, 255, 264, 869, 732, 439],
[46.46, 64.15, 80, 110, 110, 254.99, 264, 878, 739, 441],
[46.46, 63.76, 79.99, 109.99, 110, 254.99, 264, 880, 739, 440],
[46.43, 63.69, 79.99, 109.99, 109.99, 255, 263.99, 882, 738, 441],
[46.39, 62.84, 80, 110, 110, 255, 264, 871, 733, 439],
[46.36, 63.44, 79.99, 110, 110, 255, 263.99, 877, 734, 440],
[46.36, 63.47, 80, 110, 110, 255, 264, 874, 732, 439],
[46.15, 64.23, 79.99, 110, 110, 255, 263.99, 877, 734, 440],
[46.19, 64.39, 79.99, 110, 110, 254.99, 263.99, 879, 736, 440],
[46.19, 65.17, 80, 110, 109.99, 255, 264, 878, 733, 440],
[46.19, 66.35, 79.99, 109.99, 110, 254.99, 263.99, 883, 736, 440],
[46.12, 65.72, 80, 110, 110, 254.99, 264, 873, 734, 439],
[46.09, 67.05, 79.99, 110, 110, 255, 263.99, 871, 735, 440],
[46.09, 66.08, 79.99, 110, 110, 255, 263.99, 880, 739, 441],
[45.92, 65.21, 79.99, 109.99, 110, 255, 264, 881, 741, 441],
[45.92, 63.04, 80, 110, 109.99, 255, 264, 877, 739, 440],
[45.75, 63.45, 79.99, 109.99, 109.99, 255, 264, 872, 732, 439],
[45.82, 64.77, 79.99, 110, 109.99, 255, 264, 876, 737, 440],
[45.65, 63.77, 79.99, 110, 110, 255, 263.99, 878, 738, 440],
[45.48, 64.95, 80, 110, 110, 254.99, 263.99, 885, 740, 441],
[45.25, 64.78, 80, 110, 110, 254.99, 264, 874, 739, 441],
[45.25, 67.02, 79.99, 110, 110, 254.99, 263.99, 870, 730, 439],
[45.28, 67.8, 79.99, 110, 109.99, 255.01, 263.67, 878, 733, 439],
[45.22, 67.14, 79.99, 109.99, 110, 254.99, 263.99, 870, 734, 440],
[45.22, 66.97, 80, 110, 110, 254.99, 263.99, 878, 741, 441],
[45.15, 66.94, 79.99, 110, 110, 254.99, 263.99, 878, 740, 441],
[45.38, 65.12, 80, 110, 110, 254.99, 264, 865, 731, 438],
[45.28, 64.46, 80, 110, 109.99, 254.99, 263.52, 856, 718, 436],
[45.41, 65.32, 80, 109.99, 110, 254.99, 263.99, 855, 722, 436],
[45.35, 65.41, 79.99, 110, 110, 255, 263.99, 870, 730, 439],
[45.38, 65.12, 80, 110, 110, 254.99, 263.99, 871, 742, 440],
[45.41, 67.18, 79.99, 110, 110, 255, 263.99, 879, 739, 440],
[45.52, 66.5, 79.99, 110, 110, 254.99, 263.76, 877, 741, 440],
[45.48, 67.74, 80, 110, 110, 255, 263.99, 873, 736, 440],
[45.45, 66.44, 79.99, 110, 110, 255, 263.99, 872, 738, 440],
[45.62, 65.93, 79.99, 110, 110, 254.99, 263.99, 877, 742, 441],
[45.65, 65.57, 79.99, 110, 110, 254.99, 263.99, 872, 738, 440],
[45.68, 65.4, 80, 109.99, 110, 255, 263.29, 890, 738, 440],
[45.75, 64.43, 80, 110, 110, 255, 263.99, 875, 735, 439],
[45.65, 65.56, 79.99, 110, 110, 254.99, 263.99, 874, 736, 440],
[45.62, 65.26, 79.99, 110, 110, 255, 263.99, 872, 736, 440],
[45.48, 65.25, 80, 109.99, 109.99, 255, 275.7, 886, 740, 440],
[45.45, 66.23, 79.99, 110, 110, 255, 264, 878, 742, 441],
[45.38, 66.44, 79.99, 110, 109.99, 255, 263.99, 886, 748, 441],
[45.35, 66, 80, 109.99, 110, 254.99, 263.99, 877, 740, 441],
[45.25, 66.76, 80, 110, 110, 254.99, 263.99, 883, 747, 441],
[45.18, 66.57, 80, 110, 110, 255, 264, 874, 743, 442],
[45.05, 65.78, 79.99, 109.99, 110, 254.99, 263.99, 878, 743, 441],
[44.98, 65.09, 79.99, 109.99, 110, 255, 263.99, 873, 738, 442],
[44.82, 63.62, 79.99, 110, 109.99, 254.99, 264, 873, 738, 441],
[44.78, 62.96, 80, 110, 110, 254.99, 263.99, 871, 735, 441],
[44.82, 64.66, 79.99, 110, 109.99, 255, 263.99, 859, 729, 439],
[44.92, 65.46, 79.99, 109.99, 110, 254.99, 263.99, 863, 732, 440],
[44.78, 66.22, 80, 110, 109.99, 254.99, 264, 857, 729, 438],
[44.88, 65.63, 80, 109.99, 110, 254.99, 263.99, 865, 737, 440],
[44.88, 66.48, 80, 109.99, 110, 255, 264, 861, 728, 439],
[44.82, 67.64, 80, 110, 110, 254.99, 264, 868, 736, 441],
[44.85, 66.92, 80, 110, 110, 255, 264, 866, 738, 440],
[44.88, 67.87, 80, 110, 110, 254.99, 264, 864, 736, 440],
[44.88, 66.53, 79.99, 110, 110, 255, 264, 859, 730, 439],
[44.88, 65.8, 80, 110, 110, 254.99, 264, 846, 718, 436],
[44.85, 65.14, 80, 109.99, 110, 255, 263.99, 848, 718, 436],
[44.92, 64.46, 80, 109.99, 110, 254.99, 264, 869, 738, 441],
[44.82, 63.66, 80, 110, 110, 255, 264, 865, 738, 440],
[44.78, 63.53, 80, 110, 110, 255, 264, 860, 729, 439],
[44.85, 64.41, 80, 109.99, 109.99, 255, 264, 846, 717, 436],
[44.95, 63.84, 80, 110, 110, 255, 263.99, 844, 715, 435],
[44.88, 63.37, 80, 109.99, 110, 254.99, 263.98, 852, 721, 438],
[44.95, 63.52, 80, 110, 110, 255, 263.99, 853, 723, 438],
[45.12, 65.45, 79.99, 110, 110, 254.99, 263.99, 864, 728, 440],
[44.85, 64.98, 80, 110, 110, 254.99, 263.99, 872, 737, 441],
[44.72, 66.21, 79.99, 110, 109.99, 254.99, 263.99, 873, 740, 441],
[44.59, 67.74, 80, 110, 110, 255, 264, 871, 742, 441],
[44.82, 67.83, 79.99, 109.99, 109.99, 255, 264, 866, 737, 440],
[44.39, 66.17, 79.99, 110, 110, 254.99, 264, 864, 737, 441],
[44.32, 66.89, 79.99, 110, 110, 254.99, 264, 879, 745, 442],
[44.23, 66.12, 79.99, 110, 109.99, 255, 264, 871, 741, 441],
[44.19, 65.4, 80, 110, 109.99, 255, 263.99, 871, 740, 441],
[44.13, 65.8, 79.99, 110, 109.99, 255, 263.99, 876, 744, 442],
[44.06, 67.13, 80, 110, 110, 254.99, 263.99, 873, 742, 441],
[44, 67.06, 80, 110, 110, 254.99, 263.99, 868, 741, 441],
[43.74, 65.89, 80, 110, 110, 255, 264, 880, 745, 442],
[43.74, 64.88, 79.99, 110, 110, 254.99, 264, 871, 738, 441],
[43.74, 64.09, 80, 110, 110, 254.99, 263.99, 869, 739, 441],
[43.58, 65.28, 79.99, 110, 110, 255, 264, 857, 731, 439],
[43.61, 66.55, 79.99, 109.99, 109.99, 254.99, 264, 855, 729, 438],
[43.42, 67.64, 80, 110, 110, 254.99, 264, 861, 730, 440],
[43.13, 67.21, 80, 110, 110, 254.99, 264, 855, 725, 438],
[43.61, 65.91, 80, 110, 110, 254.99, 263.99, 863, 735, 440],
[43.03, 66.66, 80, 110, 110, 255, 263.99, 871, 740, 441],
[43.09, 65.87, 79.99, 109.99, 110, 254.99, 263.99, 867, 738, 441],
[43.06, 65.04, 80, 109.99, 110, 254.99, 263.99, 869, 740, 441],
[42.49, 64.02, 80, 110, 110, 255, 264, 876, 746, 442],
[42.23, 65.53, 80, 110, 109.99, 254.99, 264, 863, 735, 440],
[42.2, 66.35, 79.99, 109.99, 110, 255, 264.01, 870, 737, 441],
[42.17, 67.45, 80, 110, 110, 255, 264, 858, 725, 439],
[42.27, 66.96, 79.99, 109.99, 110, 254.99, 264, 853, 725, 439],
[42.14, 65.82, 80, 110, 110, 254.99, 263.87, 864, 738, 439],
[42.14, 66.53, 79.99, 110, 110, 255, 264, 868, 738, 442],
[42.05, 66.17, 80, 110, 109.99, 254.99, 263.99, 861, 739, 440],
[42.2, 65.85, 79.99, 110, 110, 254.99, 263.99, 859, 731, 439],
[42.01, 65.38, 80, 110, 110, 255, 264, 859, 733, 440],
[42.08, 67.19, 79.99, 110, 110, 255, 264.04, 864, 734, 441],
[42.11, 66.82, 80, 110, 110, 255, 264, 861, 732, 439],
[42.01, 66.6, 80, 110, 110, 254.99, 263.99, 849, 724, 438],
[42.14, 66.85, 80, 109.99, 110, 254.99, 263.99, 856, 734, 439],
[42.2, 66.65, 80, 110, 110, 255.06, 263.67, 861, 733, 440],
[42.01, 65.87, 80, 110, 109.99, 254.99, 263.99, 863, 735, 441],
[41.98, 64.75, 80, 109.99, 110, 255, 264, 871, 738, 441],
[42.2, 67.56, 80, 109.99, 110, 254.99, 263.99, 871, 741, 442],
[41.95, 66.5, 80, 110, 110, 254.99, 261.74, 862, 736, 440],
[42.01, 66.5, 79.99, 110, 110, 255, 264, 853, 724, 438],
[42.01, 65.89, 80, 110, 110, 255, 264, 868, 739, 441],
[42.23, 66.24, 79.99, 110, 110, 255, 264, 871, 738, 441],
[41.79, 66.62, 79.99, 110, 110, 245.22, 261.47, 872, 738, 442],
[41.83, 66.02, 80, 110, 110, 255, 263.99, 865, 737, 441],
[41.79, 65.93, 79.99, 109.99, 110, 255, 264, 868, 738, 441],
[41.98, 65.52, 79.99, 110, 110, 254.99, 264, 869, 739, 442],
[41.98, 65.38, 79.99, 110, 110, 255, 264, 865, 737, 441],
[41.7, 65.45, 79.99, 110, 110, 259.17, 305.67, 870, 744, 442],
[41.79, 65.83, 80, 110, 110, 254.99, 263.99, 863, 737, 441],
[41.83, 67.11, 80, 110, 110, 255, 263.99, 865, 740, 441],
[41.73, 67.33, 80, 110, 110, 255, 264, 855, 729, 439],
[41.76, 66.84, 79.99, 110, 109.99, 255, 264, 852, 721, 438],
[41.76, 66.83, 80, 110, 110, 255, 263.99, 861, 734, 440],
[41.76, 65.87, 79.99, 110, 109.99, 255, 264, 864, 736, 440],
[41.76, 65, 79.99, 109.99, 110, 254.99, 263.99, 869, 738, 441],
[41.7, 64.55, 80, 110, 109.99, 255, 264, 863, 732, 440],
[41.73, 63.53, 80, 109.99, 110, 255, 263.99, 853, 731, 439],
[41.54, 66.19, 80, 110, 110, 255, 264, 847, 723, 439],
[41.48, 66.76, 79.99, 110, 109.99, 254.99, 264, 858, 730, 440],
[41.42, 67.16, 79.99, 110, 110, 254.99, 264, 874, 742, 442],
[41.36, 66.7, 80, 109.99, 110, 254.99, 264, 875, 743, 443],
[41.36, 66.17, 80, 110, 110, 255, 264, 876, 743, 443],
[41.26, 66.08, 79.99, 110, 110, 255, 263.99, 872, 749, 442],
[41.08, 64.64, 79.99, 110, 110, 255, 264.01, 872, 745, 441],
[41.11, 66.45, 79.99, 110, 110, 254.99, 263.99, 871, 742, 442],
[41.17, 66.7, 80, 110, 110, 255, 264, 867, 738, 442],
[41.14, 67.45, 79.99, 110, 109.99, 255, 263.99, 876, 746, 443],
[40.83, 66.66, 80, 110, 110, 255, 264, 874, 742, 442],
[40.8, 65.2, 80, 110, 110, 255, 264, 868, 739, 442],
[40.68, 64.69, 80, 109.99, 110, 254.99, 263.99, 863, 736, 442],
[40.58, 64.94, 79.99, 110, 110, 255, 263.99, 859, 731, 441],
[40.49, 64.65, 79.99, 110, 110, 254.99, 263.98, 847, 723, 439],
[40.55, 64.5, 79.99, 110, 110, 255, 263.99, 854, 723, 440],
[40.43, 65.1, 80, 110, 110, 254.99, 263.99, 863, 737, 442],
[40.25, 67.11, 80, 110, 110, 255, 263.99, 856, 731, 441],
[40.22, 67.67, 80, 110, 110, 254.99, 263.99, 853, 728, 440],
[40.03, 67.68, 80, 110, 110, 255, 263.99, 863, 737, 443],
[40.03, 65.54, 80, 110, 110, 254.99, 264, 858, 725, 441],
[39.88, 65.15, 79.99, 110, 109.99, 255, 264, 872, 741, 444],
[39.88, 66.68, 80, 110, 109.99, 255, 263.99, 866, 738, 443],
[39.79, 66.85, 79.99, 110, 110, 254.99, 264, 867, 738, 444],
[39.85, 65.34, 79.99, 110, 110, 254.99, 263.99, 869, 741, 444],
[39.94, 65.07, 80, 110, 110, 254.99, 263.99, 872, 744, 444],
[39.76, 65.83, 80, 109.99, 110, 255, 264, 872, 745, 444],
[39.88, 65.52, 79.99, 109.99, 110, 255, 264, 874, 748, 444],
[39.64, 67.11, 80, 110, 110, 255, 264, 865, 741, 444],
[39.64, 68.49, 80, 110, 110, 254.99, 264, 880, 754, 445],
[39.52, 67.98, 79.99, 110, 110, 254.99, 264, 875, 746, 444],
[39.06, 66.55, 80, 110, 110, 254.99, 264, 873, 742, 445],
[39.55, 66.71, 79.99, 110, 110, 254.99, 264, 871, 746, 445],
[39.36, 65.33, 80, 110, 110, 255, 263.99, 873, 742, 445],
[39.27, 68.07, 79.99, 110, 110, 255, 264, 866, 739, 444],
[39.33, 68.06, 79.99, 110, 110, 255, 264, 870, 745, 445],
[39.55, 65.87, 80, 110, 110, 254.98, 264, 873, 745, 445],
[39.36, 68.47, 79.99, 110, 110, 255, 264, 864, 742, 444],
[39.55, 68.07, 80, 109.99, 110, 254.99, 264, 858, 734, 443],
[39.52, 66.17, 80, 110, 110, 255, 264, 851, 730, 441],
[39.3, 66.51, 80, 109.99, 110, 255, 263.99, 864, 734, 443],
[39.52, 65.29, 79.99, 110, 110, 254.97, 264, 868, 736, 443],
[39.64, 64.8, 79.99, 110, 110, 254.99, 263.99, 876, 746, 444],
[39.7, 64.13, 80, 109.99, 110, 254.99, 264, 874, 746, 444],
[39.79, 64.06, 79.99, 110, 110, 254.99, 263.99, 870, 739, 443],
[39.79, 65.16, 80, 109.99, 109.99, 254.99, 264, 860, 739, 443],
[39.79, 66.39, 79.99, 110, 110, 254.96, 264, 873, 741, 444],
[39.88, 66.75, 80, 110, 109.99, 254.99, 263.99, 868, 745, 444],
[40.03, 65.85, 79.99, 110, 109.99, 254.99, 263.99, 869, 743, 444],
[39.91, 67.66, 79.99, 110, 110, 254.99, 264, 864, 737, 444],
[39.76, 68.74, 80, 110, 110, 255, 264, 876, 744, 445],
[39.91, 67.23, 79.99, 110, 110, 255.01, 264, 873, 738, 445],
[39.7, 66.96, 79.99, 110, 109.99, 254.99, 264, 864, 737, 444],
[39.88, 68.47, 80, 110, 110, 254.99, 264, 853, 731, 441],
[39.7, 67.68, 80, 109.99, 110, 255, 263.99, 852, 727, 441],
[39.64, 66.09, 80, 110, 110, 255.08, 263.99, 862, 735, 442],
[39.7, 66.38, 79.99, 109.99, 110, 255, 264, 852, 731, 441],
[39.91, 65.32, 80, 109.99, 110, 255, 263.99, 856, 732, 441],
[39.67, 64.12, 80, 110, 109.99, 254.99, 263.99, 854, 732, 442],
[39.91, 63.93, 79.99, 110, 109.99, 255, 264, 873, 747, 444],
[39.55, 64.69, 80, 109.99, 110, 254.96, 264.01, 872, 742, 444],
[39.58, 64.93, 80, 110, 109.99, 255, 264, 872, 741, 445],
[39.58, 65.26, 80, 110, 110, 254.99, 263.99, 863, 735, 444],
[39.67, 65.56, 80, 110, 110, 254.99, 263.99, 864, 739, 444],
[39.49, 66.15, 79.99, 109.99, 110, 255, 263.99, 859, 735, 443],
[39.39, 68.27, 80, 110, 110, 254.95, 264.01, 892, 747, 445],
[39.39, 67.31, 79.99, 110, 110, 254.99, 264, 876, 752, 446],
[39.33, 67.83, 79.99, 110, 109.99, 255, 263.99, 875, 751, 446],
[39.46, 67.45, 80, 110, 109.99, 255, 263.99, 878, 751, 446],
[38.91, 68.03, 79.99, 110, 110, 254.99, 263.99, 871, 746, 445],
[39.12, 66.4, 80, 110, 110, 254.99, 263.99, 870, 743, 445],
[39.12, 64.83, 79.99, 110, 110, 255, 264, 858, 734, 444],
[39.21, 64.93, 80, 110, 109.99, 255, 263.99, 865, 737, 445],
[39.03, 67.6, 79.99, 110, 110, 255, 263.99, 869, 742, 446],
[39.15, 66.97, 80, 109.99, 110, 255, 264, 869, 740, 445],
[39.18, 66.64, 79.99, 110, 110, 255, 263.99, 868, 744, 446],
[38.97, 66.6, 80, 110, 110, 254.99, 264, 860, 738, 445],
[38.91, 66.66, 80, 110, 110, 255, 263.99, 868, 742, 445],
[39, 66.94, 79.99, 110, 110, 254.99, 263.99, 870, 745, 446],
[38.85, 68.43, 80, 109.99, 110, 255, 263.99, 873, 744, 446],
[38.97, 67.58, 79.99, 109.99, 110, 254.99, 264, 877, 749, 446],
[38.88, 66.23, 80, 110, 110, 255, 264, 871, 744, 446],
[38.85, 64.98, 79.99, 110, 110, 254.99, 264, 872, 743, 446],
[38.88, 64.42, 79.99, 110, 110, 255, 263.99, 870, 741, 446],
[38.88, 63.93, 79.99, 110, 109.99, 254.99, 264, 868, 741, 446],
[39.06, 65.42, 79.99, 109.99, 109.99, 255, 263.99, 871, 745, 446],
[38.88, 64.83, 79.99, 110, 110, 255, 264, 872, 743, 446],
[38.82, 65.6, 79.99, 110, 110, 254.99, 263.99, 868, 736, 446],
[38.82, 65.07, 80, 110, 110, 255, 264, 868, 743, 445],
[39.12, 64.88, 79.99, 110, 110, 255, 263.99, 864, 734, 445],
[38.91, 66.98, 79.99, 109.99, 110, 254.99, 264, 865, 741, 445],
[38.79, 66.94, 80, 110, 110, 255, 264, 859, 734, 445],
[38.82, 66.44, 79.99, 110, 110, 255, 264, 862, 738, 445],
[38.88, 66.51, 80, 109.99, 110, 255, 263.99, 868, 742, 446],
[38.82, 66.6, 80, 110, 110, 255, 263.99, 873, 741, 446],
[38.82, 66.16, 79.99, 109.99, 110, 255, 264, 866, 742, 446],
[38.88, 64.84, 79.99, 110, 110, 254.99, 264, 870, 742, 446],
[38.82, 65.38, 80, 110, 110, 254.99, 264, 874, 745, 446],
[38.82, 64.86, 79.99, 110, 110, 255, 263.99, 867, 742, 446],
[38.79, 67.07, 79.99, 110, 109.99, 254.99, 263.99, 874, 741, 446],
[38.64, 66.76, 79.99, 109.99, 110, 255, 263.99, 878, 750, 447],
[38.7, 66.37, 80, 110, 109.99, 254.99, 264, 874, 746, 446],
[38.64, 66.27, 79.99, 110, 110, 255, 263.99, 874, 742, 447],
[38.88, 64.51, 79.99, 110, 110, 255, 264, 869, 742, 446],
[38.7, 62.73, 80, 109.99, 110, 254.99, 264, 872, 744, 446],
[38.79, 64.76, 80, 110, 110, 254.99, 264, 871, 741, 446],
[38.79, 66.16, 79.99, 110, 110, 255, 263.99, 875, 746, 446],
[39, 65.88, 80, 110, 110, 255, 264, 869, 746, 446],
[39.24, 68.32, 80, 110, 110, 255, 264, 861, 735, 444],
[39.15, 67.2, 80, 110, 110, 255, 263.99, 871, 746, 446],
[38.91, 65.69, 80, 110, 110, 254.99, 263.99, 866, 742, 445],
[39, 66.7, 79.99, 110, 109.99, 254.99, 264, 876, 741, 446],
[39.06, 65.79, 80, 110, 110, 255, 264, 870, 740, 446],
[39.3, 63.92, 80, 110, 110, 255, 264, 874, 743, 446],
[39.03, 64.36, 80, 109.99, 110, 254.99, 264, 875, 748, 446],
[39.15, 64.73, 79.99, 110, 109.99, 255, 263.99, 873, 742, 446],
[39.06, 66.23, 80, 110, 110, 254.99, 264, 867, 739, 445],
[39.15, 65.6, 80, 110, 109.99, 255, 264, 867, 742, 445],
[39.18, 67.14, 79.99, 110, 110, 255, 263.99, 860, 731, 444],
[39.3, 67.49, 80, 110, 110, 255, 263.99, 874, 742, 446],
[39.12, 66.59, 79.99, 110, 110, 255, 263.99, 871, 744, 446],
[39.24, 65.75, 79.99, 110, 110, 254.99, 263.99, 871, 742, 445],
[39.21, 65.11, 79.99, 110, 110, 254.99, 263.99, 866, 737, 444],
[39.24, 65.69, 80, 110, 110, 254.99, 263.99, 864, 738, 444],
[39.24, 66.1, 79.99, 109.99, 110, 254.99, 264, 866, 738, 445],
[39.18, 66.87, 79.99, 109.99, 110, 255, 264, 873, 744, 446],
[39.21, 66.77, 80, 110, 109.99, 255, 264, 873, 745, 446],
[39.18, 65.83, 80, 109.99, 110, 255, 263.99, 858, 730, 443],
[39.36, 65.19, 80, 110, 110, 254.99, 264, 855, 730, 444],
[39.46, 64.61, 79.99, 110, 110, 254.99, 264, 863, 739, 444],
[39.24, 64.06, 79.99, 110, 110, 254.99, 263.99, 862, 732, 445],
[39.21, 64.96, 80, 110, 110, 254.99, 263.99, 855, 730, 443],
[39.3, 65.27, 79.99, 110, 110, 254.99, 264, 863, 737, 444],
[39.3, 65.03, 79.99, 110, 110, 255, 263.99, 876, 751, 446],
[39.49, 65.23, 79.99, 110, 109.99, 254.99, 264, 875, 748, 446],
[39.43, 66.82, 80, 110, 110, 255, 263.99, 874, 743, 445],
[39.76, 66.22, 79.99, 110, 110, 255, 264, 881, 747, 445],
[39.67, 64.78, 80, 110, 110, 255, 264, 879, 753, 444],
[39.64, 65.86, 80, 109.99, 110, 255, 264, 876, 748, 445],
[39.85, 66.48, 80, 109.99, 110, 255, 263.98, 875, 747, 445],
[39.88, 67.26, 80, 110, 110, 254.99, 263.99, 877, 746, 445],
[39.91, 66.77, 79.99, 109.99, 110, 255, 264, 868, 739, 444],
[40.09, 65.52, 80, 110, 110, 254.99, 263.99, 869, 743, 444],
[40.28, 65.02, 80, 109.99, 110, 254.99, 264, 870, 738, 444],
[40.31, 64.57, 80, 110, 110, 254.99, 264, 871, 745, 444],
[40.34, 64.88, 80, 109.99, 110, 255, 263.98, 870, 739, 444],
[40.28, 66.15, 79.99, 110, 110, 254.99, 264, 864, 734, 443],
[40.34, 66, 79.99, 110, 110, 255, 264, 855, 730, 441],
[40.37, 65.24, 79.99, 110, 110, 255, 263.99, 868, 739, 443],
[40.58, 67.7, 79.99, 110, 109.99, 255, 263.99, 861, 732, 443],
[40.71, 67, 80, 110, 109.99, 254.99, 264, 849, 725, 440],
[40.58, 66.36, 80, 110, 110, 254.99, 264, 848, 724, 440],
[40.55, 65.33, 79.99, 110, 110, 254.99, 263.96, 845, 721, 440],
[40.52, 65.49, 80, 110, 110, 255, 263.99, 854, 726, 441],
[40.61, 64.84, 80, 110, 110, 255, 263.99, 849, 721, 439],
[40.64, 65.39, 79.99, 110, 109.99, 254.99, 263.99, 859, 733, 442],
[40.77, 65.8, 80, 110, 109.99, 255, 263.91, 875, 733, 443],
[40.8, 66.64, 80, 110, 110, 255, 264, 858, 731, 441],
[40.92, 65.41, 80, 110, 110, 254.99, 264, 857, 730, 441],
[40.89, 64.44, 80, 109.99, 110, 254.99, 264, 852, 726, 440],
[40.89, 65.48, 80, 110, 109.99, 255, 263.86, 863, 725, 440],
[40.89, 65.38, 79.99, 110, 110, 254.99, 264, 855, 727, 440],
[40.89, 64.24, 80, 110, 109.99, 254.99, 264, 856, 730, 440],
[40.83, 67.43, 80, 110, 109.99, 255, 263.99, 856, 730, 441],
[40.86, 68.09, 80, 110, 110, 255, 264, 861, 727, 441],
[40.83, 67.39, 79.99, 109.99, 110, 254.98, 263.35, 860, 731, 441],
[40.86, 65.94, 79.99, 110, 110, 255, 263.99, 863, 738, 442],
[40.95, 65.45, 79.99, 109.99, 110, 254.99, 264, 861, 729, 441],
[41.02, 64.21, 80, 110, 109.99, 254.99, 264, 857, 731, 441],
[41.2, 64.27, 80, 110, 110, 255, 264, 864, 732, 442],
[41.2, 66.72, 79.99, 110, 109.99, 255.01, 263.75, 865, 727, 441],
[41.08, 66.65, 79.99, 110, 110, 255, 264, 854, 729, 441],
[41.33, 65.93, 80, 109.99, 110, 254.99, 264, 860, 737, 441],
[41.23, 67.47, 79.99, 109.99, 110, 254.99, 264, 861, 736, 442],
[41.17, 67.39, 79.99, 110, 110, 254.99, 264, 866, 737, 442],
[41.05, 65.45, 79.99, 110, 110, 254.99, 263.99, 869, 737, 443],
[41.17, 64.91, 80, 110, 110, 255, 263.99, 865, 733, 442],
[41.05, 66.92, 79.99, 110, 110, 255, 263.99, 854, 727, 440],
[41.36, 66.15, 80, 109.99, 110, 255, 264, 870, 739, 443],
[41.14, 66.14, 79.99, 109.99, 110, 254.99, 264, 867, 738, 442],
[41.36, 66.21, 80, 109.99, 110, 254.99, 263.99, 859, 731, 440],
[41.83, 66.06, 79.99, 109.99, 110, 254.96, 263.84, 866, 736, 441],
[41.92, 64.58, 79.99, 109.99, 110, 255, 264, 857, 725, 439],
[41.92, 64.1, 80, 110, 110, 255, 264, 846, 722, 437],
[42.42, 65.81, 80, 110, 110, 255, 264.26, 869, 728, 438],
[42.42, 65.27, 79.99, 110, 110, 254.99, 264, 861, 735, 440],
[42.74, 64.13, 79.99, 110, 109.99, 255, 264, 867, 734, 441],
[43, 66.98, 79.99, 110, 109.99, 255, 264, 854, 724, 437],
[42.74, 67.2, 80, 110, 110, 255, 264, 864, 738, 440],
[42.68, 67.83, 79.99, 110, 110, 255, 263.99, 874, 739, 441],
[42.9, 65.77, 79.99, 109.99, 109.99, 254.99, 263.99, 873, 737, 441],
[42.71, 66.73, 79.99, 110, 110, 255, 263.99, 868, 736, 441],
[42.71, 65.7, 80, 109.99, 110, 255, 264, 872, 743, 441],
[42.68, 65.63, 79.99, 109.99, 110, 255, 264, 871, 738, 441],
[42.81, 64.62, 80, 109.99, 110, 254.99, 263.99, 871, 738, 441],
[42.71, 65.99, 80, 110, 110, 254.99, 263.99, 871, 738, 441],
[42.97, 66.13, 80, 109.99, 110, 254.99, 263.99, 867, 735, 440],
[42.71, 67.41, 79.99, 109.99, 109.99, 254.99, 263.99, 871, 739, 441],
[42.65, 67.41, 79.99, 110, 110, 255, 264, 865, 736, 441],
[42.58, 67.14, 80, 110, 110, 255, 264, 866, 739, 441],
[42.49, 64.63, 80, 110, 110, 255, 264, 851, 717, 437],
[42.42, 64.41, 80, 109.99, 110, 254.99, 264, 856, 727, 439],
[42.42, 64.76, 79.99, 109.99, 110, 254.99, 264, 859, 732, 440],
[42.36, 68.27, 79.99, 109.99, 110, 254.99, 264, 866, 737, 441],
[42.27, 66.25, 79.99, 110, 110, 254.99, 263.99, 872, 738, 441],
[42.27, 67.47, 80, 110, 110, 255, 263.99, 875, 741, 442],
[42.27, 66.3, 80, 110, 110, 254.99, 264, 880, 744, 442],
[41.11, 66.66, 79.99, 110, 110, 255, 263.99, 869, 733, 442],
[41.05, 67.31, 79.99, 110, 109.99, 254.99, 264, 860, 729, 441],
[41.11, 68.34, 80, 110, 110, 255, 264, 860, 729, 440],
[40.74, 65.71, 80, 109.99, 110, 254.99, 263.99, 870, 739, 443],
[40.61, 65.15, 79.99, 110, 110, 255, 263.99, 855, 727, 441],
[40.49, 64.66, 80, 110, 110, 255, 263.99, 869, 737, 442],
[40.68, 64.21, 80, 109.99, 110, 255, 264, 862, 731, 441],
[40.64, 67.14, 79.99, 110, 110, 254.99, 263.99, 867, 734, 442],
[40.61, 68.56, 80, 109.99, 110, 255, 263.99, 859, 730, 441],
[40.68, 67.81, 79.99, 109.99, 110, 254.99, 264, 862, 734, 443],
[40.89, 66.48, 80, 109.99, 110, 254.99, 263.99, 862, 731, 441],
[40.92, 67.44, 79.99, 110, 109.99, 255, 263.99, 868, 738, 442],
[40.95, 66.35, 80, 110, 110, 255, 264, 867, 741, 442],
[40.98, 67.88, 80, 110, 110, 255, 263.99, 857, 727, 439],
[40.98, 65.97, 80, 110, 110, 254.99, 263.99, 852, 724, 439],
[40.89, 67.41, 80, 110, 110, 255, 263.99, 859, 730, 440],
[40.86, 66.64, 80, 110, 110, 254.99, 264, 859, 733, 441],
[40.83, 66.44, 80, 110, 110, 255, 264, 863, 731, 442],
[40.83, 66.12, 80, 110, 110, 254.99, 263.99, 846, 722, 438],
[40.89, 65.57, 79.99, 110, 110, 255, 263.99, 856, 729, 440],
[40.92, 64.65, 79.99, 110, 109.99, 254.99, 263.99, 852, 724, 439],
[40.89, 64.13, 80, 110, 110, 255, 264, 855, 733, 440],
[40.89, 64.95, 79.99, 110, 110, 255, 263.99, 850, 723, 439],
[40.86, 65.97, 79.99, 110, 110, 254.99, 264, 853, 727, 439],
[41.11, 65.88, 79.99, 110, 110, 255, 264, 844, 716, 438],
[41.26, 67.41, 79.99, 110, 110, 254.99, 263.99, 841, 714, 437],
[41.26, 67.22, 80, 109.99, 110, 254.99, 263.99, 845, 722, 438],
[41.36, 66.4, 79.99, 110, 109.99, 255, 264, 852, 725, 439],
[41.61, 64.48, 80, 110, 110, 255, 263.99, 860, 738, 441],
[41.64, 64.48, 80, 110, 110, 254.99, 263.99, 855, 735, 440],
[41.83, 65.12, 80, 110, 109.99, 254.99, 263.99, 860, 729, 440],
[41.83, 67.18, 79.99, 109.99, 110, 255, 263.99, 856, 728, 439],
[42.01, 66.06, 79.99, 110, 110, 254.99, 263.99, 850, 724, 438],
[42.2, 67.02, 79.99, 110, 110, 255, 264, 845, 718, 437],
[42.17, 65.35, 80, 110, 109.99, 255, 264, 843, 714, 436],
[46.29, 64.79, 80, 110, 109.99, 255, 264, 841, 715, 433],
[46.39, 64.47, 80, 109.99, 110, 254.99, 263.99, 839, 714, 433],
[46.29, 65.67, 79.99, 110, 110, 255.01, 263.62, 840, 711, 433],
[46.46, 65.53, 80, 110, 110, 254.99, 264, 838, 713, 433],
[46.49, 63.29, 80, 110, 110, 254.99, 264, 838, 708, 433],
[46.6, 66.95, 80, 110, 110, 255, 263.99, 854, 722, 436],
[46.6, 67.23, 80, 110, 110, 255, 263.99, 845, 718, 434],
[46.7, 65.48, 79.99, 110, 109.99, 255, 264, 849, 724, 435],
[46.94, 63.01, 79.99, 110, 109.99, 254.99, 264, 851, 716, 435],
[46.63, 63.1, 80, 110, 110, 255, 263.99, 871, 723, 437],
[46.6, 64.32, 80, 109.99, 110, 254.99, 264, 862, 725, 437],
[46.63, 66.3, 79.99, 109.99, 110, 255, 264, 873, 739, 440],
[46.53, 67.71, 79.99, 109.99, 110, 255, 264, 874, 740, 440],
[46.36, 68.65, 80, 110, 110, 254.99, 264, 874, 736, 440],
[46.15, 65.76, 79.99, 110, 110, 255, 263.99, 863, 729, 438],
[46.09, 63.51, 80, 110, 110, 254.99, 264, 873, 736, 440],
[46.09, 62.86, 79.99, 109.99, 110, 255, 264, 870, 735, 440],
[46.05, 62.75, 80, 110, 110, 255, 263.99, 861, 726, 437],
[46.02, 62.33, 80, 110, 110, 254.99, 263.99, 860, 728, 437],
[45.95, 63.62, 80, 110, 110, 255, 264, 871, 736, 439],
[45.92, 63.33, 80, 110, 109.99, 254.99, 263.99, 872, 735, 440],
[45.85, 62.3, 80, 109.99, 109.99, 255, 264, 875, 735, 439],
[45.75, 61.66, 80, 110, 110, 254.99, 264, 868, 728, 439],
[45.92, 61.64, 79.99, 110, 110, 255, 264, 853, 718, 436],
[45.55, 63.62, 80, 109.99, 109.99, 254.99, 264, 875, 741, 440],
[44.19, 63.96, 80, 110, 110, 255, 264, 871, 740, 440],
[43.13, 64.26, 80, 110, 110, 255, 263.99, 871, 739, 441],
[43.09, 65, 80, 110, 110, 255, 263.99, 870, 738, 440],
[42.97, 66.26, 79.99, 110, 109.99, 255, 263.99, 872, 739, 441],
[42.9, 62.96, 79.99, 110, 110, 254.99, 264, 884, 747, 442],
[42.74, 62.84, 79.99, 110, 109.99, 254.99, 263.99, 884, 748, 442],
[42.68, 61.71, 80, 110, 110, 255, 263.99, 881, 743, 442],
[42.74, 62.34, 80, 110, 110, 255, 264, 883, 745, 443],
[42.68, 67.18, 79.99, 110, 109.99, 254.99, 263.99, 880, 740, 441],
[42.68, 65.92, 79.99, 110, 110, 254.99, 263.99, 881, 746, 442],
[42.74, 65.19, 80, 110, 110, 254.99, 264, 875, 743, 441],
[42.84, 64.89, 80, 110, 110, 255, 263.99, 879, 741, 442],
[42.77, 64.08, 79.99, 109.99, 110, 254.99, 263.99, 878, 745, 442],
[42.77, 63.29, 79.99, 109.99, 110, 255, 263.99, 871, 740, 440],
[42.97, 64.89, 80, 110, 110, 255, 263.99, 873, 740, 442],
[42.84, 64.33, 80, 110, 110, 255, 263.99, 879, 746, 442],
[43.03, 64.59, 79.99, 110, 110, 254.99, 264, 881, 745, 442],
[42.87, 64.34, 80, 110, 110, 255, 264, 876, 743, 442],
[42.97, 64.36, 79.99, 109.99, 110, 255, 263.99, 862, 731, 439],
[42.97, 65.81, 79.99, 110, 110, 255, 264, 872, 740, 440],
[42.97, 65.56, 80, 110, 110, 255, 264, 877, 747, 441],
[42.84, 65.32, 80, 109.99, 110, 254.99, 264, 882, 744, 441],
[42.93, 64.92, 80, 110, 110, 255, 263.99, 917, 740, 441],
[42.97, 65.43, 79.99, 110, 110, 255, 263.99, 870, 740, 440],
[43.06, 64.92, 80, 110, 110, 254.99, 264, 872, 741, 440],
[43.13, 67.25, 79.99, 110, 109.99, 254.99, 264, 874, 743, 441],
[42.9, 67.06, 79.99, 110, 110, 255, 264, 877, 743, 442],
[42.97, 66.41, 80, 110, 110, 254.99, 263.99, 875, 742, 441],
[42.74, 65.99, 79.99, 110, 109.99, 254.99, 264, 878, 748, 442],
[42.93, 65.1, 80, 110, 110, 255, 264, 883, 745, 442],
[43, 65.64, 79.99, 110, 110, 255, 264, 884, 747, 442],
[43.03, 65.64, 80, 110, 110, 254.99, 264, 881, 749, 442],
[42.93, 64.9, 79.99, 110, 110, 254.99, 263.99, 881, 743, 442],
[42.93, 65.61, 79.99, 110, 110, 255, 263.98, 883, 750, 442],
[43.03, 65.27, 80, 109.99, 110, 255, 264, 882, 746, 442],
[43.03, 65.6, 79.99, 110, 110, 254.99, 264, 880, 745, 442],
[43.03, 65.3, 79.99, 109.99, 110, 254.99, 263.95, 882, 747, 442],
[42.93, 65.94, 79.99, 110, 110, 254.99, 264, 883, 751, 441],
[43, 67.14, 79.99, 110, 110, 254.99, 264, 885, 751, 442],
[42.97, 67.4, 80, 110, 110, 254.99, 263.99, 884, 748, 442],
[43.09, 66.61, 79.99, 110, 109.99, 255, 263.99, 885, 753, 442],
[43, 67.13, 80, 110, 109.99, 255, 263.95, 879, 743, 442],
[43, 68.09, 79.99, 110, 109.99, 255, 263.99, 878, 748, 442],
[43.06, 67.05, 79.99, 110, 110, 254.99, 263.99, 885, 746, 442],
[43.03, 67.41, 80, 110, 110, 255, 264, 883, 749, 442],
[43.06, 67.22, 80, 110, 110, 255, 264, 883, 751, 442],
[43.03, 65.85, 79.99, 110, 110, 254.98, 263.97, 883, 747, 442],
[43.06, 66.56, 80, 110, 110, 254.99, 263.99, 875, 744, 441],
[43, 66.07, 79.99, 110, 110, 255, 263.99, 877, 743, 442],
[43.16, 65.91, 79.99, 109.99, 110, 255, 264, 879, 747, 440],
[43.35, 66.91, 80, 110, 110, 255, 263.99, 878, 744, 442],
[43.03, 67.3, 79.99, 110, 110, 254.96, 264.02, 881, 747, 441],
[43.29, 67.31, 79.99, 110, 110, 254.99, 263.99, 873, 736, 441],
[43.25, 65.3, 80, 110, 109.99, 255, 263.99, 869, 736, 440],
[43.19, 66.19, 79.99, 110, 109.99, 255, 264, 874, 739, 441],
[43.42, 65.77, 79.99, 110, 110, 254.99, 264, 874, 741, 440],
[43.13, 64.59, 79.99, 110, 110, 254.99, 263.68, 875, 739, 441],
[43.19, 64.54, 80, 110, 110, 254.99, 264, 875, 745, 442],
[43.22, 65.58, 79.99, 110, 110, 255, 264, 880, 746, 441],
[43.32, 65.53, 80, 110, 110, 255, 264, 878, 741, 441],
[43.06, 66.31, 79.99, 110, 110, 255, 264, 874, 737, 440],
[43.06, 65.48, 80, 110, 110, 255, 264, 876, 745, 442],
[42.84, 66.08, 80, 110, 109.99, 255, 264, 874, 742, 441],
[43.03, 66.38, 80, 110, 110, 255, 263.99, 875, 742, 440],
[43.09, 66.28, 79.99, 110, 110, 255.22, 263.28, 879, 745, 441],
[43.32, 65.15, 80, 110, 110, 254.99, 263.99, 878, 743, 441],
[43.13, 64.66, 80, 110, 110, 254.99, 263.99, 877, 744, 442],
[43.09, 64.86, 79.99, 110, 110, 254.99, 264, 873, 739, 441],
[43.13, 65.8, 79.99, 110, 110, 254.99, 264, 873, 739, 440],
[43.13, 65.48, 80, 110, 110, 254.7, 263.73, 877, 740, 441],
[43.13, 67.82, 79.99, 110, 110, 254.99, 263.99, 882, 745, 442],
[43.16, 66.81, 80, 110, 110, 254.99, 264, 885, 748, 441],
[43.35, 66.73, 80, 110, 110, 254.99, 263.99, 874, 744, 441],
[43.16, 68.52, 80, 110, 110, 254.99, 264, 867, 736, 439],
[43.25, 67.13, 80, 110, 110, 261.97, 257.93, 858, 727, 437],
[43.29, 66.35, 80, 109.99, 110, 255, 263.99, 858, 728, 437],
[43.42, 65.67, 80, 110, 110, 255, 264, 873, 739, 440],
[43.54, 65.6, 79.99, 109.99, 110, 254.99, 263.99, 872, 738, 439],
[43.48, 65.37, 80, 110, 109.99, 255, 263.99, 862, 735, 439],
[43.42, 66.63, 79.99, 109.99, 109.99, 254.99, 263.99, 874, 744, 441],
[43.67, 66.65, 79.99, 110, 110, 254.99, 263.99, 872, 740, 440],
[43.58, 67.23, 80, 110, 110, 255, 263.99, 869, 737, 440],
[43.61, 67.78, 80, 109.99, 109.99, 255, 263.99, 861, 732, 438],
[43.58, 66.46, 79.99, 110, 109.99, 255, 264, 869, 738, 440],
[43.84, 65.65, 79.99, 110, 109.99, 255, 263.99, 861, 728, 438],
[43.84, 65.36, 79.99, 110, 110, 255, 263.99, 865, 734, 439],
[43.9, 68.77, 80, 109.99, 109.99, 254.99, 263.99, 861, 738, 438],
[44.23, 69.59, 79.99, 110, 110, 255, 264, 859, 731, 438],
[44.69, 67.43, 79.99, 110, 110, 254.99, 264, 861, 733, 438],
[44.92, 67.17, 79.99, 110, 110, 255, 264, 856, 730, 437],
[45.12, 65.98, 80, 110, 110, 255, 264, 852, 722, 435],
[45.28, 66.56, 79.99, 110, 110, 254.99, 264, 849, 719, 435],
[49.29, 63.47, 80, 110, 110, 255, 264, 845, 711, 436],
[49.43, 64.44, 80, 110, 109.99, 254.99, 263.99, 848, 715, 436],
[49.72, 64.52, 80, 110, 110, 255, 264, 851, 719, 438],
[49.5, 64.73, 80, 109.99, 110, 255, 263.99, 856, 723, 438],
[50.23, 63.23, 79.99, 109.99, 109.99, 254.99, 263.99, 876, 737, 443],
[50.04, 64.06, 79.99, 110, 110, 255, 264, 860, 727, 441],
[49.97, 62.96, 79.99, 110, 110, 255, 264, 863, 726, 440],
[50.01, 64.65, 79.99, 110, 109.99, 254.99, 264, 853, 719, 439],
[49.83, 65.1, 80, 110, 109.99, 255, 264, 861, 726, 440],
[49.9, 64.24, 79.99, 110, 110, 255, 263.99, 863, 730, 441],
[49.79, 64.03, 79.99, 110, 110, 255.18, 263.99, 885, 741, 444],
[49.68, 63.47, 80, 110, 110, 254.99, 263.99, 871, 738, 444],
[49.54, 64.28, 79.99, 110, 109.99, 255, 263.99, 850, 719, 438],
[49.5, 64.36, 80, 110, 110, 255, 263.99, 854, 719, 439],
[49.47, 63.91, 79.99, 110, 110, 255, 264, 860, 724, 441],
[49.29, 62.27, 79.99, 110, 109.99, 255, 264, 868, 734, 442],
[49.18, 61.42, 80, 110, 110, 255, 263.99, 863, 727, 441],
[47.8, 65.92, 79.99, 110, 109.99, 255, 263.99, 841, 713, 435],
[47.7, 65.6, 79.99, 109.99, 110, 254.99, 263.99, 838, 704, 434],
[47.35, 64.1, 79.99, 109.99, 110, 255, 264, 876, 739, 442],
[46.19, 63.74, 80, 109.99, 110, 255, 263.99, 866, 736, 440],
[45.92, 63.65, 80, 110, 109.99, 254.99, 263.99, 874, 744, 441],
[45.31, 62.54, 80, 110, 110, 255, 264, 866, 737, 440],
[45.28, 63.56, 80, 110, 110, 255, 264, 874, 739, 440],
[45.22, 63.23, 80, 110, 110, 255, 263.99, 882, 742, 441],
[45.25, 64.67, 80, 110, 109.99, 255, 263.99, 856, 725, 437],
[45.31, 65.5, 79.99, 110, 110, 254.99, 264, 858, 729, 437],
[45.28, 64.21, 80, 109.99, 110, 255, 264, 866, 732, 438],
[45.45, 63.75, 80, 110, 110, 254.99, 264, 866, 730, 439],
[45.22, 65.11, 79.99, 110, 110, 254.99, 263.99, 852, 724, 436],
[45.22, 64.91, 80, 110, 109.99, 254.99, 263.99, 854, 721, 436],
[45.12, 65.94, 80, 110, 110, 254.99, 264, 876, 738, 440],
[45.02, 65.86, 80, 110, 110, 255, 263.99, 866, 729, 438],
[44.98, 66.33, 79.99, 110, 110, 255, 264, 856, 723, 436],
[45.02, 64.73, 80, 110, 110, 255, 263.99, 865, 732, 439],
[44.95, 64.07, 79.99, 110, 109.99, 254.99, 264, 882, 742, 441],
[44.88, 64.27, 80, 110, 110, 255, 264, 874, 739, 441],
[45.02, 63.19, 80, 110, 110, 255, 264, 863, 729, 438],
[44.82, 64.1, 80, 110, 110, 254.99, 263.99, 857, 721, 437],
[44.75, 64.31, 79.99, 109.99, 110, 255, 263.99, 868, 735, 440],
[44.85, 65.08, 79.99, 109.99, 110, 255, 263.99, 875, 739, 440],
[44.78, 64.32, 79.99, 109.99, 110, 255, 263.99, 869, 737, 439],
[44.65, 66.2, 79.99, 110, 109.99, 255, 264, 871, 736, 440],
[44.85, 64.42, 79.99, 110, 110, 254.99, 264.01, 866, 735, 440],
[44.75, 64.51, 80, 109.99, 110, 255, 264, 863, 732, 438],
[44.75, 63.54, 79.99, 110, 110, 255, 263.99, 855, 725, 437],
[44.88, 63.58, 80, 110, 110, 254.99, 263.99, 860, 732, 438],
[45.12, 64.67, 79.99, 109.99, 110, 254.99, 264, 871, 738, 439],
[45.48, 63.9, 80, 109.99, 110, 254.99, 264, 866, 732, 438],
[45.65, 63.44, 79.99, 110, 110, 254.99, 264, 871, 737, 439],
[45.41, 63.6, 79.99, 109.99, 110, 255, 264, 856, 729, 438],
[45.38, 63.06, 80, 110, 110, 255, 264, 859, 730, 437],
[45.55, 63.49, 79.99, 110, 110, 254.99, 263.99, 852, 725, 435],
[45.12, 64.12, 80, 109.99, 109.99, 254.99, 263.99, 842, 718, 434],
[44.92, 66.2, 80, 110, 109.99, 255, 264, 857, 728, 437],
[44.69, 65.63, 80, 110, 110, 254.99, 263.99, 850, 721, 436],
[44.65, 63.86, 80, 110, 109.99, 254.99, 264, 864, 737, 438],
[44.62, 63.06, 79.99, 110, 110, 254.99, 263.97, 868, 736, 439],
[44.78, 63.63, 80, 110, 110, 254.99, 264, 862, 730, 437],
[44.69, 65, 80, 110, 110, 255, 263.99, 857, 725, 437],
[44.69, 69.37, 80, 109.99, 110, 254.99, 263.99, 854, 724, 436],
[44.65, 67.81, 79.99, 110, 110, 254.99, 264, 852, 729, 436],
[44.62, 65.61, 80, 110, 110, 254.99, 264, 856, 725, 437],
[44.52, 64.2, 79.99, 110, 110, 255, 263.97, 877, 733, 438],
[44.49, 64.32, 79.99, 110, 110, 255, 264, 861, 731, 438],
[44.39, 64.3, 79.99, 109.99, 110, 255, 264, 860, 729, 438],
[44.16, 64.13, 80, 110, 110, 254.99, 263.99, 854, 725, 436],
[44.39, 64.83, 80, 110, 109.99, 255, 264, 862, 732, 437],
[44.39, 66.94, 79.99, 110, 110, 254.98, 263.51, 868, 738, 439],
[44.59, 67.62, 80, 109.99, 110, 254.99, 264, 866, 733, 438],
[44.62, 66.59, 80, 110, 110, 255, 263.99, 862, 730, 437],
[45.02, 64.46, 80, 110, 110, 254.99, 263.99, 869, 738, 438],
[44.82, 64.89, 80, 110, 110, 254.99, 263.99, 866, 735, 438],
[45.02, 66.45, 80, 110, 110, 254.99, 264, 852, 720, 435],
[45.12, 65.63, 79.99, 110, 110, 255, 263.99, 851, 722, 435],
[45.12, 65.05, 80, 110, 110, 254.99, 263.99, 863, 731, 438],
[45.15, 64.28, 79.99, 110, 110, 254.99, 264, 864, 730, 438],
[45.12, 64.87, 79.99, 110, 109.99, 255, 264.29, 869, 729, 436],
[45.15, 64.96, 80, 110, 110, 255, 264, 857, 726, 436],
[45.22, 64.68, 80, 110, 110, 255, 264, 858, 728, 436],
[45.28, 65.28, 80, 110, 109.99, 255, 264.01, 856, 722, 436],
[44.98, 64.94, 79.99, 110, 110, 254.99, 263.99, 850, 720, 435],
[44.98, 65.58, 80, 110, 110, 255, 264, 869, 741, 439],
[45.05, 66.87, 79.99, 110, 110, 254.99, 263.99, 872, 741, 439],
[44.95, 66.43, 80, 110, 110, 255, 266.08, 880, 732, 439],
[44.95, 67.4, 80, 109.99, 110, 255, 264, 860, 729, 436],
[44.98, 66.5, 80, 109.99, 109.99, 255, 264, 854, 723, 435],
[45.05, 65.76, 79.99, 110, 110, 254.99, 263.99, 855, 723, 435],
[45.15, 63.8, 80, 110, 110, 254.99, 263.99, 870, 739, 439],
[45.22, 64.26, 80, 110, 109.99, 254.99, 264, 877, 743, 439],
[45.38, 64.37, 80, 110, 109.99, 254.99, 264, 866, 730, 438],
[45.35, 64.46, 80, 109.99, 110, 255, 264, 867, 735, 438],
[45.41, 63.9, 80, 110, 110, 254.99, 263.98, 872, 741, 439],
[45.52, 63.99, 80, 110, 110, 255, 263.99, 874, 740, 440],
[45.55, 62.29, 80, 110, 110, 254.99, 264, 873, 737, 439],
[45.82, 62.84, 80, 110, 110, 254.99, 264, 873, 738, 439],
[45.72, 63.61, 79.99, 110, 109.99, 255, 264, 863, 728, 436],
[45.68, 63.6, 79.99, 110, 109.99, 254.99, 263.99, 863, 729, 437],
[45.72, 63.43, 79.99, 110, 110, 254.99, 263.99, 859, 728, 436],
[45.75, 64.13, 80, 110, 110, 254.99, 264, 846, 715, 432],
[45.82, 65.36, 80, 110, 110, 254.99, 264, 860, 724, 436],
[45.88, 66.68, 79.99, 109.99, 110, 255, 264, 865, 730, 437],
[45.85, 67.07, 80, 109.99, 110, 255, 263.99, 866, 732, 438],
[45.78, 66.55, 79.99, 110, 110, 255, 264, 867, 729, 437],
[45.62, 63.88, 79.99, 110, 110, 254.99, 263.99, 872, 735, 439],
[45.58, 63.42, 80, 110, 109.99, 255, 264, 878, 738, 440],
[45.58, 62.68, 80, 110, 110, 254.99, 264, 874, 737, 439],
[45.55, 62.94, 80, 110, 110, 254.99, 264, 861, 730, 436],
[45.65, 63.76, 79.99, 109.99, 109.99, 254.99, 264, 871, 733, 438],
[45.48, 65.65, 79.99, 110, 110, 255, 263.99, 872, 739, 440],
[42.62, 62.84, 79.99, 110, 110, 254.99, 263.99, 848, 721, 436],
[42.49, 64.87, 80, 110, 110, 255, 263.99, 852, 732, 438],
[42.33, 67.04, 79.99, 110, 110, 254.99, 263.98, 865, 731, 438],
[42.33, 66.89, 80, 110, 110, 254.99, 263.99, 844, 723, 435],
[42.39, 67.62, 79.99, 110, 110, 255, 264, 841, 720, 435],
[42.36, 66.33, 80, 109.99, 110, 254.99, 264, 846, 720, 436],
[42.27, 66.97, 79.99, 110, 110, 255, 264, 861, 739, 439],
[42.42, 66.64, 79.99, 110, 110, 254.99, 263.98, 863, 725, 436],
[42.74, 66.74, 79.99, 109.99, 110, 254.99, 263.99, 852, 723, 438],
[42.52, 64.54, 79.99, 110, 110, 255, 263.99, 834, 713, 434],
[42.55, 63.98, 80, 110, 110, 254.99, 263.99, 861, 716, 434],
[42.55, 63.91, 80, 110, 110, 255, 264, 834, 717, 434],
[42.68, 64.88, 79.99, 110, 110, 254.99, 264, 838, 719, 435],
[42.65, 64.42, 79.99, 110, 110, 255, 264, 839, 719, 435],
[42.68, 64.73, 79.99, 109.99, 110, 255, 263.99, 838, 715, 434],
[42.62, 64.15, 80, 109.99, 109.99, 254.99, 263.98, 865, 717, 435],
[42.84, 63.67, 80, 110, 110, 254.99, 263.99, 838, 718, 435],
[42.71, 64.52, 80, 109.99, 110, 255, 263.99, 846, 720, 436],
[42.81, 64.65, 80, 110, 110, 255, 263.99, 840, 722, 435],
[42.9, 66.53, 80, 110, 110, 255, 264, 838, 721, 435],
[42.93, 67.4, 80, 110, 110, 255.01, 263.99, 857, 732, 438],
[42.97, 67.5, 79.99, 110, 110, 254.99, 263.99, 861, 742, 439],
[43, 67.4, 79.99, 110, 110, 255, 263.99, 861, 731, 439],
[42.9, 68.15, 80, 110, 110, 255, 263.99, 862, 740, 439],
[43.13, 66.69, 79.99, 110, 110, 255, 264, 854, 731, 438],
[43.13, 65.32, 80, 110, 110, 255, 263.98, 870, 739, 440],
[43.45, 64.02, 80, 110, 110, 255, 263.99, 855, 731, 439],
[43.32, 64.56, 79.99, 110, 110, 255, 264, 856, 734, 438],
[43.35, 64.78, 79.99, 110, 110, 255, 264, 866, 739, 440],
[43.54, 64.02, 80, 110, 110, 254.99, 263.98, 851, 729, 438],
[43.74, 64.5, 79.99, 110, 110, 254.99, 264, 841, 720, 435],
[43.74, 64.59, 80, 110, 110, 254.99, 263.99, 848, 723, 437],
[43.84, 65.1, 80, 110, 110, 255, 264, 843, 716, 434],
[44.85, 64.05, 80, 110, 110, 255.01, 263.7, 842, 714, 434],
[44.95, 64.4, 79.99, 109.99, 109.99, 254.99, 264, 845, 720, 436],
[46.19, 63.5, 79.99, 110, 110, 254.85, 272.87, 859, 728, 437],
[46.19, 63.46, 79.99, 110, 110, 255, 264, 850, 721, 436],
[48.96, 64.9, 79.99, 110, 110, 254.99, 264, 852, 715, 436],
[48.72, 64.19, 79.99, 109.99, 110, 254.99, 264, 858, 721, 437],
[48.82, 64.12, 79.99, 110, 110, 254.99, 263.99, 878, 736, 441],
[48.72, 64.28, 79.99, 110, 109.99, 255, 264, 844, 713, 434],
[48.72, 65.69, 80, 110, 109.99, 255, 264, 853, 722, 437],
[48.54, 66.47, 79.99, 109.99, 110, 255, 264, 868, 725, 439],
[48.68, 65.72, 79.99, 110, 110, 254.99, 263.99, 871, 734, 440],
[48.4, 65.21, 80, 109.99, 110, 254.99, 264, 868, 733, 440],
[48.26, 66.35, 79.99, 109.99, 110, 255, 263.99, 854, 726, 437],
[48.05, 66.55, 79.99, 110, 110, 255, 264, 849, 714, 436],
[46.15, 66.75, 80, 110, 109.99, 255, 264, 854, 723, 437],
[46.19, 64.95, 80, 110, 110, 255, 264, 865, 734, 439],
[45.41, 64.21, 79.99, 109.99, 110, 255, 264, 860, 729, 438],
[45.35, 64.57, 80, 110, 110, 254.97, 264, 856, 725, 437],
[45.25, 64.44, 80, 110, 110, 255, 263.99, 857, 721, 437],
[43.42, 66.17, 80, 110, 110, 254.99, 264, 866, 736, 438],
[43.61, 64.39, 79.99, 110, 110, 254.99, 264, 876, 744, 440],
[43.64, 65.91, 79.99, 110, 110, 255, 264, 876, 744, 440],
[43.06, 67.22, 79.99, 110, 110, 254.99, 263.99, 884, 750, 441],
[43.09, 67.29, 79.99, 109.99, 110, 254.99, 264, 874, 744, 441],
[42.62, 68.7, 79.99, 110, 110, 255, 263.99, 863, 736, 439],
[43.03, 67.05, 80, 110, 110, 255.31, 264, 871, 732, 439],
[42.81, 67.83, 79.99, 110, 109.99, 254.99, 263.99, 858, 732, 438],
[42.71, 66.71, 80, 109.99, 110, 255, 263.99, 864, 737, 439],
[42.71, 65.8, 80, 110, 110, 255, 263.99, 872, 739, 440],
[42.71, 65.16, 79.99, 110, 110, 254.99, 263.99, 871, 740, 440],
[42.68, 65.84, 79.99, 110, 110, 255, 264, 871, 743, 440],
[42.65, 64.77, 80, 110, 110, 255, 263.99, 867, 739, 439],
[42.84, 66.6, 80, 110, 110, 255, 264, 865, 735, 439],
[42.97, 66.72, 80, 109.99, 109.99, 255, 263.99, 858, 727, 437],
[42.74, 65.27, 80, 110, 109.99, 254.99, 264, 867, 730, 439],
[42.87, 65.63, 79.99, 110, 110, 255, 264, 851, 723, 436],
[42.87, 66.04, 80, 110, 110, 255, 264, 847, 718, 435],
[42.84, 66.46, 80, 110, 110, 254.99, 264, 858, 731, 438],
[42.84, 67.17, 80, 110, 110, 255, 264, 868, 737, 439],
[42.93, 66.99, 80, 110, 109.99, 254.99, 263.99, 875, 747, 441],
[42.87, 65.5, 79.99, 109.99, 110, 255, 264, 872, 740, 440],
[42.77, 67.03, 79.99, 110, 109.99, 255, 263.99, 872, 740, 440],
[42.81, 65.22, 80, 110, 110, 254.99, 264, 869, 734, 440],
[42.68, 66.61, 79.99, 110, 109.99, 255, 264, 862, 731, 438],
[42.65, 67.55, 79.99, 110, 110, 254.99, 263.99, 870, 738, 441],
[42.65, 67.18, 80, 110, 110, 255, 263.99, 875, 744, 441],
[42.52, 64.54, 79.99, 110, 110, 254.99, 263.99, 876, 746, 442],
[42.49, 64.66, 80, 110, 110, 255, 264, 878, 746, 441],
[42.49, 65.61, 80, 109.99, 109.99, 254.99, 264, 867, 738, 440],
[42.52, 67.64, 80, 110, 110, 254.99, 263.99, 877, 747, 441],
[42.39, 67.55, 79.99, 110, 110, 255, 263.99, 873, 745, 441],
[42.39, 67.26, 79.99, 109.99, 109.99, 255, 264, 875, 744, 441],
[42.33, 67.36, 80, 110, 110, 254.99, 263.99, 864, 738, 439],
[42.2, 65.34, 80, 110, 110, 255, 264, 874, 741, 441],
[42.36, 66.76, 80, 110, 110, 254.99, 263.99, 875, 743, 441],
[42.36, 67.39, 80, 110, 109.99, 254.99, 264, 875, 745, 441],
[42.42, 66.22, 79.99, 110, 109.99, 255, 264, 866, 735, 440],
[42.42, 65.93, 79.99, 109.99, 110, 254.99, 264, 866, 737, 440],
[42.49, 65.59, 80, 110, 110, 255, 263.99, 865, 738, 440],
[42.49, 65.42, 79.99, 110, 110, 254.99, 264, 869, 744, 440],
[42.52, 64.99, 79.99, 109.99, 110, 254.99, 264, 868, 738, 440],
[42.52, 67.08, 79.99, 109.99, 110, 254.99, 264, 882, 750, 442],
[42.39, 67.41, 79.99, 109.99, 110, 254.99, 264, 880, 747, 442],
[42.46, 66.78, 79.99, 110, 109.99, 255, 263.99, 876, 746, 441],
[42.49, 66.99, 80, 110, 110, 255, 263.99, 878, 750, 442],
[42.49, 66.88, 79.99, 110, 110, 255, 264, 872, 741, 441],
[42.71, 66.77, 80, 110, 110, 255, 263.99, 877, 744, 441],
[42.58, 64.86, 80, 109.99, 110, 254.99, 264, 879, 752, 441],
[42.58, 66.25, 79.99, 110, 109.99, 254.99, 264, 874, 742, 441],
[42.68, 66.95, 80, 110, 110, 255, 263.99, 878, 753, 442],
[42.71, 66.8, 79.99, 109.99, 110, 254.99, 264, 876, 742, 441],
[42.97, 66.02, 79.99, 110, 110, 255, 264, 874, 746, 441],
[42.81, 65.75, 80, 109.99, 110, 254.99, 263.99, 869, 744, 440],
[42.97, 66.72, 79.99, 110, 110, 254.99, 263.99, 875, 749, 441],
[42.97, 67.36, 79.99, 110, 110, 254.99, 263.99, 873, 744, 441],
[43.13, 69.13, 80, 110, 110, 254.99, 263.99, 877, 746, 441],
[43.03, 68.68, 79.99, 110, 110, 255, 263.99, 878, 743, 442],
[43.13, 66.77, 80, 110, 110, 254.99, 263.99, 881, 749, 441],
[43.42, 65.51, 80, 110, 110, 254.99, 264, 871, 745, 441],
[43.32, 65.96, 80, 110, 110, 254.99, 263.99, 871, 745, 441],
[43.45, 68.11, 79.99, 109.99, 110, 255, 263.99, 878, 752, 441],
[43.32, 69.85, 80, 110, 110, 254.99, 264, 872, 743, 441],
[43.29, 69.28, 79.99, 109.99, 110, 255, 263.99, 872, 741, 441],
[43.29, 67.59, 80, 110, 110, 255, 263.99, 862, 732, 438],
[43.03, 66.44, 80, 110, 110, 255, 263.99, 867, 739, 438],
[43.48, 64.08, 80, 110, 110, 255, 263.99, 865, 730, 438],
[43.22, 63.8, 80, 110, 110, 254.99, 264, 864, 733, 439],
[43.16, 63.98, 79.99, 109.99, 110, 255, 264, 865, 735, 438],
[43.13, 63.65, 79.99, 109.99, 110, 254.99, 263.99, 870, 736, 440],
[42.93, 63.23, 79.99, 110, 110, 254.99, 264, 866, 734, 439],
[43.03, 64.13, 80, 110, 110, 254.99, 263.99, 856, 730, 438],
[42.77, 63.51, 80, 109.99, 110, 255, 263.99, 865, 733, 439],
[42.2, 65.49, 80, 110, 110, 254.99, 264, 861, 732, 439],
[42.33, 65.47, 80, 110, 110, 254.99, 263.99, 864, 730, 440],
[42.27, 68.5, 79.99, 110, 110, 255, 264, 869, 738, 441],
[42.14, 68.44, 80, 110, 110, 254.99, 264, 864, 730, 440],
[41.95, 67.43, 80, 109.99, 110, 254.99, 264, 869, 740, 440],
[42.01, 67.32, 79.99, 110, 110, 254.99, 264, 867, 738, 441],
[41.92, 64.22, 79.99, 109.99, 109.99, 255, 264.01, 858, 730, 439],
[42.23, 63.76, 80, 110, 110, 255, 264, 859, 733, 439],
[41.95, 65.54, 79.99, 110, 110, 255, 264, 858, 734, 439],
[42.27, 66.02, 80, 110, 109.99, 255, 263.99, 864, 734, 439],
[42.14, 66.24, 79.99, 110, 110, 255, 264, 861, 731, 439],
[42.23, 65.67, 80, 110, 110, 255, 263.77, 857, 726, 438],
[42.23, 65.44, 79.99, 109.99, 110, 255, 264, 871, 739, 441],
[42.36, 66.3, 80, 109.99, 110, 254.99, 263.99, 871, 740, 441],
[42.52, 66.56, 80, 109.99, 110, 255, 263.99, 869, 739, 440],
[42.62, 66.02, 79.99, 110, 109.99, 254.99, 264, 859, 734, 439],
[43.32, 65.21, 79.99, 110, 109.99, 255, 264, 878, 749, 441],
[43.35, 64.31, 80, 109.99, 110, 255, 263.93, 891, 749, 441],
[43.42, 67.2, 80, 110, 110, 255, 263.99, 871, 740, 440],
[43.38, 67.51, 79.99, 109.99, 110, 254.99, 263.99, 869, 741, 441],
[43.58, 67.32, 80, 110, 110, 255, 263.99, 862, 739, 439],
[43.61, 66.52, 79.99, 110, 110, 255, 263.99, 855, 730, 437],
[43.71, 65.92, 80, 110, 110, 255, 264, 851, 726, 437],
[43.8, 65.82, 79.99, 110, 110, 254.99, 264, 859, 732, 439],
[44.59, 66.11, 80, 110, 109.99, 255, 264, 845, 719, 435],
[44.72, 66.53, 79.99, 110, 110, 255, 264, 843, 714, 434],
[44.75, 66.6, 80, 110, 110, 254.99, 264, 843, 715, 435],
[44.75, 66.61, 80, 110, 110, 254.99, 264, 837, 719, 433],
[46.02, 65.17, 79.99, 110, 110, 255, 264, 842, 716, 434],
[46.43, 64.43, 80, 110, 110, 255, 263.99, 841, 715, 433],
[46.36, 65.95, 79.99, 110, 110, 255, 263.99, 858, 726, 437],
[46.46, 65.03, 80, 110, 110, 255, 264, 853, 723, 435],
[47.28, 64.34, 79.99, 109.99, 109.99, 255, 263.99, 869, 737, 440],
[47.45, 63.92, 79.99, 110, 110, 255, 263.99, 867, 730, 439],
[47.56, 63.66, 80, 110, 110, 254.98, 264, 868, 734, 439],
[47.7, 64.77, 79.99, 110, 110, 255, 264, 863, 728, 439],
[47.63, 64.33, 79.99, 110, 110, 255, 263.99, 869, 733, 439],
[47.66, 64.18, 79.99, 110, 110, 254.99, 263.99, 864, 729, 438],
[47.91, 64.06, 79.99, 110, 110, 254.99, 264, 858, 726, 437],
[47.66, 64.19, 80, 110, 110, 254.99, 264, 857, 718, 436],
[47.63, 63.66, 80, 110, 109.99, 255, 264, 873, 736, 439],
[47.59, 63.93, 80, 110, 110, 254.99, 263.99, 865, 730, 439],
[47.56, 63.47, 79.99, 110, 110, 254.99, 263.99, 887, 723, 436],
[47.56, 63.19, 80, 110, 110, 254.99, 263.99, 864, 728, 438],
[47.52, 63.29, 80, 109.99, 110, 255, 264, 860, 726, 437],
[47.66, 62.9, 80, 110, 110, 254.99, 263.99, 864, 726, 438],
[47.73, 61.69, 80, 110, 110, 255, 263.99, 870, 733, 440],
[47.38, 61.8, 79.99, 110, 110, 255, 264, 865, 730, 438],
[47.01, 62.23, 80, 109.99, 110, 255, 264, 870, 731, 440],
[47.11, 63.7, 79.99, 109.99, 110, 255, 263.98, 876, 739, 440],
[47.07, 64.85, 80, 110, 110, 254.99, 264, 872, 736, 440],
[47.07, 64.53, 80, 110, 110, 255, 264, 867, 734, 439],
[47.01, 64.63, 79.99, 110, 110, 255, 264, 867, 731, 439],
[46.12, 64.88, 80, 110, 110, 255, 264, 871, 734, 439],
[46.09, 64.45, 79.99, 110, 110, 254.98, 263.97, 873, 742, 440],
[45.85, 64.29, 79.99, 110, 110, 254.99, 264, 870, 734, 440],
[45.95, 64.75, 80, 110, 110, 254.96, 264.01, 876, 739, 441],
[45.68, 65.47, 79.99, 110, 110, 255, 263.99, 870, 735, 440],
[45.62, 66.71, 79.99, 110, 110, 254.99, 263.99, 872, 743, 440],
[45.45, 65.16, 79.99, 110, 110, 255, 264, 872, 737, 440],
[45.52, 65.16, 79.99, 110, 110, 255.34, 261.22, 875, 745, 441],
[45.82, 65.56, 79.99, 110, 110, 255, 263.99, 880, 750, 441],
[45.68, 64.26, 79.99, 109.99, 110, 255, 264, 871, 740, 439],
[45.78, 64.65, 79.99, 110, 110, 255, 263.99, 874, 738, 440],
[45.65, 64.33, 79.99, 109.99, 110, 254.99, 264, 870, 735, 439],
[45.65, 64.4, 79.99, 109.99, 110, 255.11, 263.52, 859, 726, 437],
[45.62, 65.22, 80, 110, 110, 255, 264, 867, 736, 439],
[45.48, 66.06, 80, 110, 110, 255, 263.99, 867, 733, 439],
[45.48, 66.57, 79.99, 110, 110, 254.61, 264.35, 864, 733, 438],
[45.58, 67.09, 80, 110, 109.99, 254.99, 263.99, 865, 735, 439],
[44.72, 68.01, 79.99, 109.99, 109.99, 255, 264, 878, 744, 440],
[44.69, 67.62, 80, 110, 110, 255, 264, 875, 746, 440],
[44.78, 69.67, 80, 110, 110, 255, 264, 876, 748, 440],
[44.65, 69.14, 79.99, 110, 110, 255, 264, 880, 747, 440],
[44.65, 67.18, 79.99, 110, 110, 255, 263.99, 880, 745, 441],
[44.59, 66.52, 79.99, 110, 110, 255, 263.99, 873, 738, 440],
[44.55, 65.51, 80, 110, 110, 255, 264, 866, 736, 438],
[44.46, 64.11, 80, 109.99, 110, 254.99, 264.01, 871, 737, 440],
[44.42, 64, 79.99, 110, 110, 255, 264, 869, 736, 439],
[44.13, 64.67, 79.99, 110, 110, 255, 263.99, 871, 739, 440],
[44.1, 64.07, 79.99, 110, 110, 254.99, 264.01, 872, 736, 440],
[44.03, 63.59, 80, 110, 110, 255, 263.99, 870, 734, 439],
[43.87, 64.45, 79.99, 110, 110, 255, 263.99, 872, 740, 440],
[43.84, 64.54, 80, 110, 110, 254.99, 264, 870, 739, 440],
[43.84, 64.51, 80, 110, 109.99, 255, 264, 872, 743, 439],
[43.84, 65.95, 79.99, 110, 109.99, 255, 263.99, 858, 727, 436],
[43.8, 65.45, 79.99, 110, 110, 254.99, 263.99, 857, 730, 436],
[43.74, 65.09, 79.99, 110, 110, 254.99, 264, 860, 728, 437],
[43.67, 65.58, 79.99, 110, 110, 255, 264, 847, 719, 434],
[43.58, 64.62, 79.99, 110, 110, 255, 264, 842, 714, 434],
[43.45, 64.47, 80, 110, 110, 255, 264, 860, 730, 437],
[43.35, 65.23, 80, 110, 110, 254.99, 264, 860, 730, 438],
[43.54, 65.45, 79.99, 110, 110, 254.99, 263.99, 879, 748, 441],
[43.29, 64.91, 79.99, 109.99, 110, 255, 263.99, 883, 748, 441],
[43.06, 62.97, 80, 110, 110, 255, 263.99, 866, 731, 439],
[42.01, 63.7, 79.99, 110, 110, 255, 264, 856, 729, 437],
[42.05, 64.13, 80, 110, 110, 254.98, 264, 857, 729, 438],
[42.08, 63.23, 80, 110, 109.99, 255, 264, 863, 737, 439],
[42.14, 64.19, 79.99, 110, 110, 254.99, 263.99, 863, 732, 439],
[42.39, 65.28, 80, 110, 110, 254.99, 263.99, 861, 732, 438],
[42.27, 68.61, 80, 109.99, 110, 254.98, 264, 863, 734, 439],
[42.33, 67.8, 79.99, 109.99, 110, 255, 264, 864, 731, 438],
[42.3, 66.43, 79.99, 109.99, 110, 255, 263.99, 872, 737, 440],
[42.42, 65.99, 79.99, 110, 109.99, 255, 264, 868, 740, 439],
[42.46, 65.19, 80, 109.99, 110, 254.99, 264, 867, 740, 439],
[42.42, 68.05, 79.99, 110, 109.99, 254.99, 263.99, 876, 743, 441],
[42.3, 65.57, 79.99, 110, 110, 255, 264, 870, 736, 440],
[42.23, 65.95, 80, 110, 110, 255.28, 264, 878, 741, 439],
[42.39, 67.43, 79.99, 109.99, 109.99, 255, 263.99, 872, 742, 440],
[41.92, 65.74, 79.99, 110, 110, 254.99, 263.99, 871, 736, 440],
[42.23, 64.62, 79.99, 110, 110, 255.12, 264, 878, 746, 440],
[42.36, 64.19, 80, 110, 110, 254.99, 264, 873, 743, 441],
[42.17, 63.38, 80, 110, 109.99, 254.99, 263.99, 860, 732, 437],
[42.23, 62.05, 79.99, 109.99, 110, 255, 264, 858, 726, 437],
[42.33, 61.63, 80, 110, 109.99, 255, 264, 869, 738, 439],
[42.27, 61.64, 79.99, 110, 110, 256.72, 264.01, 871, 738, 440],
[42.23, 61.49, 79.99, 110, 110, 255, 264, 870, 738, 440],
[42.23, 61.96, 80, 109.99, 110, 255, 264, 859, 730, 438],
[42.39, 64.02, 79.99, 110, 110, 254.99, 264, 845, 719, 434],
[42.33, 64.38, 79.99, 110, 110, 254.99, 263.99, 852, 721, 437],
[42.42, 65.03, 80, 110, 110, 255, 263.99, 859, 729, 438],
[42.27, 65.18, 80, 110, 110, 254.99, 264, 864, 736, 439],
[42.3, 67.53, 79.99, 110, 109.99, 255, 263.99, 865, 735, 439],
[42.3, 68.13, 80, 109.99, 110, 255, 264, 851, 722, 436],
[42.2, 65.2, 79.99, 110, 110, 254.99, 264.01, 856, 724, 437],
[42.23, 63.39, 80, 110, 110, 254.99, 264, 869, 743, 440],
[42.11, 64.38, 79.99, 109.99, 110, 254.99, 264, 871, 737, 441],
[42.23, 64.94, 79.99, 110, 110, 254.99, 264, 874, 744, 440],
[42.17, 64.75, 79.99, 109.99, 110, 254.99, 263.99, 878, 747, 441],
[42.23, 64.51, 80, 110, 110, 254.99, 263.96, 862, 737, 439],
[42.14, 65.74, 80, 110, 109.99, 255, 264, 875, 748, 441],
[42.17, 68.35, 79.99, 110, 109.99, 255, 263.99, 866, 741, 440],
[42.17, 68.92, 79.99, 110, 110, 255, 264, 864, 734, 439],
[42.17, 68.83, 79.99, 109.99, 110, 255, 263.99, 848, 719, 437],
[42.3, 67.45, 79.99, 110, 110, 254.99, 264, 858, 728, 438],
[42.2, 65.04, 80, 110, 110, 255, 263.98, 859, 732, 439],
[42.3, 66.32, 79.99, 110, 110, 254.99, 264, 863, 734, 439],
[42.46, 65.63, 80, 110, 110, 255, 263.99, 858, 732, 439],
[42.58, 65.2, 80, 110, 110, 254.99, 264, 855, 730, 436],
[42.65, 64.76, 80, 110, 110, 255, 264, 858, 729, 437],
[42.9, 66.16, 79.99, 109.99, 110, 255, 263.98, 867, 739, 439],
[42.81, 64.93, 80, 110, 110, 255, 263.99, 861, 732, 439],
[42.87, 63.27, 79.99, 110, 110, 255, 263.99, 853, 729, 437],
[43.51, 64.65, 80, 109.99, 109.99, 255, 264, 862, 733, 438],
[43.35, 65.6, 80, 110, 110, 254.99, 263.99, 861, 737, 439],
[43.45, 66.77, 80, 110, 110, 255, 263.99, 859, 738, 438],
[43.58, 67.05, 80, 110, 110, 254.99, 264, 871, 744, 440],
[43.74, 66.49, 80, 110, 109.99, 255, 263.99, 854, 726, 436],
[43.74, 65.58, 79.99, 110, 110, 255, 264, 847, 728, 436],
[43.93, 65.74, 79.99, 110, 110, 254.99, 264, 857, 728, 438],
[44.13, 64.59, 80, 109.99, 110, 254.99, 264, 862, 738, 438],
[44.59, 64.81, 79.99, 110, 110, 254.99, 263.99, 855, 730, 437],
[44.29, 65.36, 80, 110, 109.99, 254.99, 264, 856, 730, 437],
[45.05, 64.1, 79.99, 110, 110, 254.99, 264, 851, 729, 436],
[45.15, 64.64, 80, 109.99, 110, 254.99, 263.99, 857, 731, 438],
[46.7, 65.07, 80, 110, 109.99, 255, 263.99, 862, 730, 438],
[46.73, 64.4, 79.99, 110, 110, 254.99, 263.99, 865, 737, 439],
[47.98, 63.03, 79.99, 110, 110, 255, 264, 868, 737, 440],
[47.98, 62.08, 79.99, 110, 109.99, 255, 264, 869, 736, 440],
[48.64, 63.83, 79.99, 110, 110, 255, 263.99, 858, 728, 437],
[49.07, 64.13, 80, 110, 110, 255, 263.99, 856, 725, 438],
[49.25, 63, 80, 110, 110, 255, 264, 850, 717, 436],
[49.29, 63.26, 79.99, 110, 110, 254.99, 263.99, 847, 719, 436],
[49.47, 64.56, 80, 109.99, 110, 255, 263.85, 869, 732, 441],
[49.36, 63.76, 80, 109.99, 110, 254.99, 263.99, 855, 720, 437],
[49.54, 64.06, 80, 110, 110, 255, 264, 862, 730, 439],
[49.18, 65.01, 80, 110, 110, 255, 263.95, 878, 725, 438],
[49.14, 64.79, 80, 110, 110, 254.99, 263.99, 854, 725, 438],
[49.11, 64.8, 80, 110, 110, 255, 264, 849, 722, 436],
[49, 64.58, 80, 109.99, 110, 254.99, 264, 854, 722, 438],
[49.04, 65.9, 80, 110, 110, 254.99, 263.99, 857, 728, 438],
[48.96, 65.69, 79.99, 110, 110, 254.99, 263.5, 873, 738, 441],
[48.89, 65.65, 79.99, 110, 110, 255, 264, 860, 730, 439],
[48.79, 65.53, 79.99, 110, 110, 254.99, 263.69, 851, 719, 436],
[48.75, 65.24, 79.99, 110, 109.99, 255, 264, 849, 717, 436],
[48.82, 64.59, 80, 110, 110, 254.99, 263.99, 854, 722, 437],
[48.96, 64.87, 80, 109.99, 109.99, 254.99, 263.71, 871, 735, 440],
[48.79, 64.83, 80, 110, 110, 255, 263.99, 867, 731, 439],
[48.64, 66.15, 79.99, 110, 110, 255, 264, 865, 729, 439],
[48.61, 65.2, 80, 110, 110, 254.99, 264, 864, 731, 440],
[48.72, 64.3, 80, 110, 110, 254.99, 264.12, 871, 732, 441],
[48.43, 64.07, 80, 109.99, 109.99, 254.99, 263.99, 875, 740, 441],
[47.7, 63.31, 79.99, 109.99, 110, 255.01, 263.96, 890, 730, 439],
[47.49, 64.34, 80, 110, 110, 254.99, 264, 878, 742, 441],
[47.42, 66.22, 79.99, 110, 110, 254.99, 264, 870, 733, 439],
[47.14, 67.12, 79.99, 110, 110, 255, 264, 857, 722, 437],
[47.56, 66.03, 79.99, 110, 109.99, 255, 262.59, 864, 718, 436],
[47.25, 65.66, 80, 110, 110, 254.99, 263.99, 866, 733, 438],
[46.19, 65.54, 80, 110, 110, 255, 263.99, 867, 732, 439],
[46.02, 64.92, 79.99, 110, 110, 254.99, 263.99, 852, 724, 435],
[46.02, 64.38, 79.99, 110, 110, 255, 264, 866, 735, 439],
[45.85, 64.71, 79.99, 109.99, 110, 255, 263.99, 883, 744, 442],
[45.95, 65.46, 80, 109.99, 110, 255, 263.99, 874, 739, 440],
[45.75, 65.11, 80, 110, 110, 254.99, 264, 876, 742, 440],
[45.78, 65.61, 79.99, 109.99, 110, 254.99, 263.99, 874, 735, 440],
[45.52, 65.15, 80, 110, 110, 254.99, 263.99, 869, 734, 440],
[45.55, 65.77, 80, 109.99, 110, 254.99, 263.99, 867, 735, 439],
[45.28, 66.76, 79.99, 110, 110, 254.99, 264, 869, 741, 439],
[45.18, 66.7, 79.99, 109.99, 110, 255, 264, 871, 741, 439],
[44.88, 65.22, 80, 110, 110, 254.99, 264, 866, 739, 439],
[44.36, 67.1, 79.99, 110, 110, 255, 264, 866, 738, 439],
[44.49, 65.98, 80, 110, 110, 254.99, 264, 867, 735, 438],
[44.13, 65.59, 79.99, 110, 110, 254.99, 263.99, 870, 738, 440],
[44.1, 64.67, 80, 109.99, 109.99, 255, 264, 867, 738, 439],
[44.16, 65, 79.99, 110, 109.99, 254.99, 264, 878, 745, 440],
[44.39, 64.5, 79.99, 110, 109.99, 254.99, 263.99, 882, 747, 441],
[44.23, 65.42, 80, 110, 109.99, 255, 264, 870, 740, 439],
[44.29, 64.6, 80, 110, 110, 255, 263.99, 867, 737, 439],
[44.19, 64.65, 80, 109.99, 110, 254.99, 264, 873, 743, 440],
[44.06, 66.46, 79.99, 110, 110, 254.99, 263.99, 870, 741, 439],
[44.1, 67.23, 79.99, 110, 110, 254.99, 264, 869, 739, 439],
[43.97, 66.6, 79.99, 110, 110, 254.99, 263.99, 866, 736, 439],
[43.64, 68.16, 79.99, 110, 110, 254.99, 263.99, 873, 741, 440],
[43.67, 66.59, 80, 110, 110, 254.99, 263.99, 874, 739, 440],
[43.48, 65.67, 80, 110, 110, 254.99, 263.99, 871, 742, 439],
[43.32, 66.94, 79.99, 110, 109.99, 255, 263.99, 869, 745, 440],
[42.87, 66.41, 79.99, 109.99, 110, 255, 263.99, 856, 733, 437],
[42.84, 67.36, 80, 110, 109.99, 254.99, 264, 870, 738, 439],
[42.81, 68.03, 80, 110, 110, 255, 263.99, 859, 732, 437],
[42.81, 66.85, 79.99, 109.99, 110, 255, 263.99, 863, 733, 438],
[43.03, 64.99, 80, 110, 110, 255, 263.99, 855, 725, 437],
[42.74, 64.28, 79.99, 110, 110, 254.99, 263.99, 856, 732, 438],
[42.71, 63.78, 80, 110, 110, 254.99, 263.99, 875, 728, 437],
[42.71, 62.68, 79.99, 110, 110, 254.99, 263.99, 868, 739, 440],
[42.74, 62.61, 80, 109.99, 110, 254.99, 263.98, 874, 741, 440],
[42.68, 62.5, 79.99, 110, 109.99, 254.99, 263.99, 863, 739, 439],
[42.81, 62.45, 80, 110, 109.99, 254.99, 263.99, 870, 739, 440],
[42.68, 65.56, 80, 110, 110, 255, 263.99, 877, 747, 441],
[42.74, 66.76, 80, 110, 110, 254.99, 264, 877, 747, 440],
[42.68, 65.87, 80.01, 110, 110, 255, 263.98, 879, 752, 441],
[42.77, 67.88, 79.99, 110, 109.99, 254.99, 263.98, 896, 743, 441],
[42.81, 67.92, 80, 110, 110, 255, 263.99, 876, 746, 440],
[42.62, 67.49, 79.99, 110, 109.99, 254.99, 263.99, 872, 742, 440],
[42.74, 66.7, 80, 110, 110, 255, 263.99, 865, 736, 439],
[42.87, 64.94, 80, 110, 110, 255, 263.99, 877, 744, 441],
[42.62, 66.72, 80, 110, 110, 255, 264, 861, 736, 439],
[42.71, 66.18, 80, 110, 110, 254.99, 263.98, 861, 736, 438],
[42.77, 68, 79.99, 110, 109.99, 254.99, 263.99, 858, 735, 438],
[42.71, 67.59, 80, 110, 110, 255, 263.99, 858, 731, 437],
[42.81, 66.27, 80, 110, 110, 255, 263.99, 854, 729, 436],
[42.87, 65.34, 80, 110, 110, 254.99, 264, 856, 733, 437],
[42.52, 68.67, 79.99, 109.99, 110, 254.99, 263.99, 857, 726, 437],
[42.58, 68.42, 80, 110, 110, 254.98, 263.95, 868, 736, 439],
[42.52, 67.16, 80, 110, 110, 255, 264, 848, 728, 436],
[41.58, 64.22, 80, 110, 110, 254.99, 264, 871, 739, 441],
[41.73, 66.96, 80, 110, 110, 254.99, 263.99, 858, 730, 438],
[41.95, 65.15, 80, 110, 110, 254.99, 264, 862, 734, 440],
[41.89, 63.04, 80, 109.99, 110, 254.99, 263.99, 868, 742, 439],
[41.89, 64.12, 79.99, 109.99, 109.99, 254.99, 264, 859, 732, 439],
[42.14, 64.53, 80, 110, 110, 254.99, 264, 850, 727, 437],
[42.05, 64.56, 79.99, 110, 110, 254.99, 264, 856, 730, 438],
[42.33, 63.71, 80, 110, 110, 254.99, 263.99, 865, 739, 440],
[42.17, 62.58, 80, 110, 110, 255, 263.99, 866, 739, 440],
[42.3, 65.18, 79.99, 110, 110, 254.99, 264, 864, 733, 439],
[42.36, 65.99, 79.99, 110, 109.99, 255, 264, 866, 741, 440],
[42.52, 65.4, 80, 109.99, 110, 254.99, 263.99, 868, 742, 440],
[42.52, 65.31, 80, 110, 110, 254.99, 263.99, 871, 740, 440],
[42.55, 64.89, 80, 110, 110, 254.99, 264, 871, 743, 441],
[42.65, 64.52, 79.99, 110, 110, 254.99, 263.99, 868, 744, 441],
[42.81, 64.43, 79.99, 110, 110, 255, 264, 865, 740, 439],
[42.84, 63.38, 79.99, 110, 110, 254.99, 264, 857, 729, 437],
[42.9, 64.25, 79.99, 110, 110, 255, 263.99, 853, 731, 437],
[43.16, 63.18, 79.99, 109.99, 110, 254.99, 263.99, 841, 716, 434],
[43.19, 63.4, 80, 110, 109.99, 255, 264, 855, 729, 437],
[43.58, 63.5, 80, 109.99, 109.99, 255, 264, 856, 730, 436],
[43.42, 65.43, 80, 109.99, 110, 255, 263.99, 844, 722, 435],
[43.61, 66.09, 79.99, 110, 110, 254.99, 263.99, 850, 729, 436],
[43.61, 65, 79.99, 110, 109.99, 254.99, 263.99, 851, 722, 435],
[43.8, 63.18, 79.99, 110, 110, 255, 264, 854, 723, 437],
[44.03, 63.13, 80, 110, 110, 254.99, 263.99, 848, 723, 434],
[43.93, 64.07, 80, 110, 110, 255, 264, 843, 723, 435],
[44.1, 64.2, 80, 109.99, 110, 254.99, 264, 869, 739, 440],
[44.16, 62.76, 80, 109.99, 110, 254.99, 264, 857, 728, 437],
[47.94, 63.95, 79.99, 109.99, 110, 254.97, 264, 858, 730, 438],
[48.12, 63.64, 80, 109.99, 110, 255, 264, 864, 729, 439],
[48.68, 65.69, 80, 110, 109.99, 255, 263.99, 869, 735, 440],
[48.64, 66.25, 79.99, 110, 110, 254.99, 263.99, 854, 724, 438],
[48.75, 65.91, 80, 110, 110, 255, 263.99, 845, 721, 435],
[48.61, 64.11, 80, 110, 110, 255, 264, 865, 731, 439],
[48.54, 64.93, 80, 110, 110, 255, 264, 857, 726, 438],
[48.54, 65.28, 80, 110, 110, 253.96, 264.01, 865, 730, 440],
[48.72, 64.23, 79.99, 110, 110, 255, 264, 869, 738, 441],
[48.33, 65.59, 80, 110, 109.99, 255, 263.99, 864, 730, 439],
[48.33, 65.32, 79.99, 110, 110, 255, 264, 874, 741, 441],
[48.08, 65.81, 80, 109.99, 109.99, 254.99, 263.99, 869, 739, 441],
[47.94, 64.93, 79.99, 110, 110, 255, 264, 862, 725, 438],
[47.8, 64.09, 79.99, 109.99, 110, 254.99, 264, 871, 734, 440],
[47.91, 63.61, 79.99, 109.99, 110, 255, 264, 857, 726, 437],
[47.73, 64.26, 79.99, 110, 110, 255, 264, 863, 732, 439],
[47.94, 64.77, 79.99, 110, 109.99, 255, 264, 865, 734, 439],
[47.73, 64.66, 80, 110, 110, 254.99, 263.99, 858, 725, 437],
[47.52, 67.49, 80, 110, 109.99, 254.99, 264, 846, 717, 434],
[47.01, 67.08, 80, 110, 110, 255, 264, 875, 741, 440],
[46.87, 66.84, 79.99, 110, 110, 255, 264, 878, 743, 441],
[46.49, 63.38, 79.99, 109.99, 110, 254.99, 264, 877, 743, 441],
[46.56, 64.19, 80, 109.99, 110, 254.99, 263.99, 875, 745, 441],
[46.46, 66.56, 80, 110, 110, 255, 263.99, 876, 744, 440],
[46.46, 66.65, 80, 110, 110, 255, 264, 872, 736, 440],
[46.39, 65.89, 80, 110, 110, 254.99, 264, 879, 745, 441],
[46.09, 69.13, 79.99, 109.99, 110, 254.99, 264, 870, 741, 439],
[46.19, 68.83, 79.99, 110, 110, 255, 264, 873, 742, 439],
[46.19, 69.23, 80, 110, 110, 254.99, 263.99, 871, 740, 440],
[46.15, 66.52, 80, 110, 110, 255, 263.99, 872, 744, 440],
[45.88, 65.37, 79.99, 110, 110, 254.99, 264, 874, 742, 440],
[45.92, 63.16, 80, 110, 110, 255, 263.98, 867, 735, 438],
[45.72, 63.29, 80, 109.99, 110, 255, 263.99, 862, 728, 437],
[45.72, 63.45, 80, 109.99, 110, 255, 263.99, 861, 728, 437],
[45.62, 66.23, 80, 110, 109.99, 255, 264, 870, 738, 439],
[45.31, 64.81, 79.99, 109.99, 109.99, 255, 263.99, 878, 747, 441],
[45.28, 65.04, 79.99, 110, 110, 254.99, 263.99, 853, 723, 435],
[45.45, 65.43, 79.99, 110, 109.99, 255, 263.99, 846, 720, 434],
[45.31, 65.29, 79.99, 109.99, 109.99, 254.99, 264, 852, 722, 434],
[45.15, 64.28, 79.99, 110, 110, 254.99, 264, 876, 744, 440],
[45.12, 63.54, 79.99, 110, 110, 255, 264, 875, 742, 440],
[45.08, 64.2, 80, 110, 110, 254.99, 263.99, 873, 743, 440],
[45.12, 65.93, 79.99, 110, 110, 254.99, 264, 870, 741, 438],
[45.08, 66.33, 79.99, 110, 110, 254.99, 264, 877, 743, 440],
[44.72, 64.91, 80, 110, 110, 255, 264, 849, 724, 435],
[44.52, 65.15, 79.99, 110, 109.99, 255, 263.99, 854, 728, 436],
[44.49, 65.18, 80, 110, 110, 255, 264, 863, 735, 438],
[44.46, 64.03, 80, 110, 110, 255, 263.99, 861, 729, 437],
[44.65, 63.07, 80, 109.99, 110, 254.99, 263.99, 861, 737, 438],
[44.42, 63.64, 80, 109.99, 110, 255, 264, 868, 740, 439],
[44.42, 62.75, 80, 110, 110, 254.99, 264, 858, 731, 437],
[44.42, 62.76, 80, 110, 110, 255, 263.99, 859, 731, 437],
[44.42, 64.15, 79.99, 110, 110, 255, 263.99, 864, 734, 438],
[44.52, 64.32, 80, 110, 110, 254.99, 264, 856, 731, 437],
[44.46, 66.19, 79.99, 110, 110, 255, 263.98, 874, 745, 440],
[44.55, 66.38, 80.01, 110, 110, 255, 263.99, 873, 743, 440],
[44.46, 66.64, 79.99, 110, 109.99, 255, 264, 870, 736, 439],
[44.32, 66.42, 79.99, 110, 110, 255, 263.97, 882, 742, 440],
[44.65, 65.28, 80, 110, 110, 255, 264, 874, 741, 440],
[44.52, 65.39, 79.99, 110, 110, 255, 264, 870, 741, 439],
[44.55, 64.61, 79.99, 109.99, 110, 254.99, 264, 865, 735, 438],
[44.52, 63.93, 79.99, 110, 110, 254.99, 264, 861, 731, 438],
[44.59, 63.94, 80, 110, 110, 255, 264, 866, 730, 438],
[44.52, 64.96, 80, 110, 110, 255, 264, 858, 731, 438],
[44.75, 66.03, 79.99, 110, 110, 255, 263.99, 859, 732, 437],
[44.36, 63.64, 79.99, 110, 109.99, 254.99, 264, 852, 724, 436],
[44.46, 64.19, 79.99, 110, 110, 254.99, 264, 852, 724, 436],
[44.52, 65.58, 80, 109.99, 110, 255, 263.37, 850, 720, 436],
[44.42, 65.87, 80, 109.99, 110, 254.99, 263.99, 847, 724, 435],
[44.72, 66.33, 79.99, 110, 110, 254.99, 263.99, 859, 732, 437],
[44.49, 66.97, 80, 110, 110, 255, 264, 845, 721, 435],
[44.46, 65.58, 79.99, 110, 110, 255, 263.99, 855, 728, 436],
[44.46, 65.45, 80, 110, 110, 254.99, 263.99, 847, 723, 435],
[44.42, 65.27, 80, 109.99, 110, 255, 264, 846, 723, 435],
[44.46, 64.33, 79.99, 110, 110, 254.99, 264, 848, 722, 435],
[44.46, 64.5, 80, 110, 110, 254.99, 264.11, 860, 730, 437],
[44.39, 64.89, 79.99, 109.99, 110, 255, 263.99, 870, 735, 439],
[44.39, 65.35, 80, 109.99, 110, 254.99, 264, 854, 731, 435],
[44.42, 64.54, 79.99, 110, 109.99, 255, 264, 845, 722, 434],
[44.39, 64.37, 80, 110, 110, 255, 264, 849, 723, 435],
[44.36, 63.9, 79.99, 110, 110, 255, 263.05, 855, 722, 435],
[44.36, 64.41, 80, 110, 110, 254.99, 263.99, 854, 723, 436],
[44.39, 65.17, 79.99, 110, 110, 255, 264, 858, 731, 437],
[44.59, 64.92, 79.99, 110, 109.99, 255, 264, 862, 731, 438],
[44.46, 63.33, 80, 110, 110, 255, 264, 858, 728, 437],
[44.39, 64.08, 80, 110, 109.99, 254.99, 261.72, 879, 740, 440],
[44.49, 64.41, 79.99, 109.99, 110, 254.99, 264, 869, 737, 439],
[44.49, 63.9, 80, 110, 110, 254.99, 263.99, 880, 747, 441],
[44.69, 63.9, 80, 109.99, 110, 254.99, 263.99, 877, 743, 440],
[44.49, 64.91, 80, 110, 110, 255, 261.81, 891, 739, 439],
[44.59, 64.59, 79.99, 110, 110, 255, 263.99, 870, 738, 438],
[44.59, 64.77, 80, 110, 110, 254.99, 263.99, 860, 729, 437],
[44.78, 64.43, 79.99, 110, 110, 254.99, 263.99, 866, 734, 438],
[44.69, 64.44, 80, 110, 110, 254.99, 263.99, 851, 725, 435],
[44.65, 66.99, 79.99, 110, 110, 255, 264, 845, 718, 434],
[44.59, 66.44, 80, 110, 110, 255, 264, 853, 723, 435],
[44.69, 66.05, 80, 110, 110, 254.99, 264, 855, 727, 436],
[44.69, 65.89, 80, 110, 110, 254.99, 264, 852, 724, 435],
[44.65, 64.6, 80, 110, 110, 254.99, 263.99, 863, 733, 437],
[44.69, 65.32, 80, 110, 110, 254.99, 264, 860, 732, 437],
[44.72, 64.43, 80, 110, 110, 255, 264, 846, 723, 434],
[44.98, 65.56, 80, 110, 110, 255, 264, 855, 725, 436],
[44.62, 66, 80, 110, 110, 255, 263.99, 846, 724, 434],
[44.72, 65.41, 80, 110, 109.99, 255, 264, 842, 720, 433],
[44.75, 66.08, 79.99, 110, 110, 254.99, 264, 847, 722, 434],
[44.72, 65.84, 80, 109.99, 109.99, 255, 263.99, 850, 724, 435],
[44.69, 64.56, 80, 110, 110, 254.99, 263.99, 872, 743, 440],
[44.65, 64.08, 80, 110, 110, 255, 263.99, 866, 740, 439],
[44.75, 64.78, 80, 110, 109.99, 255, 264, 869, 742, 439],
[44.78, 65.27, 79.99, 110, 109.99, 255, 264, 882, 751, 441],
[44.78, 64.45, 80, 110, 110, 254.99, 263.99, 877, 742, 440],
[44.78, 64.8, 79.99, 110, 110, 254.99, 264, 875, 744, 440],
[44.85, 63.65, 79.99, 110, 110, 254.99, 264, 877, 745, 441],
[44.98, 63.55, 80, 110, 110, 255, 263.99, 873, 744, 440],
[44.85, 64.05, 80, 109.99, 109.99, 254.99, 264, 867, 738, 440],
[45.12, 63.84, 79.99, 110, 110, 255, 263.99, 871, 742, 439],
[44.95, 63.13, 80, 110, 109.99, 255, 263.99, 876, 744, 440],
[45.12, 63.13, 80, 110, 109.99, 254.99, 263.99, 870, 743, 440],
[45.22, 63.03, 79.99, 110, 110, 255, 264, 868, 741, 439],
[45.15, 63.18, 79.99, 110, 110, 255, 263.99, 869, 734, 439],
[45.12, 63.96, 80, 110, 109.99, 255, 264, 866, 734, 439],
[45.35, 64.74, 80, 110, 109.99, 255, 264, 868, 736, 439],
[45.25, 65.4, 80, 110, 110, 254.99, 263.99, 859, 731, 438],
[45.52, 64.22, 79.99, 110, 109.99, 254.99, 264, 863, 734, 439],
[45.55, 63.32, 79.99, 109.99, 110, 254.99, 264, 856, 731, 437],
[45.62, 63.73, 80, 110, 110, 254.99, 264, 853, 728, 437],
[45.35, 64.83, 79.99, 110, 109.99, 255, 263.99, 850, 726, 436],
[49.86, 63.71, 80, 109.99, 110, 255, 263.99, 861, 730, 440],
[50.37, 66.84, 79.99, 110, 110, 255, 263.99, 866, 734, 440],
[50.41, 64.99, 80, 110, 110, 255, 263.99, 909, 730, 439],
[50.52, 64.95, 80, 109.99, 110, 255, 264, 849, 719, 437],
[50.56, 63.94, 80, 110, 109.99, 255, 263.99, 906, 717, 396],
[50.41, 63.61, 79.99, 110, 109.99, 255.01, 264, 918, 714, 400],
[50.37, 62.64, 80, 109.99, 110, 255, 263.99, 921, 720, 406],
[50.37, 62.54, 80, 110, 110, 254.99, 264, 895, 699, 390],
[50.3, 61.74, 80, 110, 110, 255, 263.99, 828, 654, 435],
[50.19, 63.25, 80, 109.99, 110, 255, 264.01, 843, 662, 437],
[50.12, 62.58, 80, 110, 110, 255, 264, 834, 654, 436],
[49.68, 66.12, 80, 109.99, 110, 254.99, 264, 846, 668, 438],
[49.5, 65.51, 79.99, 110, 110, 255, 264, 820, 650, 433],
[49.18, 63.73, 80, 110, 109.99, 255, 263.99, 828, 652, 434],
[49.29, 64.14, 80, 109.99, 109.99, 254.99, 264, 833, 659, 435],
[49.21, 65.23, 79.99, 110, 109.99, 255.03, 263.49, 829, 654, 435],
[49.18, 64.74, 80, 110, 110, 255, 264, 829, 657, 435],
[49, 66.39, 80, 110, 110, 255, 263.99, 838, 661, 436],
[48.86, 68.39, 79.99, 110, 110, 254.99, 264, 831, 658, 435],
[48.72, 67.34, 80, 110, 109.99, 255, 264, 827, 654, 434],
[48.33, 65.5, 80, 110, 110, 254.99, 263.99, 844, 667, 437],
[48.22, 66.78, 79.99, 110, 110, 255, 263.99, 847, 670, 437],
[47.14, 66.08, 80, 110, 110, 255, 263.99, 836, 660, 434],
[47.14, 65.27, 79.99, 109.99, 110, 254.99, 264, 828, 658, 434],
[46.97, 67.12, 79.99, 110, 110, 255, 264, 854, 674, 438],
[46.97, 67.43, 79.99, 110, 109.99, 254.99, 263.99, 860, 683, 440],
[46.8, 69.4, 79.99, 110, 110, 255, 264, 840, 666, 436],
[46.66, 67.85, 79.99, 110, 109.99, 254.99, 263.99, 852, 674, 438],
[46.56, 65.08, 80, 110, 110, 255, 264, 850, 671, 436],
[46.53, 65.91, 80, 110, 109.99, 254.99, 264, 830, 657, 434],
[46.53, 66.5, 80, 109.99, 109.99, 255, 263.99, 837, 664, 435],
[46.53, 66.53, 80, 110, 109.99, 254.99, 264, 821, 646, 432],
[46.53, 67.59, 79.99, 110, 110, 255, 264, 845, 671, 437],
[46.49, 66.71, 80, 109.99, 110, 254.99, 264, 846, 672, 436],
[46.8, 66.95, 80, 110, 110, 255, 264, 843, 666, 436],
[46.46, 69, 79.99, 109.99, 109.99, 254.99, 264, 849, 674, 437],
[46.73, 68.99, 79.99, 110, 110, 254.99, 263.99, 852, 672, 438],
[46.6, 66.39, 79.99, 110, 110, 255, 263.99, 860, 682, 438],
[46.63, 66.39, 80, 110, 110, 254.99, 264, 849, 670, 437],
[46.56, 66.88, 80, 110, 110, 254.99, 264, 838, 664, 436],
[46.8, 66.98, 79.99, 110, 110, 255, 264, 838, 663, 435],
[46.63, 67.2, 80, 109.99, 110, 254.99, 263.99, 833, 658, 434],
[46.83, 67.39, 79.99, 110, 110, 255, 264, 839, 665, 436],
[46.66, 66.03, 80, 110, 110, 255, 264, 846, 671, 436],
[46.66, 68.65, 80, 110, 110, 254.99, 264, 848, 671, 437],
[46.7, 68.08, 80, 110, 110, 254.99, 264, 827, 657, 433],
[46.63, 68.5, 79.99, 110, 110, 255, 264, 836, 663, 434],
[46.73, 66.87, 80, 110, 110, 255, 263.99, 838, 664, 435],
[46.63, 66.2, 79.99, 110, 110, 254.99, 264, 855, 677, 439],
[46.63, 65.64, 80, 110, 110, 254.99, 263.99, 860, 679, 439],
[46.66, 65.7, 79.99, 110, 109.99, 255.01, 264, 852, 677, 438],
[46.8, 65.6, 80, 110, 109.99, 254.99, 264, 842, 672, 437],
[46.15, 65.28, 79.99, 110, 110, 254.99, 264, 859, 678, 439],
[46.6, 64.29, 80, 110, 109.99, 254.98, 264, 863, 685, 440],
[46.63, 63.6, 80, 110, 110, 254.99, 264, 864, 685, 440],
[46.66, 64.56, 79.99, 110, 110, 255, 264, 863, 684, 440],
[46.73, 64.44, 80, 110, 110, 255, 264, 852, 675, 438],
[46.87, 65.81, 80, 110, 110, 254.98, 264, 859, 680, 440],
[46.9, 66.24, 80, 110, 109.99, 254.99, 264, 861, 681, 440],
[46.97, 66.8, 80, 109.99, 110, 255, 264, 843, 671, 437],
[47.11, 68.21, 80, 110, 110, 254.99, 264, 836, 664, 435],
[47.14, 65.36, 79.99, 110, 110, 254.97, 263.99, 829, 653, 434],
[47.35, 64.25, 80, 110, 110, 255, 263.99, 830, 657, 433],
[47.07, 65.07, 79.99, 110, 110, 255, 263.99, 831, 658, 433],
[47.04, 65.38, 80, 110, 110, 254.99, 264, 835, 663, 435],
[47.11, 65.78, 79.99, 110, 109.99, 254.99, 264, 835, 660, 435],
[47.35, 65.27, 80, 110, 109.99, 255, 264, 850, 673, 437],
[47.07, 68.36, 79.99, 109.99, 110, 254.99, 264, 852, 673, 439],
[47.21, 67.5, 80, 110, 110, 254.98, 264.01, 848, 671, 437],
[47.07, 66.82, 79.99, 110, 109.99, 255, 264, 839, 663, 436],
[47.01, 66.77, 80, 109.99, 110, 254.99, 263.99, 826, 653, 433],
[47.11, 67.35, 79.99, 110, 110, 255, 263.99, 831, 661, 434],
[47.21, 66.33, 80, 110, 110, 254.97, 263.99, 873, 690, 441],
[47.11, 66.11, 79.99, 110, 110, 255, 263.99, 864, 683, 440],
[47.18, 66.52, 80, 110, 110, 255, 264, 852, 679, 438],
[47.07, 68.75, 79.99, 109.99, 110, 255, 263.99, 843, 666, 436],
[47.21, 67.43, 80, 110, 110, 254.99, 264, 845, 671, 437],
[47.32, 67, 80, 109.99, 109.99, 255, 264, 855, 678, 439],
[47.35, 67.67, 79.99, 110, 110, 255, 264, 848, 674, 438],
[47.11, 67.81, 80, 110, 109.99, 255, 264, 839, 665, 436],
[46.97, 68.04, 79.99, 110, 110, 254.99, 263.99, 852, 677, 438],
[46.77, 65.92, 80, 110, 109.99, 254.95, 264, 856, 677, 439],
[46.77, 66.45, 79.99, 110, 110, 255, 264, 845, 669, 437],
[46.87, 66.51, 80, 109.99, 110, 254.99, 264, 856, 679, 439],
[46.73, 66.94, 80, 109.99, 110, 254.99, 264, 863, 682, 440],
[46.77, 65.75, 80, 110, 110, 255, 263.99, 855, 675, 438],
[46.83, 66.76, 79.99, 110, 110, 255, 263.99, 855, 677, 438],
[46.87, 67.61, 79.99, 110, 110, 254.99, 264, 847, 673, 437],
[46.83, 66.99, 80, 110, 110, 255.09, 264, 836, 659, 434],
[46.9, 66.13, 79.99, 110, 110, 254.99, 264, 832, 663, 434],
[46.77, 65.65, 79.99, 110, 110, 254.99, 263.99, 854, 676, 438],
[46.63, 65.8, 79.99, 110, 109.99, 255, 263.99, 850, 673, 437],
[46.66, 65.92, 79.99, 110, 110, 254.98, 263.98, 846, 667, 437],
[46.7, 66.89, 79.99, 110, 110, 255, 264, 835, 663, 435],
[46.83, 67.85, 80, 110, 110, 255.02, 263.99, 841, 665, 436],
[46.7, 65.66, 79.99, 110, 110, 255, 263.99, 851, 672, 438],
[46.73, 65.48, 80, 110, 109.99, 255, 263.99, 859, 683, 440],
[46.9, 64.83, 80, 110, 109.99, 254.99, 264, 863, 683, 441],
[46.9, 65.29, 80, 110, 110, 255, 264, 865, 685, 440],
[46.9, 65.82, 80, 110, 110, 254.99, 263.99, 864, 687, 440],
[46.73, 68.53, 79.99, 110, 109.99, 255, 264, 865, 683, 440],
[46.66, 67.16, 79.99, 110, 110, 255.24, 264, 858, 673, 437],
[46.53, 66.94, 80, 110, 110, 255, 263.99, 854, 673, 438],
[46.53, 66.34, 79.99, 110, 109.99, 255, 263.99, 855, 673, 439],
[46.43, 66.19, 79.99, 110, 110, 254.99, 264, 853, 677, 438],
[46.53, 65.52, 80, 110, 110, 255, 264, 834, 658, 434],
[46.43, 64.06, 80, 110, 109.99, 255, 263.99, 845, 671, 438],
[46.36, 64.39, 79.99, 109.99, 109.99, 255, 264, 844, 670, 436],
[46.43, 64.54, 80, 109.99, 110, 255, 264, 834, 659, 434],
[46.19, 66, 80, 110, 110, 255, 264, 834, 664, 435],
[46.46, 66.5, 79.99, 110, 110, 255.11, 264, 840, 659, 434],
[46.39, 66.53, 79.99, 110, 110, 255, 263.99, 851, 675, 437],
[46.6, 63.75, 79.99, 110, 110, 255.09, 264, 862, 673, 438],
[46.49, 63.61, 79.99, 110, 110, 254.99, 264, 845, 668, 436],
[46.43, 64.72, 79.99, 110, 109.99, 254.99, 263.99, 848, 672, 438],
[46.66, 64.13, 80, 109.99, 110, 254.99, 263.99, 853, 681, 439],
[46.49, 64.88, 79.99, 110, 109.99, 255, 264, 863, 680, 439],
[46.6, 65.5, 79.99, 110, 110, 255.43, 263.98, 876, 679, 439],
[46.53, 65.32, 80, 110, 110, 255, 263.99, 860, 682, 440],
[46.66, 65.61, 79.99, 110, 110, 255, 264, 861, 683, 440],
[46.6, 65.71, 79.99, 110, 110, 255, 263.99, 854, 674, 439],
[46.56, 65.17, 80, 109.99, 109.99, 255, 263.99, 839, 664, 435],
[46.6, 64.13, 80, 110, 109.99, 254.99, 264, 856, 676, 439],
[46.53, 63.81, 80, 110, 110, 254.99, 264, 850, 674, 437],
[46.56, 66.26, 79.99, 110, 109.99, 255, 264, 859, 679, 439],
[46.56, 66.26, 80, 110, 109.99, 254.99, 264, 854, 678, 438],
[46.56, 66.39, 79.99, 110, 110, 254.99, 263.99, 847, 673, 437],
[46.83, 67.63, 79.99, 110, 110, 254.99, 263.99, 844, 669, 437],
[46.73, 66.96, 79.99, 109.99, 110, 255, 263.99, 841, 664, 436],
[46.8, 65.5, 80, 110, 110, 255, 263.99, 830, 657, 433],
[46.87, 65.64, 79.99, 110, 110, 255, 264, 830, 656, 434],
[47.11, 65.53, 80, 110, 110, 254.99, 264, 853, 678, 438],
[46.94, 64.51, 80, 109.99, 110, 254.99, 263.99, 856, 675, 439],
[47.18, 63.89, 80, 109.99, 110, 255, 264, 855, 681, 439],
[47.04, 63.43, 79.99, 110, 109.99, 255, 263.99, 853, 674, 438],
[47.21, 65.45, 79.99, 110, 110, 254.99, 263.99, 835, 657, 435],
[47.45, 64.9, 80, 109.99, 109.99, 255, 263.99, 833, 662, 435],
[47.38, 64.78, 80, 109.99, 110, 255, 263.99, 833, 655, 435],
[47.42, 64.17, 80, 110, 109.99, 255, 263.99, 855, 671, 438],
[47.49, 63.42, 80, 110, 110, 254.99, 264, 855, 676, 439],
[47.63, 63.45, 80, 109.99, 109.99, 255, 264, 852, 672, 438],
[47.66, 63.44, 79.99, 109.99, 110, 254.99, 263.99, 859, 679, 440],
[47.7, 63.22, 80, 110, 109.99, 254.99, 264, 861, 676, 439],
[47.98, 65.88, 79.99, 109.99, 110, 255, 263.99, 851, 672, 438],
[48.29, 65.56, 80, 110, 110, 255, 264, 872, 686, 442],
[48.08, 66.15, 80, 110, 110, 254.99, 263.99, 866, 683, 441],
[48.26, 69, 80, 109.99, 109.99, 255, 264, 861, 679, 439],
[48.15, 68.11, 79.99, 109.99, 110, 255, 264, 864, 678, 439],
[48.19, 68.17, 80, 110, 110, 255, 264, 856, 680, 439],
[48.19, 68.2, 79.99, 110, 110, 254.99, 264.01, 845, 665, 436],
[48.05, 66.45, 80, 110, 110, 255, 263.99, 854, 672, 438],
[47.94, 65.81, 79.99, 109.99, 109.99, 254.99, 263.99, 866, 681, 439],
[47.87, 66.01, 80, 110, 109.99, 255, 264, 856, 673, 438],
[47.77, 65.56, 79.99, 110, 110, 255, 264, 842, 670, 436],
[47.84, 66.9, 79.99, 110, 110, 255, 263.99, 840, 667, 435],
[47.56, 67.84, 79.99, 109.99, 110, 254.99, 263.99, 844, 664, 436],
[47.56, 67.54, 80, 109.99, 110, 254.99, 264, 838, 663, 435],
[47.32, 67.24, 80, 109.99, 109.99, 254.99, 264, 852, 669, 438],
[47.28, 66.42, 79.99, 110, 110, 254.99, 264, 857, 675, 439],
[47.25, 64.46, 79.99, 110, 110, 255, 264, 851, 670, 437],
[47.07, 63.68, 79.99, 110, 110, 254.99, 263.99, 846, 667, 436],
[47.35, 62.58, 79.99, 110, 110, 254.99, 263.99, 849, 669, 437],
[47.11, 63.1, 79.99, 110, 110, 255, 263.99, 845, 669, 436],
[47.11, 64.37, 80, 110, 109.99, 255, 264, 854, 674, 438],
[47.14, 64.17, 80, 110, 109.99, 255, 264, 854, 673, 438],
[47.32, 66.49, 79.99, 110, 109.99, 255, 264, 863, 683, 439],
[47.14, 66.47, 80, 110, 110, 255, 264, 862, 682, 439],
[47.25, 66.29, 80, 110, 110, 254.99, 264, 849, 673, 438],
[47.56, 68.52, 80, 110, 110, 254.99, 264, 823, 651, 432],
[47.73, 66.59, 80, 110, 110, 255, 263.99, 871, 686, 441],
[47.59, 67.99, 80, 109.99, 110, 255, 264, 857, 678, 439],
[47.87, 66.96, 79.99, 110, 110, 255, 264, 849, 673, 437],
[47.63, 66.91, 80, 110, 110, 254.99, 264, 850, 672, 437],
[47.63, 66.38, 79.99, 110, 110, 254.99, 264, 853, 672, 437],
[47.56, 66.08, 80, 110, 110, 254.99, 263.99, 851, 672, 437],
[47.56, 67.46, 79.99, 109.99, 110, 255, 263.99, 850, 672, 437],
[47.7, 67.59, 80, 110, 109.99, 255, 263.99, 863, 683, 440],
[47.49, 66.55, 79.99, 110, 110, 255, 263.99, 860, 683, 439],
[47.73, 66.87, 80, 110, 109.99, 254.99, 264, 866, 684, 440],
[47.49, 67.48, 79.99, 110, 109.99, 255, 264, 866, 685, 440],
[47.38, 67.15, 79.99, 110, 110, 254.99, 263.99, 859, 677, 440],
[47.52, 66.42, 79.99, 110, 110, 254.99, 263.99, 837, 661, 434],
[47.56, 65.6, 80, 109.99, 110, 255, 264, 853, 673, 438],
[47.59, 65.8, 79.99, 110, 109.99, 255, 263.99, 853, 674, 437],
[47.52, 65.48, 79.99, 110, 110, 255, 263.99, 841, 667, 436],
[47.56, 65.2, 79.99, 109.99, 110, 255, 263.99, 836, 660, 434],
[47.59, 65.93, 80, 109.99, 110, 254.99, 264, 831, 656, 433],
[47.59, 67.1, 79.99, 110, 110, 254.99, 263.99, 851, 673, 436],
[47.63, 67.19, 79.99, 110, 110, 254.99, 264, 865, 683, 439],
[47.66, 67.18, 80, 110, 109.99, 255, 264, 853, 673, 437],
[47.73, 66.23, 80, 109.99, 110, 255, 264, 847, 674, 437],
[47.84, 66.02, 80, 110, 110, 255, 263.99, 862, 684, 440],
[47.45, 69.1, 80, 110, 109.99, 255, 264, 866, 686, 440],
[47.42, 68.58, 80, 110, 110, 254.99, 263.99, 863, 679, 440],
[47.18, 67.62, 79.99, 110, 109.99, 255, 263.99, 860, 681, 440],
[46.94, 66.3, 80, 110, 110, 255, 264, 858, 681, 439],
[47.04, 66.28, 80, 110, 109.99, 254.99, 263.98, 859, 683, 439],
[47.04, 65.87, 80, 109.99, 109.99, 255, 264, 865, 685, 440],
[47.18, 66.7, 79.99, 109.99, 110, 255, 263.99, 864, 686, 440],
[47.18, 66.44, 80, 110, 110, 255, 264, 864, 683, 440],
[47.28, 67.33, 79.99, 110, 110, 255, 264, 866, 685, 440],
[47.18, 66.98, 79.99, 110, 110, 254.99, 263.99, 860, 684, 439],
[47.35, 67.94, 80, 109.99, 110, 254.99, 263.99, 861, 684, 439],
[47.14, 66.84, 79.99, 110, 110, 255, 263.99, 858, 678, 439],
[47.18, 67.35, 79.99, 110, 109.99, 255, 264, 855, 675, 439],
[47.18, 65.91, 79.99, 110, 110, 255, 263.99, 866, 681, 440],
[47.18, 64.71, 80, 110, 110, 254.99, 264, 858, 676, 439],
[47.21, 64.09, 80, 110, 110, 254.99, 263.99, 857, 676, 439],
[47.32, 63.92, 79.99, 110, 110, 254.99, 264, 858, 674, 439],
[47.42, 63.95, 79.99, 110, 109.99, 254.99, 264, 839, 663, 435],
[47.38, 64.3, 79.99, 109.99, 110, 254.99, 263.99, 861, 681, 439],
[47.52, 63.04, 79.99, 110, 110, 254.99, 264, 856, 674, 438],
[47.52, 64.7, 80, 110, 109.99, 255, 263.98, 830, 660, 434],
[47.49, 67.17, 79.99, 110, 110, 255, 263.99, 853, 674, 438],
[47.7, 66.79, 79.99, 109.99, 110, 254.99, 264, 866, 683, 440],
[47.91, 67.02, 79.99, 110, 109.99, 254.99, 264, 863, 682, 440],
[48.01, 65.96, 79.99, 109.99, 110, 254.99, 264, 865, 686, 440],
[47.98, 66.31, 79.99, 110, 110, 254.99, 264, 868, 686, 440],
[48.01, 66.16, 80, 110, 110, 255, 263.99, 867, 686, 440],
[48.01, 68, 80, 110, 110, 255, 263.99, 869, 685, 440],
[48.26, 67.15, 80, 110, 110, 255, 263.99, 861, 681, 440],
[48.15, 66.02, 80, 110, 110, 254.99, 263.99, 870, 689, 441],
[48.33, 67.48, 79.99, 110, 109.99, 254.99, 263.99, 864, 680, 440],
[48.22, 67.92, 79.99, 110, 110, 255, 264, 858, 681, 439],
[48.33, 68.56, 79.99, 109.99, 110, 254.99, 264, 863, 684, 440],
[48.4, 69.03, 80, 110, 110, 254.99, 263.99, 864, 684, 440],
[48.22, 68, 79.99, 110, 110, 254.99, 264, 866, 682, 440],
[48.26, 67.45, 80, 109.99, 110, 255, 264, 865, 683, 440],
[48.19, 68.54, 80, 109.99, 109.99, 254.99, 263.99, 859, 683, 439],
[48.19, 68.97, 79.99, 110, 110, 254.99, 264, 859, 681, 439],
[48.29, 67.93, 79.99, 110, 110, 255, 264, 867, 683, 440],
[48.26, 66.81, 80, 110, 110, 254.99, 263.99, 869, 687, 441],
[48.26, 66.09, 80, 109.99, 110, 254.99, 264, 867, 683, 440],
[48.36, 65.52, 79.99, 110, 110, 255, 264, 864, 682, 440],
[48.15, 62.82, 80, 110, 110, 254.99, 264, 866, 682, 440],
[48.15, 62.29, 80, 110, 110, 254.99, 263.99, 865, 681, 440],
[48.19, 63.06, 80, 110, 110, 255, 264, 867, 683, 440],
[48.12, 63.59, 79.99, 110, 109.99, 254.99, 263.99, 868, 683, 440],
[48.05, 63.22, 79.99, 110, 110, 254.99, 263.99, 871, 685, 441],
[47.87, 63.95, 80, 110, 109.99, 254.99, 263.98, 873, 688, 441],
[48.01, 64.62, 79.99, 110, 110, 255, 263.99, 866, 682, 441],
[47.77, 66.3, 80, 110, 109.99, 254.99, 264, 865, 684, 440],
[47.73, 63.99, 79.99, 110, 110, 255, 264, 869, 684, 441],
[47.63, 64.19, 80, 110, 110, 254.99, 264, 875, 691, 442],
[47.66, 67.97, 79.99, 110, 110, 254.99, 263.99, 861, 682, 439],
[47.84, 67.01, 80, 110, 110, 255, 264, 865, 686, 440],
[47.84, 66.5, 79.99, 110, 110, 254.99, 263.99, 865, 684, 440],
[48.05, 66.24, 80, 110, 110, 254.99, 263.98, 866, 682, 439],
[48.19, 65.7, 80, 110, 109.99, 254.99, 264, 865, 689, 440],
[48.08, 64.79, 80, 110, 110, 255, 263.99, 850, 671, 436],
[47.87, 65.55, 79.99, 110, 110, 254.99, 263.99, 858, 681, 440],
[47.87, 65.02, 80, 110, 110, 254.99, 264, 864, 678, 440],
[47.87, 66.82, 80, 110, 110, 254.99, 263.99, 875, 690, 441],
[47.73, 68.14, 79.99, 110, 110, 255, 263.72, 872, 683, 440],
[47.84, 67.62, 80, 110, 110, 255, 264, 864, 683, 440],
[47.8, 67.48, 79.99, 109.99, 110, 255, 264, 859, 679, 439],
[47.63, 64.76, 80, 110, 109.99, 254.99, 264, 864, 678, 440],
[47.28, 65.71, 79.99, 109.99, 110, 254.99, 264, 866, 687, 440],
[47.35, 68.92, 80, 109.99, 110, 255, 263.8, 876, 683, 440],
[47.25, 68.02, 79.99, 109.99, 110, 255, 264, 866, 686, 441],
[47.01, 67.74, 79.99, 109.99, 110, 255, 263.99, 860, 683, 440],
[47.01, 66.68, 80, 109.99, 110, 254.99, 263.99, 861, 683, 440],
[46.43, 66.62, 80, 110, 110, 255, 262.99, 857, 681, 439],
[46.36, 67.15, 79.99, 110, 110, 255, 264, 860, 684, 440],
[46.32, 66.6, 79.99, 110, 110, 254.99, 264, 860, 683, 440],
[46.26, 66.73, 79.99, 109.99, 110, 255, 263.99, 858, 682, 439],
[46.36, 65.81, 79.99, 110, 110, 254.99, 264, 864, 686, 441],
[46.39, 65.14, 80, 110, 110, 255, 263.99, 868, 687, 441],
[46.6, 65.34, 79.99, 110, 110, 255, 264.38, 850, 670, 437],
[46.66, 65.73, 80, 110, 110, 255, 263.99, 838, 663, 435],
[46.66, 65.57, 79.99, 110, 110, 255, 263.99, 840, 666, 435],
[46.66, 64.99, 79.99, 110, 110, 254.99, 264, 854, 675, 438],
[46.56, 64.6, 79.99, 110, 110, 254.99, 261.67, 860, 683, 440],
[46.83, 64.7, 79.99, 110, 110, 254.99, 264, 866, 684, 440],
[46.7, 64.83, 79.99, 110, 109.99, 255, 264, 853, 680, 438],
[46.8, 66.13, 80, 110, 110, 254.99, 264, 846, 671, 437],
[46.87, 66.46, 80, 110, 110, 255, 263.99, 861, 682, 440],
[46.6, 67.71, 79.99, 110, 110, 254.99, 264, 855, 679, 439],
[46.63, 65.7, 80, 110, 110, 255, 263.99, 860, 680, 440],
[46.46, 66.65, 79.99, 110, 110, 254.99, 263.99, 859, 683, 440],
[46.46, 66.77, 79.99, 110, 110, 254.99, 263.8, 855, 682, 439],
[46.7, 67.22, 79.99, 110, 110, 255, 263.99, 861, 685, 440],
[46.46, 67.22, 79.99, 109.99, 110, 254.99, 263.99, 856, 682, 440],
[46.87, 66.68, 80, 110, 110, 254.99, 263.99, 858, 683, 440],
[46.94, 65.63, 79.99, 110, 109.99, 254.99, 264, 857, 680, 439],
[47.01, 64.41, 79.99, 110, 110, 254.97, 255.65, 861, 683, 440],
[47.11, 64.13, 79.99, 110, 110, 254.99, 264, 859, 681, 439],
[47.14, 64.26, 80, 109.99, 109.99, 254.99, 263.99, 861, 683, 440],
[47.45, 67.19, 80, 110, 109.99, 255, 263.99, 859, 683, 440],
[47.52, 67.86, 79.99, 110, 109.99, 255, 263.99, 860, 681, 440],
[47.59, 67.56, 79.99, 110, 110, 254.97, 269.51, 863, 686, 441],
[47.49, 65.7, 80, 110, 110, 254.99, 263.99, 844, 675, 437],
[47.49, 67.54, 80, 110, 110, 254.98, 263.99, 861, 686, 440],
[47.52, 66.9, 79.99, 110, 109.99, 255, 263.99, 860, 680, 440],
[47.45, 66.72, 80, 110, 110, 255, 263.99, 867, 690, 441],
[47.49, 65.76, 80, 110, 109.99, 255, 263.99, 857, 683, 440],
[47.52, 66.54, 80, 110, 110, 254.99, 264, 861, 690, 440],
[47.49, 67.13, 79.99, 110, 110, 254.99, 264.01, 867, 687, 441],
[47.28, 64.42, 79.99, 110, 110, 254.99, 263.99, 871, 690, 442],
[47.42, 65.25, 79.99, 109.99, 110, 255, 264, 860, 685, 441],
[47.49, 66.65, 80, 110, 110, 255, 264, 862, 686, 441],
[47.35, 67.08, 79.99, 110, 110, 255, 263.99, 861, 689, 442],
[47.28, 66.33, 80, 110, 110, 254.99, 264, 858, 684, 441],
[47.14, 67.79, 79.99, 109.99, 110, 255, 264, 853, 681, 440],
[47.25, 67.76, 79.99, 110, 110, 254.99, 264, 860, 685, 441],
[47.18, 66.79, 80, 110, 110, 255, 264, 855, 680, 440],
[47.42, 65.66, 80, 109.99, 109.99, 254.99, 263.99, 855, 683, 440],
[47.38, 65.48, 80, 110, 110, 255, 264, 857, 677, 440],
[47.28, 64.83, 80, 109.99, 110, 255, 264, 869, 691, 442],
[47.35, 64.46, 80, 110, 110, 255, 264, 854, 681, 440],
[47.59, 65.21, 79.99, 109.99, 110, 255, 263.99, 854, 678, 440],
[47.63, 65.97, 80, 109.99, 110, 254.99, 263.99, 841, 669, 436],
[47.66, 66.19, 79.99, 109.99, 110, 255, 263.99, 866, 686, 441],
[47.66, 65.62, 79.99, 110, 110, 255, 263.99, 843, 672, 437],
[47.73, 66.02, 80, 110, 110, 254.99, 263.99, 833, 660, 435],
[47.77, 65.51, 80, 110, 110, 255, 264, 852, 680, 438],
[47.77, 67.48, 79.99, 109.99, 110, 254.99, 264, 845, 670, 438],
[47.77, 67.14, 79.99, 110, 110, 254.99, 263.99, 845, 673, 437],
[47.98, 68.07, 80, 110, 110, 254.99, 264, 852, 677, 438],
[47.8, 66.56, 79.99, 110, 110, 254.99, 263.99, 840, 667, 437],
[47.73, 64.35, 79.99, 110, 110, 255, 263.99, 851, 681, 440],
[47.84, 64.52, 79.99, 109.99, 110, 255, 264, 859, 685, 441],
[47.7, 65.62, 79.99, 110, 109.99, 254.99, 263.99, 850, 679, 439],
[47.63, 64.75, 80, 110, 110, 254.99, 264, 824, 655, 434],
[47.63, 64.86, 79.99, 109.99, 110, 255, 264, 846, 668, 438],
[47.59, 65.63, 80, 110, 109.99, 255, 264, 863, 681, 441],
[47.59, 65.43, 80, 110, 110, 254.99, 263.99, 846, 674, 438],
[47.38, 66.34, 80, 110, 110, 254.99, 263.99, 842, 670, 437],
[47.49, 66.08, 79.99, 110, 109.99, 254.99, 264, 836, 665, 436],
[47.49, 64.77, 79.99, 110, 110, 254.99, 264, 840, 662, 436],
[47.38, 64.19, 79.99, 110, 110, 254.99, 263.99, 837, 668, 436],
[47.28, 64.96, 79.99, 110, 110, 255, 263.99, 844, 670, 438],
[47.49, 64.92, 80, 110, 110, 254.99, 263.99, 849, 673, 438],
[47.28, 64.07, 80, 110, 110, 255, 264, 859, 682, 440],
[47.32, 64.93, 79.99, 110, 110, 254.99, 264, 865, 684, 441],
[47.38, 66.4, 79.99, 109.99, 110, 254.99, 264, 845, 673, 438],
[47.01, 65.81, 79.99, 110, 110, 254.99, 263.99, 853, 678, 440],
[47.11, 65.8, 79.99, 109.99, 110, 254.99, 263.99, 840, 668, 436],
[47.11, 65.75, 80, 110, 110, 255, 263.99, 844, 673, 437],
[47.28, 68.62, 79.99, 110, 109.99, 255, 264, 857, 683, 439],
[47.01, 67.61, 80, 110, 110, 254.99, 264, 860, 686, 440],
[46.94, 65.81, 80, 110, 110, 254.99, 264, 858, 681, 440],
[47.14, 65.26, 79.99, 110, 110, 255, 263.99, 853, 677, 439],
[46.97, 64.31, 80, 110, 110, 255, 263.99, 848, 677, 438],
[47.01, 65.69, 80, 109.99, 110, 255, 263.99, 856, 681, 439],
[46.87, 65.28, 79.99, 110, 110, 254.99, 264, 857, 682, 439],
[46.97, 65.47, 80, 109.99, 109.99, 255, 264, 859, 683, 440],
[46.83, 64.71, 80, 110, 110, 254.99, 264, 844, 674, 437],
[46.8, 65.3, 80, 110, 110, 254.99, 263.99, 838, 665, 435],
[46.8, 67.85, 80, 110, 110, 255, 264, 862, 684, 440],
[46.63, 67.83, 79.99, 110, 110, 255, 263.99, 858, 683, 440],
[46.63, 68.56, 80, 110, 109.99, 255, 263.99, 858, 683, 440],
[46.56, 68.16, 79.99, 110, 110, 254.99, 264, 859, 684, 440],
[46.29, 66.86, 80, 110, 110, 255, 264, 851, 674, 438],
[46.02, 66.76, 80, 110, 109.99, 255, 264, 845, 669, 437],
[45.99, 67.86, 80, 110, 110, 254.99, 263.99, 859, 683, 439],
[46.05, 67.64, 80, 110, 110, 254.99, 263.99, 861, 689, 440],
[45.99, 67.52, 79.99, 110, 109.99, 255, 264, 858, 682, 439],
[45.25, 66.89, 80, 110, 110, 254.99, 263.99, 838, 665, 435],
[45.28, 67.44, 80, 110, 109.99, 255, 263.99, 867, 688, 440],
[45.31, 67.29, 80, 110, 110, 254.99, 263.99, 859, 683, 439],
[45.12, 68.21, 80, 110, 110, 254.99, 263.99, 847, 680, 437],
[44.98, 68.46, 79.99, 109.99, 110, 254.99, 264, 845, 674, 437],
[44.88, 65.17, 80, 110, 110, 254.99, 264, 823, 660, 432],
[44.75, 65.35, 79.99, 110, 110, 254.99, 264, 819, 656, 431],
[43.8, 68.54, 80, 109.99, 110, 254.99, 263.99, 862, 687, 439],
[43.74, 67.9, 79.99, 110, 110, 254.99, 263.99, 856, 685, 439],
[42.87, 70.03, 80, 110, 110, 255, 263.99, 858, 684, 440],
[42.81, 66.52, 79.99, 110, 110, 254.99, 264, 865, 690, 440],
[42.81, 66.84, 80, 110, 110, 254.99, 264, 870, 697, 441],
[42.81, 66.81, 80, 110, 110, 254.99, 263.99, 866, 694, 441],
[42.65, 65.14, 80, 110, 110, 254.99, 264, 865, 688, 440],
[42.87, 66.43, 80, 110, 110, 254.99, 263.99, 872, 693, 441],
[42.71, 67.73, 79.99, 110, 109.99, 254.99, 263.99, 856, 685, 439],
[42.33, 69.28, 79.99, 109.99, 110, 254.99, 263.99, 858, 686, 439],
[42.46, 67.78, 80, 110, 110, 255, 263.99, 860, 690, 439],
[42.42, 68.34, 80, 110, 110, 254.99, 263.99, 868, 691, 441],
[42.52, 67.41, 80, 110, 110, 255, 264, 849, 680, 438],
[42.46, 66.63, 80, 110, 110, 255, 263.99, 852, 683, 438],
[42.36, 65.8, 80, 110, 110, 255, 263.98, 884, 694, 441],
[42.58, 66.68, 80, 110, 110, 255, 264, 859, 685, 440],
[42.39, 65.56, 79.99, 110, 110, 254.99, 263.99, 864, 680, 438],
[42.42, 65.53, 79.99, 110, 110, 254.99, 264, 851, 680, 438],
[42.49, 65.61, 79.99, 110, 110, 254.99, 263.99, 857, 685, 439],
[42.52, 64.93, 79.99, 110, 109.99, 255, 264, 858, 687, 439],
[42.42, 69.06, 79.99, 110, 109.99, 255, 263.99, 859, 686, 439],
[42.39, 68.53, 79.99, 109.99, 110, 255, 263.99, 862, 689, 440],
[42.58, 68.27, 80, 109.99, 110, 254.99, 263.99, 895, 691, 440],
[42.68, 67.32, 80, 110, 110, 255, 264, 866, 693, 440],
[42.49, 67.79, 79.99, 110, 109.99, 255, 263.99, 864, 692, 440],
[42.52, 67.6, 79.99, 110, 110, 255, 263.99, 855, 683, 439],
[42.65, 67.51, 80, 110, 110, 254.99, 263.99, 855, 685, 440],
[42.65, 65.76, 80, 110, 110, 254.99, 263.99, 872, 693, 441],
[42.52, 65.92, 80, 110, 110, 254.99, 264, 863, 689, 440],
[42.77, 67.07, 80, 110, 110, 255, 263.99, 857, 683, 439],
[42.9, 67.24, 80, 110, 110, 254.99, 263.99, 858, 689, 440],
[42.97, 66.93, 80, 109.99, 110, 255, 264, 857, 685, 439],
[42.93, 67.3, 79.99, 110, 110, 255, 263.99, 873, 702, 441],
[42.9, 67.6, 80, 110, 110, 255.02, 264.01, 660, 538, 307],
[42.68, 67.7, 79.99, 110, 110, 254.99, 264, 539, 448, 254],
[45.35, 64.11, 79.99, 110, 110, 255, 264, 875, 694, 444],
[45.41, 64.13, 79.99, 110, 110, 255, 264, 870, 692, 442],
[45.58, 66.84, 79.99, 109.99, 110, 254.99, 264, 877, 697, 443],
[45.78, 66.37, 79.99, 110, 110, 255, 264, 874, 698, 444],
[46.32, 63.42, 80, 110, 110, 255, 263.99, 852, 676, 439],
[46.46, 63.23, 80, 110, 109.99, 255.04, 263.73, 865, 690, 441],
[46.19, 64.5, 79.99, 110, 109.99, 255, 264, 844, 670, 437],
[46.19, 66.17, 79.99, 110, 109.99, 255, 264, 862, 687, 440],
[46.19, 68.35, 79.99, 110, 109.99, 255, 264, 855, 680, 439],
[46.19, 66.77, 79.99, 109.99, 110, 254.3, 284.72, 862, 684, 440],
[46.12, 66.98, 79.99, 110, 109.99, 255, 264, 864, 690, 440],
[46.02, 64.28, 80, 110, 110, 254.99, 264, 862, 682, 439],
[46.09, 63.11, 80, 110, 109.99, 255, 263.98, 870, 688, 441],
[46.02, 63.29, 79.99, 110, 110, 254.99, 263.99, 868, 689, 441],
[45.92, 63.32, 79.99, 110, 110, 254.99, 263.99, 870, 693, 441],
[45.82, 63.3, 79.99, 110, 109.99, 255, 264, 865, 683, 440],
[45.88, 66.74, 79.99, 109.99, 109.99, 255, 264, 865, 688, 440],
[45.82, 67.33, 80, 110, 109.99, 255, 263.99, 861, 686, 440],
[45.68, 68.31, 79.99, 110, 110, 254.99, 264, 864, 689, 440],
[45.65, 69.55, 80, 110, 109.99, 254.96, 274.88, 866, 694, 440],
[45.75, 70.34, 79.99, 110, 110, 255, 263.99, 864, 690, 441],
[45.68, 70.07, 80, 110, 110, 255, 264, 865, 691, 440],
[45.65, 68.81, 79.99, 110, 109.99, 254.99, 264, 862, 684, 440],
[45.75, 66.25, 79.99, 110, 110, 255, 264, 847, 673, 437],
[45.45, 67.48, 79.99, 110, 109.99, 254.99, 263.99, 851, 679, 438],
[44.95, 68.07, 79.99, 110, 110, 255, 264, 863, 685, 440],
[44.92, 67.67, 79.99, 110, 110, 255, 263.99, 836, 667, 435],
[44.65, 68.07, 80, 110, 110, 255, 264, 857, 682, 439],
[44.55, 67.9, 79.99, 110, 110, 254.32, 263.28, 857, 685, 439],
[44.59, 68.51, 80, 110, 110, 254.99, 264, 868, 691, 441],
[44.42, 69, 80, 109.99, 110, 255, 264, 854, 681, 439],
[44.42, 68.73, 79.99, 110, 109.99, 254.99, 264, 844, 674, 438],
[44.13, 67.44, 79.99, 110, 110, 255, 264, 876, 694, 441],
[44.13, 68.02, 80, 110, 110, 255.38, 268.73, 868, 698, 440],
[44.13, 67.81, 80, 110, 110, 255, 263.99, 861, 690, 440],
[44.32, 66.49, 79.99, 109.99, 110, 254.99, 264, 859, 685, 440],
[44.06, 66.38, 79.99, 110, 110, 255, 264, 858, 683, 439],
[44.06, 67.37, 79.99, 109.99, 109.99, 255, 264, 852, 676, 438],
[44.03, 68.77, 79.99, 109.99, 110, 255, 264, 842, 675, 437],
[44, 67.45, 79.99, 109.99, 109.99, 254.99, 263.99, 853, 678, 439],
[43.84, 67.79, 80, 110, 110, 255, 264, 838, 671, 436],
[43.64, 69.56, 79.99, 110, 110, 255, 264, 846, 677, 438],
[43.64, 68.34, 79.99, 110, 110, 254.99, 263.99, 846, 679, 438],
[43.58, 69.08, 79.99, 110, 110, 255, 263.99, 861, 688, 441],
[43.42, 68.67, 80, 110, 110, 254.99, 263.99, 865, 695, 440],
[43.58, 67.67, 80, 109.99, 110, 254.66, 263.63, 863, 694, 438],
[43.61, 67.29, 79.99, 110, 109.99, 254.99, 264, 860, 690, 439],
[43.58, 67.59, 79.99, 110, 110, 255, 264, 848, 679, 438],
[43.64, 67.51, 79.99, 110, 110, 255, 264, 855, 684, 439],
[43.58, 65.75, 79.99, 110, 110, 255, 264, 858, 685, 440],
[43.51, 65, 80, 110, 110, 254.99, 263.99, 847, 678, 438],
[43.54, 65.62, 80, 109.99, 110, 254.99, 264, 856, 682, 440],
[43.51, 67.93, 80, 110, 109.99, 255, 263.99, 852, 680, 439],
[43.35, 67.88, 80, 110, 109.99, 255.32, 271.3, 843, 677, 437],
[43.54, 70.69, 79.99, 110, 109.99, 255, 263.99, 843, 677, 437],
[43.42, 69.35, 80, 109.99, 110, 255, 264, 848, 680, 439],
[43.67, 69.63, 80, 110, 110, 255, 263.99, 857, 689, 440],
[43.54, 67.22, 80, 110, 110, 255, 264, 839, 676, 437],
[43.32, 67.01, 80, 110, 110, 254.99, 264, 837, 669, 436],
[43.38, 67.45, 80, 110, 110, 255, 263.99, 849, 679, 438],
[43.48, 68.01, 79.99, 110, 110, 255, 263.99, 836, 671, 436],
[43.58, 66.37, 79.99, 110, 110, 255, 264, 839, 674, 437],
[43.51, 66.9, 80, 110, 110, 254.99, 264, 845, 680, 438],
[43.77, 65.96, 79.99, 110, 110, 254.99, 263.99, 863, 688, 440],
[43.61, 65.93, 80, 110, 110, 255, 264, 865, 691, 441],
[43.51, 67.87, 80, 109.99, 110, 255, 264, 868, 690, 441],
[43.54, 67.2, 79.99, 110, 110, 254.99, 264, 861, 686, 441],
[43.51, 65.92, 79.99, 110, 110, 254.99, 264, 867, 692, 441],
[43.54, 66.1, 79.99, 110, 110, 255, 263.99, 865, 693, 441],
[43.58, 66.77, 79.99, 110, 110, 255, 264, 854, 682, 439],
[43.48, 67.33, 79.99, 110, 109.99, 254.99, 264, 844, 678, 437],
[43.48, 66.29, 80, 110, 109.99, 255, 263.99, 856, 686, 440],
[43.71, 68.86, 80, 110, 110, 255, 264, 851, 681, 439],
[43.54, 68.15, 80, 110, 110, 254.99, 263.99, 836, 674, 436],
[43.58, 67.6, 80, 109.99, 110, 254.99, 263.99, 847, 675, 438],
[43.58, 70.33, 79.99, 110, 110, 255, 264, 840, 675, 437],
[43.58, 69.24, 80, 109.99, 110, 254.99, 264, 834, 666, 436],
[43.54, 67.84, 80, 110, 110, 255, 263.99, 846, 678, 438],
[43.25, 67.36, 80, 110, 110, 255, 264, 849, 680, 439],
[43.74, 67.99, 80, 109.99, 110, 255, 264, 841, 674, 438],
[43.8, 68.31, 79.99, 109.99, 110, 255, 264, 858, 682, 439],
[43.64, 67.49, 80, 110, 109.99, 254.99, 264, 858, 689, 440],
[43.77, 68.43, 79.99, 110, 109.99, 255, 263.99, 858, 684, 440],
[43.67, 68.07, 80, 109.99, 110, 255, 263.99, 851, 683, 438],
[43.71, 67.7, 80, 110, 110, 255, 263.99, 838, 673, 436],
[43.74, 67.97, 80, 109.99, 110, 255, 264, 852, 679, 439],
[43.87, 68.03, 80, 110, 110, 254.99, 264, 848, 675, 438],
[43.8, 65.19, 79.99, 109.99, 110, 254.99, 264, 839, 670, 436],
[43.84, 65.84, 80, 110, 110, 254.99, 264, 859, 683, 440],
[43.9, 65.19, 79.99, 110, 109.99, 255.21, 270.09, 857, 690, 439],
[43.9, 64.86, 79.99, 110, 110, 254.99, 264, 858, 690, 439],
[44.19, 64.6, 80, 110, 110, 254.99, 264, 863, 693, 441],
[44, 66, 79.99, 110, 110, 255.27, 261.72, 856, 684, 439],
[44.19, 65.93, 79.99, 110, 110, 255, 263.99, 863, 686, 441],
[44.06, 65.78, 80, 110, 110, 255, 264, 848, 682, 438],
[44.23, 65.47, 80, 110, 110, 255, 264, 850, 679, 439],
[44.16, 65.76, 80, 110, 109.99, 255, 264, 858, 684, 440],
[44.42, 66.48, 79.99, 110, 110, 254.48, 263.7, 861, 688, 440],
[44.32, 66.03, 80, 110, 110, 254.99, 263.99, 866, 690, 441],
[48.96, 64.65, 80, 110, 110, 254.99, 264, 863, 682, 442],
[49.25, 66.41, 79.99, 110, 110, 255, 264, 857, 683, 441],
[49.72, 64.1, 79.99, 110, 110, 255, 264, 863, 684, 442],
[49.57, 63.11, 79.99, 110, 110, 254.99, 264, 860, 689, 443],
[49.65, 63.51, 79.99, 110, 109.99, 255, 264, 867, 690, 444],
[49.5, 62.87, 80, 110, 110, 255, 263.99, 869, 690, 443],
[49.47, 62.03, 79.99, 110, 110, 255, 263.99, 880, 695, 444],
[49.29, 63.21, 79.99, 110, 110, 255, 264, 864, 683, 443],
[49.54, 62.99, 80, 109.99, 110, 254.99, 263.99, 862, 684, 442],
[49.32, 63.43, 80, 110, 110, 255, 264, 865, 689, 443],
[49.25, 62.52, 80, 109.99, 110, 255, 263.99, 863, 688, 442],
[49.18, 64.38, 80, 110, 110, 255, 263.99, 862, 686, 441],
[49.11, 64.72, 79.99, 109.99, 110, 255, 264, 864, 686, 442],
[49.21, 64.59, 79.99, 110, 109.99, 255, 263.99, 870, 691, 443],
[49.21, 64.06, 80, 109.99, 110, 255, 264, 870, 689, 442],
[49.43, 64.65, 80, 109.99, 109.99, 255.09, 275.81, 865, 684, 440],
[49.36, 65.05, 79.99, 110, 110, 255, 263.99, 862, 684, 442],
[49.47, 64.54, 79.99, 110, 110, 254.5, 264.17, 863, 683, 440],
[49.43, 64.36, 79.99, 110, 110, 254.99, 264, 869, 689, 442],
[49.29, 63.17, 79.99, 110, 109.99, 255.33, 268.42, 867, 685, 442],
[49.39, 63.47, 79.99, 110, 110, 254.99, 263.99, 869, 685, 442],
[49.36, 63.22, 79.99, 110, 109.99, 255, 264, 866, 683, 442],
[49.18, 63.85, 80, 110, 110, 254.99, 263.99, 871, 691, 443],
[49.14, 63.42, 80, 110, 110, 255, 264, 868, 682, 442],
[49.07, 63.18, 80, 110, 110, 255.48, 262.05, 858, 678, 440],
[48.96, 63.62, 80, 110, 109.99, 255, 264, 856, 676, 440],
[49.07, 64.04, 80, 110, 109.99, 245.15, 261.76, 871, 692, 442],
[48.64, 65.55, 80, 110, 109.99, 255, 263.99, 892, 707, 443],
[48.61, 66.75, 79.99, 109.99, 109.99, 255, 263.99, 882, 695, 444],
[48.79, 66.1, 79.99, 110, 110, 255, 264, 877, 690, 443],
[48.68, 65.92, 79.99, 109.99, 110, 255.66, 270.88, 872, 687, 443],
[48.61, 65.38, 79.99, 110, 110, 255, 263.99, 877, 695, 444],
[48.22, 64.65, 80, 110, 110, 255, 263.99, 877, 695, 441],
[48.4, 64.64, 79.99, 110, 110, 254.99, 264, 873, 690, 443],
[45.82, 65.69, 79.99, 110, 110, 254.99, 264, 874, 694, 442],
[45.28, 70.37, 79.99, 110, 109.99, 255, 264, 875, 700, 443],
[44.85, 66.04, 79.99, 110, 110, 255, 263.99, 865, 691, 441],
[44.75, 66.02, 80, 110, 110, 254.99, 263.99, 848, 672, 437],
[44.78, 65.68, 79.99, 110, 110, 255, 263.99, 847, 675, 437],
[44.85, 65.1, 79.99, 110, 110, 255, 263.99, 855, 682, 438],
[45.18, 65.95, 80, 110, 109.99, 254.99, 263.99, 839, 672, 435],
[45.02, 65.17, 80, 110, 110, 254.91, 264.11, 856, 684, 438],
[45.05, 65.06, 79.99, 109.99, 110, 254.99, 264, 851, 681, 437],
[44.26, 67.81, 80, 110, 109.99, 254.99, 263.9, 860, 689, 440],
[44.29, 69.39, 79.99, 110, 110, 254.99, 264, 844, 678, 436],
[44.23, 68.46, 79.99, 110, 110, 255, 264, 867, 693, 441],
[44.13, 68.11, 80, 110, 110, 254.98, 263.99, 865, 686, 440],
[44.16, 68.15, 80, 110, 110, 255, 263.99, 867, 689, 440],
[44.19, 66.93, 80, 110, 110, 255, 264, 876, 700, 441],
[44.52, 66.58, 79.99, 110, 110, 255, 263.99, 874, 691, 440],
[44.36, 67, 80, 110, 110, 254.99, 264, 876, 697, 442],
[44.46, 65.27, 79.99, 110, 110, 255, 263.99, 875, 698, 441],
[44.75, 65.29, 80, 110, 110, 255, 263.99, 869, 692, 440],
[44.78, 66.41, 79.99, 109.99, 110, 254.99, 264, 870, 694, 440],
[44.69, 67.89, 80, 109.99, 109.99, 254.99, 263.96, 874, 697, 441],
[44.69, 66.43, 80, 110, 109.99, 255, 264, 871, 697, 441],
[44.55, 66.69, 79.99, 110, 110, 254.99, 263.95, 870, 692, 440],
[44.59, 66.3, 80, 110, 110, 254.99, 263.99, 862, 687, 440],
[44.42, 66.16, 79.99, 110, 110, 255, 264, 868, 691, 440],
[44.36, 65.68, 80, 110, 110, 254.99, 264, 869, 691, 441],
[44.32, 66.19, 80, 110, 109.99, 254.99, 264, 860, 681, 439],
[44.49, 66.51, 80, 110, 110, 255, 263.99, 867, 687, 440],
[44.55, 67.71, 79.99, 110, 110, 254.99, 263.98, 855, 679, 438],
[44.55, 67.85, 79.99, 110, 110, 254.99, 263.99, 838, 667, 435],
[44.62, 66.69, 80, 109.99, 110, 255, 264, 854, 674, 438],
[44.69, 65.83, 79.99, 110, 110, 255, 263.99, 867, 690, 440],
[44.62, 67.26, 79.99, 110, 110, 255, 264, 850, 679, 437],
[44.65, 67.46, 79.99, 110, 110, 255, 264, 861, 691, 439],
[44.52, 66.92, 80, 110, 110, 255, 263.99, 867, 689, 440],
[44.49, 66.66, 79.99, 110, 110, 255, 263.99, 870, 693, 441],
[44.55, 67.09, 80, 110, 109.99, 254.99, 263.99, 868, 696, 440],
[44.49, 65.68, 79.99, 110, 110, 255, 263.99, 858, 684, 439],
[44.55, 64.53, 80, 110, 110, 254.99, 263.99, 865, 690, 440],
[44.69, 64.25, 79.99, 110, 110, 254.99, 263.99, 859, 685, 439],
[44.98, 66.05, 80, 110, 110, 254.99, 263.99, 860, 683, 439],
[44.95, 66.43, 80, 110, 110, 254.99, 263.99, 865, 685, 440],
[45.38, 64.94, 79.99, 110, 110, 254.99, 263.99, 867, 691, 440],
[45.31, 65.64, 80, 110, 110, 254.99, 264, 869, 689, 440],
[45.45, 65.11, 80, 109.99, 110, 255, 264, 851, 676, 437],
[49.79, 65.79, 79.99, 110, 110, 254.99, 263.99, 834, 663, 436],
[49.75, 65.76, 80, 110, 110, 255, 264, 841, 666, 437],
[49.9, 66.14, 80, 110, 109.99, 255, 264, 841, 669, 437],
[49.86, 65.27, 79.99, 110, 110, 255, 264, 837, 665, 437],
[50.26, 64.89, 80, 110, 110, 254.99, 264, 846, 668, 437],
[50.34, 64.48, 80, 110, 110, 255, 264, 836, 661, 436],
[50.15, 64.02, 79.99, 109.99, 109.99, 255, 264, 843, 668, 437],
[50.23, 67.03, 80, 110, 109.99, 254.99, 264, 854, 672, 439],
[50.19, 66.25, 79.99, 110, 110, 255, 264, 849, 669, 438],
[49.65, 67.32, 80, 110, 110, 255, 263.99, 865, 688, 441],
[49.65, 68.99, 80, 110, 110, 255, 263.99, 865, 684, 442],
[49.39, 65.79, 80, 109.99, 110, 254.99, 264, 864, 687, 442],
[49.54, 65.59, 80, 110, 109.99, 254.99, 263.99, 862, 686, 441],
[49.43, 66.13, 80, 110, 110, 254.99, 264, 866, 687, 442],
[47.01, 66.02, 80, 109.99, 110, 255, 264, 873, 697, 441],
[45.52, 67.18, 79.99, 109.99, 109.99, 255, 264, 886, 709, 443],
[45.65, 68.1, 79.99, 110, 109.99, 255, 263.99, 890, 710, 443],
[45.55, 67.56, 79.99, 110, 110, 254.99, 264, 888, 706, 443],
[45.52, 67.16, 79.99, 109.99, 110, 255, 263.99, 877, 695, 442],
[45.52, 67.11, 79.99, 109.99, 110, 255, 264, 885, 703, 443],
[45.55, 66.73, 80, 109.99, 110, 255, 264, 887, 704, 443],
[45.52, 67.56, 79.99, 110, 110, 254.99, 263.99, 857, 683, 438],
[45.48, 66.2, 80, 109.99, 110, 255, 263.99, 868, 690, 440],
[45.82, 67.24, 80, 110, 110, 255, 264, 891, 708, 443],
[45.52, 68.85, 79.99, 110, 110, 254.99, 264, 891, 708, 442],
[45.45, 65.95, 79.99, 110, 110, 254.99, 263.99, 884, 705, 442],
[45.31, 65.76, 79.99, 110, 110, 254.99, 263.99, 877, 698, 442],
[45.52, 66.75, 79.99, 110, 109.99, 255, 263.99, 868, 690, 440],
[45.52, 67.09, 79.99, 110, 109.99, 255, 263.99, 878, 698, 441],
[45.58, 67.05, 79.99, 110, 110, 254.99, 263.99, 871, 692, 441],
[45.65, 66.55, 80, 110, 110, 255, 264, 863, 687, 440],
[45.75, 66.69, 79.99, 109.99, 110, 254.99, 264, 859, 685, 438],
[45.92, 66.85, 80, 110, 110, 254.99, 264, 861, 684, 439],
[45.95, 65.63, 80, 110, 110, 254.99, 264, 869, 690, 440],
[45.88, 64.37, 80, 110, 110, 255, 263.99, 856, 680, 438],
[45.82, 64.68, 80, 110, 109.99, 254.99, 264, 860, 683, 439],
[45.88, 65.15, 80, 110, 110, 254.99, 264, 858, 680, 438],
[45.78, 64.94, 79.99, 109.99, 110, 254.99, 264, 853, 676, 437],
[45.88, 65.62, 80, 109.99, 110, 254.99, 264, 861, 686, 439],
[45.72, 66.06, 79.99, 110, 110, 254.99, 263.99, 867, 687, 439],
[45.68, 66.29, 80, 110, 110, 255, 264, 866, 692, 440],
[45.62, 68.52, 79.99, 110, 110, 254.99, 264, 867, 690, 440],
[45.68, 68.16, 80, 110, 110, 254.97, 284.4, 869, 692, 441],
[45.55, 67.37, 79.99, 110, 110, 254.99, 263.99, 865, 685, 440],
[45.55, 65.59, 80, 110, 110, 254.99, 263.99, 855, 686, 439],
[45.72, 64.89, 80.01, 110, 110, 254.99, 263.99, 866, 695, 440],
[45.62, 66.35, 80, 109.99, 110, 255, 263.46, 884, 691, 441],
[45.55, 67.14, 79.99, 110, 110, 254.99, 263.99, 867, 696, 440],
[45.45, 67.87, 80, 110, 110, 255, 264, 873, 696, 440],
[45.55, 68.45, 79.99, 110, 110, 254.99, 263.99, 872, 690, 440],
[45.58, 68.06, 80, 110, 110, 254.99, 264, 875, 693, 441],
[45.31, 66.14, 80, 110, 110, 255, 264, 865, 686, 439],
[45.35, 64.86, 80, 110, 110, 254.99, 264, 846, 672, 436],
[45.28, 64.75, 80, 110, 110, 255, 264, 849, 679, 437],
[45.28, 65.74, 79.99, 110, 110, 255, 263.26, 869, 689, 440],
[45.22, 64.65, 80, 110, 110, 255, 263.99, 867, 692, 440],
[45.28, 67.52, 80, 110, 110, 254.99, 263.99, 858, 687, 438],
[45.05, 67.36, 79.99, 110, 110, 254.99, 263.99, 859, 681, 438],
[45.12, 61.76, 79.99, 110, 109.99, 254.99, 264, 864, 685, 439],
[45.12, 62.29, 79.99, 110, 109.99, 254.99, 263.96, 866, 682, 439],
[45.12, 62.81, 80, 109.99, 109.99, 255, 264, 871, 690, 440],
[45.35, 63.01, 80, 110, 109.99, 254.99, 264, 862, 685, 439],
[45.15, 65.99, 79.99, 109.99, 110, 254.99, 264, 869, 689, 440],
[45.12, 66.22, 80, 110, 110, 255, 263.99, 871, 693, 440],
[45.12, 67.45, 79.99, 110, 110, 254.99, 264, 871, 692, 440],
[45.28, 67.68, 79.99, 110, 110, 255, 264, 862, 685, 439],
[45.15, 66.86, 80, 110, 110, 255, 264, 867, 692, 440],
[45.25, 65.45, 80, 110, 110, 254.99, 263.98, 873, 692, 441],
[45.22, 64.31, 79.99, 110, 110, 255, 264, 864, 689, 439],
[45.02, 64.49, 80, 110, 110, 254.98, 263.98, 853, 680, 437],
[44.98, 67.12, 80, 110, 110, 255, 264, 851, 679, 437],
[44.59, 67.59, 79.99, 110, 110, 255, 264, 860, 686, 439],
[44.46, 66.96, 80, 110, 110, 255, 263.99, 860, 686, 439],
[44.36, 65.62, 80, 109.99, 110, 254.99, 264, 863, 686, 439],
[44.46, 64.66, 79.99, 110, 109.99, 255, 263.99, 862, 686, 439],
[44.39, 65.98, 80, 110, 110, 254.99, 263.99, 865, 690, 440],
[44.03, 67.83, 79.99, 109.99, 110, 254.99, 263.99, 862, 686, 439],
[44.19, 67.73, 80, 110, 110, 255, 264, 868, 694, 440],
[44.39, 65.19, 80, 110, 110, 255, 263.99, 870, 691, 439],
[44.49, 64.01, 79.99, 110, 110, 254.99, 264, 856, 680, 437],
[44.49, 63.56, 79.99, 110, 110, 255, 263.99, 840, 670, 435],
[44.62, 63.46, 80, 110, 110, 254.99, 264, 834, 668, 434],
[44.62, 64.1, 79.99, 110, 110, 255, 264, 845, 671, 436],
[44.55, 65.98, 79.99, 110, 110, 255, 264, 857, 685, 439],
[44.52, 67.02, 80, 109.99, 109.99, 255, 264, 843, 672, 436],
[44.52, 67.66, 80, 109.99, 109.99, 255, 263.99, 851, 679, 438],
[44.59, 66.61, 80, 110, 110, 254.99, 264, 862, 690, 440],
[44.39, 65.74, 80, 110, 110, 255, 264, 855, 684, 438],
[44.42, 66.89, 80, 109.99, 110, 254.99, 263.99, 845, 671, 436],
[44.32, 66.19, 80, 110, 109.99, 254.99, 263.99, 849, 679, 438],
[44.39, 65.78, 80, 109.99, 110, 254.99, 264, 849, 678, 438],
[44.39, 65.64, 80, 110, 110, 254.99, 264, 865, 692, 440],
[44.52, 67.54, 79.99, 110, 110, 254.99, 264, 865, 688, 440],
[44.29, 66.38, 80, 110, 110, 255, 264, 866, 691, 440],
[44.29, 65.46, 79.99, 110, 110, 255, 263.99, 852, 676, 438],
[44.26, 64.75, 79.99, 110, 110, 255, 264, 862, 683, 439],
[44.26, 65.05, 79.99, 110, 110, 255, 263.99, 838, 667, 434],
[44.49, 63.88, 80, 110, 110, 255, 263.99, 841, 671, 435],
[44.52, 64.35, 79.99, 110, 110, 254.99, 264, 856, 679, 438],
[44.36, 64.5, 80, 110, 109.99, 254.99, 263.99, 869, 691, 440],
[44.36, 63.27, 80, 110, 110, 255, 264, 852, 678, 437],
[44.55, 65.16, 79.99, 110, 110, 255, 263.99, 857, 683, 437],
[44.72, 65.37, 79.99, 110, 110, 255, 263.99, 857, 683, 437],
[44.65, 67.03, 79.99, 110, 110, 254.99, 263.99, 863, 690, 438],
[44.75, 67.01, 80, 110, 110, 254.99, 264, 864, 683, 438],
[44.88, 66.28, 80, 110, 110, 255, 264, 870, 695, 440],
[44.88, 65.8, 79.99, 110, 110, 255, 263.99, 865, 691, 439],
[44.85, 64.94, 79.99, 110, 110, 255, 263.99, 859, 685, 439],
[45.05, 64.52, 80, 110, 110, 255, 263.99, 849, 678, 436],
[44.78, 64.82, 80, 110, 110, 254.99, 264, 871, 697, 440],
[44.95, 65.68, 80, 110, 109.99, 254.99, 264, 868, 691, 440],
[44.98, 65.72, 80, 110, 109.99, 255, 264, 866, 690, 439],
[44.98, 66.4, 80, 109.99, 110, 255, 263.99, 866, 690, 439],
[45.15, 64.7, 80, 110, 110, 255, 264, 867, 691, 440],
[45.28, 64.85, 79.99, 110, 110, 254.99, 263.99, 857, 686, 439],
[45.22, 63.39, 80, 110, 110, 255, 263.99, 864, 683, 438],
[45.22, 64.35, 79.99, 110, 109.99, 254.99, 264, 865, 683, 439],
[45.35, 64.25, 79.99, 109.99, 109.99, 255.22, 264, 880, 695, 441],
[45.45, 63.43, 79.99, 110, 110, 255, 263.99, 881, 697, 441],
[45.75, 63.24, 79.99, 110, 110, 255, 263.99, 877, 699, 440],
[45.58, 62.19, 80, 110, 109.99, 255, 264, 876, 693, 440],
[45.72, 62.07, 79.99, 110, 110, 254.99, 263.99, 881, 696, 441],
[45.85, 63.42, 80, 110, 109.99, 254.75, 263.99, 890, 695, 440],
[45.82, 65.46, 79.99, 110, 110, 255, 263.99, 876, 698, 440],
[45.85, 66.28, 80, 110, 110, 255, 264, 870, 691, 440],
[45.88, 67.33, 80, 109.99, 110, 255, 264, 871, 686, 440],
[45.88, 65.88, 79.99, 110, 110, 254.99, 264, 859, 686, 438],
[45.99, 65.28, 80, 110, 110, 255, 263.99, 845, 680, 436],
[45.38, 66.65, 79.99, 110, 110, 254.99, 264.01, 848, 675, 437],
[45.31, 66.72, 79.99, 110, 110, 254.99, 264, 862, 691, 439],
[44.98, 66.71, 80, 110, 110, 254.97, 263.98, 882, 703, 442],
[44.95, 67.12, 79.99, 109.99, 110, 255, 264, 878, 702, 442],
[44.92, 67.08, 80, 110, 110, 255, 263.99, 883, 706, 442],
[44.95, 67.64, 79.99, 109.99, 110, 255, 264, 889, 708, 442],
[45.62, 66.07, 80, 110, 110, 255, 264, 905, 717, 436],
[45.58, 64.98, 79.99, 110, 110, 254.99, 264, 892, 710, 443],
[45.62, 65.9, 79.99, 110, 110, 254.99, 264, 890, 708, 443],
[45.68, 66.5, 79.99, 110, 110, 254.95, 264, 897, 712, 442],
[45.85, 66.73, 79.99, 110, 110, 255, 264, 884, 700, 442],
[45.88, 68.35, 79.99, 110, 109.99, 255, 263.99, 880, 701, 442],
[46.09, 68.74, 79.99, 110, 110, 254.99, 263.99, 878, 700, 442],
[46.22, 65.58, 79.99, 109.99, 110, 255, 263.99, 875, 695, 441],
[46.22, 65.01, 79.99, 109.99, 110, 254.99, 264, 876, 700, 442],
[46.36, 65.98, 79.99, 110, 110, 255, 263.99, 869, 691, 440],
[46.53, 66.33, 79.99, 110, 109.99, 254.99, 263.99, 869, 692, 440],
[47.42, 68.65, 79.99, 110, 109.99, 254.99, 264, 878, 703, 441],
[47.8, 68.18, 80, 110, 109.99, 254.98, 264, 872, 693, 440],
[47.8, 68.37, 79.99, 110, 110, 254.99, 263.99, 873, 690, 440],
[47.84, 68.3, 80, 110, 110, 255, 264, 881, 696, 441],
[47.91, 69.02, 79.99, 110, 110, 254.99, 263.99, 871, 691, 440],
[47.8, 68.41, 80, 110, 110, 254.99, 264, 890, 705, 442],
[47.73, 67.95, 79.99, 110, 110, 254.99, 264, 883, 703, 442],
[47.77, 67.71, 79.99, 110, 109.99, 255, 263.99, 878, 692, 441],
[47.56, 67.46, 79.99, 110, 110, 255, 263.99, 878, 695, 441],
[47.63, 65.68, 79.99, 110, 109.99, 254.99, 263.99, 875, 691, 440],
[47.8, 66.64, 79.99, 110, 110, 254.99, 264, 873, 692, 440],
[47.56, 66.73, 80, 109.99, 109.99, 254.99, 264, 871, 692, 440],
[47.49, 67.45, 79.99, 110, 110, 254.99, 263.99, 870, 689, 439],
[47.11, 65.89, 80, 110, 110, 255, 264, 868, 686, 440],
[47.45, 64.22, 79.99, 110, 110, 254.99, 263.99, 870, 686, 439],
[47.42, 64.38, 80, 110, 110, 254.99, 263.99, 844, 669, 434],
[47.52, 64.75, 79.99, 110, 110, 255, 264, 847, 670, 435],
[47.42, 64.74, 80, 110, 110, 255, 264, 857, 681, 437],
[47.32, 65.17, 79.99, 110, 110, 255, 263.99, 858, 679, 438],
[47.45, 65.58, 80, 110, 109.99, 255, 263.99, 862, 685, 438],
[47.56, 66.12, 79.99, 110, 109.99, 254.99, 264, 861, 682, 438],
[47.49, 67.11, 79.99, 110, 110, 254.99, 263.99, 850, 671, 435],
[47.42, 65.76, 79.99, 110, 110, 254.99, 264, 867, 688, 439],
[47.59, 66.37, 80, 110, 110, 255, 264, 857, 676, 437],
[47.38, 67.38, 79.99, 110, 110, 255, 264, 863, 683, 438],
[47.38, 66.84, 79.99, 110, 110, 254.99, 264, 865, 684, 438],
[47.42, 67.24, 79.99, 110, 110, 255, 263.99, 876, 695, 440],
[47.42, 66.68, 79.99, 110, 110, 254.99, 263.99, 875, 689, 440],
[47.59, 67.26, 80, 110, 110, 255, 264, 879, 694, 440],
[47.32, 67.49, 80, 109.99, 109.99, 254.99, 264, 859, 682, 437],
[47.07, 66.78, 79.99, 110, 109.99, 254.99, 263.99, 868, 692, 440],
[47.07, 66.57, 80, 110, 109.99, 254.99, 263.99, 863, 684, 438],
[47.25, 66.72, 80, 110, 110, 255, 264, 863, 685, 437],
[47.01, 65.9, 80, 109.99, 110, 254.99, 264, 857, 682, 438],
[47.11, 66.72, 79.99, 110, 110, 255, 263.99, 875, 692, 440],
[47.07, 66.3, 79.99, 110, 110, 254.99, 264, 867, 691, 439],
[47.32, 66.06, 79.99, 110, 109.99, 255, 264, 850, 674, 436],
[47.18, 65.13, 79.99, 109.99, 110, 255, 264, 864, 683, 438],
[47.18, 65.84, 79.99, 110, 110, 255, 263.99, 870, 689, 439],
[47.11, 66.29, 79.99, 110, 110, 254.99, 263.99, 884, 703, 441],
[47.11, 66.73, 80, 110, 109.99, 255, 263.99, 866, 686, 439],
[47.04, 66.63, 80, 110, 110, 254.99, 263.99, 860, 686, 438],
[46.94, 69.78, 79.99, 110, 110, 255, 264, 872, 693, 440],
[47.18, 69.75, 79.99, 110, 110, 254.99, 264, 856, 675, 437],
[46.49, 66.39, 80, 109.99, 110, 254.99, 264.01, 871, 693, 440],
[46.19, 66.81, 79.99, 110, 110, 255, 263.99, 874, 697, 440],
[46.05, 66.72, 79.99, 110, 110, 254.99, 264, 873, 693, 440],
[45.62, 68.13, 79.99, 109.99, 110, 254.83, 262.47, 869, 692, 441],
[45.58, 67.74, 79.99, 110, 109.99, 255, 263.99, 856, 686, 438],
[44.88, 67.8, 79.99, 110, 109.99, 254.99, 263.99, 847, 681, 437],
[44.85, 68, 80, 110, 109.99, 254.99, 263.99, 857, 687, 439],
[44.59, 66.92, 79.99, 110, 110, 255.02, 263.83, 864, 690, 440],
[44.59, 67.62, 80, 109.99, 110, 255, 264, 849, 678, 437],
[44.52, 66.19, 79.99, 110, 110, 255, 264, 851, 679, 438],
[44.55, 65.76, 79.99, 109.99, 110, 254.99, 264, 866, 691, 440],
[44.26, 66.72, 80, 110, 110, 254.99, 263.99, 855, 680, 438],
[44.62, 67.22, 80, 109.99, 110, 255, 264, 854, 680, 438],
[44.82, 67.3, 80, 110, 110, 254.99, 263.99, 849, 682, 438],
[44.78, 67.23, 79.99, 110, 109.99, 255, 264, 848, 681, 437],
[44.49, 67.27, 79.99, 110, 109.99, 254.93, 264.06, 863, 691, 440],
[44.39, 68.56, 80, 109.99, 110, 254.99, 263.99, 865, 697, 440],
[44.39, 68.71, 79.99, 109.99, 110, 254.99, 264, 862, 690, 440],
[44.95, 67.53, 79.99, 110, 109.99, 254.99, 264, 861, 687, 439],
[50.41, 65.79, 80, 110, 109.99, 254.99, 263.99, 859, 679, 439],
[50.15, 65.8, 79.99, 110, 110, 254.99, 263.99, 868, 691, 441],
[50.12, 64.65, 80, 109.99, 110, 254.99, 263.99, 860, 676, 440],
[50.01, 66.83, 80, 110, 110, 254.99, 264, 858, 683, 440],
[50.01, 66.72, 79.99, 109.99, 110, 255, 264, 864, 690, 442],
[50.19, 66.11, 80, 110, 110, 254.99, 264, 882, 701, 444],
[50.15, 66.45, 79.99, 109.99, 110, 255, 263.99, 879, 695, 443],
[49.94, 65.46, 80, 110, 110, 255, 264, 863, 681, 441],
[50.04, 66.54, 80, 110, 109.99, 255, 264, 864, 684, 441],
[49.83, 66.11, 80, 110, 110, 254.99, 263.99, 859, 677, 439],
[50.01, 65.44, 80, 110, 110, 255, 263.99, 860, 681, 440],
[50.04, 66.3, 79.99, 109.99, 110, 255, 263.99, 858, 681, 439],
[50.08, 65.77, 79.99, 110, 110, 254.99, 263.99, 835, 666, 436],
[49.83, 66.25, 80, 110, 109.99, 255, 263.99, 860, 684, 440],
[49.68, 66.21, 80, 110, 110, 255, 263.99, 842, 670, 437],
[49.72, 67.96, 80, 109.99, 110, 255, 264, 862, 684, 441],
[49.79, 67.48, 80, 110, 110, 255, 264, 849, 671, 438],
[49.86, 65.56, 79.99, 109.99, 110, 254.99, 264, 848, 676, 438],
[49.83, 64.74, 80, 110, 110, 255, 263.99, 834, 663, 435],
[50.04, 65.88, 79.99, 110, 110, 255, 274.12, 849, 674, 437],
[50.04, 66.53, 79.99, 110, 110, 255, 264, 848, 674, 439],
[49.86, 67.09, 80, 110, 110, 255, 264, 844, 675, 437],
[50.56, 67.26, 79.99, 110, 110, 255, 263.7, 873, 683, 441],
[50.3, 67.04, 80, 110, 110, 255, 264, 872, 691, 442],
[50.45, 65.48, 80, 110, 110, 254.99, 261.51, 875, 678, 439],
[50.52, 65.31, 79.99, 110, 110, 254.99, 264, 871, 691, 442],
[50.41, 66.61, 79.99, 110, 110, 255, 262.47, 874, 687, 441],
[50.37, 66.12, 80, 110, 110, 254.99, 263.99, 880, 698, 443],
[50.41, 66.54, 79.99, 110, 110, 255, 264, 859, 685, 440],
[50.67, 66.94, 79.99, 110, 110, 255, 264, 869, 689, 442],
[50.34, 66.63, 80, 110, 110, 254.99, 263.99, 870, 692, 441],
[50.26, 67.33, 79.99, 110, 110, 255, 263.99, 869, 692, 442],
[50.15, 68.15, 80, 110, 110, 255, 263.93, 877, 684, 441],
[50.23, 68.23, 79.99, 110, 110, 255, 263.99, 858, 681, 439],
[50.52, 67.74, 80, 110, 109.99, 255, 263.99, 866, 689, 441],
[50.23, 66.34, 80, 110, 110, 255, 263.87, 897, 705, 444],
[50.15, 64.93, 80, 110, 110, 255, 264, 877, 695, 443],
[50.3, 65.07, 79.99, 109.99, 110, 255, 263.99, 871, 685, 441],
[50.08, 65.25, 80, 110, 110, 254.99, 263.99, 848, 673, 437],
[50.04, 65.19, 79.99, 110, 110, 255, 264, 840, 666, 436],
[50.23, 64.99, 80, 110, 110, 254.99, 263.98, 863, 683, 440],
[50.26, 64.33, 80, 109.99, 110, 254.99, 263.99, 875, 690, 442],
[50.26, 64.08, 80, 109.99, 110, 254.99, 264, 882, 694, 443],
[50.45, 64.09, 80, 109.99, 110, 255, 263.99, 877, 694, 442],
[50.19, 65.45, 79.99, 109.99, 109.99, 254.99, 264, 871, 690, 441],
[50.12, 64.92, 79.99, 110, 110, 255, 264, 869, 686, 441],
[50.08, 65.69, 79.99, 110, 110, 255, 264, 872, 687, 441],
[50.04, 67.43, 79.99, 110, 110, 255, 264, 847, 668, 436],
[49.97, 66.4, 79.99, 110, 110, 254.99, 264, 862, 680, 440],
[50.19, 66.63, 79.99, 109.99, 109.99, 254.99, 263.99, 866, 687, 440],
[49.97, 65.22, 80, 110, 110, 254.99, 264, 863, 682, 439],
[49.97, 64.15, 79.99, 110, 109.99, 254.99, 264, 858, 678, 438],
[50.23, 64.54, 79.99, 110, 110, 255, 263.99, 861, 682, 439],
[50.12, 65.65, 79.99, 110, 109.99, 254.99, 263.99, 871, 683, 440],
[50.01, 66.16, 79.99, 109.99, 110, 255, 264, 861, 680, 439],
[49.94, 67.93, 79.99, 110, 109.99, 255, 263.99, 868, 689, 440],
[49.72, 64.86, 79.99, 110, 109.99, 255, 264, 867, 685, 440],
[49.94, 65.71, 79.99, 110, 110, 254.99, 264, 878, 691, 442],
[49.86, 68.37, 79.99, 110, 110, 255, 263.99, 860, 681, 438],
[49.97, 68.68, 79.99, 110, 110, 254.99, 264, 844, 670, 436],
[49.94, 67.8, 79.99, 110, 110, 255, 263.99, 871, 688, 440],
[49.72, 68.91, 80, 109.99, 110, 254.99, 263.99, 878, 693, 441],
[49.79, 68.6, 79.99, 110, 110, 254.99, 264, 884, 698, 443],
[49.86, 68.06, 79.99, 110, 110, 255, 263.99, 879, 694, 442],
[49.57, 66.19, 79.99, 109.99, 109.99, 255, 264, 871, 688, 440],
[49.11, 64.4, 79.99, 110, 110, 255, 264, 870, 687, 441],
[49.11, 64.17, 79.99, 109.99, 109.99, 254.99, 264, 871, 689, 440],
[48.57, 68.41, 80, 110, 110, 254.99, 264, 851, 678, 437],
[48.47, 69.89, 80, 110, 110, 254.99, 264, 849, 674, 436],
[47.66, 68.27, 80, 110, 110, 254.99, 263.99, 866, 690, 440],
[47.91, 67.55, 79.99, 109.99, 110, 255, 264, 859, 685, 439],
[48.08, 66.66, 80, 109.99, 110, 254.99, 263.99, 869, 691, 441],
[48.19, 66.04, 80, 109.99, 110, 254.99, 263.99, 864, 687, 440],
[48.22, 65.59, 80, 110, 110, 254.99, 263.99, 854, 678, 438],
[48.33, 65.84, 80, 110, 109.99, 254.99, 264, 856, 681, 438],
[48.57, 66.11, 79.99, 110, 110, 254.99, 263.99, 857, 686, 439],
[48.82, 65.58, 79.99, 110, 110, 254.99, 264, 883, 701, 442],
[48.89, 67.15, 80, 109.99, 109.99, 255, 264, 879, 699, 443],
[49.54, 66.28, 80, 109.99, 110, 254.99, 263.99, 872, 693, 442],
[51.82, 66.01, 80, 110, 110, 254.99, 264, 860, 683, 441],
[52.01, 65.54, 79.99, 109.99, 110, 254.99, 264, 868, 685, 443],
[52.93, 65.45, 80, 110, 110, 254.99, 263.98, 861, 681, 442],
[52.97, 65.26, 79.99, 110, 110, 255, 264, 861, 685, 443],
[54.06, 64.53, 80, 110, 110, 255, 264, 885, 697, 447],
[55.3, 64.07, 79.99, 110, 110, 254.99, 263.99, 835, 660, 435],
[55.34, 63.43, 80, 109.99, 110, 255, 264, 830, 659, 435],
[55.46, 63.06, 79.99, 109.99, 110, 255, 264, 876, 692, 443],
[55.66, 63.65, 80, 110, 109.99, 254.99, 263.99, 854, 678, 441],
[55.58, 63.84, 79.99, 110, 109.99, 254.99, 263.99, 850, 676, 439],
[55.26, 64.39, 80, 110, 110, 254.99, 263.99, 853, 673, 439],
[54.94, 64.39, 80, 109.99, 110, 254.99, 264, 869, 683, 442],
[54.42, 61.61, 79.99, 110, 110, 255, 264, 851, 672, 438],
[54.3, 61.51, 79.99, 109.99, 109.99, 255, 263.99, 850, 672, 439],
[54.1, 60.42, 80, 109.99, 110, 255, 264, 860, 679, 440],
[54.38, 60.3, 79.99, 110, 110, 255, 263.99, 853, 673, 439],
[54.14, 60.06, 79.99, 109.99, 110, 255, 264, 864, 682, 441],
[54.1, 60.88, 80, 110, 110, 255, 264, 855, 671, 439],
[53.94, 62.36, 80, 110, 110, 255, 264, 869, 683, 441],
[53.74, 63.01, 80, 110, 109.99, 254.99, 264, 869, 683, 441],
[53.74, 63.72, 79.99, 109.99, 109.99, 255, 263.99, 863, 681, 441],
[53.74, 63.4, 80, 110, 110, 254.99, 263.99, 862, 680, 440],
[53.63, 63.41, 80, 110, 110, 254.99, 263.99, 874, 689, 443],
[52.74, 64.21, 80, 110, 110, 255, 264, 867, 687, 442],
[52.62, 64.72, 80, 110, 110, 255, 264, 854, 677, 440],
[52.24, 66.04, 79.99, 110, 110, 255.31, 264, 901, 702, 445],
[52.43, 64.99, 80, 110, 110, 254.99, 264, 871, 691, 443],
[51.9, 65.13, 80, 109.99, 110, 254.99, 264, 867, 686, 442],
[51.9, 65.87, 80, 110, 110, 254.99, 264, 878, 694, 444],
[51.9, 65.42, 79.99, 109.99, 110, 255, 264, 866, 686, 442],
[52.16, 67.18, 79.99, 109.99, 110, 255, 264, 885, 700, 443],
[52.09, 67.33, 80, 110, 110, 255, 264, 882, 700, 446],
[47.84, 65.9, 79.99, 110, 110, 254.99, 264, 884, 701, 443],
[47.8, 65.79, 79.99, 110, 110, 255, 263.99, 883, 699, 443],
[47.11, 64.79, 79.99, 110, 110, 254.99, 264, 847, 672, 435],
[47.21, 65.34, 80, 110, 109.99, 255, 263.99, 835, 666, 433],
[47.56, 65.13, 80, 110, 110, 254.99, 263.99, 847, 671, 435],
[47.56, 65.4, 79.99, 110, 109.99, 255, 264, 851, 677, 436],
[47.73, 65.38, 79.99, 110, 109.99, 255, 264, 854, 678, 436],
[47.52, 64.36, 79.99, 109.99, 110, 254.99, 264, 852, 677, 436],
[47.45, 64.57, 79.99, 110, 110, 255, 264, 842, 669, 434],
[47.52, 63.71, 79.99, 109.99, 109.99, 254.99, 264, 844, 672, 436],
[47.42, 63.7, 80, 110, 110, 255, 263.99, 873, 699, 441],
[47.52, 66.06, 79.99, 110, 110, 254.99, 263.99, 865, 692, 440],
[47.7, 64.98, 80, 109.99, 110, 254.99, 263.99, 842, 673, 435],
[48.26, 64.48, 80, 110, 110, 255, 263.99, 835, 666, 433],
[48.4, 63.88, 80, 110, 110, 255, 264, 837, 671, 435],
[48.72, 64.84, 80, 110, 110, 254.99, 263.99, 851, 677, 437],
[50.81, 63.83, 79.99, 109.99, 109.99, 254.99, 264, 855, 682, 439],
[51, 63.87, 80, 109.99, 110, 255, 263.99, 868, 688, 441],
[51.15, 64.85, 79.99, 110, 110, 254.99, 264, 861, 675, 438],
[51.3, 65.81, 80, 109.99, 110, 255, 264, 875, 694, 442],
[52.28, 63.71, 80, 110, 110, 254.99, 264, 865, 685, 442],
[57.84, 61.79, 79.99, 110, 110, 254.99, 263.99, 866, 683, 441],
[56.9, 60.65, 80, 110, 109.99, 255, 264, 866, 681, 440],
[56.9, 60.27, 79.99, 110, 109.99, 254.99, 264, 878, 694, 442],
[56.95, 61.59, 79.99, 110, 110, 255, 264, 844, 664, 435],
[47.21, 63.98, 80, 109.99, 110, 254.91, 263.99, 876, 696, 440],
[47.11, 65.36, 79.99, 110, 110, 254.99, 264, 868, 697, 438],
[47.04, 65.63, 79.99, 110, 110, 254.94, 264, 873, 690, 439],
[47.11, 64.19, 80, 110, 110, 255, 264, 866, 686, 437],
[47.32, 64.23, 79.99, 110, 110, 255, 263.99, 862, 686, 437],
[47.35, 65.43, 79.99, 110, 109.99, 255, 263.99, 883, 699, 440],
[47.38, 66.27, 80, 110, 110, 255, 264, 886, 705, 441],
[47.56, 68.37, 80, 110, 110, 254.99, 264, 879, 705, 440],
[47.52, 70.64, 80, 110, 110, 255, 264, 878, 695, 440],
[47.77, 65.51, 79.99, 110, 110, 255, 263.99, 887, 704, 441],
[47.63, 66.27, 79.99, 110, 110, 255.16, 264.15, 883, 704, 439],
[47.59, 66.62, 80, 110, 110, 255, 264, 882, 704, 440],
[46.73, 67.27, 80, 110, 110, 254.99, 264, 879, 695, 440],
[46.6, 68.33, 79.99, 110, 109.99, 255, 263.99, 878, 695, 439],
[45.92, 67.56, 79.99, 110, 110, 254.99, 263.99, 883, 703, 439],
[46.83, 64.13, 80, 110, 110, 254.99, 263.99, 882, 698, 440],
[46.97, 64.43, 79.99, 110, 110, 255, 263.99, 879, 697, 439],
[47.42, 64.58, 80, 110, 110, 254.99, 264, 861, 684, 436],
[47.52, 67.3, 80, 110, 110, 255, 264, 867, 689, 437],
[47.91, 67.45, 80, 110, 110, 254.99, 264, 865, 686, 437],
[47.91, 66.96, 79.99, 109.99, 110, 255, 264, 863, 681, 436],
[48.15, 65.42, 79.99, 110, 109.99, 254.99, 264, 852, 680, 435],
[48.29, 66.46, 79.99, 110, 110, 255, 263.99, 873, 696, 438],
[48.72, 66.21, 80, 110, 110, 254.99, 264, 863, 679, 436],
[50.52, 64.62, 79.99, 110, 109.99, 255, 278.78, 858, 677, 436],
[50.67, 66.57, 80, 109.99, 110, 255, 264, 870, 686, 439],
[52.97, 65.15, 80, 110, 110, 254.99, 263.99, 883, 697, 443],
[53.08, 66.04, 80, 110, 110, 255, 264, 882, 692, 443],
[52.2, 66.4, 80, 109.99, 109.99, 254.99, 264, 877, 692, 443],
[46.9, 65.99, 79.99, 109.99, 110, 254.97, 264, 885, 699, 441],
[46.9, 65.64, 79.99, 110, 110, 255, 263.99, 885, 705, 441],
[46.77, 66.04, 80, 110, 109.99, 254.99, 263.99, 875, 692, 439],
[46.87, 67.11, 79.99, 110, 110, 254.99, 264, 878, 702, 439],
[46.9, 66.76, 80, 110, 110, 254.99, 264, 878, 694, 439],
[46.8, 66.35, 80, 109.99, 110, 255, 264, 878, 699, 439],
[46.8, 65.1, 80, 110, 109.99, 254.99, 263.99, 857, 684, 436],
[46.83, 64.21, 80, 110, 110, 254.99, 263.98, 848, 672, 433],
[46.15, 68.15, 80, 110, 110, 254.99, 263.99, 892, 705, 441],
[46.53, 66.89, 80, 110, 110, 254.99, 264, 884, 702, 441],
[46.29, 65.89, 79.99, 110, 110, 254.99, 263.99, 876, 694, 438],
[43.48, 63.11, 79.99, 109.99, 110, 254.99, 263.99, 869, 680, 439],
[43.54, 63.59, 80, 110, 110, 255, 263.99, 877, 689, 440],
[43.9, 64.1, 79.99, 109.99, 109.99, 255, 263.99, 860, 684, 437],
[44.36, 64.99, 79.99, 110, 110, 255, 264, 877, 684, 440],
[44.42, 66.17, 79.99, 110, 109.99, 254.99, 264, 870, 683, 439],
[44.49, 65.42, 79.99, 110, 110, 255, 264, 871, 682, 439],
[45.02, 65.41, 80, 109.99, 110, 254.99, 263.99, 876, 689, 439],
[45.52, 66.4, 80, 110, 110, 255, 263.52, 863, 667, 435],
[45.45, 66.69, 79.99, 110, 110, 255, 264, 846, 661, 435],
[45.88, 63.59, 79.99, 110, 109.99, 255, 263.63, 882, 673, 437],
[45.88, 63.39, 80, 109.99, 110, 255, 264, 877, 684, 439],
[45.99, 63.92, 79.99, 109.99, 110, 254.99, 264, 875, 683, 439],
[46.63, 64.51, 80, 110, 110, 255, 263.98, 876, 682, 439],
[46.73, 63.59, 80, 110, 109.99, 254.99, 264, 885, 693, 440],
[46.73, 65.24, 79.99, 110, 109.99, 254.99, 263.99, 856, 671, 435],
[46.87, 65.12, 79.99, 110, 110, 254.99, 264, 875, 684, 438],
[47.07, 64.31, 79.99, 110, 110, 254.99, 263.99, 875, 684, 439],
[46.94, 63.28, 80, 110, 109.99, 255, 264, 877, 688, 440],
[47.01, 66.31, 80, 110, 110, 254.99, 264, 879, 687, 439],
[47.18, 65.4, 79.99, 110, 110, 255, 263.99, 874, 685, 439],
[45.72, 66.95, 80, 110, 110, 254.99, 264, 882, 691, 440],
[45.72, 66.13, 79.99, 109.99, 110, 254.99, 264, 881, 692, 440],
[45.72, 65.17, 79.99, 110, 110, 254.99, 263.99, 865, 678, 438],
[45.65, 66.12, 79.99, 110, 110, 255.3, 264, 873, 677, 437],
[45.68, 65.33, 80, 110, 110, 254.99, 263.99, 860, 673, 436],
[45.68, 66.82, 80, 110, 110, 255, 263.99, 881, 684, 439],
[45.82, 65.96, 79.99, 110, 110, 255, 264, 874, 685, 439],
[45.41, 64.43, 79.99, 110, 110, 254.97, 263.99, 876, 681, 439],
[45.35, 65.02, 80, 110, 109.99, 254.99, 264, 880, 688, 438],
[44.88, 67.89, 80, 110, 110, 255, 264, 879, 685, 439],
[44.36, 66.39, 79.99, 110, 110, 254.99, 264, 883, 688, 439],
[44.65, 65.21, 80, 109.99, 109.99, 254.99, 263.99, 884, 691, 440],
[44.55, 64.66, 80, 110, 109.99, 254.99, 264, 867, 681, 438],
[44.46, 65.4, 80, 110, 110, 254.99, 264.01, 875, 680, 439],
[44.39, 67.62, 79.99, 110, 110, 255, 264, 878, 685, 440],
[44.16, 67.5, 79.99, 109.99, 110, 254.99, 264, 879, 685, 439],
[44, 67.37, 79.99, 110, 110, 254.99, 264, 888, 693, 440],
[44, 66.34, 79.99, 110, 110, 255, 263.99, 891, 696, 440],
[43.35, 66.34, 80, 110, 110, 254.99, 264, 890, 694, 441],
[43.32, 64.52, 80, 110, 110, 254.99, 264.01, 885, 688, 440],
[43.38, 65.33, 80, 110, 110, 254.99, 264, 888, 692, 440],
[43.03, 65.08, 80, 110, 110, 254.99, 264, 869, 684, 439],
[43.22, 66.64, 80, 110, 110, 255, 264, 897, 702, 441],
[43.25, 66.38, 79.99, 110, 110, 254.99, 264, 891, 695, 441],
[42.9, 64.11, 80, 110, 110, 254.99, 264, 875, 685, 440],
[42.87, 65.01, 79.99, 110, 109.99, 255, 263.99, 896, 695, 440],
[52.89, 64.28, 80, 110, 110, 254.99, 264, 827, 642, 432],
[52.97, 63.6, 80, 110, 110, 255, 263.99, 836, 649, 435],
[52.85, 63.86, 80, 109.99, 109.99, 254.99, 264, 845, 655, 436],
[52.77, 63.46, 80, 110, 110, 255, 263.99, 835, 651, 434],
[52.24, 66.45, 79.99, 109.99, 110, 254.99, 263.99, 850, 657, 437],
[52.28, 65.82, 80, 110, 110, 255, 264, 873, 678, 443],
[52.31, 64.8, 79.99, 110, 109.99, 255, 264, 873, 679, 443],
[50.41, 65.57, 79.99, 110, 110, 254.99, 263.99, 858, 668, 440],
[50.59, 64.58, 80, 109.99, 110, 254.99, 264, 864, 673, 440],
[50.59, 64.51, 79.99, 109.99, 110, 254.99, 263.99, 864, 673, 440],
[49.61, 63.73, 79.99, 110, 110, 254.99, 263.99, 858, 667, 438],
[49.65, 63.61, 80, 110, 110, 255, 264, 870, 675, 441],
[49.32, 66.28, 80, 110, 110, 254.99, 263.99, 873, 679, 440],
[49.21, 65.57, 80, 110, 110, 255, 263.99, 876, 680, 440],
[48.96, 65.02, 80, 110, 110, 254.99, 264, 855, 669, 437],
[48.93, 65.99, 79.99, 110, 109.99, 255, 264, 868, 673, 438],
[48.82, 65.86, 79.99, 110, 109.99, 255, 264, 865, 678, 438],
[48.86, 65.77, 79.99, 110, 110, 254.99, 264, 874, 679, 439],
[48.79, 65.67, 80, 110, 110, 255, 264, 867, 679, 438],
[48.96, 65.39, 79.99, 110, 110, 255, 264, 864, 671, 438],
[48.75, 64.18, 80, 110, 110, 255, 264, 866, 676, 438],
[48.82, 64.25, 80, 110, 110, 255, 264, 872, 677, 439],
[48.96, 64.34, 79.99, 109.99, 109.99, 255, 263.99, 864, 669, 438],
[49.25, 64.85, 79.99, 110, 109.99, 255, 263.99, 879, 686, 441],
[48.93, 65.13, 80, 110, 109.99, 255.6, 264, 896, 684, 440],
[49.11, 66.72, 80, 110, 110, 255, 264, 875, 680, 440],
[49.29, 62.96, 79.99, 110, 109.99, 254.97, 264, 842, 659, 434],
[49.36, 62.73, 79.99, 110, 109.99, 254.99, 264, 836, 653, 433],
[49.83, 64.74, 79.99, 110, 110, 254.99, 264, 836, 652, 433],
[49.79, 64.41, 79.99, 110, 110, 254.99, 263.99, 842, 655, 433],
[50.19, 64.62, 80, 110, 110, 254.99, 264, 869, 679, 439],
[50.48, 65.35, 80, 110, 109.99, 255, 264, 870, 677, 439],
[50.59, 62.7, 79.99, 109.99, 110, 255, 263.99, 852, 663, 436],
[50.45, 63.73, 79.99, 110, 109.99, 254.74, 263.77, 863, 671, 437],
[50.45, 63.86, 80, 110, 110, 255, 263.99, 850, 660, 435],
[50.3, 63.34, 79.99, 110, 110, 255, 263.99, 877, 679, 440],
[50.34, 63.15, 80, 110, 110, 255, 263.89, 863, 671, 437],
[50.23, 63.42, 80, 110, 109.99, 255, 263.99, 864, 674, 438],
[49.72, 64.37, 79.99, 110, 110, 254.99, 263.99, 865, 672, 438],
[49.68, 64.7, 80, 110, 109.99, 255, 264, 867, 675, 437],
[49.36, 64.62, 79.99, 109.99, 110, 255, 264, 861, 671, 436],
[49.25, 65.42, 79.99, 110, 109.99, 255, 264, 862, 671, 437],
[49.47, 65.49, 80, 109.99, 110, 254.99, 263.98, 838, 652, 431],
[49.07, 64.11, 79.99, 110, 110, 255, 264, 834, 642, 431],
[49.14, 62.77, 79.99, 110, 110, 255, 264, 841, 652, 432],
[49.18, 63.59, 80, 110, 109.99, 255, 264, 836, 651, 431],
[48.68, 64.43, 80, 110, 110, 255, 264, 845, 660, 434],
[48.43, 63.39, 80, 110, 110, 255, 263.99, 838, 652, 432],
[46.02, 64.29, 80, 110, 110, 255, 263.99, 849, 666, 433],
[45.92, 65.25, 79.99, 110, 110, 254.99, 264, 844, 662, 432],
[46.09, 63.34, 80, 110, 110, 254.99, 263.99, 868, 678, 436],
[46.12, 64.55, 80, 110, 110, 255, 264, 860, 670, 435],
[45.99, 65.87, 79.99, 110, 110, 254.99, 264, 876, 683, 437],
[46.15, 66.87, 79.99, 110, 110, 255, 264, 866, 677, 436],
[46.46, 66.86, 79.99, 109.99, 110, 254.99, 264, 870, 677, 437],
[46.66, 65.87, 79.99, 109.99, 110, 254.99, 264, 878, 680, 437],
[46.53, 65.74, 79.99, 110, 109.99, 255, 263.98, 875, 683, 437],
[46.9, 67.61, 79.99, 110, 110, 254.99, 263.99, 854, 666, 433],
[46.6, 65.72, 80, 110, 110, 255, 263.99, 864, 673, 436],
[46.05, 68.83, 80, 110, 110, 254.99, 263.99, 862, 674, 435],
[46.02, 64.78, 80, 110, 109.99, 254.99, 263.99, 882, 687, 439],
[46.19, 63.77, 80, 109.99, 109.99, 255, 264, 875, 684, 438],
[46.05, 61.52, 80, 110, 110, 254.99, 264, 868, 675, 436],
[45.92, 61.53, 79.99, 110, 110, 254.99, 263.99, 870, 679, 437],
[45.92, 62.6, 79.99, 110, 110, 254.99, 263.99, 863, 674, 436],
[46.49, 67.4, 80, 110, 109.99, 255, 263.99, 866, 682, 437],
[46.19, 67.91, 79.99, 110, 110, 254.99, 264, 864, 681, 436],
[46.22, 67.68, 79.99, 110, 110, 255, 264, 865, 679, 436],
[46.32, 66.22, 79.99, 110, 109.99, 255.34, 261.59, 870, 682, 436],
[46.39, 66.76, 79.99, 110, 110, 254.99, 263.99, 867, 680, 437],
[46.43, 65.08, 79.99, 110, 110, 254.99, 263.99, 868, 678, 437],
[46.46, 68.52, 80, 109.99, 110, 254.99, 264, 868, 677, 436],
[46.49, 67.85, 79.99, 110, 110, 254.99, 263.99, 869, 681, 436],
[46.46, 66.96, 80, 110, 110, 254.99, 264, 832, 650, 429],
[46.46, 66.51, 80, 110, 110, 255, 264, 836, 656, 431],
[46.46, 66.71, 80, 109.99, 110, 254.99, 264, 841, 660, 432],
[46.6, 66.84, 79.99, 110, 110, 254.99, 264, 843, 659, 432],
[50.12, 62.69, 79.99, 110, 109.99, 255, 264, 869, 677, 439],
[50.15, 62.57, 80, 110, 110, 254.99, 264, 857, 667, 438],
[52.16, 63.09, 79.99, 110, 109.99, 255, 264, 858, 665, 437],
[52.24, 63.23, 80, 110, 109.99, 255, 264, 863, 669, 439],
[52.28, 63.74, 80, 110, 110, 254.99, 264, 869, 674, 440],
[52.62, 64.01, 80, 109.99, 110, 254.99, 264, 868, 674, 441],
[52.81, 67.39, 79.99, 109.99, 110, 254.99, 263.99, 851, 657, 437],
[53.01, 66.52, 80, 110, 110, 255, 264, 830, 643, 435],
[53.04, 64.96, 80, 110, 110, 254.99, 264, 850, 659, 437],
[52.97, 64.45, 79.99, 110, 110, 254.99, 264, 843, 656, 436],
[52.16, 64.53, 80, 110, 110, 255, 263.99, 863, 670, 440],
[50.89, 64.89, 79.99, 110, 110, 255, 263.89, 850, 665, 437],
[50.81, 64.51, 80, 110, 110, 254.99, 263.98, 862, 673, 441],
[50.45, 64.96, 79.99, 110, 110, 254.99, 263.99, 875, 661, 436],
[50.45, 64.62, 79.99, 110, 110, 254.99, 263.99, 850, 664, 438],
[49.83, 64.67, 79.99, 109.99, 110, 255, 263.99, 881, 685, 442],
[49.79, 64.31, 80, 110, 110, 254.99, 264, 872, 682, 440],
[49.07, 63.56, 79.99, 110, 110, 255, 264, 854, 665, 436],
[48.86, 66.58, 79.99, 110, 110, 255, 261.75, 846, 656, 433],
[48.89, 65.82, 79.99, 109.99, 110, 255, 263.99, 860, 673, 437],
[47.91, 64.74, 80, 109.99, 109.99, 254.99, 263.99, 862, 673, 436],
[47.98, 65.27, 80, 110, 109.99, 254.99, 264, 865, 678, 438],
[47.98, 64.06, 79.99, 110, 110, 255, 264, 859, 672, 437],
[48.15, 63.41, 79.99, 109.99, 110, 255, 264, 868, 674, 438],
[47.94, 65.88, 79.98, 110, 110, 255, 264, 871, 680, 438],
[47.94, 64.92, 79.99, 109.99, 110, 255, 264, 861, 674, 437],
[47.66, 64.67, 80, 110, 110, 255, 264, 853, 667, 435],
[47.49, 65.51, 80, 110, 110, 255, 263.99, 861, 674, 437],
[47.07, 66.21, 80, 110, 110, 254.99, 264, 858, 667, 435],
[47.01, 66.31, 79.99, 110, 110, 255, 264, 868, 680, 437],
[47.04, 66.25, 80, 110, 110, 255, 264, 863, 673, 436],
[46.97, 64.81, 79.99, 110, 110, 255, 263.99, 860, 673, 436],
[47.01, 64.09, 80, 110, 110, 255, 263.99, 862, 674, 436],
[47.18, 65.17, 79.99, 109.99, 109.99, 255, 263.99, 847, 662, 433],
[47.04, 64.14, 79.99, 110, 110, 254.99, 264, 840, 659, 431],
[48.86, 63.27, 79.99, 110, 110, 254.99, 263.99, 850, 663, 434],
[48.79, 63.75, 80, 110, 110, 255.01, 263.99, 856, 657, 434],
[48.79, 63.42, 80, 110, 109.99, 254.99, 263.99, 832, 651, 431],
[51.94, 64.21, 79.99, 110, 110, 254.99, 264, 836, 648, 433],
[52.7, 65.49, 79.99, 110, 110, 255, 264, 837, 653, 434],
[51.82, 64.41, 80, 110, 110, 254.99, 264, 860, 668, 438],
[51.78, 64.34, 80, 110, 110, 255, 263.99, 847, 659, 437],
[51.75, 65.37, 80, 110, 109.99, 255, 264, 852, 664, 437],
[51.6, 64.29, 79.99, 110, 110, 254.99, 263.99, 837, 650, 435],
[49.94, 64.88, 80, 110, 110, 254.99, 263.97, 866, 674, 440],
[50.23, 65.74, 79.99, 110, 110, 255, 264, 873, 681, 441],
[49.97, 67.1, 79.99, 110, 110, 254.99, 263.99, 881, 667, 436],
[49.72, 67.21, 80, 110, 110, 254.99, 263.99, 848, 666, 435],
[49.54, 64.57, 80, 110, 110, 255, 264, 861, 674, 437],
[49.5, 64.03, 79.99, 109.99, 110, 255, 264, 856, 673, 436],
[48.86, 67.21, 79.99, 109.99, 109.99, 254.99, 263.99, 852, 670, 435],
[48.93, 66.25, 79.99, 110, 110, 255, 264, 854, 673, 435],
[48.64, 66.83, 79.99, 109.99, 110, 254.99, 264, 848, 664, 434],
[48.79, 65.47, 80, 109.99, 109.99, 255, 263.99, 847, 665, 433],
[48.57, 64.86, 80, 110, 110, 255, 264, 841, 660, 433],
[54.53, 64.32, 79.99, 110, 109.99, 255, 263.99, 867, 676, 440],
[54.57, 64.54, 80, 109.99, 110, 255, 263.99, 866, 674, 439],
[54.69, 64, 79.99, 110, 110, 255, 264, 876, 678, 441],
[55.3, 64.36, 80, 110, 110, 254.99, 263.99, 848, 660, 436],
[55.14, 64.49, 79.99, 110, 110, 255, 264, 855, 670, 438],
[55.02, 64.87, 79.99, 110, 110, 254.99, 263.98, 843, 660, 435],
[54.85, 65.52, 79.99, 109.99, 110, 254.99, 264, 840, 656, 434],
[54.53, 64.74, 79.99, 110, 110, 255, 263.99, 855, 667, 438],
[53.24, 65.1, 80, 110, 110, 255, 264, 865, 677, 440],
[53.12, 64.98, 80, 110, 110, 254.99, 264, 853, 667, 438],
[52.35, 64.28, 80, 109.99, 110, 254.99, 263.99, 843, 658, 435],
[52.28, 64.48, 79.99, 110, 110, 254.99, 264, 824, 648, 432],
[51.67, 64.69, 80, 110, 109.99, 254.99, 264, 838, 656, 435],
[51.41, 65.94, 79.99, 110, 110, 255, 263.99, 841, 657, 435],
[51.56, 64.83, 80, 110, 110, 254.99, 264, 843, 666, 436],
[50.7, 64.28, 79.99, 110, 110, 255, 264, 848, 664, 437],
[50.7, 63.73, 79.99, 109.99, 110, 254.99, 264, 845, 665, 436],
[50.3, 63.26, 80, 110, 109.99, 254.99, 263.99, 862, 676, 440],
[50.19, 64.56, 79.99, 110, 110, 254.99, 264, 856, 666, 438],
[50.26, 65.88, 79.99, 110, 109.99, 254.99, 263.99, 857, 671, 439],
[49.54, 65.45, 79.99, 110, 110, 254.99, 264, 864, 674, 439],
[49.54, 65.61, 80, 110, 109.99, 255, 263.99, 856, 671, 439],
[49.47, 66.54, 80, 109.99, 109.99, 254.99, 264, 842, 657, 436],
[49.11, 66.55, 79.99, 110, 110, 254.99, 263.99, 857, 670, 438],
[48.79, 64.99, 79.99, 110, 110, 255, 264, 854, 667, 436],
[47.63, 65.98, 79.99, 110, 110, 254.99, 275.02, 873, 683, 437],
[47.32, 64.49, 79.99, 110, 110, 254.99, 263.99, 870, 683, 437],
[47.73, 64.75, 79.99, 110, 110, 254.99, 263.99, 891, 699, 440],
[47.28, 65.27, 79.99, 110, 110, 255, 264, 875, 685, 438],
[47.35, 64.02, 80, 110, 110, 255, 264, 866, 681, 437],
[47.25, 64.77, 79.99, 110, 110, 254.99, 263.99, 853, 669, 435],
[47.52, 64.61, 80, 110, 109.99, 254.99, 263.99, 847, 664, 433],
[47.38, 63.32, 80, 110, 110, 255, 264, 865, 676, 435],
[47.38, 64.62, 80, 109.99, 110, 255, 263.99, 863, 676, 435],
[47.49, 65.56, 79.99, 110, 110, 254.99, 263.99, 846, 664, 432],
[47.49, 64.56, 80, 110, 110, 255, 263.99, 857, 670, 435],
[47.38, 64.87, 79.99, 110, 109.99, 255, 263.99, 854, 666, 434],
[47.07, 65.3, 79.99, 110, 110, 255, 264, 879, 691, 439],
[46.87, 63.94, 80, 109.99, 110, 255, 264, 875, 684, 438],
[47.87, 62.5, 79.99, 110, 109.99, 255, 264, 855, 668, 435],
[47.91, 63.83, 79.99, 110, 110, 254.99, 263.99, 859, 670, 435],
[48.01, 63.58, 79.99, 110, 110, 254.99, 264, 844, 661, 432],
[48.08, 64.35, 79.99, 110, 110, 255, 264, 857, 672, 435],
[47.94, 64.11, 79.99, 109.99, 110, 255, 263.99, 864, 678, 437],
[48.57, 62.62, 80, 110, 110, 254.99, 264, 873, 683, 438],
[48.64, 62, 79.99, 110, 110, 255, 264, 872, 684, 438],
[52.43, 63.92, 80, 110, 110, 254.99, 264, 873, 685, 440],
[52.77, 63.71, 79.99, 110, 110, 255, 264, 867, 678, 439],
[58.09, 65.81, 79.99, 110, 110, 255, 263.99, 849, 661, 434],
[58.14, 65.37, 80, 110, 110, 255, 264, 838, 655, 433],
[58.09, 64.79, 79.99, 110, 110, 255, 263.99, 842, 657, 433],
[57.5, 66.09, 80, 110, 110, 255, 263.99, 861, 669, 437],
[57.54, 65.1, 79.99, 109.99, 110, 255, 264, 858, 667, 436],
[57.28, 62.09, 80, 110, 110, 255, 263.17, 864, 670, 437],
[57.16, 61.34, 79.99, 110, 110, 255, 263.99, 849, 665, 436],
[57.54, 61.26, 80, 109.99, 110, 254.99, 263.99, 856, 669, 436],
[57.11, 61.24, 80, 110, 110, 255, 264.01, 840, 655, 432],
[57.24, 61.44, 80, 110, 110, 254.99, 264, 848, 660, 435],
[57.16, 61.83, 79.99, 110, 110, 255, 263.47, 875, 672, 437],
[57.2, 62.04, 80, 110, 110, 254.99, 264, 863, 672, 438],
[56.32, 65.45, 80, 110, 110, 254.99, 264, 860, 673, 437],
[56.28, 65.58, 80, 110, 110, 255, 263.99, 873, 682, 440],
[56.03, 64.87, 80, 110, 110, 255, 263.99, 862, 675, 438],
[55.99, 65.57, 79.99, 110, 110, 255, 263.99, 875, 681, 440],
[55.91, 65.32, 80, 110, 110, 254.99, 263.99, 869, 676, 438],
[55.99, 65.39, 79.99, 110, 110, 254.99, 263.99, 883, 691, 440],
[56.03, 64.72, 80, 110, 110, 255, 264, 880, 686, 441],
[56.03, 63.87, 80, 110, 110, 254.99, 264, 877, 683, 440],
[55.7, 66.09, 79.99, 109.99, 110, 254.99, 263.99, 864, 673, 438],
[55.7, 65.96, 80, 110, 110, 254.99, 263.99, 862, 670, 437],
[55.58, 64.8, 79.99, 110, 110, 254.99, 263.99, 906, 680, 438],
[55.58, 65.13, 80, 110, 109.99, 255, 263.99, 867, 679, 439],
[55.5, 60.74, 80, 110, 110, 254.99, 263.99, 872, 677, 439],
[54.98, 61, 80, 109.99, 110, 255, 264, 867, 677, 439],
[53.01, 66.46, 79.99, 110, 110, 254.99, 264, 838, 656, 433],
[52.85, 65.8, 80, 110, 110, 255, 264, 857, 673, 437],
[52.35, 63.57, 80, 110, 110, 255, 264, 872, 686, 441],
[52.35, 63.69, 80, 109.99, 109.99, 254.99, 264, 870, 684, 440],
[54.06, 64.1, 79.99, 110, 110, 255, 263.99, 871, 679, 439],
[54.18, 63.91, 79.99, 110, 109.99, 254.99, 264, 854, 669, 436],
[54.98, 63.24, 79.99, 110, 110, 255, 264, 845, 664, 434],
[57.45, 64.98, 80, 110, 110, 254.99, 263.99, 863, 670, 436],
[57.54, 64.59, 80, 110, 110, 255, 264, 859, 672, 437],
[59.01, 64.94, 79.99, 109.99, 109.99, 254.99, 264, 864, 671, 437],
[59.05, 64.36, 79.99, 110, 109.99, 255, 263.99, 861, 670, 436],
[59.27, 64.79, 79.99, 110, 110, 254.99, 263.99, 854, 667, 435],
[59.27, 64.79, 80, 109.99, 110, 255, 264, 843, 658, 432],
[59.23, 64.6, 79.99, 110, 110, 254.99, 264, 841, 654, 433],
[58.05, 63.73, 80, 109.99, 109.99, 254.99, 264, 850, 660, 434],
[57.88, 63.61, 79.99, 109.99, 110, 254.99, 264, 846, 662, 434],
[58.18, 63.34, 80, 110, 109.99, 255, 264, 845, 659, 433],
[57.71, 64.22, 79.99, 110, 110, 255, 263.99, 876, 683, 440],
[57.58, 63.88, 80, 110, 109.99, 255, 263.99, 877, 682, 439],
[57.37, 63.11, 80, 110, 110, 254.99, 263.99, 876, 684, 439],
[55.95, 63.62, 80, 110, 110, 255.01, 263.85, 898, 698, 443],
[55.7, 63.85, 80, 110, 110, 255, 264, 896, 698, 444],
[55.42, 65.06, 79.99, 110, 110, 255, 263.99, 895, 697, 444],
[55.26, 65.4, 80, 110, 110, 254.99, 263.98, 896, 698, 443],
[55.5, 66.06, 80, 110, 110, 255, 264, 895, 698, 444],
[54.5, 64.51, 80, 109.99, 110, 255, 264, 894, 697, 443],
[54.38, 65.4, 80, 109.99, 110, 255, 263.99, 891, 697, 443],
[53.9, 65.48, 80, 110, 110, 254.99, 264, 893, 701, 444],
[54.06, 63.62, 79.99, 109.99, 110, 255, 263.99, 876, 685, 440],
[54.02, 66.16, 79.99, 109.99, 110, 255, 264, 878, 683, 441],
[53.51, 64.99, 80, 110, 109.99, 255, 264, 882, 689, 441],
[53.08, 64.47, 79.99, 110, 110, 255, 264, 885, 689, 442],
[53.12, 65.52, 80, 110, 110, 255, 264, 886, 693, 442],
[53.08, 66.44, 80, 109.99, 110, 254.99, 264, 870, 684, 439],
[52.77, 63.27, 79.99, 110, 110, 254.98, 264, 882, 689, 440],
[53.04, 63.76, 79.99, 110, 109.99, 254.99, 263.99, 880, 686, 440],
[52.09, 65.7, 79.99, 110, 110, 255, 264, 885, 691, 440],
[52.01, 66.22, 80, 110, 110, 255, 264, 879, 688, 440],
[51.9, 64.83, 80, 110, 110, 255, 263.98, 883, 691, 441],
[51.9, 65.59, 80, 110, 110, 254.99, 263.98, 886, 691, 441],
[51.86, 64.56, 80, 110, 110, 254.99, 263.99, 887, 694, 442],
[52.2, 64.84, 80, 110, 110, 254.99, 264, 884, 692, 440],
[51.9, 64.96, 79.99, 110, 110, 254.99, 264, 890, 691, 442],
[51, 66.42, 80, 110, 110, 255, 263.99, 886, 696, 441],
[51.3, 66.63, 79.99, 110, 110, 255, 263.99, 883, 696, 443],
[51.19, 66.08, 80, 110, 110, 254.99, 263.99, 882, 691, 442],
[51.26, 64.53, 80, 110, 110, 254.99, 264, 879, 687, 440],
[51.37, 63.85, 80, 110, 110, 254.99, 263.98, 879, 688, 441],
[52.31, 62.3, 80, 109.99, 110, 254.99, 263.99, 886, 690, 442],
[52.39, 63.56, 80, 110, 110, 254.99, 263.99, 874, 685, 440],
[49.97, 63.29, 79.99, 109.99, 110, 254.99, 258.8, 891, 695, 440],
[49.83, 64.07, 79.99, 109.99, 110, 254.99, 258.84, 892, 697, 440],
[48.96, 64.03, 79.99, 110, 110, 255, 259.61, 876, 691, 437],
[48.96, 64.43, 80, 110, 110, 255, 259.36, 882, 693, 437],
[48.93, 63.4, 80, 110, 110, 254.99, 259.29, 869, 686, 436],
[49, 63.4, 79.99, 109.99, 110, 255, 259, 853, 672, 432],
[49.47, 64.54, 80, 110, 110, 255, 258.67, 875, 686, 436],
[49.75, 63.54, 80, 110, 110, 254.99, 258.87, 861, 677, 434],
[49.5, 62.77, 80, 110, 110, 255, 258.9, 862, 675, 434],
[48.72, 64.62, 80, 110, 110, 254.99, 260.68, 858, 673, 433],
[48.61, 64.05, 79.99, 110, 110, 254.99, 260.87, 854, 671, 433],
[48.4, 64.01, 79.99, 110, 110, 255, 261.26, 868, 682, 435],
[48.26, 64.77, 80, 110, 110, 255, 261.06, 867, 683, 435],
[49.07, 64.69, 79.99, 110, 110, 254.99, 259.35, 870, 685, 436],
[50.08, 63.18, 79.99, 110, 110, 255, 258.3, 882, 690, 438],
[50.59, 63.63, 79.99, 110, 110, 255, 258.07, 884, 690, 438],
[50.81, 64.79, 79.99, 110, 110, 254.99, 258.25, 889, 692, 438],
[50.7, 64.93, 80, 110, 109.99, 254.99, 258.42, 890, 690, 438],
[50.74, 64.76, 80, 110, 110, 254.99, 258.05, 885, 694, 438],
[50.89, 65.29, 79.99, 109.99, 109.99, 254.99, 258.13, 882, 689, 438],
[50.78, 65.51, 80, 110, 110, 254.99, 258.16, 902, 703, 441],
[50.85, 64.51, 79.99, 110, 110, 255, 258.41, 888, 693, 438],
[50.78, 65.22, 79.99, 110, 110, 254.99, 258.76, 898, 701, 440],
[49.79, 65.12, 80, 110, 110, 255, 260.49, 876, 689, 437],
[49.61, 64.47, 79.99, 110, 110, 255, 260.7, 884, 692, 439],
[49.5, 63.94, 80, 110, 110, 255, 260.94, 902, 694, 438],
[49.43, 63.55, 79.99, 109.99, 110, 254.99, 261.03, 884, 695, 438],
[49.39, 64.33, 79.99, 109.99, 110, 254.99, 261.19, 875, 688, 436],
[49, 63.96, 79.99, 110, 110, 255, 261.28, 868, 682, 435],
[49.21, 64.21, 80, 110, 110, 255, 261.45, 852, 672, 432],
[48.96, 64.51, 80, 109.99, 110, 254.99, 261.49, 851, 670, 431],
[48.75, 64.93, 80, 110, 110, 254.99, 261.76, 861, 679, 434],
[48.68, 65.82, 79.99, 110, 110, 254.99, 262.15, 866, 683, 434],
[48.36, 65.32, 80, 110, 110, 255, 262.31, 854, 670, 433],
[48.29, 63.96, 80, 110, 110, 255, 262.84, 866, 683, 435],
[48.4, 63.08, 80, 110, 109.99, 255, 262.95, 864, 683, 435],
[47.38, 65.94, 79.99, 110, 110, 255, 263.86, 882, 699, 438],
[47.28, 65.37, 80, 110, 109.99, 254.99, 263.69, 880, 693, 436],
[47.28, 64.43, 79.99, 110, 110, 254.99, 263.6, 876, 689, 437],
[47.21, 64.2, 80, 110, 110, 254.99, 263.4, 876, 689, 435],
[47.59, 63.95, 80, 110, 109.99, 254.99, 263.2, 880, 695, 437],
[47.38, 64.13, 79.99, 109.99, 110, 254.99, 263.09, 875, 692, 436],
[47.18, 63.76, 80, 110, 110, 255.01, 264.01, 916, 698, 438],
[47.18, 63.93, 80, 110, 110, 254.99, 263.94, 886, 700, 438],
[46.6, 64.36, 80, 109.99, 110, 255, 263.98, 883, 696, 437],
[46.63, 63.86, 80, 110, 110, 255, 263.99, 876, 692, 436],
[46.73, 65.48, 80, 109.99, 110, 254.99, 263.99, 879, 690, 436],
[46.94, 65.19, 80, 109.99, 110, 255, 264.02, 877, 694, 436],
[46.73, 66.46, 79.99, 110, 110, 255, 263.99, 877, 691, 436],
[46.7, 65.16, 79.99, 110, 110, 255, 264, 871, 691, 435],
[46.56, 62.33, 80, 110, 109.99, 255, 264.01, 879, 697, 437],
[46.83, 62.29, 80, 110, 110, 255, 264, 888, 698, 438],
[46.73, 63.97, 79.99, 109.99, 110, 255, 264.01, 874, 690, 436],
[46.77, 63.95, 80, 110, 110, 254.99, 263.99, 884, 692, 437],
[46.8, 63.5, 80, 110, 110, 255, 263.99, 880, 691, 437],
[46.7, 64.22, 79.99, 110, 110, 254.99, 264.01, 864, 686, 434],
[46.8, 63.98, 79.99, 109.99, 109.99, 255, 264.04, 855, 675, 432],
[46.66, 63.05, 79.99, 110, 109.99, 254.99, 263.99, 872, 687, 435],
[47.25, 64.99, 80, 110, 110, 254.98, 264.35, 897, 707, 439],
[47.21, 65.35, 79.99, 110, 110, 254.99, 263.98, 903, 710, 440],
[48.33, 62.12, 80, 110, 109.99, 254.99, 263.59, 846, 666, 431],
[48.33, 62.42, 80, 109.99, 110, 254.99, 263.39, 852, 669, 432],
[48.4, 63.79, 79.99, 110, 109.99, 254.99, 263.56, 845, 664, 431],
[48.29, 65.22, 79.99, 110, 110, 254.92, 264.02, 871, 683, 435],
[48.4, 63.99, 79.99, 109.99, 110, 255, 263.99, 869, 681, 435],
[48.43, 63.7, 80, 110, 110, 255, 263.99, 853, 668, 431],
[48.4, 63.4, 80, 109.99, 110, 255, 263.99, 842, 660, 430],
[48.54, 62.93, 79.99, 110, 110, 254.69, 263.3, 856, 669, 431],
[48.54, 62.67, 79.99, 110, 110, 254.99, 263.99, 844, 666, 430],
[48.4, 63.8, 79.99, 110, 110, 255, 263.99, 859, 677, 433],
[48.47, 63.95, 80, 109.99, 110, 255, 264.05, 874, 686, 436],
[48.64, 64.79, 79.99, 109.99, 110, 254.99, 263.98, 868, 684, 434],
[48.64, 64.6, 79.99, 109.99, 109.99, 254.99, 264.14, 861, 677, 433],
[48.68, 62.93, 79.99, 109.99, 110, 254.99, 263.99, 858, 676, 432],
[48.68, 62.2, 79.99, 110, 110, 254.99, 264.01, 855, 676, 433],
[48.75, 63.85, 80, 110, 110, 255, 263.97, 869, 678, 434],
[48.86, 65.29, 80, 109.99, 110, 254.99, 264.02, 859, 674, 433],
[48.72, 65.44, 80, 110, 110, 254.99, 264, 862, 676, 433],
[48.75, 66.31, 79.99, 110, 110, 254.99, 263.88, 871, 683, 435],
[49.04, 65.21, 80, 109.99, 110, 255, 263.89, 858, 673, 433],
[49, 64.69, 80, 109.99, 110, 254.99, 263.93, 869, 685, 435],
[49.07, 63.95, 79.99, 109.99, 110, 255, 264.01, 863, 674, 434],
[48.89, 63.32, 80, 109.99, 110, 255, 263.98, 874, 684, 435],
[48.75, 63.17, 80, 109.99, 110, 254.98, 264, 871, 687, 435],
[48.79, 63.68, 79.99, 110, 110, 254.99, 264.01, 862, 673, 432],
[48.86, 63.76, 80, 109.99, 110, 254.99, 263.97, 882, 691, 437],
[48.64, 65.32, 79.99, 109.99, 110, 255, 264, 876, 685, 436],
[48.68, 65.13, 80, 110, 109.99, 254.99, 263.99, 873, 683, 435],
[48.57, 66.82, 79.99, 110, 110, 254.99, 263.99, 877, 688, 436],
[48.43, 64.17, 79.99, 109.99, 109.99, 255, 263.99, 869, 685, 435],
[48.54, 64.1, 80, 110, 110, 255, 264, 892, 701, 438],
[48.29, 65.01, 80, 110, 110, 254.99, 263.99, 883, 691, 436],
[48.43, 64.91, 80, 110, 110, 254.99, 263.99, 883, 691, 437],
[48.57, 65.44, 79.99, 109.99, 110, 255, 264, 875, 683, 435],
[48.47, 65.56, 79.99, 109.99, 110, 254.99, 264, 870, 682, 435],
[48.4, 66.01, 79.99, 110, 109.99, 254.99, 264, 870, 683, 435],
[47.98, 65.84, 80, 110, 110, 255, 263.99, 876, 690, 436],
[47.91, 65.87, 80, 110, 110, 255, 264.01, 877, 690, 436],
[47.56, 64.63, 80, 110, 110, 254.99, 264, 885, 699, 437],
[47.38, 64.05, 80, 110, 110, 255, 264, 878, 692, 438],
[47.42, 64.15, 79.99, 110, 109.99, 254.99, 264.01, 876, 690, 436],
[47.25, 63.91, 79.99, 110, 110, 254.99, 263.99, 878, 691, 436],
[47.11, 65.92, 79.99, 110, 110, 254.83, 264.44, 879, 691, 436],
[47.25, 65.52, 80, 109.99, 109.99, 254.99, 263.99, 875, 691, 436],
[47.14, 66.37, 79.99, 110, 110, 254.99, 263.97, 888, 691, 437],
[47.28, 65.8, 79.99, 110, 109.99, 255, 264, 876, 690, 436],
[47.01, 63.18, 79.99, 110, 109.99, 254.99, 263.97, 861, 677, 433],
[47.18, 63.46, 79.99, 110, 110, 255, 264, 857, 676, 432],
[47.35, 63.23, 79.99, 109.99, 109.99, 255, 263.99, 853, 670, 432],
[47.25, 62.99, 80, 110, 110, 255, 264, 846, 666, 430],
[47.25, 64.09, 80, 110, 110, 254.99, 263.99, 861, 673, 433],
[47.25, 63.3, 79.99, 110, 110, 255, 263.99, 862, 674, 433],
[47.66, 65.26, 79.99, 109.99, 110, 255, 263.91, 867, 674, 432],
[47.59, 64.67, 79.99, 110, 110, 255, 264, 863, 680, 433],
[47.63, 65.59, 79.99, 109.99, 109.99, 254.99, 264, 857, 671, 432],
[47.8, 65, 79.99, 110, 110, 254.99, 264.01, 844, 663, 430],
[47.8, 64.4, 80, 110, 110, 255, 263.99, 847, 664, 431],
[47.87, 64.59, 79.99, 110, 110, 255, 264, 861, 678, 433],
[47.87, 64.1, 80, 110, 109.99, 255, 263.99, 855, 671, 432],
[47.87, 63.9, 79.99, 110, 110, 255, 263.99, 843, 662, 430],
[48.26, 64.08, 79.99, 109.99, 110, 254.99, 264.01, 859, 673, 432],
[48.36, 64.29, 79.99, 109.99, 110, 254.99, 263.99, 858, 670, 432],
[48.22, 65.92, 80, 110, 110, 255, 263.99, 855, 667, 432],
[48.72, 63.84, 80, 109.99, 110, 255, 263.99, 852, 668, 432],
[48.86, 63.78, 79.99, 109.99, 110, 254.99, 263.99, 856, 673, 432],
[48.93, 63.04, 79.99, 110, 110, 255, 263.98, 868, 681, 435],
[48.89, 63.91, 79.99, 110, 110, 254.99, 263.99, 875, 691, 436],
[49.18, 64.42, 79.99, 110, 109.99, 255, 263.99, 866, 683, 434],
[49.9, 64.42, 79.99, 109.99, 110, 255, 264.05, 870, 684, 436],
[50.04, 65.48, 80, 109.99, 109.99, 255, 264, 875, 683, 436],
[50.08, 64.29, 80, 110, 110, 254.99, 264.02, 882, 692, 437],
[50.08, 63.72, 79.99, 110, 109.99, 255, 264, 862, 673, 434],
[49.94, 67.09, 79.99, 109.99, 110, 254.98, 264, 875, 681, 436],
[49.83, 65.34, 80, 110, 110, 254.99, 264, 877, 690, 437],
[49.97, 63.13, 79.99, 110, 110, 255, 264.03, 915, 679, 435],
[49.65, 64, 79.99, 110, 110, 255, 263.99, 851, 668, 432],
[49.75, 64, 80, 110, 110, 254.99, 263.99, 856, 672, 433],
[49.9, 64.08, 80, 109.99, 110, 254.99, 263.99, 848, 664, 431],
[49.57, 64.42, 79.99, 110, 110, 255, 263.97, 857, 669, 433],
[49.68, 63.29, 79.99, 110, 110, 254.99, 264, 863, 678, 433],
[49.57, 64.47, 80, 110, 110, 254.99, 263.99, 874, 684, 435],
[49.75, 63.62, 80, 110, 110, 255, 263.99, 858, 672, 433],
[49.25, 65.7, 80, 110, 109.99, 254.99, 263.99, 878, 690, 436],
[49.25, 66.81, 80, 109.99, 110, 255, 264, 877, 685, 436],
[49.21, 66.3, 79.99, 110, 110, 254.99, 263.98, 877, 691, 436],
[49.04, 65.71, 79.99, 110, 110, 254.99, 264.02, 854, 672, 432],
[48.82, 64.42, 80, 109.99, 109.99, 255, 264, 881, 690, 437],
[48.93, 64.98, 80, 109.99, 110, 255, 263.99, 875, 690, 436],
[48.75, 66, 79.99, 109.99, 109.99, 255, 263.99, 873, 683, 435],
[48.72, 66.14, 79.99, 109.99, 110, 255, 264.01, 864, 679, 434],
[49, 66.67, 80, 109.99, 110, 254.99, 264, 867, 684, 434],
[48.75, 65.56, 80, 110, 110, 254.99, 264, 870, 683, 435],
[48.68, 62.5, 80, 110, 109.99, 254.99, 264.01, 865, 682, 434],
[48.43, 63.7, 79.99, 109.99, 110, 254.99, 263.99, 863, 679, 433],
[48.54, 63.31, 80, 110, 109.99, 255, 263.99, 859, 674, 433],
[48.29, 62.47, 80, 110, 110, 254.99, 263.99, 882, 690, 436],
[48.26, 65.35, 80, 110, 110, 255, 264, 861, 682, 434],
[48.15, 64.7, 80, 110, 110, 254.99, 264, 868, 682, 435],
[48.15, 64.93, 79.99, 109.99, 110, 255, 264, 870, 684, 435],
[48.4, 63.38, 79.99, 110, 110, 254.97, 260.36, 863, 673, 433],
[48.36, 63.28, 80, 110, 109.99, 254.99, 264, 857, 672, 433],
[48.4, 63.91, 80, 110, 110, 254.99, 263.99, 855, 672, 433],
[48.4, 63.77, 79.99, 110, 109.99, 255, 263.99, 863, 680, 433],
[48.57, 64.52, 80, 109.99, 109.99, 254.99, 264.01, 874, 690, 436],
[48.61, 65.08, 80, 110, 110, 255, 263.99, 852, 672, 432],
[49.43, 65.45, 80, 109.99, 110, 254.99, 264.02, 877, 688, 436],
[49.43, 64.29, 79.99, 110, 110, 255, 263.99, 877, 688, 436],
[49.5, 65.03, 79.99, 109.99, 110, 255, 264, 861, 681, 433],
[49.57, 64.37, 79.99, 110, 110, 255, 263.99, 860, 676, 433],
[49.68, 61.56, 79.99, 110, 110, 255, 264, 880, 688, 437],
[49.65, 61.31, 79.99, 109.99, 110, 255, 264, 863, 678, 434],
[49.75, 63.61, 79.99, 110, 110, 255, 264, 852, 670, 432],
[49.86, 63.71, 80, 110, 109.99, 255, 263.98, 855, 672, 432],
[50.01, 62.72, 79.99, 110, 110, 255, 263.98, 864, 679, 434],
[51.63, 64.49, 79.99, 109.99, 110, 254.99, 263.62, 872, 688, 437],
[51.9, 64.89, 79.99, 109.99, 109.99, 255, 263.06, 868, 682, 436],
[53.55, 65.01, 79.99, 110, 110, 254.62, 260.9, 871, 676, 436],
[53.78, 63.68, 79.99, 109.99, 109.99, 254.99, 260.57, 859, 677, 437],
[54.65, 62.84, 80, 110, 110, 255, 259.99, 863, 677, 436],
[54.38, 63.06, 80, 110, 110, 254.99, 259.72, 862, 677, 437],
[54.81, 63.1, 79.99, 110, 110, 254.99, 259.61, 849, 666, 433],
[54.73, 63.17, 79.99, 110, 110, 254.99, 259.6, 849, 668, 434],
[54.81, 64.98, 80, 110, 110, 254.99, 261.01, 865, 676, 436],
[54.69, 66.42, 79.99, 109.99, 110, 255, 260.85, 882, 691, 440],
[54.98, 65.37, 80, 110, 110, 254.99, 261.16, 878, 686, 439],
[54.9, 65.76, 80, 110, 109.99, 255, 261.25, 884, 691, 439],
[54.61, 64.86, 80, 110, 110, 254.99, 262.45, 867, 674, 436],
[54.26, 65.07, 80, 110, 110, 255, 262.46, 859, 673, 435],
[54.26, 64.74, 79.99, 110, 110, 254.99, 262.68, 867, 675, 437],
[54.34, 65.35, 80, 110, 110, 254.99, 262.72, 869, 683, 438],
[50.81, 61.5, 80, 109.99, 109.99, 255, 263.93, 892, 687, 439],
[50.74, 62.63, 79.99, 109.99, 109.99, 255, 263.99, 880, 686, 437],
[51.15, 63.27, 79.99, 110, 109.99, 255, 264.05, 855, 669, 433],
[51.37, 62.58, 80, 110, 109.99, 255, 263.99, 852, 669, 432],
[51.26, 62.44, 79.99, 110, 110, 255, 264, 864, 679, 435],
[51.41, 62.45, 80, 110, 110, 254.99, 263.99, 859, 673, 434],
[51.33, 62.79, 79.99, 109.99, 110, 255, 264, 853, 668, 432],
[51.41, 63.03, 79.99, 110, 109.99, 255, 264.02, 850, 667, 432],
[51.48, 62.69, 80, 110, 109.99, 255, 264, 863, 674, 434],
[51.52, 66.1, 80, 110, 110, 254.96, 264, 867, 679, 435],
[51.56, 65.4, 80, 110, 110, 255, 264, 866, 680, 436],
[51.67, 65.25, 79.99, 110, 110, 254.99, 264, 862, 681, 435],
[51.63, 65.01, 80, 110, 110, 255, 264, 877, 688, 438],
[51.9, 64.59, 79.99, 110, 110, 254.99, 263.99, 870, 683, 437],
[51.19, 65.37, 80, 110, 109.99, 254.99, 264, 871, 686, 436],
[51.22, 64.27, 79.99, 110, 109.99, 254.99, 264, 871, 683, 437],
[50.93, 63.71, 80, 110, 110, 255, 263.99, 879, 687, 438],
[50.89, 65.16, 80, 109.99, 109.99, 254.99, 263.99, 882, 694, 439],
[51.04, 63.69, 80, 109.99, 110, 254.99, 264.02, 866, 682, 435],
[50.93, 63.69, 80, 110, 110, 255, 264, 857, 673, 433],
[51.33, 64.01, 79.99, 110, 110, 254.99, 263.99, 873, 688, 437],
[51.19, 64.46, 79.99, 109.99, 110, 254.99, 263.99, 862, 674, 435],
[51.11, 65.99, 79.99, 109.99, 110, 255, 263.99, 875, 689, 437],
[51.19, 65.51, 79.99, 110, 109.99, 254.99, 263.99, 872, 686, 437],
[51.3, 65.76, 80, 110, 110, 255, 264, 856, 672, 433],
[51.48, 64.82, 79.99, 110, 110, 255, 264, 852, 668, 433],
[51.52, 64.24, 79.99, 110, 110, 254.99, 263.99, 835, 656, 429],
[52.31, 64.47, 79.99, 109.99, 110, 254.99, 264.33, 863, 676, 435],
[52.43, 64.44, 80, 110, 110, 254.99, 263.99, 865, 678, 436],
[52.74, 63.5, 80, 109.99, 110, 254.99, 263.97, 847, 662, 432],
[52.89, 63.23, 80, 109.99, 110, 255, 263.96, 860, 673, 435],
[53.82, 64.16, 80, 110, 109.99, 254.99, 263.72, 854, 668, 434],
[53.82, 63.34, 79.99, 110, 109.99, 255, 263.23, 838, 657, 433],
[58.7, 60.72, 80, 110, 110, 255, 256.49, 865, 673, 433],
[58.35, 60.93, 80, 110, 110, 255, 256.99, 864, 674, 434],
[56.4, 63.94, 80, 109.99, 110, 255, 257.92, 875, 685, 435],
[56.45, 63.76, 79.99, 109.99, 110, 254.99, 257.87, 888, 694, 438],
[56.28, 62.8, 79.99, 110, 110, 255, 258.15, 877, 688, 436],
[56.32, 62.96, 80, 110, 110, 255, 258.14, 878, 689, 437],
[54.69, 62.79, 80, 109.99, 109.99, 255, 260.59, 878, 686, 437],
[54.46, 62.47, 80, 110, 110, 254.99, 260.73, 876, 686, 437],
[53.67, 64.53, 80, 110, 110, 254.98, 261.23, 875, 686, 436],
[53.74, 63.55, 79.99, 110, 110, 254.99, 261.27, 868, 681, 436],
[53.9, 63.22, 79.99, 110, 109.99, 255, 261.15, 870, 680, 436],
[54.34, 66.57, 79.99, 110, 109.99, 255.02, 262.43, 875, 690, 437],
[54.18, 66.91, 80, 109.99, 110, 255, 262.23, 883, 695, 438],
[53.51, 60.57, 80, 110, 110, 255, 264, 863, 678, 434],
[53.39, 60.8, 80, 110, 110, 254.99, 264.02, 855, 673, 434],
[53.31, 61.53, 79.99, 110, 109.99, 255, 263.98, 859, 674, 434],
[53.43, 61.64, 80, 109.99, 110, 255, 264, 869, 682, 436],
[53.12, 64.2, 79.99, 109.99, 110, 255, 263.93, 845, 662, 431],
[53.12, 67.34, 79.99, 110, 110, 255, 263.63, 872, 686, 437],
[53.71, 65.46, 80, 110, 109.99, 254.99, 262.92, 870, 689, 436],
[53.63, 66.83, 80, 110, 110, 254.99, 262.63, 873, 686, 438],
[53.9, 65.42, 80, 109.99, 110, 255, 262.73, 878, 690, 437],
[53.71, 65.41, 80, 110, 110, 254.99, 262.53, 871, 687, 437],
[54.1, 65.76, 80, 109.99, 110, 255, 262.27, 871, 687, 436],
[54.06, 65.44, 80, 109.99, 109.99, 255, 261.83, 867, 681, 436],
[54.53, 63.34, 79.99, 110, 110, 255, 262.09, 858, 675, 434],
[54.65, 62.89, 79.99, 110, 110, 254.99, 261.73, 871, 682, 436],
[54.5, 63.47, 79.99, 110, 110, 254.99, 261.91, 866, 682, 436],
[54.57, 64.68, 80, 109.99, 110, 255, 262.23, 871, 684, 436],
[54.53, 65.54, 80, 110, 110, 255, 262.2, 880, 690, 438],
[54.53, 65.04, 80, 109.99, 110, 255, 262.47, 879, 690, 439],
[51.97, 66.48, 79.99, 110, 110, 254.99, 263.98, 887, 699, 441],
[49.04, 65.32, 80, 110, 110, 254.99, 263.99, 870, 686, 435],
[48.89, 65.86, 80, 109.99, 109.99, 254.99, 264, 876, 695, 437],
[49.18, 66.27, 80, 109.99, 110, 255, 264, 882, 693, 437],
[48.29, 64.72, 80, 109.99, 110, 254.99, 264, 854, 674, 432],
[48.22, 64.64, 79.99, 110, 110, 255, 264, 848, 669, 431],
[47.32, 64.01, 79.99, 109.99, 109.99, 254.99, 264.04, 884, 697, 436],
[47.45, 63.07, 80, 109.99, 110, 254.99, 263.99, 874, 690, 434],
[47.77, 63.43, 80, 110, 110, 254.99, 263.99, 879, 694, 435],
[47.52, 64.74, 80, 110, 110, 255, 263.99, 877, 690, 435],
[47.45, 67.41, 79.99, 110, 109.99, 254.99, 263.99, 876, 690, 435],
[47.7, 63.84, 79.99, 110, 110, 254.99, 264, 876, 687, 434],
[47.7, 65.15, 79.99, 110, 110, 254.99, 264, 878, 690, 434],
[47.8, 64.87, 79.99, 109.99, 110, 255, 264, 885, 693, 435],
[47.7, 64.49, 80, 110, 110, 254.99, 264, 874, 684, 434],
[47.66, 64.85, 79.99, 110, 110, 255, 263.99, 874, 687, 434],
[47.66, 64.21, 79.99, 110, 110, 254.99, 263.99, 873, 690, 434],
[47.59, 64.35, 80, 110, 109.99, 254.99, 263.99, 893, 700, 437],
[47.84, 63.7, 79.99, 110, 110, 254.99, 263.99, 887, 695, 436],
[47.63, 67.28, 79.99, 109.99, 109.99, 255, 264, 880, 692, 435],
[47.63, 66.47, 79.99, 110, 110, 255, 264, 876, 689, 433],
[47.63, 63.54, 80, 110, 110, 255, 263.99, 874, 685, 434],
[47.66, 65.69, 79.99, 109.99, 110, 255, 264, 879, 692, 435],
[46.6, 67.19, 79.99, 110, 110, 255, 263.99, 871, 685, 433],
[46.53, 68.55, 79.99, 110, 110, 254.99, 264, 872, 688, 433],
[46.49, 69.24, 80, 110, 110, 254.99, 264, 850, 674, 430],
[46.46, 68.61, 80, 110, 110, 254.99, 263.99, 859, 677, 431],
[46.6, 69.42, 79.99, 110, 110, 255, 264, 873, 687, 434],
[46.73, 68.34, 79.99, 110, 110, 255, 263.99, 843, 670, 429],
[46.73, 66.69, 79.99, 109.99, 109.99, 254.99, 264, 849, 673, 430],
[46.8, 66.33, 80, 109.99, 110, 255, 263.99, 859, 683, 431],
[47.59, 66.59, 79.99, 109.99, 110, 255, 264, 848, 670, 429],
[47.84, 65.77, 80, 110, 110, 254.97, 263.99, 869, 688, 433],
[48.05, 65.12, 80, 110, 110, 254.99, 264, 865, 687, 433],
[47.87, 65.25, 80, 110, 110, 255, 264, 873, 687, 433],
[47.94, 66.71, 79.99, 110, 109.99, 255.01, 264, 853, 675, 430],
[48.68, 64.46, 80, 109.99, 110, 254.99, 264, 862, 679, 432],
[48.54, 65.26, 79.99, 110, 109.99, 254.99, 264, 860, 679, 432],
[48.82, 67.09, 80, 109.99, 110, 255, 264, 844, 663, 429],
[49.57, 64.72, 79.99, 110, 110, 254.99, 263.99, 870, 686, 435],
[49.43, 64.36, 79.99, 110, 110, 254.99, 263.99, 869, 687, 435],
[48.54, 64.35, 80, 109.99, 110, 254.99, 264, 869, 691, 435],
[48.68, 65.32, 79.99, 109.99, 110, 255, 264, 874, 692, 435],
[48.64, 64.26, 79.99, 109.99, 110, 254.99, 263.99, 870, 689, 436],
[49.36, 65.6, 79.99, 110, 110, 255, 263.99, 864, 681, 433],
[49.21, 65.85, 79.99, 110, 110, 255, 263.99, 872, 686, 435],
[49.21, 64.91, 79.99, 110, 110, 255, 263.99, 869, 687, 435],
[49.04, 65.95, 79.99, 110, 110, 255, 263.99, 870, 684, 434],
[48.89, 65.51, 80, 110, 110, 255, 264, 866, 685, 433],
[49.04, 64.13, 79.99, 110, 109.99, 255, 264, 877, 690, 435],
[49.18, 64.33, 79.99, 110, 110, 255, 264, 880, 691, 436],
[49.18, 64.41, 80, 110, 110, 254.99, 264, 882, 693, 436],
[48.93, 64.12, 80, 110, 110, 254.99, 264, 865, 685, 434],
[48.93, 66.38, 79.99, 110, 110, 255, 264.01, 881, 691, 435],
[48.93, 67.8, 79.99, 110, 110, 254.99, 264, 876, 690, 435],
[49.18, 65.47, 79.99, 109.99, 110, 254.99, 263.99, 879, 688, 435],
[48.96, 63.86, 80, 110, 110, 254.99, 264, 881, 696, 436],
[49.54, 68.48, 80, 109.99, 110, 255, 263.99, 877, 693, 436],
[49.72, 67.41, 80, 110, 110, 255, 263.99, 878, 693, 436],
[49.94, 63.9, 79.99, 109.99, 110, 254.99, 264, 852, 671, 430],
[49.83, 64.56, 80, 110, 109.99, 255, 263.99, 861, 678, 433],
[50.85, 62.73, 79.99, 110, 109.99, 254.99, 263.99, 877, 690, 437],
[50.96, 63.17, 79.99, 110, 110, 255, 264, 873, 689, 435],
[52.74, 63.46, 79.99, 110, 109.99, 254.99, 264, 874, 684, 437],
[52.74, 63.88, 79.99, 110, 110, 255, 264, 879, 686, 437],
[50.81, 61.89, 79.99, 109.99, 110, 255, 264.02, 854, 668, 432],
[50.96, 62.19, 80, 109.99, 110, 255, 263.99, 846, 663, 431],
[51.11, 63.63, 80, 109.99, 110, 255, 264, 883, 690, 437],
[51.07, 63.26, 79.99, 110, 109.99, 254.99, 263.99, 874, 683, 436],
[51, 63.79, 79.99, 109.99, 109.99, 254.99, 263.99, 875, 687, 435],
[51.04, 63.49, 79.99, 110, 110, 254.83, 264, 883, 685, 436],
[51.04, 63.13, 80, 110, 110, 254.99, 264, 872, 683, 435],
[51.11, 64.6, 79.99, 110, 110, 254.99, 263.99, 876, 684, 436],
[50.93, 64.34, 79.99, 110, 110, 255, 264, 870, 682, 435],
[49.54, 65.61, 79.99, 110, 109.99, 254.99, 264.02, 876, 685, 435],
[48.43, 65.09, 79.99, 110, 110, 255, 264, 881, 688, 435],
[48.47, 64.77, 79.99, 110, 110, 255, 263.99, 876, 687, 434],
[48.64, 65.95, 80, 110, 110, 254.91, 264.07, 876, 686, 435],
[48.72, 66.7, 79.99, 109.99, 110, 255, 264, 876, 689, 434],
[48.57, 65.63, 80, 110, 110, 255, 264, 874, 689, 435],
[48.61, 64.2, 80, 110, 110, 254.99, 264, 871, 684, 433],
[48.47, 64.92, 79.99, 110, 110, 255, 264, 861, 681, 432],
[47.87, 66.14, 80, 110, 110, 254.99, 263.99, 880, 691, 436],
[47.77, 66.24, 80, 110, 110, 255, 264, 881, 691, 436],
[47.21, 66.01, 79.99, 110, 110, 254.99, 264, 872, 685, 434],
[47.32, 63.98, 79.99, 110, 110, 255, 264, 874, 684, 434],
[47.21, 64.35, 80, 110, 110, 254.99, 264, 875, 693, 434],
[48.36, 63.15, 79.99, 110, 110, 254.99, 264, 876, 687, 435],
[48.93, 64.18, 80, 110, 110, 255, 263.99, 868, 687, 434],
[49.04, 64.21, 79.99, 110, 109.99, 255, 264, 861, 677, 432],
[49.21, 63.04, 80, 110, 109.99, 255, 264, 859, 676, 432],
[49.68, 64.38, 79.99, 110, 109.99, 255, 264, 860, 678, 433],
[49.86, 64.09, 79.99, 109.99, 109.99, 255, 264.34, 854, 669, 431],
[49.72, 63.78, 79.99, 110, 110, 255, 263.99, 843, 662, 429],
[44.95, 66.73, 80, 79.43, 79.38, 15.23, 264, 885, 699, 436],
[44.85, 66.27, 79.99, 79.43, 79.38, 15.45, 263.99, 882, 694, 436],
[44.75, 63.92, 80, 79.43, 79.39, 15.62, 264, 884, 701, 436],
[50.78, 64.95, 79.99, 79.52, 79.47, 6.84, 263.99, 860, 679, 435],
[50.41, 64.46, 80, 79.52, 79.48, 6.91, 263.99, 869, 685, 438],
[50.63, 64.53, 80, 79.52, 79.48, 6.9, 263.99, 875, 689, 438],
[50.74, 65.1, 79.99, 79.53, 79.48, 6.96, 263.99, 872, 690, 438],
[56.03, 64.37, 79.99, 110, 109.99, 254.99, 263.99, 857, 678, 432],
[56.2, 64.38, 80, 110, 110, 255, 263.99, 855, 673, 432],
[56.65, 63.74, 80, 110, 109.99, 255, 264, 869, 685, 435],
[56.7, 64.18, 80, 109.99, 109.99, 255, 264, 878, 692, 436],
[57.33, 64.01, 79.99, 110, 110, 255, 264, 881, 691, 436],
[57.33, 64.31, 80, 109.99, 110, 255, 264, 869, 688, 436],
[57.75, 64.03, 79.99, 110, 110, 254.99, 263.99, 861, 676, 433],
[57.67, 65.2, 80, 110, 110, 255, 264, 849, 668, 432],
[57.28, 63.62, 79.99, 110, 109.99, 255, 264, 879, 694, 436],
[57.45, 63.53, 80, 110, 110, 255, 263.99, 891, 700, 438],
[56.99, 63.68, 80, 110, 109.99, 254.99, 263.99, 878, 690, 436],
[55.02, 64.19, 79.99, 110, 110, 255, 264, 893, 702, 440],
[54.14, 63.36, 80, 110, 110, 254.99, 264, 867, 683, 434],
[54.34, 64.12, 80, 110, 110, 255, 264, 870, 683, 435],
[54.18, 65.8, 80, 110, 110, 254.99, 264, 883, 696, 439],
[58.61, 65.12, 80, 110, 110, 255, 264, 881, 691, 436],
[58.7, 64.21, 80, 109.99, 110, 255, 264, 860, 677, 434],
[57.67, 65.41, 79.99, 110, 110, 255, 263.99, 881, 690, 436],
[57.45, 66.08, 80, 109.99, 110, 255, 264, 890, 694, 438],
[57.37, 67.32, 80, 110, 110, 255, 264, 881, 693, 437],
[56.36, 64.62, 80, 110, 110, 254.99, 264, 873, 686, 435],
[56.49, 65.53, 80, 109.99, 110, 255, 264, 867, 682, 434],
[53.12, 63.14, 79.99, 110, 110, 254.99, 264, 897, 703, 440],
[54.42, 62.24, 79.99, 110, 110, 254.99, 263.99, 885, 696, 438],
[54.38, 62.61, 79.99, 110, 110, 255, 263.99, 888, 700, 439],
[55.54, 62.25, 80, 109.99, 110, 254.99, 263.99, 883, 693, 437],
[55.58, 61.82, 79.99, 109.99, 110, 254.99, 263.99, 891, 698, 439],
[57.03, 64.01, 80, 109.99, 110, 255.06, 264.01, 885, 696, 435],
[56.99, 64.68, 79.99, 110, 110, 254.99, 263.99, 884, 694, 437],
[56.99, 63.09, 79.99, 109.99, 110, 254.99, 264, 884, 696, 437],
[57.07, 62.63, 79.99, 110, 110, 254.99, 263.99, 880, 692, 436],
[55.18, 65.08, 79.99, 110, 110, 255, 264.01, 875, 690, 434],
[55.02, 65.45, 80, 110, 110, 254.99, 264, 877, 690, 436],
[50.7, 65.4, 80, 110, 110, 254.99, 264, 890, 706, 439],
[50.67, 65.09, 79.99, 110, 110, 255, 264, 886, 699, 439],
[50.45, 63.8, 80, 110, 110, 254.99, 264, 888, 705, 439],
[50.48, 63.76, 79.99, 110, 109.99, 255, 264, 881, 695, 437],
[50.41, 63.39, 79.99, 110, 109.99, 255, 263.99, 881, 692, 437],
[55.75, 63.96, 79.99, 110, 110, 254.99, 263.99, 882, 692, 437],
[56.07, 62.97, 80, 110, 110, 255, 264, 897, 702, 440],
[52.74, 63.32, 80, 110, 109.99, 254.99, 263.99, 891, 699, 439],
[52.54, 65.45, 80, 109.99, 110, 254.99, 263.99, 891, 699, 439],
[52.51, 65.97, 79.99, 110, 110, 255, 264, 891, 704, 440],
[52.51, 65.69, 79.99, 110, 110, 254.99, 263.99, 890, 702, 439],
[51.52, 64.9, 80, 109.99, 110, 254.99, 264, 888, 697, 439],
[51.19, 64.95, 80, 110, 110, 254.99, 263.99, 886, 701, 438],
[51.11, 64.81, 79.99, 110, 109.99, 255, 263.99, 888, 702, 439],
[51.45, 64.16, 79.99, 110, 109.99, 255, 264, 894, 705, 440],
[51.19, 63.13, 80, 109.99, 110, 254.99, 264, 891, 703, 439],
[51.37, 62.3, 80, 110, 109.99, 255, 264, 893, 703, 439],
[51.86, 64.39, 79.99, 109.99, 110, 255, 264, 890, 705, 439],
[51.9, 64.3, 79.99, 110, 109.99, 255, 263.99, 891, 701, 439],
[51.56, 63.93, 80, 110, 110, 254.97, 263.99, 889, 698, 438],
[51.67, 64.05, 79.99, 110, 110, 254.99, 263.99, 888, 699, 438],
[51.41, 63.44, 80, 109.99, 110, 254.99, 263.99, 890, 704, 438],
[51.41, 64.12, 80, 110, 110, 255, 264, 885, 702, 439],
[49.94, 62.25, 79.99, 109.99, 110, 254.99, 263.99, 851, 669, 429],
[49.86, 61.72, 79.99, 109.99, 110, 255, 264, 855, 677, 429],
[49.61, 62.4, 80, 110, 110, 255, 264, 843, 668, 427],
[50.04, 62.69, 79.99, 110, 110, 255, 263.99, 853, 669, 429],
[50.41, 63.72, 80, 110, 110, 255, 263.99, 861, 680, 432],
[50.34, 63.26, 80, 110, 110, 255, 263.99, 876, 695, 435],
[51.04, 63.23, 80, 110, 110, 254.99, 263.99, 891, 695, 438],
[50.67, 63.47, 79.99, 110, 110, 255, 264, 892, 695, 438],
[50.19, 63.02, 80, 109.99, 110, 254.99, 264, 884, 688, 435],
[50.26, 63.5, 79.99, 109.99, 110, 255, 263.99, 879, 685, 434],
[49.79, 64.62, 79.99, 110, 110, 255, 264, 884, 690, 435],
[49.79, 64.66, 79.99, 110, 110, 254.99, 263.99, 883, 689, 435],
[49.86, 63.59, 80, 110, 109.99, 254.99, 263.99, 881, 684, 435],
[50.15, 63.9, 79.99, 110, 109.99, 255, 264, 884, 693, 436],
[50.04, 63.1, 79.99, 110, 109.99, 254.99, 264, 882, 687, 435],
[49.94, 65.11, 80, 110, 110, 255, 264, 894, 699, 438],
[51.9, 64.41, 79.99, 110, 109.99, 254.99, 263.99, 897, 696, 438],
[51.86, 64.22, 79.99, 110, 110, 254.99, 264, 900, 700, 438],
[51.75, 63.59, 80, 110, 110, 255, 264, 886, 687, 436],
[51.86, 63.98, 79.99, 110, 110, 255, 264, 879, 684, 434],
[53.04, 63.98, 79.99, 110, 110, 255, 264, 900, 699, 439],
[53.04, 64.31, 79.99, 110, 109.99, 254.99, 263.99, 897, 696, 438],
[50.93, 62.57, 79.99, 110, 109.99, 254.99, 264, 882, 685, 435],
[50.93, 62.92, 80, 110, 110, 255, 263.99, 897, 699, 438],
[51.19, 63.43, 79.99, 109.99, 110, 255, 263.99, 904, 704, 440],
[51.04, 62.74, 80, 110, 110, 254.99, 264, 901, 705, 440],
[51.07, 64.5, 79.99, 110, 110, 254.99, 263.99, 884, 690, 436],
[50.78, 63.94, 80, 109.99, 110, 254.99, 264, 878, 681, 434],
[50.93, 63.24, 79.99, 110, 110, 254.97, 264, 881, 685, 435],
[50.89, 63.29, 80, 110, 110, 254.99, 263.99, 868, 681, 433],
[49.18, 64.3, 80, 109.99, 110, 255, 263.99, 868, 681, 431],
[49.21, 65.42, 79.99, 110, 110, 255, 264, 873, 682, 432],
[49.25, 65.88, 79.99, 109.99, 110, 255, 264, 863, 673, 430],
[48.93, 65.83, 80, 109.99, 110, 255, 264, 903, 701, 437],
[48.96, 65.56, 79.99, 110, 109.99, 255, 263.99, 902, 702, 436],
[48.19, 63.61, 79.99, 110, 110, 255, 264, 888, 689, 434],
[48.22, 64.04, 79.99, 110, 110, 255, 264, 883, 686, 432],
[48.22, 64.07, 80, 109.99, 110, 254.99, 263.99, 913, 706, 437],
[48.26, 64.16, 80, 109.99, 110, 254.99, 264, 905, 706, 437],
[48.33, 63.72, 79.99, 109.99, 110, 255, 264, 895, 700, 435],
[48.26, 63.51, 80, 110, 110, 255, 264, 900, 701, 436],
[48.4, 63.3, 80, 110, 109.99, 255, 264, 898, 699, 435],
[49.68, 65.09, 80.01, 110, 110, 254.99, 263.99, 890, 697, 435],
[49.86, 67.54, 79.99, 110, 110, 254.99, 264, 871, 679, 432],
[50.45, 64.57, 80, 110, 110, 255, 263.99, 877, 684, 433],
[50.34, 63.69, 79.99, 110, 110, 255, 264, 893, 693, 435],
[54.5, 64.33, 80, 110, 110, 255, 263.99, 892, 698, 440],
[53.2, 63.95, 79.99, 110, 110, 255, 263.99, 879, 683, 438],
[53.08, 63.59, 79.99, 110, 110, 254.99, 263.99, 906, 704, 441],
[53.08, 67.72, 79.99, 110, 109.99, 254.99, 264, 887, 686, 437],
[52.74, 66.19, 80, 110, 109.99, 254.99, 264, 886, 694, 438],
[52.7, 63.87, 79.99, 110, 110, 254.99, 264, 893, 699, 440],
[51.71, 65.71, 80, 110, 109.99, 254.97, 263.99, 894, 697, 437],
[51.82, 66.18, 80, 110, 110, 254.99, 263.99, 899, 703, 441],
[51.9, 65.24, 79.99, 110, 109.99, 255, 263.99, 898, 699, 441],
[51.45, 64.37, 80, 110, 109.99, 254.99, 264, 886, 691, 438],
[51.07, 64.66, 79.99, 110, 109.99, 255, 264, 875, 680, 435],
[50.96, 63.89, 80, 109.99, 110, 254.99, 263.99, 869, 681, 435],
[51.26, 63.97, 80, 110, 110, 255, 264, 901, 704, 441],
[51.07, 63.87, 79.99, 110, 110, 255, 264, 898, 703, 441],
[52.31, 63.36, 79.99, 110, 110, 254.99, 264, 892, 697, 440],
[52.24, 63.45, 80, 110, 110, 255, 264, 892, 698, 440],
[52.62, 63.4, 80, 110, 110, 255, 264, 882, 687, 437],
[52.54, 63.65, 80, 109.99, 109.99, 254.99, 264, 881, 687, 437],
[52.7, 64.35, 79.99, 109.99, 110, 255, 263.99, 872, 680, 435],
[52.89, 65.69, 79.99, 109.99, 110, 255, 264, 883, 692, 438],
[59.58, 64.5, 80, 110, 110, 255, 263.99, 863, 672, 432],
[59.76, 64, 80, 110, 109.99, 255, 263.99, 863, 673, 432],
[59.94, 63.34, 79.99, 110, 109.99, 255, 263.99, 862, 668, 431],
[59.89, 63.15, 79.99, 110, 110, 255, 264, 870, 676, 433],
[57.45, 63.41, 80, 110, 110, 254.99, 263.99, 909, 706, 441],
[57.45, 63.23, 80, 109.99, 109.99, 255, 264, 904, 699, 440],
[56.74, 64.47, 79.99, 110, 109.99, 255.1, 263.91, 890, 692, 436],
[56.65, 64.91, 79.99, 110, 109.99, 255, 263.99, 884, 684, 436],
[53.08, 64.3, 80, 110, 110, 255, 263.99, 872, 679, 434],
[53.08, 64.98, 79.99, 110, 110, 255, 264, 899, 700, 439],
[53.04, 65.73, 79.99, 110, 110, 255, 264, 919, 717, 443],
[52.89, 64.15, 79.99, 110, 110, 254.99, 264, 889, 693, 437],
[53.12, 63.85, 79.99, 110, 109.99, 254.99, 263.99, 885, 687, 438],
[60.25, 63.8, 80, 109.99, 110, 254.99, 263.99, 905, 703, 439],
[60.48, 63.85, 79.99, 109.99, 110, 254.99, 263.99, 892, 690, 437],
[61.86, 65.6, 80, 110, 110, 255, 263.99, 852, 659, 430],
[61.63, 64.61, 79.99, 110, 110, 254.99, 264, 886, 689, 436],
[60.61, 64.53, 79.99, 110, 109.99, 254.99, 264, 885, 683, 434],
[60.43, 64.49, 80, 110, 110, 254.99, 264, 895, 689, 435],
[60.52, 63.95, 80, 109.99, 109.99, 254.99, 263.99, 893, 691, 436],
[60.21, 64.98, 80, 110, 110, 255, 264, 882, 683, 434],
[60.34, 65.07, 80, 110, 109.99, 254.99, 263.99, 882, 683, 434],
[60.21, 64.76, 80, 110, 110, 254.99, 264, 907, 702, 440],
[59.98, 64.49, 79.99, 110, 110, 254.99, 263.99, 886, 689, 437],
[58.66, 63.08, 79.99, 110, 109.99, 255, 263.99, 895, 693, 438],
[56.16, 64.89, 80, 110, 110, 254.99, 263.99, 873, 680, 433],
[55.99, 65, 79.99, 110, 109.99, 255, 263.99, 893, 693, 437],
[53.47, 64, 79.99, 110, 109.99, 255, 264, 881, 688, 436],
[55.22, 62.34, 79.99, 110, 109.99, 254.99, 263.99, 866, 673, 431],
[55.3, 62.1, 79.99, 110, 109.99, 254.99, 263.99, 871, 682, 434],
[57.24, 62.42, 80, 110, 110, 254.99, 263.99, 887, 691, 438],
[55.02, 64.55, 79.99, 110, 110, 254.99, 263.98, 895, 696, 438],
[54.9, 65.82, 79.99, 109.99, 110, 255, 263.99, 889, 693, 437],
[51.19, 65.54, 80, 109.99, 109.99, 254.99, 264, 900, 703, 440],
[51, 64.9, 79.99, 110, 110, 254.99, 263.99, 893, 698, 439],
[51.07, 63.38, 79.99, 109.99, 110, 255, 264, 884, 690, 437],
[51.15, 63.03, 80, 110, 110, 254.99, 263.99, 897, 698, 438],
[51, 62.97, 80, 109.99, 110, 255, 263.99, 893, 697, 438],
[51.19, 62.75, 80, 110, 110, 254.99, 264, 895, 701, 438],
[50.93, 63.25, 79.99, 110, 109.99, 254.99, 264, 899, 702, 440],
[50.96, 64.24, 79.99, 110, 110, 254.98, 264, 892, 698, 438],
[51.07, 64.78, 80, 109.99, 110, 255, 264, 890, 696, 438],
[51.26, 63.24, 79.99, 110, 109.99, 255, 264, 890, 697, 437],
[51.3, 63.39, 80, 110, 110, 254.99, 264, 890, 697, 438],
[51.26, 62.47, 79.99, 109.99, 109.99, 254.99, 264, 877, 685, 435],
[51.56, 63.55, 80, 110, 110, 255, 264, 887, 691, 436],
[50.85, 63.42, 79.99, 110, 110, 255, 264, 877, 684, 434],
[51.37, 63.27, 80, 110, 110, 254.99, 264, 884, 692, 437],
[51.63, 63.4, 80, 110, 110, 254.99, 263.99, 878, 684, 436],
[51.71, 63.15, 79.99, 109.99, 110, 255, 263.99, 882, 689, 435],
[53.39, 62.99, 79.99, 110, 110, 255, 263.99, 884, 693, 437],
[53.39, 63.21, 79.99, 110, 110, 255, 264, 884, 691, 436],
[53.9, 64.24, 80, 110, 109.99, 254.99, 263.99, 876, 683, 434],
[54.06, 64.86, 79.99, 110, 110, 255, 263.99, 883, 688, 436],
[56.03, 64.43, 79.99, 110, 110, 255, 264, 891, 694, 437],
[54.85, 64.33, 80, 110, 110, 254.99, 264, 901, 699, 439],
[54.85, 64.73, 79.99, 110, 110, 255, 264, 904, 703, 440],
[54.81, 65.22, 80, 110, 110, 254.99, 264, 894, 696, 439],
[54.81, 65.35, 79.99, 109.99, 110, 255, 264, 897, 694, 438],
[54.85, 64.45, 80, 110, 110, 254.99, 264, 903, 701, 440],
[54.85, 63.82, 79.99, 110, 110, 255, 263.99, 898, 699, 438],
[54.69, 64.39, 80, 109.99, 109.99, 254.99, 263.99, 888, 690, 436],
[53.9, 64.33, 80, 110, 110, 254.99, 264, 874, 682, 433],
[53.94, 64.35, 80, 110, 109.99, 255, 264, 871, 678, 433],
[52.31, 64.65, 80, 110, 110, 254.99, 264, 915, 709, 441],
[52.28, 64.9, 80, 110, 110, 254.99, 263.99, 913, 710, 441],
[52.43, 66.83, 79.99, 110, 110, 254.99, 263.99, 911, 708, 441],
[52.47, 67.11, 80, 110, 110, 254.99, 263.99, 910, 708, 440],
[52.58, 64.75, 80, 110, 110, 254.99, 263.99, 893, 697, 437],
[52.62, 64.86, 80, 110, 110, 255, 264, 905, 703, 440],
[52.58, 64.64, 80, 110, 110, 255, 263.99, 906, 705, 440],
[52.51, 65.38, 80, 110, 110, 255, 264, 911, 708, 441],
[52.43, 64.34, 79.99, 110, 109.99, 255, 264, 907, 710, 441],
[52.47, 64.5, 80, 110, 109.99, 254.99, 264, 911, 710, 441],
[52.54, 64.38, 79.99, 110, 110, 254.99, 263.99, 905, 707, 441],
[52.54, 65.22, 80, 109.99, 110, 255.01, 264.13, 907, 708, 440],
[52.54, 65.03, 79.99, 110, 109.99, 254.99, 264, 913, 713, 441],
[53.12, 63.42, 79.99, 109.99, 110, 254.99, 263.99, 878, 687, 435],
[52.77, 63.81, 79.99, 110, 110, 254.99, 263.99, 871, 681, 435],
[52.97, 62.51, 79.99, 110, 110, 255.14, 264, 884, 688, 435],
[53.12, 63.96, 79.99, 110, 110, 254.99, 264, 877, 684, 435],
[53.16, 63.35, 80, 110, 110, 254.99, 263.99, 896, 699, 438],
[53.24, 62.87, 79.99, 110, 110, 254.99, 263.99, 897, 700, 439],
[53.86, 63.63, 79.99, 110, 110, 255, 263.99, 878, 687, 435],
[55.99, 62.72, 79.99, 109.99, 110, 254.99, 264, 891, 692, 436],
[57.37, 62.28, 80, 110, 110, 255, 263.99, 881, 684, 434],
[56.82, 65.26, 79.99, 110, 110, 254.98, 263.99, 875, 679, 432],
[56.82, 64.6, 80, 110, 110, 254.99, 263.99, 887, 692, 435],
[56.32, 63.57, 80, 109.99, 109.99, 255, 264, 882, 685, 434],
[56.16, 63.62, 79.99, 110, 110, 254.99, 264, 874, 682, 433],
[55.22, 63.95, 80, 110, 110, 255, 263.99, 888, 692, 437],
[54.81, 64.31, 80, 110, 109.99, 254.99, 263.99, 893, 697, 437],
[54.81, 64.59, 80, 110, 110, 254.99, 264, 899, 698, 438],
[54.22, 64.54, 80, 109.99, 110, 255, 264, 903, 703, 439],
[54.34, 64.34, 79.99, 110, 109.99, 254.99, 264, 907, 705, 440],
[54.34, 63.8, 79.99, 110, 110, 255, 263.99, 907, 711, 439],
[54.38, 64.94, 80, 109.99, 110, 254.99, 263.99, 904, 703, 439],
[54.3, 65.11, 80, 110, 109.99, 255, 264, 905, 705, 440],
[54.38, 65.72, 79.99, 109.99, 109.99, 254.99, 263.99, 902, 701, 439],
[54.18, 65.75, 79.99, 110, 109.99, 254.99, 263.99, 903, 704, 439],
[54.06, 63.7, 80, 110, 110, 255, 264, 884, 691, 435],
[54.02, 64, 80, 110, 109.99, 255, 264, 896, 704, 439],
[53.98, 64.26, 79.99, 109.99, 110, 255, 264, 902, 703, 439],
[54.06, 64.45, 79.99, 110, 110, 255, 263.99, 906, 708, 440],
[54.1, 63.43, 79.99, 109.99, 110, 254.99, 264, 901, 701, 439],
[54.06, 62.99, 80, 110, 110, 254.99, 263.99, 906, 704, 440],
[54.06, 63.61, 79.99, 110, 110, 254.99, 263.99, 876, 683, 433],
[53.9, 64.13, 80, 110, 110, 254.99, 263.99, 877, 685, 434],
[54.1, 63.82, 80, 109.99, 110, 255, 263.99, 890, 692, 436],
[54.1, 64.79, 79.99, 110, 109.99, 254.99, 264, 890, 695, 437],
[54.18, 65.89, 80, 110, 110, 255, 263.99, 911, 709, 440],
[54.14, 65.07, 80, 109.99, 109.99, 255, 263.99, 912, 710, 441],
[54.14, 65.87, 79.99, 110, 110, 255, 264, 906, 705, 440],
[54.3, 65.04, 80, 110, 109.99, 255, 263.99, 909, 708, 441],
[54.46, 64.68, 79.99, 109.99, 110, 255, 263.99, 892, 693, 437],
[54.57, 64.5, 79.99, 110, 110, 254.99, 264, 887, 690, 435],
[54.5, 64.65, 80, 110, 110, 254.99, 263.99, 885, 692, 435],
[54.73, 64.25, 79.99, 110, 110, 255, 264, 884, 689, 436],
[54.57, 63.84, 80, 109.99, 110, 255, 264, 882, 686, 436],
[54.9, 63.82, 80, 110, 109.99, 255, 263.99, 865, 672, 433],
[55.79, 64.33, 79.99, 110, 109.99, 255, 263.99, 893, 695, 438],
[57.2, 65.49, 80, 110, 109.99, 255, 264, 852, 658, 429],
[57.11, 64.96, 80, 110, 110, 255, 264, 847, 655, 429],
[57.37, 61.96, 79.99, 109.99, 109.99, 255.02, 263.99, 863, 662, 431],
[57.24, 62.86, 79.99, 110, 110, 255, 263.99, 852, 658, 432],
[53.67, 62.25, 80, 110, 110, 255, 264, 865, 672, 433],
[53.71, 62.95, 80, 110, 110, 255, 263.99, 873, 677, 437],
[54.06, 64.06, 80, 110, 110, 255, 264, 881, 683, 438],
[54.14, 65.32, 79.99, 109.99, 109.99, 255, 264, 893, 688, 441],
[54.3, 63.71, 79.99, 109.99, 110, 255, 263.99, 865, 668, 434],
[54.26, 63.81, 79.99, 109.99, 109.99, 254.99, 263.99, 868, 675, 433],
[54.34, 64.45, 80, 110, 110, 255, 264, 889, 688, 439],
[54.53, 63.09, 79.99, 110, 110, 254.99, 264, 886, 684, 438],
[54.53, 63.37, 80, 109.99, 110, 254.99, 263.99, 892, 691, 441],
[53.01, 63.17, 80, 110, 110, 254.99, 264, 885, 688, 438],
[53.12, 63.35, 79.99, 110, 110, 254.99, 263.99, 870, 682, 436],
[53.12, 63.3, 80, 110, 110, 255, 264, 884, 688, 440],
[53.39, 64.54, 79.99, 109.99, 109.99, 255, 264, 858, 669, 433],
[53.63, 64.8, 80, 110, 110, 255, 263.99, 855, 668, 432],
[53.71, 62.64, 79.99, 110, 110, 255, 263.99, 852, 666, 432],
[54.1, 63.11, 79.99, 109.99, 109.99, 254.99, 264, 874, 680, 438],
[57.79, 63.52, 80, 110, 110, 254.99, 264, 860, 668, 433],
[57.96, 64.33, 80, 110, 110, 255, 264, 864, 671, 433],
[57.75, 63.65, 80, 110, 109.99, 255, 263.99, 880, 682, 437],
[57.79, 63.95, 79.99, 109.99, 110, 254.99, 263.99, 882, 683, 437],
[56.82, 64.4, 79.99, 109.99, 109.99, 254.99, 263.99, 878, 680, 437],
[54.65, 64.7, 79.99, 110, 110, 255, 264, 881, 684, 438],
[54.61, 64.45, 79.99, 109.99, 110, 255, 263.99, 871, 676, 435],
[54.26, 64.37, 79.99, 110, 110, 255, 264, 867, 674, 434],
[53.43, 64.34, 79.99, 110, 109.99, 254.99, 263.99, 895, 691, 441],
[53.43, 64.69, 79.99, 110, 110, 255, 264, 889, 694, 440],
[53.39, 63.73, 80, 110, 110, 255, 263.99, 873, 680, 436],
[53.2, 65.6, 80, 110, 110, 254.99, 264, 869, 674, 433],
[53.24, 64.67, 79.99, 109.99, 110, 255, 264, 877, 680, 437],
[53.2, 64.03, 79.99, 110, 110, 255, 263.99, 882, 684, 438],
[53.31, 63.44, 80, 110, 110, 254.99, 264, 885, 687, 438],
[53.28, 64.16, 79.99, 110, 110, 255, 263.99, 867, 674, 434],
[53.28, 64.3, 80, 110, 110, 254.99, 264, 877, 679, 436],
[53.08, 64.26, 80, 110, 110, 254.99, 263.99, 868, 673, 434],
[53.08, 63.74, 79.99, 110, 110, 254.99, 264, 875, 683, 437],
[57.71, 63.71, 80, 110, 109.99, 254.99, 264, 877, 682, 437],
[57.71, 63.53, 80, 109.99, 110, 255, 263.99, 870, 677, 434],
[57.2, 65, 79.99, 109.99, 109.99, 254.99, 263.99, 878, 677, 436],
[57.16, 65.26, 79.99, 110, 110, 255, 264, 871, 674, 434],
[57.2, 65.23, 80, 110, 110, 254.99, 263.99, 874, 681, 436],
[56.2, 64.85, 79.99, 110, 110, 255, 264, 877, 685, 436],
[56.28, 64.85, 80, 110, 110, 254.99, 263.99, 859, 674, 433],
[54.57, 65.25, 79.99, 109.99, 109.99, 255, 264, 878, 686, 437],
[54.5, 65.3, 79.99, 110, 110, 254.99, 263.99, 884, 691, 437],
[54.38, 65.81, 79.99, 110, 110, 254.99, 264, 892, 694, 439],
[54.14, 65.49, 80, 110, 110, 255, 263.99, 883, 689, 437],
[54.02, 64.75, 79.99, 110, 110, 254.99, 263.99, 889, 699, 439],
[54.38, 64.2, 79.99, 110, 110, 254.99, 264, 890, 699, 439],
[53.78, 63.7, 79.99, 109.99, 109.99, 255, 264, 870, 683, 436],
[53.67, 62.47, 80, 110, 109.99, 255.02, 263.88, 875, 689, 436],
[53.63, 63.74, 80, 110, 110, 255, 264, 886, 693, 440],
[53.67, 63.7, 80, 110, 110, 255, 264, 885, 692, 438],
[53.55, 64.23, 79.99, 110, 109.99, 255, 264, 880, 690, 438],
[53.47, 63.97, 80, 109.99, 110, 254.99, 263.98, 866, 681, 433],
[53.67, 64.56, 79.99, 110, 110, 254.99, 264, 871, 680, 436],
[54.81, 64.83, 79.99, 110, 110, 254.99, 264, 868, 681, 434],
[54.9, 64.97, 79.99, 110, 110, 255, 263.99, 865, 677, 433],
[54.57, 65.81, 80, 110, 110, 254.99, 263.99, 858, 675, 432],
[54.65, 66.64, 79.99, 110, 110, 255.44, 264, 881, 681, 434],
[54.57, 65.78, 80, 110, 110, 255, 264, 862, 674, 434],
[54.73, 65.75, 79.99, 109.99, 110, 254.99, 263.99, 857, 672, 431],
[54.57, 66.29, 79.99, 109.99, 109.99, 254.99, 263.99, 858, 673, 432],
[54.14, 66.81, 80, 110, 109.99, 254.99, 264, 883, 695, 438],
[54.3, 66.6, 79.99, 110, 110, 254.99, 263.99, 875, 690, 437],
[54.18, 64.86, 79.99, 109.99, 110, 255, 264, 883, 691, 439],
[53.67, 64.14, 79.99, 109.99, 110, 254.99, 263.99, 866, 679, 435],
[53.67, 64.06, 80, 110, 110, 254.99, 264, 870, 685, 435],
[53.63, 64.48, 79.99, 110, 110, 254.99, 263.99, 867, 683, 436],
[53.59, 63.62, 80, 110, 109.99, 254.99, 264, 872, 685, 435],
[53.51, 64.52, 79.99, 109.99, 109.99, 255, 263.99, 854, 671, 432],
[53.63, 63.87, 79.99, 110, 110, 254.99, 264, 861, 680, 434],
[53.55, 63.99, 80, 110, 110, 254.99, 263.99, 867, 683, 434],
[53.35, 64.28, 80, 110, 109.99, 255, 263.99, 879, 692, 438],
[53.43, 64.93, 79.99, 110, 109.99, 254.99, 264, 873, 689, 436],
[53.39, 63.31, 80, 110, 110, 254.99, 264, 873, 689, 436],
[53.63, 65.33, 79.99, 110, 109.99, 255, 264, 890, 697, 439],
[53.86, 64.42, 79.99, 110, 109.99, 254.99, 264, 898, 704, 440],
[56.16, 66.37, 80, 110, 110, 255, 264, 893, 703, 439],
[56.32, 66.67, 80, 109.99, 110, 254.99, 264, 877, 690, 437],
[55.54, 63.37, 80, 110, 110, 255, 264, 878, 688, 436],
[55.5, 63.2, 80, 110, 109.99, 254.99, 264, 888, 694, 438],
[55.5, 63.47, 79.99, 110, 110, 255, 264, 884, 691, 438],
[55.26, 62.19, 80, 110, 110, 255, 263.99, 895, 703, 440],
[54.77, 62.31, 79.99, 110, 110, 255, 263.99, 895, 702, 440],
[54.94, 65.19, 80, 110, 110, 254.99, 264, 879, 690, 434],
[55.06, 65.18, 79.99, 110, 110, 255, 264, 882, 691, 436],
[55.02, 64.15, 80, 110, 110, 255, 263.99, 881, 695, 437],
[55.22, 64.7, 79.99, 109.99, 109.99, 254.99, 263.99, 883, 697, 438],
[54.85, 64.2, 79.99, 110, 110, 255, 263.99, 880, 690, 437],
[54.81, 64.54, 79.99, 110, 110, 255, 263.99, 885, 694, 437],
[54.98, 64.1, 79.99, 110, 110, 254.99, 264, 884, 695, 436],
[54.9, 63.93, 79.99, 110, 110, 254.99, 264, 889, 700, 439],
[55.02, 64.73, 79.99, 110, 109.99, 254.99, 263.96, 899, 705, 439],
[54.9, 65.65, 80, 110, 110, 255, 264, 898, 704, 441],
[55.02, 65.74, 80, 109.99, 110, 254.99, 264, 885, 694, 436],
[55.02, 64.94, 79.99, 110, 110, 254.99, 263.99, 882, 690, 437],
[55.1, 64.11, 80, 110, 110, 255, 263.99, 882, 694, 436],
[55.1, 63.21, 79.99, 110, 110, 255, 264, 898, 701, 440],
[54.98, 63.34, 80, 110, 110, 255, 263.99, 898, 704, 439],
[54.81, 62.2, 79.99, 110, 109.99, 255.17, 263.99, 899, 699, 438],
[54.77, 62.08, 80, 110, 110, 255, 264, 901, 706, 440],
[54.73, 62.74, 79.99, 110, 110, 255, 264, 896, 703, 439],
[54.77, 63.51, 80, 109.99, 110, 254.99, 264, 895, 701, 439],
[54.9, 65.01, 79.99, 110, 109.99, 255, 264, 894, 701, 439],
[55.54, 63.71, 79.99, 110, 110, 254.98, 264, 894, 700, 439],
[55.38, 63.78, 80, 110, 110, 254.99, 264, 891, 696, 438],
[55.99, 61.06, 79.99, 110, 110, 255, 263.99, 880, 686, 435],
[56.24, 61.32, 79.99, 110, 110, 254.99, 263.99, 875, 682, 434],
[56.45, 61.15, 80, 110, 110, 255, 263.99, 908, 708, 441],
[58.4, 63.22, 79.99, 110, 110, 254.99, 264, 874, 683, 432],
[58.35, 63.4, 80, 109.99, 110, 254.99, 264, 858, 675, 432],
[59.18, 63.96, 79.99, 110, 109.99, 254.99, 264, 885, 688, 435],
[59.4, 63.43, 79.99, 110, 109.99, 255, 263.99, 885, 691, 436],
[59.14, 63.11, 79.99, 109.99, 110, 255, 264.01, 887, 690, 435],
[58.92, 63.04, 80, 110, 110, 255, 263.99, 874, 682, 434],
[54.53, 65.79, 80, 109.99, 109.99, 255, 264, 872, 688, 435],
[51.9, 62.9, 79.99, 110, 109.99, 255, 264, 889, 703, 438],
[51.9, 63.61, 80, 110, 110, 254.99, 263.99, 886, 700, 439],
[51.9, 64.65, 79.99, 110, 110, 255.03, 263.67, 878, 696, 437],
[52.01, 64.98, 80, 110, 110, 255, 264, 877, 692, 437],
[51.48, 64.66, 79.99, 110, 110, 255, 264, 867, 684, 433],
[51.48, 64.65, 79.99, 110, 110, 255, 264, 865, 684, 434],
[53.35, 64.56, 79.99, 110, 110, 255, 264, 956, 707, 391],
[53.08, 64.44, 79.99, 110, 110, 254.99, 264, 953, 707, 390],
[53.78, 64.41, 80, 110, 110, 255, 263.99, 936, 694, 384],
[53.51, 65.25, 80, 110, 110, 255.17, 264, 917, 674, 374],
[53.82, 64.86, 80, 110, 110, 255, 263.99, 920, 683, 377],
[52.47, 64.91, 79.99, 110, 109.99, 255, 264, 835, 619, 350],
[52.54, 63.89, 80, 110, 110, 255, 264, 805, 603, 340],
[52.43, 63.62, 80, 109.99, 110, 254.99, 264, 868, 644, 432],
[52.31, 63.99, 80, 110, 110, 255, 264, 883, 655, 437],
[52.7, 64.35, 80, 109.99, 110, 254.99, 264, 893, 661, 438],
[52.51, 64.47, 80, 109.99, 110, 255, 264, 903, 670, 440],
[52.51, 65.14, 79.99, 110, 110, 255, 263.99, 890, 660, 439],
[52.51, 64.78, 79.99, 110, 110, 254.99, 264, 881, 657, 437],
[52.47, 64.39, 79.99, 109.99, 110, 255, 264, 884, 658, 437],
[52.51, 63.54, 80, 109.99, 110, 254.99, 263.99, 866, 643, 433],
[52.31, 64, 79.99, 109.99, 110, 255, 263.99, 899, 663, 439],
[52.31, 64.45, 79.99, 110, 110, 255, 263.99, 870, 643, 437],
[52.16, 64.33, 79.99, 110, 110, 255, 264, 875, 648, 434],
[51.86, 64.56, 80, 110, 109.99, 254.99, 264, 896, 664, 438],
[51.9, 65.67, 79.99, 110, 110, 255, 264, 902, 664, 439],
[51.78, 65.08, 80, 109.99, 110, 254.99, 263.99, 905, 670, 440],
[51.78, 65.47, 79.99, 110, 109.99, 254.99, 264, 914, 671, 441],
[51.71, 64.74, 80, 110, 110, 255, 263.99, 912, 675, 441],
[51.9, 63.89, 79.99, 109.99, 110, 255, 263.99, 909, 675, 441],
[51.9, 63.12, 80, 109.99, 110, 255, 263.99, 900, 668, 439],
[51.97, 63.06, 79.99, 110, 110, 255, 264, 895, 668, 438],
[52.01, 63.38, 80, 110, 110, 254.97, 264, 914, 667, 439],
[51.94, 63.57, 79.99, 110, 110, 254.99, 263.99, 900, 666, 439],
[52.09, 64.4, 80, 109.99, 110, 254.99, 264, 869, 648, 433],
[52.31, 64.42, 79.99, 109.99, 110, 255, 264, 884, 656, 437],
[52.16, 63.97, 79.99, 109.99, 109.99, 255, 263.98, 892, 663, 438],
[52.16, 63.31, 79.99, 110, 110, 254.99, 263.98, 914, 675, 441],
[52.39, 65.09, 80, 110, 110, 255, 264, 889, 658, 437],
[52.35, 64.49, 79.99, 110, 110, 254.99, 263.99, 892, 658, 437],
[52.28, 64.37, 79.99, 110, 110, 255, 264, 892, 661, 438],
[52.39, 66.02, 79.99, 110, 110, 254.99, 264, 900, 665, 439],
[52.7, 66.74, 79.99, 109.99, 109.99, 254.99, 264, 896, 664, 438],
[52.85, 63.96, 80, 110, 110, 254.99, 264, 909, 670, 441],
[52.97, 63.41, 80, 110, 110, 255, 264, 904, 665, 440],
[53.12, 63.52, 79.99, 110, 109.99, 254.99, 263.99, 910, 673, 442],
[52.51, 64.35, 79.99, 110, 110, 254.99, 263.99, 899, 670, 439],
[51.9, 64.71, 80, 110, 110, 254.99, 263.99, 905, 672, 441],
[51.71, 63.89, 79.99, 110, 109.99, 254.99, 264, 889, 661, 437],
[51.56, 64.61, 80, 109.99, 109.99, 254.99, 263.99, 884, 654, 437],
[51.63, 62.89, 80, 110, 110, 255, 263.99, 889, 664, 437],
[51.71, 62.56, 79.99, 109.99, 110, 254.99, 263.99, 887, 659, 437],
[52.24, 64.62, 79.99, 110, 110, 255, 264, 905, 667, 440],
[52.31, 64.14, 80, 110, 110, 254.99, 263.99, 905, 672, 441],
[52.39, 63.42, 80, 109.99, 110, 255, 263.99, 912, 675, 441],
[52.39, 63.86, 79.99, 110, 110, 255, 263.99, 914, 676, 442],
[53.28, 66.44, 79.99, 110, 110, 254.99, 263.99, 909, 674, 440],
[51.97, 63.82, 80, 110, 109.99, 254.99, 263.99, 897, 671, 440],
[52.05, 63.49, 79.99, 110, 110, 254.99, 263.99, 879, 655, 436],
[51.94, 62.8, 80, 110, 110, 254.99, 263.99, 890, 661, 437],
[52.35, 62.92, 80, 110, 110, 254.99, 263.99, 877, 649, 435],
[52.09, 64.1, 79.99, 110, 109.99, 254.99, 264, 875, 652, 435],
[51.9, 66.09, 79.99, 110, 109.99, 255, 263.99, 909, 675, 441],
[51.82, 65.03, 79.99, 110, 109.99, 254.99, 263.99, 896, 668, 440],
[51.9, 64.7, 80, 109.99, 110, 255, 264, 904, 671, 441],
[51.86, 64.98, 80, 109.99, 110, 255, 263.99, 911, 674, 441],
[51.86, 65.07, 79.99, 110, 110, 255, 264, 901, 671, 441],
[51.82, 63.58, 79.99, 110, 109.99, 255, 263.99, 895, 666, 439],
[48.19, 65.2, 79.99, 110, 110, 255, 264, 904, 673, 437],
[48.12, 65.1, 79.99, 109.99, 110, 254.99, 264, 918, 678, 438],
[48.19, 65.88, 80, 109.99, 110, 255, 264, 909, 682, 438],
[47.87, 66.74, 79.99, 110, 109.99, 255, 263.99, 913, 677, 438],
[49.94, 64.46, 79.99, 110, 110, 255, 263.99, 891, 657, 434],
[49.86, 65.28, 79.99, 110, 109.99, 254.99, 264, 904, 670, 436],
[50.19, 64.39, 79.99, 109.99, 110, 254.99, 264, 910, 672, 438],
[50.93, 62.78, 80, 110, 110, 254.99, 263.99, 889, 653, 434],
[50.93, 63.24, 80, 110, 110, 254.99, 263.99, 890, 656, 434],
[50.96, 62.97, 79.99, 110, 110, 255, 263.99, 886, 657, 433],
[50.93, 64.61, 80, 109.99, 110, 255, 263.99, 901, 664, 437],
[51.11, 65.35, 80, 109.99, 109.99, 255, 264, 897, 660, 436],
[51.22, 63.44, 79.99, 109.99, 110, 255, 263.99, 893, 661, 436],
[51.94, 63.01, 79.99, 109.99, 110, 254.99, 264, 888, 659, 435],
[51.63, 63.96, 79.99, 110, 110, 255, 264, 887, 658, 435],
[51.9, 64.24, 79.99, 109.99, 110, 255, 263.99, 883, 653, 434],
[51.82, 64.21, 80, 110, 110, 254.99, 263.99, 885, 658, 434],
[51.48, 65.51, 79.99, 110, 110, 254.99, 264, 905, 671, 437],
[51.45, 64.83, 79.99, 110, 109.99, 255, 264, 894, 661, 435],
[51.15, 65.21, 79.99, 109.99, 110, 254.99, 263.98, 893, 662, 434],
[51.04, 65.13, 80, 110, 110, 255, 264, 887, 658, 434],
[51, 68.17, 80, 110, 109.99, 255, 263.99, 894, 664, 435],
[50.93, 67.62, 79.99, 110, 109.99, 255, 264, 890, 657, 434],
[50.96, 66.4, 79.99, 110, 110, 255, 264, 884, 655, 433],
[50.85, 66.08, 80, 109.99, 110, 255, 263.99, 878, 652, 433],
[51.3, 64.7, 80, 110, 109.99, 255, 263.99, 902, 668, 436],
[51.22, 64.26, 79.99, 110, 110, 255, 263.99, 894, 662, 435],
[50.74, 63.82, 80, 110, 109.99, 255, 264, 900, 664, 437],
[50.96, 63.91, 79.99, 109.99, 110, 255, 263.99, 885, 654, 433],
[50.96, 64.34, 80, 110, 109.99, 255, 264, 885, 656, 433],
[51.3, 64.87, 80, 109.99, 110, 254.99, 263.98, 877, 648, 432],
[56.24, 63.77, 80, 110, 110, 254.99, 264, 874, 644, 434],
[56.28, 63.59, 79.99, 110, 110, 254.99, 264, 862, 638, 432],
[54.65, 66, 79.99, 109.99, 110, 255.04, 263.92, 904, 665, 437],
[54.81, 66.16, 79.99, 110, 110, 255, 264, 889, 656, 437],
[54.22, 63.36, 79.99, 109.99, 110, 254.99, 264, 879, 652, 435],
[54.26, 62.86, 79.99, 110, 110, 254.99, 263.99, 890, 660, 437],
[54.18, 63.1, 79.99, 109.99, 110, 255, 263.87, 880, 648, 434],
[54.14, 62.95, 80, 110, 109.99, 254.99, 264, 867, 642, 433],
[54.14, 62.68, 79.99, 110, 110, 255, 264, 889, 659, 437],
[54.06, 63.76, 80, 110, 109.99, 254.99, 264, 884, 656, 436],
[54.14, 65.08, 79.99, 110, 110, 254.99, 263.99, 888, 656, 436],
[54.14, 65.18, 79.99, 110, 110, 254.99, 263.99, 892, 660, 437],
[53.9, 65.53, 80, 110, 110, 255, 264, 883, 654, 436],
[53.78, 66.04, 80, 110, 110, 255, 263.99, 876, 646, 433],
[54.06, 65.28, 80, 110, 110, 254.99, 263.99, 885, 657, 436],
[54.06, 64.56, 80, 110, 110, 254.99, 263.94, 901, 657, 435],
[53.94, 63.88, 79.99, 109.99, 110, 255, 264, 891, 657, 437],
[53.71, 67.24, 79.99, 110, 110, 255, 263.99, 887, 656, 437],
[53.98, 66.4, 79.99, 109.99, 110, 255, 263.99, 884, 656, 436],
[53.08, 63.82, 79.99, 110, 110, 254.99, 263.99, 882, 653, 434],
[53.12, 63.24, 80, 110, 109.99, 254.99, 264, 904, 669, 439],
[54.18, 63.44, 80, 110, 110, 254.98, 263.9, 904, 670, 438],
[54.53, 63.27, 80, 110, 110, 255, 264, 902, 665, 440],
[56.53, 63.5, 79.99, 110, 109.99, 254.99, 263.98, 884, 653, 435],
[56.28, 63.4, 79.99, 110, 109.99, 255, 263.99, 889, 659, 436],
[58.31, 66.01, 80, 110, 110, 255.03, 264, 895, 658, 436],
[58.27, 66.52, 79.99, 110, 110, 255, 263.99, 901, 666, 438],
[57.28, 64.07, 79.99, 109.99, 110, 254.99, 263.84, 894, 659, 435],
[57.33, 63.5, 80, 110, 110, 254.99, 264, 906, 666, 438],
[57.41, 63.58, 80, 110, 110, 254.99, 264, 935, 667, 438],
[57.11, 63.3, 79.99, 110, 109.99, 255, 264, 901, 669, 438],
[56.28, 64.24, 80, 110, 109.99, 255.04, 264, 896, 657, 436],
[56.2, 63.94, 80, 109.99, 110, 254.99, 264, 905, 670, 438],
[56.24, 64.24, 80, 110, 110, 255, 264, 894, 660, 437],
[55.99, 64.55, 79.99, 109.99, 110, 254.99, 263.99, 889, 658, 436],
[55.83, 64.28, 79.99, 110, 109.99, 255, 263.99, 877, 649, 434],
[53.04, 65.55, 80, 110, 110, 255, 263.99, 888, 658, 435],
[53.01, 64.64, 79.99, 110, 110, 254.99, 263.99, 885, 660, 436],
[53.12, 64.23, 80, 109.99, 110, 254.99, 263.99, 885, 655, 435],
[53.24, 64.01, 79.99, 110, 110, 255, 264, 911, 677, 442],
[53.35, 64.29, 79.99, 109.99, 110, 255, 263.99, 882, 651, 434],
[53.39, 65.12, 80, 109.99, 110, 255, 264, 871, 646, 432],
[53.39, 65.02, 80, 109.99, 110, 255, 264, 872, 647, 433],
[58.05, 62.64, 80, 109.99, 110, 255, 264, 889, 656, 436],
[58.14, 62.28, 79.99, 110, 110, 254.99, 264, 875, 647, 433],
[58.35, 61.84, 79.99, 110, 110, 254.99, 264, 878, 643, 433],
[58.87, 65.05, 79.99, 110, 110, 255, 263.99, 882, 650, 434],
[59.05, 64.85, 79.99, 110, 110, 255, 263.99, 879, 649, 435],
[58.92, 64.16, 79.99, 110, 109.99, 254.99, 263.99, 864, 642, 431],
[58.83, 65.14, 80, 109.99, 110, 254.99, 264, 866, 640, 431],
[58.96, 64.98, 79.99, 110, 110, 256.11, 264.02, 892, 656, 435],
[58.92, 64.18, 80, 110, 110, 255, 263.99, 892, 652, 434],
[57.37, 64.77, 80, 109.99, 110, 254.99, 264, 900, 667, 438],
[57.45, 64.83, 80, 110, 110, 255, 264, 894, 662, 436],
[57.58, 65.2, 79.99, 110, 110, 254.99, 263.99, 909, 671, 438],
[57.33, 65.79, 80, 110, 110, 254.99, 264, 905, 669, 438],
[57.28, 66.59, 79.99, 110, 109.99, 254.99, 264, 899, 666, 438],
[57.2, 65.67, 80, 109.99, 110, 254.99, 264, 884, 654, 434],
[57.07, 64.79, 80, 110, 110, 255, 263.99, 895, 660, 437],
[57.28, 65.28, 79.99, 110, 110, 255, 264, 897, 662, 436],
[57.41, 65.64, 80, 110, 110, 254.99, 263.99, 899, 663, 438],
[57.2, 65.88, 79.99, 110, 110, 255, 264, 898, 663, 437],
[55.83, 64.34, 79.99, 109.99, 109.99, 254.99, 263.99, 880, 650, 434],
[54.81, 63.38, 79.99, 109.99, 110, 255, 264, 889, 656, 435],
[54.77, 63.8, 80, 110, 109.99, 254.99, 263.99, 897, 665, 437],
[54.85, 62.91, 79.99, 109.99, 110, 254.99, 264, 890, 658, 436],
[54.61, 63.56, 79.99, 110, 110, 255, 264, 907, 669, 439],
[54.73, 63.65, 79.99, 110, 110, 255, 263.99, 907, 673, 439],
[55.38, 63.21, 80, 110, 110, 255, 263.99, 867, 643, 431],
[55.42, 62.36, 80, 110, 110, 254.99, 264, 863, 642, 431],
[56.53, 61.66, 80, 110, 110, 255, 263.99, 876, 651, 434],
[56.4, 62.87, 79.99, 110, 110, 255, 263.99, 881, 655, 434],
[56.61, 63.23, 80, 110, 110, 254.99, 263.99, 895, 662, 437],
[60.12, 63.81, 79.99, 109.99, 110, 255, 263.99, 904, 659, 435],
[59.94, 64.1, 80, 110, 110, 255, 264, 898, 659, 436],
[59.89, 65.58, 80, 110, 110, 255, 264, 886, 652, 434],
[59.85, 65.28, 80, 110, 110, 254.99, 263.98, 911, 666, 437],
[59.76, 64.83, 80, 109.99, 110, 254.99, 264, 912, 673, 440],
[57.33, 64.85, 79.99, 110, 110, 255, 263.99, 913, 673, 439],
[57.07, 64.73, 79.99, 109.99, 110, 254.99, 264, 911, 672, 439],
[56.95, 64.35, 79.99, 110, 110, 255, 263.99, 898, 663, 436],
[56.99, 64.69, 80, 110, 110, 254.99, 264, 898, 664, 437],
[57.16, 65.4, 79.99, 110, 110, 255, 263.99, 913, 667, 438],
[57.11, 64.66, 79.99, 110, 110, 255, 264, 905, 672, 438],
[56.7, 64.6, 80, 110, 110, 254.99, 263.99, 914, 671, 439],
[56.61, 64.37, 79.99, 110, 110, 255, 264, 908, 668, 438],
[54.9, 64.8, 80, 110, 109.99, 254.99, 263.99, 905, 668, 438],
[55.18, 64.59, 80, 110, 110, 255, 264, 895, 667, 437],
[54.77, 65.1, 80, 109.99, 109.99, 254.99, 264, 914, 674, 440],
[54.81, 64.93, 80, 110, 110, 254.99, 263.99, 921, 682, 440],
[54.81, 64.77, 80, 109.99, 110, 255, 264.01, 907, 677, 439],
[54.61, 65.41, 79.99, 110, 110, 255, 264, 920, 680, 441],
[54.5, 65.04, 80, 109.99, 110, 255, 264, 909, 673, 439],
[54.53, 65.2, 80, 110, 110, 255, 264, 913, 674, 440],
[54.46, 64.9, 79.99, 110, 109.99, 254.98, 264, 899, 666, 438],
[54.5, 64.83, 80, 110, 110, 255, 264, 898, 668, 437],
[56.86, 64.25, 80, 109.99, 110, 254.99, 263.99, 918, 684, 441],
[56.78, 64.7, 79.99, 109.99, 110, 255, 264, 914, 678, 440],
[60.12, 64.07, 80, 109.99, 110, 255, 264, 915, 673, 439],
[60.16, 63.83, 79.99, 110, 109.99, 255, 264, 908, 671, 437],
[54.94, 64.55, 80, 110, 110, 255, 264, 914, 676, 439],
[55.02, 65.45, 80, 109.99, 110, 255, 263.99, 911, 677, 439],
[55.02, 65.44, 79.99, 110, 110, 254.99, 264, 915, 677, 441],
[55.18, 64.51, 80, 110, 110, 254.99, 263.99, 914, 679, 438],
[55.06, 64.88, 80, 109.99, 110, 255, 264, 903, 673, 438],
[53.78, 64.06, 80, 110, 110, 255, 264, 910, 677, 440],
[53.74, 63.97, 79.99, 110, 110, 255, 264, 915, 677, 439],
[53.67, 63.53, 80, 109.99, 109.99, 254.99, 264, 914, 675, 440],
[53.71, 64.65, 80, 109.99, 109.99, 255, 264, 897, 669, 438],
[53.82, 64.52, 80, 110, 109.99, 255, 264, 914, 677, 440],
[54.14, 65.21, 80, 110, 110, 255, 263.99, 921, 683, 441],
[53.51, 64.36, 79.99, 110, 110, 255, 263.99, 881, 654, 434],
[53.04, 63.94, 80, 110, 109.99, 254.99, 264, 890, 663, 436],
[55.02, 62.63, 79.99, 110, 110, 254.99, 264, 910, 676, 440],
[54.98, 63.22, 80, 110, 110, 255, 263.98, 914, 674, 439],
[55.14, 64, 79.99, 110, 110, 255, 263.99, 907, 672, 439],
[59.18, 64.98, 80, 110, 110, 255, 263.99, 903, 667, 436],
[59.27, 64.94, 80, 110, 110, 254.99, 264, 914, 672, 438],
[57.54, 63.55, 79.99, 109.99, 110, 255, 263.99, 883, 655, 434],
[57.24, 64.29, 79.99, 110, 110, 255, 264, 888, 658, 434],
[56.32, 65.59, 79.99, 110, 110, 254.96, 264, 890, 659, 434],
[56.24, 65.69, 79.99, 109.99, 110, 254.99, 263.99, 892, 660, 436],
[56.16, 65.09, 79.99, 110, 110, 254.99, 264, 911, 673, 439],
[56.07, 63.85, 79.99, 110, 110, 255, 264, 905, 670, 437],
[55.95, 63.94, 79.99, 110, 110, 255, 263.99, 916, 676, 439],
[55.26, 65.64, 79.99, 110, 110, 255, 263.99, 918, 682, 441],
[55.1, 65.26, 80, 109.99, 110, 255, 263.99, 899, 665, 438],
[55.14, 65.15, 80, 109.99, 109.99, 254.99, 264, 894, 665, 435],
[55.06, 64.67, 79.99, 110, 109.99, 255, 264, 915, 675, 440],
[55.14, 64.3, 79.99, 109.99, 110, 255, 264, 908, 673, 439],
[54.53, 62.65, 79.99, 110, 110, 255, 264, 914, 677, 438],
[54.53, 63.19, 79.99, 110, 110, 255, 264, 890, 660, 437],
[54.69, 64.12, 80, 110, 110, 254.97, 264, 915, 675, 439],
[54.53, 64.91, 79.99, 110, 110, 254.99, 263.99, 920, 683, 440],
[54.5, 64.97, 79.99, 110, 110, 254.99, 264, 912, 678, 439],
[54.69, 64.68, 80, 110, 110, 255, 263.99, 910, 671, 439],
[54.65, 65.2, 79.99, 110, 110, 255, 264.01, 910, 675, 440],
[54.57, 65.24, 79.99, 109.99, 110, 254.99, 263.99, 920, 683, 440],
[54.5, 65.37, 79.99, 109.99, 110, 255, 263.99, 911, 676, 440],
[54.18, 64.31, 80, 109.99, 110, 255, 264, 917, 680, 441],
[54.22, 63.79, 80, 110, 109.99, 255, 263.98, 919, 681, 441],
[54.3, 64.74, 79.99, 110, 110, 254.99, 263.99, 904, 668, 438],
[54.18, 64.91, 80, 110, 109.99, 255, 264, 903, 669, 438],
[53.9, 65.26, 79.99, 109.99, 110, 254.99, 264, 898, 665, 435],
[54.06, 64.95, 79.99, 110, 109.99, 255, 263.99, 882, 656, 435],
[54.1, 64.74, 80, 109.99, 109.99, 254.99, 264, 873, 650, 433],
[54.22, 63.87, 80, 109.99, 110, 254.99, 264, 881, 652, 434],
[54.57, 63.44, 80, 110, 110, 254.98, 263.98, 873, 651, 432],
[54.46, 62.88, 79.99, 110, 110, 254.99, 263.99, 884, 657, 435],
[55.02, 64.01, 79.99, 110, 110, 254.99, 263.99, 896, 666, 436],
[55.3, 63.24, 80, 110, 110, 254.98, 264, 890, 659, 435],
[55.3, 62.85, 79.99, 110, 110, 255, 264, 904, 670, 438],
[55.34, 63.79, 79.99, 110, 110, 254.99, 264, 903, 671, 438],
[55.42, 63.72, 80, 110, 110, 254.99, 264, 892, 660, 436],
[55.46, 63.98, 79.99, 110, 110, 255, 263.99, 882, 658, 434],
[55.83, 64.37, 80, 110, 109.99, 254.99, 263.99, 914, 678, 439],
[54.57, 64.74, 80, 110, 110, 254.99, 264, 910, 673, 439],
[54.69, 64.24, 79.99, 110, 110, 254.99, 264, 913, 679, 439],
[53.86, 64.05, 80, 110, 110, 254.97, 263.98, 893, 664, 435],
[53.94, 64.81, 80, 110, 110, 254.99, 263.99, 889, 660, 435],
[53.94, 64.42, 79.99, 110, 109.99, 254.99, 263.99, 919, 680, 440],
[53.82, 64.4, 80, 110, 109.99, 255, 264, 914, 677, 440],
[53.67, 63.88, 79.99, 109.99, 110, 254.99, 263.99, 903, 668, 437],
[53.86, 64.8, 80, 109.99, 110, 255, 263.99, 901, 669, 437],
[53.82, 65.02, 80, 109.99, 109.99, 254.99, 263.99, 908, 672, 439],
[53.86, 64.68, 80, 110, 110, 254.98, 264, 917, 683, 440],
[53.71, 65.46, 80, 109.99, 109.99, 255, 264, 907, 674, 439],
[53.16, 64.8, 79.99, 110, 110, 254.99, 264, 908, 673, 439],
[53.08, 64.66, 79.99, 110, 110, 254.99, 263.99, 903, 666, 437],
[53.01, 65.08, 79.99, 110, 109.99, 255, 263.99, 904, 666, 438],
[53.08, 64.83, 80, 110, 109.99, 254.96, 263.99, 910, 672, 439],
[53.12, 64.41, 80, 110, 109.99, 254.99, 264, 897, 668, 437],
[53.31, 63.86, 80, 110, 110, 254.99, 264, 917, 678, 440],
[53.39, 64.07, 80, 110, 109.99, 255, 263.99, 912, 673, 439],
[53.59, 64.28, 79.99, 110, 109.99, 255, 264, 905, 667, 438],
[54.38, 64.73, 80, 110, 109.99, 255, 264, 903, 667, 438],
[54.02, 64.42, 79.99, 110, 110, 254.99, 264.01, 920, 671, 440],
[54.06, 65.94, 79.99, 110, 110, 255, 264, 888, 658, 435],
[54.02, 65.47, 80, 109.99, 110, 255, 263.99, 904, 665, 437],
[54.02, 65.61, 80, 110, 109.99, 255, 263.99, 911, 670, 439],
[53.94, 65.21, 80, 110, 110, 255, 264, 904, 665, 438],
[54.02, 65.08, 79.99, 110, 110, 255, 264, 922, 679, 441],
[54.3, 65.25, 80, 109.99, 110, 255, 264, 927, 678, 441],
[54.26, 65.47, 79.99, 110, 110, 255, 264, 913, 677, 439],
[54.42, 63.3, 79.99, 110, 110, 254.96, 264, 910, 669, 438],
[54.5, 62.45, 80, 110, 110, 254.99, 263.99, 912, 674, 439],
[54.81, 63.52, 80, 110, 110, 255, 264, 916, 675, 439],
[54.69, 63.75, 80, 110, 110, 254.99, 264.01, 909, 668, 437],
[54.69, 64.43, 79.99, 110, 110, 255, 264, 905, 669, 438],
[54.73, 64.91, 79.99, 109.99, 109.99, 255, 263.99, 883, 654, 434],
[54.9, 63.52, 80, 109.99, 110, 255, 263.99, 900, 661, 437],
[54.65, 64.69, 79.99, 109.99, 110, 255, 264, 902, 663, 437],
[54.38, 67.25, 80.01, 110, 110, 254.99, 264, 916, 678, 440],
[54.53, 68.34, 79.99, 110, 109.99, 254.99, 263.99, 917, 678, 440],
[54.26, 64.35, 79.99, 109.99, 110, 255, 263.99, 911, 671, 440],
[54.26, 62.28, 80, 109.99, 109.99, 254.99, 263.99, 918, 680, 441],
[54.42, 64.79, 79.99, 109.99, 109.99, 254.99, 263.99, 923, 680, 442],
[54.18, 64.91, 80, 109.99, 110, 255, 263.99, 919, 673, 439],
[54.26, 64.71, 79.99, 110, 110, 254.99, 264, 907, 671, 439],
[54.26, 65.54, 80, 110, 110, 255, 264, 903, 667, 437],
[53.43, 63.85, 80, 110, 110, 254.99, 263.99, 919, 677, 441],
[53.24, 64.17, 79.99, 109.99, 110, 255, 264, 923, 680, 440],
[53.74, 64.3, 79.99, 110, 110, 254.99, 263.99, 915, 674, 440],
[53.71, 63.38, 79.99, 110, 110, 255, 264, 913, 673, 438],
[53.67, 63.44, 80, 110, 110, 254.99, 264, 917, 673, 440],
[53.74, 63.12, 80, 110, 110, 255, 264, 908, 669, 437],
[53.94, 63.64, 80, 110, 110, 254.98, 263.94, 919, 674, 439],
[53.67, 64, 79.99, 110, 110, 254.99, 264, 922, 680, 441],
[53.67, 64.16, 80, 109.99, 109.99, 254.99, 263.99, 904, 672, 438],
[53.67, 64.55, 79.99, 110, 110, 254.99, 264, 890, 661, 436],
[53.35, 64.86, 79.99, 110, 110, 255, 263.99, 911, 674, 439],
[53.2, 63.99, 80, 110, 109.99, 255, 264, 904, 670, 439],
[53.16, 63.56, 79.99, 110, 110, 254.99, 263.99, 904, 667, 438],
[53.12, 63.27, 79.99, 110, 110, 254.99, 263.99, 902, 669, 437],
[52.77, 67.27, 80, 109.99, 109.99, 255, 264, 906, 671, 438],
[52.77, 66.45, 79.99, 110, 110, 255, 263.99, 910, 674, 439],
[52.74, 65.42, 80, 110, 109.99, 254.99, 263.99, 905, 666, 437],
[52.81, 65.29, 79.99, 110, 109.99, 254.99, 263.99, 910, 679, 440],
[52.66, 64.84, 79.99, 110, 110, 255, 264, 916, 675, 440],
[52.77, 64.46, 79.99, 109.99, 110, 255, 264, 916, 678, 440],
[52.81, 64.55, 80, 109.99, 109.99, 254.99, 264, 912, 675, 440],
[52.93, 64.2, 79.99, 110, 110, 254.99, 263.99, 915, 682, 440],
[54.38, 63.43, 80, 109.99, 109.99, 254.99, 264, 890, 663, 436],
[54.14, 62.51, 79.99, 109.99, 110, 255, 263.99, 883, 658, 435],
[54.65, 64.96, 79.99, 110, 110, 254.99, 264, 917, 680, 441],
[54.46, 64.75, 79.99, 109.99, 110, 254.97, 264, 921, 683, 441],
[54.57, 63.91, 79.99, 110, 110, 255, 263.99, 915, 682, 441],
[54.61, 64.15, 80, 109.99, 110, 255, 263.99, 914, 678, 440],
[54.65, 64.36, 80, 110, 109.99, 254.99, 264, 912, 677, 440],
[55.66, 64.78, 79.99, 109.99, 110, 255, 264, 920, 681, 441],
[55.75, 65.37, 80, 110, 109.99, 254.99, 264, 898, 662, 436],
[55.95, 65.76, 79.99, 110, 110, 254.99, 264, 905, 672, 438],
[55.79, 64.86, 79.99, 110, 109.99, 254.99, 264, 906, 669, 439],
[55.95, 64.61, 79.99, 109.99, 109.99, 255, 264, 921, 678, 440],
[55.66, 64.71, 79.99, 110, 110, 255, 263.99, 905, 672, 439],
[55.91, 64.03, 79.99, 110, 110, 254.99, 264, 894, 659, 435],
[55.75, 63.52, 79.99, 110, 110, 255, 263.99, 897, 661, 437],
[55.1, 64.54, 80, 110, 110, 255, 264, 905, 670, 439],
[54.69, 64.47, 79.99, 110, 110, 254.99, 263.99, 910, 673, 439],
[54.42, 64.47, 79.99, 110, 110, 255, 264, 922, 680, 441],
[53.74, 65.42, 79.99, 109.99, 110, 255, 264, 906, 668, 438],
[53.86, 64.89, 80, 110, 110, 254.99, 263.99, 914, 672, 439],
[53.74, 64.7, 80, 110, 109.99, 254.99, 264, 914, 678, 440],
[53.24, 64.56, 79.99, 110, 110, 254.99, 263.99, 911, 675, 438],
[53.28, 64.55, 79.99, 110, 110, 254.99, 264, 906, 670, 438],
[53.16, 64.45, 80, 109.99, 110, 255, 264, 915, 678, 440],
[53.47, 64.84, 79.99, 110, 110, 254.99, 263.99, 906, 675, 438],
[53.2, 65.34, 79.99, 110, 110, 255, 264, 901, 663, 437],
[53.12, 65.22, 80, 110, 110, 255, 263.99, 900, 664, 437],
[52.89, 64.77, 80, 110, 110, 255, 263.99, 902, 669, 438],
[53.08, 64.77, 80, 110, 110, 254.99, 263.99, 914, 673, 439],
[53.01, 63.93, 80, 110, 109.99, 254.99, 263.99, 909, 673, 438],
[53.04, 64.99, 80, 110, 110, 254.84, 263.99, 911, 665, 438],
[52.97, 67.61, 80, 110, 110, 255, 263.99, 908, 670, 438],
[53.12, 64.83, 80, 109.99, 110, 263.36, 263.99, 922, 685, 442],
[53.16, 64.02, 80, 110, 110, 255, 263.99, 915, 675, 440],
[57.2, 64.89, 79.99, 109.99, 110, 254.99, 264, 889, 657, 435],
[57.54, 64.4, 79.99, 110, 109.99, 255, 264.01, 874, 644, 432],
[57.84, 65.12, 79.99, 110, 110, 254.99, 263.99, 873, 644, 430],
[57.88, 64.52, 80, 110, 109.99, 254.99, 264, 876, 649, 433],
[57.71, 63.82, 80, 110, 110, 254.99, 264, 904, 664, 437],
[57.62, 64.17, 80, 110, 110, 254.99, 263.99, 909, 670, 437],
[55.42, 65.31, 80, 110, 110, 254.99, 264, 910, 670, 439],
[55.58, 64.57, 80, 110, 110, 254.99, 263.99, 908, 670, 438],
[55.54, 64.42, 80, 110, 110, 255, 264, 911, 676, 439],
[53.78, 64.57, 80, 110, 109.99, 254.99, 264, 905, 670, 438],
[54.06, 64.86, 79.99, 110, 110, 254.99, 264, 910, 672, 438],
[54.02, 64.11, 80, 110, 110, 255, 264, 917, 674, 439],
[53.9, 64.18, 79.99, 110, 109.99, 254.99, 263.99, 904, 670, 438],
[53.98, 63.78, 79.99, 110, 110, 254.99, 264, 916, 678, 440],
[53.86, 63.95, 80, 109.99, 110, 254.99, 263.99, 929, 688, 442],
[53.55, 64.45, 80, 109.99, 110, 255.02, 269.51, 910, 672, 438],
[53.74, 64.93, 80, 109.99, 110, 255, 263.98, 906, 671, 439],
[53.2, 64.88, 80, 109.99, 110, 255, 263.99, 906, 671, 437],
[53.16, 64.16, 80, 110, 110, 254.95, 263.6, 895, 665, 435],
[53.2, 63.67, 80, 109.99, 110, 255, 263.99, 897, 665, 437],
[53.94, 64.2, 79.99, 110, 110, 255, 264, 893, 661, 436],
[54.14, 63.77, 80, 110, 110, 254.99, 264, 883, 656, 434],
[54.26, 63.27, 80, 110, 110, 255.02, 263.8, 875, 651, 431],
[54.18, 63.98, 80, 110, 110, 255, 263.99, 891, 659, 435],
[54.34, 63.89, 79.99, 109.99, 110, 255, 264, 905, 667, 438],
[54.42, 64.01, 79.99, 110, 110, 255, 263.99, 912, 674, 439],
[54.42, 62.8, 80, 110, 110, 254.99, 263.99, 900, 668, 438],
[54.42, 62.93, 80, 110, 110, 255, 263.99, 898, 664, 436],
[54.5, 62.93, 79.99, 109.99, 109.99, 255, 264, 894, 663, 436],
[55.34, 63.82, 79.99, 110, 110, 255, 263.99, 906, 671, 438],
[55.34, 64.1, 80, 110, 109.99, 254.99, 264, 911, 676, 439],
[56.32, 64.59, 80, 110, 110, 255, 263.99, 876, 649, 432],
[56.36, 64.82, 80, 110, 110, 254.95, 264, 883, 660, 433],
[56.4, 65.08, 79.99, 110, 109.99, 255, 264, 897, 668, 436],
[57.37, 63.15, 80, 110, 109.99, 255, 264, 887, 654, 434],
[57.54, 63.15, 80, 110, 110, 254.99, 264, 881, 652, 432],
[57.5, 63.37, 79.99, 110, 110, 255, 264, 881, 650, 431],
[57.54, 64.06, 79.99, 110, 110, 255, 264, 887, 654, 433],
[57.5, 64.59, 79.99, 110, 110, 254.99, 264, 892, 658, 434],
[57.45, 64.87, 80, 110, 109.99, 255, 264, 886, 654, 434],
[56.86, 65.18, 79.99, 110, 110, 254.86, 264, 904, 668, 436],
[56.78, 65.31, 80, 110, 110, 254.99, 263.99, 889, 655, 435],
[55.62, 64.54, 80, 109.99, 110, 254.99, 264, 911, 672, 439],
[54.18, 65.43, 80, 110, 109.99, 254.11, 264, 891, 662, 433],
[54.26, 65.66, 79.99, 110, 109.99, 254.99, 263.99, 909, 673, 439],
[53.74, 63.42, 80, 110, 110, 255, 264, 902, 668, 436],
[53.74, 63.81, 80, 109.99, 109.99, 255, 264, 882, 653, 435],
[53.55, 64.73, 79.99, 110, 109.99, 254.93, 263.99, 902, 666, 436],
[53.59, 65.78, 79.99, 109.99, 109.99, 254.99, 263.99, 914, 676, 440],
[53.35, 64.89, 80, 110, 109.99, 254.99, 264, 929, 689, 442],
[53.39, 65.04, 79.99, 109.99, 109.99, 255, 264, 925, 684, 441],
[53.39, 63.67, 79.99, 110, 110, 255, 263.99, 913, 678, 439],
[53.35, 64.22, 79.99, 110, 110, 255, 263.99, 917, 679, 440],
[53.63, 65.25, 80, 109.99, 110, 255, 264, 904, 671, 437],
[53.59, 64.14, 80, 110, 110, 255, 264, 902, 671, 438],
[53.55, 64.08, 79.99, 110, 110, 254.98, 263.99, 903, 669, 437],
[53.74, 64.09, 79.99, 109.99, 110, 254.99, 263.99, 897, 665, 437],
[53.47, 63.31, 79.99, 110, 110, 255, 263.99, 888, 658, 434],
[53.63, 63.56, 79.99, 109.99, 109.99, 255, 263.98, 886, 655, 434],
[53.74, 64.54, 80, 110, 110, 255, 263.99, 897, 669, 436],
[54.1, 64.71, 80, 110, 110, 255, 264.34, 908, 662, 435],
[53.82, 64.78, 80, 109.99, 109.99, 255, 264, 907, 676, 438],
[53.98, 64.37, 79.99, 110, 110, 255, 263.99, 901, 670, 437],
[53.9, 65.29, 80, 110, 109.99, 254.99, 264, 899, 668, 438],
[53.59, 64.83, 79.99, 109.99, 110, 255, 263.99, 913, 680, 440],
[53.59, 64.53, 79.99, 110, 110, 255, 264, 911, 675, 439],
[53.71, 64.25, 79.99, 110, 110, 254.99, 263.99, 913, 680, 439],
[53.55, 64.75, 79.99, 109.99, 109.99, 255, 264, 911, 675, 439],
[53.51, 63.64, 80, 110, 109.99, 255, 263.99, 900, 671, 438],
[53.82, 64.72, 80, 110, 110, 255, 264, 904, 667, 438],
[54.14, 64.52, 79.99, 110, 109.99, 255, 264, 900, 667, 437],
[54.26, 63.87, 79.99, 110, 110, 254.99, 264, 894, 660, 436],
[54.18, 63.94, 80, 109.99, 109.99, 254.99, 263.95, 909, 674, 437],
[54.38, 66.01, 79.99, 110, 109.99, 254.99, 263.99, 911, 675, 438],
[54.57, 66.2, 79.99, 110, 110, 255, 264, 899, 669, 436],
[54.65, 65.71, 79.99, 110, 110, 254.99, 263.99, 880, 655, 434],
[55.95, 64.69, 79.99, 109.99, 110, 255, 264, 902, 670, 435],
[55.87, 64.71, 80, 110, 110, 254.99, 264, 897, 668, 437],
[56.28, 64.43, 80, 110, 110, 255, 263.99, 918, 678, 439],
[56.2, 64.45, 79.99, 110, 110, 255, 263.99, 905, 671, 438],
[58.83, 63.92, 79.99, 110, 110, 254.99, 264, 896, 663, 435],
[58.79, 64.68, 80, 109.99, 110, 254.99, 264.01, 888, 655, 434],
[56.65, 65.5, 79.99, 110, 110, 254.99, 263.99, 921, 681, 440],
[56.57, 65.62, 80, 110, 110, 254.99, 263.99, 922, 680, 439],
[55.99, 64.24, 79.99, 110, 110, 254.99, 264, 907, 672, 437],
[56.07, 65.09, 80, 110, 110, 254.99, 263.99, 913, 675, 439],
[55.66, 64.21, 79.99, 109.99, 110, 255, 263.99, 877, 653, 432],
[55.54, 64.47, 80, 110, 110, 255, 264, 882, 653, 433],
[54.77, 63.32, 80, 110, 110, 254.99, 263.99, 890, 657, 434],
[54.65, 62.79, 80, 109.99, 110, 254.99, 263.99, 890, 659, 434],
[53.2, 64.03, 80, 110, 110, 254.99, 264, 902, 669, 437],
[53.12, 64.38, 80, 110, 110, 254.99, 263.99, 906, 673, 438],
[53.04, 65.92, 80, 110, 110, 254.96, 263.99, 906, 674, 438],
[53.04, 65.8, 80, 110, 109.99, 255, 264, 905, 672, 438],
[53.71, 64.01, 80, 110, 109.99, 255, 263.99, 905, 674, 437],
[53.55, 65.24, 79.99, 110, 110, 255, 264, 905, 675, 438],
[54.3, 65.2, 79.99, 110, 110, 255, 263.99, 879, 654, 434],
[54.1, 64.9, 80, 110, 110, 254.85, 264, 881, 653, 432],
[53.98, 64.75, 79.99, 110, 110, 255, 264, 878, 654, 433],
[54.1, 64.17, 79.99, 110, 109.99, 254.99, 264, 908, 673, 439],
[54.06, 63.9, 79.99, 110, 110, 254.99, 264, 909, 675, 438],
[54.1, 64.39, 79.99, 110, 110, 254.99, 263.99, 905, 672, 437],
[54.38, 64.01, 80, 110, 109.99, 254.99, 264, 914, 675, 439],
[54.69, 64.65, 79.99, 110, 110, 254.99, 264, 924, 682, 440],
[54.94, 63.86, 79.99, 110, 110, 255, 264, 928, 687, 440],
[55.02, 64.52, 79.99, 110, 110, 254.98, 264, 891, 660, 434],
[54.94, 65.1, 79.99, 110, 110, 254.99, 263.98, 883, 653, 433],
[55.34, 66.11, 79.99, 109.99, 110, 255, 263.99, 902, 668, 437],
[55.18, 66.12, 79.99, 110, 110, 254.99, 264, 896, 666, 435],
[55.18, 65.87, 80, 110, 109.99, 255, 263.99, 881, 654, 435],
[55.38, 63.28, 80, 110, 110, 254.99, 263.93, 922, 679, 439],
[55.66, 62.8, 79.99, 109.99, 110, 255, 263.99, 920, 676, 439],
[55.66, 66.3, 79.99, 110, 109.99, 255, 264, 922, 684, 440],
[55.62, 65.85, 80, 110, 109.99, 255, 264, 918, 681, 440],
[55.58, 66.69, 79.99, 110, 110, 254.99, 264, 907, 672, 438],
[55.66, 65.26, 79.99, 109.99, 109.99, 254.99, 263.99, 912, 678, 439],
[55.7, 65.17, 80, 110, 110, 255, 263.99, 907, 675, 438],
[55.87, 65.47, 79.99, 110, 109.99, 254.99, 263.99, 897, 667, 436],
[55.3, 64.64, 80, 110, 110, 255, 264, 911, 676, 438],
[55.14, 64.44, 79.99, 110, 110, 254.99, 264, 899, 668, 436],
[55.06, 64.74, 80, 109.99, 110, 255, 264, 908, 671, 437],
[55.34, 63.98, 79.99, 109.99, 110, 255, 263.99, 908, 672, 437],
[55.34, 64.17, 80, 110, 110, 255, 263.99, 906, 671, 438],
[55.3, 63.61, 80, 109.99, 110, 254.99, 263.99, 906, 671, 438],
[55.34, 64.34, 80, 109.99, 110, 255, 264, 910, 674, 438],
[55.1, 65.06, 79.99, 109.99, 109.99, 255, 263.98, 915, 681, 439],
[55.34, 65.09, 79.99, 110, 110, 255, 264, 933, 690, 441],
[55.06, 64.4, 80, 110, 110, 254.98, 263.98, 898, 667, 435],
[54.77, 64.46, 79.99, 110, 109.99, 255, 263.99, 905, 671, 437],
[54.85, 63.78, 79.99, 109.99, 110, 255, 264, 895, 662, 435],
[54.81, 65.09, 80, 110, 110, 255, 263.99, 902, 667, 436],
[55.22, 64.36, 79.99, 110, 109.99, 254.99, 264, 907, 673, 438],
[58.48, 65.15, 80, 109.99, 110, 255, 264, 898, 664, 435],
[58.7, 65.32, 80, 110, 110, 254.99, 264, 915, 676, 438],
[58.31, 64.94, 79.99, 109.99, 110, 255, 263.99, 911, 671, 436],
[58.22, 65.4, 80, 109.99, 109.99, 255, 263.99, 889, 657, 435],
[58.22, 63.84, 80, 110, 109.99, 254.99, 264, 893, 660, 434],
[58.22, 64.21, 80, 109.99, 110, 255, 263.99, 904, 667, 436],
[56.65, 65.19, 79.99, 109.99, 110, 254.99, 263.91, 898, 664, 434],
[56.57, 66.25, 79.99, 109.99, 109.99, 254.99, 263.99, 907, 672, 437],
[53.86, 64.18, 79.99, 109.99, 110, 255, 263.99, 920, 679, 440],
[53.74, 64.22, 80, 110, 110, 255, 264, 920, 683, 440],
[53.47, 64.53, 79.99, 110, 110, 254.99, 263.99, 921, 681, 441],
[51.71, 64.96, 79.99, 110, 109.99, 254.99, 263.99, 921, 688, 440],
[51.52, 65.37, 79.99, 110, 110, 255, 263.99, 908, 678, 439],
[50.23, 64.67, 79.99, 110, 110, 255, 263.99, 917, 683, 438],
[50.85, 63.95, 80, 110, 110, 254.99, 263.99, 891, 664, 434],
[50.96, 64.39, 80, 110, 110, 254.99, 263.99, 896, 669, 435],
[51.19, 64.46, 79.99, 110, 109.99, 255, 263.99, 902, 670, 435],
[51.04, 64.15, 80, 110, 109.99, 254.99, 263.99, 901, 672, 436],
[52.01, 63.86, 79.99, 109.99, 110, 254.99, 264, 911, 678, 438],
[52.47, 63.72, 80, 110, 110, 255, 264, 919, 684, 439],
[52.31, 63.63, 79.99, 110, 109.99, 255, 264, 906, 673, 437],
[52.13, 62.59, 79.99, 110, 110, 255, 264, 906, 673, 437],
[52.28, 62.23, 80, 110, 110, 254.99, 264, 905, 670, 436],
[53.94, 64.08, 80, 110, 110, 255, 263.99, 909, 680, 438],
[54.06, 63.81, 79.99, 110, 109.99, 255, 263.99, 906, 676, 438],
[54.1, 64.55, 80, 110, 109.99, 255, 263.99, 891, 665, 436],
[55.95, 64.05, 80, 109.99, 110, 255, 263.99, 892, 663, 435],
[58.96, 66.49, 80, 110, 110, 254.99, 264, 895, 667, 434],
[58.87, 63.97, 79.99, 110, 110, 255, 264, 889, 663, 434],
[58.87, 63.54, 79.99, 110, 109.99, 254.99, 263.99, 898, 668, 434],
[59.05, 63.26, 80, 110, 110, 255, 263.99, 911, 678, 437],
[63.97, 61.63, 79.99, 110, 109.99, 252.53, 263.99, 889, 621, 432],
[63.77, 62.13, 80, 110, 109.99, 252.61, 264, 901, 629, 435],
[62.05, 62.41, 80, 110, 110, 255.06, 264, 885, 625, 432],
[62, 62.87, 79.99, 109.99, 109.99, 255.02, 264, 876, 620, 431],
[60.48, 63.45, 79.99, 110, 110, 255, 264, 900, 633, 436],
[60.48, 62.9, 80, 109.99, 110, 254.98, 264, 916, 641, 437],
[60.3, 63.29, 80, 110, 110, 255, 264, 908, 639, 438],
[60.07, 64.37, 79.99, 110, 110, 254.99, 264, 886, 629, 434],
[60.07, 64.01, 80, 110, 110, 254.99, 263.99, 890, 631, 434],
[60.03, 64.43, 80, 110, 110, 254.99, 264, 874, 622, 433],
[60.03, 64.18, 80, 110, 110, 254.98, 263.5, 889, 625, 433],
[59.98, 64.31, 80, 110, 109.99, 254.99, 263.99, 900, 634, 436],
[59.01, 64.27, 79.99, 110, 110, 255, 264, 913, 646, 438],
[58.92, 64.11, 80, 110, 109.99, 254.99, 263.99, 900, 637, 435],
[59.05, 64.21, 79.99, 110, 110, 255, 263.99, 900, 635, 436],
[58.74, 63.46, 79.99, 110, 110, 254.99, 264, 897, 636, 436],
[58.7, 64.21, 80, 110, 109.99, 254.99, 263.99, 894, 634, 436],
[58.53, 64.39, 79.99, 110, 110, 255, 263.99, 895, 633, 436],
[58.44, 64.49, 79.99, 110, 110, 255, 264, 912, 644, 438],
[58.53, 64.57, 80, 109.99, 110, 254.99, 264, 905, 642, 438],
[57.92, 64, 79.99, 110, 110, 255, 263.99, 904, 642, 438],
[58.01, 64.39, 79.99, 110, 110, 255, 264, 881, 624, 433],
[57.92, 64.21, 79.99, 110, 110, 254.99, 264, 876, 624, 433],
[57.92, 63.58, 80, 110, 109.99, 255, 264, 868, 619, 432],
[57.92, 64.18, 80, 110, 109.99, 254.98, 264, 883, 627, 434],
[58.31, 63.58, 80, 109.99, 110, 254.99, 263.99, 904, 642, 437],
[58.01, 63.63, 80, 110, 110, 255, 263.99, 894, 632, 436],
[58.18, 63.14, 80, 110, 110, 254.99, 264, 897, 639, 436],
[58.14, 64.01, 80, 110, 109.99, 255, 264, 898, 639, 438],
[58.35, 64.04, 80, 109.99, 110, 254.99, 264, 892, 634, 437],
[59.4, 66.3, 80.01, 110, 110, 254.99, 264, 894, 638, 437],
[59.27, 65.57, 79.99, 110, 110, 255, 264, 888, 635, 435],
[59.36, 64.51, 79.99, 110, 110, 255, 264, 892, 634, 436],
[59.4, 64.26, 79.99, 109.99, 109.99, 255, 263.99, 894, 635, 435],
[59.54, 65.26, 80, 109.99, 110, 255, 264, 869, 618, 431],
[59.58, 64.98, 79.99, 110, 110, 255, 264, 883, 626, 433],
[59.62, 64.66, 79.99, 110, 110, 254.98, 263.99, 883, 626, 434],
[60.16, 62.22, 79.99, 110, 110, 254.97, 263.99, 891, 631, 434],
[60.21, 62.2, 80, 109.99, 110, 254.99, 263.99, 877, 624, 433],
[60.75, 63.66, 79.99, 109.99, 110, 254.94, 263.99, 872, 620, 432],
[60.66, 63.65, 79.99, 110, 109.99, 254.99, 264, 874, 620, 433],
[60.7, 64.06, 80, 110, 109.99, 255.01, 263.99, 864, 613, 431],
[60.98, 63.82, 79.99, 109.99, 110, 254.95, 264, 858, 610, 429],
[60.98, 63.91, 79.99, 110, 110, 254.98, 263.99, 865, 610, 430],
[63.33, 64.65, 80, 110, 110, 252.19, 263.99, 904, 636, 436],
[63.19, 64.92, 80, 110, 110, 252.25, 263.99, 893, 628, 434],
[63.24, 64.67, 79.99, 109.99, 110, 252.41, 264, 892, 632, 435],
[62.9, 64.35, 79.99, 109.99, 109.99, 252.49, 263.99, 889, 620, 431],
[63.48, 63.86, 80, 109.99, 110, 252.1, 263.99, 879, 623, 432],
[59.94, 63.98, 79.99, 110, 110, 254.99, 263.99, 884, 623, 432],
[59.98, 63.26, 80, 110, 110, 254.98, 264, 889, 627, 434],
[60.12, 63.5, 79.99, 110, 110, 254.96, 264, 877, 620, 432],
[60.3, 63.25, 79.99, 110, 110, 255, 263.99, 892, 629, 434],
[60.03, 63.12, 79.99, 110, 109.99, 255, 264, 899, 637, 436],
[60.07, 63.59, 80, 109.99, 109.99, 255.01, 264, 904, 637, 437],
[59.98, 64.2, 79.99, 109.99, 110, 255, 264, 918, 647, 428],
[60.12, 63.59, 80, 110, 110, 254.97, 264, 904, 638, 437],
[60.12, 63.74, 80, 110, 110, 254.99, 263.99, 903, 638, 436],
[59.98, 63.98, 79.99, 110, 110, 254.99, 263.99, 895, 633, 436],
[59.89, 63.61, 80, 110, 110, 255.29, 263.99, 896, 633, 436],
[59.71, 63.77, 79.99, 110, 110, 255, 264, 871, 615, 433],
[59.62, 64.73, 80, 110, 110, 255, 264, 888, 625, 435],
[59.67, 64.03, 79.99, 110, 110, 254.96, 264, 878, 623, 432],
[59.54, 63.86, 80, 109.99, 110, 255, 264, 875, 622, 432],
[59.76, 64.12, 80, 109.99, 110, 255, 263.99, 876, 619, 432],
[59.45, 63.71, 80, 109.99, 109.99, 254.99, 263.99, 879, 622, 431],
[59.23, 64.06, 80, 110, 109.99, 255, 264, 884, 626, 434],
[59.27, 64.36, 80, 110, 110, 254.98, 263.99, 874, 624, 432],
[59.36, 63.74, 80, 110, 110, 254.99, 264, 880, 622, 433],
[59.09, 64.06, 80, 109.99, 110, 255, 264, 903, 641, 438],
[59.05, 64.85, 79.99, 110, 110, 255, 264, 897, 636, 437],
[59.18, 65.45, 80, 109.99, 110, 255, 263.94, 903, 637, 437],
[58.92, 65.67, 79.99, 109.99, 109.99, 255, 264, 905, 641, 437],
[58.57, 64.23, 80, 110, 109.99, 254.99, 264, 881, 628, 433],
[58.7, 64.13, 79.99, 110, 110, 255, 264, 898, 635, 436],
[58.57, 63.21, 80, 110, 110, 254.96, 263.99, 898, 637, 436],
[58.53, 62.72, 79.99, 110, 110, 255, 264, 897, 633, 436],
[58.4, 64.14, 80, 110, 109.99, 254.99, 264, 889, 631, 434],
[58.4, 64.49, 80, 110, 110, 254.99, 263.99, 907, 639, 438],
[58.48, 64.13, 79.99, 109.99, 110, 254.98, 263.99, 905, 641, 438],
[58.35, 63.74, 80, 110, 110, 255, 263.99, 898, 637, 436],
[58.53, 62.98, 79.99, 110, 110, 255, 264, 907, 641, 438],
[58.18, 63.81, 80, 110, 110, 254.98, 263.99, 903, 641, 437],
[58.09, 63.87, 80, 110, 110, 254.99, 263.99, 898, 634, 436],
[57.54, 63.44, 80, 109.99, 110, 254.99, 264, 901, 637, 437],
[57.24, 62.41, 80, 109.99, 110, 254.99, 264, 902, 641, 438],
[57.54, 62.12, 79.99, 110, 110, 255, 263.99, 898, 639, 438],
[57.45, 64.24, 80, 110, 110, 255.33, 263.99, 884, 627, 434],
[57.54, 63.59, 79.99, 110, 110, 255, 263.99, 876, 623, 433],
[57.67, 63.53, 80, 110, 110, 255, 264, 876, 622, 433],
[57.58, 62.87, 80, 109.99, 110, 255, 263.99, 882, 622, 434],
[57.75, 63.25, 79.99, 110, 110, 255, 264, 905, 642, 439],
[58.35, 64.16, 80, 110, 110, 255, 263.99, 894, 635, 437],
[58.48, 63.92, 79.99, 110, 110, 254.98, 263.99, 897, 637, 437],
[58.53, 63.97, 80, 110, 110, 255, 263.99, 904, 641, 438],
[59.14, 64.72, 79.99, 110, 109.99, 254.99, 264, 901, 637, 438],
[59.23, 64.18, 79.99, 110, 110, 255.01, 264, 907, 643, 438],
[59.23, 63.88, 79.99, 109.99, 109.99, 254.99, 263.99, 903, 640, 438],
[59.31, 64.36, 80, 109.99, 110, 255, 263.99, 900, 634, 437],
[59.36, 64.34, 79.99, 109.99, 110, 255, 264, 901, 639, 437],
[59.4, 63.86, 79.99, 110, 109.99, 255, 264, 896, 634, 436],
[59.76, 63.45, 80, 110, 110, 255, 263.99, 897, 635, 436],
[59.8, 63.3, 79.99, 109.99, 110, 255, 263.99, 877, 622, 433],
[60.48, 64.45, 79.99, 109.99, 110, 255, 263.99, 892, 634, 436],
[60.48, 63.55, 80, 110, 110, 255.26, 264, 882, 623, 433],
[60.43, 64.23, 79.99, 109.99, 109.99, 255, 264, 892, 632, 435],
[60.98, 63.86, 79.99, 110, 110, 255.4, 263.93, 901, 638, 435],
[60.7, 64.18, 79.99, 110, 110, 255, 263.99, 889, 629, 436],
[60.66, 64.2, 79.99, 110, 109.99, 255, 264, 892, 633, 435],
[60.7, 64.69, 80, 109.99, 110, 254.99, 263.99, 888, 627, 433],
[60.75, 65, 80, 109.99, 110, 254.99, 264, 876, 622, 433],
[60.89, 64.74, 79.99, 110, 110, 255, 264, 877, 617, 432],
[60.89, 64.74, 80, 110, 109.99, 254.99, 263.99, 889, 625, 434],
[60.3, 62.73, 79.99, 109.99, 109.99, 255, 263.99, 896, 634, 436],
[60.21, 62.3, 80, 109.99, 109.99, 254.99, 264, 911, 641, 438],
[60.12, 62.14, 80, 110, 109.99, 255, 263.99, 914, 642, 437],
[59.27, 64.35, 80, 109.99, 110, 255, 263.99, 868, 614, 430],
[59.01, 63.31, 80, 110, 109.99, 254.99, 264, 876, 625, 434],
[58.79, 63.33, 79.99, 110, 110, 255, 263.99, 889, 626, 435],
[58.27, 61.85, 80, 110, 109.99, 254.99, 263.43, 885, 621, 433],
[58.27, 62.07, 79.99, 109.99, 110, 255, 263.99, 892, 631, 436],
[52.24, 63.44, 79.99, 110, 109.99, 254.99, 263.99, 876, 627, 435],
[52.77, 63.01, 80, 110, 110, 254.98, 264, 896, 640, 437],
[52.66, 63.3, 79.99, 109.99, 110, 255, 264, 895, 638, 438],
[52.97, 61.83, 79.99, 110, 110, 254.99, 264, 913, 649, 440],
[53.08, 62.32, 80, 110, 110, 254.99, 263.99, 906, 643, 439],
[53.16, 62.14, 79.99, 110, 109.99, 254.99, 264, 896, 639, 438],
[53.16, 62.51, 79.99, 110, 110, 255.23, 263.91, 898, 639, 436],
[53.86, 62.43, 80, 110, 109.99, 254.99, 264, 894, 637, 437],
[53.78, 61.98, 79.99, 109.99, 109.99, 254.99, 263.99, 875, 621, 434],
[53.43, 62.73, 80, 110, 110, 255, 263.99, 889, 631, 436],
[53.86, 62.29, 80, 110, 110, 255, 263.99, 898, 636, 439],
[53.78, 62.99, 80, 109.99, 109.99, 254.99, 264, 887, 631, 437],
[54.06, 62.45, 79.99, 110, 110, 254.99, 264, 870, 618, 433],
[53.98, 62.47, 80, 110, 110, 254.99, 263.99, 879, 623, 434],
[54.26, 63.39, 79.99, 110, 110, 255, 264, 879, 624, 435],
[54.34, 63.29, 80, 110, 110, 254.99, 263.99, 897, 636, 437],
[54.61, 63.4, 80, 110, 110, 255, 263.99, 871, 622, 435],
[54.46, 63.58, 79.99, 109.99, 110, 255, 264, 870, 620, 433],
[54.46, 62.97, 80, 110, 109.99, 254.99, 264, 878, 623, 434],
[54.5, 64.05, 79.99, 110, 110, 255, 264, 870, 618, 433],
[54.53, 63.43, 79.99, 109.99, 110, 254.99, 264, 877, 625, 435],
[54.85, 63.32, 79.99, 110, 109.99, 255, 264, 872, 624, 434],
[58.31, 63.09, 79.99, 110, 110, 254.99, 264, 899, 640, 437],
[58.31, 63, 79.99, 110, 109.99, 254.99, 264, 894, 636, 437],
[58.31, 62.54, 79.99, 109.99, 110, 255, 264, 881, 624, 434],
[58.44, 63.4, 80, 110, 110, 255, 263.99, 867, 612, 432],
[60.34, 63.78, 80, 110, 110, 255, 264, 883, 624, 433],
[60.25, 63.98, 79.99, 110, 109.99, 254.99, 263.99, 893, 630, 435],
[60.48, 62.37, 79.99, 110, 110, 254.99, 264, 883, 625, 433],
[60.21, 62.76, 80, 110, 110, 254.99, 263.98, 886, 625, 434],
[57.71, 62.49, 80, 110, 109.99, 254.99, 263.99, 897, 633, 437],
[57.67, 63.55, 80, 109.99, 110, 255, 264, 898, 634, 437],
[56.82, 64.66, 79.99, 110, 110, 254.99, 264, 900, 634, 437],
[56.65, 63.97, 80, 109.99, 110, 254.99, 264, 890, 629, 435],
[56.65, 64.33, 79.99, 109.99, 110, 254.99, 263.98, 881, 624, 435],
[56.49, 64.48, 79.99, 110, 110, 254.99, 264, 889, 629, 435],
[54.85, 64, 79.99, 110, 110, 254.99, 264, 899, 637, 438],
[54.9, 63.77, 80, 110, 110, 254.99, 264, 889, 634, 435],
[54.98, 63.52, 79.99, 110, 110, 255, 263.99, 879, 626, 435],
[54.77, 62.52, 80, 110, 110, 254.99, 264, 877, 623, 434],
[54.73, 61.28, 80, 110, 110, 254.99, 264, 860, 612, 430],
[54.69, 61.51, 79.99, 110, 110, 254.99, 263.99, 857, 612, 431],
[54.77, 62.3, 80, 109.99, 109.99, 255, 264, 864, 614, 432],
[54.81, 61.81, 79.99, 110, 110, 255, 264, 865, 614, 431],
[54.77, 61.8, 79.99, 110, 110, 254.99, 263.99, 865, 618, 432],
[54.85, 62.26, 79.99, 109.99, 110, 254.99, 264, 880, 624, 434],
[54.81, 62.18, 80, 109.99, 109.99, 255, 264, 868, 619, 433],
[57.79, 62.88, 80, 110, 110, 254.99, 264, 878, 624, 433],
[57.96, 62.45, 79.99, 110, 110, 254.99, 264, 872, 618, 432],
[58.14, 62.3, 80, 110, 110, 255, 264, 873, 622, 432],
[58.05, 61.63, 80, 110, 109.99, 254.99, 263.99, 869, 615, 432],
[58.22, 63.2, 79.99, 110, 110, 255, 264, 861, 616, 431],
[58.35, 63.5, 80, 110, 110, 254.98, 263.99, 861, 611, 430],
[58.05, 63.46, 79.99, 110, 110, 255, 263.99, 854, 611, 429],
[59.76, 63.74, 80, 110, 110, 254.99, 264, 893, 633, 435],
[59.76, 63.42, 79.99, 110, 109.99, 254.97, 263.99, 895, 634, 436],
[59.76, 62.96, 79.99, 110, 110, 254.99, 264, 906, 638, 437],
[58.7, 65.18, 79.99, 110, 110, 254.99, 263.99, 894, 634, 435],
[58.66, 65.41, 80, 109.99, 109.99, 254.99, 264, 880, 621, 433],
[58.35, 62.88, 79.99, 110, 110, 255, 264, 894, 630, 436],
[58.27, 64.66, 79.99, 109.99, 109.99, 255, 263.99, 904, 639, 437],
[58.09, 64.94, 80, 109.99, 109.99, 255, 263.99, 901, 640, 438],
[57.03, 63.21, 79.99, 109.99, 109.99, 254.99, 264, 870, 615, 432],
[56.9, 62.9, 79.99, 109.99, 110, 254.99, 263.99, 878, 623, 433],
[56.61, 62.87, 80, 110, 110, 255, 263.99, 880, 624, 433],
[55.75, 62.73, 79.99, 110, 110, 255, 264, 897, 638, 437],
[55.66, 62.55, 80, 109.99, 110, 254.99, 263.99, 891, 635, 437],
[55.83, 62.75, 80, 109.99, 109.99, 254.99, 263.99, 890, 632, 435],
[55.66, 63.46, 79.99, 110, 110, 255, 263.99, 898, 641, 437],
[55.75, 63.31, 80, 109.99, 110, 254.99, 263.99, 895, 641, 437],
[55.66, 63.3, 80, 109.99, 110, 254.99, 264, 895, 634, 437],
[55.34, 62.22, 79.99, 110, 110, 255, 264, 898, 638, 438],
[55.26, 62.31, 80, 110, 110, 255, 263.99, 897, 637, 438],
[55.06, 63.99, 79.99, 109.99, 110, 254.99, 264, 882, 624, 434],
[54.85, 63.44, 79.99, 110, 110, 255, 264, 868, 617, 433],
[54.85, 63.07, 79.99, 110, 110, 254.99, 263.99, 893, 640, 438],
[54.85, 63.77, 79.99, 109.99, 109.99, 255, 264, 885, 632, 435],
[54.57, 62.55, 79.99, 110, 110, 255, 264, 893, 636, 437],
[55.34, 62.77, 80, 109.99, 109.99, 255.02, 263.78, 884, 628, 434],
[55.34, 63.23, 79.99, 109.99, 110, 254.99, 263.98, 894, 640, 438],
[55.54, 62.38, 79.99, 109.99, 110, 255, 264, 886, 633, 436],
[55.87, 61.95, 80, 110, 110, 255, 263.99, 885, 631, 435],
[55.66, 62.31, 79.99, 109.99, 110, 255, 264, 879, 629, 435],
[55.66, 62.56, 80, 110, 109.99, 254.99, 264, 869, 620, 433],
[55.66, 63.4, 79.99, 110, 110, 255, 263.99, 874, 624, 435],
[55.95, 63.16, 80, 109.99, 110, 255, 263.99, 856, 613, 431],
[55.91, 62.79, 80, 110, 110, 254.99, 264, 858, 613, 430],
[56.16, 62.96, 79.99, 110, 110, 254.97, 263.99, 868, 616, 432],
[56.24, 62.66, 80, 110, 109.99, 254.99, 263.99, 869, 619, 432],
[56.45, 62.6, 80, 109.99, 110, 254.99, 264.01, 869, 621, 432],
[56.65, 62.62, 79.99, 109.99, 110, 254.99, 263.98, 870, 620, 432],
[56.45, 62.08, 80, 109.99, 110, 255, 264, 874, 622, 434],
[57.33, 63.12, 79.99, 110, 110, 255.02, 263.98, 883, 629, 432],
[57.24, 62.89, 80, 109.99, 110, 254.99, 263.99, 876, 620, 433],
[57.28, 63.31, 79.99, 109.99, 110, 255, 264, 876, 624, 433],
[57.41, 63.29, 79.99, 110, 109.99, 255, 264, 864, 614, 432],
[57.41, 63.48, 79.99, 110, 109.99, 255, 264, 866, 613, 432],
[57.67, 62.83, 80, 109.99, 110, 255, 264, 884, 629, 435],
[57.62, 62.74, 79.99, 110, 110, 254.99, 263.99, 871, 622, 432],
[58.79, 63.83, 80, 110, 110, 255, 264, 886, 633, 435],
[59.05, 63.93, 79.99, 110, 109.99, 254.99, 263.99, 891, 634, 436],
[59.14, 62.68, 80, 110, 110, 254.99, 264, 870, 615, 431],
[59.23, 62.93, 80, 110, 110, 254.99, 264, 865, 619, 432],
[59.09, 63.2, 79.99, 109.99, 109.99, 255, 264, 869, 621, 432],
[59.09, 63.86, 80, 110, 109.99, 254.99, 263.99, 857, 609, 430],
[59.27, 63.98, 79.99, 109.99, 109.99, 255, 264, 865, 617, 431],
[60.57, 63.26, 80, 110, 110, 255.01, 263.98, 896, 616, 430],
[60.66, 62.95, 79.99, 110, 109.99, 255, 263.99, 892, 628, 434],
[60.48, 62.75, 79.99, 110, 109.99, 255, 264, 891, 630, 434],
[60.93, 62.67, 79.99, 109.99, 109.99, 255, 263.99, 869, 617, 433],
[59.89, 64.01, 80, 110, 109.99, 254.99, 264, 870, 617, 432],
[60.03, 63.76, 80, 110, 109.99, 255, 264, 871, 616, 431],
[59.8, 63.44, 79.99, 110, 110, 254.99, 264, 870, 616, 431],
[59.71, 63.8, 79.99, 110, 109.99, 254.99, 263.99, 876, 624, 433],
[59.62, 65.19, 79.99, 110, 110, 255, 263.99, 898, 633, 436],
[59.54, 64.87, 80, 110, 109.99, 254.99, 264, 885, 627, 434],
[59.49, 64.49, 80, 110, 110, 255, 264, 881, 623, 434],
[58.61, 64.29, 79.99, 109.99, 110, 255, 263.99, 897, 639, 436],
[58.53, 65, 79.99, 109.99, 110, 254.99, 263.99, 897, 634, 436],
[58.48, 65.18, 79.99, 110, 110, 254.99, 264, 901, 641, 437],
[58.57, 64.87, 79.99, 109.99, 110, 255, 264, 903, 639, 437],
[58.4, 64.46, 79.99, 110, 110, 255, 264, 905, 640, 438],
[58.18, 63.8, 79.99, 110, 110, 255, 263.98, 897, 638, 436],
[58.01, 62.16, 80, 110, 110, 254.99, 263.99, 882, 625, 434],
[58.31, 62.18, 79.99, 110, 109.99, 254.99, 263.99, 875, 621, 433],
[57.79, 63.99, 80, 110, 109.99, 255, 263.99, 895, 638, 437],
[57.62, 63.65, 79.99, 110, 110, 254.99, 264, 893, 637, 436],
[57.54, 64.66, 80, 109.99, 109.99, 255, 264, 867, 616, 431],
[57.75, 64.21, 79.99, 110, 110, 255, 264, 868, 614, 431],
[57.84, 63.67, 79.99, 110, 110, 255, 264, 874, 622, 433],
[57.5, 63.78, 80, 110, 110, 254.99, 264, 882, 627, 435],
[57.54, 63.7, 79.99, 110, 110, 255, 263.99, 863, 614, 432],
[57.67, 62.45, 80, 110, 110, 255, 263.99, 887, 627, 435],
[57.5, 62.44, 79.99, 110, 110, 255, 264, 880, 624, 434],
[57.33, 61.96, 80, 110, 110, 254.99, 263.99, 888, 631, 435],
[57.45, 63.12, 80, 110, 109.99, 255, 264, 883, 624, 434],
[57.67, 63.81, 79.99, 110, 110, 255, 263.99, 902, 634, 437],
[57.45, 64.16, 80, 109.99, 110, 254.99, 263.99, 893, 632, 435],
[57.33, 63.09, 80, 110, 110, 255.24, 264, 911, 642, 438],
[57.5, 63.36, 80, 110, 110, 254.99, 264, 904, 642, 438],
[57.45, 63.64, 80, 110, 109.99, 254.99, 264, 898, 636, 437],
[57.5, 64.14, 79.99, 110, 109.99, 254.99, 263.99, 891, 634, 436],
[57.37, 63.41, 79.99, 110, 110, 255, 264, 902, 638, 438],
[57.28, 63.47, 80, 110, 109.99, 254.99, 264, 900, 637, 436],
[57.33, 62.89, 79.99, 110, 110, 255, 263.99, 903, 638, 437],
[57.33, 63.23, 79.99, 109.99, 109.99, 254.99, 264, 904, 636, 438],
[57.33, 62.91, 80, 110, 109.99, 254.99, 263.99, 900, 635, 436],
[57.33, 63.75, 80, 110, 110, 255, 264, 898, 637, 437],
[57.37, 63.3, 80, 109.99, 109.99, 255, 263.99, 907, 637, 438],
[57.37, 63.88, 80, 110, 110, 255, 264, 904, 640, 437],
[57.33, 62.94, 80, 110, 110, 254.99, 264, 892, 634, 436],
[57.45, 62.97, 79.99, 110, 110, 254.99, 263.98, 905, 638, 436],
[57.41, 63.14, 79.99, 110, 110, 254.99, 264, 903, 638, 437],
[57.24, 63.85, 79.99, 110, 110, 254.99, 264, 903, 641, 438],
[57.2, 63.53, 79.99, 110, 110, 255, 264, 898, 640, 437],
[57.11, 64.75, 80, 109.99, 110, 255, 264.02, 923, 645, 438],
[57.45, 64.35, 79.99, 110, 109.99, 254.99, 263.99, 903, 641, 438],
[57.16, 63.51, 80, 110, 110, 254.99, 264, 898, 639, 437],
[57.16, 64, 79.99, 110, 110, 254.99, 264, 900, 636, 437],
[57.41, 64.22, 80, 110, 110, 255, 264.01, 897, 640, 438],
[57.2, 64.26, 79.99, 110, 110, 255, 264, 879, 622, 433],
[57.37, 63.56, 79.99, 109.99, 110, 254.99, 263.99, 876, 622, 432],
[57.67, 63.85, 80, 109.99, 110, 254.98, 264, 887, 629, 434],
[57.33, 63.1, 80, 110, 109.99, 254.99, 263.99, 898, 638, 437],
[57.33, 63.07, 80, 110, 109.99, 255, 264, 890, 632, 435],
[57.37, 63.17, 80, 110, 109.99, 255, 263.99, 896, 635, 436],
[57.67, 62.63, 80, 110, 110, 255.05, 263.92, 902, 641, 437],
[57.79, 62.34, 80, 109.99, 110, 255, 263.99, 878, 622, 436],
[57.33, 63.27, 80, 110, 110, 254.99, 263.99, 879, 623, 433],
[57.62, 63.31, 79.99, 109.99, 110, 255, 263.99, 855, 610, 430],
[57.37, 63.1, 80, 110, 110, 254.99, 264, 845, 602, 427],
[57.88, 62.95, 79.99, 110, 109.99, 255, 263.98, 841, 600, 426],
[58.31, 63.67, 80, 109.99, 110, 255, 263.99, 866, 615, 431],
[58.14, 63.04, 79.99, 110, 109.99, 255, 264, 862, 614, 431],
[58.35, 63.16, 79.99, 110, 109.99, 255, 264, 870, 621, 433],
[58.22, 63.56, 79.99, 110, 109.99, 254.97, 264, 852, 606, 428],
[58.35, 63.09, 80, 110, 110, 255, 264, 857, 612, 431],
[58.7, 61.76, 80, 110, 110, 254.99, 263.99, 856, 612, 428],
[58.66, 61.81, 80, 110, 110, 254.99, 263.99, 855, 608, 429],
[58.92, 62.36, 79.99, 109.99, 110, 255.14, 263.95, 849, 606, 427],
[58.92, 63.34, 80, 110, 110, 255, 264, 842, 599, 427],
[59.62, 62.21, 80, 109.99, 110, 255, 264.01, 882, 626, 433],
[59.67, 62.02, 79.99, 110, 110, 254.98, 263.99, 891, 634, 435],
[60.12, 62.44, 79.99, 110, 110, 255.1, 263.83, 851, 604, 427],
[60.12, 62.78, 79.99, 110, 110, 255, 264, 858, 607, 428],
[60.34, 63.29, 80, 109.99, 110, 254.99, 263.99, 877, 621, 433],
[60.21, 62.96, 80, 110, 109.99, 254.98, 263.98, 864, 615, 429],
[60.48, 63.11, 79.99, 110, 110, 254.99, 264, 857, 611, 429],
[60.48, 64.37, 80, 110, 109.99, 255, 281.47, 871, 617, 431],
[60.48, 64.34, 79.99, 110, 110, 254.99, 263.99, 859, 608, 430],
[60.25, 62.43, 80, 110, 110, 254.99, 263.99, 880, 624, 433],
[60.25, 62.82, 80, 110, 110, 255.36, 263.18, 898, 637, 434],
[60.25, 63.12, 80, 110, 110, 254.99, 264, 888, 628, 435],
[60.12, 63.67, 80, 110, 110, 254.99, 264, 886, 632, 434],
[59.8, 64.46, 80, 109.99, 110, 254.99, 264, 883, 625, 434],
[60.16, 64.48, 80, 110, 110, 255, 264, 881, 622, 433],
[59.67, 64.61, 80, 109.99, 110, 254.99, 264, 863, 612, 431],
[60.07, 64.07, 80, 110, 110, 254.99, 264, 856, 604, 429],
[58.27, 64.24, 79.99, 110, 109.99, 254.99, 263.99, 898, 639, 437],
[57.92, 65.02, 79.99, 109.99, 110, 255.01, 263.93, 900, 634, 435],
[58.01, 64.73, 79.99, 110, 110, 255, 263.99, 891, 631, 436],
[58.14, 64.36, 79.99, 110, 110, 254.99, 263.99, 897, 636, 437],
[57.84, 63.52, 79.99, 109.99, 109.99, 255, 264, 892, 633, 436],
[57.84, 64.37, 79.99, 110, 110, 254.99, 264, 886, 629, 434],
[57.79, 64.47, 80, 110, 109.99, 255, 264, 890, 634, 436],
[57.84, 64.86, 79.99, 110, 110, 254.96, 264, 896, 636, 437],
[57.58, 64.27, 79.99, 110, 110, 254.99, 264, 899, 638, 437],
[57.54, 64.2, 79.99, 110, 110, 255.05, 263.92, 905, 641, 436],
[57.71, 63.79, 79.99, 109.99, 110, 255, 263.99, 892, 635, 436],
[57.5, 63.61, 80, 110, 110, 255, 264, 897, 637, 436],
[57.41, 63.94, 79.99, 110, 110, 255, 264, 886, 631, 434],
[57.41, 63.26, 79.99, 110, 109.99, 255, 264, 891, 632, 436],
[57.45, 63.3, 80, 110, 109.99, 254.99, 263.98, 888, 628, 434],
[57.58, 63.24, 80, 110, 109.99, 254.99, 264, 885, 627, 434],
[57.5, 63.14, 79.99, 110, 110, 255, 263.99, 883, 628, 434],
[57.5, 63.67, 80, 110, 110, 255, 264, 876, 623, 433],
[57.71, 64.52, 79.99, 109.99, 110, 255, 263.99, 881, 625, 433],
[57.5, 64.78, 80, 110, 110, 255, 263.99, 873, 622, 433],
[57.5, 64.21, 80, 110, 109.99, 255, 264, 862, 611, 430],
[57.41, 63.98, 79.99, 110, 110, 255, 264, 867, 614, 431],
[57.33, 63.67, 79.99, 109.99, 110, 255, 263.99, 893, 631, 436],
[57.79, 63.62, 79.99, 110, 110, 255, 264, 897, 636, 436],
[57.28, 63.76, 80, 109.99, 110, 255, 263.99, 877, 625, 433],
[57.28, 63.88, 80, 110, 110, 255, 264, 879, 623, 433],
[57.33, 64.18, 80, 110, 109.99, 254.99, 264, 876, 624, 433],
[57.28, 64.43, 79.99, 110, 109.99, 254.96, 264, 894, 634, 436],
[57.45, 64.4, 79.99, 110, 110, 255, 264, 895, 637, 436],
[57.67, 63.75, 79.99, 110, 110, 254.99, 264, 873, 620, 432],
[57.28, 63.18, 80, 110, 109.99, 254.99, 263.99, 875, 621, 432],
[57.45, 62.84, 80, 110, 110, 254.99, 263.99, 862, 614, 429],
[57.37, 63.43, 79.99, 110, 109.99, 254.99, 264, 863, 613, 429],
[57.28, 63.77, 79.99, 110, 109.99, 255, 263.99, 873, 621, 431],
[57.24, 63.67, 80, 109.99, 110, 255, 263.99, 868, 620, 432],
[57.28, 62.34, 79.99, 110, 109.99, 255, 264, 866, 613, 431],
[57.24, 63.83, 79.99, 110, 110, 255, 263.99, 890, 630, 436],
[57.07, 64.52, 80, 109.99, 110, 255, 263.99, 890, 631, 436],
[56.28, 61.92, 80, 110, 110, 254.99, 264, 882, 626, 433],
[56.2, 63.96, 80, 109.99, 110, 255, 263.99, 878, 625, 433],
[56.24, 63.74, 79.99, 109.99, 110, 255, 264, 870, 621, 433],
[56.4, 63.47, 79.99, 109.99, 110, 255, 264, 876, 620, 433],
[56.36, 63.51, 79.99, 110, 110, 254.99, 264, 897, 635, 437],
[56.45, 63.2, 80, 109.99, 110, 255, 264, 892, 634, 436],
[56.7, 63.64, 79.99, 109.99, 110, 255, 264, 882, 626, 435],
[57.5, 63.29, 79.99, 110, 110, 255, 264, 878, 628, 434],
[57.5, 63.37, 79.99, 110, 110, 255, 264, 870, 621, 431],
[57.67, 63.98, 79.99, 110, 109.99, 255, 264, 860, 611, 430],
[57.58, 63.78, 79.99, 110, 110, 255, 264, 869, 620, 432],
[57.79, 63.26, 80, 110, 110, 254.99, 264, 900, 641, 438],
[58.96, 63.3, 79.99, 110, 110, 255, 263.99, 881, 625, 433],
[58.92, 63.19, 80, 110, 109.99, 254.99, 263.99, 863, 610, 430],
[58.96, 62.66, 80, 110, 110, 255, 263.99, 851, 604, 429],
[59.23, 62.55, 79.99, 110, 109.99, 255, 264, 875, 619, 432],
[59.18, 62.6, 79.99, 110, 109.99, 254.99, 264, 880, 624, 433],
[59.31, 63.51, 80, 109.99, 110, 255, 263.99, 873, 621, 433],
[59.23, 62.72, 80, 110, 110, 254.99, 264, 873, 621, 432],
[59.49, 63.59, 79.99, 110, 110, 254.99, 264, 876, 621, 433],
[60.43, 63.86, 79.99, 110, 109.99, 255, 263.99, 937, 640, 436],
[60.16, 63.33, 80, 110, 110, 255, 264, 894, 635, 436],
[56.57, 63.02, 79.99, 110, 110, 254.99, 263.99, 877, 623, 433],
[56.49, 62.14, 79.99, 110, 110, 254.99, 264, 876, 626, 434],
[56.49, 61.67, 79.99, 110, 110, 255, 263.99, 882, 630, 434],
[56.28, 61.75, 80, 110, 110, 254.99, 264, 876, 625, 434],
[55.22, 63.88, 80, 109.99, 110, 254.99, 264, 893, 640, 437],
[55.1, 63.59, 79.99, 110, 109.99, 255, 264, 889, 633, 436],
[54.73, 65.4, 79.99, 109.99, 110, 255, 264, 889, 634, 436],
[54.65, 64.17, 80, 109.99, 109.99, 254.99, 264, 893, 640, 438],
[54.73, 63.54, 79.99, 109.99, 110, 255, 264, 894, 642, 438],
[54.69, 63.9, 79.99, 109.99, 110, 255, 263.99, 892, 640, 437],
[54.69, 63.55, 80, 109.99, 110, 254.99, 263.99, 890, 638, 437],
[54.94, 63.78, 79.99, 109.99, 110, 255, 260.91, 916, 640, 438],
[54.53, 64.38, 80, 110, 110, 255, 263.99, 891, 640, 437],
[54.5, 63.95, 80, 110, 110, 254.99, 263.99, 889, 634, 437],
[54.42, 63.68, 80, 110, 110, 255, 264, 883, 633, 436],
[54.42, 63.9, 79.99, 109.99, 109.99, 255, 264, 879, 630, 435],
[54.3, 63.29, 79.99, 110, 110, 255, 264, 893, 640, 437],
[54.22, 63.67, 80, 110, 110, 254.99, 264, 884, 633, 436],
[54.22, 63.3, 80, 110, 110, 254.99, 263.93, 875, 628, 433],
[54.26, 62.93, 79.99, 110, 109.99, 254.99, 263.99, 874, 627, 435],
[54.26, 62.85, 79.99, 109.99, 110, 255, 263.99, 878, 626, 434],
[54.22, 62.79, 79.99, 110, 110, 255, 264, 879, 630, 435],
[54.3, 62.81, 79.99, 110, 110, 254.99, 264, 876, 629, 435],
[54.06, 64.34, 80, 110, 110, 254.99, 263.99, 892, 638, 437],
[54.3, 62.33, 79.99, 110, 109.99, 254.97, 287.91, 887, 635, 436],
[54.18, 63, 79.99, 109.99, 110, 254.99, 263.99, 891, 640, 437],
[54.34, 62.23, 80, 110, 110, 255, 264, 878, 628, 434],
[54.22, 62.06, 80, 110, 110, 254.99, 264, 872, 623, 435],
[54.22, 61.91, 79.99, 109.99, 109.99, 255, 264, 883, 632, 435],
[54.06, 62.2, 80, 110, 110, 254.99, 263.99, 887, 636, 437],
[53.86, 62.14, 79.99, 109.99, 110, 255, 264, 879, 630, 435],
[53.9, 62.73, 79.99, 109.99, 110, 255, 263.99, 870, 625, 434],
[53.78, 62.08, 80, 110, 110, 254.99, 263.99, 882, 629, 436],
[53.51, 63.14, 80, 110, 109.99, 254.99, 264, 877, 627, 434],
[53.78, 62.66, 80, 110, 110, 255.01, 261.72, 883, 626, 433],
[53.59, 62.55, 79.99, 110, 109.99, 255, 264, 883, 632, 436],
[53.63, 62.6, 79.99, 110, 110, 255, 263.99, 882, 633, 436],
[53.71, 62.55, 79.99, 110, 110, 255, 263.99, 891, 638, 437],
[53.71, 62.63, 79.99, 110, 109.99, 255, 264.01, 891, 634, 437],
[53.98, 64.24, 80, 109.99, 110, 254.99, 264, 884, 632, 436],
[53.9, 65.17, 80, 110, 110, 254.99, 264, 889, 640, 438],
[53.82, 62.16, 80, 110, 110, 254.99, 263.99, 888, 641, 437],
[53.82, 62.67, 80, 110, 110, 255.26, 263.99, 897, 640, 437],
[53.94, 62.89, 80, 110, 109.99, 255, 264, 893, 642, 438],
[53.94, 63.29, 79.99, 110, 109.99, 254.99, 263.99, 891, 639, 438],
[54.06, 63.66, 80, 110, 110, 254.99, 263.99, 893, 640, 438],
[54.1, 63.55, 80, 109.99, 110, 254.99, 264, 890, 640, 438],
[54.14, 63.33, 80, 110, 110, 254.99, 263.99, 889, 641, 437],
[54.02, 63.32, 80, 110, 110, 254.99, 264, 891, 639, 437],
[54.38, 63.19, 79.99, 110, 110, 255, 264, 882, 629, 437],
[55.66, 63.54, 79.99, 109.99, 109.99, 254.99, 263.99, 854, 610, 431],
[55.7, 63.4, 79.99, 110, 109.99, 254.99, 263.99, 871, 623, 433],
[55.66, 63.48, 80, 109.99, 110, 255, 263.99, 873, 628, 434],
[55.7, 63.71, 79.99, 110, 109.99, 254.99, 263.99, 880, 627, 435],
[56.03, 62.63, 79.99, 110, 109.99, 255, 263.99, 889, 639, 436],
[56.32, 63.33, 79.99, 110, 110, 255, 263.99, 876, 629, 435],
[56.12, 64.14, 80, 110, 110, 255, 263.99, 886, 623, 432],
[56.28, 64.73, 79.99, 110, 109.99, 255, 264, 884, 635, 436],
[56.7, 62.64, 79.99, 110, 109.99, 254.96, 264, 889, 636, 436],
[56.53, 63.21, 80, 110, 109.99, 254.99, 263.99, 889, 636, 436],
[56.7, 62.95, 79.99, 109.99, 110, 255, 264, 873, 623, 433],
[56.65, 63.48, 80, 110, 110, 255, 263.99, 876, 631, 433],
[56.78, 62.61, 80, 110, 109.99, 255, 263.99, 874, 625, 434],
[56.9, 62.27, 80, 110, 110, 255, 264, 867, 620, 433],
[56.86, 64, 79.99, 110, 110, 255, 263.99, 854, 612, 430],
[56.9, 63.62, 80, 110, 110, 254.99, 263.99, 863, 619, 432],
[58.61, 64.19, 80, 110, 110, 254.99, 263.99, 878, 627, 434],
[58.57, 64.36, 79.99, 110, 110, 255, 264, 874, 620, 433],
[58.61, 64.35, 79.99, 110, 109.99, 254.99, 258.84, 898, 625, 433],
[58.92, 64.22, 80, 110, 110, 255, 263.99, 883, 627, 434],
[58.66, 64.13, 80, 110, 110, 255, 264, 893, 634, 435],
[58.57, 64.05, 80, 109.99, 110, 254.99, 263.99, 885, 634, 436],
[58.35, 64.21, 80, 110, 110, 255, 263.99, 877, 624, 434],
[58.18, 65.02, 80, 110, 109.99, 254.99, 263.99, 880, 631, 434],
[55.99, 64.37, 80, 110, 110, 255, 263.91, 863, 615, 431],
[56.03, 63.97, 79.99, 110, 110, 254.99, 264, 870, 620, 432],
[56.2, 62.98, 80, 110, 110, 255, 263.99, 890, 631, 436],
[55.3, 62.49, 80, 110, 110, 254.99, 263.99, 890, 640, 435],
[55.14, 62.33, 79.99, 110, 109.99, 255, 263.99, 877, 630, 435],
[55.42, 63.05, 79.99, 110, 110, 255, 263.99, 894, 638, 436],
[55.14, 62.78, 79.99, 110, 110, 255, 263.99, 899, 643, 438],
[55.06, 63.1, 79.99, 110, 110, 255, 263.99, 892, 635, 436],
[55.06, 63.2, 79.99, 110, 110, 255, 264, 878, 627, 435],
[55.02, 63.47, 80, 109.99, 110, 254.99, 264, 880, 631, 435],
[54.77, 63.03, 79.99, 110, 109.99, 255, 264, 888, 638, 437],
[54.77, 63.68, 80, 109.99, 110, 254.99, 264, 890, 636, 437],
[55.02, 63.14, 79.99, 109.99, 109.99, 254.99, 263.99, 874, 626, 433],
[54.94, 62.87, 80, 109.99, 109.99, 255, 263.99, 876, 629, 435],
[54.98, 62.68, 79.99, 110, 110, 255, 263.98, 882, 632, 435],
[54.9, 63.25, 79.99, 109.99, 110, 254.99, 263.98, 898, 643, 438],
[54.98, 64.28, 79.99, 110, 110, 254.99, 264, 905, 645, 439],
[54.98, 63.9, 79.99, 110, 110, 255, 264, 900, 642, 438],
[54.85, 63.57, 79.99, 110, 110, 254.99, 264, 901, 642, 438],
[54.9, 63.58, 79.99, 110, 110, 255, 263.99, 905, 646, 439],
[54.81, 63.39, 79.99, 110, 110, 255, 263.99, 893, 638, 437],
[54.77, 63.42, 79.99, 110, 110, 254.99, 264, 892, 639, 438],
[54.69, 62.74, 79.99, 110, 110, 255, 263.99, 890, 636, 436],
[54.69, 63.12, 80, 110, 109.99, 255, 264, 884, 633, 436],
[54.65, 63.33, 79.99, 110, 110, 254.99, 264, 893, 637, 437],
[54.65, 62.91, 80, 110, 110, 254.99, 263.99, 890, 639, 437],
[54.42, 62.5, 79.99, 110, 109.99, 254.99, 263.99, 889, 637, 437],
[54.46, 62.86, 79.99, 109.99, 110, 255, 264, 897, 641, 438],
[54.14, 62.81, 80, 109.99, 110, 254.99, 263.99, 894, 642, 437],
[54.02, 62.62, 79.99, 109.99, 110, 255, 264, 895, 643, 438],
[53.94, 62.9, 79.99, 110, 109.99, 255, 264, 897, 643, 438],
[54.06, 62.98, 79.99, 110, 109.99, 254.99, 264, 894, 641, 437],
[54.02, 62.93, 79.99, 110, 110, 255.5, 264, 914, 638, 437],
[54.3, 63.23, 79.99, 110, 110, 255, 263.99, 893, 636, 437],
[54.02, 63.61, 79.99, 109.99, 110, 255, 264, 887, 634, 435],
[54.22, 63.83, 80, 109.99, 110, 254.99, 263.99, 892, 636, 436],
[54.06, 63.86, 79.99, 110, 110, 254.99, 264, 892, 637, 437],
[53.98, 62.85, 80, 110, 109.99, 254.99, 264, 884, 634, 436],
[54.06, 64.63, 80, 110, 109.99, 254.99, 264, 893, 641, 439],
[53.98, 63.89, 79.99, 110, 109.99, 255, 264, 893, 641, 436],
[54.02, 63.15, 79.99, 110, 110, 255, 263.99, 879, 630, 436],
[54.02, 62.98, 80, 110, 109.99, 254.99, 263.99, 878, 633, 435],
[54.06, 63.56, 79.99, 110, 110, 255, 263.99, 883, 634, 437],
[53.98, 62.88, 80, 110, 110, 255, 263.99, 927, 639, 437],
[54.02, 62.45, 79.99, 110, 110, 255, 264, 881, 630, 435],
[54.22, 62.51, 79.99, 109.99, 110, 254.99, 264, 878, 628, 435],
[54.57, 62.43, 79.99, 109.99, 110, 255, 263.85, 915, 643, 438],
[54.3, 63.24, 79.99, 109.99, 110, 255, 264, 876, 628, 436],
[54.77, 62.98, 80, 110, 109.99, 254.99, 264, 891, 643, 437],
[54.69, 62.94, 79.99, 110, 109.99, 254.99, 264, 886, 636, 437],
[54.69, 62.8, 79.99, 109.99, 110, 255, 263.99, 888, 634, 437],
[54.77, 62.19, 79.99, 110, 109.99, 254.99, 264, 891, 639, 437],
[54.9, 62.02, 80, 110, 110, 254.99, 264, 891, 641, 438],
[55.62, 62.24, 79.99, 109.99, 110, 254.99, 264, 878, 628, 434],
[55.54, 62.39, 80, 109.99, 110, 255, 263.99, 890, 639, 437],
[55.91, 64.03, 80, 110, 110, 254.99, 264, 887, 638, 436],
[55.79, 64.36, 80, 109.99, 110, 255, 264, 881, 630, 435],
[55.95, 63.46, 80, 110, 110, 254.99, 263.99, 852, 609, 428],
[56.16, 62.93, 80, 110, 110, 254.99, 263.99, 867, 623, 432],
[56.4, 62.98, 80, 110, 110, 254.99, 263.99, 857, 615, 431],
[56.36, 62.72, 80, 110, 109.99, 254.99, 264, 854, 612, 430],
[56.61, 63.06, 80, 110, 110, 255, 264, 879, 627, 435],
[56.53, 63.56, 80, 110, 109.99, 254.99, 263.99, 871, 625, 433],
[56.86, 63.32, 79.99, 110, 109.99, 254.99, 264, 862, 617, 433],
[56.61, 62.89, 80, 110, 110, 255, 264, 882, 634, 435],
[56.65, 62.62, 79.99, 110, 110, 254.99, 264, 879, 632, 436],
[56.9, 63.29, 80, 110, 110, 255, 263.99, 876, 626, 434],
[57.84, 63.33, 79.99, 110, 110, 254.99, 263.99, 879, 626, 433],
[57.88, 63.34, 80, 110, 110, 255, 264, 868, 620, 432],
[58.53, 64.59, 80, 110, 110, 254.99, 264, 897, 637, 437],
[58.35, 65, 79.99, 110, 109.99, 255, 263.99, 895, 639, 436],
[58.48, 63.02, 80, 109.99, 110, 255, 263.99, 888, 633, 435],
[58.57, 62.22, 79.99, 109.99, 109.99, 254.97, 263.99, 895, 639, 436],
[58.44, 62.78, 79.99, 110, 109.99, 254.99, 264, 883, 633, 434],
[58.48, 62.96, 80, 109.99, 109.99, 255, 263.99, 873, 628, 433],
[58.57, 63.52, 80, 110, 109.99, 255, 264, 875, 625, 432],
[58.35, 63.95, 80, 110, 110, 255, 264, 876, 631, 433],
[58.22, 63.52, 79.99, 110, 109.99, 255, 263.99, 871, 622, 433],
[57.84, 63.26, 79.99, 110, 109.99, 255, 264, 862, 617, 431],
[57.96, 63.85, 79.99, 109.99, 110, 255, 263.99, 857, 613, 429],
[57.67, 63.48, 79.99, 110, 110, 255, 263.99, 889, 634, 436],
[57.84, 63.15, 80, 110, 110, 254.99, 263.99, 881, 630, 434],
[57.71, 63.43, 79.99, 110, 110, 254.99, 263.99, 870, 624, 434],
[57.11, 63.17, 80, 110, 110, 254.98, 263.99, 890, 634, 435],
[56.95, 62.86, 79.99, 110, 110, 254.99, 264, 882, 629, 434],
[57.11, 62.48, 79.99, 110, 110, 255, 263.99, 886, 635, 436],
[56.99, 63.02, 80, 110, 110, 254.99, 264, 876, 631, 435],
[55.54, 62.83, 80, 110, 110, 254.99, 264, 869, 622, 433],
[55.5, 62.75, 80, 110, 110, 255, 263.99, 878, 627, 434],
[55.34, 62.55, 80, 109.99, 109.99, 255, 264, 875, 628, 434],
[55.22, 62.72, 79.99, 110, 110, 255, 263.99, 890, 637, 437],
[55.5, 62.81, 79.99, 110, 110, 255, 264, 899, 642, 439],
[55.18, 63.89, 80, 109.99, 109.99, 255, 264, 882, 630, 435],
[55.1, 63.4, 80, 109.99, 110, 255, 263.99, 890, 637, 436],
[55.02, 62.72, 79.99, 110, 110, 254.99, 263.99, 893, 640, 437],
[54.94, 63.17, 79.99, 110, 109.99, 255.11, 264.04, 896, 643, 437],
[54.9, 63.13, 80, 110, 109.99, 254.99, 264, 884, 634, 436],
[54.98, 63.51, 79.99, 109.99, 110, 255, 264, 888, 636, 436],
[54.9, 63.15, 80, 109.99, 110, 255, 263.99, 889, 635, 437],
[54.85, 62.7, 80, 109.99, 110, 254.99, 264, 886, 632, 436],
[54.9, 62.49, 80, 109.99, 110, 255, 263.99, 882, 632, 436],
[54.85, 63.11, 79.99, 109.99, 110, 255, 264, 884, 633, 437],
[54.81, 62.81, 79.99, 110, 109.99, 255, 263.99, 891, 639, 436],
[54.73, 62.96, 79.99, 110, 110, 254.99, 264, 887, 637, 436],
[54.94, 62.64, 79.99, 110, 110, 255, 263.56, 891, 634, 435],
[54.9, 61.67, 80, 110, 110, 255, 263.99, 892, 640, 437],
[54.73, 62.16, 79.99, 110, 110, 254.99, 263.99, 890, 634, 436],
[54.73, 62.43, 79.99, 110, 110, 255, 264, 894, 639, 437],
[54.61, 63.08, 79.99, 110, 110, 255, 264, 879, 630, 435],
[54.57, 63.8, 79.99, 110, 110, 254.99, 263.99, 877, 629, 433],
[54.61, 64.64, 80, 110, 110, 255, 264, 885, 634, 436],
[54.53, 63.94, 80, 110, 109.99, 254.99, 264, 883, 637, 437],
[54.5, 63.62, 80, 110, 110, 255, 263.99, 882, 630, 436],
[54.65, 62.03, 79.99, 110, 109.99, 254.99, 263.99, 854, 613, 429],
[54.5, 61.8, 80, 110, 109.99, 254.99, 264, 850, 611, 430],
[54.46, 62.98, 80, 110, 109.99, 254.99, 263.99, 846, 612, 429],
[54.57, 63.62, 79.99, 110, 110, 254.99, 264, 854, 614, 431],
[54.42, 62.75, 79.99, 110, 110, 255, 264, 852, 612, 429],
[54.5, 62.49, 79.99, 109.99, 109.99, 255, 264, 848, 608, 429],
[54.53, 62.75, 80, 110, 110, 255, 263.99, 851, 611, 430],
[54.69, 63.39, 80, 110, 110, 254.99, 263.99, 860, 617, 431],
[54.81, 64.01, 80, 110, 110, 255, 264, 859, 617, 431],
[54.73, 63.3, 80, 110, 110, 254.99, 264.01, 850, 612, 429],
[54.69, 63.36, 79.99, 110, 110, 254.99, 264, 854, 616, 430],
[54.85, 62.44, 80, 110, 110, 254.99, 264, 888, 635, 436],
[54.69, 63.61, 79.99, 110, 110, 255, 263.99, 893, 638, 437],
[54.77, 63.24, 79.99, 110, 110, 254.99, 264, 887, 634, 436],
[54.77, 63.43, 79.99, 110, 110, 254.99, 263.99, 890, 637, 436],
[55.02, 63.85, 80, 110, 110, 255, 264, 871, 623, 434],
[54.9, 64.86, 79.99, 110, 110, 255, 263.99, 881, 631, 435],
[54.9, 64.61, 79.99, 110, 110, 254.99, 264, 882, 634, 434],
[55.02, 63.53, 79.99, 110, 110, 255, 263.99, 885, 634, 436],
[54.85, 63.03, 80, 110, 110, 255, 264, 875, 627, 433],
[54.81, 62.52, 79.99, 110, 109.99, 254.99, 264, 883, 633, 436],
[54.69, 63.15, 79.99, 109.99, 109.99, 254.99, 264, 891, 640, 438],
[54.9, 63.19, 80, 110, 110, 255, 263.99, 886, 633, 436],
[54.61, 63.33, 80, 109.99, 110, 255, 264, 894, 643, 437],
[54.65, 63.37, 80, 110, 110, 255, 264, 890, 639, 437],
[54.69, 62.72, 79.99, 110, 110, 254.99, 263.99, 889, 638, 436],
[54.81, 63.36, 80, 109.99, 110, 255, 263.99, 881, 630, 434],
[54.85, 62.2, 79.99, 110, 110, 254.99, 264, 877, 629, 434],
[54.81, 62.5, 80, 110, 110, 254.99, 263.99, 871, 626, 433],
[54.81, 62.84, 79.99, 109.99, 109.99, 254.99, 263.99, 879, 631, 435],
[54.77, 62.73, 80, 110, 110, 255, 264, 890, 639, 437],
[54.73, 62.79, 79.99, 110, 110, 254.99, 263.99, 864, 625, 435],
[54.94, 63.02, 80, 110, 110, 254.99, 264, 880, 632, 436],
[54.98, 62.65, 80, 110, 110, 254.99, 263.99, 881, 633, 434],
[54.98, 63.19, 79.99, 110, 110, 255, 264, 860, 620, 432],
[54.94, 62.89, 79.99, 110, 109.99, 255, 264, 859, 617, 431],
[54.69, 62.9, 80, 110, 110, 255, 264, 869, 623, 432],
[54.85, 63.05, 79.99, 110, 110, 255, 263.99, 875, 632, 435],
[54.69, 63.62, 80, 109.99, 110, 254.99, 263.99, 854, 616, 431],
[55.02, 63.81, 79.99, 110, 110, 254.99, 264, 871, 624, 433],
[54.98, 63.62, 79.99, 110, 110, 254.99, 264, 862, 619, 431],
[54.9, 63.43, 79.99, 110, 110, 255, 264, 866, 624, 432],
[54.69, 63.51, 79.99, 109.99, 109.99, 254.99, 264, 862, 619, 432],
[54.73, 63.37, 79.99, 110, 109.99, 254.99, 263.99, 890, 639, 437],
[54.85, 63.86, 79.99, 109.99, 110, 255, 263.99, 864, 622, 433],
[54.69, 62.84, 79.99, 110, 110, 255, 263.99, 859, 616, 432],
[55.02, 61.84, 79.99, 110, 110, 254.99, 263.99, 876, 627, 433],
[54.98, 61.82, 80, 109.99, 110, 255, 263.99, 853, 612, 432],
[55.58, 61.76, 79.99, 110, 110, 254.99, 264, 879, 633, 435],
[55.62, 62.45, 80, 110, 110, 254.99, 264, 872, 623, 433],
[55.7, 63.5, 80, 110, 110, 255, 264, 876, 627, 434],
[55.7, 63.41, 80, 110, 110, 254.99, 263.99, 892, 642, 437],
[55.7, 63.56, 80, 110, 110, 255.01, 264.1, 905, 634, 435],
[55.79, 64.66, 79.99, 109.99, 110, 255, 264, 884, 634, 435],
[55.75, 63.69, 79.99, 110, 110, 254.99, 264, 887, 637, 436],
[55.95, 63.56, 79.99, 109.99, 110, 255, 264, 891, 638, 436],
[56.03, 62.39, 79.99, 109.99, 110, 255, 263.99, 888, 637, 436],
[55.95, 62.98, 80, 110, 110, 254.99, 263.99, 885, 639, 436],
[55.95, 64.13, 79.99, 110, 110, 255, 264, 883, 635, 434],
[56.16, 63.32, 79.99, 110, 110, 255, 264, 882, 633, 435],
[56.24, 62.6, 80, 110, 110, 255, 264, 860, 620, 431],
[56.2, 63.24, 80, 110, 110, 255.01, 263.66, 886, 620, 432],
[56.16, 62.69, 79.99, 109.99, 110, 254.99, 264, 865, 623, 432],
[55.99, 62.92, 80, 110, 110, 255, 264, 861, 618, 433],
[56.03, 62.53, 80, 109.99, 110, 255, 263.99, 881, 632, 434],
[55.7, 62.64, 80, 110, 110, 254.99, 263.99, 875, 625, 433],
[56.07, 63.71, 79.99, 109.99, 109.99, 255, 264, 890, 635, 436],
[55.95, 64.07, 79.99, 110, 109.99, 254.96, 263.99, 882, 631, 435],
[55.83, 64.31, 79.99, 110, 110, 255, 264, 886, 633, 436],
[55.95, 64.28, 79.99, 110, 110, 255, 263.99, 875, 629, 434],
[55.79, 63.15, 80, 110, 110, 254.99, 264, 879, 628, 434],
[55.79, 62.32, 79.99, 110, 109.99, 255, 263.99, 870, 626, 433],
[55.62, 61.67, 79.99, 110, 110, 255, 264, 863, 619, 431],
[55.66, 62.05, 79.99, 110, 110, 254.99, 263.99, 870, 624, 433],
[55.91, 62.44, 79.99, 110, 110, 255, 263.99, 874, 626, 434],
[55.7, 62.51, 79.99, 109.99, 109.99, 254.99, 263.99, 883, 633, 435],
[55.7, 63.23, 79.99, 110, 109.99, 255, 263.99, 884, 634, 435],
[55.66, 63.21, 80, 110, 110, 255, 264, 869, 623, 433],
[55.5, 62.5, 79.99, 110, 110, 254.99, 264, 873, 626, 433],
[55.26, 63.1, 79.99, 110, 110, 254.99, 264, 895, 641, 437],
[55.06, 63.48, 79.99, 110, 110, 255, 263.99, 890, 642, 437],
[54.98, 64.07, 79.99, 110, 110, 255, 263.99, 879, 630, 434],
[54.9, 64.09, 80, 110, 110, 255, 264, 860, 619, 432],
[55.14, 64.09, 79.99, 110, 110, 255, 264, 868, 625, 433],
[54.77, 63.2, 80, 109.99, 109.99, 254.99, 264, 863, 618, 432],
[54.73, 63.99, 80, 109.99, 110, 255, 263.99, 864, 622, 432],
[54.61, 63.45, 79.99, 109.99, 109.99, 255, 263.99, 864, 622, 432],
[54.65, 63.29, 79.99, 110, 109.99, 255, 264, 866, 624, 432],
[54.77, 63.18, 79.99, 109.99, 110, 255, 263.99, 860, 621, 431],
[54.73, 63.34, 79.99, 110, 109.99, 254.99, 264, 872, 624, 433],
[54.5, 62.94, 79.99, 110, 110, 254.99, 264, 873, 626, 434],
[54.53, 63.17, 79.99, 109.99, 109.99, 255.26, 264.21, 873, 624, 432],
[54.81, 63.06, 80, 110, 110, 254.99, 264, 871, 625, 433],
[54.61, 62.73, 80, 110, 110, 255.01, 264, 890, 641, 436],
[54.73, 63.31, 79.99, 110, 110, 254.99, 263.99, 889, 640, 436],
[54.3, 63.05, 79.99, 110, 110, 254.99, 264, 897, 643, 438],
[54.3, 62.69, 80, 109.99, 109.99, 255, 264, 894, 642, 437],
[54.14, 63.64, 79.99, 109.99, 110, 255, 264, 859, 616, 431],
[54.14, 63.64, 80, 110, 110, 254.97, 263.99, 879, 628, 433],
[54.02, 62.76, 79.99, 110, 110, 254.99, 264, 890, 642, 436],
[53.86, 63.33, 79.99, 110, 110, 255, 264, 864, 621, 432],
[53.63, 61.82, 80, 110, 109.99, 255, 264, 864, 622, 433],
[53.82, 62.79, 80, 110, 110, 255, 263.99, 870, 615, 430],
[53.51, 62.74, 79.99, 110, 109.99, 255, 264, 870, 626, 434],
[52.89, 62.52, 79.99, 110, 109.99, 254.99, 264, 875, 630, 434],
[52.77, 62.59, 80, 110, 109.99, 255, 263.99, 888, 636, 436],
[52.35, 61.99, 80, 110, 110, 255, 264, 880, 634, 436],
[52.51, 61.84, 79.99, 109.99, 110, 255, 264, 903, 645, 438],
[52.47, 61.98, 79.99, 109.99, 110, 255, 263.99, 891, 635, 437],
[52.54, 62.31, 79.99, 109.99, 109.99, 254.99, 264, 881, 632, 436],
[52.66, 62.56, 79.99, 109.99, 110, 254.99, 263.96, 882, 634, 434],
[52.51, 62.37, 80, 110, 110, 254.99, 264, 879, 633, 435],
[52.62, 62.05, 79.99, 109.99, 109.99, 254.99, 264, 894, 639, 437],
[52.58, 62.18, 80, 110, 109.99, 254.99, 264, 879, 637, 436],
[52.66, 62.31, 80, 110, 109.99, 254.99, 264, 885, 635, 437],
[52.77, 62.87, 80, 110, 110, 254.99, 264, 874, 626, 434],
[52.77, 62.52, 80, 110, 110, 255, 263.99, 881, 634, 435],
[52.74, 62.36, 80, 110, 110, 254.99, 264, 886, 640, 437],
[53.04, 61.57, 80, 109.99, 110, 254.99, 263.99, 869, 624, 432],
[53.01, 62.26, 80, 109.99, 109.99, 255, 264, 876, 630, 434],
[53.01, 62.47, 79.99, 110, 110, 254.99, 264, 881, 635, 435],
[52.81, 62.82, 79.99, 110, 110, 255, 263.99, 884, 635, 436],
[53.04, 62.42, 80, 110, 110, 255, 263.99, 890, 642, 438],
[52.58, 62.24, 80, 110, 110, 255, 264, 888, 639, 438],
[52.54, 62, 79.99, 110, 110, 255, 264, 891, 638, 438],
[52.43, 62.48, 79.99, 109.99, 110, 254.99, 263.99, 876, 630, 435],
[51.67, 61.6, 79.99, 109.99, 110, 255, 263.99, 861, 619, 432],
[51.52, 62.34, 79.99, 110, 110, 254.99, 263.99, 883, 634, 437],
[51.75, 61.96, 80, 110, 110, 254.99, 264, 886, 636, 436],
[51.82, 61.6, 79.99, 110, 109.99, 254.99, 264, 890, 640, 438],
[51.67, 61.34, 79.99, 110, 110, 254.99, 263.99, 876, 632, 435],
[51.56, 61.37, 80, 110, 110, 255, 264, 862, 627, 435],
[51.82, 62.19, 79.99, 110, 110, 255, 264, 865, 623, 434],
[51.86, 62.58, 80, 110, 109.99, 255, 264, 884, 639, 436],
[51.86, 62.35, 79.99, 110, 110, 255, 263.99, 889, 639, 437],
[51.71, 63.15, 79.99, 110, 110, 255, 263.99, 886, 636, 436],
[51.75, 62.32, 80, 110, 110, 255, 263.99, 883, 642, 435],
[51.67, 62.5, 80, 109.99, 110, 254.99, 264.01, 876, 635, 435],
[51.67, 62.39, 79.99, 110, 110, 255, 264, 883, 634, 436],
[51.56, 62.56, 80, 109.99, 110, 254.99, 263.99, 880, 636, 436],
[51.9, 63.03, 79.99, 110, 110, 255, 263.99, 877, 634, 435],
[51.94, 63.29, 79.99, 110, 110, 254.99, 264, 880, 636, 437],
[51.9, 62.92, 79.99, 110, 110, 255.01, 263.29, 894, 638, 436],
[52.09, 62.2, 80, 110, 110, 254.99, 263.99, 873, 632, 434],
[51.86, 62.86, 79.99, 110, 110, 254.99, 264, 876, 627, 435],
[52.2, 62.35, 80, 110, 110, 254.99, 263.99, 892, 642, 438],
[52.31, 62.47, 79.99, 110, 110, 255, 264, 881, 637, 436],
[52.47, 62.18, 79.99, 109.99, 109.99, 255, 263.99, 896, 649, 438],
[52.35, 63.18, 79.99, 110, 109.99, 255, 264, 878, 635, 435],
[52.51, 62.42, 80, 110, 109.99, 254.99, 264, 884, 638, 436],
[52.47, 62.33, 80, 110, 109.99, 255, 264, 873, 633, 434],
[52.51, 62.27, 79.99, 109.99, 110, 255, 263.99, 889, 639, 436],
[52.77, 62.33, 80, 109.99, 110, 254.99, 264, 856, 619, 434],
[52.47, 62.09, 79.99, 110, 110, 255, 264, 860, 618, 433],
[52.66, 63.03, 79.99, 110, 110, 255.23, 264.1, 874, 626, 433],
[52.47, 62.38, 80, 110, 110, 255, 263.99, 872, 631, 435],
[52.62, 61.93, 80, 109.99, 110, 254.99, 264, 881, 634, 435],
[52.62, 62.53, 79.99, 110, 109.99, 255, 264, 871, 627, 435],
[52.81, 63.28, 80, 110, 109.99, 255, 263.99, 862, 617, 432],
[52.74, 63.19, 79.99, 109.99, 110, 254.99, 264, 875, 629, 434],
[52.43, 63.3, 79.99, 110, 109.99, 255, 263.99, 878, 632, 436],
[52.81, 62.68, 80, 110, 110, 255, 263.9, 895, 624, 433],
[52.66, 63.27, 79.99, 110, 110, 254.99, 264, 876, 630, 435],
[52.81, 63.06, 79.99, 109.99, 110, 254.99, 264, 872, 624, 434],
[52.7, 62.38, 80, 109.99, 110, 255, 264, 878, 628, 434],
[52.62, 62.43, 80, 110, 110, 255, 263.99, 868, 624, 433],
[52.47, 62.26, 79.99, 110, 109.99, 254.99, 264, 866, 622, 434],
[52.51, 62.74, 80, 110, 110, 255, 263.99, 860, 619, 432],
[52.43, 63.73, 79.99, 110, 109.99, 255, 264, 853, 612, 431],
[52.66, 63.25, 80, 110, 110, 254.99, 264, 876, 633, 435],
[52.51, 63.2, 79.99, 110, 110, 255, 263.99, 872, 627, 434],
[52.35, 63.74, 79.99, 110, 110, 254.99, 263.99, 883, 637, 436],
[52.39, 63.52, 79.99, 110, 110, 255, 264, 888, 640, 437],
[52.39, 62.74, 80, 110, 110, 255, 261.39, 892, 633, 435],
[52.47, 63.31, 79.99, 109.99, 110, 255, 263.99, 885, 641, 436],
[52.43, 62.86, 79.99, 110, 110, 255, 263.99, 887, 642, 437],
[52.39, 62.69, 80, 110, 110, 255.18, 264, 903, 639, 438],
[52.51, 63.01, 79.99, 110, 109.99, 255, 263.99, 885, 640, 437],
[52.13, 62.44, 79.99, 110, 110, 254.99, 263.99, 900, 649, 439],
[52.31, 62.94, 79.99, 109.99, 110, 255, 264, 893, 641, 438],
[52.31, 62.68, 80, 109.99, 110, 254.97, 263.99, 896, 645, 438],
[52.47, 62.77, 80, 110, 110, 255, 264, 892, 641, 438],
[52.35, 63.48, 80, 110, 110, 254.99, 264, 894, 642, 438],
[52.35, 63.69, 79.99, 110, 109.99, 255, 264, 888, 639, 436],
[52.58, 63.71, 80, 110, 110, 254.99, 264.01, 888, 640, 436],
[52.35, 62.64, 79.99, 110, 110, 254.99, 263.99, 887, 637, 437],
[52.13, 62.79, 80, 110, 109.99, 254.99, 263.99, 887, 636, 436],
[52.09, 62.46, 80, 110, 110, 254.99, 263.99, 891, 639, 438],
[51.82, 62.66, 79.99, 110, 110, 254.97, 264, 875, 632, 434],
[51.97, 63.29, 79.99, 110, 110, 254.99, 264, 863, 626, 432],
[51.9, 63.49, 79.99, 110, 110, 255.07, 263.95, 874, 632, 432],
[51.9, 63.35, 79.99, 110, 109.99, 255, 264, 881, 634, 434],
[51.9, 62.43, 79.99, 110, 110, 254.99, 264, 891, 641, 437],
[51.82, 62.43, 80, 110, 110, 254.99, 264, 902, 648, 439],
[51.9, 63.13, 80, 110, 110, 255, 264, 906, 653, 439],
[52.01, 63.07, 80, 110, 110, 255, 264, 892, 641, 437],
[52.2, 63.33, 79.99, 109.99, 110, 254.99, 264, 878, 632, 435],
[52.2, 62.31, 80, 110, 110, 255.49, 264, 882, 634, 435],
[51.9, 62.35, 80, 109.99, 110, 255, 264, 878, 630, 434],
[51.78, 63.21, 80, 110, 110, 254.99, 263.99, 873, 628, 433],
[51.9, 62.99, 79.99, 109.99, 110, 255, 264, 882, 631, 435],
[52.39, 62.6, 80, 110, 110, 255, 263.99, 893, 642, 438],
[52.13, 62.83, 79.99, 109.99, 110, 254.99, 264, 890, 641, 437],
[52.31, 62.27, 79.99, 110, 110, 254.99, 264, 890, 637, 436],
[52.28, 61.77, 80, 110, 110, 255.01, 264, 940, 640, 436],
[52.2, 61.69, 80, 110, 110, 254.99, 264, 883, 633, 436],
[51.86, 63.25, 80, 110, 110, 254.99, 264, 886, 635, 436],
[51.82, 63.71, 79.99, 110, 110, 254.99, 264, 889, 634, 436],
[51.63, 63.61, 79.99, 110, 110, 254.99, 263.99, 882, 639, 435],
[51.86, 63.05, 80, 110, 110, 254.99, 264, 877, 628, 434],
[51.71, 62.51, 79.99, 110, 110, 254.99, 263.99, 891, 634, 437],
[51.56, 62.45, 79.99, 109.99, 110, 254.99, 264, 899, 642, 437],
[51.6, 62.34, 80, 109.99, 109.99, 255, 264, 886, 632, 436],
[51.78, 62.01, 80, 110, 110, 255, 264, 903, 643, 439],
[51.67, 61.47, 79.99, 110, 110, 255, 264, 890, 637, 437],
[51.75, 62.52, 80, 110, 110, 254.99, 263.99, 890, 641, 438],
[51.9, 62.82, 80, 110, 110, 255, 263.99, 869, 626, 432],
[51.78, 63.66, 80, 109.99, 109.99, 255, 264, 869, 624, 433],
[51.94, 62.49, 80, 110, 110, 254.99, 263.99, 857, 614, 430],
[51.97, 62.2, 79.99, 110, 110, 254.99, 263.99, 867, 620, 432],
[52.13, 62.29, 79.99, 110, 109.99, 255, 263.94, 907, 634, 436],
[52.05, 61.88, 79.99, 110, 110, 255, 263.99, 876, 630, 435],
[52.43, 62.69, 80, 109.99, 110, 254.99, 263.99, 885, 638, 436],
[52.51, 62.06, 80, 110, 110, 255, 264, 894, 642, 438],
[52.85, 62.73, 79.99, 110, 110, 254.99, 264, 894, 641, 437],
[52.93, 62.85, 79.99, 110, 109.99, 255, 264, 891, 642, 436],
[52.89, 62.93, 80, 110, 110, 254.99, 263.99, 892, 640, 437],
[53.01, 63.32, 79.99, 110, 110, 254.99, 263.99, 896, 640, 437],
[53.04, 62.79, 80, 110, 110, 254.99, 263.99, 897, 644, 438],
[52.93, 62.12, 79.99, 109.99, 110, 255, 264, 883, 638, 435],
[53.01, 61.85, 80, 109.99, 110, 255, 264, 887, 635, 436],
[53.04, 62.36, 79.99, 110, 110, 255, 263.99, 890, 638, 436],
[53.12, 62.18, 79.99, 110, 109.99, 255, 264, 890, 641, 437],
[52.93, 61.92, 80, 109.99, 109.99, 254.99, 264, 891, 638, 437],
[52.97, 61.86, 80, 110, 110, 255, 263.99, 900, 643, 437],
[52.62, 61.74, 79.99, 109.99, 110, 255, 263.99, 890, 639, 436],
[52.58, 62.55, 80, 110, 110, 255, 264, 896, 642, 438],
[52.01, 62.66, 80, 110, 109.99, 255, 264, 889, 637, 436],
[52.47, 62.97, 79.99, 110, 110, 254.99, 263.99, 889, 635, 435],
[52.47, 63.42, 79.99, 109.99, 109.99, 255, 263.99, 894, 642, 437],
[52.43, 62.86, 80, 109.99, 110, 255, 263.99, 889, 637, 435],
[52.39, 63.02, 80, 110, 109.99, 255, 263.99, 878, 631, 434],
[52.28, 63.51, 79.99, 110, 110, 255, 263.99, 897, 642, 438],
[52.39, 62.76, 79.99, 110, 110, 254.99, 264, 897, 643, 437],
[52.2, 62.65, 79.99, 109.99, 110, 255, 264, 899, 643, 438],
[52.28, 62.63, 80, 110, 110, 254.99, 263.98, 896, 647, 438],
[52.05, 63.01, 79.99, 110, 109.99, 255, 263.99, 898, 644, 438],
[52.35, 62.84, 79.99, 110, 110, 254.99, 264, 904, 644, 439],
[52.31, 63.11, 80, 110, 110, 255, 263.99, 893, 641, 437],
[52.47, 63.25, 79.99, 110, 109.99, 254.99, 263.99, 895, 643, 437],
[52.39, 63.21, 80, 109.99, 110, 254.99, 263.99, 895, 639, 437],
[52.28, 62.44, 79.99, 110, 110, 255, 263.99, 898, 645, 438],
[52.31, 62.46, 80, 110, 110, 254.99, 264, 897, 643, 438],
[52.62, 63.12, 79.99, 110, 110, 255, 263.99, 872, 627, 433],
[52.35, 63.36, 79.99, 110, 109.99, 255, 263.99, 894, 641, 437],
[52.47, 61.95, 80, 110, 109.99, 254.96, 263.99, 898, 645, 438],
[52.51, 62.65, 80, 109.99, 110, 255, 264, 896, 640, 437],
[52.31, 62.44, 80, 110, 110, 254.99, 263.99, 897, 643, 438],
[52.13, 62.64, 80, 110, 110, 254.99, 264, 893, 639, 437],
[52.24, 63.3, 79.99, 110, 110, 255, 264, 893, 644, 438],
[52.01, 62.76, 79.99, 109.99, 110, 254.99, 264, 895, 642, 437],
[52.16, 62.53, 79.99, 110, 110, 254.99, 264, 887, 636, 435],
[52.16, 61.9, 79.99, 110, 110, 254.99, 264, 885, 635, 436],
[52.13, 62.44, 79.99, 110, 109.99, 255, 264, 895, 642, 437],
[52.05, 62.92, 79.99, 110, 109.99, 254.99, 264, 897, 645, 438],
[51.9, 62.25, 80, 110, 110, 255, 263.99, 907, 650, 440],
[52.2, 63.5, 80, 109.99, 110, 255.02, 263.91, 897, 642, 436],
[52.01, 63.57, 79.99, 110, 109.99, 255, 264, 882, 634, 435],
[52.28, 63.29, 80, 109.99, 110, 255, 263.99, 891, 637, 436],
[52.01, 62.86, 80, 110, 110, 255, 263.99, 891, 640, 437],
[52.01, 62.44, 79.99, 110, 110, 255, 263.99, 889, 636, 435],
[52.28, 62.55, 80, 110, 109.99, 255, 263.99, 880, 633, 434],
[52.13, 63.09, 79.99, 109.99, 110, 254.99, 263.99, 893, 641, 438],
[52.24, 63.89, 79.99, 109.99, 110, 254.99, 263.99, 895, 648, 437],
[52.09, 65.9, 79.99, 110, 110, 254.99, 264, 886, 636, 435],
[52.47, 64.95, 80, 109.99, 110, 255, 263.99, 882, 633, 435],
[52.13, 63.96, 80, 110, 109.99, 255, 264.01, 889, 639, 437],
[52.16, 62.74, 79.99, 110, 110, 255, 263.99, 899, 649, 438],
[52.28, 62.1, 80, 110, 109.99, 255, 263.99, 901, 647, 439],
[52.13, 62.11, 80, 110, 110, 255, 264, 898, 643, 438],
[52.2, 62.84, 79.99, 110, 109.99, 255, 263.99, 898, 649, 438],
[52.2, 63.8, 80, 110, 109.99, 254.99, 263.99, 898, 642, 438],
[52.16, 64.02, 79.99, 110, 110, 255, 264, 898, 640, 438],
[52.05, 63.66, 80, 110, 110, 254.99, 264, 902, 652, 439],
[52.13, 61.96, 79.99, 110, 109.99, 254.99, 264, 885, 634, 436],
[52.05, 62.71, 80, 110, 110, 254.99, 263.99, 885, 634, 436],
[52.05, 62.94, 80, 110, 110, 254.99, 263.99, 894, 641, 437],
[51.9, 62.75, 80, 109.99, 110, 255, 263.99, 898, 642, 438],
[51.9, 63.08, 80, 109.99, 110, 255, 264, 898, 645, 437],
[51.94, 63.24, 80, 110, 109.99, 255, 264, 898, 645, 437],
[51.94, 63.44, 79.99, 109.99, 110, 254.99, 263.99, 893, 639, 436],
[52.2, 62.21, 79.99, 110, 110, 255, 264, 894, 643, 437],
[51.9, 63.52, 79.99, 110, 110, 254.99, 263.99, 899, 643, 438],
[51.9, 63.31, 79.99, 110, 110, 254.99, 264, 902, 643, 438],
[51.78, 63.07, 79.99, 110, 110, 255, 264, 890, 635, 435],
[51.97, 62.97, 79.99, 109.99, 109.99, 255, 264, 898, 641, 437],
[51.97, 62.04, 79.99, 110, 110, 255, 264, 901, 646, 439],
[51.94, 61.9, 79.99, 110, 110, 254.99, 263.99, 888, 637, 437],
[52.43, 62.28, 79.99, 110, 110, 255, 264, 896, 640, 437],
[52.35, 61.63, 79.99, 110, 110, 255, 263.99, 899, 641, 438],
[52.39, 61.81, 79.99, 110, 110, 255, 263.99, 902, 642, 438],
[52.54, 62.46, 80, 110, 110, 254.99, 263.99, 902, 643, 437],
[52.54, 62.11, 80, 110, 110, 255, 264, 910, 648, 439],
[52.7, 63.28, 80, 110, 109.99, 254.99, 264, 906, 647, 439],
[52.74, 62.3, 80, 109.99, 110, 254.99, 263.99, 907, 646, 438],
[52.89, 62.94, 79.99, 109.99, 109.99, 255, 263.99, 885, 631, 434],
[53.12, 62.52, 79.99, 110, 110, 255, 264, 872, 625, 434],
[53.2, 62.34, 80, 110, 110, 254.99, 263.99, 894, 637, 436],
[53.39, 62.68, 79.99, 110, 110, 255, 263.99, 907, 648, 438],
[53.59, 62.65, 80, 110, 110, 255, 263.99, 897, 638, 436],
[53.39, 63.44, 79.99, 109.99, 110, 254.99, 264, 894, 640, 437],
[53.43, 63.07, 80, 110, 109.99, 254.99, 264, 878, 624, 434],
[53.63, 62.45, 80, 109.99, 110, 254.98, 263.98, 900, 644, 436],
[53.67, 62.89, 79.99, 109.99, 110, 255, 263.99, 899, 641, 437],
[53.71, 63.44, 80, 110, 110, 255, 263.99, 892, 638, 435],
[53.67, 63.19, 79.99, 110, 110, 254.99, 263.99, 905, 644, 438],
[53.67, 62.32, 80, 109.99, 110, 254.99, 264, 886, 637, 436],
[53.71, 62.94, 80, 110, 110, 255, 263.99, 892, 639, 436],
[53.67, 62.65, 79.99, 109.99, 110, 255, 263.99, 880, 629, 434],
[53.59, 63.29, 79.99, 110, 109.99, 255, 264, 893, 634, 435],
[53.51, 63.36, 79.99, 110, 109.99, 255, 263.99, 906, 648, 439],
[53.47, 62.74, 79.99, 109.99, 110, 255, 264, 899, 642, 437],
[53.47, 62.36, 80, 110, 109.99, 255, 263.99, 902, 642, 437],
[53.31, 62.95, 80, 110, 110, 254.99, 264, 893, 638, 436],
[53.39, 63.04, 80, 110, 110, 254.99, 264, 880, 629, 433],
[53.39, 62.67, 80, 110, 110, 255, 263.99, 891, 636, 436],
[53.12, 62.7, 79.99, 110, 110, 255, 264, 892, 636, 435],
[53.31, 63.13, 79.99, 110, 110, 255, 264, 873, 620, 433],
[53.67, 63.46, 80, 110, 110, 254.99, 263.99, 890, 637, 434],
[53.31, 63.86, 80, 110, 110, 254.99, 263.99, 880, 627, 433],
[53.39, 63.71, 79.99, 110, 110, 255, 264, 864, 617, 431],
[53.35, 63.08, 80, 110, 110, 255, 264, 875, 623, 431],
[53.31, 62.45, 79.99, 110, 110, 255, 264, 884, 630, 434],
[53.47, 62.88, 79.99, 109.99, 110, 254.99, 263.99, 898, 642, 436],
[53.51, 62.64, 80, 110, 110, 255, 264, 888, 638, 436],
[53.55, 62.87, 80, 110, 110, 254.99, 263.99, 894, 638, 436],
[53.74, 62.13, 79.99, 110, 109.99, 254.99, 264, 871, 620, 434],
[53.94, 62.77, 79.99, 109.99, 110, 254.99, 264, 883, 629, 434],
[53.67, 62.58, 80, 110, 109.99, 255, 264, 885, 633, 435],
[53.82, 61.97, 79.99, 110, 110, 254.99, 264, 884, 629, 434],
[53.74, 61.6, 80, 110, 110, 254.99, 264, 891, 639, 436],
[53.71, 62.41, 79.99, 110, 109.99, 254.99, 263.99, 882, 631, 436],
[53.94, 63.38, 79.99, 110, 110, 255, 263.83, 881, 616, 430],
[53.94, 63.86, 79.99, 110, 109.99, 255, 263.99, 874, 628, 433],
[53.86, 62.9, 80, 109.99, 110, 254.99, 263.99, 883, 629, 434],
[54.1, 63.17, 80, 110, 110, 254.99, 263.99, 899, 637, 437],
[53.94, 62.97, 79.99, 110, 110, 255, 263.99, 898, 641, 437],
[54.02, 63.04, 80, 110, 109.99, 255, 263.99, 903, 643, 437],
[53.74, 62.83, 80, 109.99, 110, 254.99, 264, 898, 639, 437],
[53.63, 62.54, 79.99, 110, 110, 255, 264, 896, 635, 437],
[53.82, 62.86, 79.99, 110, 110, 254.99, 264, 883, 633, 434],
[53.59, 62.79, 80, 110, 110, 254.99, 264, 893, 637, 435],
[53.86, 62.95, 80, 110, 110, 255, 263.99, 900, 641, 438],
[53.74, 63.2, 79.99, 110, 110, 255, 264, 898, 638, 437],
[53.63, 62.89, 80, 110, 110, 255, 263.99, 897, 639, 436],
[53.55, 62.67, 79.99, 110, 110, 254.99, 263.99, 902, 642, 438],
[53.63, 62.55, 80, 110, 110, 254.99, 263.99, 899, 641, 437],
[53.47, 63.82, 79.99, 110, 110, 254.99, 263.99, 882, 635, 434],
[53.24, 64.69, 79.99, 110, 110, 255, 264, 886, 628, 435],
[53.08, 63.59, 79.99, 110, 109.99, 255, 263.99, 896, 638, 437],
[53.04, 62.5, 80, 110, 109.99, 255, 264, 896, 639, 437],
[53.12, 62.46, 79.99, 110, 109.99, 255, 263.99, 894, 634, 436],
[53.12, 61.79, 80, 110, 109.99, 255, 263.98, 900, 640, 437],
[53.12, 62.37, 79.99, 110, 110, 255, 258.27, 915, 646, 439],
[53.16, 62.36, 79.99, 110, 110, 255, 263.99, 907, 645, 438],
[53.12, 62.81, 79.99, 109.99, 110, 255, 264, 908, 646, 438],
[53.59, 63.3, 79.99, 110, 110, 254.99, 263.99, 905, 648, 439],
[53.67, 63.59, 79.99, 110, 110, 255, 264, 900, 642, 437],
[53.86, 63.7, 79.99, 110, 110, 255, 264, 903, 645, 439],
[53.82, 64.07, 79.99, 109.99, 110, 255, 263.99, 900, 642, 437],
[53.74, 63.89, 80, 110, 110, 255, 263.99, 900, 642, 436],
[53.9, 62.46, 80, 110, 110, 254.99, 263.99, 900, 640, 437],
[53.9, 62.8, 80, 110, 109.99, 255, 263.99, 903, 643, 438],
[54.14, 63.24, 79.99, 110, 110, 255, 263.99, 899, 642, 437],
[54.06, 64.49, 79.99, 109.99, 110, 255.06, 264.02, 906, 646, 437],
[54.06, 65.22, 79.99, 110, 110, 255, 264, 905, 649, 438],
[53.94, 62.91, 80, 109.99, 110, 255, 264, 905, 647, 438],
[53.9, 62.22, 80, 109.99, 109.99, 254.99, 264, 905, 646, 438],
[53.98, 61.9, 79.99, 110, 110, 255, 263.99, 905, 644, 437],
[54.06, 63.34, 79.99, 110, 110, 255, 263.99, 905, 644, 438],
[54.06, 62.93, 80, 110, 110, 254.99, 264, 904, 643, 438],
[54.18, 62.73, 79.99, 110, 110, 255, 264, 886, 634, 434],
[54.06, 63.1, 80, 109.99, 110, 255, 263.99, 882, 628, 434],
[54.06, 63.14, 80, 110, 109.99, 254.99, 263.99, 888, 634, 435],
[54.02, 63.71, 80, 109.99, 110, 255, 264, 891, 636, 436],
[54.02, 63.77, 79.99, 110, 110, 255, 263.99, 888, 636, 435],
[53.9, 63.44, 79.99, 110, 110, 254.99, 264, 897, 638, 436],
[53.94, 63.29, 80, 110, 109.99, 255, 264.01, 894, 636, 436],
[53.86, 63.78, 80, 109.99, 109.99, 255, 263.99, 892, 635, 435],
[53.71, 65.25, 80, 109.99, 110, 254.99, 264, 872, 627, 433],
[53.63, 64.74, 80, 109.99, 110, 255, 263.99, 884, 631, 435],
[53.71, 63.58, 79.99, 110, 110, 255, 264, 881, 629, 434],
[53.63, 63.83, 80, 109.99, 110, 254.97, 264, 899, 643, 438],
[53.82, 64.27, 80, 110, 110, 255, 264, 888, 634, 434],
[53.43, 63.44, 80, 110, 109.99, 254.99, 263.99, 907, 651, 438],
[53.39, 63.41, 80, 109.99, 109.99, 254.99, 264, 907, 650, 439],
[53.47, 63.63, 79.99, 110, 110, 255.06, 263.21, 905, 647, 437],
[53.35, 63.67, 79.99, 110, 109.99, 255, 264, 909, 651, 439],
[53.28, 64.07, 79.99, 110, 110, 255, 264, 900, 641, 438],
[53.08, 63.28, 80, 110, 110, 255, 264.01, 908, 647, 439],
[53.04, 62.84, 79.99, 109.99, 109.99, 254.99, 263.99, 898, 643, 437],
[53.04, 63.89, 79.99, 110, 109.99, 254.99, 264, 899, 644, 438],
[52.89, 62.81, 79.99, 110, 110, 255, 264, 905, 645, 438],
[53.12, 63.44, 79.99, 110, 110, 255, 264, 877, 627, 434],
[52.93, 63.47, 80, 110, 110, 255, 263.99, 879, 626, 434],
[52.74, 62.79, 80, 110, 110, 255, 264, 889, 631, 436],
[52.7, 62.49, 79.99, 109.99, 110, 255, 263.99, 879, 629, 433],
[52.74, 62.25, 79.99, 110, 110, 255, 264, 873, 622, 433],
[52.77, 61.73, 80, 110, 110, 255, 264, 887, 633, 436],
[52.89, 62.04, 80, 110, 110, 254.99, 263.99, 902, 641, 438],
[52.77, 63, 80, 109.99, 110, 255, 263.99, 904, 647, 438],
[52.93, 61.95, 80, 110, 110, 254.99, 263.99, 906, 650, 439],
[52.85, 62.25, 79.99, 110, 110, 255, 264, 908, 646, 439],
[52.81, 61.98, 80, 110, 110, 255.19, 262.46, 898, 642, 436],
[52.85, 62.7, 79.99, 110, 109.99, 255, 263.99, 897, 642, 437],
[52.54, 62.55, 80, 110, 110, 255, 263.99, 947, 640, 436],
[52.58, 62.02, 79.99, 110, 109.99, 254.99, 263.99, 889, 633, 435],
[52.54, 61.87, 80, 110, 110, 254.99, 264, 905, 645, 439],
[52.74, 61.76, 80, 110, 110, 254.99, 264, 905, 643, 438],
[52.47, 61.7, 80, 109.99, 110, 255, 263.99, 902, 642, 438],
[52.51, 62.36, 79.99, 109.99, 110, 255.01, 263.93, 901, 640, 437],
[52.43, 62.62, 80, 110, 110, 254.99, 263.99, 898, 645, 437],
[53.01, 63.17, 79.99, 109.99, 109.99, 255, 263.99, 898, 641, 437],
[52.74, 63.89, 80, 110, 109.99, 254.99, 264, 899, 644, 437],
[52.7, 64.33, 80, 110, 110, 255, 263.98, 899, 643, 438],
[52.7, 63.58, 80, 110, 110, 255, 263.99, 901, 645, 438],
[52.85, 62.37, 80, 110, 110, 254.99, 264, 901, 643, 438],
[52.85, 62.78, 80, 110, 110, 255, 263.99, 901, 649, 437],
[53.04, 63.9, 80, 110, 110, 255, 264, 905, 648, 438],
[52.66, 63.43, 79.99, 110, 110, 254.96, 264, 900, 644, 438],
[52.89, 63.2, 80, 110, 110, 255, 264, 900, 647, 438],
[53.12, 63.44, 79.99, 109.99, 109.99, 255, 264, 863, 621, 432],
[53.12, 62.22, 80, 110, 110, 255.03, 263.94, 865, 624, 430],
[53.12, 62.59, 80, 110, 110, 254.99, 263.98, 869, 625, 432],
[52.93, 62.71, 80, 109.99, 110, 255, 264, 896, 642, 438],
[52.85, 62.37, 79.99, 110, 109.99, 254.99, 263.99, 903, 637, 436],
[52.81, 62.44, 79.99, 109.99, 110, 254.99, 263.99, 881, 630, 435],
[52.85, 62.42, 80, 109.99, 110, 255, 263.99, 877, 626, 433],
[52.7, 61.95, 80, 110, 110, 255, 264, 888, 634, 436],
[52.51, 62.45, 79.99, 109.99, 110, 255, 264, 894, 640, 436],
[52.28, 62.52, 79.99, 109.99, 110, 255, 264, 882, 627, 435],
[52.28, 63.15, 79.99, 110, 109.99, 255, 264, 901, 642, 438],
[52.01, 62.47, 79.99, 110, 110, 254.99, 263.99, 896, 636, 438],
[52.09, 62.38, 80, 110, 109.99, 255, 263.99, 904, 640, 438],
[52.01, 61.59, 79.99, 110, 110, 254.99, 263.99, 894, 640, 436],
[52.05, 62.66, 79.99, 109.99, 109.99, 254.96, 264, 873, 622, 432],
[52.09, 62.5, 79.99, 110, 109.99, 254.99, 264, 863, 620, 432],
[52.01, 62.33, 79.99, 110, 110, 254.99, 264, 874, 623, 433],
[52.2, 61.74, 80, 110, 110, 255, 264, 885, 631, 436],
[52.05, 63.01, 79.99, 110, 109.99, 255, 263.99, 883, 628, 435],
[51.82, 62.95, 79.99, 109.99, 110, 255, 263.99, 882, 630, 435],
[51.71, 62.85, 79.99, 110, 110, 255, 264, 890, 641, 436],
[51.71, 63.01, 80, 110, 109.99, 255.06, 263.53, 906, 648, 436],
[51.75, 62.34, 79.99, 110, 110, 254.99, 263.99, 905, 644, 439],
[51.67, 62.06, 79.99, 110, 110, 255, 264, 903, 651, 438],
[51.9, 61.99, 79.99, 110, 109.99, 255, 263.99, 898, 645, 438],
[52.01, 62.56, 80, 110, 110, 254.99, 264, 904, 648, 439],
[52.24, 62.58, 79.99, 110, 110, 254.99, 263.99, 898, 642, 438],
[52.2, 62.75, 79.99, 109.99, 110, 255, 263.99, 898, 642, 437],
[52.24, 62.58, 79.99, 110, 110, 254.99, 264, 895, 640, 436],
[52.35, 61.79, 79.99, 110, 110, 254.99, 263.99, 899, 642, 437],
[52.7, 61.58, 79.99, 109.99, 110, 255, 263.99, 898, 643, 437],
[52.43, 62.56, 79.99, 109.99, 110, 254.99, 264.01, 903, 642, 437],
[52.51, 63.18, 79.99, 109.99, 109.99, 254.99, 263.99, 907, 650, 438],
[52.66, 62.68, 80, 110, 109.99, 254.99, 264, 895, 639, 437],
[52.7, 62.6, 79.99, 110, 110, 254.99, 264, 885, 633, 435],
[53.04, 63.15, 79.99, 110, 110, 255, 263.99, 882, 631, 434],
[53.01, 62.56, 79.99, 110, 110, 254.99, 263.99, 889, 635, 436],
[52.97, 62.4, 80, 109.99, 110, 254.99, 264, 882, 629, 435],
[52.89, 62.16, 80, 110, 110, 255, 264, 886, 634, 435],
[53.04, 61.82, 80, 110, 110, 254.99, 264, 900, 643, 438],
[52.97, 62.08, 79.99, 110, 110, 255, 264, 905, 646, 438],
[53.12, 61.97, 80, 110, 110, 254.99, 264, 901, 642, 438],
[53.24, 62.14, 79.99, 110, 109.99, 255, 264, 897, 642, 437],
[53.24, 62.35, 80, 110, 110, 255, 263.99, 901, 641, 437],
[53.39, 62.89, 80, 110, 110, 255.02, 263.53, 901, 644, 436],
[53.43, 63.23, 80, 110, 109.99, 254.99, 264, 899, 643, 437],
[53.86, 63.01, 80, 110, 110, 255, 264, 905, 645, 438],
[53.74, 63.67, 79.99, 110, 109.99, 255, 263.98, 905, 643, 437],
[53.86, 63.31, 80, 109.99, 109.99, 255, 264, 899, 644, 437],
[54.06, 62.33, 80, 110, 109.99, 255, 263.98, 891, 638, 436],
[54.22, 62.95, 80, 109.99, 109.99, 254.99, 264, 881, 628, 434],
[54.42, 62.44, 79.99, 110, 109.99, 254.99, 264, 896, 640, 436],
[54.46, 63.04, 79.99, 110, 110, 254.99, 263.99, 899, 642, 438],
[54.5, 63.23, 79.99, 110, 110, 254.99, 264, 886, 630, 436],
[54.73, 63.36, 80, 109.99, 110, 255, 264, 890, 633, 436],
[54.57, 62.31, 79.99, 109.99, 110, 255, 263.99, 889, 637, 435],
[56.53, 62.35, 79.99, 109.99, 110, 255.01, 264.43, 863, 615, 428],
[56.74, 62.42, 79.99, 109.99, 109.99, 254.99, 264, 865, 616, 430],
[56.57, 63.19, 79.99, 110, 109.99, 255, 264.01, 866, 618, 430],
[56.7, 63.07, 79.99, 110, 110, 255, 263.99, 861, 617, 429],
[56.78, 63.41, 80, 109.99, 109.99, 254.98, 288.75, 879, 629, 433],
[56.9, 63.98, 79.99, 110, 110, 254.99, 263.99, 889, 635, 435],
[56.99, 63.92, 79.99, 110, 110, 255, 264, 899, 639, 436],
[57.58, 62.04, 79.99, 109.99, 110, 254.97, 264, 866, 619, 430],
[57.33, 62.23, 80, 110, 109.99, 255, 263.99, 873, 623, 431],
[56.4, 62.75, 80, 110, 110, 255, 263.99, 897, 641, 436],
[56.32, 62.71, 79.99, 110, 109.99, 255, 263.99, 899, 641, 436],
[56.36, 62.87, 79.99, 109.99, 110, 255, 264, 898, 640, 436],
[56.36, 62.64, 79.99, 110, 110, 255, 264, 895, 639, 435],
[56.28, 63.14, 79.99, 110, 110, 255, 264, 884, 629, 434],
[56.03, 63.41, 79.99, 110, 110, 255, 263.99, 890, 633, 435],
[55.95, 62.18, 80, 110, 110, 254.99, 264, 889, 634, 435],
[55.91, 63.48, 80, 110, 110, 254.99, 263.99, 902, 642, 437],
[55.95, 64.32, 79.99, 110, 110, 254.99, 264, 901, 643, 436],
[56.2, 64.61, 79.99, 109.99, 110, 255, 264, 903, 646, 437],
[56.12, 64.25, 79.99, 109.99, 110, 255, 264, 906, 643, 437],
[55.95, 63.33, 79.99, 109.99, 110, 255, 263.99, 902, 643, 437],
[55.91, 63.48, 80, 109.99, 110, 255, 263.99, 904, 648, 437],
[55.87, 64.18, 80, 110, 110, 255, 264, 906, 647, 437],
[56.2, 63.66, 80, 110, 109.99, 255, 263.99, 898, 639, 436],
[55.87, 63.1, 80, 109.99, 109.99, 255, 264, 895, 637, 435],
[56.07, 62.92, 79.99, 109.99, 109.99, 255, 263.99, 880, 630, 433],
[56.03, 63.06, 79.99, 110, 109.99, 255, 263.99, 879, 631, 434],
[55.95, 63.89, 79.99, 110, 110, 255, 264, 885, 632, 434],
[55.99, 63.78, 79.99, 110, 110, 254.97, 264, 894, 635, 435],
[56.07, 63.48, 80, 109.99, 110, 255, 263.99, 891, 637, 436],
[55.95, 63.38, 79.99, 110, 110, 254.99, 264, 895, 639, 435],
[55.99, 62.8, 79.99, 109.99, 110, 255, 263.99, 896, 637, 436],
[56.03, 63.69, 79.99, 109.99, 110, 255, 263.99, 891, 635, 435],
[56.2, 63.81, 80, 110, 110, 254.99, 264, 892, 635, 435],
[55.91, 64.57, 79.99, 110, 110, 255, 264, 879, 627, 433],
[56.16, 64.45, 80, 110, 109.99, 254.98, 264, 884, 622, 430],
[55.91, 63.83, 80, 110, 110, 255, 263.99, 876, 625, 432],
[55.95, 62.97, 79.99, 110, 110, 254.99, 263.99, 886, 634, 433],
[55.91, 62.9, 80, 110, 109.99, 254.99, 264, 885, 633, 434],
[55.62, 63.86, 80, 110, 109.99, 255, 263.99, 876, 621, 432],
[56.24, 62.03, 80, 110, 110, 255, 263.99, 881, 629, 432],
[56.03, 62.31, 79.99, 110, 110, 254.99, 264, 893, 638, 435],
[56.07, 62.45, 80, 110, 110, 255, 263.99, 889, 635, 434],
[56.2, 63.1, 80, 110, 110, 255, 264.01, 890, 631, 434],
[56.07, 63.41, 79.99, 110, 110, 255, 263.99, 899, 641, 436],
[55.91, 63.65, 80, 110, 109.99, 255, 264, 896, 641, 436],
[55.99, 64.93, 80, 110, 109.99, 254.99, 264, 874, 623, 431],
[55.66, 64.38, 80, 110, 109.99, 254.99, 264, 872, 621, 431],
[55.7, 63.9, 79.99, 109.99, 110, 254.99, 263.99, 868, 622, 431],
[55.66, 63.43, 79.99, 110, 110, 255, 264, 866, 616, 430],
[55.42, 62.64, 80, 110, 110, 254.99, 264, 862, 615, 429],
[55.83, 63.46, 79.99, 109.99, 110, 255, 264, 862, 621, 430],
[55.79, 63.08, 79.99, 110, 110, 255, 263.99, 863, 616, 428],
[56.2, 62.44, 79.99, 110, 110, 255, 264, 868, 620, 430],
[55.99, 63.08, 79.99, 109.99, 110, 254.99, 264, 865, 619, 429],
[55.79, 63.34, 80, 110, 110, 255, 263.99, 865, 619, 429],
[55.87, 63.2, 80, 110, 110, 254.98, 263.99, 863, 618, 428],
[55.95, 63.14, 80, 110, 110, 254.99, 263.99, 862, 614, 429],
[56.49, 64.25, 79.99, 109.99, 110, 255, 263.99, 869, 620, 430],
[56.24, 63.62, 80, 110, 110, 255, 264, 873, 623, 431],
[56.24, 63.08, 79.99, 110, 110, 254.99, 263.99, 890, 636, 435],
[56.49, 63.61, 79.99, 110, 109.99, 255, 263.99, 886, 631, 435],
[60.8, 62.27, 80, 109.99, 110, 255, 264, 896, 632, 433],
[60.93, 62.34, 79.99, 110, 110, 255, 263.99, 894, 632, 433],
[60.21, 60.62, 80, 110, 109.99, 255, 264, 888, 630, 433],
[60.07, 60.74, 80, 110, 110, 254.99, 264, 889, 631, 434],
[60.21, 61.17, 80, 110, 110, 255, 264, 891, 630, 433],
[60.25, 62.11, 80, 110, 110, 255, 263.99, 907, 641, 435],
[60.34, 62.3, 80, 110, 110, 255, 263.99, 904, 641, 435],
[60.34, 62.75, 79.99, 110, 110, 254.99, 264, 913, 643, 436],
[59.98, 63.19, 80, 110, 110, 255, 264, 910, 642, 436],
[59.94, 62.73, 80, 110, 110, 254.99, 264, 898, 637, 435],
[60.07, 62.59, 79.99, 109.99, 110, 255, 263.98, 916, 634, 433],
[60.03, 63.41, 80, 109.99, 110, 255, 263.99, 896, 634, 434],
[59.94, 63.61, 79.99, 109.99, 110, 254.99, 264.01, 892, 631, 433],
[59.89, 63.03, 80, 110, 110, 255, 264, 899, 636, 434],
[59.4, 61.93, 79.99, 110, 110, 255, 264, 902, 641, 436],
[59.62, 62.44, 80, 110, 110, 255, 264, 892, 637, 433],
[59.54, 62.43, 79.99, 110, 110, 255, 264, 903, 640, 435],
[59.27, 62.5, 80, 110, 109.99, 255, 263.99, 898, 640, 435],
[59.18, 63, 80, 110, 109.99, 254.99, 263.99, 890, 632, 433],
[58.83, 64.33, 80, 109.99, 110, 255, 264, 908, 644, 436],
[59.05, 65.92, 79.99, 109.99, 110, 255, 264, 906, 640, 433],
[58.7, 65.46, 80, 110, 110, 254.99, 264, 911, 645, 436],
[58.61, 64.44, 80, 110, 109.99, 254.99, 264, 889, 634, 433],
[58.57, 63.75, 79.99, 110, 110, 255, 264, 902, 640, 435],
[58.53, 63.15, 79.99, 110, 110, 255, 263.99, 891, 637, 433],
[58.57, 63.18, 80, 110, 110, 254.99, 264, 885, 633, 433],
[58.66, 62.98, 80, 110, 110, 254.99, 264, 895, 636, 435],
[58.57, 64.54, 79.99, 110, 110, 255, 263.99, 897, 638, 435],
[56.7, 63.52, 80, 110, 109.99, 254.99, 264, 908, 648, 437],
[56.82, 63.53, 79.99, 110, 109.99, 254.99, 263.99, 907, 644, 438],
[56.95, 63.6, 79.99, 109.99, 110, 254.99, 263.99, 889, 637, 433],
[56.95, 64.34, 79.99, 109.99, 110, 255, 264, 890, 635, 434],
[56.86, 63.7, 80, 110, 110, 255, 264, 891, 636, 434],
[56.7, 63.83, 80, 110, 110, 254.99, 263.99, 899, 644, 436],
[56.57, 64.11, 80, 110, 110, 255, 263.99, 892, 637, 434],
[56.32, 62.32, 79.99, 110, 110, 254.98, 264, 891, 634, 433],
[56.61, 62.49, 79.99, 109.99, 110, 255, 264, 890, 635, 434],
[56.36, 62.58, 80, 110, 110, 254.99, 264, 882, 633, 433],
[56.36, 62.71, 80, 109.99, 110, 255, 264, 903, 645, 436],
[56.57, 63.13, 79.99, 110, 110, 254.99, 264, 904, 641, 436],
[56.74, 62.62, 79.99, 110, 110, 255, 264, 900, 639, 435],
[56.74, 62.85, 80, 110, 110, 254.99, 263.97, 911, 648, 437],
[56.86, 63.83, 79.99, 110, 110, 255, 263.99, 896, 640, 436],
[57.11, 63.38, 79.99, 110, 109.99, 255, 264, 898, 637, 435],
[56.99, 63.41, 79.99, 110, 110, 254.99, 263.99, 891, 639, 434],
[56.9, 63.5, 79.99, 109.99, 110, 255, 264, 874, 623, 430],
[57.11, 63.6, 80, 110, 110, 254.99, 263.99, 889, 634, 432],
[57.16, 63.71, 79.99, 110, 110, 254.99, 263.99, 882, 628, 432],
[57.75, 63.28, 79.99, 109.99, 110, 254.99, 264, 878, 625, 432],
[57.84, 62.97, 80, 110, 110, 254.99, 264, 889, 632, 433],
[57.88, 62.78, 79.99, 110, 110, 254.99, 263.99, 893, 633, 434],
[58.18, 63.01, 79.99, 110, 110, 254.99, 264, 887, 631, 432],
[58.22, 62.76, 79.99, 110, 110, 255, 264, 883, 628, 432],
[58.14, 62.38, 79.99, 110, 109.99, 254.99, 264, 884, 629, 432],
[58.18, 62.65, 79.99, 110, 110, 255, 264, 891, 633, 433],
[58.27, 62.76, 80, 109.99, 110, 255, 263.99, 890, 634, 434],
[58.31, 62.88, 79.99, 110, 109.99, 255, 264.01, 882, 629, 432],
[58.35, 63.31, 79.99, 110, 110, 255, 264, 883, 629, 432],
[58.35, 63.24, 79.99, 109.99, 110, 255, 264, 893, 635, 434],
[59.05, 63.67, 79.99, 109.99, 110, 255, 263.99, 872, 622, 430],
[59.54, 63.3, 80, 109.99, 110, 254.99, 264, 899, 641, 436],
[59.76, 63.47, 79.99, 110, 109.99, 255, 264, 904, 643, 436],
[59.67, 63.45, 80, 110, 110, 254.99, 263.6, 893, 634, 433],
[59.27, 63.11, 79.99, 109.99, 109.99, 254.99, 264, 886, 634, 433],
[59.85, 64.7, 79.99, 110, 110, 254.99, 264, 899, 639, 434],
[59.94, 64.48, 80, 109.99, 110, 255, 263.99, 896, 637, 434],
[59.94, 64.1, 80, 109.99, 110, 255, 264, 903, 640, 435],
[59.98, 63.33, 80, 109.99, 110, 254.99, 263.99, 899, 639, 434],
[59.8, 64.29, 80, 110, 110, 255.03, 263.94, 910, 644, 435],
[59.71, 64.42, 79.99, 110, 110, 254.99, 264, 907, 644, 436],
[58.87, 64.29, 80, 110, 110, 254.99, 264, 887, 631, 432],
[58.83, 64.79, 79.99, 109.99, 110, 255, 264, 881, 627, 432],
[58.57, 64.21, 79.99, 110, 110, 255, 264, 903, 643, 435],
[58.48, 64.81, 79.99, 110, 110, 255, 264, 903, 643, 435],
[57.67, 64.6, 80, 110, 110, 254.99, 263.99, 905, 646, 436],
[57.84, 63.47, 80, 109.99, 110, 255, 263.99, 909, 647, 437],
[57.96, 62.61, 80, 110, 109.99, 255, 263.98, 909, 645, 437],
[57.07, 62.42, 80, 109.99, 109.99, 254.99, 263.99, 911, 651, 438],
[56.86, 63.1, 80, 110, 110, 254.99, 263.99, 913, 650, 438],
[56.82, 63.5, 80, 110, 110, 254.99, 264, 902, 644, 436],
[56.82, 63.31, 79.99, 110, 110, 255, 263.99, 900, 645, 436],
[56.74, 63.22, 79.99, 110, 110, 255, 264, 872, 623, 430],
[56.82, 63.33, 80, 110, 110, 255, 264, 865, 622, 429],
[56.86, 62.91, 79.99, 109.99, 110, 254.99, 263.99, 868, 622, 430],
[57.11, 62.11, 79.99, 110, 110, 254.99, 264, 890, 636, 433],
[57.24, 61.96, 80, 110, 110, 255, 264, 890, 634, 434],
[57.07, 63.23, 79.99, 110, 109.99, 254.99, 263.99, 871, 624, 430],
[57.45, 62.87, 79.99, 110, 110, 255.01, 263.79, 875, 625, 430],
[57.16, 62.66, 79.99, 109.99, 110, 255, 263.99, 890, 635, 434],
[57.24, 61.96, 80, 110, 110, 255, 263.99, 886, 630, 432],
[57.2, 62.18, 79.99, 110, 110, 255, 264, 885, 631, 432],
[57.54, 64.83, 80, 109.99, 110, 254.99, 264, 884, 632, 432],
[57.28, 63.18, 79.99, 110, 109.99, 254.99, 264.01, 898, 643, 435],
[57.45, 63.32, 80, 110, 110, 255, 263.99, 891, 634, 434],
[57.24, 63.25, 79.99, 110, 110, 255.01, 264.34, 906, 634, 432],
[57.2, 63.01, 79.99, 110, 109.99, 254.99, 264, 877, 627, 431],
[57.37, 63.11, 79.99, 110, 110, 254.99, 263.99, 903, 643, 436],
[57.54, 63.46, 79.99, 109.99, 110, 255, 264, 898, 642, 437],
[58.7, 63.02, 80, 109.99, 110, 255, 264, 906, 645, 435],
[58.61, 63.1, 80, 110, 109.99, 254.99, 263.99, 892, 637, 434],
[59.05, 62.38, 80, 110, 110, 255, 263.99, 916, 654, 437],
[58.79, 62.25, 80, 110, 110, 255, 264, 906, 643, 436],
[59.01, 61.58, 79.99, 110, 110, 254.99, 264, 903, 642, 435],
[58.83, 61.7, 79.99, 110, 110, 255, 264, 905, 644, 436],
[57.41, 63.75, 80, 110, 110, 255, 264, 905, 644, 436],
[57.62, 63.61, 80, 110, 110, 254.99, 264, 901, 645, 436],
[57.28, 64, 80, 109.99, 110, 255, 263.99, 897, 639, 434],
[57.28, 63.82, 79.99, 110, 110, 255, 263.99, 904, 644, 437],
[57.24, 62.96, 80, 110, 110, 254.99, 263.99, 905, 647, 436],
[57.24, 62.4, 79.99, 110, 110, 255, 263.93, 927, 651, 437],
[56.9, 61.88, 80, 110, 110, 254.99, 264, 913, 650, 437],
[57.33, 62.5, 80, 109.99, 110, 255, 264, 913, 650, 438],
[56.86, 62.74, 80, 110, 110, 254.99, 264, 912, 652, 437],
[56.9, 63.08, 79.99, 110, 110, 254.99, 263.98, 913, 649, 437],
[56.95, 63.08, 80, 110, 109.99, 255, 264.01, 912, 649, 437],
[57.24, 62.41, 80, 109.99, 109.99, 255, 264, 912, 649, 437],
[57.28, 64.27, 79.99, 109.99, 110, 254.99, 264, 903, 644, 436],
[57.11, 64.39, 80, 110, 109.99, 254.99, 263.99, 910, 647, 437],
[57.11, 63.87, 80, 110, 110, 254.99, 264, 911, 650, 437],
[57.11, 62.9, 80, 110, 110, 254.99, 264, 915, 651, 438],
[57.11, 62.46, 80, 110, 110, 254.99, 263.99, 909, 650, 437],
[56.16, 64.29, 79.99, 109.99, 110, 255, 264, 878, 630, 432],
[56.12, 63.87, 79.99, 110, 110, 254.99, 264, 906, 649, 436],
[56.4, 63.03, 79.99, 110, 110, 254.21, 264.01, 906, 643, 436],
[56.57, 63.63, 79.99, 109.99, 110, 255, 264, 891, 639, 435],
[56.7, 63.27, 79.99, 110, 110, 254.99, 264, 891, 639, 435],
[56.78, 63.35, 80, 110, 110, 255, 264, 905, 648, 436],
[56.99, 64.27, 79.99, 110, 110, 254.99, 263.99, 902, 642, 436],
[56.9, 64.16, 80, 109.99, 110, 255, 264, 905, 645, 436],
[56.9, 63.39, 80, 110, 110, 255, 264, 891, 634, 434],
[56.24, 64.93, 79.99, 110, 109.99, 255, 263.99, 905, 648, 437],
[55.99, 64.18, 80, 109.99, 110, 255, 263.99, 897, 641, 435],
[56.07, 63.56, 79.99, 110, 110, 255, 263.99, 883, 635, 433],
[55.83, 63.17, 80, 110, 110, 254.99, 263.99, 894, 641, 435],
[55.79, 63.06, 80, 110, 110, 255, 263.99, 898, 644, 436],
[55.54, 63.83, 80, 110, 110, 255, 263.99, 911, 650, 438],
[55.42, 63.32, 80, 109.99, 110, 255, 264, 903, 644, 437],
[55.42, 62.5, 80, 110, 110, 255, 263.99, 901, 643, 436],
[55.66, 63.47, 79.99, 110, 109.99, 254.99, 263.99, 903, 646, 436],
[55.75, 63.27, 80, 110, 110, 254.99, 263.99, 913, 653, 438],
[55.99, 62, 79.99, 110, 110, 254.99, 263.99, 900, 645, 436],
[56.03, 61.89, 79.99, 109.99, 110, 254.99, 264, 896, 641, 434],
[56.03, 62.1, 79.99, 110, 110, 255, 264, 901, 646, 436],
[56.03, 62.32, 80, 109.99, 110, 255, 263.99, 895, 642, 435],
[55.87, 65.2, 80, 110, 110, 254.99, 264, 882, 636, 434],
[55.42, 63.83, 80, 110, 110, 254.99, 263.99, 902, 643, 436],
[55.46, 63.4, 80, 110, 109.99, 255, 263.99, 886, 634, 434],
[55.42, 63.61, 80, 110, 110, 254.99, 264, 900, 645, 436],
[55.5, 63.28, 80, 110, 110, 254.99, 264, 898, 643, 436],
[55.5, 63.43, 79.99, 110, 110, 254.99, 264, 887, 638, 435],
[55.58, 63.49, 80, 110, 110, 255, 264, 905, 647, 436],
[55.58, 63.79, 79.99, 110, 110, 255, 263.99, 903, 649, 436],
[55.83, 63.36, 80, 110, 109.99, 255, 263.99, 914, 655, 438],
[55.66, 63.34, 79.99, 110, 110, 254.99, 263.99, 904, 651, 436],
[55.5, 62.54, 79.99, 110, 110, 255, 263.99, 897, 643, 436],
[55.34, 62.55, 80, 110, 110, 255, 263.99, 895, 639, 436],
[55.22, 62.35, 80, 110, 109.99, 255, 263.99, 897, 641, 434],
[55.22, 62.07, 79.99, 110, 110, 255, 263.99, 901, 649, 437],
[55.62, 62.86, 79.99, 109.99, 109.99, 255, 264, 905, 648, 437],
[55.38, 62.54, 79.99, 109.99, 109.99, 254.99, 264, 903, 649, 437],
[55.42, 63.01, 80, 110, 110, 254.99, 263.99, 909, 654, 438],
[55.54, 63.05, 80, 110, 110, 254.99, 263.99, 907, 652, 438],
[55.06, 63.59, 79.99, 109.99, 110, 254.99, 263.99, 912, 654, 439],
[54.98, 62.87, 79.99, 110, 110, 255, 264, 907, 652, 439],
[55.3, 62.4, 79.99, 109.99, 110, 255, 263.99, 909, 651, 438],
[55.02, 62.38, 79.99, 110, 110, 254.99, 263.99, 905, 652, 438],
[54.98, 62.14, 80, 110, 110, 254.98, 264, 905, 649, 437],
[55.02, 62.82, 80, 110, 110, 254.99, 263.99, 901, 648, 437],
[55.3, 64.12, 79.99, 110, 110, 254.99, 263.99, 904, 650, 438],
[54.98, 63.88, 80, 110, 110, 254.99, 264, 905, 649, 438],
[54.69, 63.57, 79.99, 110, 110, 254.99, 263.99, 905, 651, 437],
[55.02, 63.97, 80, 110, 110, 254.99, 263.95, 903, 650, 437],
[55.1, 63.68, 80, 110, 110, 255, 264, 890, 641, 435],
[55.22, 63.51, 79.99, 110, 109.99, 255, 263.99, 891, 641, 436],
[55.34, 62.85, 80, 109.99, 110, 254.99, 264, 901, 649, 437],
[55.42, 63.23, 80, 110, 109.99, 255, 263.99, 904, 649, 437],
[55.34, 62.64, 79.99, 110, 109.99, 255, 263.99, 898, 648, 436],
[55.66, 62.64, 79.99, 109.99, 110, 254.99, 263.99, 879, 634, 433],
[58.87, 63.15, 80, 110, 110, 255, 264.01, 902, 642, 435],
[58.92, 63.31, 79.99, 109.99, 110, 254.99, 264, 889, 635, 434],
[59.05, 63.74, 80, 110, 110, 255, 264, 881, 629, 431],
[59.01, 62.91, 80, 110, 110, 254.99, 264, 879, 630, 431],
[58.96, 63.26, 80, 110, 110, 255, 264, 882, 631, 432],
[59.05, 63.1, 79.99, 109.99, 110, 254.99, 264, 890, 637, 433],
[59.01, 63.38, 80, 109.99, 110, 254.99, 263.99, 891, 638, 433],
[58.92, 64.33, 79.99, 110, 109.99, 255, 264, 903, 644, 436],
[59.05, 63.9, 79.99, 110, 110, 254.99, 263.99, 892, 633, 431],
[58.7, 63.33, 80, 109.99, 109.99, 254.99, 264, 894, 634, 433],
[58.74, 63.44, 79.99, 110, 110, 255, 264, 882, 628, 432],
[58.83, 63.17, 79.99, 110, 109.99, 255, 264, 908, 646, 436],
[58.57, 62.58, 79.99, 109.99, 110, 255, 263.99, 896, 636, 434],
[58.35, 62.61, 79.99, 110, 110, 254.99, 263.99, 882, 629, 431],
[58.27, 63.21, 80, 110, 109.99, 254.99, 264, 909, 649, 436],
[58.35, 64.41, 79.99, 110, 110, 254.99, 263.99, 905, 645, 436],
[58.18, 65.19, 80, 109.99, 109.99, 254.99, 264, 904, 641, 435],
[58.18, 64.94, 80, 110, 110, 255, 263.98, 891, 638, 434],
[58.31, 63.01, 79.99, 109.99, 109.99, 255, 264, 905, 647, 436],
[58.27, 63.29, 80, 110, 110, 254.99, 263.99, 912, 651, 437],
[56.24, 63.45, 80, 110, 109.99, 254.99, 263.99, 906, 650, 436],
[56.16, 62.99, 79.99, 110, 110, 255, 263.99, 908, 650, 437],
[56.24, 62.59, 79.99, 110, 110, 255, 264, 897, 642, 436],
[56.32, 62.91, 79.99, 110, 110, 255, 264, 891, 640, 434],
[56.32, 63.18, 80, 109.99, 110, 254.99, 263.99, 878, 632, 433],
[56.53, 64.41, 79.99, 110, 110, 254.99, 264, 897, 643, 435],
[56.45, 64.08, 80, 109.99, 110, 255, 264, 893, 642, 435],
[56.74, 63.14, 80, 110, 109.99, 255, 264, 905, 649, 435],
[56.82, 63.49, 80, 110, 109.99, 254.99, 264, 901, 646, 436],
[56.49, 63.05, 79.99, 110, 110, 255, 264, 901, 644, 436],
[56.74, 63.27, 80, 110, 110, 254.99, 264, 896, 643, 435],
[56.53, 64.53, 79.99, 109.99, 110, 254.99, 263.97, 891, 640, 433],
[56.53, 64.32, 79.99, 110, 109.99, 255, 264, 892, 637, 434],
[56.45, 64.11, 79.99, 110, 110, 255, 263.99, 899, 646, 435],
[56.36, 63.98, 79.99, 109.99, 110, 255, 263.99, 900, 647, 436],
[56.49, 63.27, 80, 110, 110, 255, 263.99, 906, 646, 436],
[56.61, 63.86, 79.99, 110, 110, 255, 263.75, 909, 645, 437],
[56.82, 63.38, 79.99, 110, 110, 254.99, 264, 904, 646, 436],
[56.78, 62.87, 79.99, 109.99, 110, 255, 263.99, 903, 645, 436],
[56.74, 63.03, 79.99, 110, 110, 254.99, 263.99, 910, 648, 437],
[57.11, 62.71, 80, 110, 110, 255, 263.99, 905, 643, 435],
[56.82, 62.69, 79.99, 110, 110, 254.99, 263.99, 909, 649, 437],
[56.86, 62.17, 80, 110, 110, 255, 264, 899, 643, 435],
[56.28, 64.38, 80, 110, 110, 254.99, 264, 869, 625, 431],
[55.99, 65.01, 80, 110, 110, 254.97, 264, 876, 628, 431],
[55.79, 64.33, 79.99, 110, 110, 255, 264, 880, 631, 432],
[55.3, 63.3, 80, 110, 109.99, 254.99, 264.01, 908, 654, 438],
[55.5, 62.71, 80, 109.99, 110, 255, 264, 898, 646, 436],
[55.38, 62.5, 79.99, 110, 110, 255, 263.99, 899, 643, 436],
[55.5, 62.43, 79.99, 110, 110, 254.99, 263.99, 890, 639, 435],
[55.66, 64.06, 80, 110, 110, 254.99, 264.01, 881, 632, 432],
[55.95, 63.89, 79.99, 109.99, 110, 255, 263.99, 874, 627, 432],
[55.87, 63.02, 79.99, 109.99, 110, 255, 264, 889, 636, 434],
[56.95, 62.7, 80, 109.99, 110, 255, 264, 871, 625, 431],
[56.9, 62.59, 79.99, 110, 110, 255, 264.01, 871, 626, 431],
[57.28, 63.28, 80, 110, 110, 255, 263.99, 885, 633, 433],
[57.2, 62.91, 80, 109.99, 110, 254.99, 264, 880, 633, 433],
[57.2, 62.98, 79.99, 110, 110, 255, 264, 886, 641, 434],
[57.37, 63.95, 79.99, 110, 110, 254.99, 264, 882, 632, 432],
[57.41, 64.55, 79.99, 110, 110, 254.99, 264, 893, 641, 435],
[57.41, 63.8, 79.99, 110, 110, 254.98, 263.99, 890, 639, 433],
[57.54, 63.45, 79.99, 109.99, 110, 254.99, 264, 886, 637, 433],
[57.62, 62.97, 80, 110, 110, 255, 264, 879, 628, 432],
[57.67, 62.76, 80, 109.99, 109.99, 255, 263.99, 869, 627, 431],
[57.75, 63.61, 80, 110, 109.99, 254.99, 264, 868, 621, 430],
[60.34, 62.88, 79.99, 110, 109.99, 255, 264, 885, 631, 433],
[60.34, 61.61, 79.99, 110, 110, 255, 263.99, 893, 634, 433],
[60.48, 62.33, 79.99, 110, 109.99, 254.99, 264, 889, 634, 433],
[60.34, 63.76, 80, 110, 110, 255, 263.99, 914, 649, 436],
[60.25, 63.76, 79.99, 109.99, 109.99, 255, 264, 899, 640, 435],
[59.76, 63.97, 79.99, 109.99, 110, 254.99, 264, 899, 641, 435],
[59.67, 63.22, 79.99, 109.99, 110, 255, 263.99, 896, 640, 434],
[59.54, 63.91, 80, 109.99, 110, 255.21, 264, 898, 636, 434],
[59.4, 63.77, 80, 110, 110, 255, 264, 895, 639, 435],
[59.36, 64.39, 80, 110, 110, 255, 263.99, 888, 639, 434],
[58.96, 64.58, 80, 110, 110, 255, 263.99, 893, 641, 434],
[55.87, 62.98, 79.99, 110, 110, 255, 264, 882, 633, 434],
[55.66, 62.85, 80, 110, 110, 255.32, 263.99, 893, 632, 432],
[55.75, 63.19, 79.99, 110, 110, 254.99, 264, 883, 636, 434],
[55.79, 64.25, 80, 109.99, 110, 255, 264, 896, 646, 436],
[55.66, 64.4, 79.99, 110, 110, 255, 264, 906, 647, 437],
[55.66, 63.18, 80, 110, 110, 254.99, 263.99, 905, 647, 437],
[55.5, 62.49, 79.99, 110, 110, 254.99, 264, 902, 644, 437],
[54.98, 63.29, 79.99, 110, 110, 255, 264, 895, 643, 436],
[54.94, 62.68, 79.99, 109.99, 110, 255, 263.99, 899, 647, 436],
[54.81, 62.42, 80, 110, 109.99, 254.99, 264, 890, 643, 435],
[55.02, 62.38, 79.99, 110, 110, 254.99, 264, 894, 641, 435],
[54.65, 63.23, 79.99, 110, 110, 255, 264, 897, 643, 436],
[54.94, 62.72, 79.99, 110, 110, 254.99, 263.99, 897, 644, 436],
[54.69, 62.67, 80, 110, 110, 254.99, 263.95, 900, 647, 434],
[54.65, 63.16, 80, 110, 110, 254.99, 263.99, 901, 649, 437],
[54.65, 63.04, 79.99, 110, 110, 254.99, 264, 901, 649, 437],
[54.42, 62.85, 79.99, 110, 110, 255, 263.99, 899, 651, 437],
[54.73, 62.4, 79.99, 110, 110, 254.99, 263.99, 896, 644, 435],
[54.73, 62.59, 79.99, 109.99, 110, 254.99, 264, 897, 642, 436],
[54.81, 63.23, 80, 109.99, 110, 254.97, 264, 898, 645, 436],
[54.73, 62.98, 80, 110, 110, 254.99, 264, 900, 647, 436],
[54.81, 62.78, 80, 110, 110, 255, 263.99, 899, 645, 437],
[54.61, 62.57, 79.99, 109.99, 110, 254.99, 263.99, 895, 646, 436],
[54.69, 63.46, 80, 109.99, 110, 255, 263.99, 898, 648, 437],
[54.61, 63.66, 80, 109.99, 110, 255, 263.99, 904, 645, 437],
[54.61, 63.42, 80, 110, 109.99, 255, 264, 905, 648, 437],
[54.69, 63.16, 79.99, 110, 110, 254.99, 263.98, 899, 648, 436],
[54.9, 64.15, 80, 109.99, 110, 255, 264, 899, 646, 436],
[54.65, 64.41, 79.99, 109.99, 110, 255, 264, 903, 648, 437],
[54.65, 64.32, 79.99, 110, 110, 254.99, 263.99, 899, 646, 437],
[54.69, 63.06, 80, 110, 110, 254.99, 263.99, 899, 642, 437],
[54.81, 63.76, 79.99, 110, 109.99, 254.99, 263.99, 887, 637, 435],
[54.57, 63.12, 80, 110, 110, 255, 263.99, 886, 638, 434],
[55.02, 63.21, 79.99, 110, 110, 254.99, 263.99, 894, 642, 436],
[54.85, 63.85, 79.99, 110, 110, 254.99, 264, 892, 640, 434],
[55.14, 62.91, 80, 109.99, 110, 254.99, 263.99, 894, 644, 435],
[54.81, 62.91, 80, 110, 110, 255, 264, 896, 644, 436],
[54.85, 62.24, 79.99, 109.99, 110, 255, 264, 895, 642, 436],
[54.98, 62.64, 80, 110, 110, 255, 263.99, 900, 648, 437],
[55.02, 62.58, 80, 109.99, 109.99, 255, 264, 901, 642, 436],
[54.9, 62.82, 79.99, 110, 110, 254.97, 264, 888, 638, 434],
[54.85, 62.98, 80, 109.99, 110, 254.99, 264, 891, 640, 435],
[54.81, 62.19, 79.99, 109.99, 110, 255, 263.54, 901, 644, 436],
[54.77, 62.23, 80, 110, 110, 254.99, 263.99, 897, 644, 436],
[54.9, 62.05, 79.99, 110, 109.99, 254.99, 263.99, 890, 641, 435],
[54.94, 62.9, 79.99, 110, 110, 254.99, 263.99, 883, 638, 433],
[54.77, 63.23, 79.99, 110, 110, 254.99, 264, 884, 641, 434],
[54.81, 62.82, 80, 110, 110, 254.99, 264, 885, 638, 434],
[54.9, 63.87, 79.99, 110, 110, 254.99, 263.99, 879, 636, 435],
[55.38, 63.32, 79.99, 110, 110, 255, 264, 885, 637, 435],
[55.42, 64.22, 80, 110, 110, 255, 263.99, 880, 631, 433],
[55.58, 63.01, 79.99, 110, 109.99, 255, 263.99, 869, 627, 430],
[55.79, 62.92, 79.99, 109.99, 110, 255, 264, 880, 634, 433],
[56.53, 63.06, 79.99, 109.99, 110, 254.99, 264, 865, 624, 431],
[56.7, 60.73, 79.99, 109.99, 110, 254.99, 263.99, 850, 614, 428],
[56.82, 61.29, 80, 110, 109.99, 254.99, 263.99, 865, 621, 430],
[56.9, 62.05, 79.99, 110, 110, 254.99, 263.99, 862, 622, 430],
[57.16, 63.54, 80, 110, 110, 255, 263.99, 900, 649, 437],
[57.03, 63.18, 79.99, 110, 110, 255, 263.99, 901, 649, 436],
[56.78, 62.59, 80, 110, 110, 254.99, 264, 900, 647, 437],
[56.86, 62.78, 79.99, 110, 109.99, 255, 264, 895, 644, 435],
[56.99, 62.43, 79.99, 110, 109.99, 255, 264, 894, 642, 435],
[57.11, 62.53, 80, 110, 110, 254.99, 264, 898, 643, 436],
[54.85, 62, 80, 110, 109.99, 254.99, 263.99, 899, 645, 436],
[55.54, 63.5, 80, 110, 110, 254.99, 264, 894, 642, 434],
[55.91, 63.25, 79.99, 110, 110, 255, 264, 891, 643, 434],
[55.83, 62.77, 79.99, 110, 110, 254.99, 263.99, 886, 636, 434],
[57.41, 61.67, 80, 110, 110, 255, 264, 872, 624, 431],
[57.37, 62.71, 80, 109.99, 110, 255, 264, 873, 629, 430],
[57.79, 63.33, 80, 110, 110, 255, 264, 876, 625, 431],
[58.01, 62.89, 80, 110, 109.99, 254.99, 263.99, 880, 628, 432],
[57.84, 62.88, 80, 110, 110, 254.99, 264, 888, 634, 433],
[57.84, 62.5, 80, 110, 110, 254.99, 264, 880, 631, 432],
[58.27, 62.72, 80, 110, 110, 255, 264, 901, 644, 436],
[58.09, 62.31, 80, 109.99, 109.99, 255, 264, 897, 640, 435],
[58.05, 62.73, 79.99, 110, 110, 255, 263.99, 896, 641, 435],
[58.09, 63.31, 80, 109.99, 110, 255.02, 263.99, 903, 634, 432],
[58.05, 63.94, 80, 110, 109.99, 254.99, 264, 883, 631, 432],
[58.35, 64.07, 79.99, 110, 110, 255, 263.99, 889, 638, 433],
[57.92, 63.58, 80, 110, 110, 254.99, 264, 891, 636, 434],
[57.96, 63.8, 80, 110, 110, 254.99, 264, 892, 634, 434],
[58.01, 62.75, 79.99, 110, 109.99, 254.99, 264, 897, 641, 435],
[58.01, 62.89, 80, 109.99, 110, 254.99, 264, 903, 644, 436],
[57.88, 64.37, 80, 110, 110, 254.99, 264, 901, 641, 435],
[57.96, 64.56, 80, 109.99, 110, 254.99, 263.99, 900, 641, 435],
[58.22, 63.56, 79.99, 109.99, 110, 254.99, 263.99, 898, 639, 435],
[58.18, 63.52, 80, 110, 109.99, 254.99, 263.99, 902, 644, 436],
[57.92, 63.4, 80, 110, 110, 255, 264, 900, 641, 435],
[57.84, 62.93, 80, 109.99, 109.99, 254.99, 263.99, 890, 637, 434],
[57.45, 63.65, 79.99, 110, 110, 255.03, 263.68, 897, 640, 433],
[57.11, 63.96, 80, 110, 109.99, 255, 263.98, 902, 644, 435],
[57.28, 63.09, 80, 110, 110, 254.99, 263.99, 898, 644, 435],
[57.07, 63.84, 80, 110, 109.99, 255, 263.99, 897, 642, 434],
[57.16, 64.01, 79.99, 110, 110, 255, 264, 899, 640, 435],
[57.2, 61.76, 80, 110, 110, 255, 263.99, 875, 624, 430],
[57.41, 60.84, 79.99, 110, 110, 255, 263.99, 866, 620, 430],
[57.41, 60.85, 80, 110, 110, 255, 264, 865, 618, 429],
[57.2, 61.04, 80, 110, 110, 255, 264, 877, 626, 430],
[57.37, 62.8, 79.99, 110, 110, 254.99, 263.99, 889, 634, 432],
[57.45, 63.35, 80, 110, 109.99, 254.99, 263.99, 893, 636, 434],
[57.54, 63.26, 80, 109.99, 110, 255, 264, 895, 640, 434],
[60.48, 63.91, 80, 110, 110, 255, 264, 868, 620, 428],
[60.25, 63.85, 79.99, 110, 110, 254.99, 264, 862, 614, 427],
[60.25, 63.41, 79.99, 110, 109.99, 254.99, 263.99, 870, 625, 429],
[60.3, 63.4, 80, 110, 110, 254.99, 264, 871, 622, 428],
[60.25, 63.04, 80, 110, 109.99, 254.99, 264, 870, 622, 429],
[60.34, 63.26, 79.99, 109.99, 110, 254.99, 264, 908, 633, 433],
[60.61, 63.1, 80, 110, 110, 254.99, 263.99, 888, 629, 432],
[59.62, 64.18, 79.99, 110, 110, 255, 264, 894, 641, 434],
[59.76, 65.38, 80, 109.99, 109.99, 255, 263.99, 898, 641, 434],
[59.67, 65.05, 79.99, 109.99, 110, 254.99, 264, 894, 638, 433],
[59.45, 63.16, 79.99, 110, 109.99, 254.99, 263.99, 904, 643, 435],
[59.45, 62.96, 79.99, 110, 109.99, 255, 263.99, 897, 643, 434],
[59.23, 62.96, 80, 110, 110, 254.99, 263.99, 872, 621, 429],
[59.14, 62.71, 79.99, 110, 110, 255, 264, 888, 634, 432],
[58.01, 62.29, 80, 110, 110, 255, 263.98, 921, 624, 429],
[58.05, 62.74, 80, 110, 110, 254.99, 264, 882, 630, 433],
[57.84, 63.26, 80, 110, 110, 254.99, 264, 889, 634, 433],
[57.92, 63.35, 79.99, 109.99, 110, 254.99, 264, 877, 627, 432],
[57.88, 63.71, 80, 110, 109.99, 255, 263.99, 877, 628, 432],
[56.99, 62.54, 79.99, 110, 110, 254.99, 264, 896, 640, 434],
[56.95, 62.43, 80, 110, 110, 255, 264, 891, 639, 433],
[57.11, 62.55, 80, 110, 110, 255, 264, 891, 639, 434],
[56.99, 62.98, 80, 110, 110, 255, 264, 894, 638, 433],
[56.9, 62.95, 79.99, 110, 110, 254.99, 264, 888, 638, 433],
[56.07, 62.04, 80, 110, 110, 254.99, 263.99, 891, 637, 434],
[56.16, 62.11, 79.99, 110, 109.99, 254.99, 263.99, 889, 639, 433],
[56.16, 62.19, 79.99, 110, 110, 254.99, 263.99, 894, 640, 434],
[56.16, 62.59, 80, 110, 110, 255, 264, 892, 638, 434],
[56.45, 62.53, 79.99, 110, 110, 254.99, 263.99, 896, 641, 434],
[56.61, 62.72, 80, 110, 110, 254.99, 263.99, 899, 641, 435],
[56.57, 62.46, 80, 110, 109.99, 255, 264, 897, 644, 435],
[56.74, 62.55, 79.99, 110, 110, 254.99, 263.99, 898, 642, 435],
[56.53, 62.95, 80, 109.99, 110, 254.99, 264, 895, 639, 435],
[56.61, 63.97, 80, 109.99, 110, 255, 262.47, 901, 645, 435],
[56.4, 63.97, 80, 110, 110, 254.99, 264, 898, 644, 434],
[56.45, 64.64, 79.99, 110, 110, 254.99, 263.99, 896, 644, 434],
[56.45, 64.22, 79.99, 110, 110, 255, 264.01, 894, 644, 434],
[56.4, 63.98, 80, 109.99, 110, 255, 264, 894, 642, 434],
[56.36, 63.9, 80, 109.99, 110, 254.99, 264, 895, 639, 434],
[55.87, 63.26, 80, 110, 110, 254.99, 264, 889, 641, 434],
[55.83, 63.5, 79.99, 109.99, 110, 255, 263.99, 886, 637, 434],
[55.79, 63.31, 80, 110, 110, 255, 264, 873, 629, 432],
[55.79, 63.38, 80, 110, 110, 254.98, 263.99, 895, 642, 434],
[55.7, 64.03, 79.99, 110, 110, 255, 264, 897, 642, 436],
[56.32, 62.35, 80, 109.99, 110, 254.99, 264, 900, 645, 435],
[56.07, 62.97, 80, 109.99, 110, 254.99, 263.99, 902, 643, 435],
[56.03, 63.55, 80, 110, 109.99, 255, 263.99, 899, 644, 436],
[56.07, 63.45, 80, 110, 110, 255, 264, 893, 641, 434],
[56.12, 63.63, 80, 109.99, 110, 254.99, 263.99, 887, 636, 432],
[56.16, 63.49, 79.99, 110, 110, 254.99, 264, 889, 641, 433],
[55.99, 63.71, 80, 110, 110, 255, 263.99, 880, 633, 433],
[56.2, 63.66, 80, 110, 110, 255, 263.99, 882, 632, 432],
[56.28, 64.11, 80, 110, 110, 255, 264, 878, 634, 432],
[56.32, 64.07, 80, 110, 110, 254.99, 264, 862, 620, 429],
[56.4, 63.83, 79.99, 110, 110, 255, 264, 869, 624, 429],
[56.9, 62.7, 80, 110, 110, 255, 264, 889, 635, 434],
[56.74, 62.74, 79.99, 110, 110, 254.99, 264, 889, 636, 433],
[57.2, 62.54, 79.99, 110, 110, 254.99, 264, 893, 640, 434],
[57.03, 63.1, 79.99, 110, 109.99, 255, 264, 876, 625, 431],
[57.03, 63.44, 79.99, 110, 110, 255, 263.99, 885, 633, 432],
[57.07, 62.91, 79.99, 109.99, 110, 255, 264, 885, 636, 433],
[57.2, 62.35, 80, 110, 110, 254.99, 263.99, 899, 642, 435],
[57.54, 62.8, 80, 109.99, 109.99, 254.99, 264, 897, 641, 435],
[57.37, 62.01, 79.99, 110, 110, 254.99, 263.99, 903, 650, 436],
[57.5, 62.29, 79.99, 109.99, 110, 254.99, 263.99, 890, 638, 432],
[57.54, 62.34, 80, 110, 110, 255, 263.99, 898, 645, 435],
[57.71, 63.63, 79.99, 109.99, 110, 255, 264, 898, 647, 435],
[57.96, 63.37, 79.99, 110, 110, 255, 263.99, 892, 639, 434],
[57.84, 63.63, 80, 110, 110, 254.99, 264.01, 904, 649, 436],
[57.88, 63.64, 79.99, 110, 110, 255, 264, 894, 641, 434],
[59.49, 63.17, 79.99, 110, 110, 254.99, 264, 882, 632, 432],
[59.49, 62.57, 80, 109.99, 109.99, 254.99, 264, 879, 630, 432],
[60.12, 62.9, 80, 110, 110, 255, 263.99, 892, 641, 433],
[59.94, 63.34, 79.99, 110, 110, 254.99, 263.99, 886, 635, 431],
[60.89, 64.06, 80, 110, 110, 255, 264, 892, 635, 433],
[60.8, 64, 79.99, 110, 110, 255, 264, 885, 631, 432],
[60.8, 64.27, 80, 110, 110, 254.99, 263.99, 877, 629, 431],
[60.75, 64.64, 80, 109.99, 110, 255, 264, 869, 625, 429],
[60.84, 64.54, 79.99, 109.99, 110, 255, 263.99, 860, 618, 427],
[60.7, 63.91, 80, 109.99, 110, 254.99, 264, 870, 625, 428],
[60.89, 63.79, 80, 109.99, 110, 254.99, 263.99, 880, 628, 431],
[60.43, 64.27, 80, 109.99, 109.99, 255, 264, 866, 619, 428],
[60.43, 64.31, 80, 109.99, 110, 254.99, 263.99, 883, 634, 431],
[60.43, 64.71, 80, 109.99, 110, 255, 263.99, 882, 631, 432],
[60.03, 63.03, 80, 109.99, 109.99, 255, 263.82, 859, 615, 426],
[60.34, 62.96, 80, 109.99, 110, 255, 263.99, 852, 611, 425],
[60.16, 62.75, 79.99, 109.99, 110, 254.99, 263.99, 856, 614, 426],
[60.39, 62.19, 79.99, 110, 109.99, 255, 263.99, 854, 610, 425],
[60.25, 62.26, 80, 110, 109.99, 255, 263.99, 858, 613, 426],
[59.36, 64.28, 80, 110, 110, 254.99, 264, 892, 636, 431],
[59.27, 64.03, 80, 110, 110, 254.99, 264, 891, 634, 433],
[58.35, 63.37, 79.99, 110, 109.99, 254.99, 263.99, 883, 633, 432],
[58.4, 63.89, 80, 110, 110, 255, 263.99, 877, 628, 430],
[58.31, 63.44, 79.99, 110, 110, 255.01, 263.67, 902, 632, 432],
[58.27, 62.98, 79.99, 109.99, 110, 254.99, 264, 887, 632, 432],
[57.33, 62.99, 79.99, 110, 109.99, 255, 263.94, 918, 641, 434],
[57.11, 63.07, 79.99, 110, 109.99, 255, 264, 894, 642, 434],
[57.54, 65.1, 79.99, 110, 110, 255, 264, 905, 649, 435],
[57.24, 64.18, 79.99, 110, 110, 254.99, 264, 897, 646, 434],
[57.33, 63.5, 80, 110, 109.99, 254.99, 264, 892, 640, 433],
[57.33, 63.51, 80, 110, 110, 254.99, 264, 897, 644, 434],
[57.24, 63.58, 80, 109.99, 110, 254.99, 263.99, 905, 645, 436],
[57.54, 62.66, 80, 110, 110, 254.99, 264, 903, 649, 434],
[57.41, 62.44, 79.99, 110, 110, 255, 263.99, 897, 642, 434],
[57.58, 62.6, 80, 110, 110, 255, 264, 899, 643, 434],
[57.45, 63.12, 79.99, 110, 109.99, 254.99, 264, 895, 641, 434],
[57.54, 63.93, 79.99, 110, 109.99, 255, 264, 898, 645, 434],
[57.16, 63.88, 80, 110, 109.99, 254.99, 264, 907, 648, 436],
[57.16, 63.76, 80, 110, 110, 255, 264, 913, 652, 438],
[57.24, 63.5, 80, 110, 110, 255, 263.99, 900, 643, 435],
[57.07, 63.78, 80, 109.99, 110, 255, 264, 896, 641, 434],
[57.2, 64.09, 80, 109.99, 110, 254.99, 263.99, 900, 644, 435],
[57.07, 63.34, 79.99, 110, 110, 254.99, 264, 902, 643, 434],
[56.95, 64.08, 79.99, 109.99, 110, 254.99, 263.99, 903, 645, 435],
[57.07, 64.56, 80, 110, 109.99, 255, 264, 905, 646, 436],
[57.2, 64.97, 80, 109.99, 109.99, 254.99, 264, 899, 644, 435],
[56.78, 64.02, 80, 110, 110, 255, 264.07, 902, 647, 433],
[56.86, 64.38, 80, 110, 109.99, 254.99, 264, 896, 642, 435],
[56.82, 64.88, 79.99, 110, 110, 255, 264, 896, 642, 434],
[56.9, 63.7, 80, 110, 109.99, 254.99, 264, 903, 644, 434],
[56.78, 63.23, 79.99, 109.99, 110, 254.99, 262.27, 905, 646, 435],
[56.78, 63.18, 80, 110, 109.99, 255, 261.89, 905, 650, 436],
[57.16, 63.88, 80, 110, 110, 255, 259.42, 904, 650, 436],
[56.9, 63.92, 80, 110, 110, 254.99, 258.88, 899, 647, 435],
[56.9, 63.9, 80, 110, 110, 254.99, 258.3, 900, 649, 435],
[52.51, 54.16, 78.84, 90.21, 87.96, 254.98, 204.92, 899, 640, 437],
[54.38, 53.25, 78.66, 88.59, 86.38, 254.96, 205.32, 895, 637, 435],
[55.22, 52.56, 78.47, 87.17, 85.02, 254.6, 205.29, 902, 641, 435],
[55.5, 51.74, 78.27, 85.93, 83.87, 253.83, 205.21, 908, 647, 437],
[55.95, 51.06, 78.05, 84.83, 82.87, 253.13, 205.01, 902, 642, 437],
[55.91, 50.44, 77.84, 83.86, 82.01, 252.49, 204.77, 901, 637, 436],
[55.91, 49.88, 77.62, 83, 81.26, 251.71, 204.41, 894, 634, 434],
[56.03, 49.38, 77.39, 82.22, 80.59, 250.94, 204.03, 904, 641, 436],
[55.99, 48.9, 77.17, 81.53, 80.01, 250.15, 203.59, 906, 641, 436],
[56.16, 48.5, 76.94, 80.9, 79.48, 249.36, 203.44, 897, 635, 435],
[56.16, 48.13, 76.7, 80.33, 79, 248.66, 203.1, 897, 636, 435],
[56.32, 47.82, 76.46, 79.8, 78.56, 247.92, 202.44, 889, 628, 434],
[56.28, 47.51, 76.23, 79.32, 78.16, 247.17, 202.22, 889, 628, 432],
[56.28, 47.22, 75.99, 78.87, 77.78, 246.4, 201.52, 912, 645, 437],
[56.53, 46.95, 75.76, 78.44, 77.43, 245, 201.2, 912, 643, 437],
[56.53, 46.71, 75.51, 78.05, 77.09, 244.36, 200.56, 920, 648, 437],
[56.53, 46.48, 75.27, 77.68, 76.77, 243.61, 200.16, 919, 648, 438],
[56.57, 46.25, 75.03, 77.33, 76.47, 242.87, 199.77, 913, 642, 437],
[56.45, 46.05, 74.8, 76.99, 76.17, 242.14, 199.34, 914, 644, 437],
[56.61, 45.84, 74.56, 76.67, 75.89, 241.41, 198.89, 911, 641, 436],
[56.57, 45.66, 74.32, 76.35, 75.61, 240.75, 198.38, 916, 644, 437],
[56.57, 45.47, 74.07, 76.05, 75.34, 240.03, 198.15, 911, 640, 436],
[56.57, 45.29, 73.83, 75.76, 75.07, 239.32, 197.63, 912, 642, 436],
[56.4, 45.08, 73.59, 75.48, 74.81, 238.61, 197.27, 912, 639, 437],
[56.49, 44.9, 73.35, 75.2, 74.56, 237.89, 196.76, 910, 641, 437],
[56.36, 44.75, 73.12, 74.93, 74.3, 237.19, 196.55, 908, 639, 436],
[56.32, 44.59, 72.87, 74.66, 74.05, 236.55, 195.97, 910, 639, 436],
[56.32, 44.42, 72.64, 74.4, 73.8, 235.86, 195.63, 906, 637, 437],
[55.83, 44.25, 72.4, 74.14, 73.56, 235.17, 195.07, 904, 637, 435],
[56.16, 44.1, 72.16, 73.88, 73.31, 234.47, 194.52, 905, 637, 436],
[56.16, 43.94, 71.93, 73.63, 73.07, 233.82, 194.08, 904, 634, 436],
[55.95, 43.78, 71.69, 73.38, 72.83, 233.34, 193.7, 894, 630, 434],
[55.99, 43.63, 71.46, 73.13, 72.59, 232.69, 193.26, 894, 628, 434],
[55.99, 43.49, 71.23, 72.89, 72.35, 232.05, 192.76, 901, 634, 435],
[55.95, 43.36, 70.99, 72.64, 72.12, 231.4, 192.13, 913, 640, 437],
[55.83, 43.21, 70.76, 72.4, 71.88, 230.77, 191.77, 908, 638, 436],
[55.87, 43.07, 70.53, 72.16, 71.65, 230.22, 191.28, 911, 639, 436],
[55.83, 42.96, 70.3, 71.93, 71.42, 229.57, 190.8, 912, 639, 437],
[55.99, 42.84, 70.07, 71.69, 71.18, 228.94, 190.44, 904, 637, 436],
[55.83, 42.72, 69.84, 71.46, 70.96, 228.31, 189.94, 900, 632, 434],
[55.79, 42.59, 69.62, 71.23, 70.73, 227.68, 189.67, 897, 631, 435],
[55.79, 42.51, 69.39, 70.99, 70.5, 227.3, 188.99, 905, 636, 436],
[55.75, 42.41, 69.17, 70.76, 70.27, 226.77, 188.54, 913, 642, 437],
[55.66, 42.28, 68.94, 70.53, 70.05, 226.17, 187.99, 910, 638, 436],
[55.62, 42.19, 68.72, 70.31, 69.82, 225.56, 187.54, 915, 642, 437],
[55.66, 42.1, 68.49, 70.08, 69.6, 225.08, 186.95, 916, 641, 437],
[55.62, 41.98, 68.27, 69.85, 69.38, 224.67, 186.57, 919, 644, 437],
[55.62, 41.88, 68.05, 69.63, 69.15, 224.07, 185.95, 913, 641, 437],
[55.54, 41.79, 67.84, 69.41, 68.94, 223.46, 185.74, 907, 635, 436],
[55.66, 41.7, 67.62, 69.19, 68.72, 223.02, 185.1, 900, 631, 435],
[55.58, 41.64, 67.4, 68.97, 68.5, 222.54, 184.73, 897, 630, 434],
[55.66, 41.53, 67.19, 68.75, 68.28, 221.96, 183.96, 900, 633, 435],
[55.66, 41.45, 66.97, 68.53, 68.07, 221.36, 183.72, 898, 629, 433],
[55.66, 41.38, 66.76, 68.31, 67.85, 220.77, 183.12, 904, 635, 435],
[55.66, 41.29, 66.55, 68.1, 67.64, 220.29, 182.68, 904, 635, 436],
[55.34, 41.2, 66.33, 67.89, 67.43, 219.74, 182.06, 894, 627, 434],
[55.3, 41.08, 66.13, 67.67, 67.22, 219.14, 181.71, 892, 626, 433],
[55.26, 41, 65.92, 67.46, 67.01, 218.57, 181.04, 905, 635, 436],
[55.26, 40.93, 65.71, 67.25, 66.8, 218.05, 180.85, 909, 635, 436],
[55.3, 40.85, 65.5, 67.04, 66.6, 217.48, 180.24, 903, 632, 435],
[55.18, 40.79, 65.3, 66.83, 66.39, 216.91, 180.02, 918, 641, 437],
[55.22, 40.7, 65.09, 66.62, 66.18, 216.31, 179.41, 914, 640, 437],
[55.22, 40.59, 64.89, 66.41, 65.98, 215.75, 179.06, 906, 637, 436],
[55.42, 40.54, 64.69, 66.21, 65.78, 215.16, 178.5, 915, 641, 437],
[55.26, 40.47, 64.49, 66.01, 65.58, 214.58, 178.24, 915, 641, 437],
[55.3, 40.4, 64.29, 65.8, 65.37, 213.99, 177.47, 919, 644, 438],
[55.14, 40.33, 64.09, 65.6, 65.18, 213.42, 177.11, 907, 633, 436],
[55.3, 40.27, 63.89, 65.4, 64.98, 212.88, 176.86, 905, 634, 435],
[55.38, 40.17, 63.7, 65.2, 64.78, 212.32, 176.07, 898, 629, 435],
[55.02, 40.15, 63.5, 65, 64.58, 211.76, 175.67, 903, 633, 435],
[55.06, 40.05, 63.3, 64.81, 64.39, 211.31, 175.12, 912, 638, 437],
[55.14, 39.98, 63.12, 64.61, 64.2, 210.77, 174.5, 912, 638, 436],
[54.9, 39.9, 62.92, 64.41, 64, 210.26, 174.04, 914, 639, 437],
[54.81, 39.78, 62.73, 64.22, 63.81, 209.75, 173.54, 914, 641, 437],
[54.77, 39.69, 62.54, 64.03, 63.62, 209.29, 172.98, 903, 633, 435],
[54.65, 39.6, 62.35, 63.84, 63.43, 208.76, 172.54, 912, 639, 438],
[54.77, 39.52, 62.16, 63.64, 63.24, 208.25, 172.11, 907, 635, 436],
[54.73, 39.46, 61.97, 63.45, 63.05, 207.79, 171.36, 910, 640, 437],
[54.61, 39.37, 61.78, 63.26, 62.86, 207.31, 171.05, 914, 638, 436],
[54.65, 39.31, 61.6, 63.07, 62.68, 206.82, 170.76, 912, 637, 437],
[54.5, 39.22, 61.42, 62.89, 62.49, 206.33, 170.28, 911, 636, 437],
[54.46, 39.13, 61.23, 62.7, 62.31, 205.83, 169.72, 905, 633, 436],
[54.61, 39.09, 61.05, 62.52, 62.13, 205.35, 169.3, 905, 633, 436],
[54.3, 38.98, 60.87, 62.33, 61.94, 204.91, 168.69, 905, 632, 435],
[54.61, 38.91, 60.68, 62.15, 61.76, 204.47, 168.21, 899, 629, 435],
[54.34, 38.84, 60.51, 61.96, 61.58, 203.98, 167.81, 904, 634, 436],
[54.18, 38.74, 60.33, 61.79, 61.4, 203.5, 167.45, 908, 637, 437],
[54.22, 38.68, 60.15, 61.6, 61.22, 203.01, 166.9, 917, 641, 437],
[54.18, 38.59, 59.97, 61.42, 61.04, 202.53, 166.39, 905, 631, 435],
[54.34, 38.5, 59.8, 61.24, 60.87, 202.11, 165.84, 916, 640, 438],
[54.18, 38.44, 59.63, 61.06, 60.69, 201.7, 165.47, 914, 637, 437],
[54.02, 38.36, 59.45, 60.89, 60.52, 201.25, 164.91, 911, 638, 436],
[53.94, 38.3, 59.28, 60.71, 60.34, 200.8, 164.42, 908, 636, 437],
[53.94, 38.21, 59.1, 60.54, 60.17, 200.35, 164.01, 914, 639, 437],
[53.82, 38.12, 58.93, 60.36, 60, 199.86, 163.49, 918, 641, 437],
[53.78, 38.05, 58.75, 60.19, 59.83, 199.38, 162.94, 901, 631, 436],
[53.74, 37.98, 58.58, 60.01, 59.65, 198.87, 162.58, 912, 637, 436],
[53.67, 37.87, 58.42, 59.84, 59.49, 198.39, 161.88, 915, 639, 437],
[53.71, 37.82, 58.25, 59.67, 59.31, 197.96, 161.61, 913, 637, 437],
[53.55, 37.72, 58.08, 59.5, 59.15, 197.49, 161.25, 916, 641, 438],
[53.51, 37.66, 57.91, 59.33, 58.98, 197.06, 160.62, 916, 641, 438],
[53.63, 37.59, 57.75, 59.16, 58.81, 196.57, 160.33, 912, 637, 438],
[53.39, 37.49, 57.58, 59, 58.65, 196.14, 159.82, 911, 638, 437],
[53.35, 37.41, 57.42, 58.83, 58.48, 195.78, 159.42, 917, 641, 438],
[53.16, 37.37, 57.25, 58.66, 58.32, 195.32, 158.87, 916, 642, 438],
[53.31, 37.3, 57.09, 58.5, 58.15, 194.88, 158.36, 916, 640, 438],
[53.12, 37.2, 56.93, 58.33, 57.99, 194.44, 157.79, 924, 645, 438],
[53.16, 37.14, 56.76, 58.17, 57.83, 193.99, 157.55, 914, 640, 438],
[53.08, 37.09, 56.6, 58, 57.67, 193.52, 157.2, 913, 640, 438],
[53.12, 36.99, 56.45, 57.84, 57.51, 193.05, 156.91, 911, 639, 437],
[53.01, 36.94, 56.29, 57.68, 57.35, 192.59, 156.54, 912, 638, 438],
[53.04, 36.9, 56.13, 57.52, 57.19, 192.11, 156.22, 908, 636, 437],
[53.12, 36.82, 55.97, 57.36, 57.03, 191.64, 155.72, 911, 637, 437],
[53.04, 36.76, 55.81, 57.2, 56.87, 191.21, 155.31, 915, 639, 438],
[53.08, 36.69, 55.66, 57.05, 56.72, 190.75, 154.79, 913, 638, 437],
[52.77, 36.64, 55.5, 56.89, 56.57, 190.25, 154.46, 914, 640, 437],
[53.12, 36.58, 55.35, 56.73, 56.41, 189.79, 154.22, 920, 643, 438],
[53.04, 36.53, 55.2, 56.58, 56.26, 189.3, 153.69, 915, 641, 437],
[53.01, 36.5, 55.05, 56.42, 56.11, 188.83, 153.45, 911, 637, 437],
[53.01, 36.46, 54.9, 56.27, 55.95, 188.4, 153.17, 914, 637, 437],
[53.08, 36.41, 54.75, 56.12, 55.8, 187.93, 153.05, 913, 638, 436],
[52.58, 36.34, 54.6, 55.96, 55.65, 187.47, 152.24, 916, 640, 437],
[53.01, 36.33, 54.45, 55.81, 55.5, 187.01, 151.9, 911, 638, 437],
[53.01, 36.28, 54.3, 55.66, 55.36, 186.55, 151.66, 913, 639, 438],
[53.01, 36.2, 54.16, 55.51, 55.21, 186.11, 151.19, 901, 631, 437],
[53.01, 36.18, 54.01, 55.36, 55.06, 185.66, 150.99, 891, 623, 434],
[53.01, 36.14, 53.87, 55.22, 54.92, 185.23, 150.75, 893, 627, 435],
[53.01, 36.12, 53.72, 55.07, 54.77, 184.77, 150.36, 882, 620, 433],
[53.01, 36.08, 53.58, 54.93, 54.63, 184.32, 150.12, 890, 624, 434],
[53.08, 36.02, 53.44, 54.78, 54.49, 183.89, 149.7, 885, 621, 433],
[53.12, 35.98, 53.3, 54.64, 54.34, 183.42, 149.24, 886, 620, 432],
[53.12, 35.96, 53.16, 54.5, 54.2, 182.98, 148.99, 900, 628, 434],
[53.12, 35.93, 53.01, 54.35, 54.06, 182.51, 148.7, 910, 634, 436],
[53.12, 35.87, 52.88, 54.21, 53.93, 182.07, 148.3, 910, 635, 437],
[52.89, 35.82, 52.74, 54.07, 53.79, 181.61, 148.02, 911, 634, 437],
[52.89, 35.78, 52.6, 53.93, 53.65, 181.19, 147.72, 914, 640, 437],
[52.89, 35.74, 52.47, 53.79, 53.51, 180.87, 147.24, 914, 639, 438],
[52.77, 35.66, 52.33, 53.65, 53.38, 180.56, 146.62, 914, 639, 437],
[52.7, 35.62, 52.2, 53.52, 53.24, 180.19, 145.98, 906, 636, 436],
[52.54, 35.56, 52.06, 53.38, 53.11, 179.82, 145.31, 922, 646, 438],
[52.47, 35.5, 51.93, 53.25, 52.97, 179.5, 144.7, 917, 643, 439],
[52.39, 35.45, 51.79, 53.11, 52.84, 179.16, 144.2, 914, 641, 438],
[52.2, 35.37, 51.66, 52.97, 52.71, 178.73, 143.97, 920, 644, 438],
[52.39, 35.3, 51.53, 52.84, 52.57, 178.22, 143.72, 918, 641, 438],
[52.2, 35.26, 51.4, 52.71, 52.45, 177.69, 143.19, 915, 640, 438],
[52.13, 35.16, 51.27, 52.57, 52.31, 177.14, 142.72, 921, 642, 438],
[52.31, 35.09, 51.14, 52.44, 52.18, 176.62, 142.19, 914, 641, 438],
[52.01, 35.04, 51, 52.31, 52.05, 176.11, 141.66, 918, 642, 438],
[52.2, 34.96, 50.88, 52.18, 51.92, 175.6, 141.15, 916, 641, 438],
[51.86, 34.91, 50.75, 52.05, 51.79, 175.11, 140.7, 919, 642, 438],
[51.82, 34.83, 50.62, 51.92, 51.67, 174.61, 140.27, 914, 639, 437],
[51.82, 34.77, 50.49, 51.79, 51.54, 174.16, 139.89, 909, 637, 437],
[51.6, 34.69, 50.36, 51.66, 51.41, 173.69, 139.55, 914, 638, 437],
[51.82, 34.65, 50.24, 51.53, 51.28, 173.24, 139.18, 914, 639, 438],
[51.63, 34.57, 50.12, 51.41, 51.16, 172.76, 138.95, 913, 640, 438],
[51.6, 34.52, 49.99, 51.28, 51.03, 172.31, 138.8, 908, 637, 437],
[51.6, 34.43, 49.86, 51.15, 50.91, 171.85, 138.28, 909, 635, 437],
[51.78, 34.39, 49.74, 51.03, 50.78, 171.4, 137.67, 913, 639, 438],
[51.78, 34.33, 49.61, 50.9, 50.66, 170.95, 137.34, 913, 638, 438],
[51.82, 34.32, 49.5, 50.78, 50.54, 170.49, 136.98, 908, 635, 437],
[52.16, 34.27, 49.37, 50.65, 50.41, 170.03, 136.86, 907, 634, 437],
[51.67, 34.23, 49.25, 50.53, 50.29, 169.58, 136.52, 906, 631, 436],
[51.71, 34.22, 49.13, 50.41, 50.17, 169.12, 135.91, 905, 632, 436],
[51.9, 34.17, 49.02, 50.29, 50.05, 168.72, 135.61, 919, 641, 438],
[51.75, 34.1, 48.9, 50.17, 49.93, 168.29, 135.44, 915, 640, 438],
[51.75, 34.11, 48.78, 50.05, 49.81, 167.84, 135.21, 915, 640, 438],
[51.6, 34.08, 48.66, 49.93, 49.7, 167.4, 135.19, 913, 637, 437],
[51.67, 34.03, 48.54, 49.81, 49.58, 166.96, 134.64, 915, 640, 438],
[51.26, 34, 48.43, 49.69, 49.46, 166.53, 134.57, 914, 641, 438],
[51.9, 33.99, 48.31, 49.57, 49.35, 166.11, 134.36, 918, 641, 438],
[51.6, 33.92, 48.2, 49.46, 49.23, 165.65, 134.17, 914, 640, 438],
[51.6, 33.88, 48.08, 49.34, 49.12, 165.23, 133.87, 911, 638, 438],
[51.63, 33.86, 47.96, 49.22, 49, 164.81, 133.51, 914, 640, 438],
[51.48, 33.81, 47.86, 49.11, 48.89, 164.37, 133.07, 917, 642, 438],
[51.6, 33.77, 47.74, 49, 48.78, 163.98, 132.75, 920, 643, 439],
[51.45, 33.72, 47.63, 48.88, 48.66, 163.54, 132.4, 921, 643, 439],
[51.45, 33.68, 47.52, 48.77, 48.55, 163.13, 132.16, 913, 638, 438],
[51.3, 33.65, 47.41, 48.66, 48.44, 162.71, 131.76, 906, 636, 437],
[51.3, 33.61, 47.3, 48.54, 48.33, 162.24, 131.48, 915, 640, 438],
[51.52, 33.57, 47.19, 48.43, 48.22, 161.8, 131.16, 905, 634, 437],
[51.37, 33.53, 47.08, 48.32, 48.11, 161.36, 130.85, 914, 641, 437],
[51.45, 33.53, 46.97, 48.21, 48.01, 160.95, 130.63, 914, 643, 438],
[51.6, 33.52, 46.87, 48.1, 47.9, 160.52, 130.22, 913, 640, 438],
[51.41, 33.5, 46.77, 47.99, 47.79, 160.12, 129.75, 925, 646, 439],
[51.56, 33.49, 46.66, 47.89, 47.69, 159.69, 129.46, 913, 642, 439],
[51.56, 33.47, 46.55, 47.78, 47.58, 159.25, 129.21, 910, 637, 437],
[51.6, 33.45, 46.45, 47.67, 47.47, 158.82, 129.24, 913, 639, 438],
[51.6, 33.46, 46.34, 47.56, 47.37, 158.4, 128.5, 915, 642, 438],
[51.63, 33.45, 46.24, 47.46, 47.27, 157.98, 128.4, 919, 642, 438],
[51.67, 33.44, 46.14, 47.36, 47.17, 157.55, 128.14, 913, 641, 438],
[51.48, 33.45, 46.04, 47.25, 47.06, 157.12, 128.1, 907, 637, 437],
[51.78, 33.41, 45.93, 47.15, 46.96, 156.72, 127.33, 911, 638, 438],
[51.82, 33.43, 45.84, 47.05, 46.86, 156.29, 127.4, 914, 641, 439],
[51.75, 33.43, 45.74, 46.95, 46.76, 155.87, 126.61, 912, 638, 438],
[51.82, 33.43, 45.64, 46.85, 46.67, 155.44, 126.61, 921, 641, 439],
[51.94, 33.44, 45.55, 46.75, 46.57, 155.05, 125.78, 910, 637, 437],
[51.94, 33.46, 45.45, 46.65, 46.47, 154.66, 125.46, 906, 634, 437],
[52.01, 33.48, 45.35, 46.55, 46.38, 154.28, 125.2, 902, 632, 436],
[52.31, 33.5, 45.26, 46.45, 46.28, 153.87, 124.64, 910, 640, 438],
[52.43, 33.54, 45.17, 46.36, 46.19, 153.49, 124.48, 907, 636, 437],
[52.31, 33.55, 45.08, 46.26, 46.09, 153.1, 123.98, 907, 635, 437],
[52.35, 33.58, 44.98, 46.17, 46, 152.71, 123.74, 908, 634, 437],
[52.51, 33.62, 44.9, 46.07, 45.91, 152.39, 123.44, 900, 633, 436],
[52.62, 33.66, 44.81, 45.98, 45.82, 152, 123.3, 905, 632, 435],
[52.74, 33.7, 44.71, 45.89, 45.73, 151.62, 123.19, 915, 642, 438],
[52.66, 33.72, 44.63, 45.8, 45.64, 151.26, 122.98, 902, 632, 436],
[52.74, 33.76, 44.54, 45.71, 45.55, 150.88, 122.81, 900, 632, 436],
[52.74, 33.78, 44.45, 45.62, 45.47, 150.5, 122.63, 909, 635, 437],
[52.77, 33.8, 44.36, 45.53, 45.38, 150.13, 122.42, 898, 630, 436],
[52.66, 33.8, 44.28, 45.44, 45.3, 149.79, 122.31, 898, 628, 435],
[52.74, 33.82, 44.2, 45.35, 45.21, 149.53, 121.94, 904, 632, 437],
[52.74, 33.84, 44.12, 45.27, 45.13, 149.17, 121.82, 900, 633, 436],
[52.81, 33.85, 44.03, 45.18, 45.05, 148.8, 121.65, 895, 628, 435],
[52.85, 33.88, 43.95, 45.1, 44.96, 148.44, 121.21, 877, 615, 433],
[52.97, 33.89, 43.87, 45.02, 44.88, 148.06, 120.98, 882, 620, 433],
[53.01, 33.91, 43.79, 44.93, 44.8, 147.74, 120.74, 902, 632, 436],
[53.12, 33.95, 43.71, 44.85, 44.72, 147.42, 120.4, 904, 635, 438],
[53.12, 33.97, 43.63, 44.77, 44.64, 147.07, 120.1, 915, 641, 438],
[53.16, 33.99, 43.56, 44.69, 44.57, 146.77, 119.85, 903, 633, 436],
[53.24, 34.03, 43.48, 44.61, 44.49, 146.42, 119.67, 895, 627, 436],
[53.24, 34.06, 43.4, 44.53, 44.41, 146.07, 119.58, 887, 621, 434],
[53.2, 34.08, 43.33, 44.45, 44.34, 145.73, 119.35, 892, 628, 434],
[53.04, 34.1, 43.25, 44.37, 44.26, 145.36, 119.33, 885, 621, 434],
[53.28, 34.11, 43.18, 44.3, 44.19, 145.01, 118.9, 893, 625, 434],
[53.28, 34.14, 43.11, 44.22, 44.11, 144.66, 118.64, 911, 638, 437],
[53.59, 34.14, 43.03, 44.15, 44.04, 144.3, 118.43, 900, 631, 435],
[53.35, 34.18, 42.96, 44.07, 43.97, 143.96, 118.25, 897, 630, 435],
[53.31, 34.2, 42.89, 44, 43.9, 143.63, 117.99, 900, 629, 436],
[53.43, 34.19, 42.82, 43.93, 43.83, 143.27, 117.77, 906, 633, 436],
[53.59, 34.22, 42.75, 43.86, 43.76, 142.95, 117.6, 911, 636, 436],
[53.67, 34.24, 42.68, 43.79, 43.69, 142.64, 117.33, 908, 635, 437],
[53.71, 34.26, 42.62, 43.71, 43.62, 142.29, 117.13, 916, 643, 438],
[53.63, 34.27, 42.55, 43.64, 43.55, 141.97, 117.01, 913, 642, 438],
[53.82, 34.3, 42.48, 43.58, 43.49, 141.66, 116.84, 903, 632, 436],
[53.82, 34.32, 42.42, 43.51, 43.42, 141.32, 116.58, 913, 640, 438],
[53.98, 34.35, 42.35, 43.44, 43.35, 141.01, 116.31, 914, 637, 437],
[53.78, 34.37, 42.28, 43.37, 43.29, 140.69, 116.25, 908, 636, 437],
[53.98, 34.39, 42.22, 43.31, 43.22, 140.36, 116.18, 902, 633, 436],
[54.26, 34.41, 42.16, 43.24, 43.16, 140.05, 116, 907, 634, 436],
[54.02, 34.45, 42.1, 43.17, 43.1, 139.73, 115.63, 905, 632, 436],
[54.02, 34.48, 42.04, 43.11, 43.03, 139.4, 115.61, 898, 629, 436],
[54.06, 34.5, 41.98, 43.05, 42.97, 139.09, 115.38, 879, 616, 434],
[54.26, 34.55, 41.92, 42.98, 42.91, 138.78, 115.16, 893, 625, 433],
[54.26, 34.58, 41.86, 42.92, 42.85, 138.45, 115.12, 893, 627, 434],
[54.34, 34.62, 41.8, 42.86, 42.79, 138.16, 114.86, 899, 629, 436],
[54.38, 34.65, 41.74, 42.8, 42.73, 137.96, 114.75, 893, 626, 435],
[54.5, 34.69, 41.68, 42.74, 42.68, 137.64, 114.58, 884, 617, 433],
[54.61, 34.73, 41.63, 42.68, 42.62, 137.33, 114.32, 883, 620, 433],
[54.69, 34.77, 41.57, 42.62, 42.56, 137.06, 114.23, 899, 629, 435],
[54.57, 34.82, 41.52, 42.56, 42.5, 136.73, 114.02, 903, 629, 436],
[54.53, 34.85, 41.46, 42.51, 42.45, 136.09, 113.94, 884, 621, 434],
[54.69, 34.88, 41.4, 42.45, 42.4, 135.77, 113.78, 881, 619, 432],
[54.9, 34.93, 41.35, 42.39, 42.34, 135.45, 113.56, 886, 621, 434],
[54.77, 34.94, 41.3, 42.34, 42.29, 135.18, 113.5, 884, 618, 432],
[54.57, 34.98, 41.25, 42.28, 42.24, 134.84, 113.2, 892, 624, 433],
[54.85, 35.02, 41.2, 42.23, 42.18, 134.54, 113.32, 880, 617, 433],
[54.85, 35.06, 41.14, 42.18, 42.13, 134.24, 113.1, 870, 611, 431],
[54.94, 35.09, 41.09, 42.12, 42.08, 133.93, 113.03, 884, 617, 431],
[54.9, 35.12, 41.04, 42.07, 42.03, 133.63, 112.78, 875, 615, 432],
[55.1, 35.15, 41, 42.02, 41.98, 133.4, 112.51, 889, 622, 432],
[55.18, 35.19, 40.95, 41.97, 41.93, 133.11, 112.38, 882, 618, 432],
[55.18, 35.22, 40.9, 41.92, 41.89, 132.77, 112.38, 874, 612, 432],
[55.46, 35.43, 40.54, 41.54, 41.52, 132.46, 111.36, 890, 622, 432],
[55.58, 35.48, 40.45, 41.45, 41.44, 132.19, 111.11, 899, 627, 435],
[55.58, 35.5, 40.41, 41.41, 41.4, 131.88, 111.03, 897, 629, 434],
[55.5, 35.52, 40.37, 41.37, 41.35, 131.65, 110.89, 893, 626, 433],
[55.66, 35.54, 40.33, 41.32, 41.31, 131.46, 110.76, 893, 625, 434],
[55.58, 35.56, 40.29, 41.28, 41.27, 131.15, 110.55, 891, 621, 433],
[55.58, 35.57, 40.25, 41.24, 41.23, 130.87, 110.48, 881, 618, 432],
[55.66, 35.59, 40.21, 41.2, 41.19, 130.62, 110.51, 869, 607, 430],
[55.66, 35.61, 40.17, 41.16, 41.15, 130.3, 110.5, 869, 608, 429],
[55.62, 35.61, 40.13, 41.12, 41.11, 129.97, 110.46, 873, 610, 430],
[55.5, 35.61, 40.1, 41.08, 41.08, 129.75, 110.31, 889, 621, 433],
[55.58, 35.61, 40.06, 41.04, 41.04, 129.46, 110.26, 883, 617, 432],
[55.54, 35.6, 40.02, 41, 41, 129.16, 110.13, 888, 620, 433],
[55.66, 35.6, 39.98, 40.96, 40.96, 128.91, 110.12, 892, 622, 433],
[55.58, 35.59, 39.95, 40.92, 40.93, 128.63, 109.96, 903, 631, 435],
[55.5, 35.58, 39.91, 40.88, 40.89, 128.33, 109.91, 887, 619, 435],
[55.3, 35.57, 39.87, 40.85, 40.85, 128.08, 109.91, 878, 614, 432],
[55.42, 35.57, 39.83, 40.81, 40.81, 127.84, 109.82, 899, 628, 435],
[55.42, 35.55, 39.79, 40.77, 40.78, 127.53, 109.78, 900, 629, 435],
[55.38, 35.54, 39.76, 40.74, 40.74, 127.21, 109.67, 898, 629, 435],
[55.62, 35.52, 39.72, 40.7, 40.71, 126.98, 109.62, 901, 631, 435],
[55.34, 35.5, 39.69, 40.66, 40.67, 126.68, 109.61, 887, 624, 434],
[55.22, 35.48, 39.65, 40.63, 40.64, 126.38, 109.42, 881, 619, 432],
[55.38, 35.45, 39.61, 40.59, 40.6, 126.13, 109.44, 890, 624, 434],
[55.3, 35.43, 39.58, 40.55, 40.56, 125.84, 109.62, 885, 620, 434],
[55.14, 35.4, 39.54, 40.52, 40.53, 125.51, 109.9, 883, 622, 432],
[55.1, 35.38, 39.51, 40.48, 40.49, 125.23, 109.79, 901, 633, 435],
[55.06, 35.36, 39.47, 40.45, 40.46, 125.01, 109.89, 905, 632, 436],
[55.26, 35.33, 39.44, 40.41, 40.42, 124.69, 110.19, 902, 629, 435],
[54.98, 35.3, 39.4, 40.38, 40.39, 124.38, 110.33, 897, 627, 435],
[55.02, 35.28, 39.37, 40.34, 40.35, 124.08, 110.61, 898, 630, 435],
[54.85, 35.25, 39.33, 40.31, 40.32, 123.77, 110.69, 897, 629, 434],
[54.9, 35.2, 39.29, 40.27, 40.29, 123.43, 110.61, 896, 624, 434],
[54.73, 35.16, 39.26, 40.24, 40.25, 123.13, 110.82, 898, 626, 434],
[54.69, 35.13, 39.22, 40.2, 40.22, 122.87, 110.7, 898, 629, 435],
[54.77, 35.08, 39.19, 40.16, 40.18, 122.59, 110.77, 896, 627, 435],
[54.57, 35.04, 39.16, 40.13, 40.15, 122.29, 110.84, 899, 631, 435],
[54.65, 35.01, 39.12, 40.1, 40.11, 122.03, 110.91, 905, 634, 436],
[54.53, 34.96, 39.09, 40.06, 40.08, 121.74, 111.05, 908, 637, 437],
[54.57, 34.92, 39.05, 40.03, 40.04, 121.46, 111.19, 907, 631, 436],
[54.69, 34.86, 39.02, 39.99, 40.01, 121.2, 111.19, 900, 628, 435],
[54.38, 34.82, 38.98, 39.96, 39.97, 121.06, 111.28, 894, 628, 435],
[54.26, 34.78, 38.95, 39.92, 39.94, 120.75, 111.38, 908, 635, 436],
[54.22, 34.74, 38.91, 39.89, 39.9, 120.44, 111.45, 908, 637, 437],
[54.26, 34.68, 38.87, 39.85, 39.87, 120.19, 111.53, 902, 634, 436],
[54.1, 34.62, 38.84, 39.81, 39.83, 119.9, 111.58, 906, 634, 436],
[54.02, 34.56, 38.8, 39.78, 39.8, 119.63, 111.69, 903, 632, 437],
[53.86, 34.51, 38.77, 39.75, 39.76, 119.32, 111.6, 913, 639, 437],
[53.74, 34.44, 38.73, 39.71, 39.73, 119.13, 111.59, 908, 636, 436],
[53.86, 34.3, 38.65, 39.64, 39.65, 118.83, 111.55, 899, 631, 435],
[53.63, 34.24, 38.62, 39.6, 39.62, 118.55, 111.48, 897, 629, 436],
[53.43, 34.18, 38.59, 39.57, 39.58, 118.26, 111.37, 905, 633, 436],
[53.51, 34.12, 38.55, 39.53, 39.54, 117.97, 111.27, 904, 633, 436],
[53.28, 34.03, 38.51, 39.49, 39.51, 117.69, 111.33, 900, 631, 436],
[53.2, 33.97, 38.47, 39.46, 39.47, 117.45, 111.11, 907, 635, 436],
[53.12, 33.9, 38.43, 39.42, 39.43, 117.31, 111.16, 904, 633, 436],
[53.12, 33.83, 38.4, 39.38, 39.4, 117.07, 111.1, 904, 634, 436],
[53.04, 33.77, 38.36, 39.35, 39.36, 116.81, 111.13, 908, 635, 437],
[52.97, 33.71, 38.32, 39.31, 39.32, 116.67, 111.1, 906, 636, 437],
[53.08, 33.64, 38.28, 39.27, 39.28, 116.42, 111.02, 909, 637, 437],
[52.81, 33.57, 38.25, 39.23, 39.24, 116.16, 111.03, 909, 635, 437],
[52.77, 33.51, 38.21, 39.2, 39.21, 115.98, 111, 906, 637, 438],
[52.58, 33.43, 38.17, 39.16, 39.17, 115.8, 110.92, 904, 632, 436],
[52.93, 33.36, 38.13, 39.12, 39.13, 115.54, 110.89, 912, 637, 437],
[52.66, 33.31, 38.09, 39.08, 39.09, 115.33, 110.88, 908, 637, 437],
[52.39, 33.24, 38.05, 39.04, 39.05, 115.08, 110.88, 887, 624, 435],
[52.66, 33.16, 38.01, 39.01, 39.01, 114.82, 110.83, 897, 627, 435],
[52.58, 33.09, 37.97, 38.97, 38.97, 114.59, 110.73, 902, 631, 436],
[52.35, 33.04, 37.93, 38.93, 38.93, 114.44, 110.69, 910, 634, 437],
[52.24, 32.97, 37.89, 38.89, 38.89, 114.17, 110.8, 905, 633, 438],
[52.43, 32.93, 37.85, 38.85, 38.85, 113.77, 110.89, 906, 636, 437],
[52.28, 32.87, 37.81, 38.81, 38.81, 113.53, 110.88, 901, 631, 437],
[52.31, 32.81, 37.77, 38.77, 38.77, 113.31, 110.87, 886, 623, 434],
[52.2, 32.76, 37.73, 38.73, 38.73, 113.13, 110.81, 889, 622, 434],
[52.05, 32.7, 37.69, 38.69, 38.69, 112.89, 110.78, 904, 633, 437],
[52.2, 32.65, 37.65, 38.65, 38.65, 112.66, 110.72, 904, 634, 436],
[51.9, 32.59, 37.61, 38.61, 38.61, 112.43, 110.63, 901, 629, 436],
[52.05, 32.54, 37.57, 38.57, 38.57, 112.17, 110.65, 897, 626, 435],
[51.82, 32.49, 37.53, 38.53, 38.53, 111.99, 110.58, 899, 632, 436],
[51.78, 32.43, 37.49, 38.49, 38.49, 111.86, 110.44, 905, 634, 437],
[51.71, 32.37, 37.44, 38.45, 38.45, 111.63, 110.5, 904, 633, 436],
[51.71, 32.33, 37.4, 38.41, 38.41, 111.43, 110.46, 908, 635, 437],
[51.82, 32.26, 37.36, 38.37, 38.37, 111.2, 110.46, 907, 635, 437],
[51.63, 32.22, 37.33, 38.33, 38.33, 110.98, 110.41, 902, 634, 436],
[51.78, 32.17, 37.27, 38.29, 38.29, 110.86, 110.28, 903, 633, 437],
[51.52, 32.12, 37.24, 38.25, 38.25, 110.7, 110.16, 901, 632, 436],
[51.48, 32.08, 37.2, 38.21, 38.2, 110.48, 110.04, 915, 640, 438],
[51.45, 32.05, 37.16, 38.17, 38.16, 110.42, 109.93, 914, 641, 438],
[51.45, 32, 37.11, 38.12, 38.12, 110.24, 109.85, 906, 637, 438],
[51.56, 31.96, 37.08, 38.08, 38.08, 110.03, 109.89, 915, 639, 438],
[51.45, 31.93, 37.04, 38.04, 38.04, 109.83, 110.09, 914, 639, 438],
[51.45, 31.91, 36.99, 38, 38, 109.6, 110.11, 915, 639, 438],
[51.75, 31.88, 36.96, 37.96, 37.96, 109.39, 110.23, 905, 634, 437],
[51.52, 31.83, 36.91, 37.92, 37.92, 109.16, 109.88, 910, 635, 437],
[51.56, 31.8, 36.87, 37.88, 37.88, 108.93, 109.58, 909, 634, 437],
[51.48, 31.78, 36.83, 37.84, 37.84, 108.73, 109.45, 914, 637, 437],
[51.45, 31.75, 36.79, 37.8, 37.79, 108.52, 109.54, 898, 628, 436],
[51.56, 31.73, 36.75, 37.76, 37.76, 108.32, 109.6, 893, 624, 435],
[51.45, 31.71, 36.72, 37.72, 37.71, 108.11, 109.52, 883, 619, 434],
[51.48, 31.68, 36.67, 37.68, 37.67, 107.88, 109.37, 899, 630, 436],
[51.41, 31.65, 36.63, 37.64, 37.63, 107.73, 109.39, 904, 630, 436],
[51.37, 31.64, 36.59, 37.6, 37.59, 107.51, 109.27, 911, 635, 437],
[51.37, 31.62, 36.55, 37.55, 37.55, 107.3, 109.04, 907, 634, 437],
[51.71, 31.59, 36.51, 37.52, 37.51, 107.08, 109.21, 900, 629, 436],
[51.48, 31.57, 36.48, 37.47, 37.47, 106.89, 109.5, 900, 629, 436],
[51.41, 31.55, 36.43, 37.43, 37.43, 106.77, 109.58, 901, 631, 436],
[51.41, 31.52, 36.4, 37.4, 37.39, 106.59, 109.68, 889, 625, 435],
[51.33, 31.5, 36.35, 37.35, 37.35, 106.38, 109.74, 899, 631, 435],
[51.26, 31.47, 36.32, 37.32, 37.32, 106.21, 109.71, 914, 637, 438],
[51.41, 31.43, 36.28, 37.28, 37.28, 106.01, 109.64, 904, 631, 437],
[51.3, 31.4, 36.24, 37.24, 37.24, 105.81, 109.57, 903, 632, 436],
[51, 31.38, 36.19, 37.2, 37.2, 105.59, 109.57, 913, 638, 438],
[51.15, 31.35, 36.16, 37.16, 37.16, 105.4, 109.37, 906, 634, 438],
[51.19, 31.32, 36.12, 37.12, 37.12, 105.19, 109.42, 903, 632, 436],
[51.15, 31.28, 36.08, 37.08, 37.08, 104.99, 109.48, 898, 630, 436],
[51.22, 31.26, 36.04, 37.04, 37.04, 104.81, 109.53, 898, 627, 436],
[51.11, 31.24, 36.01, 37, 37.01, 104.62, 109.55, 911, 635, 437],
[51.11, 31.2, 35.97, 36.96, 36.97, 104.46, 109.46, 908, 635, 437],
[51.07, 31.17, 35.93, 36.93, 36.93, 104.28, 109.5, 910, 636, 438],
[51.15, 31.14, 35.89, 36.89, 36.89, 104.07, 109.45, 915, 639, 438],
[51.15, 31.12, 35.85, 36.85, 36.85, 103.87, 109.5, 916, 638, 438],
[51.22, 31.08, 35.81, 36.81, 36.81, 103.65, 109.34, 917, 641, 438],
[51.04, 31.05, 35.77, 36.77, 36.77, 103.46, 109.12, 914, 641, 438],
[50.93, 31.04, 35.73, 36.73, 36.74, 103.27, 109.09, 919, 642, 439],
[50.96, 31.01, 35.69, 36.69, 36.7, 103.07, 109.02, 916, 639, 438],
[50.96, 30.99, 35.66, 36.66, 36.66, 102.86, 108.81, 918, 638, 438],
[50.93, 30.96, 35.62, 36.62, 36.62, 102.63, 108.93, 915, 637, 438],
[50.93, 30.96, 35.58, 36.58, 36.58, 102.54, 109.1, 912, 638, 438],
[50.89, 30.93, 35.55, 36.54, 36.55, 102.35, 109.23, 913, 638, 438],
[50.93, 30.91, 35.51, 36.5, 36.51, 102.16, 108.47, 908, 635, 438],
[50.85, 30.9, 35.47, 36.47, 36.47, 101.99, 106.07, 904, 632, 437],
[51, 30.84, 35.39, 36.39, 36.4, 101.79, 106.5, 902, 634, 437],
[50.67, 30.8, 35.36, 36.35, 36.36, 101.61, 106.6, 906, 637, 437],
[50.89, 30.78, 35.32, 36.32, 36.32, 101.38, 106.68, 906, 634, 437],
[50.59, 30.75, 35.28, 36.28, 36.29, 101.18, 106.69, 905, 634, 437],
[50.52, 30.7, 35.25, 36.24, 36.25, 100.98, 106.76, 903, 634, 437],
[50.48, 30.68, 35.21, 36.2, 36.21, 100.8, 106.81, 914, 638, 438],
[50.45, 30.64, 35.17, 36.17, 36.18, 100.61, 106.61, 906, 635, 437],
[50.45, 30.61, 35.13, 36.13, 36.14, 100.41, 106.79, 907, 636, 437],
[50.48, 30.58, 35.1, 36.09, 36.1, 100.26, 107.04, 905, 634, 437],
[50.52, 30.55, 35.06, 36.06, 36.07, 100.06, 106.96, 905, 635, 437],
[50.37, 30.52, 35.02, 36.02, 36.03, 99.84, 106.96, 909, 636, 438],
[50.48, 30.49, 34.99, 35.98, 35.99, 99.64, 106.91, 907, 635, 437],
[50.3, 30.45, 34.95, 35.95, 35.96, 99.45, 106.98, 907, 636, 438],
[50.23, 30.43, 34.91, 35.91, 35.92, 99.26, 107.14, 909, 636, 438],
[50.3, 30.4, 34.87, 35.87, 35.88, 99.05, 107.11, 904, 633, 438],
[50.34, 30.38, 34.84, 35.84, 35.85, 98.86, 107.28, 904, 636, 438],
[50.34, 30.35, 34.8, 35.8, 35.81, 98.66, 107.14, 891, 625, 436],
[50.26, 30.33, 34.77, 35.77, 35.77, 98.47, 107, 909, 637, 438],
[50.15, 30.31, 34.73, 35.73, 35.74, 98.27, 107.01, 913, 640, 438],
[50.19, 30.28, 34.7, 35.69, 35.7, 98.05, 107.06, 913, 639, 439],
[50.26, 30.26, 34.66, 35.66, 35.67, 97.85, 107.28, 908, 636, 438],
[50.45, 30.24, 34.63, 35.62, 35.63, 97.43, 107.27, 910, 635, 438],
[50.23, 30.22, 34.59, 35.58, 35.59, 97.24, 107.24, 908, 637, 438],
[50.26, 30.2, 34.56, 35.55, 35.56, 97.04, 107.37, 915, 639, 438],
[50.04, 30.18, 34.52, 35.51, 35.52, 96.83, 107.33, 915, 639, 438],
[50.04, 30.15, 34.48, 35.48, 35.49, 96.63, 107.33, 911, 639, 438],
[50.34, 30.13, 34.45, 35.44, 35.45, 96.41, 107.12, 906, 635, 438],
[50.04, 30.1, 34.41, 35.41, 35.42, 96.2, 106.87, 909, 635, 438],
[50.04, 30.09, 34.38, 35.37, 35.38, 95.99, 106.81, 901, 629, 437],
[50.34, 30.06, 34.34, 35.34, 35.35, 95.8, 107.02, 909, 637, 438],
[50.08, 30.04, 34.31, 35.3, 35.31, 95.59, 107.12, 907, 637, 438],
[50.04, 30.02, 34.27, 35.27, 35.28, 95.47, 107.2, 907, 637, 438],
[50.04, 30.01, 34.24, 35.23, 35.24, 95.35, 107.23, 908, 634, 438],
[50.01, 29.99, 34.2, 35.2, 35.21, 95.16, 107.13, 914, 639, 438],
[50.08, 29.96, 34.17, 35.16, 35.18, 95, 106.87, 919, 640, 439],
[50.04, 29.95, 34.14, 35.13, 35.14, 94.79, 106.5, 917, 638, 438],
[50.15, 29.94, 34.11, 35.09, 35.11, 94.71, 106.7, 914, 640, 439],
[50.12, 29.92, 34.07, 35.06, 35.07, 94.54, 106.76, 911, 636, 438],
[50.12, 29.91, 34.04, 35.02, 35.04, 94.31, 106.78, 908, 635, 438],
[50.12, 29.9, 34, 34.99, 35.01, 94.08, 106.86, 911, 636, 438],
[50.01, 29.88, 33.97, 34.96, 34.97, 93.83, 106.95, 906, 634, 437],
[50.12, 29.86, 33.94, 34.92, 34.94, 93.57, 106.91, 903, 633, 437],
[50.04, 29.84, 33.9, 34.89, 34.91, 93.33, 106.85, 898, 630, 437],
[49.94, 29.82, 33.87, 34.86, 34.87, 93.1, 106.97, 910, 636, 438],
[49.97, 29.8, 33.84, 34.82, 34.84, 92.88, 107.01, 914, 637, 439],
[50.01, 29.79, 33.81, 34.79, 34.81, 92.68, 107.14, 911, 635, 438],
[50.19, 29.77, 33.77, 34.75, 34.77, 92.54, 106.89, 913, 636, 438],
[50.12, 29.74, 33.74, 34.72, 34.74, 92.33, 106.87, 905, 634, 438],
[49.9, 29.73, 33.71, 34.69, 34.71, 92.23, 106.98, 912, 637, 438],
[49.94, 29.71, 33.67, 34.66, 34.68, 92.03, 107.07, 914, 637, 439],
[49.83, 29.7, 33.64, 34.62, 34.64, 91.93, 106.95, 912, 637, 438],
[49.9, 29.68, 33.61, 34.59, 34.61, 91.79, 106.87, 916, 638, 439],
[50.26, 29.66, 33.58, 34.56, 34.58, 91.63, 106.77, 914, 638, 439],
[49.94, 29.64, 33.55, 34.53, 34.55, 91.48, 107.01, 915, 638, 438],
[49.94, 29.63, 33.51, 34.49, 34.51, 91.28, 107.14, 913, 639, 438],
[50.01, 29.61, 33.48, 34.46, 34.48, 91.16, 107.18, 916, 640, 438],
[49.86, 29.6, 33.45, 34.43, 34.45, 90.93, 107.07, 908, 635, 437],
[49.75, 29.57, 33.42, 34.4, 34.42, 90.77, 106.79, 904, 632, 436],
[50.12, 29.56, 33.39, 34.36, 34.39, 90.58, 106.75, 912, 637, 437],
[49.94, 29.55, 33.36, 34.34, 34.36, 90.39, 106.5, 909, 635, 437],
[49.97, 29.54, 33.32, 34.3, 34.33, 90.2, 106.17, 909, 634, 436],
[50.23, 29.54, 33.29, 34.27, 34.29, 89.94, 106.14, 907, 633, 436],
[50.37, 29.54, 33.26, 34.24, 34.26, 89.69, 105.31, 915, 637, 438],
[49.97, 29.56, 33.23, 34.21, 34.23, 89.4, 105.52, 911, 633, 437],
[50.23, 29.58, 33.2, 34.18, 34.2, 89.15, 104.89, 912, 634, 437],
[50.26, 29.6, 33.18, 34.15, 34.17, 88.88, 104.76, 914, 635, 438],
[50.37, 29.63, 33.15, 34.12, 34.14, 88.62, 104.59, 907, 632, 436],
[50.52, 29.65, 33.12, 34.09, 34.11, 88.35, 104.61, 896, 625, 435],
[50.45, 29.68, 33.09, 34.06, 34.09, 88.09, 104.92, 895, 626, 435],
[50.56, 29.7, 33.06, 34.03, 34.06, 87.82, 104.74, 908, 634, 437],
[50.67, 29.73, 33.03, 34, 34.03, 87.56, 104.87, 905, 635, 437],
[50.52, 29.76, 33, 33.97, 34, 87.32, 105, 905, 635, 438],
[50.56, 29.78, 32.98, 33.94, 33.97, 87.07, 104.7, 909, 637, 438],
[50.52, 29.8, 32.95, 33.91, 33.95, 86.81, 104.64, 911, 638, 438],
[50.59, 29.83, 32.92, 33.89, 33.92, 86.59, 104.58, 913, 637, 438],
[50.48, 29.85, 32.89, 33.86, 33.89, 86.36, 104.79, 906, 634, 437],
[50.63, 29.89, 32.87, 33.83, 33.87, 86.13, 105.08, 908, 634, 437],
[50.34, 29.91, 32.84, 33.81, 33.84, 85.92, 104.75, 913, 639, 438],
[50.59, 29.94, 32.82, 33.78, 33.82, 85.7, 104.66, 907, 635, 437],
[50.63, 29.96, 32.8, 33.76, 33.79, 85.49, 104.54, 911, 636, 438],
[50.96, 30, 32.77, 33.73, 33.77, 85.28, 104.26, 908, 634, 437],
[50.85, 30.03, 32.75, 33.7, 33.74, 85.06, 104.15, 913, 637, 438],
[51.19, 30.12, 32.7, 33.65, 33.7, 84.87, 104.47, 907, 634, 437],
[51, 30.16, 32.68, 33.63, 33.67, 84.66, 103.82, 908, 634, 437],
[51.22, 30.21, 32.65, 33.61, 33.65, 84.45, 104.12, 911, 635, 437],
[51.26, 30.26, 32.63, 33.58, 33.63, 84.26, 104.15, 907, 634, 437],
[51.52, 30.3, 32.61, 33.56, 33.61, 84.06, 103.75, 908, 635, 438],
[51.41, 30.35, 32.6, 33.54, 33.59, 83.88, 104.12, 902, 632, 437],
[51.41, 30.38, 32.57, 33.52, 33.57, 83.69, 103.77, 906, 632, 436],
[51.78, 30.42, 32.55, 33.49, 33.55, 83.53, 103.99, 892, 625, 436],
[51.45, 30.46, 32.53, 33.47, 33.53, 83.35, 104, 906, 633, 437],
[51.63, 30.49, 32.51, 33.45, 33.51, 83.19, 103.86, 906, 635, 438],
[51.6, 30.53, 32.5, 33.43, 33.49, 83.01, 103.79, 900, 632, 436],
[51.52, 30.56, 32.48, 33.41, 33.47, 82.87, 104.15, 884, 620, 435],
[51.86, 30.6, 32.46, 33.39, 33.45, 82.74, 103.94, 885, 621, 433],
[51.6, 30.64, 32.44, 33.37, 33.43, 82.57, 103.5, 887, 622, 434],
[51.71, 30.68, 32.42, 33.36, 33.42, 82.37, 103.85, 903, 628, 436],
[51.94, 30.73, 32.41, 33.34, 33.4, 82.2, 103.4, 905, 634, 437],
[51.9, 30.78, 32.39, 33.32, 33.38, 82.08, 103.4, 906, 634, 438],
[51.82, 30.84, 32.37, 33.31, 33.37, 81.89, 103.72, 901, 632, 436],
[52.01, 30.89, 32.36, 33.28, 33.35, 81.73, 103.55, 910, 634, 436],
[52.28, 30.94, 32.35, 33.27, 33.34, 81.56, 103.42, 905, 632, 437],
[52.2, 30.99, 32.33, 33.25, 33.32, 81.37, 103.74, 900, 631, 436],
[52.09, 31.04, 32.32, 33.24, 33.31, 80.98, 103.35, 894, 624, 435],
[52.2, 31.09, 32.3, 33.22, 33.29, 80.81, 103.11, 877, 615, 434],
[52.31, 31.15, 32.29, 33.21, 33.28, 80.62, 102.89, 894, 626, 434],
[52.39, 31.21, 32.28, 33.19, 33.27, 80.43, 103.45, 885, 621, 433],
[52.43, 31.27, 32.26, 33.18, 33.25, 80.27, 103.12, 877, 616, 433],
[52.58, 31.32, 32.25, 33.16, 33.24, 80.09, 103.19, 876, 612, 432],
[52.62, 31.38, 32.24, 33.15, 33.23, 79.93, 103.4, 865, 609, 431],
[52.81, 31.44, 32.23, 33.14, 33.22, 79.76, 103.02, 867, 609, 430],
[52.77, 31.5, 32.22, 33.13, 33.21, 79.6, 103.36, 878, 614, 432],
[52.62, 31.56, 32.21, 33.11, 33.2, 79.44, 103.27, 882, 617, 433],
[52.93, 31.62, 32.2, 33.1, 33.19, 79.29, 103.32, 897, 627, 435],
[52.93, 31.67, 32.19, 33.09, 33.18, 79.14, 103.41, 906, 634, 436],
[52.97, 31.72, 32.18, 33.08, 33.17, 78.98, 103.46, 905, 632, 437],
[52.97, 31.77, 32.17, 33.07, 33.16, 78.81, 103.54, 897, 629, 436],
[53.04, 31.81, 32.17, 33.06, 33.15, 78.66, 103.34, 904, 633, 437],
[53.04, 31.85, 32.16, 33.05, 33.15, 78.51, 103.32, 903, 630, 436],
[53.04, 31.88, 32.15, 33.04, 33.14, 78.35, 103.15, 896, 625, 435],
[53.04, 31.93, 32.14, 33.03, 33.13, 78.19, 103.53, 873, 613, 433],
[53.08, 31.97, 32.14, 33.03, 33.12, 78, 103.61, 876, 615, 432],
[53.16, 32.01, 32.13, 33.02, 33.12, 77.84, 103.07, 874, 613, 431],
[53.28, 32.05, 32.12, 33.01, 33.11, 77.69, 103.21, 885, 618, 433],
[53.31, 32.09, 32.12, 33, 33.11, 77.52, 103.04, 872, 612, 432],
[53.35, 32.14, 32.11, 33, 33.1, 77.35, 103.09, 871, 612, 431],
[53.43, 32.18, 32.11, 32.99, 33.09, 77.18, 102.97, 872, 612, 431],
[53.47, 32.22, 32.1, 32.99, 33.09, 77.02, 102.77, 875, 611, 432],
[53.39, 32.27, 32.1, 32.98, 33.08, 76.88, 102.7, 878, 613, 431],
[53.71, 32.33, 32.09, 32.97, 33.08, 76.72, 102.71, 874, 611, 432],
[53.78, 32.39, 32.09, 32.97, 33.08, 76.56, 102.54, 871, 608, 431],
[53.94, 32.46, 32.09, 32.96, 33.07, 76.41, 103.05, 871, 608, 431],
[54.06, 32.53, 32.09, 32.96, 33.07, 76.26, 102.78, 883, 616, 432],
[54.34, 32.6, 32.08, 32.96, 33.07, 76.11, 102.48, 892, 620, 434],
[54.3, 32.68, 32.08, 32.95, 33.06, 75.95, 102.81, 890, 620, 433],
[54.38, 32.76, 32.08, 32.95, 33.06, 75.8, 103.19, 894, 625, 434],
[54.61, 32.83, 32.09, 32.95, 33.06, 75.64, 103.23, 891, 620, 434],
[54.42, 32.89, 32.09, 32.94, 33.06, 75.5, 103.43, 896, 626, 434],
[54.46, 32.94, 32.08, 32.94, 33.06, 75.37, 103.11, 878, 615, 433],
[54.38, 32.99, 32.08, 32.94, 33.06, 75.24, 103, 893, 620, 432],
[54.61, 33.04, 32.08, 32.94, 33.06, 75.1, 103.37, 898, 628, 434],
[54.57, 33.09, 32.08, 32.94, 33.06, 74.93, 103.45, 896, 625, 435],
[54.69, 33.14, 32.08, 32.94, 33.06, 74.81, 103.29, 900, 627, 435],
[54.77, 33.18, 32.08, 32.94, 33.06, 74.67, 103.34, 905, 632, 435],
[54.73, 33.23, 32.09, 32.94, 33.06, 74.55, 103.22, 912, 637, 437],
[55.02, 33.27, 32.09, 32.94, 33.07, 74.44, 103.19, 902, 628, 435],
[54.65, 33.31, 32.09, 32.94, 33.07, 74.3, 103.62, 898, 627, 434],
[54.9, 33.35, 32.09, 32.94, 33.07, 74.17, 103.41, 905, 631, 436],
[54.77, 33.39, 32.1, 32.94, 33.07, 74.03, 103.97, 907, 634, 436],
[54.69, 33.41, 32.1, 32.95, 33.08, 73.89, 103.83, 892, 627, 435],
[54.9, 33.45, 32.1, 32.95, 33.08, 73.76, 103.66, 903, 632, 436],
[55.02, 33.49, 32.11, 32.95, 33.08, 73.63, 103.76, 900, 628, 435],
[54.81, 33.53, 32.11, 32.95, 33.09, 73.48, 105.19, 898, 627, 435],
[54.85, 33.56, 32.11, 32.95, 33.09, 73.31, 105.14, 900, 629, 435],
[54.9, 33.58, 32.12, 32.96, 33.09, 73.16, 104.89, 903, 632, 436],
[55.18, 33.61, 32.12, 32.96, 33.1, 73.02, 104.76, 907, 633, 435],
[55.18, 33.64, 32.13, 32.97, 33.1, 72.88, 104.59, 911, 634, 436],
[55.22, 33.68, 32.13, 32.97, 33.11, 72.74, 104.97, 910, 635, 437],
[55.02, 33.7, 32.14, 32.97, 33.11, 72.6, 104.96, 905, 632, 436],
[54.85, 33.71, 32.14, 32.98, 33.12, 72.47, 104.71, 900, 630, 436],
[54.98, 33.73, 32.15, 32.98, 33.12, 72.37, 104.67, 904, 631, 435],
[54.94, 33.75, 32.15, 32.99, 33.13, 72.24, 105.16, 903, 630, 435],
[54.94, 33.77, 32.16, 32.99, 33.13, 72.13, 105.35, 917, 642, 437],
[55.02, 33.78, 32.16, 32.99, 33.14, 72.02, 104.65, 904, 631, 435],
[55.06, 33.81, 32.17, 33.01, 33.15, 71.91, 105.02, 895, 624, 434],
[54.98, 33.83, 32.18, 33.01, 33.16, 71.79, 104.99, 892, 623, 434],
[55.34, 33.84, 32.18, 33.02, 33.16, 71.67, 105.08, 899, 628, 434],
[55.02, 33.85, 32.19, 33.02, 33.17, 71.53, 105.07, 896, 625, 435],
[55.18, 33.86, 32.19, 33.03, 33.17, 71.4, 105.23, 882, 616, 433],
[54.98, 33.87, 32.2, 33.03, 33.18, 71.28, 104.97, 898, 625, 435],
[55.18, 33.88, 32.21, 33.04, 33.18, 71.14, 105.29, 895, 626, 435],
[55.3, 33.88, 32.21, 33.04, 33.19, 71.01, 105.18, 905, 628, 435],
[55.06, 33.9, 32.22, 33.05, 33.2, 70.89, 105.12, 894, 625, 435],
[55.06, 33.91, 32.23, 33.06, 33.2, 70.75, 105.59, 894, 622, 434],
[55.02, 33.92, 32.23, 33.06, 33.21, 70.67, 106.09, 902, 629, 435],
[55.1, 33.93, 32.24, 33.07, 33.22, 70.53, 105.87, 906, 632, 436],
[55.22, 33.94, 32.24, 33.07, 33.22, 70.37, 106.28, 897, 625, 435],
[55.06, 33.94, 32.25, 33.08, 33.23, 70.25, 106.43, 895, 624, 435],
[55.14, 33.94, 32.26, 33.08, 33.23, 70.1, 106.75, 890, 624, 434],
[55.02, 33.93, 32.26, 33.09, 33.24, 69.93, 106.79, 905, 631, 435],
[55.02, 33.92, 32.27, 33.1, 33.25, 69.77, 106.78, 903, 631, 436],
[55.02, 33.9, 32.27, 33.1, 33.25, 69.6, 106.99, 905, 632, 435],
[54.98, 33.89, 32.28, 33.11, 33.26, 69.45, 106.85, 897, 627, 435],
[54.85, 33.88, 32.29, 33.12, 33.27, 69.31, 106.94, 897, 626, 434],
[54.81, 33.87, 32.29, 33.12, 33.27, 69.18, 106.96, 899, 629, 435],
[55.02, 33.85, 32.3, 33.13, 33.28, 69.07, 107.18, 899, 630, 435],
[54.85, 33.83, 32.3, 33.13, 33.28, 68.92, 107.4, 894, 626, 434],
[54.81, 33.82, 32.31, 33.14, 33.29, 68.79, 107.33, 897, 628, 435],
[54.81, 33.8, 32.31, 33.14, 33.29, 68.65, 107.68, 892, 623, 433],
[54.65, 33.77, 32.32, 33.15, 33.3, 68.51, 107.84, 892, 624, 434],
[54.53, 33.75, 32.32, 33.16, 33.31, 68.41, 107.95, 881, 618, 434],
[54.57, 33.72, 32.33, 33.16, 33.31, 68.3, 108.1, 882, 617, 432],
[54.57, 33.68, 32.33, 33.17, 33.32, 68.17, 108.23, 883, 620, 433],
[54.61, 33.65, 32.34, 33.17, 33.32, 68.05, 108.29, 884, 619, 432],
[54.42, 33.61, 32.34, 33.18, 33.33, 67.92, 108.21, 907, 633, 436],
[54.69, 33.58, 32.34, 33.18, 33.33, 67.79, 108.47, 908, 635, 436],
[54.34, 33.54, 32.35, 33.19, 33.33, 67.68, 108.56, 905, 631, 436],
[54.3, 33.5, 32.35, 33.19, 33.34, 67.59, 108.75, 907, 631, 436],
[54.38, 33.47, 32.35, 33.2, 33.34, 67.45, 109.25, 904, 632, 436],
[54.38, 33.42, 32.36, 33.2, 33.35, 67.33, 109.34, 912, 634, 436],
[54.38, 33.39, 32.36, 33.2, 33.35, 67.2, 109.44, 895, 625, 434],
[54.14, 33.26, 32.37, 33.21, 33.36, 67.09, 109.68, 910, 634, 436],
[54.14, 33.22, 32.37, 33.22, 33.36, 66.96, 109.69, 914, 639, 437],
[54.18, 33.18, 32.37, 33.22, 33.36, 66.84, 109.77, 909, 633, 437],
[54.02, 33.14, 32.37, 33.22, 33.36, 66.71, 109.88, 904, 631, 436],
[54.06, 33.09, 32.38, 33.22, 33.36, 66.56, 109.89, 901, 631, 436],
[53.98, 33.05, 32.38, 33.23, 33.36, 66.43, 110, 907, 634, 437],
[54.14, 33, 32.38, 33.23, 33.37, 66.31, 109.96, 906, 631, 436],
[53.94, 32.95, 32.38, 33.23, 33.37, 66.2, 110.02, 906, 633, 437],
[53.9, 32.91, 32.38, 33.23, 33.37, 66.08, 110.18, 911, 633, 436],
[53.71, 32.87, 32.38, 33.23, 33.37, 65.95, 110.25, 911, 637, 436],
[53.63, 32.82, 32.38, 33.23, 33.36, 65.84, 110.31, 914, 634, 436],
[53.59, 32.77, 32.37, 33.23, 33.36, 65.72, 110.35, 911, 634, 437],
[53.51, 32.73, 32.37, 33.23, 33.36, 65.62, 110.45, 910, 633, 436],
[53.43, 32.67, 32.37, 33.23, 33.36, 65.54, 110.51, 904, 631, 436],
[53.59, 32.62, 32.37, 33.23, 33.36, 65.4, 110.52, 903, 631, 436],
[53.51, 32.57, 32.36, 33.23, 33.36, 65.31, 110.62, 901, 631, 435],
[53.43, 32.52, 32.37, 33.23, 33.36, 65.2, 110.42, 900, 630, 437],
[53.24, 32.47, 32.36, 33.23, 33.35, 65.09, 110.45, 902, 629, 436],
[53.35, 32.42, 32.36, 33.23, 33.35, 64.98, 110.42, 898, 628, 435],
[53.16, 32.38, 32.36, 33.23, 33.35, 64.87, 110.55, 907, 633, 436],
[53.12, 32.34, 32.35, 33.22, 33.34, 64.77, 110.68, 897, 626, 435],
[53.08, 32.29, 32.35, 33.22, 33.34, 64.68, 110.73, 898, 626, 434],
[53.08, 32.25, 32.35, 33.22, 33.34, 64.57, 110.44, 908, 631, 436],
[53.01, 32.21, 32.34, 33.21, 33.33, 64.48, 110.34, 901, 629, 436],
[52.97, 32.17, 32.34, 33.21, 33.33, 64.36, 110.35, 905, 633, 436],
[53.12, 32.14, 32.33, 33.21, 33.33, 64.27, 110.33, 908, 632, 437],
[52.81, 32.1, 32.33, 33.2, 33.32, 64.17, 110.6, 901, 630, 435],
[52.85, 32.07, 32.32, 33.2, 33.31, 64.07, 110.71, 903, 631, 436],
[52.97, 32.03, 32.32, 33.19, 33.31, 63.98, 110.68, 887, 622, 435],
[52.74, 32, 32.31, 33.19, 33.3, 63.88, 110.76, 896, 629, 435],
[52.7, 31.96, 32.31, 33.19, 33.3, 63.77, 110.74, 910, 633, 437],
[52.66, 31.92, 32.3, 33.18, 33.29, 63.65, 110.63, 909, 634, 436],
[52.74, 31.88, 32.3, 33.18, 33.29, 63.55, 110.63, 903, 630, 436],
[52.58, 31.84, 32.29, 33.17, 33.28, 63.48, 110.76, 911, 635, 437],
[52.66, 31.8, 32.28, 33.16, 33.27, 63.38, 110.66, 903, 631, 436],
[52.62, 31.77, 32.27, 33.16, 33.27, 63.29, 110.61, 903, 631, 436],
[52.47, 31.73, 32.27, 33.15, 33.26, 63.19, 110.27, 913, 637, 438],
[52.62, 31.69, 32.26, 33.15, 33.25, 63.1, 110.11, 914, 636, 437],
[52.43, 31.65, 32.25, 33.14, 33.25, 63.03, 110.41, 917, 640, 437],
[52.35, 31.62, 32.24, 33.13, 33.24, 62.93, 109.98, 918, 640, 438],
[52.35, 31.59, 32.24, 33.13, 33.23, 62.84, 109.98, 919, 638, 438],
[52.58, 31.57, 32.23, 33.12, 33.22, 62.75, 109.43, 912, 634, 437],
[52.51, 31.55, 32.22, 33.11, 33.21, 62.66, 109.37, 912, 633, 437],
[52.54, 31.54, 32.21, 33.1, 33.21, 62.58, 108.9, 909, 630, 436],
[52.81, 31.55, 32.21, 33.1, 33.2, 62.49, 108.67, 905, 629, 435],
[52.58, 31.56, 32.2, 33.09, 33.19, 62.41, 108.75, 897, 623, 435],
[52.85, 31.57, 32.2, 33.08, 33.18, 62.35, 108.14, 886, 619, 434],
[52.85, 31.59, 32.19, 33.07, 33.18, 62.27, 108.51, 877, 612, 432],
[53.01, 31.59, 32.18, 33.07, 33.17, 62.17, 108.59, 878, 613, 431],
[53.12, 31.6, 32.17, 33.06, 33.16, 62.06, 108.75, 890, 621, 433],
[52.93, 31.59, 32.17, 33.05, 33.15, 61.99, 108.79, 898, 626, 434],
[52.85, 31.59, 32.16, 33.04, 33.14, 61.92, 108.57, 905, 630, 436],
[53.04, 31.58, 32.15, 33.04, 33.14, 61.83, 108.9, 902, 628, 435],
[52.77, 31.56, 32.14, 33.03, 33.13, 61.75, 109.28, 893, 621, 434],
[52.93, 31.54, 32.13, 33.02, 33.12, 61.67, 109.27, 894, 620, 433],
[52.7, 31.52, 32.13, 33.01, 33.11, 61.6, 109.49, 905, 630, 437],
[52.74, 31.5, 32.12, 33.01, 33.1, 61.53, 109.39, 900, 626, 435],
[52.7, 31.48, 32.11, 33, 33.1, 61.43, 109.29, 906, 628, 436],
[52.62, 31.47, 32.1, 32.99, 33.09, 61.31, 109.26, 910, 634, 437],
[52.58, 31.44, 32.09, 32.98, 33.08, 61.22, 108.75, 910, 633, 437],
[52.58, 31.42, 32.08, 32.97, 33.07, 61.13, 108.83, 905, 630, 436],
[52.58, 31.41, 32.08, 32.97, 33.06, 61.05, 108.45, 904, 627, 436],
[52.58, 31.39, 32.07, 32.96, 33.06, 60.95, 108.59, 900, 627, 435],
[52.62, 31.37, 32.06, 32.95, 33.05, 60.88, 108.75, 891, 619, 434],
[52.58, 31.35, 32.05, 32.94, 33.04, 60.79, 109.08, 892, 622, 434],
[52.62, 31.33, 32.04, 32.93, 33.03, 60.73, 108.95, 900, 625, 434],
[52.7, 31.31, 32.03, 32.92, 33.02, 60.64, 109.27, 910, 633, 436],
[52.54, 31.28, 32.02, 32.91, 33.01, 60.56, 109.55, 912, 634, 437],
[52.62, 31.26, 32.01, 32.91, 33, 60.49, 109.39, 914, 634, 437],
[52.35, 31.24, 32, 32.9, 32.99, 60.41, 109.36, 913, 634, 437],
[52.28, 31.21, 31.99, 32.89, 32.99, 60.31, 109.4, 916, 636, 437],
[52.43, 31.19, 31.99, 32.88, 32.98, 60.24, 109.54, 921, 642, 438],
[52.31, 31.16, 31.98, 32.87, 32.97, 60.14, 109.8, 912, 634, 437],
[52.58, 31.14, 31.97, 32.86, 32.96, 60.08, 109.94, 905, 630, 436],
[52.31, 31.12, 31.96, 32.85, 32.95, 59.95, 109.69, 909, 632, 436],
[52.24, 31.09, 31.95, 32.84, 32.94, 59.86, 109.48, 910, 632, 437],
[52.28, 31.08, 31.94, 32.83, 32.93, 59.74, 109.51, 913, 634, 437],
[52.28, 31.05, 31.93, 32.82, 32.92, 59.64, 109.81, 915, 634, 437],
[52.39, 31.03, 31.92, 32.81, 32.91, 59.6, 109.87, 909, 630, 436],
[52.31, 31.01, 31.91, 32.8, 32.9, 59.51, 109.8, 903, 627, 436],
[52.35, 30.99, 31.89, 32.79, 32.89, 59.47, 108.9, 911, 631, 436],
[52.24, 30.98, 31.89, 32.78, 32.88, 59.39, 108.88, 911, 633, 437],
[52.39, 30.96, 31.87, 32.77, 32.87, 59.31, 108.89, 902, 628, 436],
[52.28, 30.95, 31.87, 32.76, 32.86, 59.23, 108.74, 889, 622, 434],
[52.24, 30.94, 31.85, 32.75, 32.85, 59.13, 108.84, 898, 626, 434],
[52.24, 30.93, 31.84, 32.74, 32.84, 59.01, 108.74, 905, 631, 436],
[52.13, 30.92, 31.83, 32.73, 32.83, 58.89, 107.5, 907, 633, 436],
[52.43, 30.91, 31.83, 32.72, 32.82, 58.79, 106.2, 906, 631, 436],
[52.39, 30.91, 31.82, 32.71, 32.81, 58.7, 106.12, 905, 632, 436],
[52.35, 30.91, 31.81, 32.7, 32.8, 58.61, 106.46, 894, 623, 435],
[52.39, 30.91, 31.8, 32.69, 32.79, 58.51, 106.19, 884, 618, 433],
[52.51, 30.89, 31.79, 32.68, 32.78, 58.39, 106.31, 896, 623, 434],
[52.35, 30.89, 31.77, 32.67, 32.77, 58.31, 106.23, 897, 624, 434],
[52.43, 30.89, 31.77, 32.67, 32.76, 58.25, 105.9, 907, 633, 437],
[52.43, 30.88, 31.76, 32.65, 32.75, 58.21, 106.25, 907, 631, 436],
[52.66, 30.88, 31.75, 32.64, 32.74, 58.17, 106.26, 913, 634, 436],
[52.47, 30.88, 31.74, 32.63, 32.73, 58.08, 106.41, 914, 634, 437],
[52.43, 30.87, 31.73, 32.62, 32.72, 58.02, 106.6, 912, 634, 437],
[52.93, 30.86, 31.72, 32.61, 32.71, 57.91, 106.73, 912, 635, 437],
[52.39, 30.86, 31.71, 32.6, 32.7, 57.83, 106.64, 912, 633, 437],
[52.35, 30.84, 31.7, 32.59, 32.69, 57.77, 106.78, 908, 632, 436],
[52.28, 30.83, 31.69, 32.58, 32.68, 57.67, 107.06, 900, 625, 435],
[52.51, 30.81, 31.68, 32.57, 32.67, 57.54, 106.84, 898, 624, 435],
[52.43, 30.8, 31.67, 32.56, 32.66, 57.36, 106.78, 918, 635, 437],
[52.24, 30.79, 31.65, 32.55, 32.65, 57.22, 107.1, 914, 634, 437],
[52.39, 30.77, 31.65, 32.54, 32.64, 57.04, 106.6, 914, 636, 437],
[52.28, 30.76, 31.63, 32.53, 32.63, 56.89, 106.96, 910, 633, 437],
[52.24, 30.75, 31.63, 32.52, 32.62, 56.7, 107.17, 911, 631, 436],
[52.31, 30.73, 31.61, 32.51, 32.6, 56.59, 107.22, 914, 634, 437],
[52.24, 30.71, 31.6, 32.5, 32.6, 56.47, 106.97, 912, 634, 437],
[52.09, 30.7, 31.59, 32.49, 32.59, 56.36, 107.15, 914, 634, 437],
[52.13, 30.68, 31.58, 32.48, 32.57, 56.23, 107.45, 905, 628, 436],
[52.24, 30.66, 31.57, 32.47, 32.56, 56.05, 107.09, 917, 635, 437],
[52.01, 30.65, 31.56, 32.46, 32.55, 55.9, 107.14, 907, 630, 436],
[52.09, 30.64, 31.55, 32.45, 32.54, 55.72, 107.39, 902, 628, 436],
[52.01, 30.62, 31.54, 32.44, 32.53, 55.58, 107.58, 905, 628, 435],
[51.97, 30.6, 31.53, 32.43, 32.52, 55.43, 107.79, 904, 630, 436],
[51.86, 30.57, 31.52, 32.42, 32.51, 55.28, 107.62, 894, 623, 435],
[52.01, 30.55, 31.51, 32.41, 32.5, 55.13, 107.73, 915, 638, 438],
[51.63, 30.52, 31.49, 32.4, 32.49, 55.01, 107.59, 915, 638, 438],
[51.75, 30.5, 31.48, 32.39, 32.48, 54.88, 107.62, 908, 634, 437],
[51.67, 30.47, 31.47, 32.38, 32.47, 54.77, 108.03, 899, 627, 436],
[51.52, 30.44, 31.46, 32.37, 32.46, 54.69, 108.13, 904, 628, 436],
[51.78, 30.41, 31.45, 32.36, 32.45, 54.56, 107.99, 914, 636, 438],
[51.41, 30.39, 31.44, 32.34, 32.43, 54.47, 107.98, 920, 641, 438],
[51.75, 30.36, 31.43, 32.33, 32.42, 54.36, 107.77, 916, 638, 438],
[51.41, 30.33, 31.42, 32.32, 32.41, 54.24, 107.76, 909, 634, 437],
[51.45, 30.31, 31.4, 32.31, 32.4, 54.13, 107.71, 891, 622, 435],
[51.26, 30.3, 31.39, 32.3, 32.39, 54.02, 108, 911, 632, 437],
[51.45, 30.27, 31.38, 32.29, 32.38, 53.91, 107.88, 915, 637, 438],
[51.41, 30.26, 31.37, 32.28, 32.37, 53.77, 107.99, 919, 639, 438],
[51.52, 30.24, 31.36, 32.27, 32.35, 53.67, 107.88, 915, 636, 438],
[51.48, 30.23, 31.34, 32.25, 32.34, 53.56, 107.66, 912, 633, 437],
[51.41, 30.22, 31.34, 32.24, 32.33, 53.43, 107.93, 911, 634, 437],
[51.41, 30.21, 31.32, 32.23, 32.32, 53.33, 108, 915, 634, 438],
[51.56, 30.2, 31.31, 32.22, 32.31, 53.23, 107.8, 914, 636, 438],
[51.52, 30.18, 31.3, 32.21, 32.3, 53.18, 108.06, 914, 635, 437],
[51.48, 30.17, 31.29, 32.2, 32.28, 53.08, 108.15, 913, 634, 437],
[51.41, 30.16, 31.28, 32.19, 32.27, 52.92, 108.26, 912, 634, 437],
[51.26, 30.14, 31.26, 32.17, 32.26, 52.84, 108.26, 914, 635, 437],
[51.11, 30.13, 31.26, 32.16, 32.25, 52.76, 108.29, 912, 634, 437],
[51, 30.11, 31.24, 32.15, 32.24, 52.66, 108.32, 911, 634, 437],
[51.22, 30.1, 31.23, 32.14, 32.23, 52.58, 108.39, 911, 634, 437],
[51.22, 30.08, 31.22, 32.13, 32.21, 52.5, 108.36, 911, 633, 437],
[51.22, 30.06, 31.2, 32.11, 32.2, 52.41, 108.1, 913, 634, 438],
[51.41, 30.05, 31.2, 32.1, 32.19, 52.35, 107.9, 909, 632, 437],
[51.11, 30.04, 31.18, 32.09, 32.18, 52.26, 107.66, 909, 634, 437],
[51.07, 30.04, 31.17, 32.08, 32.17, 52.16, 107.83, 909, 634, 437],
[51.26, 30.03, 31.16, 32.07, 32.15, 52.07, 107.83, 907, 632, 437],
[51.19, 30.03, 31.15, 32.06, 32.14, 51.98, 107.86, 908, 632, 437],
[51.48, 30.02, 31.14, 32.05, 32.13, 51.9, 107.81, 904, 629, 437],
[51.19, 30.02, 31.12, 32.03, 32.12, 51.79, 107.69, 908, 633, 437],
[51.15, 30.01, 31.11, 32.02, 32.11, 51.61, 107.79, 913, 637, 437],
[51.15, 30.01, 31.1, 32.01, 32.1, 51.49, 107.51, 907, 631, 438],
[51.19, 30.01, 31.09, 32, 32.08, 51.38, 107.31, 915, 638, 438],
[51.26, 30.01, 31.08, 31.99, 32.07, 51.27, 107.06, 906, 631, 437],
[51.26, 30.03, 31.07, 31.97, 32.06, 51.18, 106.89, 903, 629, 436],
[51.37, 30.05, 31.06, 31.96, 32.05, 51.07, 106.57, 897, 626, 436],
[51.56, 30.08, 31.05, 31.95, 32.04, 50.98, 106.39, 910, 636, 437],
[51.52, 30.12, 31.04, 31.94, 32.03, 50.88, 106.27, 917, 639, 438],
[51.67, 30.16, 31.03, 31.93, 32.02, 50.77, 106.03, 920, 640, 439],
[51.78, 30.2, 31.02, 31.92, 32.01, 50.66, 105.78, 917, 639, 438],
[51.75, 30.24, 31.01, 31.91, 32, 50.54, 105.85, 914, 636, 437],
[51.9, 30.28, 31, 31.9, 31.99, 50.45, 105.77, 917, 638, 438],
[51.86, 30.33, 30.99, 31.89, 31.98, 50.33, 105.83, 915, 637, 438],
[52.47, 30.38, 30.98, 31.88, 31.97, 50.22, 105.81, 914, 635, 438],
[51.97, 30.42, 30.97, 31.87, 31.96, 50.12, 105.42, 910, 632, 437],
[52.05, 30.46, 30.96, 31.86, 31.96, 49.99, 105.23, 910, 634, 438],
[52.09, 30.51, 30.96, 31.85, 31.95, 49.89, 105.08, 912, 637, 437],
[52.39, 30.63, 30.94, 31.84, 31.93, 49.75, 104.96, 907, 632, 437],
[52.51, 30.68, 30.94, 31.83, 31.93, 49.65, 104.85, 910, 634, 437],
[52.58, 30.73, 30.93, 31.82, 31.92, 49.52, 105.09, 906, 631, 436],
[52.7, 30.8, 30.93, 31.81, 31.91, 49.39, 104.74, 889, 620, 435],
[52.7, 30.85, 30.92, 31.81, 31.91, 49.29, 104.85, 879, 614, 433],
[52.74, 30.91, 30.92, 31.8, 31.9, 49.16, 104.33, 889, 622, 433],
[53.12, 30.97, 30.91, 31.8, 31.9, 49.05, 104.48, 905, 631, 436],
[52.97, 31.04, 30.91, 31.79, 31.89, 48.93, 104.4, 904, 631, 437],
[52.89, 31.1, 30.9, 31.78, 31.89, 48.83, 104.26, 882, 615, 435],
[52.97, 31.16, 30.9, 31.78, 31.89, 48.72, 104.17, 873, 609, 432],
[53.08, 31.22, 30.89, 31.77, 31.88, 48.62, 104.14, 882, 615, 432],
[53.16, 31.29, 30.9, 31.77, 31.88, 48.54, 103.79, 871, 609, 431],
[53.28, 31.35, 30.89, 31.77, 31.88, 48.45, 103.69, 883, 616, 432],
[53.31, 31.42, 30.89, 31.76, 31.88, 48.38, 103.53, 883, 619, 432],
[53.43, 31.49, 30.89, 31.76, 31.87, 48.29, 103.78, 890, 621, 434],
[53.51, 31.56, 30.89, 31.76, 31.87, 48.22, 103.34, 873, 611, 432],
[53.71, 31.64, 30.89, 31.75, 31.87, 48.15, 103.62, 880, 614, 431],
[53.67, 31.72, 30.89, 31.75, 31.87, 48.07, 103.39, 897, 624, 434],
[53.78, 31.8, 30.89, 31.75, 31.87, 48, 103.06, 904, 631, 436],
[53.86, 31.88, 30.89, 31.75, 31.87, 47.9, 103.07, 883, 613, 434],
[54.14, 31.96, 30.89, 31.75, 31.87, 47.82, 103.21, 873, 612, 431],
[53.98, 32.04, 30.89, 31.75, 31.87, 47.73, 102.84, 882, 615, 431],
[54.18, 32.12, 30.89, 31.75, 31.87, 47.66, 103.21, 901, 628, 435],
[54.34, 32.21, 30.9, 31.75, 31.88, 47.59, 103.05, 904, 629, 435],
[54.42, 32.29, 30.9, 31.75, 31.88, 47.49, 102.72, 904, 629, 435],
[54.46, 32.37, 30.91, 31.76, 31.88, 47.42, 103.18, 894, 622, 434],
[54.53, 32.45, 30.91, 31.76, 31.89, 47.33, 102.67, 904, 630, 435],
[54.85, 32.61, 30.92, 31.76, 31.89, 47.23, 103.2, 908, 633, 436],
[54.98, 32.68, 30.93, 31.76, 31.9, 47.16, 102.97, 906, 631, 435],
[54.9, 32.75, 30.93, 31.77, 31.9, 47.06, 103.21, 912, 634, 436],
[54.94, 32.82, 30.94, 31.77, 31.91, 46.98, 102.75, 912, 634, 436],
[55.06, 32.89, 30.94, 31.78, 31.92, 46.9, 102.78, 897, 625, 435],
[55.18, 32.97, 30.95, 31.78, 31.92, 46.81, 103.09, 894, 623, 433],
[55.18, 33.03, 30.95, 31.79, 31.93, 46.72, 102.95, 912, 632, 435],
[55.54, 33.09, 30.96, 31.79, 31.94, 46.62, 103.19, 908, 632, 435],
[55.46, 33.16, 30.97, 31.8, 31.95, 46.55, 102.87, 912, 634, 436],
[55.34, 33.22, 30.98, 31.81, 31.95, 46.44, 102.81, 909, 632, 436],
[55.46, 33.29, 30.99, 31.81, 31.96, 46.38, 102.75, 909, 631, 436],
[55.66, 33.36, 31, 31.82, 31.97, 46.27, 103.06, 893, 621, 434],
[55.54, 33.43, 31.01, 31.83, 31.98, 46.19, 102.76, 886, 618, 432],
[55.66, 33.49, 31.02, 31.84, 31.99, 46.08, 103.29, 895, 625, 433],
[55.79, 33.55, 31.03, 31.85, 32, 45.98, 103.38, 906, 632, 436],
[55.87, 33.61, 31.04, 31.86, 32.01, 45.91, 103.65, 903, 630, 435],
[55.75, 33.66, 31.05, 31.86, 32.02, 45.83, 103.4, 903, 630, 435],
[55.66, 33.7, 31.06, 31.87, 32.03, 45.73, 103.73, 898, 628, 435],
[55.66, 33.74, 31.07, 31.88, 32.04, 45.63, 103.33, 889, 621, 434],
[55.79, 33.77, 31.08, 31.89, 32.05, 45.55, 103.85, 875, 612, 432],
[55.83, 33.79, 31.09, 31.9, 32.07, 45.47, 104.06, 888, 619, 433],
[55.58, 33.8, 31.1, 31.91, 32.08, 45.39, 104.12, 899, 628, 435],
[55.46, 33.81, 31.11, 31.92, 32.09, 45.35, 104.39, 886, 620, 433],
[55.66, 33.81, 31.13, 31.94, 32.1, 45.17, 104.62, 888, 622, 433],
[55.38, 33.81, 31.14, 31.95, 32.11, 45.09, 104.69, 885, 620, 432],
[55.38, 33.8, 31.15, 31.96, 32.12, 45.01, 104.44, 896, 626, 434],
[55.3, 33.79, 31.16, 31.97, 32.14, 44.93, 104.48, 889, 623, 434],
[55.54, 33.8, 31.17, 31.98, 32.15, 44.84, 104.51, 883, 619, 433],
[55.42, 33.79, 31.18, 31.99, 32.16, 44.74, 104.39, 896, 628, 434],
[55.26, 33.8, 31.19, 32, 32.17, 44.63, 104.23, 890, 624, 434],
[55.22, 33.8, 31.21, 32.02, 32.18, 44.52, 104.3, 901, 630, 435],
[55.46, 33.81, 31.22, 32.03, 32.19, 44.43, 104.18, 900, 630, 435],
[55.22, 33.82, 31.24, 32.04, 32.21, 44.34, 103.76, 910, 635, 436],
[55.38, 33.84, 31.24, 32.05, 32.22, 44.26, 103.56, 905, 631, 436],
[55.34, 33.86, 31.26, 32.06, 32.23, 44.16, 103.73, 884, 618, 434],
[55.58, 33.9, 31.28, 32.08, 32.25, 44.06, 103.49, 890, 623, 433],
[55.54, 33.92, 31.29, 32.1, 32.27, 43.96, 103.55, 906, 633, 436],
[55.38, 33.95, 31.31, 32.11, 32.28, 43.87, 103.71, 900, 629, 435],
[55.42, 33.97, 31.32, 32.12, 32.29, 43.77, 103.6, 894, 626, 434],
[55.58, 34, 31.33, 32.13, 32.3, 43.67, 103.53, 891, 623, 433],
[55.5, 34.02, 31.34, 32.15, 32.32, 43.59, 103.47, 877, 614, 432],
[55.62, 34.04, 31.35, 32.16, 32.33, 43.51, 103.43, 886, 620, 433],
[55.54, 34.07, 31.37, 32.17, 32.34, 43.43, 103.46, 892, 624, 434],
[55.58, 34.08, 31.38, 32.18, 32.36, 43.36, 103.47, 907, 634, 436],
[55.62, 34.1, 31.39, 32.2, 32.37, 43.27, 103.32, 907, 634, 436],
[55.66, 34.12, 31.41, 32.21, 32.38, 43.2, 103.43, 905, 632, 436],
[55.7, 34.13, 31.42, 32.22, 32.39, 43.14, 103.49, 901, 629, 435],
[55.58, 34.14, 31.44, 32.23, 32.41, 43.06, 103.73, 904, 629, 435],
[55.62, 34.15, 31.45, 32.25, 32.42, 43, 103.71, 907, 633, 436],
[55.62, 34.15, 31.46, 32.26, 32.43, 42.92, 103.84, 902, 631, 435],
[55.58, 34.16, 31.47, 32.27, 32.45, 42.82, 103.86, 907, 633, 436],
[55.58, 34.16, 31.49, 32.29, 32.46, 42.71, 103.87, 907, 633, 435],
[55.58, 34.16, 31.5, 32.3, 32.47, 42.65, 103.87, 916, 638, 437],
[55.66, 34.16, 31.52, 32.31, 32.49, 42.57, 103.6, 901, 629, 436],
[55.62, 34.16, 31.53, 32.32, 32.5, 42.46, 103.47, 897, 627, 434],
[55.66, 34.16, 31.54, 32.34, 32.51, 42.37, 103.46, 897, 627, 434],
[55.58, 34.16, 31.55, 32.35, 32.52, 42.3, 103.44, 901, 629, 436],
[55.54, 34.16, 31.56, 32.36, 32.54, 42.21, 103.37, 899, 628, 435],
[55.66, 34.16, 31.58, 32.38, 32.55, 42.14, 103.27, 902, 629, 434],
[55.58, 34.17, 31.59, 32.39, 32.56, 42.05, 103.28, 901, 627, 435],
[55.58, 34.18, 31.6, 32.4, 32.57, 41.96, 103.56, 898, 627, 435],
[55.66, 34.18, 31.62, 32.41, 32.59, 41.87, 103.52, 893, 624, 433],
[55.7, 34.2, 31.63, 32.43, 32.6, 41.8, 103.5, 893, 622, 434],
[55.75, 34.2, 31.64, 32.44, 32.61, 41.72, 103.55, 889, 620, 433],
[55.66, 34.21, 31.65, 32.45, 32.63, 41.66, 103.52, 894, 623, 433],
[55.87, 34.22, 31.67, 32.47, 32.64, 41.6, 103.76, 890, 621, 433],
[55.7, 34.22, 31.68, 32.48, 32.65, 41.52, 103.89, 885, 618, 433],
[55.7, 34.22, 31.69, 32.49, 32.66, 41.45, 103.8, 866, 608, 431],
[55.66, 34.22, 31.7, 32.5, 32.67, 41.38, 104.03, 873, 610, 430],
[55.66, 34.21, 31.72, 32.51, 32.69, 41.33, 103.89, 896, 626, 434],
[55.66, 34.21, 31.73, 32.53, 32.7, 41.24, 103.71, 890, 622, 434],
[55.66, 34.2, 31.74, 32.54, 32.71, 41.18, 103.96, 897, 625, 434],
[55.91, 34.19, 31.75, 32.55, 32.72, 41.12, 103.86, 892, 622, 434],
[56.07, 34.19, 31.76, 32.56, 32.73, 41.04, 103.97, 894, 623, 433],
[55.58, 34.18, 31.77, 32.58, 32.75, 40.96, 104.01, 891, 622, 433],
[55.5, 34.16, 31.78, 32.59, 32.76, 40.9, 103.83, 882, 615, 432],
[55.54, 34.15, 31.8, 32.6, 32.77, 40.84, 104.06, 879, 613, 431],
[55.58, 34.14, 31.81, 32.61, 32.78, 40.77, 104.2, 889, 623, 434],
[55.5, 34.12, 31.82, 32.62, 32.79, 40.68, 104.29, 900, 629, 434],
[55.46, 34.1, 31.83, 32.63, 32.8, 40.61, 103.74, 909, 633, 435],
[55.5, 34.08, 31.84, 32.64, 32.81, 40.55, 104.03, 906, 631, 435],
[55.58, 34.05, 31.86, 32.67, 32.84, 40.46, 103.65, 883, 618, 433],
[55.5, 34.04, 31.87, 32.68, 32.85, 40.38, 103.87, 894, 619, 433],
[55.58, 34.03, 31.89, 32.69, 32.86, 40.31, 104.09, 899, 626, 434],
[55.54, 34.01, 31.89, 32.7, 32.87, 40.25, 104.26, 906, 630, 435],
[55.66, 33.98, 31.91, 32.71, 32.88, 40.17, 104.33, 907, 632, 435],
[55.42, 33.95, 31.91, 32.72, 32.89, 40.08, 104.49, 913, 634, 436],
[55.34, 33.92, 31.92, 32.73, 32.9, 40.01, 104.56, 922, 641, 437],
[55.34, 33.89, 31.93, 32.74, 32.91, 39.96, 104.77, 909, 631, 436],
[55.3, 33.85, 31.94, 32.75, 32.91, 39.91, 105.27, 916, 636, 436],
[55.38, 33.81, 31.95, 32.76, 32.92, 39.81, 105.01, 913, 635, 436],
[55.14, 33.78, 31.96, 32.77, 32.93, 39.75, 105.12, 914, 635, 436],
[55.34, 33.74, 31.97, 32.78, 32.94, 39.68, 105.5, 914, 635, 436],
[55.14, 33.7, 31.98, 32.79, 32.95, 39.61, 105.64, 912, 637, 436],
[54.94, 33.66, 31.98, 32.8, 32.96, 39.55, 105.72, 911, 634, 436],
[54.98, 33.62, 31.99, 32.8, 32.96, 39.47, 105.52, 906, 629, 435],
[55.22, 33.58, 32, 32.81, 32.97, 39.41, 105.49, 907, 633, 436],
[55.18, 33.54, 32, 32.82, 32.98, 39.36, 105.58, 908, 631, 436],
[54.9, 33.51, 32.01, 32.83, 32.99, 39.32, 105.85, 906, 631, 435],
[54.81, 33.47, 32.01, 32.84, 32.99, 39.26, 105.39, 903, 628, 435],
[54.94, 33.43, 32.02, 32.84, 33, 39.2, 105.71, 905, 628, 435],
[54.81, 33.4, 32.03, 32.85, 33, 39.15, 105.96, 905, 630, 436],
[54.73, 33.36, 32.03, 32.86, 33.01, 39.12, 106.08, 914, 635, 436],
[54.65, 33.32, 32.04, 32.86, 33.01, 39.08, 105.76, 910, 634, 436],
[54.65, 33.28, 32.04, 32.87, 33.02, 39.08, 106.07, 905, 629, 436],
[54.57, 33.24, 32.05, 32.88, 33.02, 39, 105.85, 897, 626, 434],
[54.57, 33.21, 32.05, 32.88, 33.03, 38.96, 106.03, 898, 625, 434],
[54.5, 33.16, 32.05, 32.89, 33.03, 38.91, 106.36, 897, 624, 434],
[54.38, 33.13, 32.06, 32.89, 33.04, 38.89, 106.02, 900, 628, 435],
[54.42, 33.09, 32.06, 32.9, 33.04, 38.83, 106.45, 899, 626, 435],
[54.69, 33.05, 32.07, 32.9, 33.05, 38.82, 106.64, 904, 628, 435],
[54.57, 33.01, 32.06, 32.9, 33.05, 38.78, 106.82, 906, 630, 435],
[54.22, 32.96, 32.07, 32.91, 33.05, 38.73, 106.76, 904, 632, 435],
[54.22, 32.91, 32.07, 32.91, 33.05, 38.67, 106.68, 906, 628, 436],
[54.1, 32.87, 32.07, 32.92, 33.06, 38.64, 106.77, 902, 627, 435],
[53.94, 32.82, 32.07, 32.92, 33.06, 38.62, 107.08, 888, 620, 434],
[54.02, 32.78, 32.08, 32.92, 33.06, 38.6, 107.03, 874, 610, 432],
[53.86, 32.74, 32.08, 32.92, 33.06, 38.55, 106.98, 896, 625, 434],
[53.78, 32.7, 32.08, 32.93, 33.06, 38.53, 106.97, 906, 631, 435],
[53.78, 32.65, 32.08, 32.93, 33.06, 38.49, 107.31, 907, 630, 435],
[53.82, 32.62, 32.08, 32.93, 33.06, 38.47, 106.89, 911, 634, 436],
[53.74, 32.59, 32.08, 32.93, 33.06, 38.41, 106.88, 907, 632, 436],
[53.86, 32.56, 32.08, 32.93, 33.07, 38.34, 106.95, 910, 631, 436],
[53.78, 32.54, 32.08, 32.93, 33.07, 38.31, 106.58, 913, 634, 437],
[53.86, 32.52, 32.08, 32.93, 33.06, 38.27, 106.66, 915, 635, 436],
[53.67, 32.51, 32.08, 32.93, 33.06, 38.23, 106.37, 910, 632, 436],
[53.82, 32.5, 32.08, 32.93, 33.06, 38.17, 106.26, 914, 634, 436],
[53.98, 32.49, 32.08, 32.94, 33.06, 38.12, 106.3, 910, 633, 436],
[53.9, 32.48, 32.08, 32.93, 33.06, 38.07, 106.12, 919, 637, 437],
[54.02, 32.47, 32.08, 32.93, 33.06, 38.03, 106.2, 907, 632, 435],
[53.94, 32.47, 32.08, 32.93, 33.06, 37.99, 106.27, 907, 633, 435],
[53.9, 32.46, 32.08, 32.93, 33.06, 37.93, 105.68, 894, 624, 435],
[53.86, 32.46, 32.08, 32.93, 33.06, 37.92, 106.15, 905, 633, 435],
[54.1, 32.45, 32.07, 32.93, 33.06, 37.87, 105.93, 903, 631, 435],
[53.82, 32.43, 32.08, 32.93, 33.06, 37.81, 105.79, 898, 625, 435],
[53.74, 32.42, 32.07, 32.93, 33.06, 37.74, 106.24, 890, 621, 433],
[53.78, 32.41, 32.07, 32.93, 33.06, 37.7, 106.18, 890, 620, 432],
[53.82, 32.39, 32.07, 32.93, 33.05, 37.64, 106.46, 883, 615, 432],
[53.78, 32.37, 32.07, 32.93, 33.05, 37.59, 106.6, 887, 616, 431],
[54.18, 32.35, 32.07, 32.93, 33.05, 37.55, 106.44, 911, 633, 436],
[53.82, 32.33, 32.07, 32.93, 33.05, 37.49, 106.81, 906, 630, 435],
[53.71, 32.31, 32.06, 32.93, 33.05, 37.46, 106.72, 905, 630, 436],
[53.71, 32.29, 32.06, 32.92, 33.04, 37.43, 107.12, 896, 625, 434],
[53.74, 32.26, 32.06, 32.92, 33.04, 37.37, 107.3, 905, 630, 435],
[53.51, 32.23, 32.05, 32.92, 33.04, 37.32, 107.07, 910, 631, 436],
[53.63, 32.2, 32.05, 32.92, 33.04, 37.29, 107.12, 908, 631, 436],
[53.35, 32.17, 32.05, 32.92, 33.04, 37.23, 106.94, 904, 627, 434],
[53.47, 32.15, 32.05, 32.91, 33.03, 37.19, 107.44, 888, 615, 434],
[53.55, 32.12, 32.04, 32.91, 33.03, 37.14, 107.55, 878, 612, 432],
[53.55, 32.09, 32.04, 32.91, 33.03, 37.12, 107.65, 880, 614, 431],
[53.31, 32.06, 32.03, 32.91, 33.02, 37.07, 107.83, 904, 630, 435],
[53.31, 32.02, 32.03, 32.9, 33.02, 37.05, 107.82, 905, 631, 435],
[53.12, 31.99, 32.03, 32.9, 33.02, 36.99, 107.69, 896, 624, 434],
[53.12, 31.95, 32.02, 32.9, 33.01, 36.97, 107.94, 909, 633, 436],
[53.01, 31.91, 32.02, 32.89, 33.01, 36.94, 108.09, 901, 628, 435],
[52.97, 31.88, 32.01, 32.89, 33, 36.88, 108.17, 901, 629, 435],
[52.93, 31.84, 32.01, 32.88, 33, 36.83, 107.97, 901, 626, 435],
[52.97, 31.81, 32, 32.88, 33, 36.79, 107.75, 897, 625, 434],
[52.89, 31.79, 32, 32.88, 32.99, 36.72, 107.83, 894, 622, 433],
[52.93, 31.76, 32, 32.87, 32.98, 36.69, 107.79, 905, 630, 435],
[52.97, 31.75, 31.99, 32.87, 32.98, 36.64, 107.59, 906, 630, 435],
[52.93, 31.73, 31.99, 32.86, 32.97, 36.58, 107.42, 913, 636, 437],
[53.12, 31.72, 31.98, 32.86, 32.97, 36.51, 107.38, 910, 634, 436],
[53.08, 31.71, 31.97, 32.85, 32.96, 36.47, 107.21, 907, 632, 436],
[52.74, 31.71, 31.96, 32.84, 32.95, 36.43, 106.99, 903, 631, 436],
[53.08, 31.71, 31.96, 32.84, 32.95, 36.39, 107.14, 890, 620, 434],
[46.22, 31.6, 31.87, 33.11, 33.32, 36.35, 103.35, 904, 637, 438],
[48.26, 31.61, 31.87, 33.06, 33.24, 36.3, 103.82, 908, 639, 438],
[49.43, 31.62, 31.87, 33.02, 33.18, 36.26, 104.17, 908, 638, 437],
[49.65, 31.62, 31.87, 32.99, 33.13, 36.21, 104.38, 903, 635, 437],
[50.15, 31.63, 31.87, 32.95, 33.09, 36.14, 104.44, 905, 633, 437],
[50.37, 31.63, 31.87, 32.92, 33.05, 36.1, 104.91, 899, 632, 436],
[50.56, 31.62, 31.86, 32.9, 33.02, 36.05, 105.1, 896, 628, 435],
[50.81, 31.61, 31.86, 32.88, 32.99, 35.99, 105.25, 898, 628, 436],
[50.81, 31.59, 31.86, 32.86, 32.97, 35.94, 105.72, 888, 623, 434],
[50.96, 31.57, 31.85, 32.84, 32.94, 35.89, 106.16, 905, 635, 437],
[50.93, 31.54, 31.85, 32.82, 32.93, 35.84, 106.47, 894, 628, 436],
[51, 31.5, 31.84, 32.81, 32.91, 35.8, 106.68, 883, 620, 434],
[51, 31.46, 31.84, 32.79, 32.89, 35.77, 106.85, 883, 619, 433],
[51.04, 31.42, 31.83, 32.78, 32.88, 35.71, 106.89, 894, 627, 435],
[51.11, 31.38, 31.83, 32.77, 32.87, 35.67, 106.9, 889, 625, 434],
[51.04, 31.33, 31.82, 32.76, 32.86, 35.62, 107.01, 892, 625, 434],
[51.11, 31.29, 31.81, 32.74, 32.84, 35.56, 107.09, 884, 620, 434],
[51.26, 31.25, 31.81, 32.73, 32.83, 35.51, 107, 882, 619, 433],
[51.22, 31.22, 31.8, 32.72, 32.82, 35.45, 106.88, 878, 617, 433],
[51.22, 31.19, 31.79, 32.71, 32.81, 35.42, 106.61, 889, 620, 433],
[51.37, 31.17, 31.79, 32.7, 32.8, 35.39, 106.47, 891, 623, 434],
[51.45, 31.16, 31.78, 32.69, 32.79, 35.33, 105.99, 890, 622, 434],
[51.75, 31.14, 31.77, 32.68, 32.78, 35.31, 106.11, 894, 625, 435],
[51.52, 31.13, 31.77, 32.68, 32.77, 35.3, 105.9, 890, 621, 434],
[51.6, 31.12, 31.76, 32.67, 32.77, 35.29, 105.61, 886, 617, 433],
[51.67, 31.12, 31.76, 32.66, 32.76, 35.24, 105.82, 890, 621, 434],
[51.82, 31.11, 31.75, 32.65, 32.75, 35.2, 105.92, 891, 622, 433],
[51.78, 31.11, 31.74, 32.64, 32.74, 35.16, 105.97, 889, 620, 434],
[51.82, 31.11, 31.73, 32.63, 32.73, 35.08, 106.12, 888, 620, 433],
[51.9, 31.1, 31.72, 32.62, 32.72, 35.05, 105.93, 886, 621, 434],
[51.94, 31.09, 31.71, 32.61, 32.71, 34.98, 106.2, 895, 625, 434],
[51.75, 31.08, 31.71, 32.61, 32.71, 34.91, 106.36, 888, 621, 433],
[51.9, 31.07, 31.7, 32.6, 32.7, 34.84, 106.43, 892, 623, 433],
[52.01, 31.05, 31.7, 32.59, 32.69, 34.77, 106.44, 887, 619, 433],
[51.9, 31.05, 31.69, 32.58, 32.68, 34.74, 106.53, 882, 617, 433],
[51.94, 31.03, 31.68, 32.57, 32.67, 34.65, 106.79, 887, 620, 433],
[51.94, 31.02, 31.67, 32.56, 32.66, 34.56, 106.77, 889, 619, 434],
[51.86, 31.01, 31.66, 32.56, 32.66, 34.5, 106.45, 893, 624, 434],
[51.94, 30.99, 31.65, 32.55, 32.65, 34.42, 106.82, 894, 624, 434],
[51.82, 30.98, 31.65, 32.54, 32.64, 34.37, 107.01, 895, 626, 435],
[51.78, 30.96, 31.63, 32.53, 32.63, 34.29, 107.21, 896, 625, 434],
[51.86, 30.95, 31.63, 32.52, 32.62, 34.22, 107.27, 898, 628, 435],
[51.71, 30.93, 31.62, 32.51, 32.61, 34.15, 106.78, 897, 626, 434],
[51.86, 30.92, 31.61, 32.51, 32.6, 34.07, 107.05, 891, 622, 434],
[51.75, 30.91, 31.6, 32.5, 32.6, 34.02, 106.61, 901, 630, 435],
[51.97, 30.91, 31.59, 32.49, 32.59, 33.95, 106.51, 904, 631, 436],
[51.9, 30.9, 31.59, 32.48, 32.58, 33.88, 106.41, 907, 632, 436],
[51.94, 30.9, 31.58, 32.47, 32.57, 33.81, 106.26, 898, 625, 435],
[51.9, 30.9, 31.57, 32.46, 32.56, 33.75, 106.44, 884, 618, 433],
[52.09, 30.91, 31.56, 32.46, 32.56, 33.7, 106.55, 883, 617, 433],
[52.05, 30.9, 31.56, 32.45, 32.55, 33.64, 106.89, 886, 619, 434],
[52.2, 30.9, 31.55, 32.44, 32.54, 33.58, 106.89, 889, 619, 433],
[51.9, 30.89, 31.54, 32.43, 32.53, 33.51, 106.95, 890, 623, 433],
[51.86, 30.89, 31.53, 32.42, 32.52, 33.45, 106.89, 893, 622, 434],
[51.9, 30.88, 31.52, 32.41, 32.51, 33.41, 106.74, 885, 616, 433],
[52.05, 30.89, 31.51, 32.41, 32.5, 33.34, 106.66, 883, 615, 432],
[52.01, 30.9, 31.5, 32.4, 32.5, 33.26, 106.46, 888, 619, 433],
[52.31, 30.91, 31.5, 32.39, 32.49, 33.24, 106.02, 894, 624, 434],
[52.35, 30.93, 31.49, 32.38, 32.48, 33.19, 106.15, 895, 625, 434],
[52.2, 30.94, 31.48, 32.37, 32.47, 33.12, 106, 889, 622, 435],
[52.24, 30.96, 31.47, 32.37, 32.46, 33.05, 105.9, 882, 617, 433],
[52.24, 30.97, 31.46, 32.36, 32.46, 32.99, 105.76, 893, 623, 433],
[52.28, 30.98, 31.46, 32.35, 32.45, 32.95, 105.51, 892, 625, 434],
[52.28, 31.01, 31.45, 32.34, 32.44, 32.9, 105.73, 894, 624, 434],
[52.35, 31.03, 31.44, 32.34, 32.44, 32.83, 105.27, 896, 624, 435],
[52.39, 31.06, 31.44, 32.33, 32.43, 32.75, 105.64, 882, 617, 433],
[52.54, 31.07, 31.43, 32.32, 32.42, 32.67, 105.71, 884, 617, 432],
[52.51, 31.1, 31.42, 32.31, 32.42, 32.59, 105.52, 903, 630, 436],
[52.51, 31.13, 31.42, 32.31, 32.41, 32.5, 105.16, 914, 637, 437],
[52.58, 31.16, 31.42, 32.3, 32.4, 32.42, 105.09, 913, 637, 437],
[52.7, 31.23, 31.4, 32.29, 32.39, 32.34, 104.66, 914, 637, 437],
[53.08, 31.28, 31.4, 32.28, 32.39, 32.29, 104.51, 917, 640, 438],
[53.12, 31.34, 31.39, 32.28, 32.38, 32.24, 104.66, 902, 630, 437],
[53.12, 31.4, 31.39, 32.27, 32.38, 32.17, 104.41, 896, 623, 435],
[53.28, 31.47, 31.39, 32.27, 32.37, 32.09, 104.43, 907, 634, 436],
[53.59, 31.55, 31.38, 32.26, 32.37, 32.03, 104.58, 916, 637, 437],
[53.47, 31.62, 31.38, 32.26, 32.36, 31.95, 104.41, 919, 638, 437],
[53.74, 31.7, 31.38, 32.25, 32.36, 31.87, 104.37, 925, 642, 438],
[53.59, 31.76, 31.38, 32.25, 32.36, 31.79, 104.36, 929, 644, 438],
[53.74, 31.83, 31.37, 32.25, 32.36, 31.72, 103.95, 922, 641, 438],
[53.86, 31.91, 31.37, 32.24, 32.35, 31.64, 104.32, 921, 641, 438],
[53.86, 31.97, 31.37, 32.24, 32.35, 31.59, 104.3, 921, 639, 438],
[53.94, 32.04, 31.37, 32.24, 32.35, 31.52, 103.86, 919, 641, 438],
[53.98, 32.1, 31.37, 32.23, 32.35, 31.45, 104.17, 933, 650, 432],
[54.02, 32.17, 31.37, 32.23, 32.35, 31.41, 104.22, 925, 643, 438],
[54.1, 32.23, 31.37, 32.23, 32.35, 31.35, 103.77, 922, 641, 438],
[54.14, 32.29, 31.37, 32.23, 32.35, 31.28, 103.97, 923, 641, 437],
[54.42, 32.35, 31.37, 32.23, 32.35, 31.24, 104.18, 916, 639, 437],
[54.22, 32.4, 31.37, 32.23, 32.35, 31.2, 104.4, 893, 624, 436],
[54.26, 32.46, 31.38, 32.23, 32.35, 31.12, 104.19, 884, 619, 434],
[54.26, 32.5, 31.38, 32.23, 32.36, 31.04, 104.2, 906, 633, 436],
[54.34, 32.55, 31.38, 32.23, 32.36, 30.98, 103.68, 911, 634, 437],
[54.38, 32.61, 31.38, 32.23, 32.36, 30.94, 103.9, 900, 628, 436],
[54.3, 32.67, 31.39, 32.23, 32.36, 30.88, 104.12, 890, 621, 434],
[54.57, 32.72, 31.39, 32.24, 32.37, 30.82, 104.12, 873, 609, 432],
[54.5, 32.77, 31.39, 32.24, 32.37, 30.76, 103.9, 890, 622, 433],
[54.57, 32.83, 31.39, 32.24, 32.37, 30.71, 103.87, 881, 615, 432],
[54.77, 32.87, 31.4, 32.24, 32.38, 30.64, 103.75, 882, 616, 432],
[54.65, 32.92, 31.4, 32.25, 32.38, 30.58, 103.63, 893, 624, 434],
[54.9, 32.97, 31.41, 32.25, 32.39, 30.56, 103.54, 905, 632, 435],
[54.85, 33.03, 31.41, 32.25, 32.39, 30.51, 103.4, 893, 623, 434],
[54.81, 33.08, 31.42, 32.26, 32.4, 30.44, 103.39, 898, 624, 433],
[54.94, 33.14, 31.43, 32.26, 32.4, 30.36, 103.66, 904, 630, 435],
[55.02, 33.2, 31.43, 32.27, 32.41, 30.29, 103.57, 901, 629, 435],
[55.06, 33.27, 31.44, 32.27, 32.41, 30.23, 103.06, 900, 628, 435],
[55.26, 33.33, 31.45, 32.27, 32.42, 30.2, 103.03, 900, 627, 435],
[55.66, 33.4, 31.45, 32.28, 32.43, 30.13, 102.8, 889, 621, 433],
[55.42, 33.48, 31.46, 32.29, 32.43, 30.08, 103.35, 905, 632, 435],
[55.54, 33.54, 31.47, 32.29, 32.44, 30.02, 103.2, 909, 634, 436],
[55.58, 33.6, 31.47, 32.3, 32.45, 29.95, 102.76, 905, 630, 436],
[55.95, 33.68, 31.49, 32.31, 32.46, 29.9, 103.11, 907, 630, 435],
[55.7, 33.75, 31.49, 32.31, 32.46, 29.83, 102.61, 900, 628, 434],
[55.79, 33.82, 31.5, 32.32, 32.47, 29.77, 102.56, 899, 625, 434],
[55.99, 33.9, 31.51, 32.33, 32.48, 29.73, 103.07, 898, 623, 434],
[56.12, 33.98, 31.52, 32.34, 32.49, 29.68, 102.62, 896, 625, 434],
[56.16, 34.06, 31.53, 32.35, 32.5, 29.65, 103.09, 905, 628, 435],
[56.16, 34.13, 31.55, 32.36, 32.51, 29.58, 103.13, 896, 625, 435],
[56.28, 34.19, 31.56, 32.37, 32.52, 29.52, 103.21, 882, 615, 432],
[56.45, 34.25, 31.57, 32.38, 32.54, 29.44, 103.37, 903, 627, 434],
[56.32, 34.31, 31.58, 32.39, 32.55, 29.38, 103.22, 905, 629, 435],
[56.36, 34.37, 31.59, 32.4, 32.56, 29.37, 103.24, 907, 631, 435],
[56.53, 34.42, 31.6, 32.41, 32.57, 29.28, 103.3, 906, 631, 436],
[56.53, 34.47, 31.62, 32.42, 32.58, 29.23, 103.23, 904, 631, 435],
[56.49, 34.52, 31.63, 32.43, 32.6, 29.15, 103.19, 906, 631, 435],
[56.49, 34.57, 31.64, 32.44, 32.61, 29.1, 102.84, 904, 630, 435],
[56.65, 34.62, 31.66, 32.45, 32.62, 29.07, 102.75, 906, 630, 435],
[56.65, 34.69, 31.67, 32.47, 32.64, 29.02, 102.65, 914, 635, 435],
[56.74, 34.76, 31.69, 32.48, 32.65, 28.98, 103.3, 906, 629, 435],
[56.74, 34.83, 31.7, 32.49, 32.66, 28.93, 103.2, 901, 628, 435],
[56.99, 34.9, 31.72, 32.51, 32.68, 28.89, 102.97, 907, 632, 434],
[57.03, 34.96, 31.73, 32.52, 32.69, 28.85, 103.22, 903, 630, 435],
[57.11, 35.02, 31.75, 32.53, 32.71, 28.8, 103.39, 903, 627, 434],
[57.24, 35.08, 31.76, 32.55, 32.72, 28.75, 103.82, 904, 633, 435],
[57.16, 35.12, 31.78, 32.56, 32.74, 28.7, 103.53, 903, 629, 435],
[56.99, 35.17, 31.8, 32.58, 32.75, 28.64, 103.29, 903, 629, 436],
[57.28, 35.21, 31.81, 32.59, 32.77, 28.56, 103.81, 902, 627, 434],
[57.24, 35.26, 31.83, 32.61, 32.79, 28.49, 103.31, 902, 630, 434],
[57.24, 35.32, 31.84, 32.62, 32.8, 28.41, 103.71, 905, 630, 434],
[57.11, 35.36, 31.86, 32.64, 32.82, 28.33, 103.96, 906, 632, 435],
[57.37, 35.41, 31.88, 32.65, 32.84, 28.25, 103.44, 905, 628, 434],
[57.28, 35.46, 31.9, 32.67, 32.86, 28.18, 103.81, 908, 632, 435],
[57.62, 35.5, 31.91, 32.69, 32.87, 28.12, 103.7, 903, 626, 435],
[57.62, 35.55, 31.93, 32.71, 32.89, 28.06, 103.39, 897, 624, 433],
[57.62, 35.59, 31.95, 32.72, 32.91, 27.98, 104.09, 913, 634, 435],
[57.84, 35.62, 31.97, 32.74, 32.93, 27.91, 103.5, 906, 630, 434],
[57.58, 35.66, 31.99, 32.76, 32.95, 27.82, 103.39, 905, 630, 435],
[57.79, 35.7, 32.01, 32.78, 32.96, 27.77, 103.63, 902, 625, 434],
[57.96, 35.74, 32.02, 32.79, 32.98, 27.71, 104.12, 902, 627, 434],
[57.79, 35.76, 32.04, 32.81, 33, 27.64, 104.62, 900, 625, 434],
[58.09, 35.76, 32.06, 32.83, 33.02, 27.57, 104.9, 901, 627, 434],
[58.01, 35.77, 32.08, 32.85, 33.04, 27.5, 105.13, 894, 621, 433],
[57.62, 35.77, 32.1, 32.87, 33.06, 27.46, 104.48, 899, 624, 433],
[57.71, 35.77, 32.12, 32.88, 33.08, 27.38, 104.64, 889, 616, 433],
[57.92, 35.77, 32.14, 32.9, 33.1, 27.28, 104.81, 888, 618, 431],
[57.5, 35.76, 32.16, 32.92, 33.11, 27.21, 105.22, 907, 629, 434],
[57.67, 35.75, 32.17, 32.94, 33.13, 27.14, 104.82, 906, 631, 435],
[57.62, 35.74, 32.2, 32.96, 33.15, 27.08, 104.85, 900, 625, 433],
[57.54, 35.72, 32.21, 32.98, 33.17, 27, 104.59, 887, 618, 433],
[57.45, 35.71, 32.23, 32.99, 33.19, 26.94, 105.13, 898, 625, 433],
[57.75, 35.7, 32.25, 33.01, 33.2, 26.86, 105.3, 906, 631, 435],
[57.71, 35.68, 32.27, 33.03, 33.22, 26.8, 104.77, 906, 629, 435],
[57.5, 35.68, 32.29, 33.05, 33.24, 26.76, 105.02, 904, 630, 434],
[57.45, 35.67, 32.3, 33.07, 33.26, 26.72, 104.84, 908, 631, 434],
[57.71, 35.65, 32.32, 33.08, 33.28, 26.67, 105.08, 911, 634, 435],
[57.41, 35.63, 32.34, 33.1, 33.29, 26.63, 105.34, 909, 634, 435],
[57.37, 35.61, 32.35, 33.12, 33.31, 26.6, 104.97, 908, 631, 435],
[57.33, 35.59, 32.37, 33.14, 33.33, 26.53, 105.4, 906, 631, 435],
[57.33, 35.56, 32.39, 33.16, 33.35, 26.47, 105.97, 896, 625, 433],
[57.16, 35.51, 32.4, 33.17, 33.36, 26.42, 106.53, 910, 633, 435],
[56.95, 35.46, 32.41, 33.19, 33.38, 26.37, 106.34, 911, 634, 435],
[56.82, 35.4, 32.43, 33.21, 33.39, 26.32, 106.59, 905, 630, 435],
[56.65, 35.33, 32.45, 33.22, 33.41, 26.23, 106.66, 905, 630, 435],
[56.53, 35.27, 32.46, 33.24, 33.43, 26.2, 107.17, 899, 627, 434],
[56.45, 35.2, 32.47, 33.25, 33.44, 26.12, 106.7, 897, 625, 434],
[56.4, 35.13, 32.49, 33.27, 33.45, 26.07, 106.64, 900, 626, 434],
[56.57, 35.08, 32.5, 33.29, 33.47, 25.98, 107.13, 889, 620, 432],
[56.45, 35.02, 32.52, 33.3, 33.48, 25.87, 107.22, 897, 623, 433],
[56.24, 34.97, 32.53, 33.31, 33.49, 25.79, 106.8, 895, 624, 434],
[56.32, 34.91, 32.54, 33.33, 33.51, 25.74, 106.7, 906, 629, 435],
[56.12, 34.86, 32.55, 33.34, 33.52, 25.69, 107.38, 903, 628, 434],
[56.07, 34.81, 32.56, 33.35, 33.53, 25.67, 107.09, 903, 627, 434],
[56.03, 34.77, 32.57, 33.37, 33.54, 25.62, 106.95, 902, 627, 435],
[56.07, 34.73, 32.58, 33.38, 33.55, 25.56, 107.05, 894, 622, 433],
[56.07, 34.69, 32.6, 33.39, 33.56, 25.51, 106.72, 892, 621, 432],
[56.12, 34.66, 32.6, 33.4, 33.58, 25.43, 107.3, 882, 613, 431],
[56.12, 34.63, 32.62, 33.42, 33.59, 25.36, 106.99, 902, 624, 434],
[56.03, 34.6, 32.63, 33.43, 33.6, 25.33, 107.34, 904, 628, 434],
[55.99, 34.57, 32.64, 33.44, 33.61, 25.26, 107.58, 893, 622, 433],
[56.2, 34.53, 32.65, 33.45, 33.62, 25.2, 107.76, 883, 615, 432],
[56.03, 34.49, 32.66, 33.46, 33.63, 25.13, 107.78, 883, 614, 431],
[55.83, 34.44, 32.67, 33.47, 33.63, 25.07, 108.04, 883, 615, 431],
[55.87, 34.39, 32.68, 33.48, 33.64, 25.01, 107.94, 896, 624, 433],
[55.99, 34.33, 32.68, 33.49, 33.65, 24.98, 108.08, 899, 627, 434],
[55.5, 34.27, 32.69, 33.5, 33.66, 24.91, 108.1, 890, 618, 432],
[55.54, 34.21, 32.69, 33.51, 33.67, 24.86, 108.08, 904, 629, 434],
[55.42, 34.15, 32.7, 33.52, 33.67, 24.83, 108.65, 897, 623, 434],
[55.22, 34.08, 32.71, 33.52, 33.68, 24.79, 108.76, 892, 621, 433],
[55.1, 34.02, 32.71, 33.53, 33.69, 24.77, 108.59, 903, 627, 435],
[55.06, 33.96, 32.72, 33.54, 33.69, 24.74, 108.84, 890, 619, 433],
[55.22, 33.9, 32.72, 33.54, 33.7, 24.71, 108.92, 885, 617, 432],
[54.9, 33.84, 32.73, 33.55, 33.7, 24.65, 108.79, 905, 632, 435],
[54.94, 33.78, 32.73, 33.56, 33.71, 24.6, 109.37, 912, 634, 436],
[54.73, 33.72, 32.73, 33.56, 33.71, 24.53, 109.16, 911, 633, 436],
[54.85, 33.66, 32.74, 33.57, 33.71, 24.45, 109.47, 905, 629, 436],
[54.77, 33.61, 32.74, 33.57, 33.72, 24.39, 109.58, 910, 633, 436],
[54.53, 33.54, 32.74, 33.58, 33.72, 24.32, 109.54, 911, 631, 436],
[54.46, 33.49, 32.75, 33.58, 33.72, 24.29, 109.41, 914, 635, 436],
[54.61, 33.43, 32.75, 33.58, 33.73, 24.23, 109.29, 913, 634, 436],
[54.38, 33.38, 32.75, 33.59, 33.73, 24.21, 109.29, 913, 633, 436],
[54.38, 33.33, 32.75, 33.59, 33.73, 24.16, 109.36, 903, 628, 435],
[54.38, 33.29, 32.75, 33.59, 33.73, 24.11, 109.1, 901, 629, 435],
[54.57, 33.26, 32.75, 33.6, 33.73, 24.08, 109.59, 908, 633, 436],
[54.61, 33.23, 32.75, 33.6, 33.73, 24.03, 109.63, 916, 637, 436],
[54.53, 33.19, 32.75, 33.6, 33.73, 23.94, 109.73, 905, 629, 436],
[54.38, 33.16, 32.76, 33.6, 33.73, 23.8, 109.64, 905, 628, 435],
[54.38, 33.13, 32.75, 33.6, 33.73, 23.66, 110.01, 891, 619, 433],
[54.3, 33.1, 32.75, 33.6, 33.73, 23.53, 109.61, 899, 626, 434],
[54.38, 33.08, 32.75, 33.6, 33.73, 23.44, 109.64, 898, 627, 434],
[54.38, 33.04, 32.75, 33.6, 33.73, 23.36, 109.85, 911, 634, 436],
[54.3, 33.02, 32.75, 33.6, 33.73, 23.27, 110.02, 908, 630, 436],
[54.22, 32.99, 32.75, 33.6, 33.73, 23.2, 110.02, 908, 631, 435],
[54.38, 32.96, 32.75, 33.6, 33.73, 23.15, 110.07, 897, 624, 435],
[54.18, 32.92, 32.74, 33.6, 33.72, 23.1, 109.87, 902, 629, 435],
[54.02, 32.89, 32.75, 33.6, 33.72, 23.05, 110.32, 908, 632, 435],
[54.02, 32.86, 32.74, 33.6, 33.72, 23, 110.45, 905, 629, 435],
[53.94, 32.82, 32.74, 33.6, 33.72, 22.94, 110.41, 890, 620, 434],
[53.9, 32.78, 32.73, 33.59, 33.72, 22.88, 110.22, 890, 619, 433],
[53.94, 32.74, 32.73, 33.59, 33.71, 22.83, 110.44, 893, 623, 434],
[53.86, 32.71, 32.72, 33.59, 33.71, 22.81, 110.63, 897, 626, 434],
[53.94, 32.67, 32.72, 33.59, 33.71, 22.78, 110.76, 905, 627, 436],
[54.02, 32.64, 32.72, 33.58, 33.7, 22.77, 110.35, 897, 623, 434],
[53.74, 32.61, 32.71, 33.58, 33.7, 22.74, 110.47, 891, 621, 433],
[53.98, 32.58, 32.71, 33.58, 33.7, 22.75, 110.66, 896, 625, 433],
[53.74, 32.55, 32.71, 33.58, 33.69, 22.7, 110.47, 914, 637, 436],
[53.71, 32.52, 32.7, 33.57, 33.69, 22.66, 110.62, 915, 637, 437],
[53.71, 32.49, 32.7, 33.57, 33.68, 22.65, 110.15, 916, 638, 437],
[53.74, 32.47, 32.7, 33.56, 33.68, 22.64, 110.17, 905, 629, 436],
[53.63, 32.45, 32.69, 33.56, 33.67, 22.61, 110.57, 894, 623, 433],
[53.63, 32.43, 32.68, 33.55, 33.67, 22.63, 110.27, 897, 625, 434],
[53.59, 32.4, 32.68, 33.55, 33.66, 22.62, 110.37, 894, 623, 433],
[53.59, 32.38, 32.67, 33.55, 33.66, 22.59, 110.37, 890, 621, 433],
[53.59, 32.36, 32.67, 33.54, 33.65, 22.57, 110.25, 884, 617, 432],
[53.55, 32.34, 32.66, 33.54, 33.65, 22.58, 110.24, 892, 622, 433],
[53.51, 32.33, 32.66, 33.53, 33.64, 22.6, 110.34, 903, 629, 435],
[53.59, 32.31, 32.65, 33.53, 33.63, 22.62, 110.45, 900, 627, 435],
[53.59, 32.3, 32.65, 33.52, 33.63, 22.61, 110.62, 904, 628, 435],
[53.55, 32.28, 32.64, 33.51, 33.62, 22.57, 110.44, 898, 625, 435],
[53.67, 32.27, 32.64, 33.51, 33.62, 22.55, 110.5, 911, 632, 435],
[53.47, 32.25, 32.63, 33.5, 33.61, 22.51, 110.6, 909, 632, 436],
[53.67, 32.23, 32.62, 33.5, 33.61, 22.52, 110.45, 909, 633, 436],
[53.59, 32.22, 32.61, 33.49, 33.6, 22.51, 110.81, 903, 626, 435],
[53.47, 32.2, 32.61, 33.49, 33.59, 22.5, 110.6, 896, 622, 433],
[53.47, 32.19, 32.6, 33.48, 33.59, 22.48, 110.62, 908, 633, 436],
[53.63, 32.17, 32.59, 33.48, 33.58, 22.44, 110.99, 906, 632, 436],
[53.39, 32.15, 32.58, 33.47, 33.57, 22.43, 111.09, 901, 630, 435],
[53.55, 32.13, 32.58, 33.46, 33.57, 22.44, 111.18, 909, 634, 436],
[53.2, 32.09, 32.57, 33.46, 33.56, 22.4, 111.32, 907, 634, 436],
[53.12, 32.06, 32.57, 33.45, 33.55, 22.45, 111.63, 903, 632, 436],
[52.93, 32.01, 32.56, 33.44, 33.55, 22.43, 111.66, 898, 629, 435],
[52.81, 31.96, 32.55, 33.43, 33.54, 22.46, 111.84, 890, 624, 434],
[52.62, 31.9, 32.54, 33.43, 33.53, 22.47, 111.87, 892, 625, 434],
[52.51, 31.85, 32.53, 33.42, 33.52, 22.42, 111.98, 911, 637, 437],
[52.51, 31.79, 32.52, 33.41, 33.51, 22.39, 111.98, 917, 641, 438],
[52.35, 31.74, 32.51, 33.4, 33.51, 22.37, 110.88, 914, 637, 438],
[52.24, 31.7, 32.5, 33.4, 33.5, 22.42, 110.72, 915, 639, 438],
[52.31, 31.65, 32.49, 33.39, 33.49, 22.42, 110.5, 919, 641, 438],
[52.35, 31.62, 32.48, 33.38, 33.48, 22.38, 110.32, 919, 639, 438],
[52.58, 31.59, 32.47, 33.37, 33.47, 22.35, 110.39, 913, 635, 437],
[52.51, 31.57, 32.46, 33.36, 33.46, 22.35, 110.12, 914, 635, 437],
[52.43, 31.56, 32.46, 33.35, 33.45, 22.32, 110.15, 915, 634, 437],
[52.58, 31.56, 32.45, 33.34, 33.44, 22.27, 110.35, 918, 637, 437],
[52.58, 31.55, 32.44, 33.33, 33.43, 22.23, 109.81, 913, 634, 437],
[52.93, 31.55, 32.43, 33.32, 33.42, 22.22, 110.05, 910, 634, 437],
[52.74, 31.55, 32.42, 33.32, 33.41, 22.22, 109.66, 910, 633, 436],
[53.01, 31.55, 32.41, 33.31, 33.4, 22.19, 110, 911, 633, 437],
[52.85, 31.56, 32.4, 33.3, 33.39, 22.23, 109.9, 895, 620, 435],
[52.97, 31.56, 32.39, 33.29, 33.38, 22.24, 110.27, 908, 629, 435],
[52.81, 31.54, 32.38, 33.28, 33.37, 22.24, 110.55, 911, 632, 437],
[52.81, 31.52, 32.37, 33.27, 33.36, 22.29, 110.37, 915, 635, 437],
[53.04, 31.5, 32.37, 33.26, 33.35, 22.28, 110.36, 915, 634, 437],
[52.77, 31.48, 32.35, 33.25, 33.34, 22.31, 110.38, 913, 633, 437],
[52.7, 31.46, 32.34, 33.24, 33.33, 22.3, 110.22, 908, 630, 436],
[53.01, 31.44, 32.33, 33.23, 33.32, 22.31, 110.56, 905, 629, 436],
[52.77, 31.43, 32.33, 33.22, 33.31, 22.31, 110.03, 913, 633, 436],
[52.58, 31.42, 32.31, 33.21, 33.3, 22.28, 110.03, 914, 633, 436],
[52.81, 31.41, 32.31, 33.2, 33.29, 22.28, 110.4, 915, 634, 437],
[52.77, 31.39, 32.3, 33.19, 33.28, 22.24, 110.66, 911, 633, 436],
[53.01, 31.37, 32.29, 33.18, 33.27, 22.25, 110.89, 911, 633, 436],
[52.66, 31.35, 32.27, 33.17, 33.26, 22.22, 111.08, 916, 639, 437],
[52.85, 31.33, 32.26, 33.16, 33.25, 22.22, 111.23, 915, 640, 437],
[52.77, 31.29, 32.25, 33.15, 33.24, 22.24, 111.57, 912, 635, 437],
[52.43, 31.25, 32.24, 33.14, 33.23, 22.24, 111.7, 913, 638, 437],
[52.51, 31.22, 32.23, 33.13, 33.22, 22.24, 111.88, 914, 638, 437],
[52.16, 31.18, 32.21, 33.11, 33.21, 22.19, 111.83, 915, 638, 438],
[52.35, 31.14, 32.2, 33.1, 33.19, 22.19, 112.03, 914, 640, 438],
[52.01, 31.11, 32.19, 33.09, 33.18, 22.17, 112.21, 915, 639, 438],
[52.16, 31.07, 32.18, 33.08, 33.17, 22.15, 112.36, 916, 636, 438],
[51.9, 31.03, 32.16, 33.07, 33.16, 22.14, 112.42, 920, 640, 438],
[51.82, 30.99, 32.15, 33.06, 33.15, 22.09, 112.48, 919, 642, 439],
[51.78, 30.95, 32.14, 33.05, 33.14, 22.09, 112.61, 914, 635, 437],
[51.82, 30.91, 32.13, 33.04, 33.12, 22.07, 112.65, 919, 639, 438],
[51.71, 30.87, 32.12, 33.02, 33.11, 22.04, 112.64, 908, 633, 437],
[51.86, 30.84, 32.1, 33.01, 33.1, 22.01, 112.85, 905, 633, 436],
[51.82, 30.79, 32.09, 33, 33.09, 21.98, 112.92, 906, 634, 437],
[51.52, 30.75, 32.08, 32.99, 33.07, 21.96, 112.84, 905, 631, 436],
[51.52, 30.72, 32.06, 32.97, 33.06, 21.91, 112.72, 896, 624, 435],
[51.45, 30.68, 32.05, 32.96, 33.05, 21.93, 112.69, 896, 626, 435],
[51.3, 30.66, 32.04, 32.95, 33.03, 21.89, 112.69, 893, 627, 436],
[51.41, 30.62, 32.02, 32.94, 33.02, 21.89, 112.69, 905, 633, 437],
[51.37, 30.59, 32.01, 32.92, 33.01, 21.9, 112.34, 907, 633, 437],
[51.63, 30.57, 31.99, 32.91, 32.99, 21.86, 112.39, 907, 632, 436],
[51.52, 30.55, 31.98, 32.9, 32.98, 21.88, 112.43, 903, 630, 436],
[51.26, 30.53, 31.97, 32.88, 32.97, 21.83, 112.46, 898, 626, 435],
[51.71, 30.52, 31.96, 32.87, 32.95, 21.82, 112.66, 901, 630, 436],
[51.37, 30.5, 31.94, 32.86, 32.94, 21.81, 112.58, 905, 632, 436],
[51.37, 30.48, 31.93, 32.84, 32.92, 21.8, 112.73, 901, 629, 436],
[51.63, 30.47, 31.91, 32.83, 32.91, 21.73, 112.97, 898, 627, 435],
[51.26, 30.44, 31.9, 32.81, 32.9, 21.68, 113.09, 889, 622, 435],
[51.19, 30.4, 31.87, 32.79, 32.87, 21.68, 113.21, 904, 631, 436],
[51.04, 30.39, 31.86, 32.77, 32.85, 21.62, 112.96, 907, 633, 437],
[51.19, 30.36, 31.84, 32.76, 32.84, 21.57, 112.85, 910, 635, 437],
[51.04, 30.34, 31.83, 32.74, 32.83, 21.5, 112.29, 912, 634, 437],
[51.37, 30.33, 31.81, 32.73, 32.81, 21.41, 112.01, 910, 634, 437],
[51.22, 30.33, 31.8, 32.72, 32.8, 21.42, 111.87, 918, 639, 439],
[51.15, 30.34, 31.79, 32.7, 32.78, 21.32, 111.87, 912, 634, 437],
[51.48, 30.34, 31.77, 32.69, 32.77, 21.28, 111.33, 915, 637, 437],
[51.41, 30.36, 31.76, 32.68, 32.76, 21.25, 111.4, 922, 641, 438],
[51.45, 30.38, 31.75, 32.66, 32.74, 21.18, 111.52, 916, 637, 438],
[51.56, 30.39, 31.73, 32.65, 32.73, 21.13, 111.34, 915, 638, 438],
[51.56, 30.4, 31.72, 32.63, 32.71, 21.06, 111.31, 915, 638, 438],
[51.67, 30.42, 31.71, 32.62, 32.7, 21.05, 111, 916, 638, 438],
[51.63, 30.43, 31.7, 32.61, 32.69, 21.03, 110.88, 916, 637, 437],
[51.82, 30.46, 31.68, 32.59, 32.68, 20.97, 110.72, 915, 636, 437],
[51.9, 30.48, 31.67, 32.58, 32.66, 20.96, 110.96, 915, 637, 438],
[51.9, 30.51, 31.66, 32.57, 32.65, 20.9, 110.68, 911, 634, 437],
[52.2, 30.53, 31.65, 32.56, 32.64, 20.85, 110.52, 911, 634, 437],
[52.01, 30.57, 31.64, 32.54, 32.63, 20.8, 110.88, 906, 634, 436],
[52.01, 30.6, 31.62, 32.53, 32.62, 20.76, 110.67, 912, 635, 437],
[52.16, 30.63, 31.61, 32.52, 32.6, 20.7, 110.75, 908, 631, 436],
[52.2, 30.66, 31.6, 32.51, 32.59, 20.62, 110.64, 908, 630, 436],
[52.24, 30.69, 31.59, 32.5, 32.58, 20.45, 110.39, 905, 628, 436],
[52.43, 30.72, 31.58, 32.49, 32.57, 20.34, 110.67, 898, 623, 434],
[52.35, 30.76, 31.57, 32.47, 32.56, 20.2, 110.35, 902, 630, 435],
[52.39, 30.78, 31.56, 32.46, 32.55, 20.11, 110.46, 898, 625, 434],
[52.39, 30.81, 31.55, 32.45, 32.54, 20, 110.51, 909, 633, 436],
[52.47, 30.82, 31.54, 32.44, 32.53, 19.89, 110.06, 890, 621, 435],
[52.39, 30.84, 31.53, 32.43, 32.52, 19.78, 110, 897, 625, 434],
[52.39, 30.87, 31.52, 32.42, 32.51, 19.67, 110.04, 913, 636, 437],
[52.47, 30.89, 31.51, 32.41, 32.5, 19.58, 110.04, 907, 633, 436],
[52.47, 30.92, 31.51, 32.4, 32.5, 19.48, 109.97, 912, 634, 437],
[52.54, 30.95, 31.5, 32.39, 32.49, 19.39, 109.62, 903, 629, 436],
[52.58, 30.98, 31.49, 32.39, 32.48, 19.31, 109.97, 884, 616, 434],
[52.93, 31.02, 31.48, 32.38, 32.47, 19.24, 110, 886, 619, 433],
[52.93, 31.04, 31.48, 32.37, 32.46, 19.18, 110.1, 906, 630, 435],
[52.7, 31.06, 31.47, 32.36, 32.46, 19.19, 109.92, 914, 637, 437],
[52.58, 31.08, 31.46, 32.35, 32.45, 19.13, 109.66, 898, 627, 436],
[52.85, 31.1, 31.45, 32.34, 32.44, 19.14, 109.57, 891, 622, 434],
[52.7, 31.13, 31.44, 32.33, 32.44, 19.1, 109.45, 886, 618, 433],
[52.66, 31.15, 31.44, 32.33, 32.43, 19.04, 109.29, 881, 616, 432],
[52.7, 31.18, 31.43, 32.32, 32.42, 19.03, 109.26, 884, 617, 432],
[52.81, 31.22, 31.42, 32.31, 32.41, 18.98, 109.09, 883, 615, 432],
[53.12, 31.26, 31.42, 32.31, 32.41, 18.89, 109.27, 885, 616, 432],
[53.04, 31.31, 31.42, 32.3, 32.4, 18.8, 109.26, 876, 610, 431],
[53.12, 31.35, 31.41, 32.29, 32.4, 18.71, 109.26, 875, 612, 431],
[53.16, 31.4, 31.41, 32.29, 32.39, 18.63, 109.03, 872, 608, 431],
[53.12, 31.44, 31.4, 32.28, 32.39, 18.57, 108.52, 880, 614, 431],
[53.35, 31.49, 31.4, 32.28, 32.38, 18.49, 108.96, 880, 614, 432],
[53.39, 31.53, 31.39, 32.27, 32.38, 18.42, 109.04, 877, 612, 432],
[53.55, 31.57, 31.39, 32.26, 32.37, 18.33, 109.04, 874, 610, 431],
[53.28, 31.6, 31.38, 32.26, 32.37, 18.24, 108.9, 879, 616, 432],
[53.28, 31.63, 31.38, 32.26, 32.37, 18.16, 108.55, 882, 616, 431],
[53.43, 31.67, 31.38, 32.25, 32.36, 18.08, 108.93, 897, 627, 435],
[53.16, 31.69, 31.37, 32.25, 32.36, 18.03, 108.53, 890, 620, 433],
[53.74, 31.72, 31.37, 32.24, 32.36, 17.97, 108.3, 897, 622, 434],
[53.2, 31.76, 31.37, 32.24, 32.35, 17.99, 108.49, 883, 615, 433],
[53.74, 31.83, 31.37, 32.24, 32.35, 17.98, 108.21, 882, 615, 432],
[53.63, 31.88, 31.36, 32.23, 32.35, 18, 108.15, 890, 620, 433],
[53.63, 31.9, 31.35, 32.23, 32.35, 17.98, 108.18, 877, 610, 432],
[53.74, 31.94, 31.36, 32.23, 32.34, 17.98, 108.11, 880, 613, 431],
[53.63, 31.96, 31.36, 32.22, 32.34, 17.96, 107.75, 879, 611, 431],
[53.67, 31.98, 31.36, 32.22, 32.34, 17.88, 108.19, 873, 611, 430],
[53.71, 32, 31.36, 32.22, 32.34, 17.79, 108.01, 874, 612, 430],
[53.78, 32.01, 31.35, 32.22, 32.34, 17.7, 107.92, 889, 622, 433],
[53.74, 32.03, 31.35, 32.22, 32.34, 17.64, 108.43, 882, 615, 432],
[53.31, 32.05, 31.36, 32.22, 32.34, 17.53, 108.7, 895, 622, 433],
[53.63, 32.06, 31.35, 32.21, 32.34, 17.45, 108.36, 887, 619, 432],
[53.55, 32.06, 31.35, 32.22, 32.34, 17.39, 108.69, 885, 616, 432],
[53.55, 32.07, 31.35, 32.21, 32.34, 17.33, 108.38, 891, 619, 433],
[53.67, 32.08, 31.35, 32.21, 32.34, 17.34, 108.06, 898, 625, 434],
[53.74, 32.1, 31.35, 32.21, 32.34, 17.36, 108, 887, 618, 433],
[53.98, 32.13, 31.35, 32.21, 32.34, 17.36, 108.19, 889, 619, 433],
[53.78, 32.14, 31.35, 32.21, 32.34, 17.37, 108.25, 886, 618, 433],
[53.71, 32.16, 31.35, 32.21, 32.34, 17.34, 107.87, 873, 610, 431],
[53.82, 32.19, 31.35, 32.21, 32.34, 17.3, 107.39, 872, 609, 430],
[54.14, 32.23, 31.36, 32.21, 32.34, 17.29, 107.77, 889, 620, 433],
[54.3, 32.26, 31.36, 32.21, 32.34, 17.25, 107.79, 875, 610, 432],
[54.26, 32.3, 31.36, 32.21, 32.34, 17.27, 107.33, 876, 612, 430],
[54.1, 32.34, 31.36, 32.22, 32.34, 17.24, 107.59, 876, 612, 431],
[54.14, 32.39, 31.37, 32.22, 32.35, 17.18, 107.3, 871, 608, 430],
[54.38, 32.47, 31.37, 32.22, 32.35, 17.14, 106.96, 882, 612, 431],
[54.3, 32.52, 31.37, 32.22, 32.35, 17.09, 107.02, 886, 619, 433],
[54.46, 32.57, 31.37, 32.22, 32.36, 17.08, 107.19, 890, 619, 433],
[54.65, 32.62, 31.38, 32.22, 32.36, 17.05, 106.78, 897, 622, 433],
[54.61, 32.67, 31.38, 32.23, 32.36, 17.01, 106.57, 882, 614, 432],
[54.77, 32.71, 31.38, 32.23, 32.36, 16.95, 106.8, 878, 613, 431],
[54.65, 32.75, 31.39, 32.23, 32.37, 16.92, 106.97, 893, 622, 433],
[54.73, 32.79, 31.39, 32.24, 32.37, 16.85, 107.2, 882, 617, 432],
[54.73, 32.82, 31.4, 32.24, 32.38, 16.78, 107.17, 891, 618, 433],
[54.77, 32.85, 31.4, 32.24, 32.38, 16.71, 106.55, 898, 624, 434],
[54.77, 32.88, 31.4, 32.24, 32.38, 16.63, 106.98, 899, 625, 434],
[54.9, 32.91, 31.41, 32.25, 32.39, 16.57, 106.47, 894, 623, 434],
[54.85, 32.95, 31.41, 32.25, 32.39, 16.49, 106.76, 895, 623, 433],
[54.85, 32.97, 31.42, 32.26, 32.4, 16.46, 107.08, 905, 629, 434],
[54.94, 33, 31.42, 32.26, 32.4, 16.42, 106.68, 905, 627, 435],
[54.94, 33.02, 31.43, 32.27, 32.41, 16.36, 107.05, 904, 627, 435],
[54.94, 33.04, 31.44, 32.27, 32.41, 16.33, 107.03, 912, 632, 436],
[54.85, 33.05, 31.44, 32.27, 32.42, 16.29, 107.47, 915, 638, 437],
[54.77, 33.06, 31.45, 32.28, 32.42, 16.25, 107.98, 915, 639, 437],
[54.81, 33.04, 31.45, 32.28, 32.43, 16.22, 108.52, 911, 634, 436],
[54.65, 33.02, 31.46, 32.29, 32.43, 16.17, 108.71, 909, 634, 436],
[54.46, 32.98, 31.46, 32.29, 32.44, 16.12, 109.1, 910, 633, 437],
[54.34, 32.94, 31.46, 32.3, 32.44, 16.09, 109.47, 909, 634, 436],
[54.14, 32.88, 31.47, 32.3, 32.45, 16.06, 109.8, 906, 632, 436],
[54.14, 32.8, 31.47, 32.31, 32.45, 16.04, 110.28, 905, 631, 436],
[53.94, 32.73, 31.47, 32.31, 32.46, 16, 110.41, 913, 638, 436],
[53.59, 32.64, 31.47, 32.32, 32.46, 15.97, 111, 905, 631, 435],
[53.47, 32.54, 31.48, 32.32, 32.46, 15.96, 111.27, 907, 632, 436],
[53.08, 32.42, 31.47, 32.32, 32.47, 15.91, 111.91, 900, 630, 436],
[52.62, 32.27, 31.47, 32.33, 32.47, 15.9, 112.87, 903, 631, 436],
[52.24, 32.12, 31.47, 32.33, 32.47, 15.94, 113.39, 911, 634, 437],
[51.9, 31.95, 31.47, 32.33, 32.47, 15.94, 113.65, 897, 628, 436],
[51.48, 31.78, 31.46, 32.33, 32.47, 15.95, 114.09, 902, 630, 437],
[51.15, 31.58, 31.46, 32.33, 32.46, 15.97, 113.79, 907, 630, 436],
[51, 31.4, 31.45, 32.33, 32.46, 15.94, 113.8, 905, 631, 436],
[50.85, 31.22, 31.45, 32.33, 32.46, 15.92, 114.13, 899, 626, 436],
[50.63, 31.06, 31.44, 32.32, 32.45, 15.93, 114.19, 899, 628, 436],
[50.59, 30.87, 31.43, 32.32, 32.45, 15.93, 114.49, 909, 632, 437],
[50.59, 30.71, 31.43, 32.32, 32.44, 15.95, 114.13, 899, 627, 436],
[50.34, 30.56, 31.41, 32.31, 32.43, 15.91, 114.31, 914, 634, 436],
[50.59, 30.43, 31.41, 32.3, 32.42, 15.94, 114, 914, 634, 435],
[50.3, 30.28, 31.4, 32.3, 32.41, 15.93, 114.46, 905, 629, 434],
[50.26, 30.18, 31.39, 32.29, 32.4, 15.94, 114.82, 897, 623, 432],
[50.12, 30.06, 31.38, 32.28, 32.39, 15.98, 114.87, 897, 624, 432],
[50.19, 29.94, 31.37, 32.27, 32.38, 15.93, 115.01, 904, 628, 432],
[50.12, 29.83, 31.35, 32.26, 32.36, 15.91, 114.9, 890, 619, 430],
[50.12, 29.72, 31.34, 32.25, 32.35, 15.85, 115.17, 886, 615, 429],
[50.04, 29.63, 31.32, 32.24, 32.33, 15.81, 114.76, 898, 625, 432],
[50.19, 29.54, 31.31, 32.23, 32.32, 15.8, 114.58, 911, 632, 434],
[50.19, 29.47, 31.3, 32.21, 32.3, 15.75, 114.34, 924, 642, 435],
[50.23, 29.41, 31.29, 32.2, 32.29, 15.74, 114.3, 925, 642, 436],
[50.23, 29.37, 31.27, 32.19, 32.27, 15.69, 114.24, 921, 639, 435],
[50.34, 29.34, 31.26, 32.17, 32.26, 15.64, 113.98, 924, 639, 435],
[50.52, 29.32, 31.24, 32.16, 32.24, 15.61, 114.1, 925, 640, 436],
[50.7, 29.3, 31.22, 32.15, 32.23, 15.54, 113.41, 926, 642, 436],
[50.63, 29.3, 31.21, 32.13, 32.21, 15.47, 113.7, 921, 640, 435],
[50.7, 29.31, 31.2, 32.12, 32.19, 15.42, 113.67, 926, 642, 436],
[50.7, 29.32, 31.18, 32.1, 32.18, 15.38, 113.53, 922, 641, 436],
[50.78, 29.32, 31.17, 32.09, 32.16, 15.41, 113.26, 926, 643, 436],
[50.7, 29.32, 31.15, 32.07, 32.15, 15.38, 113.16, 926, 644, 436],
[50.78, 29.33, 31.14, 32.06, 32.13, 15.34, 113.35, 926, 642, 436],
[50.81, 29.32, 31.12, 32.04, 32.11, 15.34, 113.3, 926, 643, 436],
[50.7, 29.32, 31.11, 32.02, 32.1, 15.31, 113.49, 922, 642, 436],
[50.89, 29.31, 31.09, 32.01, 32.08, 15.3, 113.36, 922, 640, 436],
[50.7, 29.31, 31.07, 31.99, 32.07, 15.24, 113.78, 923, 642, 436],
[50.67, 29.3, 31.06, 31.98, 32.05, 15.18, 113.7, 921, 640, 436],
[50.7, 29.3, 31.04, 31.96, 32.04, 15.09, 113.31, 921, 642, 436],
[50.78, 29.3, 31.02, 31.95, 32.02, 15.02, 113.02, 922, 640, 436],
[50.89, 29.31, 31.01, 31.93, 32.01, 14.96, 113.1, 920, 641, 436],
[50.85, 29.33, 31, 31.92, 31.99, 14.9, 112.84, 921, 640, 436],
[50.85, 29.35, 30.98, 31.9, 31.98, 14.84, 113, 916, 635, 435],
[50.96, 29.37, 30.97, 31.89, 31.96, 14.8, 112.93, 920, 639, 436],
[51.04, 29.39, 30.95, 31.87, 31.95, 14.75, 112.39, 914, 635, 435],
[51.15, 29.43, 30.94, 31.86, 31.93, 14.73, 112.29, 900, 626, 433],
[51.11, 29.46, 30.92, 31.84, 31.92, 14.68, 112.12, 895, 623, 432],
[51.3, 29.49, 30.91, 31.83, 31.9, 14.7, 112.5, 913, 636, 435],
[51.26, 29.52, 30.89, 31.81, 31.89, 14.71, 112.13, 916, 637, 435],
[51.26, 29.55, 30.88, 31.8, 31.88, 14.67, 112.19, 920, 637, 436],
[51.63, 29.59, 30.87, 31.79, 31.86, 14.66, 112.27, 907, 631, 434],
[51.6, 29.62, 30.86, 31.77, 31.85, 14.6, 111.93, 894, 624, 432],
[51.6, 29.65, 30.85, 31.76, 31.84, 14.53, 112.33, 912, 635, 435],
[51.63, 29.68, 30.84, 31.75, 31.82, 14.5, 111.93, 922, 641, 436],
[51.67, 29.74, 30.81, 31.72, 31.8, 14.45, 111.86, 925, 640, 437],
[51.67, 29.77, 30.8, 31.71, 31.79, 14.42, 111.62, 915, 635, 435],
[51.71, 29.79, 30.79, 31.69, 31.78, 14.38, 111.77, 918, 635, 436],
[51.75, 29.82, 30.77, 31.68, 31.77, 14.32, 111.94, 902, 625, 433],
[51.9, 29.84, 30.77, 31.67, 31.76, 14.28, 111.5, 913, 635, 435],
[51.86, 29.87, 30.76, 31.66, 31.74, 14.23, 111.47, 910, 632, 434],
[51.71, 29.89, 30.75, 31.65, 31.73, 14.18, 111.63, 918, 635, 435],
[51.78, 29.92, 30.73, 31.64, 31.72, 14.15, 111.88, 917, 636, 436],
[51.86, 29.94, 30.72, 31.63, 31.71, 14.1, 111.59, 913, 634, 436],
[51.82, 29.95, 30.71, 31.62, 31.7, 14.09, 111.44, 920, 639, 436],
[51.86, 29.98, 30.7, 31.61, 31.69, 14.06, 111.46, 921, 639, 436],
[51.9, 30, 30.7, 31.6, 31.68, 14.04, 111.32, 921, 639, 436],
[51.86, 30.02, 30.68, 31.58, 31.68, 14, 111.49, 916, 636, 436],
[51.9, 30.04, 30.68, 31.58, 31.67, 14, 111.21, 918, 636, 436],
[51.94, 30.07, 30.67, 31.57, 31.66, 13.97, 111.19, 921, 638, 436],
[52.09, 30.09, 30.66, 31.56, 31.65, 13.98, 111.66, 921, 636, 436],
[52.01, 30.11, 30.65, 31.55, 31.64, 14.04, 111.1, 921, 638, 436],
[52.01, 30.13, 30.64, 31.54, 31.63, 14.03, 111.64, 918, 634, 436],
[52.31, 30.15, 30.63, 31.53, 31.62, 14.07, 111.29, 916, 635, 436],
[52.16, 30.18, 30.63, 31.52, 31.62, 14.09, 111.38, 914, 633, 435],
[52.13, 30.2, 30.62, 31.51, 31.61, 14.08, 111.73, 913, 632, 435],
[52.31, 30.21, 30.61, 31.5, 31.6, 14.09, 111.63, 916, 634, 435],
[52.35, 30.21, 30.6, 31.5, 31.59, 14.1, 112.18, 916, 634, 436],
[52.09, 30.2, 30.59, 31.49, 31.59, 14.11, 111.96, 915, 634, 435],
[52.2, 30.19, 30.59, 31.48, 31.58, 14.11, 112.56, 912, 633, 435],
[52.05, 30.17, 30.58, 31.47, 31.57, 14.1, 112.8, 914, 633, 435],
[51.9, 30.15, 30.57, 31.46, 31.56, 14.07, 112.97, 916, 634, 435],
[51.9, 30.13, 30.56, 31.46, 31.56, 14.06, 113.16, 914, 634, 435],
[52.01, 30.11, 30.55, 31.45, 31.55, 14.04, 113.22, 920, 636, 436],
[51.94, 30.09, 30.55, 31.44, 31.54, 13.99, 113.26, 919, 636, 436],
[52.01, 30.07, 30.54, 31.43, 31.53, 13.99, 113.01, 921, 639, 436],
[51.9, 30.05, 30.54, 31.43, 31.53, 13.95, 113.08, 922, 636, 436],
[51.9, 30.03, 30.52, 31.42, 31.52, 13.95, 113.03, 919, 635, 436],
[51.86, 30.02, 30.52, 31.41, 31.51, 13.99, 113.24, 922, 638, 436],
[51.9, 30, 30.51, 31.4, 31.5, 13.97, 113.43, 915, 634, 436],
[51.82, 29.99, 30.5, 31.39, 31.5, 14.01, 113.1, 922, 638, 435],
[51.86, 29.98, 30.49, 31.39, 31.49, 13.99, 113.2, 920, 637, 435],
[51.9, 29.98, 30.48, 31.38, 31.48, 13.97, 113.41, 919, 637, 436],
[51.9, 29.97, 30.48, 31.37, 31.47, 14.02, 113.09, 919, 637, 436],
[51.86, 29.97, 30.47, 31.36, 31.46, 14, 113.44, 919, 634, 435],
[51.86, 29.96, 30.46, 31.35, 31.45, 14, 113.41, 913, 633, 435],
[52.13, 29.96, 30.45, 31.35, 31.45, 14.02, 113.49, 920, 639, 436],
[51.71, 29.95, 30.44, 31.34, 31.44, 14.03, 113.61, 913, 634, 435],
[51.71, 29.94, 30.44, 31.33, 31.43, 14.04, 113.68, 912, 634, 435],
[51.67, 29.93, 30.43, 31.32, 31.42, 14.01, 113.77, 910, 633, 434],
[51.78, 29.93, 30.42, 31.32, 31.41, 14.01, 113.79, 913, 633, 434],
[51.6, 29.92, 30.41, 31.31, 31.4, 13.99, 113.55, 915, 634, 435],
[51.6, 29.91, 30.4, 31.3, 31.4, 13.96, 113.61, 914, 635, 434],
[51.56, 29.89, 30.39, 31.29, 31.39, 13.96, 113.57, 917, 636, 435],
[51.63, 29.89, 30.39, 31.28, 31.38, 13.94, 113.91, 909, 633, 434],
[51.52, 29.87, 30.38, 31.27, 31.37, 13.97, 113.83, 908, 631, 434],
[51.45, 29.86, 30.37, 31.27, 31.37, 13.98, 113.96, 913, 635, 435],
[51.48, 29.84, 30.36, 31.26, 31.36, 13.93, 113.89, 921, 639, 435],
[51.45, 29.82, 30.35, 31.25, 31.35, 13.91, 113.88, 920, 637, 435],
[51.48, 29.81, 30.35, 31.24, 31.34, 13.92, 114, 920, 639, 436],
[51.33, 29.79, 30.34, 31.23, 31.33, 13.93, 114.1, 923, 641, 436],
[51.45, 29.78, 30.33, 31.23, 31.33, 13.92, 113.98, 921, 638, 436],
[51.67, 29.76, 30.32, 31.22, 31.32, 13.89, 113.55, 914, 635, 435],
[51.41, 29.75, 30.31, 31.21, 31.31, 13.89, 113.79, 916, 636, 434],
[51.33, 29.74, 30.3, 31.2, 31.3, 13.87, 113.63, 918, 639, 436],
[51.41, 29.73, 30.3, 31.19, 31.29, 13.84, 113.57, 921, 639, 436],
[51.41, 29.71, 30.29, 31.19, 31.28, 13.84, 113.82, 927, 642, 436],
[51.3, 29.7, 30.28, 31.18, 31.28, 13.87, 113.96, 918, 635, 435],
[51.37, 29.69, 30.27, 31.17, 31.27, 13.84, 113.79, 914, 634, 435],
[51.45, 29.68, 30.26, 31.16, 31.26, 13.9, 114.08, 915, 635, 435],
[51.41, 29.67, 30.26, 31.15, 31.25, 13.91, 114.09, 924, 640, 436],
[51.56, 29.66, 30.25, 31.14, 31.24, 13.89, 113.84, 920, 638, 436],
[51.41, 29.65, 30.24, 31.14, 31.24, 13.91, 113.73, 914, 635, 435],
[51.48, 29.65, 30.23, 31.13, 31.23, 13.95, 113.46, 912, 633, 435],
[51.48, 29.65, 30.23, 31.12, 31.22, 13.92, 113.45, 915, 633, 435],
[51.3, 29.65, 30.22, 31.11, 31.21, 13.92, 113.6, 920, 636, 435],
[51.9, 29.65, 30.21, 31.1, 31.2, 13.92, 113.39, 914, 634, 435],
[51.37, 29.65, 30.2, 31.1, 31.19, 13.9, 113.49, 912, 631, 434],
[51.52, 29.65, 30.19, 31.09, 31.19, 13.92, 113.51, 907, 630, 434],
[51.41, 29.65, 30.19, 31.08, 31.18, 13.91, 113.36, 903, 627, 433],
[51.45, 29.65, 30.18, 31.07, 31.17, 13.9, 113.53, 906, 629, 434],
[51.3, 29.65, 30.17, 31.06, 31.16, 13.87, 113.25, 898, 626, 433],
[51.41, 29.65, 30.15, 31.05, 31.15, 13.85, 113.64, 910, 630, 434],
[51.56, 29.64, 30.15, 31.05, 31.15, 13.81, 113.62, 913, 631, 434],
[51.75, 29.64, 30.14, 31.04, 31.14, 13.85, 113.65, 899, 624, 433],
[51.52, 29.64, 30.14, 31.03, 31.13, 13.87, 113.53, 903, 625, 433],
[51.48, 29.64, 30.13, 31.02, 31.12, 13.86, 113.35, 912, 631, 434],
[51.67, 29.64, 30.12, 31.02, 31.11, 13.86, 113.19, 906, 630, 434],
[51.48, 29.63, 30.11, 31.01, 31.11, 13.88, 113.37, 912, 633, 434],
[51.6, 29.64, 30.1, 31, 31.1, 13.85, 112.95, 917, 634, 435],
[51.52, 29.64, 30.1, 30.99, 31.09, 13.83, 113.13, 917, 634, 435],
[51.52, 29.65, 30.09, 30.98, 31.09, 13.81, 113.19, 919, 636, 435],
[51.75, 29.65, 30.08, 30.98, 31.08, 13.79, 112.82, 911, 632, 434],
[51.45, 29.65, 30.07, 30.97, 31.07, 13.77, 112.76, 912, 632, 434],
[51.48, 29.64, 30.06, 30.96, 31.06, 13.75, 112.8, 908, 631, 434],
[51.48, 29.64, 30.06, 30.95, 31.06, 13.7, 112.89, 903, 627, 433],
[51.37, 29.63, 30.05, 30.95, 31.05, 13.68, 112.92, 917, 636, 435],
[51.41, 29.63, 30.05, 30.94, 31.04, 13.69, 112.74, 916, 636, 435],
[51.48, 29.62, 30.04, 30.93, 31.03, 13.68, 113.06, 922, 640, 436],
[51.37, 29.61, 30.03, 30.92, 31.03, 13.67, 113.34, 920, 638, 435],
[51.26, 29.6, 30.02, 30.92, 31.02, 13.68, 113.02, 917, 636, 435],
[51.37, 29.59, 30.02, 30.91, 31.01, 13.64, 113.27, 920, 638, 435],
[51.22, 29.58, 30.01, 30.9, 31.01, 13.63, 113.6, 919, 638, 435],
[51.19, 29.56, 30, 30.9, 31, 13.62, 113.37, 919, 640, 435],
[51.3, 29.55, 29.99, 30.89, 30.99, 13.62, 113.13, 916, 637, 435],
[51.48, 29.54, 29.99, 30.88, 30.98, 13.63, 113.08, 920, 639, 436],
[51.15, 29.54, 29.98, 30.87, 30.97, 13.65, 112.55, 919, 638, 435],
[51.15, 29.54, 29.97, 30.87, 30.97, 13.6, 113.02, 924, 642, 436],
[51.19, 29.54, 29.96, 30.86, 30.96, 13.57, 113.15, 915, 635, 435],
[51.15, 29.54, 29.96, 30.85, 30.95, 13.54, 113.29, 908, 633, 434],
[51.11, 29.54, 29.95, 30.84, 30.95, 13.5, 113.46, 910, 633, 434],
[51.19, 29.53, 29.94, 30.84, 30.94, 13.46, 113.42, 910, 634, 434],
[51.15, 29.52, 29.94, 30.83, 30.93, 13.42, 113.31, 913, 634, 434],
[51.15, 29.51, 29.93, 30.82, 30.93, 13.41, 113.58, 916, 636, 435],
[51.04, 29.5, 29.92, 30.82, 30.92, 13.38, 113.76, 917, 637, 435],
[51.04, 29.49, 29.92, 30.81, 30.91, 13.36, 113.95, 913, 634, 434],
[51.07, 29.48, 29.91, 30.8, 30.9, 13.36, 114.18, 908, 633, 434],
[50.93, 29.46, 29.9, 30.79, 30.9, 13.33, 114.28, 916, 638, 435],
[51.04, 29.45, 29.89, 30.79, 30.89, 13.31, 114.34, 914, 636, 435],
[50.81, 29.43, 29.88, 30.78, 30.88, 13.29, 114.57, 912, 634, 434],
[50.78, 29.41, 29.88, 30.77, 30.87, 13.32, 114.7, 901, 627, 433],
[50.7, 29.39, 29.87, 30.77, 30.87, 13.28, 114.78, 893, 624, 431],
[50.7, 29.37, 29.86, 30.76, 30.86, 13.26, 114.56, 905, 628, 433],
[50.7, 29.35, 29.85, 30.75, 30.85, 13.22, 114.58, 905, 630, 433],
[50.7, 29.33, 29.85, 30.74, 30.84, 13.19, 114.86, 910, 634, 434],
[50.7, 29.31, 29.84, 30.73, 30.84, 13.16, 114.94, 901, 628, 432],
[50.93, 29.3, 29.83, 30.73, 30.83, 13.12, 115.04, 893, 624, 432],
[50.7, 29.28, 29.82, 30.72, 30.82, 13.08, 115.09, 911, 635, 434],
[50.59, 29.26, 29.81, 30.71, 30.81, 13.05, 115.04, 915, 638, 434],
[50.41, 29.24, 29.81, 30.7, 30.8, 13.03, 115.01, 920, 641, 436],
[50.45, 29.23, 29.8, 30.7, 30.8, 12.98, 115.09, 912, 635, 434],
[50.7, 29.21, 29.79, 30.69, 30.79, 12.93, 115.11, 909, 634, 434],
[50.34, 29.19, 29.78, 30.68, 30.78, 12.9, 114.86, 913, 635, 434],
[50.52, 29.17, 29.78, 30.67, 30.77, 12.87, 114.8, 914, 637, 434],
[50.37, 29.16, 29.77, 30.66, 30.77, 12.82, 115.01, 913, 637, 434],
[50.41, 29.15, 29.76, 30.66, 30.76, 12.79, 115.19, 909, 635, 433],
[50.34, 29.13, 29.75, 30.65, 30.75, 12.76, 115.24, 895, 626, 432],
[50.37, 29.12, 29.74, 30.64, 30.74, 12.72, 114.69, 906, 633, 433],
[50.59, 29.12, 29.73, 30.63, 30.73, 12.69, 114.66, 902, 630, 433],
[50.3, 29.11, 29.72, 30.62, 30.72, 12.65, 114.67, 914, 638, 435],
[50.37, 29.1, 29.72, 30.62, 30.72, 12.62, 114.83, 914, 637, 435],
[50.34, 29.09, 29.71, 30.61, 30.71, 12.56, 114.93, 913, 637, 434],
[50.34, 29.09, 29.7, 30.6, 30.7, 12.52, 114.84, 905, 631, 434],
[50.45, 29.08, 29.69, 30.59, 30.69, 12.48, 114.74, 911, 635, 434],
[50.26, 29.07, 29.68, 30.58, 30.68, 12.42, 114.82, 914, 637, 434],
[50.3, 29.07, 29.68, 30.58, 30.68, 12.39, 114.99, 919, 642, 435],
[50.23, 29.06, 29.67, 30.57, 30.67, 12.37, 115.18, 915, 636, 435],
[50.23, 29.05, 29.66, 30.56, 30.66, 12.31, 115.29, 917, 642, 435],
[50.19, 29.05, 29.65, 30.55, 30.65, 12.27, 115.37, 917, 639, 434],
[50.26, 29.04, 29.64, 30.54, 30.64, 12.24, 115.04, 909, 637, 434],
[50.15, 29.03, 29.63, 30.54, 30.63, 12.21, 115.16, 914, 637, 434],
[50.3, 29.03, 29.63, 30.53, 30.63, 12.18, 115.08, 905, 631, 433],
[50.3, 29.02, 29.62, 30.52, 30.62, 12.14, 115.1, 902, 631, 433],
[50.23, 29.02, 29.61, 30.51, 30.61, 12.11, 115.19, 907, 636, 434],
[50.08, 29.02, 29.6, 30.5, 30.6, 12.09, 114.99, 910, 635, 434],
[50.37, 29.02, 29.59, 30.49, 30.59, 12.08, 114.93, 914, 638, 434],
[50.04, 29.01, 29.59, 30.49, 30.59, 12.07, 114.88, 911, 635, 434],
[50.15, 29.01, 29.57, 30.48, 30.58, 12.03, 114.83, 905, 633, 433],
[50.19, 29.02, 29.57, 30.47, 30.57, 11.99, 114.49, 907, 633, 434],
[50.23, 29.03, 29.56, 30.46, 30.56, 11.93, 114.37, 909, 635, 434],
[50.3, 29.05, 29.55, 30.45, 30.55, 11.88, 114.2, 907, 634, 434],
[50.26, 29.06, 29.55, 30.45, 30.55, 11.86, 113.87, 914, 638, 435],
[50.56, 29.09, 29.54, 30.44, 30.54, 11.81, 113.8, 913, 638, 434],
[50.41, 29.12, 29.53, 30.43, 30.53, 11.78, 113.74, 911, 638, 434],
[50.52, 29.15, 29.52, 30.42, 30.53, 11.74, 113.61, 910, 637, 435],
[50.56, 29.18, 29.52, 30.42, 30.52, 11.69, 113.76, 909, 634, 434],
[50.59, 29.21, 29.52, 30.41, 30.51, 11.64, 113.31, 905, 634, 434],
[50.7, 29.25, 29.51, 30.4, 30.51, 11.62, 113.31, 887, 620, 430],
[50.78, 29.32, 29.5, 30.39, 30.49, 11.58, 113.11, 905, 632, 433],
[51, 29.36, 29.49, 30.39, 30.49, 11.52, 112.92, 905, 635, 434],
[50.85, 29.4, 29.49, 30.38, 30.48, 11.47, 113.16, 912, 635, 434],
[51, 29.43, 29.48, 30.37, 30.48, 11.44, 113.11, 895, 626, 432],
[50.89, 29.47, 29.48, 30.37, 30.47, 11.41, 113.06, 898, 628, 434],
[50.93, 29.5, 29.47, 30.36, 30.47, 11.37, 113.07, 903, 632, 433],
[51.3, 29.54, 29.47, 30.36, 30.47, 11.34, 112.91, 904, 631, 434],
[51.04, 29.57, 29.47, 30.35, 30.46, 11.28, 112.64, 890, 623, 432],
[51.04, 29.61, 29.46, 30.35, 30.46, 11.25, 112.55, 903, 630, 434],
[51, 29.64, 29.46, 30.34, 30.45, 11.24, 112.47, 889, 621, 431],
[51.26, 29.68, 29.46, 30.34, 30.45, 11.2, 112.46, 906, 635, 435],
[51.19, 29.72, 29.45, 30.33, 30.45, 11.18, 112.26, 898, 628, 433],
[51.22, 29.78, 29.45, 30.33, 30.45, 11.17, 112.2, 896, 624, 433],
[51.33, 29.83, 29.44, 30.33, 30.44, 11.14, 112.02, 885, 619, 432],
[51.45, 29.88, 29.45, 30.33, 30.44, 11.1, 111.95, 893, 626, 432],
[51.6, 29.94, 29.44, 30.32, 30.44, 11.1, 111.82, 910, 635, 435],
[51.15, 29.99, 29.44, 30.32, 30.44, 11.05, 111.87, 914, 636, 435],
[51.6, 30.05, 29.44, 30.32, 30.44, 11.01, 111.6, 913, 637, 436],
[51.9, 30.1, 29.44, 30.31, 30.43, 10.98, 111.89, 901, 631, 434],
[51.71, 30.15, 29.44, 30.31, 30.43, 10.95, 111.88, 888, 622, 432],
[51.9, 30.2, 29.44, 30.31, 30.43, 10.92, 111.84, 892, 623, 432],
[52.24, 30.24, 29.44, 30.31, 30.43, 10.9, 111.53, 903, 632, 434],
[51.86, 30.29, 29.44, 30.31, 30.43, 10.87, 111.33, 911, 635, 435],
[51.97, 30.33, 29.44, 30.31, 30.43, 10.86, 111.39, 909, 635, 435],
[51.86, 30.38, 29.44, 30.31, 30.44, 10.87, 111.2, 921, 642, 437],
[51.94, 30.42, 29.44, 30.31, 30.44, 10.84, 111.38, 902, 632, 434],
[52.01, 30.47, 29.44, 30.31, 30.44, 10.83, 111.53, 908, 634, 435],
[51.97, 30.52, 29.45, 30.31, 30.44, 10.87, 111.08, 909, 635, 436],
[52.01, 30.56, 29.45, 30.31, 30.44, 10.83, 110.98, 903, 629, 434],
[52.09, 30.61, 29.45, 30.31, 30.44, 10.8, 111.21, 892, 626, 433],
[52.47, 30.65, 29.45, 30.31, 30.45, 10.79, 111.2, 889, 625, 432],
[52.31, 30.71, 29.45, 30.32, 30.45, 10.78, 111.17, 900, 628, 434],
[52.28, 30.75, 29.46, 30.32, 30.45, 10.73, 111.15, 906, 632, 435],
[52.35, 30.79, 29.46, 30.32, 30.45, 10.7, 110.96, 900, 628, 434],
[52.7, 30.83, 29.47, 30.32, 30.46, 10.68, 110.76, 901, 630, 435],
[52.51, 30.87, 29.47, 30.32, 30.46, 10.66, 110.64, 881, 617, 431],
[52.51, 30.91, 29.48, 30.33, 30.46, 10.64, 110.47, 892, 624, 433],
[52.58, 30.95, 29.47, 30.33, 30.47, 10.6, 110.88, 903, 632, 434],
[52.58, 30.99, 29.48, 30.33, 30.47, 10.6, 110.53, 900, 630, 434],
[52.66, 31.02, 29.49, 30.34, 30.48, 10.59, 110.57, 890, 621, 432],
[52.89, 31.06, 29.49, 30.34, 30.48, 10.56, 110.46, 879, 613, 430],
[52.74, 31.09, 29.5, 30.35, 30.49, 10.54, 110.21, 875, 613, 430],
[52.81, 31.13, 29.51, 30.35, 30.49, 10.53, 110.46, 874, 611, 430],
[53.12, 31.16, 29.51, 30.35, 30.5, 10.51, 110.65, 883, 618, 432],
[52.81, 31.19, 29.52, 30.36, 30.5, 10.5, 110.65, 890, 623, 433],
[53.12, 31.23, 29.52, 30.36, 30.51, 10.47, 110.77, 890, 623, 433],
[53.04, 31.25, 29.53, 30.37, 30.51, 10.44, 110.83, 890, 623, 433],
[52.81, 31.27, 29.53, 30.37, 30.52, 10.43, 110.87, 876, 612, 430],
[52.93, 31.29, 29.54, 30.38, 30.53, 10.42, 110.84, 875, 610, 430],
[52.97, 31.3, 29.55, 30.38, 30.53, 10.39, 110.94, 890, 621, 433],
[53.12, 31.32, 29.55, 30.39, 30.54, 10.37, 111.14, 889, 624, 433],
[53.04, 31.33, 29.55, 30.4, 30.54, 10.34, 111.18, 890, 622, 432],
[53.01, 31.33, 29.56, 30.4, 30.55, 10.31, 111.42, 884, 619, 431],
[52.89, 31.34, 29.57, 30.41, 30.56, 10.27, 111.67, 885, 619, 431],
[52.81, 31.33, 29.58, 30.41, 30.56, 10.24, 112.09, 903, 629, 434],
[52.77, 31.31, 29.58, 30.42, 30.57, 10.23, 112.32, 909, 632, 434],
[52.77, 31.28, 29.59, 30.43, 30.58, 10.22, 112.24, 905, 631, 434],
[52.77, 31.25, 29.59, 30.43, 30.58, 10.18, 112.4, 915, 637, 436],
[52.58, 31.22, 29.6, 30.44, 30.59, 10.15, 112.48, 913, 636, 435],
[52.54, 31.18, 29.6, 30.44, 30.59, 10.14, 112.49, 918, 639, 436],
[52.54, 31.15, 29.61, 30.45, 30.6, 10.12, 112.44, 921, 640, 436],
[52.66, 31.12, 29.61, 30.46, 30.61, 10.08, 112.27, 915, 638, 436],
[52.51, 31.1, 29.62, 30.46, 30.61, 10.04, 112.2, 909, 633, 435],
[52.54, 31.09, 29.62, 30.47, 30.62, 10, 111.99, 911, 634, 435],
[52.54, 31.07, 29.63, 30.47, 30.62, 10, 111.76, 895, 623, 432],
[52.43, 31.07, 29.63, 30.48, 30.63, 9.98, 111.98, 900, 630, 434],
[52.51, 31.06, 29.64, 30.48, 30.63, 9.98, 111.87, 900, 630, 434],
[52.66, 31.05, 29.64, 30.49, 30.64, 9.96, 111.87, 906, 633, 435],
[52.51, 31.05, 29.65, 30.5, 30.64, 9.94, 111.93, 896, 626, 433],
[52.54, 31.05, 29.65, 30.5, 30.65, 9.93, 111.93, 887, 619, 431],
[52.54, 31.04, 29.66, 30.51, 30.65, 9.93, 111.77, 879, 612, 430],
[52.54, 31.04, 29.66, 30.51, 30.66, 9.9, 111.51, 889, 619, 431],
[52.58, 31.04, 29.67, 30.52, 30.66, 9.88, 111.57, 898, 627, 433],
[52.51, 31.04, 29.67, 30.52, 30.67, 9.86, 111.76, 877, 614, 430],
[52.54, 31.04, 29.68, 30.53, 30.67, 9.85, 111.77, 878, 614, 430],
[52.66, 31.04, 29.68, 30.53, 30.68, 9.81, 111.71, 904, 629, 435],
[52.51, 31.04, 29.69, 30.53, 30.68, 9.81, 111.64, 896, 623, 433],
[52.51, 31.04, 29.69, 30.54, 30.69, 9.78, 111.72, 896, 628, 433],
[52.54, 31.04, 29.7, 30.55, 30.69, 9.76, 111.77, 902, 630, 433],
[52.66, 31.04, 29.71, 30.55, 30.7, 9.74, 111.72, 901, 629, 434],
[52.58, 31.04, 29.7, 30.55, 30.7, 9.72, 111.62, 900, 627, 433],
[52.58, 31.03, 29.71, 30.56, 30.7, 9.7, 111.73, 893, 622, 432],
[52.74, 31.03, 29.71, 30.56, 30.71, 9.67, 111.88, 890, 619, 431],
[52.81, 31.03, 29.72, 30.57, 30.71, 9.65, 111.87, 879, 613, 430],
[52.47, 31.03, 29.73, 30.57, 30.72, 9.64, 111.89, 901, 628, 434],
[52.58, 31.02, 29.73, 30.58, 30.72, 9.58, 112.04, 913, 637, 436],
[52.43, 31, 29.73, 30.59, 30.73, 9.57, 112.38, 902, 632, 435],
[52.51, 30.99, 29.74, 30.59, 30.73, 9.54, 112.39, 905, 632, 435],
[52.54, 30.98, 29.74, 30.59, 30.74, 9.53, 112.63, 902, 631, 434],
[52.2, 30.97, 29.75, 30.6, 30.74, 9.52, 112.77, 897, 628, 433],
[52.28, 30.94, 29.75, 30.6, 30.75, 9.49, 112.77, 892, 624, 432],
[52.35, 30.93, 29.76, 30.61, 30.75, 9.48, 112.86, 892, 624, 433],
[52.16, 30.91, 29.76, 30.61, 30.75, 9.48, 113.05, 905, 633, 435],
[52.2, 30.9, 29.77, 30.61, 30.76, 9.45, 113.02, 900, 627, 433],
[52.05, 30.87, 29.77, 30.62, 30.76, 9.48, 113.22, 902, 630, 434],
[52.43, 30.86, 29.77, 30.62, 30.76, 9.45, 113.05, 913, 635, 435],
[52.05, 30.84, 29.78, 30.63, 30.77, 9.44, 112.98, 906, 634, 435],
[52.09, 30.82, 29.77, 30.63, 30.77, 9.45, 112.8, 897, 628, 433],
[52.05, 30.81, 29.78, 30.63, 30.77, 9.42, 112.66, 897, 626, 433],
[52.13, 30.81, 29.78, 30.64, 30.78, 9.37, 112.72, 906, 633, 435],
[52.09, 30.8, 29.79, 30.64, 30.78, 9.34, 112.83, 907, 633, 435],
[52.13, 30.79, 29.79, 30.64, 30.78, 9.3, 112.83, 905, 634, 435],
[52.13, 30.78, 29.79, 30.65, 30.78, 9.27, 112.86, 905, 631, 434],
[52.13, 30.78, 29.79, 30.65, 30.79, 9.27, 112.73, 905, 631, 434],
[52.2, 30.77, 29.8, 30.65, 30.79, 9.23, 112.94, 904, 630, 434],
[52.58, 30.77, 29.8, 30.65, 30.79, 9.21, 113.05, 901, 629, 434],
[52.24, 30.76, 29.8, 30.66, 30.8, 9.16, 113.09, 901, 629, 433],
[52.13, 30.74, 29.8, 30.66, 30.8, 9.12, 113.24, 912, 635, 435],
[52.13, 30.74, 29.81, 30.66, 30.8, 9.1, 113.36, 897, 626, 433],
[52.13, 30.72, 29.81, 30.66, 30.8, 9.08, 113.43, 886, 618, 430],
[52.28, 30.71, 29.81, 30.67, 30.8, 9.07, 113.41, 897, 625, 433],
[52.31, 30.69, 29.81, 30.67, 30.81, 9.08, 113.48, 907, 633, 434],
[52.05, 30.67, 29.81, 30.67, 30.81, 9.05, 113.53, 910, 635, 435],
[51.97, 30.65, 29.81, 30.67, 30.81, 8.99, 113.62, 900, 628, 433],
[52.2, 30.63, 29.82, 30.68, 30.81, 8.98, 113.72, 900, 627, 432],
[51.94, 30.61, 29.82, 30.68, 30.81, 8.95, 113.77, 897, 626, 432],
[51.78, 30.59, 29.82, 30.68, 30.81, 8.91, 113.8, 900, 630, 434],
[51.9, 30.56, 29.82, 30.68, 30.82, 8.87, 113.73, 910, 634, 435],
[51.82, 30.54, 29.82, 30.68, 30.82, 8.88, 113.63, 910, 634, 434],
[51.75, 30.52, 29.82, 30.68, 30.82, 8.84, 113.66, 915, 637, 435],
[51.9, 30.49, 29.82, 30.68, 30.82, 8.8, 113.82, 904, 631, 434],
[51.78, 30.47, 29.82, 30.69, 30.82, 8.79, 113.97, 889, 620, 431],
[51.71, 30.45, 29.82, 30.69, 30.82, 8.8, 114.06, 892, 622, 432],
[51.75, 30.43, 29.83, 30.69, 30.82, 8.77, 114.12, 893, 623, 432],
[51.71, 30.4, 29.82, 30.69, 30.82, 8.77, 113.86, 908, 633, 434],
[51.9, 30.38, 29.82, 30.69, 30.82, 8.74, 114.15, 904, 629, 433],
[51.78, 30.36, 29.82, 30.69, 30.82, 8.73, 113.87, 904, 630, 434],
[51.63, 30.33, 29.82, 30.69, 30.82, 8.71, 114.08, 900, 629, 433],
[51.6, 30.32, 29.82, 30.69, 30.82, 8.7, 114.22, 900, 630, 433],
[51.63, 30.29, 29.82, 30.69, 30.82, 8.69, 114.19, 899, 628, 432],
[51.71, 30.27, 29.82, 30.69, 30.82, 8.69, 114.11, 905, 633, 434],
[51.52, 30.25, 29.82, 30.69, 30.82, 8.67, 113.8, 913, 635, 435],
[51.6, 30.23, 29.82, 30.69, 30.82, 8.65, 114.13, 902, 630, 433],
[51.78, 30.22, 29.82, 30.69, 30.82, 8.65, 114.28, 893, 622, 431],
[51.48, 30.19, 29.82, 30.69, 30.81, 8.63, 114.39, 897, 625, 432],
[51.48, 30.17, 29.82, 30.69, 30.81, 8.63, 114.47, 901, 628, 433],
[51.48, 30.15, 29.81, 30.69, 30.81, 8.64, 114.41, 894, 624, 432],
[51.3, 30.13, 29.81, 30.68, 30.81, 8.62, 114.6, 915, 638, 435],
[51.6, 30.1, 29.81, 30.69, 30.81, 8.62, 114.55, 914, 637, 434],
[51.33, 30.08, 29.81, 30.68, 30.81, 8.61, 114.52, 913, 634, 434],
[51.33, 30.06, 29.81, 30.68, 30.81, 8.6, 114.63, 914, 637, 435],
[51.3, 30.04, 29.8, 30.68, 30.8, 8.6, 114.73, 913, 636, 435],
[51.41, 30.01, 29.8, 30.68, 30.8, 8.59, 114.68, 914, 638, 435],
[51.15, 29.98, 29.8, 30.68, 30.8, 8.59, 114.76, 914, 636, 435],
[51.15, 29.96, 29.8, 30.67, 30.8, 8.6, 114.75, 911, 635, 434],
[51.07, 29.94, 29.79, 30.67, 30.79, 8.58, 114.77, 914, 636, 434],
[51.07, 29.92, 29.79, 30.67, 30.79, 8.59, 114.93, 913, 638, 435],
[51.15, 29.9, 29.79, 30.67, 30.79, 8.58, 114.96, 911, 635, 434],
[51.04, 29.89, 29.79, 30.66, 30.79, 8.6, 114.97, 913, 637, 434],
[50.93, 29.87, 29.78, 30.66, 30.78, 8.61, 114.91, 913, 636, 435],
[51.04, 29.85, 29.78, 30.66, 30.78, 8.64, 114.95, 910, 635, 434],
[51, 29.83, 29.78, 30.66, 30.78, 8.6, 114.91, 900, 628, 433],
[50.93, 29.81, 29.77, 30.65, 30.77, 8.61, 114.87, 909, 633, 434],
[51.15, 29.8, 29.77, 30.65, 30.77, 8.6, 114.91, 909, 636, 434],
[50.96, 29.77, 29.76, 30.64, 30.76, 8.61, 114.88, 913, 638, 434],
[51, 29.76, 29.76, 30.64, 30.76, 8.63, 114.85, 912, 638, 434],
[50.89, 29.75, 29.76, 30.64, 30.76, 8.63, 114.98, 899, 627, 433],
[50.85, 29.74, 29.75, 30.64, 30.75, 8.62, 114.97, 905, 633, 433],
[51, 29.73, 29.74, 30.63, 30.75, 8.6, 115.21, 901, 628, 433],
[51.22, 29.72, 29.74, 30.63, 30.75, 8.58, 115.22, 897, 626, 433],
[51, 29.71, 29.74, 30.62, 30.74, 8.55, 115.23, 909, 633, 434],
[50.89, 29.7, 29.73, 30.62, 30.74, 8.55, 115.13, 908, 634, 433],
[50.96, 29.69, 29.73, 30.62, 30.73, 8.54, 115.04, 908, 633, 434],
[50.78, 29.68, 29.73, 30.61, 30.73, 8.55, 115.14, 912, 635, 434],
[50.78, 29.67, 29.73, 30.61, 30.73, 8.56, 115.3, 907, 631, 433],
[50.81, 29.66, 29.72, 30.61, 30.72, 8.56, 115.36, 905, 632, 433],
[50.78, 29.65, 29.71, 30.6, 30.72, 8.59, 115.41, 900, 630, 433],
[50.81, 29.63, 29.71, 30.6, 30.71, 8.61, 115.38, 913, 636, 434],
[51, 29.62, 29.71, 30.59, 30.71, 8.64, 115.41, 909, 635, 434],
[50.96, 29.61, 29.7, 30.59, 30.7, 8.67, 115.39, 911, 637, 434],
[51, 29.6, 29.7, 30.59, 30.7, 8.68, 115.45, 904, 631, 433],
[50.7, 29.58, 29.69, 30.58, 30.69, 8.68, 115.42, 907, 636, 434],
[50.74, 29.57, 29.68, 30.58, 30.69, 8.7, 115.53, 907, 634, 434],
[50.74, 29.56, 29.68, 30.57, 30.68, 8.72, 115.51, 904, 632, 433],
[50.45, 29.55, 29.68, 30.57, 30.68, 8.73, 115.26, 902, 629, 433],
[50.63, 29.54, 29.67, 30.56, 30.68, 8.74, 115.61, 898, 630, 433],
[50.59, 29.52, 29.67, 30.56, 30.67, 8.73, 115.58, 906, 634, 434],
[50.7, 29.51, 29.66, 30.55, 30.67, 8.74, 115.45, 906, 634, 434],
[50.56, 29.5, 29.66, 30.55, 30.66, 8.77, 115.3, 916, 639, 435],
[50.56, 29.49, 29.65, 30.54, 30.66, 8.79, 115.24, 919, 641, 435],
[50.56, 29.48, 29.65, 30.54, 30.65, 8.78, 115.38, 921, 641, 435],
[50.67, 29.47, 29.64, 30.54, 30.65, 8.78, 115.4, 917, 641, 435],
[50.52, 29.46, 29.64, 30.53, 30.64, 8.76, 115.38, 917, 641, 435],
[50.48, 29.45, 29.63, 30.53, 30.64, 8.76, 115.5, 918, 642, 436],
[50.48, 29.44, 29.63, 30.52, 30.63, 8.79, 115.57, 917, 642, 435],
[50.45, 29.43, 29.62, 30.51, 30.63, 8.78, 115.5, 916, 639, 434],
[50.45, 29.42, 29.62, 30.51, 30.62, 8.79, 115.47, 917, 640, 435],
[50.45, 29.41, 29.61, 30.51, 30.62, 8.83, 115.59, 910, 637, 434],
[50.48, 29.41, 29.61, 30.5, 30.61, 8.86, 115.6, 907, 636, 434],
[50.56, 29.4, 29.6, 30.49, 30.61, 8.87, 115.81, 911, 638, 434],
[50.19, 29.39, 29.6, 30.49, 30.6, 8.83, 115.75, 909, 636, 434],
[50.41, 29.38, 29.59, 30.48, 30.59, 8.83, 115.79, 907, 637, 435],
[50.41, 29.37, 29.59, 30.48, 30.59, 8.84, 115.88, 908, 635, 434],
[50.34, 29.36, 29.58, 30.47, 30.58, 8.86, 115.85, 907, 634, 433],
[50.41, 29.35, 29.57, 30.47, 30.58, 8.86, 115.72, 903, 632, 433],
[50.59, 29.33, 29.57, 30.46, 30.57, 8.88, 115.44, 915, 637, 434],
[50.41, 29.33, 29.56, 30.46, 30.57, 8.91, 115.39, 911, 635, 434],
[50.59, 29.32, 29.56, 30.45, 30.56, 8.93, 115.44, 923, 643, 435],
[50.48, 29.31, 29.55, 30.45, 30.56, 8.92, 115.09, 913, 636, 434],
[50.12, 29.31, 29.55, 30.44, 30.55, 8.93, 114.65, 910, 635, 434],
[50.56, 29.31, 29.54, 30.44, 30.55, 8.94, 114.87, 913, 636, 434],
[50.56, 29.31, 29.54, 30.43, 30.54, 8.92, 114.8, 916, 638, 435],
[50.59, 29.31, 29.53, 30.43, 30.53, 8.93, 114.45, 913, 638, 434],
[50.56, 29.32, 29.53, 30.42, 30.53, 8.92, 114.87, 909, 634, 434],
[50.7, 29.31, 29.53, 30.41, 30.52, 8.93, 115.3, 896, 627, 432],
[50.59, 29.3, 29.52, 30.41, 30.52, 8.95, 115.58, 907, 632, 433],
[50.67, 29.29, 29.51, 30.4, 30.51, 8.99, 115.62, 914, 639, 435],
[50.48, 29.28, 29.51, 30.4, 30.51, 8.99, 115.65, 907, 634, 434],
[50.45, 29.27, 29.5, 30.39, 30.5, 8.99, 115.81, 911, 635, 434],
[50.45, 29.26, 29.5, 30.39, 30.5, 8.98, 115.97, 892, 626, 432],
[50.45, 29.25, 29.49, 30.38, 30.49, 8.96, 115.84, 893, 625, 432],
[50.48, 29.23, 29.48, 30.38, 30.49, 8.95, 115.85, 906, 633, 433],
[50.48, 29.23, 29.48, 30.37, 30.48, 8.94, 115.93, 908, 635, 434],
[50.34, 29.22, 29.47, 30.36, 30.47, 8.91, 115.88, 910, 634, 434],
[50.34, 29.21, 29.47, 30.36, 30.47, 8.92, 115.96, 908, 635, 434],
[50.48, 29.2, 29.46, 30.35, 30.46, 8.92, 115.97, 913, 638, 434],
[50.41, 29.2, 29.45, 30.35, 30.46, 8.91, 115.9, 908, 634, 434],
[50.48, 29.19, 29.45, 30.34, 30.45, 8.9, 115.89, 912, 636, 434],
[50.45, 29.18, 29.44, 30.34, 30.44, 8.91, 115.91, 910, 635, 433],
[50.23, 29.18, 29.44, 30.33, 30.44, 8.9, 115.88, 914, 638, 434],
[50.48, 29.17, 29.43, 30.32, 30.43, 8.92, 116.06, 911, 635, 434],
[50.37, 29.17, 29.42, 30.32, 30.43, 8.91, 116.16, 914, 641, 435],
[50.37, 29.16, 29.42, 30.31, 30.42, 8.9, 115.99, 913, 637, 434],
[50.37, 29.16, 29.41, 30.31, 30.41, 8.87, 116.04, 923, 642, 435],
[50.37, 29.15, 29.4, 30.3, 30.41, 8.88, 116.04, 915, 639, 434],
[50.59, 29.15, 29.4, 30.29, 30.4, 8.89, 116.05, 911, 636, 434],
[50.52, 29.14, 29.39, 30.29, 30.4, 8.86, 116.23, 913, 639, 434],
[50.26, 29.14, 29.39, 30.28, 30.39, 8.84, 116.26, 912, 638, 434],
[50.26, 29.13, 29.38, 30.28, 30.39, 8.84, 116.2, 908, 636, 434],
[50.26, 29.12, 29.38, 30.27, 30.38, 8.83, 116.27, 912, 638, 434],
[50.37, 29.12, 29.37, 30.26, 30.37, 8.8, 116.17, 907, 635, 434],
[50.3, 29.11, 29.37, 30.26, 30.37, 8.79, 116.2, 906, 634, 433],
[50.26, 29.11, 29.36, 30.25, 30.36, 8.78, 116.34, 896, 626, 432],
[50.34, 29.1, 29.35, 30.25, 30.36, 8.76, 116.17, 896, 627, 432],
[50.23, 29.09, 29.35, 30.24, 30.35, 8.75, 116.1, 905, 635, 434],
[50.26, 29.09, 29.34, 30.24, 30.34, 8.75, 116.09, 906, 634, 434],
[50.3, 29.08, 29.33, 30.22, 30.33, 8.71, 116.09, 889, 621, 430],
[50.37, 29.07, 29.32, 30.22, 30.33, 8.68, 116.12, 895, 626, 432],
[50.37, 29.06, 29.32, 30.21, 30.32, 8.66, 116.14, 899, 629, 432],
[50.23, 29.06, 29.31, 30.21, 30.31, 8.63, 115.99, 907, 632, 433],
[50.56, 29.06, 29.3, 30.2, 30.31, 8.61, 115.98, 905, 633, 433],
[50.26, 29.05, 29.3, 30.2, 30.3, 8.58, 115.9, 909, 634, 434],
[50.3, 29.05, 29.3, 30.19, 30.3, 8.56, 115.92, 909, 634, 433],
[50.26, 29.05, 29.29, 30.18, 30.29, 8.55, 115.96, 912, 636, 434],
[50.3, 29.05, 29.28, 30.18, 30.29, 8.51, 115.97, 914, 638, 434],
[50.3, 29.05, 29.28, 30.17, 30.28, 8.46, 116, 911, 635, 434],
[50.26, 29.05, 29.27, 30.17, 30.27, 8.43, 115.96, 919, 641, 435],
[50.26, 29.05, 29.27, 30.16, 30.27, 8.4, 115.97, 906, 633, 433],
[50.23, 29.05, 29.26, 30.15, 30.26, 8.37, 115.99, 908, 633, 433],
[50.26, 29.05, 29.26, 30.15, 30.26, 8.38, 116.02, 908, 635, 433],
[50.26, 29.06, 29.25, 30.14, 30.25, 8.34, 116.07, 912, 636, 434],
[50.26, 29.06, 29.24, 30.14, 30.25, 8.28, 116.12, 907, 635, 433],
[50.3, 29.05, 29.24, 30.13, 30.24, 8.26, 116.1, 905, 632, 433],
[50.3, 29.05, 29.23, 30.13, 30.23, 8.25, 116.15, 908, 634, 434],
[50.34, 29.05, 29.23, 30.12, 30.23, 8.19, 116.12, 914, 636, 434],
[50.45, 29.05, 29.22, 30.12, 30.22, 8.15, 116.07, 911, 635, 433],
[50.3, 29.05, 29.22, 30.11, 30.22, 8.13, 116.04, 911, 635, 433],
[50.34, 29.05, 29.21, 30.11, 30.21, 8.12, 115.9, 911, 637, 434],
[50.41, 29.05, 29.21, 30.1, 30.21, 8.11, 115.88, 907, 634, 434],
[50.37, 29.04, 29.2, 30.1, 30.2, 8.08, 115.81, 906, 633, 433],
[50.3, 29.05, 29.2, 30.09, 30.2, 8.01, 115.76, 918, 638, 434],
[50.56, 29.05, 29.19, 30.08, 30.19, 7.96, 115.71, 915, 638, 434],
[50.59, 29.06, 29.19, 30.08, 30.19, 7.88, 115.61, 912, 637, 434],
[50.41, 29.06, 29.18, 30.07, 30.18, 7.81, 115.59, 905, 634, 434],
[50.41, 29.07, 29.18, 30.07, 30.18, 7.69, 115.55, 911, 636, 434],
[50.26, 29.08, 29.17, 30.06, 30.17, 7.61, 115.44, 915, 640, 434],
[50.3, 29.09, 29.17, 30.06, 30.17, 7.58, 115.36, 913, 638, 434]]

// module.exports = {
//     dataList
// }
export {
    dataList
}
// 制作成sql 放到数据库一份
// 另外一份实时顺序获取
// 环境（℃）	柱箱1（℃）	柱箱2（℃）	检测器1（℃）	检测器2（℃）	柱前压2（Kpa）	柱前压3（Kpa）	柱箱正压（pa）	工作站正压（pa）	驱动气源总压（kpa）
   