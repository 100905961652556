import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import * as CountAPI from '@/api/project/CountAPI'


@Component({
  components: {

  }
})
export default class OnlineNum extends Vue {


  onlineNum = 0;
  totalNum = 0;

  mounted() {
    this.getCount()
  }

  // TODO 添加socket
  getCount() {
    CountAPI.userCount().then(res => {
      if (res.isSucc) {
        this.onlineNum = res.data?.userOnline || 0
        this.totalNum = res.data?.userCount || 0
      }
    })
  }
}