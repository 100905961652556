import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import Moment from "moment";
import Numeral from "numeral";
import * as Echarts from "echarts";

import * as DevSensorTS from "@/type/project/DeviceSensorTS";
import * as UITS from "@/type/system/UITS";
import * as SensorTS from "@/type/project/SensorTS";
import * as SensorHistoryAPI from "@/api/project/SensorHistoryAPI";
import * as DataSrcConfigTS from '@/type/project/DataSrcConfigTS'
import * as DeviceUIConfigTS from '@/type/project/DeviceUIConfigTS'

export class SensorData {
  info: DevSensorTS.DeviceSensorTS
  data: any
}
/**
 * 趋势统计图
 * TODO 把标题和按钮拿出来
 */
@Component({
  components: {},
})
export default class ChartSmall extends Vue {

  // 监控数据
  @Prop() mainData!: SensorData[];
  // 驱动数据变化
  @Prop() dataSize: number;
  // 展开大图回调
  @Prop() onOpenBigBFn: Function;
  // TODO 临时 后期去掉
  @Prop() title: string;
  // 画图的参数
  @Prop() chartOption: DeviceUIConfigTS.ChartOption;

  chartObj: Echarts.ECharts = null;

  tempChartDataOption: any = {};


  mounted() {
    // 初始化统计图外框tgh
    this.initChart();
    this.onDataChange()
  }

  initChart() {
    const _dom = this.$refs.chartOut as HTMLElement;
    this.chartObj = Echarts.init(_dom);
    // 设置基础数据
    this.chartObj.setOption(this.getInitOpt());
    this.chartObj.showLoading();
  }

  @Watch("dataSize")
  onDataChange() {
    console.log("=============== in", this.dataSize, this.mainData, this.chartObj)
    if (!this.chartObj) {
      console.error("ERROR +++++++++++++++", this.mainData)
      return;
    }
    /**
     * 横坐标值 时间 n个
     * 系列里多个数组, 每一个是一条线 n个
     * 系列的name对应legend
     * TODO 瞬时值单独给个对象
     */
    
    // 时间 - 值 临时映射对象
    const _obj: any = {}
    
    // 系列数据转换
    const xSet = new Set
    // 先对齐数据
    this.mainData.forEach(item => {
      if (!item.data) {
        return
      }
      const _dataMap = new Map
      item.data.forEach(itemTwo => {
        // 合并时间轴
        xSet.add(itemTwo.crTime)
        // 存时间-值的映射关系  TODO 考虑单位导致的数值转换
        _dataMap.set(itemTwo.crTime, itemTwo.value)
      });
      _obj[item.info.sensorCode] = _dataMap
    })

    // 横轴时间排序
    const xTime: number[] = Array.from(xSet).sort((a: any, b: any) => {
      return a - b
    }) as number[]
    
    // Echarts数据系列对象
    const _series: any[] = []
    // Echarts 索引对象
    const _legendData: string[] = []

    // const titleList = []
    this.mainData.forEach(item => {
      if (!item.data) {
        return
      }

      // 制作系列数据
      const _tempMap = _obj[item.info.sensorCode]
      // 补0
      const _data = []
      xTime.forEach(itemTwo => {
        _data.push(_tempMap.get(itemTwo) || 0)
      })
      _series.push({
        name: `${item.info.itemName}(${item.info.sensorUnit.replace("C", "℃")})`,
        type: 'line',
        data: _data
      })

      // 制作legend数据
      _legendData.push(`${item.info.itemName}(${item.info.sensorUnit.replace("C", "℃")})`)

      // 优化标题 TODO 对应插件
      // titleList.push(`${item.info.itemName} 单位: ${item.info.sensorUnit.replace("C", "℃")}`)
    })

    // 设置标题
    // this.title = titleList.length == 1 ? titleList.join("") : '综合指标'
    this.tempChartDataOption = {
      legend: {
        data: _legendData
      },
      xAxis: {
        data: xTime.map(item => Moment(item).format(UITS.TIME.SEC))
      },
      series: _series,
    }
    // TODO研究多Y轴问题
    this.chartObj.setOption(this.tempChartDataOption);

    this.chartObj.hideLoading()

  }

  getInitOpt() {
    return {
      legend: {
        orient: 'vertical',
        right: -8,
        top: 32,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'cross',
            label: {
                backgroundColor: '#6a7985'
            }
        }
      },
      grid: {
        show: true,
        top: 20,
        bottom: 32,
        left: 60,
        right: 120,

      },
      // 时间轴 接受一个二维数组 里层第一位是时间 字符串格式
      xAxis: {
        type: "category",
        name: "时间",
      },

      yAxis: {
        type: this.chartOption && this.chartOption.yAxisType && this.chartOption.yAxisType || "value",
        min: "dataMin"
      },
      series: [
        {
          data: [],
          type: "line",
        },
      ],
    };
  }

  // // 打开大图
  onOpenBig() {
    if (this.onOpenBigBFn) {
      this.onOpenBigBFn(this.tempChartDataOption, this.title, this.chartOption);
    }
  }

}
