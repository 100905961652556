import * as HttpTS from '@/type/system/HttpTS'

export class RoleListReq extends HttpTS.ListReq {}

export class RoleListRes extends HttpTS.ListRes < Role > {}

export class Role {
	/** marykey */
	id ? : number;
	/** 角色代码 保留  */
	code ? : string;
	/** 角色描述 keyWord  */
	name ? : string;
	/** 创建人selkey */
	createUserId ? : string;
	/** 创建时间 */
	crTime?: number;

	/** 关联的用户名列表 */
	userArr ? : string[];
	/** 关联的权限名列表 */
	permNameArr ? : string[];
	/** 权限Code/Id列表 */
	permCodeArr?: string[];


}

export class RolePerm {
	id ? : number;
	permCode ? : string;
}