import { Vue, Component, Prop, Model } from "vue-property-decorator";

import * as UITS from "@/type/system/UITS";
import * as HttpTS from '@/type/system/HttpTS'
import * as CDictTS from "@/type/system/CDictTS";
import * as ReSure from "@/components/global/ReSure";

import * as SensorTS from "@/type/project/SensorTS";
import * as SensorDataGeoTS from "@/type/project/SensorDataGeoTS";
import * as SensorAPI from "@/api/project/SensorAPI";
import * as DataSrcConfigTS from "@/type/project/DataSrcConfigTS";
import * as DataSrcConfigAPI from "@/api/project/DataSrcConfigAPI";

import { MapRes } from "@/components/baidu/MapEditBaidu";

import ShowLen from "@/components/normal/ShowLen.vue";
import MapEditBaidu from "@/components/baidu/MapEditBaidu.vue";
import MapDetailBaidu from "@/components/baidu/MapDetailBaidu.vue";

@Component({
  components: {
    ShowLen,
    MapEditBaidu,
    MapDetailBaidu
  },
})
export default class SensorEditSia extends Vue {
  
  @Model("onchange") mainData: SensorTS.SensorTS;
  // 初始化标识
  @Prop() initType: UITS.INIT_TYPE
  // 位置信息设置后回调
  @Prop() onChangeGeoData: Function;
  /** 初始化标识 */
  INIT_TYPE = UITS.INIT_TYPE;
  /** 表单规则 */
  FORM_RULE = UITS.FORM_RULE;
  SIA_INNER_SENSOR = SensorTS.SIA_INNER_SENSOR;

  SIA_INNER_SEL = SensorTS.SIA_INNER_SEL;

  /** 是否显示编辑框 */
  show: boolean = false;
  /** 提交等待 */
  loading: UITS.Loading = new UITS.Loading("数据保存中...");
  /** 初始化参数 */

  mounted() {
  }

  /** 表单验证 */
  public async formValidate() {
    const _mainForm = this.$refs.mainForm as any;
    return new Promise((resolve) => {
      _mainForm.validate((valid: boolean) => {
        resolve(valid);
      });
    });
  }

  /** 取消 */
  public onClean() {
    const _mainForm = this.$refs.mainForm as any;
    _mainForm.resetFields();
  }

   // 检测指标变更事件
   onChangeTypeSel(value: SensorTS.SIA_INNER_SENSOR) {

    if (value) {
      Object.assign(this.mainData, this.SIA_INNER_SEL[value])
      // 处理code
      this.onMakeCode()
    }
  }
  onMakeCode() {
    if (this.mainData.LNGDeviceId && this.mainData.LNGType) {
      this.mainData.sensorCode = this.mainData.LNGDeviceId + HttpTS.MID_LINE + this.mainData.LNGType
    }
  }
  /** 地图选点 */
  onChangeInitLocal(data: MapRes) {
    if (this.onChangeGeoData) {
      this.onChangeGeoData(data)
    }
  }
}
