import { Vue, Component, Prop } from "vue-property-decorator";
import { VNode } from "vue";
import * as UITS from "@/type/system/UITS";
import * as ReSure from "@/components/global/ReSure";
import ShowLen from "@/components/normal/ShowLen.vue";
import * as HttpUtil from "@/util/HttpUtil";

import * as RoleTS from "@/type/system/RoleTS";
import * as PermTS from "@/type/system/PermTS";
import * as RoleAPI from "@/api/system/RoleAPI";
import * as PermAPI from "@/api/system/PermAPI";

@Component({
  components: {
    ShowLen,
  },
})
export default class RoleEdit extends Vue {
  @Prop() onChange?: Function;
  /** 初始化标识 */
  INIT_TYPE = UITS.INIT_TYPE;
  /** 表单校验规则 */
  FORM_RULE = UITS.FORM_RULE;
  /** 是否显示编辑框 */
  show: boolean = false;
  /** 提交等待 */
  loading: UITS.Loading = new UITS.Loading("数据保存中...");
  /** 初始化参数 */
  initType: UITS.INIT_TYPE | '--' = '--';
  /** 主要数据 */
  mainData: RoleTS.Role = new RoleTS.Role();
  /** 权限备选数据 */
  // permSel: UITS.Transfer[] = []
  permSel: PermTS.Perm[] = [];

  mounted() {
    // 获取权限备选列表 初始化获取一遍即可 大概率不会变
    this.getPermSel();
  }

  getPermSel() {
    const permReq = new PermTS.PermListReq();
    permReq.pageSize = 9999;
    PermAPI.getList(permReq).then((res) => {
      const succ = HttpUtil.checkSucc(res);
      if (succ) {
        this.permSel = res.list || [];
      }
    });
  }

  init(_initType: UITS.INIT_TYPE, _mainData?: RoleTS.Role) {
    // 清空表单
    const _mainForm = this.$refs.mainForm as any;
    _mainForm && _mainForm.resetFields();

    // 显示组件
    this.show = true;
    // 调整loading
    this.loading.spinning = false;

    // 注入数据
    if (!_mainData) {
      _mainData = new RoleTS.Role();
    } else {
      _mainData = { ..._mainData };
    }
    this.mainData = _mainData;
    this.initType = _initType;
  }

  async onSave() {
    // 浅拷贝, 避免影响调试
    const _mainData = { ...this.mainData };
    const _mainForm = this.$refs.mainForm as any;
    const _validSucc = await new Promise((resolve) => {
      _mainForm.validate((valid: boolean) => {
        resolve(valid);
      });
    });
    if (!_validSucc) {
      return;
    }

    const _sure = await ReSure.saveSure();
    if (!_sure) {
      console.warn("sure do false");
      return;
    }

    this.loading.spinning = true;
    switch (this.initType) {
      case this.INIT_TYPE.UPDATE:
        RoleAPI.updateGroup(
          _mainData,
          [_mainData.id!],
          _mainData.permCodeArr || []
        ).then((res) => {
          const succ = HttpUtil.checkSucc(res);
          if (succ) {
            this.onChange && this.onChange();
            this.show = false;
          }
        });
        break;
      case this.INIT_TYPE.ADD:
        RoleAPI.addOne(_mainData, _mainData.permCodeArr || []).then((res) => {
          const succ = HttpUtil.checkSucc(res);
          if (succ) {
            this.onChange && this.onChange();
            this.show = false;
          }
        });
    }
  }

  onClose() {
    ReSure.saveSure("是否放弃保存 ?", "放弃保存").then((res) => {
      if (res) {
        this.show = false;
      }
    });
  }

  onChangePermSel() {
    console.log("=============change sel", this.mainData?.permCodeArr);
  }

  choosedPerm(permCode: string) {
    return this.mainData?.permCodeArr?.includes(permCode);
  }

  onFilterSel(inputVal?: string, option?: VNode) {
    if (inputVal) {
      const _com = option!.componentOptions!.children![0];
      if (_com && _com.text?.indexOf(inputVal) != -1) {
        return true;
      }
    }
  }
}
