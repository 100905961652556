
import * as DataSrcConfigTS from '@/type/project/DataSrcConfigTS'

export class SensorDataGeoTS {
  /** 主键 [关联键] */
  id?: number;
  /** 设备id   如果换传感器 需要留底 */
  deviceId?: number;
  /** 传感器数据源编号 */
  sensorCode?: string;
  /** 色谱/真空设备 */
  dataSrcId?: DataSrcConfigTS.DATA_SRC_ENUM
  /** 经度 */
  lng?: number;
  /** 纬度 */
  lat?: number;
  /**  手写地址 */
  address?: string;
  /** 国家 [对比缓存] */
  nation?: string;
  /** 省 [对比缓存] */
  province?: string;
  /** 市 [对比缓存] */
  city?: string;
  /** 创建时间 */
  crTime?: number;

  
}
