import {Vue, Component, Prop} from 'vue-property-decorator'
import * as NaviTS from '@/type/system/NaviTS'

@Component({
  components: {

  }
})
export default class NaviDetail extends Vue {
  
  get devDetailRoute() {
    return this.$router.getRoutes().filter(item => {
      const _meta = item.meta as NaviTS.MyRouteMeta
      if (_meta.groupName == NaviTS.ROUTE_NAME.DEV_DETAIL) {
        return item;
      }
    })
  }

  onChangePage(routeName: string) {
    if (this.$route.name != routeName) {
      this.$router.replace({
        name: routeName,
        query: this.$route.query
      })
    }

  }


}