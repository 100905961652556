import * as HttpUtil from '@/util/HttpUtil'
import * as HttpTS from '@/type/system/HttpTS'
import * as DevTS from '@/type/project/DeviceTS'
import * as DeviceSensorTS from '@/type/project/DeviceSensorTS'

export const getList = (params: DevTS.DevListReq): Promise<DevTS.DevListRes> => {
    params = JSON.parse(JSON.stringify(params))
    // params.devTypeStr = params.devTypeList.length && params.devTypeList.join(HttpTS.ARRAY_FLAG) || null
    return HttpUtil.get("dev/list", params)
}

/**
 * 
 * @param id 
 * @param addInfo 
 * 额外的附加信息标识 有一个算一个
 * @returns 
 */
export const detail = async (id: number | string, addInfo?: DevTS.DEV_DETAIL_ADDINFO[]): Promise<DevTS.DevDetailRes> => {
    const res = await HttpUtil.get("dev/detail", {id, addInfo: addInfo?.join(",")}) as DevTS.DevDetailRes
    // 处理图标配置
    if (res.data.uiConfigList) {
        for (let i = 0; i < res.data.uiConfigList.length; i++) {
            const item = res.data.uiConfigList[i]
            item.chartOption = item.chartOption && JSON.parse(item.chartOption) || {}
        }
    }
    return res;
}

export const addOne = (dev: DevTS.DeviceTS, geoSensor: DeviceSensorTS.DeviceSensorTS): Promise<HttpTS.HttpRes> => {
    return HttpUtil.post("dev/add", {devJson: JSON.stringify(dev), geoSensor: JSON.stringify(geoSensor)})
}

export const update = (user: DevTS.DeviceTS, id?: number): Promise<HttpTS.HttpRes> => {
    return HttpUtil.post("dev/update", {devJson: JSON.stringify(user), id})
}

export const deleteGroup = (ids: number[]): Promise<HttpTS.HttpRes> => {
    return HttpUtil.post("dev/delete", {ids: ids.join(HttpTS.ARRAY_FLAG)})
}

/** 添加中车设备 自动关联传感器 */
export const addListLNGSensor = (dev: DevTS.DeviceTS): Promise<HttpTS.HttpRes> => {
    return HttpUtil.post("dev/add-list-lng-sensor", {devJson: JSON.stringify(dev)})
}

/** 添加气相色谱设备 自动关联传感器 */
export const addChromaDev = (dev: DevTS.DeviceTS, geoSensor: DeviceSensorTS.DeviceSensorTS): Promise<HttpTS.HttpRes> => {
    return HttpUtil.post("dev/add-chroma", {devJson: JSON.stringify(dev), geoSensorJson: JSON.stringify(geoSensor)})
}

/** 添加真空度设备 */
export const addSiaDev = (dev: DevTS.DeviceTS ): Promise<HttpTS.HttpRes> => {
    return HttpUtil.post("dev/add-sia", {devJson: JSON.stringify(dev)})
}