import Numeral from 'numeral'
import Convert from 'convert-units'
export {
    lengthFormat, // 长度转显示值
    lengthParse, // 长度转存储值
    lengthStep, // 输入组件步长
    LENG_UNIT,
    massFormat, // 重量转显示值
    massParse, // 重量转存储值
    massStep, // 输入组件步长
    MASS_UNIT,
    volumeFormat, // 体积转显示值
    volumeParse, // 体积转存储值
    volumeStep, // 输入组件步长
    VOLUME_UNIT,
    pressureFormat, // 压力限制值
    pressureParse, // 压力转存储值
    pressureStep, // 输入组件步长
    PRESSURE_UNIT,
    getRandom,
}
const getRandom = function(min = 0, max = 1, b = 0) {
    return Number((Math.random() * (max - min) + min).toFixed(b));
}
type LENG_UNIT = 'mm' | 'm' | 'km' 
type MASS_UNIT = 'mg' | 'g' | 'kg' | 't' 
type VOLUME_UNIT = 'ml' | 'l' | 'm3' | 'km3'
type PRESSURE_UNIT = 'Pa' | 'kPa' | 'MPa'

const LENGTH_SAVE: LENG_UNIT = 'mm'
const MASS_SAVE: MASS_UNIT = 'mg'
const VOLUME_SAVE: VOLUME_UNIT = 'ml'
const PERSSURE_SAVE: PRESSURE_UNIT = 'Pa'

const lengthStep = (_armUnit: LENG_UNIT): number => {
    return Convert(1).from(_armUnit).to(LENGTH_SAVE)
}
const massStep = (_armUnit: MASS_UNIT): number => {
    return Convert(1).from(_armUnit).to(MASS_SAVE)
}
const volumeStep = (_armUnit: VOLUME_UNIT): number => {
    return Convert(1).from(_armUnit).to(VOLUME_SAVE)
}
const pressureStep = (_armUnit: PRESSURE_UNIT): number => {
    return Convert(1).from(_armUnit).to(PERSSURE_SAVE)
}


const pressureFormat = (_src?: number | string, _armUnit?: PRESSURE_UNIT): string => {
    _src = String(_src)
    if (!_src || !_armUnit) {
        return '--'
    }
    if (noAct(_src)) {
        return _src;
    }
    try {
        const _temp = Convert(Numeral(_src).value() || 0).from(PERSSURE_SAVE).to(_armUnit)
        return Numeral(_temp).format('0,0.[0000]')
    } catch(e) {
        console.error(e)
        return '--'
    }
}

const pressureParse = (_src?: string, _srcUnit?: PRESSURE_UNIT): number | string => {
    if (!_src || !_srcUnit) {
        return 0
    }
    if (noAct(_src)) {
        return _src;
    }
    try {
        const _temp = Numeral(_src).value() || 0
        return Convert(_temp).from(_srcUnit).to(PERSSURE_SAVE)

    } catch (e) {
        console.error(e)
        return 0
    }
}

const volumeFormat = (_src?: number | string, _armUnit?: VOLUME_UNIT): string => {
    _src = String(_src)
    if (!_src || !_armUnit) {
        return '--'
    }
    if (noAct(_src)) {
        return _src;
    }
    try {
        const _temp = Convert(Numeral(_src).value() || 0).from(VOLUME_SAVE).to(_armUnit)
        return Numeral(_temp).format('0,0.[0000]')
    } catch(e) {
        console.error(e)
        return '--'
    }
}

const volumeParse = (_src?: string, _srcUnit?: VOLUME_UNIT): number | string => {
    if (!_src || !_srcUnit) {
        return 0
    }
    if (noAct(_src)) {
        return _src;
    }
    try {
        const _temp = Numeral(_src).value() || 0
        return Convert(_temp).from(_srcUnit).to(VOLUME_SAVE)

    } catch (e) {
        console.error(e)
        return 0
    }
}

const massFormat = (_src?: number | string, _armUnit?: MASS_UNIT): string => {
    _src = String(_src)
    if (!_src || !_armUnit) {
        return '--'
    }
    if (noAct(_src)) {
        return _src;
    }
    try {
        const _temp = Convert(Numeral(_src).value() || 0).from(MASS_SAVE).to(_armUnit)
        return Numeral(_temp).format('0,0.[0000]')
    } catch(e) {
        console.error(e)
        return '--'
    }
}

const massParse = (_src?: string, _srcUnit?: MASS_UNIT): number | string => {
    if (!_src || !_srcUnit) {
        return 0
    }
    if (noAct(_src)) {
        return _src;
    }
    try {
        const _temp = Numeral(_src).value() || 0
        return Convert(_temp).from(_srcUnit).to(MASS_SAVE)

    } catch (e) {
        console.error(e)
        return 0
    }
}

const lengthFormat = (_src?: string | number, _armUnit?: LENG_UNIT): string => {
    _src = String(_src)
    if (!_src || !_armUnit) {
        return '--'
    }
    if (noAct(_src)) {
        return _src;
    }
    try {
        const _temp = Convert(Numeral(_src).value() || 0).from(LENGTH_SAVE).to(_armUnit)
        const result = Numeral(_temp).format('0,0.[0000000]')
        return result
    } catch(e) {
        console.error(e)
        return '--'
    }
}

const lengthParse = (_src?: string, _srcUnit?: LENG_UNIT): number | string => {
    if (!_src || !_srcUnit) {
        return 0
    }

    if (noAct(_src)) {
        return _src;
    }
    try {
        const _temp = Numeral(_src).value() || 0
        const result = Convert(_temp).from(_srcUnit).to(LENGTH_SAVE)
        return result

    } catch (e) {
        console.error(e)
        return 0
    }
}

// true 不处理  false  继续处理 
const noAct = (_src: string): boolean => {
    if (_src.endsWith(".") || (_src.includes(".") && _src.endsWith("0"))) {
        return true;
    }
    return false;
}