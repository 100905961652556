import { Vue, Component, Prop } from "vue-property-decorator";
import moment from "moment";

import * as HttpTS from "@/type/system/HttpTS";
import * as UITS from "@/type/system/UITS";
import * as DevTS from "@/type/project/DeviceTS";
import * as DeviceSensorTS from "@/type/project/DeviceSensorTS";

import * as SensorTS from "@/type/project/SensorTS";

import * as DevAPI from "@/api/project/DevAPI";
import * as SensorHistoryAPI from "@/api/project/SensorHistoryAPI";

const LIST_ROUTE = "home";

@Component({
  components: {},
})
export default class DDWatchHistory extends Vue {

  @Prop() onClose!: Function;
  /** 时间格式 */
  TIME = UITS.TIME;
  moment = moment;
  SENSOR_DATA_TABLE_NAME = SensorTS.SENSOR_DATA_TABLE_NAME;

  // 当前传感器配置
  curDevSensorInfo: DeviceSensorTS.DeviceSensorTS = new DeviceSensorTS.DeviceSensorTS();
  // 请求条件
  historyReq: SensorHistoryAPI.SensorHistoryListReq = null;

  // 查询等待
  loading: UITS.Loading = new UITS.Loading("数据加载中...");

  // 主要数据
  mainData: any = new HttpTS.ListRes();

  // 统一查询器 考虑三个梯度 模糊查询, 分类查询, 排序和翻页
  onSearch(page?: HttpTS.Page) {
    if (page && page instanceof HttpTS.Page) {
      Object.assign(this.historyReq, page);
    } else {
      Object.assign(this.historyReq, new HttpTS.Page());
    }

    this.loading.spinning = true;
    SensorHistoryAPI.getList(this.historyReq).then((res) => {
      this.loading.spinning = false;
      if (res.isSucc) {
        this.mainData = res;
      }
    });
  }

  init(_data: DeviceSensorTS.DeviceSensorTS) {

    this.curDevSensorInfo = _data;

    const _historyReq = new SensorHistoryAPI.SensorHistoryListReq
    _historyReq.sensorType = _data.sensorType
    _historyReq.sensorCode = _data.sensorCode
    this.historyReq = _historyReq
    this.onSearch();
  }

  // 换页
  onChangeTable(page: UITS.TablePage) {
    const _page = new HttpTS.Page();
    _page.currentPage = page.current;
    _page.pageSize = page.pageSize;
    this.onSearch(_page);
  }

  onBackList() {
    this.historyReq = new SensorHistoryAPI.SensorHistoryListReq();
    this.mainData = [];
    if (this.onClose) {
      this.onClose();
    }
  }

  // 获取值
  onGetValue(item: any) {
    if (!item) {
      return "--";
    }

    if (
      SensorTS.SENSOR_DATA_TABLE_NAME.BOTH == this.curDevSensorInfo.sensorType
    ) {
      return item.value / item.maxValue;
    }

    if (this.curDevSensorInfo.sensorType == SensorTS.SENSOR_DATA_TABLE_NAME.GEO) {
      return item.lng + ", " + item.lat;
    }

    if (this.curDevSensorInfo.sensorType == SensorTS.SENSOR_DATA_TABLE_NAME.CODE) {
      return item.code
    }

    if (item.value === 0) {
      return item.value
    }

    return item.value || "--";
  }
}
