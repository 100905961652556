import Vue from 'vue'
import Vuex from 'vuex'
import MainStore from './MainStore'
import LoginStore from './LoginStore'
Vue.use(Vuex)

export default new Vuex.Store({

  modules: {
    MainStore,
    LoginStore
  }
})
