import { ActionContext } from "vuex";
import * as SysDictTS from "@/type/system/SysDictTS";

import * as UserTS from "@/type/system/UserTS";
import * as CountHomeTS from "@/type/project/CountHomeTS";

interface StateType {
  // 用户基本信息
  userInfo: UserTS.User | null;
  // 权限列表
  perm: string[];
  // 字典表
  dict: SysDictTS.SysDictTS[];
  // ws通讯标识
  socketToken: string | null;

  refreshLoginData: string;

}

const state = (): StateType => ({
  // 用户基本信息
  userInfo: null,
  // 权限列表
  perm: [],
  // 字典表
  dict: [],
  // ws通讯标识
  socketToken: null,
  // 存随机串, 驱动重新获取数据
  refreshLoginData: ''
});

const getters = {
  userInfo: (state: StateType) => state.userInfo,
  perm: (state: StateType) => state.perm,
  dict: (state: StateType) => state.dict,
  socketToken: (state: StateType) => state.socketToken,
  refreshLoginData: (state: StateType) => state.refreshLoginData,
};

const actions = {
  setUserInfo(
    context: ActionContext<StateType, any>,
    newVal: UserTS.User | null
  ) {
    context.commit("userInfo", newVal);
  },

  setPerm(context: ActionContext<StateType, any>, newVal: string[]) {
    context.commit("perm", newVal);
  },
  setDict(
    context: ActionContext<StateType, any>,
    newVal: SysDictTS.SysDictTS[]
  ) {
    context.commit("dict", newVal);
  },
  setSocketToken(
    context: ActionContext<StateType, any>,
    newVal: string | null
  ) {
    context.commit("socketToken", newVal);
  },
  setRefreshLoginData(
    context: ActionContext<StateType, any>,
    newVal: string
  ) {
    context.commit("refreshLoginData", newVal)
  }
};

const mutations = {
  userInfo(state: StateType, newVal: UserTS.User | null) {
    state.userInfo = newVal;
  },
  perm(state: StateType, newVal: string[]) {
    state.perm = newVal;
  },
  dict(state: StateType, newVal: SysDictTS.SysDictTS[]) {
    state.dict = newVal;
  },
  socketToken(state: StateType, newVal: string | null) {
    state.socketToken = newVal;
  },
  refreshLoginData(state: StateType, newVal: string) {
    state.refreshLoginData = newVal;
  },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }