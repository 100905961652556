import * as HttpTS from "@/type/system/HttpTS";
import * as SensorDataGeoTS from "@/type/project/SensorDataGeoTS";
import * as SysDictTS from '@/type/system/SysDictTS';
import * as ObjUtil from '@/util/ObjUtil';
import {getVue} from '@/util/GlobalRuntimeUtil'
import * as DeviceUIConfigTS from '@/type/project/DeviceUIConfigTS'
import * as DataSrcConfigTS from '@/type/project/DataSrcConfigTS'
import * as DeviceSensorTS from '@/type/project/DeviceSensorTS'
export class DevListReq extends HttpTS.ListReq {
  devTypeList: number[] = []; // 设备标识 前端关注
  devTypeStr: string | '' = ''; // 序列化后的 后端关注
  moveType: number | '' = ''; // 移动类型 固定/移动
  status: number | '' = ''; // 状态 运行/未运行
  dataSrcId: number | '' = ''// 色谱/真空设备
}

export class DevListRes extends HttpTS.ListRes<DeviceTS> {
  geoList?: SensorDataGeoTS.SensorDataGeoTS[];
}

export class DevDetailRes extends HttpTS.HttpRes {
  sensorGeo?: SensorDataGeoTS.SensorDataGeoTS
  list?: DeviceSensorTS.DeviceSensorTS[]
}

export enum DEV_DETAIL_ADDINFO {
  SENSOR_DATA_GEO = 'sensorGeo'
}

// 前端合并数据
export interface DevWithGeo extends DeviceTS, SensorDataGeoTS.SensorDataGeoTS {
}

export class DeviceTS {
  /** 主键 [关联键] */
  id?: number;
  /** 设备编号[非重] */
  code?: string;
  /** 设备名称 */
  name?: string;
  /** 设备型号 */
  typeNumber?: string;
  /** 固定式/移动式 [字典表-固定类型] */
  moveType?: number;
  /** 设备状态 [字典表] */
  status?: number;
  /** 设备分类 [字典表-设备分类] */
  devType?: number;
  /** 设备描述 */
  description?: string;
  /** 接受单位 [关联 - 工厂表 相同type] */
  receiverId?: number;
  /** 保修期  */
  warrantyPeriod?: number;
  /** 介质 [关联]  */
  substanceId?: number;
  /** 设备长度 单位 mm */
  devLength?: number;
  /** 设备宽度 单位 mm */
  devWidth?: number;
  /** 设备高度 单位 mm */
  devHeight?: number;
  /** 安全距离 单位 mm */
  safeDistance?: number;
  /** 额定质量 单位 g */
  ratedWeight?: number;
  /** 罐体质量 单位 g  */
  selfWeight?: number;
  /** 最大填充量 单位 g / ml ???  */
  maxFilling?: number;
  /** 额定单位充装量 g/ml ??? */
  ratedPerFilling?: number;
  /** 内罐额定工作压力 单位 Pa */
  innerRatedWorkPressure?: number;
  /** 安全码垛(层) */
  safeStorey?: number;
  /** 是否删除 1 -是 0 -否 */
  isDel?: number;
  /** 创建人  [关联 = 用户] */
  crUser?: string;
  /** 创建时间 */
  crTime?: number;
  /** 修改时间 */
  upTime?: number;
  /** 介质名称 */
  substanceName?: string;
  /** 接收单位名称 */
  receiverName?: string;

  /** 前端冗余, 位置传感器id */
  tempGeoSensorCode: string;

  /** 趋势查看页面配置信息 */
  uiConfigList?: DeviceUIConfigTS.DeviceUIConfigTS[]
  /** 前端用 设备主类型 */
  uiMainDevType?: DataSrcConfigTS.DATA_SRC_ENUM;
}

export enum DEV_MOVE_TYPE {
  MOVE = 200,
  FIXED = 201
}

export const MOVE_TYPE_SEL: SysDictTS.SysDictTS[] = [
  {key: DEV_MOVE_TYPE.MOVE, value: '移动式', title: ''},
  {key: DEV_MOVE_TYPE.FIXED, value: '固定式', title: ''}
]

export const MOVE_TYPE_KV_OBJ = ObjUtil.getKVObj(MOVE_TYPE_SEL)
