import * as SysDictTS from '@/type/system/SysDictTS'

/**
 * 根据Key 获取Value
 * @param arr 
 * @param key 
 * @returns 
 */
export const getValueByKey = (arr: SysDictTS.SysDictTS[], key?: string | number): string | number => {
    if (key !== undefined && key !== null) {
        const hasOne = arr.find(item => String(item.key) == String(key));
        if (hasOne !== undefined && hasOne !== null) {
            return String(hasOne.value);
        }
    }

    return '--';
}

interface SimpleObj {
    [key: number]: number | string | null
    [key: string]: number | string | null
}

/**
 * 键值数组转键值对象
 * @param arr 
 * @returns 
 */
export const getKVObj = (arr: SysDictTS.SysDictTS[]): SimpleObj => {
    const result: SimpleObj = {}
    for (let i = 0; i < arr.length; i++) {
        const _key = arr[i].key as number
        const _val = arr[i].value as string
        result[_key] = _val
    }
    return result;
}

/**
 * 从数组中提取主键作为对象的key 
 * @param arr 
 * @param keyField 
 * @returns 
 */
export const arrToObj = (arr?: any[], keyField?: string): any => {
    const result: any = {}
    if (arr && arr instanceof Array && keyField) {
        for (let i = 0; i < arr.length; i++) {
            const _key = arr[i][keyField] as string
            result[_key] = arr[i]
        }
    }
    return result;
}