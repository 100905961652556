import { Vue, Component, Prop } from "vue-property-decorator";

import * as UITS from "@/type/system/UITS";
import * as ReSure from "@/components/global/ReSure";
import ShowLen from '@/components/normal/ShowLen.vue'

import * as SysDictTS from "@/type/system/SysDictTS";
import * as DictAPI from '@/api/system/DictAPI';

@Component({
  components: {
    ShowLen
  },
})
export default class DictEdit extends Vue {

  @Prop() onChange?: Function;
  /** 初始化标识 */
  INIT_TYPE = UITS.INIT_TYPE;
  FORM_RULE = UITS.FORM_RULE;
  TYPE_KV_OBJ = SysDictTS.TYPE_KV_OBJ;
  /** 是否显示编辑框 */
  show: boolean = false;
  /** 提交等待 */
  loading: UITS.Loading = new UITS.Loading("数据保存中...");
  /** 初始化参数 */
  initType: UITS.INIT_TYPE | '--' = '--';
  /** 主要数据 */
  mainData: SysDictTS.SysDictTS = new SysDictTS.SysDictTS;

  init(_initType: UITS.INIT_TYPE, _mainData: SysDictTS.SysDictTS) {
    // 清空表单
    const _mainForm = this.$refs.mainForm as any;
    _mainForm && _mainForm.resetFields();

    // 显示组件
    this.show = true;
    // 调整loading
    this.loading.spinning = false;

    // 注入数据
    if (!_mainData) {
      _mainData = new SysDictTS.SysDictTS();
    } else {
      _mainData = {
        ..._mainData,
      };
    }
    
    this.mainData = _mainData;
    this.initType = _initType;
  }

  async onSave() {
    // 浅拷贝, 避免影响调试
    const _mainData = { ...this.mainData }
    const _mainForm = this.$refs.mainForm as any;
    const _validSucc = await new Promise((resolve) => {
      _mainForm.validate((valid: boolean) => {
        resolve(valid);
      });
    });
    if (!_validSucc) {
      return;
    }

    const _sure = await ReSure.saveSure();
    if (!_sure) {
      return;
    }

    this.loading.spinning = true;

    if (this.initType == this.INIT_TYPE.UPDATE) {
      DictAPI.update(_mainData, _mainData.key).then((res) => {
        this.onChange && this.onChange();
        this.show = false;
      });
    } else if (this.initType == this.INIT_TYPE.ADD) {
      DictAPI.addOne(_mainData).then((res) => {
        this.onChange && this.onChange();
        this.show = false;
      });
    }
  }

  onClose() {
    ReSure.saveSure("是否放弃保存 ?", "放弃保存").then((res) => {
      if (res) {
        this.show = false;
      }
    });
  }
}
