import * as HttpUtil from '@/util/HttpUtil'
import * as HttpTS from '@/type/system/HttpTS'

import * as DevSensorTS from '@/type/project/DeviceSensorTS'
import * as SensorTS from '@/type/project/SensorTS'

/**
 * 查设备 - 传感器的绑定列表 
 * 一个传感器某一时间下只能绑定到一个设备 
 * 
 * @param params 
 * deviceId -- 设备id 为null - 查所有未绑定的传感器
 * 
 * @returns 
 */
export const getList = (params: DevSensorTS.DevSensorListReq): Promise<DevSensorTS.DevSensorListRes> => {
    params = JSON.parse(JSON.stringify(params))
    return HttpUtil.get("devsensor/list", params)
}

export const addOne = (mainData: DevSensorTS.DeviceSensorTS): Promise<HttpTS.HttpRes> => {
    return HttpUtil.post("devsensor/add", {devJson: JSON.stringify(mainData)})
}

export const update = (template: DevSensorTS.DeviceSensorTS, id?: number): Promise<HttpTS.HttpRes> => {
    return HttpUtil.post("devsensor/update", {devJson: JSON.stringify(template), id})
}

// 解绑 物理删除
export const deleteOne = (deviceId: number, sensorCode: string): Promise<HttpTS.HttpRes> => {
    return HttpUtil.post("devsensor/delete", {deviceId, sensorCode})
}

/**
 * 获取未绑定的传感器
 * @returns 
 */
export const getLeisureList = (): Promise<SensorTS.SensorListRes> => {
    return HttpUtil.get("devsensor/list/leisure", null)
}

/**
 * 获取未绑定的传感器
 * @returns 
 */
 export const getOtherList = (deviceId: number): Promise<SensorTS.SensorListRes> => {
    return HttpUtil.get("devsensor/list/other", {deviceId})
}
/**
 * 获取最新瞬时值 
 * @param deviceId 
 * @returns 
 */
export const getDetail = (deviceId: number): Promise<DevSensorTS.DevSensorDetail> => {

    return HttpUtil.get("devsensor/detail", {deviceId})
}

