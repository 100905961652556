import {dataList} from '../../tests/siatest/data'

/**
 * 前端模拟数据
 */
export class QueueItem {
    // 当前记录数
    curRow: number = 0
    // 回调函数
    setDataFn: Function = null
    // 列, 也是监控指标
    inx: number = null
}
// 回调队列
let queue: QueueItem[] = []
export const cleanQueue = () => {
    queue = []
}
export const addQueue = (one: QueueItem) => {
    queue.push(one)
}

window.setInterval(() => {
    console.log("cur queue", queue)
    for (let i = 0; i < queue.length; i++) {
        const item = queue[i]
        if (item.setDataFn) {
            if (item.inx) {
                item.setDataFn(dataList[item.curRow][item.inx])
            } else {
                item.setDataFn(dataList[item.curRow])
            }
        }
        item.curRow++;
        if (item.curRow >= dataList.length) {
            item.curRow = 0;
        }

    }
}, 5000)
