import { Vue, Component, Prop } from "vue-property-decorator";
import * as HttpTS from "@/type/system/HttpTS";
import * as HttpUtil from "@/util/HttpUtil";
import * as UITS from "@/type/system/UITS";
import * as DataSrcConfigTS from '@/type/project/DataSrcConfigTS'
import * as DataSrcConfigAPI from '@/api/project/DataSrcConfigAPI'
import * as CDictTS from "@/type/system/CDictTS";

import SensorEdit from "./components/SensorEdit.vue";
import * as SensorTS from "@/type/project/SensorTS";
import * as SensorAPI from "@/api/project/SensorAPI";

@Component({
  components: {
    SensorEdit,
  },
})
export default class SensorListViews extends Vue {

  BAN_SEL = CDictTS.BAN_SEL
  // SENSOR_KV_OBJ = SensorTS.SENSOR_KV_OBJ;
  // SENSOR_SEL = SensorTS.SENSOR_SEL;
  
  // 数据源备选列表
  dataSrcConfigSel: DataSrcConfigTS.DataSrcConfigTS[] = []
  // 查询等待
  loading: UITS.Loading = new UITS.Loading();
  // 编辑页初始化类型
  INIT_TYPE = UITS.INIT_TYPE;
  // 查询条件
  reqObj: SensorTS.SensorListReq = new SensorTS.SensorListReq();
  // 响应结果
  mainData: SensorTS.SensorListRes = new SensorTS.SensorListRes;

  created() {
    this.onSearch();
    this.getDataSrcConfigSel()
  }
  // 获取数据源列表
  getDataSrcConfigSel() {
    const _req = new DataSrcConfigTS.DataSrcConfigListReq()
    _req.pageSize = 99999
    _req.isDel = CDictTS.IS_BAN.N
    DataSrcConfigAPI.getList(_req).then(res => {
      this.dataSrcConfigSel = res.list || []
    })
  }
  // 搜索列表
  onSearch(page?: HttpTS.Page) {
    if (page && page instanceof HttpTS.Page) {
      Object.assign(this.reqObj, page);
    } else {
      Object.assign(this.reqObj, new HttpTS.Page());
    }
    this.loading.spinning = true;
    SensorAPI.getList(this.reqObj).then((res) => {
      this.loading.spinning = false;
      if (HttpUtil.checkSucc(res)) {
        this.mainData = res;
      }
    });
  }

  // 换页
  onChangeTable(page: UITS.TablePage) {
    const _page = new HttpTS.Page();
    _page.currentPage = page.current;
    _page.pageSize = page.pageSize;
    this.onSearch(_page);
  }
  // 打开编辑页
  onShowEdit(initType: UITS.INIT_TYPE, data?: SensorTS.SensorTS) {
    const _edit = this.$refs.SensorEdit as any;
    _edit.init(initType, data);
  }
  onReset() {
    this.reqObj = new SensorTS.SensorListReq();
    this.onSearch()
  }
}
