import * as HttpUtil from '@/util/HttpUtil'
import * as HttpTS from '@/type/system/HttpTS'
import * as SubstanceTS from '@/type/project/SubstanceTS'

export const getList = (params: SubstanceTS.SubstanceListReq): Promise<SubstanceTS.SubstanceListRes> => {
    params = JSON.parse(JSON.stringify(params))
    return HttpUtil.get("substance/list", params)
}

export const addOne = (mainData: SubstanceTS.SubstanceTS): Promise<HttpTS.HttpRes> => {
    return HttpUtil.post("substance/add", {mainJson: JSON.stringify(mainData)})
}

export const update = (template: SubstanceTS.SubstanceTS, id?: number): Promise<HttpTS.HttpRes> => {
    return HttpUtil.post("substance/update", {mainJson: JSON.stringify(template), id})
}

export const deleteGroup = (ids: number[]): Promise<HttpTS.HttpRes> => {
    return HttpUtil.post("substance/delete", {idList: ids.join(HttpTS.ARRAY_FLAG)})
}


