var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('a-form-model',{ref:"mainForm",attrs:{"model":_vm.mainData,"labelCol":{ span: 4 },"wrapperCol":{ span: 18 }}},[_c('a-form-model-item',{attrs:{"label":"中车设备ID","prop":"LNGDeviceId","rules":[
      {
        required: true,
        message: `中车设备ID, 例如: ${_vm.LNG_DEV_LIST.join(', ')}`,
      },
    ]}},[_c('a-input',{staticClass:"def_inp_only",attrs:{"disabled":_vm.initType != _vm.INIT_TYPE.ADD,"placeholder":`中车设备ID, 例如: ${_vm.LNG_DEV_LIST.join(', ')}`,"maxLength":_vm.FORM_RULE.MAX_LENG_32},on:{"change":_vm.onMakeCode},model:{value:(_vm.mainData.LNGDeviceId),callback:function ($$v) {_vm.$set(_vm.mainData, "LNGDeviceId", $$v)},expression:"mainData.LNGDeviceId"}},[_c('ShowLen',{attrs:{"slot":"suffix","max":_vm.FORM_RULE.MAX_LENG_32,"len":(_vm.mainData.LNGDeviceId && _vm.mainData.LNGDeviceId.length) || 0},slot:"suffix"})],1)],1),_c('a-form-model-item',{attrs:{"label":"检测指标","prop":"sensorType","rules":[{ required: true, message: '请选择检测指标!' }]}},[_c('a-select',{staticClass:"def_select",attrs:{"disabled":_vm.initType != _vm.INIT_TYPE.ADD,"placeholder":"请选择检测指标"},on:{"change":_vm.onChangeTypeSel},model:{value:(_vm.mainData.LNGType),callback:function ($$v) {_vm.$set(_vm.mainData, "LNGType", $$v)},expression:"mainData.LNGType"}},_vm._l((_vm.LNG_TYPE_SEL),function(value,key){return _c('a-select-option',{key:key,attrs:{"value":value.LNGType}},[_vm._v(" "+_vm._s(value.name)+" ")])}),1)],1),_c('a-form-model-item',{attrs:{"label":"传感器名称","prop":"name","rules":[{ required: true, message: '请输入传感器名称!' }]}},[_c('a-input',{staticClass:"def_inp_only",attrs:{"disabled":_vm.initType == _vm.INIT_TYPE.DETAIL,"placeholder":"请输入传感器名称","maxLength":_vm.FORM_RULE.MAX_LENG_32},model:{value:(_vm.mainData.name),callback:function ($$v) {_vm.$set(_vm.mainData, "name", $$v)},expression:"mainData.name"}},[_c('ShowLen',{attrs:{"slot":"suffix","max":_vm.FORM_RULE.MAX_LENG_32,"len":(_vm.mainData.name && _vm.mainData.name.length) || 0},slot:"suffix"})],1)],1),_c('a-form-model-item',{attrs:{"label":"传感器编号","prop":"sensorCode","rules":[
      { required: true, message: '请输入传感器编号!' },
      {
        pattern: _vm.FORM_RULE.REP_NO_CN,
        message: '仅支持数字, 字母以及 _, +, =, @, .',
      },
    ]}},[_c('a-input',{staticClass:"def_inp_only",attrs:{"disabled":true,"placeholder":"由设备id及检测类型自动生成!","maxLength":_vm.FORM_RULE.MAX_LENG_32},model:{value:(_vm.mainData.sensorCode),callback:function ($$v) {_vm.$set(_vm.mainData, "sensorCode", $$v)},expression:"mainData.sensorCode"}},[_c('ShowLen',{attrs:{"slot":"suffix","max":_vm.FORM_RULE.MAX_LENG_32,"len":(_vm.mainData.sensorCode && _vm.mainData.sensorCode.length) || 0},slot:"suffix"})],1)],1),_c('a-form-model-item',{attrs:{"label":"数据单位","prop":"sensorUnit","rules":[{ required: true, message: '请输入数据单位!' }]}},[_c('a-input',{staticClass:"def_inp_only",attrs:{"disabled":true,"maxLength":_vm.FORM_RULE.MAX_LENG_32},model:{value:(_vm.mainData.sensorUnit),callback:function ($$v) {_vm.$set(_vm.mainData, "sensorUnit", $$v)},expression:"mainData.sensorUnit"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }